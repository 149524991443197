import React, { useMemo } from 'react';
import { get, max } from 'lodash';
import styled from 'styled-components';

import { DatePicker } from '@float/common/components/DatePicker/DatePicker';
import { moment } from '@float/libs/moment';

const DateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  margin-right: 20px;
  margin-top: 10px;

  input {
    // https://app.asana.com/0/1201258914201276/1201438041567968/f
    // fix width of date picker input here for now to avoid regressions in other places
    width: 140px;
    font-size: 18px !important;
    height: 40px;
  }
`;

const DateRowContainer = styled.div`
  display: flex;

  &:nth-child(2) {
    margin-top: 20px;
  }

  ${DateContainer}:last-child {
    margin-right: 0;
  }
`;

export function format(momentDate) {
  if (!momentDate) return momentDate;
  return momentDate.format('YYYY-MM-DD');
}

export function getMoment(dateStr) {
  if (dateStr && dateStr !== 'Invalid date') return moment(dateStr);
  return null;
}

function DateRow({
  thisYear,
  nextYear,
  dateThisYear,
  dateNextYear,
  showLabel,
  onChange,
}) {
  const momentThisYear = useMemo(() => getMoment(dateThisYear), [dateThisYear]);
  const momentNextYear = useMemo(() => getMoment(dateNextYear), [dateNextYear]);

  return (
    <DateRowContainer>
      <DateContainer>
        <DatePicker
          label={showLabel && thisYear}
          allowClear
          value={momentThisYear}
          onChange={(momentDate) =>
            onChange({ key: thisYear, value: format(momentDate) })
          }
          placeholder="No date"
          minimumDate={moment(`${thisYear}-01-01`).toDate()}
          maximumDate={moment(`${thisYear}-12-31`).toDate()}
          initialYear={Number(thisYear)}
          initialMonth={moment().month()}
        />
      </DateContainer>
      <DateContainer>
        <DatePicker
          label={showLabel && nextYear}
          allowClear
          value={momentNextYear}
          onChange={(momentDate) =>
            onChange({ key: nextYear, value: format(momentDate) })
          }
          placeholder="No date"
          minimumDate={moment(`${nextYear}-01-01`).toDate()}
          maximumDate={moment(`${nextYear}-12-31`).toDate()}
          initialYear={Number(nextYear)}
          initialMonth={moment().month()}
        />
      </DateContainer>
    </DateRowContainer>
  );
}

export default function CustomPublicHolidayDates({
  thisYear,
  nextYear,
  datesThisYear,
  datesNextYear,
  onChange,
}) {
  // we need at least one row, i.e. when adding a custom public holiday
  const dateRowsCount = max([datesThisYear.length, datesNextYear.length, 1]);
  const rows = [];
  for (let i = 0; i < dateRowsCount; i++) {
    const dateThisYear = get(datesThisYear, `[${i}]`, '');
    const dateNextYear = get(datesNextYear, `[${i}]`, '');
    rows.push(
      <DateRow
        key={`${i}-${dateThisYear}-${dateNextYear}`}
        thisYear={thisYear}
        nextYear={nextYear}
        dateThisYear={dateThisYear}
        dateNextYear={dateNextYear}
        showLabel={i === 0}
        onChange={({ key, value }) => onChange({ key, value, index: i })}
      />,
    );
  }
  return rows;
}
