import React, { useLayoutEffect, useRef } from 'react';
import { max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleBand, scaleLinear } from 'd3-scale';
import * as d3Selection from 'd3-selection';
import { isEmpty } from 'lodash';
import tippy, { createSingleton } from 'tippy.js';

import DayDots from '@float/common/components/DayDots';
import DayTooltip from '@float/common/components/DayTooltip';

import { MODES, UNITS } from '../constants';
import { useRenderComponentToString } from '../helpers';
import { createTimeoffStripes } from '../svg-utils';
import { formatTickDate, getTimeoffHours } from './BarChart.helpers';
import ChartTooltip from './BarChartTippy';
import { BarChartContainer } from './styles';

export { formatTickDate, getTimeoffHours };

const maxLabels = 15;
const PADDING_INNER = 0.14;

const MARGIN = {
  top: 10,
  right: 30,
  bottom: 40,
  left: 50,
};

const HEIGHT = 275 - MARGIN.top - MARGIN.bottom;

export function BarChart(props) {
  const chartRef = useRef();
  const {
    items,
    mode,
    unit,
    width: chartWidth,
    loggedTimeBoundary,
    loggedTimeBoundaryIdx,
    noTimeoff,
    noCapacity,
    noUnscheduled,
  } = props;

  let xScale;
  let yScale;
  let xAxis;
  let yAxis;
  let svg;
  let xBandwidth;
  let tippies;
  let dayTippies;

  const formatDate = (d) => formatTickDate(d, unit === UNITS.MONTH);

  const isCompareMode = () => mode === MODES.COMPARE;

  const allInPast = items[items.length - 1]?.date < loggedTimeBoundary;

  const getWidth = () => {
    return chartWidth - MARGIN.left - MARGIN.right;
  };

  const setScaleBands = () => {
    xScale = scaleBand()
      .domain([0, 1])
      .rangeRound([-1, getWidth() + 1], 0.1, 0)
      .paddingInner(PADDING_INNER);

    yScale = scaleLinear().range([HEIGHT, 0]);
  };

  const setAxis = () => {
    const toWrap = items.length > maxLabels;
    const child = toWrap ? Math.round(items.length / maxLabels) : 1;
    yAxis = axisLeft()
      .scale(yScale)
      .ticks(3)
      .tickSizeInner(-getWidth() - 20)
      .tickSizeOuter(0)
      .tickPadding(-30);

    xAxis = axisBottom()
      .scale(xScale)
      .tickFormat((d, i) => {
        d = d.substring(0, d.length - 5);
        if (toWrap) {
          return i % child ? '' : d;
        }
        return d;
      });
  };

  const initSvg = () => {
    svg = d3Selection
      .select(chartRef.current)
      .append('svg')
      .attr('width', getWidth() + MARGIN.left + MARGIN.right)
      .attr('height', HEIGHT + MARGIN.top + MARGIN.bottom)
      .attr('class', 'bar-chart')
      .append('g')
      .attr('transform', `translate(${MARGIN.left},${MARGIN.top})`);

    createTimeoffStripes(svg);
  };

  const scaleDomains = () => {
    const maxAvail = max(items, (d) => d.capacity + d.timeoff);
    let maxValue;

    switch (mode) {
      case MODES.SCHEDULED:
        maxValue = max(items, (d) => d.scheduled + (noTimeoff ? 0 : d.timeoff));
        break;
      case MODES.LOGGED:
        maxValue = max(
          items,
          (d) => d.logged.total + (noTimeoff ? 0 : getTimeoffHours(d, true)),
        );
        break;
      case MODES.COMBINED:
      case MODES.COMPARE: {
        const maxScheduled = max(
          items,
          (d) => d.scheduled + (noTimeoff ? 0 : d.timeoff),
        );
        const maxLogged = max(
          items,
          (d) => d.logged.total + (noTimeoff ? 0 : d.timeoff),
        );
        maxValue = max([maxScheduled, maxLogged]);
        break;
      }
      default:
        maxValue = 0;
        break;
    }

    if (noTimeoff && noCapacity && noUnscheduled) {
      yScale.domain([0, maxValue]);
    } else {
      yScale.domain([0, max([maxAvail, maxValue])]);
    }

    xScale.domain(items.map((d) => formatDate(d.date)));
    xBandwidth = xScale.bandwidth();
    if (isCompareMode()) {
      xBandwidth /= 2;
    }
  };

  const appendAxis = () => {
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${HEIGHT})`)
      .call(xAxis)
      .selectAll('text')
      .each(function (formattedDate, idx) {
        if (idx === loggedTimeBoundaryIdx) {
          this.classList.add('today-label');
        }
      });

    svg
      .append('g')
      .attr('class', 'y axis axis-lines')
      .call(yAxis)
      .attr('transform', `translate(-10, 0)`);
  };

  const addBar = ({ className, x, y, width, height, tippyFn }) => {
    const barClass = `bar-${className}`;
    x = x || ((d) => xScale(formatDate(d.date)));
    width = width || xBandwidth;

    if (mode === MODES.COMBINED) {
      const widthProp = width;

      width = (d, idx) => {
        if (
          idx === loggedTimeBoundaryIdx &&
          className.includes('logged-unscheduled')
        ) {
          return 0;
        }

        if (
          className.includes('logged') &&
          !className.includes('handler') &&
          (loggedTimeBoundaryIdx == -1
            ? d.date >= loggedTimeBoundary
            : idx > loggedTimeBoundaryIdx)
        ) {
          return 0;
        }

        if (
          !className.includes('logged') &&
          !className.includes('handler') &&
          (loggedTimeBoundaryIdx == -1
            ? d.date < loggedTimeBoundary
            : idx < loggedTimeBoundaryIdx)
        ) {
          return 0;
        }

        return typeof widthProp === 'function' ? widthProp(d) : widthProp;
      };
    }

    svg
      .selectAll(`.${barClass}`)
      .data(items)
      .enter()
      .append('rect')
      .attr('class', `bar ${barClass}`)
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', height)
      // .attr('data-tippy-delay', '[0, 10000000]') // (for inspecting)
      .attr('data-tippy-content', tippyFn);
  };

  const isHybridBar = (idx) => {
    return (
      mode === MODES.COMBINED &&
      unit !== UNITS.DAY &&
      idx === loggedTimeBoundaryIdx
    );
  };

  const addBillableBar = ({ x, className = 'billable' }) => {
    addBar({
      className,
      x,
      y: (d, idx) => {
        if (isHybridBar(idx)) {
          return yScale(d.logged.total + d.future.billable);
        }
        return yScale(d.billable);
      },
      height: (d, idx) => {
        if (isHybridBar(idx)) {
          return Math.max(0, HEIGHT - yScale(d.future.billable));
        }
        return Math.max(0, HEIGHT - yScale(d.billable));
      },
    });
  };

  const addNonbillableBar = ({ x, className = 'nonbillable' }) => {
    addBar({
      className,
      x,
      y: (d, idx) => {
        if (isHybridBar(idx)) {
          return yScale(
            d.logged.total + d.future.billable + d.future.nonbillable,
          );
        }
        return yScale(d.nonbillable + d.billable);
      },
      height: (d, idx) => {
        if (isHybridBar(idx)) {
          return Math.max(0, HEIGHT - yScale(d.future.nonbillable));
        }
        return Math.max(0, HEIGHT - yScale(d.nonbillable));
      },
    });
  };

  const addTentativeBar = ({ x, className = 'tentative' }) => {
    addBar({
      className: `${className}-billable`,
      x,
      y: (d, idx) => {
        if (isHybridBar(idx)) {
          return yScale(d.logged.total + d.future.billable);
        }
        return yScale(d.billable);
      },
      height: (d, idx) => {
        if (isHybridBar(idx)) {
          return Math.max(0, HEIGHT - yScale(d.future.tentative.billable));
        }
        return Math.max(0, HEIGHT - yScale(d.tentative.billable));
      },
    });
    addBar({
      className: `${className}-nonbillable`,
      x,
      y: (d, idx) => {
        if (isHybridBar(idx)) {
          return yScale(
            d.logged.total + d.future.billable + d.future.nonbillable,
          );
        }
        return yScale(d.nonbillable + d.billable);
      },
      height: (d, idx) => {
        if (isHybridBar(idx)) {
          return Math.max(0, HEIGHT - yScale(d.future.tentative.nonbillable));
        }
        return Math.max(0, HEIGHT - yScale(d.tentative.nonbillable));
      },
    });
  };

  const addUnscheduledBar = ({ x, className = 'unscheduled' }) => {
    if (noUnscheduled) return;

    addBar({
      className,
      x,
      y: (d) => yScale(d.capacity),
      width: xBandwidth - 2,
      height: (d) => {
        let unscheduled = d.capacity - d.scheduled;
        if (mode === MODES.COMBINED) {
          unscheduled = d.capacity - d.logged.total - d.future.scheduled;
        }
        return Math.max(0, HEIGHT - yScale(unscheduled));
      },
    });
  };

  const addCapacityLine = ({ offset, width, className = 'available' } = {}) => {
    if (noCapacity) return;

    addBar({
      className,
      x: (d) => xScale(formatDate(d.date)) + offset,
      y: (d) => Math.min(HEIGHT - 2, Math.max(1, yScale(d.capacity) - 1)),
      height: 2,
      width: xBandwidth,
    });
  };

  const addTimeoffBar = ({ offset, className = 'timeoff' } = {}) => {
    if (noTimeoff) return;
    const isLogged = mode === MODES.LOGGED || className === 'logged-timeoff';

    addBar({
      className: `${className}-bg`,
      x: (d) => xScale(formatDate(d.date)) + offset,
      y: (d) => {
        const base = isLogged ? d.capacity : Math.max(d.capacity, d.scheduled);
        const timeoffHours = getTimeoffHours(d, isLogged);
        return yScale(timeoffHours + base) - 1;
      },
      height: (d) =>
        Math.max(0, HEIGHT - yScale(getTimeoffHours(d, isLogged)) - 1),
      width: xBandwidth,
    });
    addBar({
      className,
      x: (d) => xScale(formatDate(d.date)) + offset,
      y: (d) => {
        const base =
          isLogged || className === 'logged-timeoff'
            ? d.capacity
            : Math.max(d.capacity, d.scheduled);
        const timeoffHours = getTimeoffHours(d, isLogged);
        return yScale(timeoffHours + base) - 1;
      },
      height: (d) =>
        Math.max(0, HEIGHT - yScale(getTimeoffHours(d, isLogged)) - 1),
      width: xBandwidth,
    });
  };

  const addBarHandler = ({ x, className = 'handler' } = {}) => {
    addBar({
      className,
      x,
      y: 0,
      height: HEIGHT,
      tippyFn: (d, idx) => {
        const showLogged =
          className === 'logged-handler' ||
          (mode == MODES.COMBINED &&
            (allInPast ||
              (unit == UNITS.DAY
                ? idx < loggedTimeBoundaryIdx
                : idx <= loggedTimeBoundaryIdx)));

        return renderComponentToString(
          <ChartTooltip
            data={d}
            showLogged={showLogged}
            noTentativeTimeoff={
              mode === MODES.LOGGED || className === 'logged-timeoff'
            }
            showCombined={
              mode == MODES.COMBINED &&
              unit != UNITS.DAY &&
              idx == loggedTimeBoundaryIdx
            }
            noTimeoff={noTimeoff}
            noCapacity={noCapacity}
          />,
        );
      },
    });
  };

  const addScheduledBar = () => {
    if (mode === MODES.LOGGED) {
      return;
    }

    const offset = isCompareMode() ? xBandwidth + xBandwidth / 10 : 0;
    const x = (d) => xScale(formatDate(d.date)) + offset;

    addUnscheduledBar({ x: (d) => xScale(formatDate(d.date)) + offset + 1 });
    addNonbillableBar({ x });
    addBillableBar({ x });
    addTentativeBar({ x });

    addCapacityLine({ offset });
    addTimeoffBar({ offset });
    addBarHandler({ x });
  };

  const addLoggedBar = () => {
    if (mode === MODES.SCHEDULED) {
      return;
    }

    if (!noUnscheduled) {
      addBar({
        className: 'logged-unscheduled',
        y: (d) => yScale(d.capacity),
        height: (d) =>
          Math.max(1, HEIGHT - yScale(d.capacity - d.logged.total)),
      });
    }

    addBar({
      className: 'logged-billable',
      y: (d) => yScale(d.logged.billable),
      height: (d) => Math.max(0, HEIGHT - yScale(d.logged.billable)),
    });

    addBar({
      className: 'logged-nonbillable',
      y: (d) => yScale(d.logged.billable + d.logged.nonbillable),
      height: (d) => Math.max(0, HEIGHT - yScale(d.logged.nonbillable)),
    });

    addCapacityLine({ className: 'logged-available', offset: 0 });
    addTimeoffBar({ className: 'logged-timeoff', offset: 0 });

    if (mode === MODES.COMBINED) {
      // No need to render redundant bars since they will
      // get rendered for scheduled data
      return;
    }

    addBarHandler({ className: 'logged-handler' });
  };

  const addTippies = () => {
    tippies = tippy('.bar-handler, .bar-logged-handler');

    createSingleton(tippies, {
      delay: [150, 150],
      allowHTML: true,
      arrow: true,
      placement: 'top',
      offset: [0, -HEIGHT + 15],
    });

    dayTippies = tippy('.marker-content[data-tippy-content]', {});

    createSingleton(dayTippies, {
      delay: [150, 150],
      allowHTML: true,
      interactive: true,
      placement: 'bottom',
      appendTo: document.body,
    });
  };

  const destoryTippies = () => {
    if (tippies && !isEmpty(tippies)) {
      tippies.forEach((i) => {
        i.reference.dispatchEvent(new MouseEvent('mouseleave'));
        i.destroy();
      });
    }
    if (dayTippies && !isEmpty(dayTippies)) {
      dayTippies.forEach((i) => {
        i.reference.dispatchEvent(new MouseEvent('mouseleave'));
        i.destroy();
      });
    }
  };

  const modifyAxisLabels = () => {
    svg
      .append('g')
      .attr('class', 'y axis axis-labels')
      .call(yAxis)
      .attr('transform', `translate(-50, 0)`);
  };

  const renderComponentToString = useRenderComponentToString();

  const addMarkers = () => {
    const dayWidth = isCompareMode() ? xBandwidth * 2 : xBandwidth;
    const itemsWithHighlights = items.filter((i) => i.highlights?.length);
    svg
      .selectAll('.marker')
      .data(itemsWithHighlights)
      .enter()
      .append('svg:foreignObject')
      .attr('class', 'marker')
      .attr('x', (d) => xScale(formatDate(d.date)))
      .attr('y', HEIGHT + 30)
      .attr('width', dayWidth)
      .attr('height', 40)
      .append('xhtml:div')
      .attr('class', 'marker-content')
      .attr('data-tippy-content', (d, i) => {
        const markers = itemsWithHighlights[i].highlights;
        return renderComponentToString(<DayTooltip highlights={markers} />);
      })
      .html((d, i) => {
        const markers = itemsWithHighlights[i].highlights;
        return renderComponentToString(
          <DayDots highlights={markers} dayWidth={dayWidth} reportsView />,
        );
      });
  };

  useLayoutEffect(() => {
    setScaleBands();
    setAxis();
    initSvg();
    scaleDomains();
    appendAxis();
    modifyAxisLabels();
    addLoggedBar();
    addScheduledBar();
    addMarkers();
    addTippies();

    if (props.setScale) {
      props.setScale(xScale);
    }

    return destoryTippies;
  }, []); // eslint-disable-line

  return <BarChartContainer ref={chartRef} />;
}

export default BarChart;
