import { createSelector } from 'reselect';

import { Person } from '@float/types/person';
import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

import { selectDatesManager } from '../currentUser';
import { getWorkDaysCountInRange } from './getWorkDaysCountInRange';
import { selectIsWorkDayGetter } from './selectIsWorkDayGetter';

type TaskPayload = Pick<Task, 'start_date' | 'end_date'>;
type TimeoffPayload = Pick<Timeoff, 'start_date' | 'end_date' | 'timeoff_id'>;

export const selectEntityWorkDaysDurationGetter = createSelector(
  [selectDatesManager, selectIsWorkDayGetter],
  (dates, getIsWorkDay) =>
    (entity: TaskPayload | TimeoffPayload, person: Person) => {
      const excludeTimeoffId =
        'timeoff_id' in entity ? entity.timeoff_id : undefined;

      return getWorkDaysCountInRange({
        start_date: entity.start_date,
        end_date: entity.end_date,
        dates,
        getIsWorkDay,
        people: [person],
        excludeTimeoffId: excludeTimeoffId,
      });
    },
);
