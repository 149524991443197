import React from 'react';
import format from 'date-fns/format';
import styled from 'styled-components';

import { DatePicker } from '@float/common/components/DatePicker/DatePicker';
import {
  REPEAT_INTERVAL,
  REPEAT_INTERVAL_ORDER,
} from '@float/common/components/taskModals/constants';
import { getDateString } from '@float/common/lib/utils';
import { moment } from '@float/libs/moment';
import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect/DropdownSelect';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { REPEAT_KEYS } from '@float/web/components/schedule/task/components/InputAllocationRepeat/InputAllocationRepeat.constants';

import { calculateRepeatTimes } from '../helpers/timeReducers';

const RepeatControlsContainer = styled.div`
  ${Typography.Label12.R400};

  display: flex;
  flex-wrap: wrap;

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  margin-top: 8px;

  flex-basis: 100%;
`;

const ReadOnlyRepeatingName = styled.div`
  display: flex;
  align-items: center;

  min-height: 20px;
`;

const RepeatUntilContainer = styled.div`
  display: flex;
  align-items: baseline;

  padding: 2px 6px;

  // align all children vertically to the center
  // as per input (see below) styles
  > * {
    display: flex;
    align-items: center;

    min-height: 20px;
  }
`;

const RepeatEndDateContainer = styled.div`
  max-width: 75px;
  max-height: 20px;
  margin: auto 5px auto 6px;

  input {
    width: 74px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    min-height: 20px;
    color: ${(p) => p.theme.emphasisMedium};
  }
`;

let etmThis;

const setRepeatEnd = (repeatEnd) => {
  const newState = { ...etmThis.state, repeatEnd: repeatEnd.toDate() };
  newState.repeatTimes = calculateRepeatTimes(etmThis.props.dates, newState);
  etmThis.setState(newState);
};

const disableForRepeat = (date) => {
  if (REPEAT_KEYS.indexOf(etmThis.state.repeatState) > -1) {
    const day = date.format('YYYY-MM-DD');

    const ignoreTimeoff =
      etmThis.state.taskMode === 'timeoff' && etmThis.state.isFullDay
        ? { timeoff_id: etmThis.state.task.timeoff_id }
        : undefined;

    return !etmThis.props.isWorkDay(
      etmThis.state.peopleIds,
      day,
      ignoreTimeoff,
    );
  }
};

const getMinRepeatDate = () => {
  const endMoment = moment(etmThis.state.task.end_date).toDate();
  let minDate = null;
  if (etmThis.state.task.repeatOrig) {
    minDate = etmThis.state.task.repeatOrig.end_date;
  } else {
    minDate = etmThis.state.endDate || endMoment;
  }
  return moment(minDate).toDate();
};

const getNextRepeat = (startDate, mode) => {
  const { dates } = etmThis.props;

  const start = moment(startDate).format('YYYY-MM-DD');
  const repeatEnd = moment(startDate)
    .add(1, 'year')
    .add(1, 'day')
    .format('YYYY-MM-DD');

  return dates.getRepeatStarts(mode, start, repeatEnd)[0];
};

const setRepeatState = ({ value }) => {
  const newRepeatState = +value;
  const oldRepeatState = etmThis.state.repeatState;
  const isRepeating = !!newRepeatState;
  const wasRepeating = !!oldRepeatState;

  if (newRepeatState !== oldRepeatState) {
    const newState = {
      ...etmThis.state,
      isRepeating,
      repeatState: newRepeatState,
    };
    if (isRepeating && !wasRepeating) {
      const startDate = etmThis.state.endDate;
      newState.repeatEnd = new Date(
        getNextRepeat(startDate, newState.repeatState),
      );
    }
    newState.repeatTimes = calculateRepeatTimes(etmThis.props.dates, newState);
    etmThis.setState(newState, () => {
      if (newState.isRepeating) {
        etmThis.repeatEndDateRef.focusInput();
      } else if (etmThis.state.taskMode == 'task') {
        etmThis.taskRef.current?.focus();
      }
    });
  }
};

const setRepeatEndDateRef = (el) => {
  etmThis.repeatEndDateRef = el;
};

const getRepeatEndElem = () => {
  return (
    <RepeatEndDateContainer>
      <DatePicker
        ref={setRepeatEndDateRef}
        disableDayFn={disableForRepeat}
        value={moment(etmThis.state.repeatEnd)}
        minimumDate={getMinRepeatDate()}
        onChange={setRepeatEnd}
      />
    </RepeatEndDateContainer>
  );
};

const getAllocationRepeatIntervalReadOnlyControls = () => {
  const { repeatState, repeatTimes, integrationSynced, task } = etmThis.state;
  if (!repeatState) {
    if (integrationSynced && task.ext_calendar_recur_id) {
      return <RepeatControlsContainer>Repeat custom</RepeatControlsContainer>;
    }
    return null;
  }

  return (
    <RepeatControlsContainer>
      <ReadOnlyRepeatingName>
        {REPEAT_INTERVAL[repeatState]}
      </ReadOnlyRepeatingName>
      <RepeatUntilContainer>
        <span>
          until {getDateString(etmThis.state.repeatEnd)} ({repeatTimes} time
          {repeatTimes == 1 ? '' : 's'})
        </span>
      </RepeatUntilContainer>
    </RepeatControlsContainer>
  );
};

// TODO: Move this logic to common and reuse with mobile when the date-fns version will be aligned.
const weekNumberLabel = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
};
const repeatLabelDay = [1, 3, 4, 5];
const repeatLabelDayWithNumeral = [2, 6, 7, 8];
const getRepeatingLabel = (repeatState, startDate, dates) => {
  if (!repeatState) {
    return REPEAT_INTERVAL[repeatState];
  }
  const monthLabel = format(startDate, 'MMMM');
  const startStr = format(startDate, 'yyyy-MM-dd');
  const dayOfWeekLabel = format(startDate, 'EEEE');
  const weekOfMonthLabel = weekNumberLabel[`${dates.toWeekOfMonth(startStr)}`];
  const repeatDayLabel = repeatLabelDay.includes(repeatState)
    ? `on ${dayOfWeekLabel}`
    : repeatLabelDayWithNumeral.includes(repeatState)
      ? `on the ${weekOfMonthLabel} ${dayOfWeekLabel}`
      : `on the ${weekOfMonthLabel} ${dayOfWeekLabel} of ${monthLabel}`;
  return `${REPEAT_INTERVAL[repeatState]} ${repeatDayLabel}`;
};

export const getRepeatControls = (self) => {
  etmThis = self;
  if (etmThis.isReadOnly() || etmThis.state.integrationSyncLocked) {
    return getAllocationRepeatIntervalReadOnlyControls();
  }

  const options = REPEAT_INTERVAL_ORDER.map((value) => {
    return {
      value,
      label: REPEAT_INTERVAL[value],
      selectedLabel: getRepeatingLabel(
        value,
        etmThis.state.startDate,
        etmThis.props.dates,
      ),
    };
  });
  const { repeatTimes } = etmThis.state;
  return (
    <RepeatControlsContainer>
      <DropdownSelect
        appearance="text"
        value={etmThis.state.repeatState}
        options={options}
        onChange={setRepeatState}
      />
      {!etmThis.state.repeatState ? null : (
        <RepeatUntilContainer>
          <span>until</span>
          {getRepeatEndElem()}
          <span>
            ({repeatTimes} time{repeatTimes == 1 ? '' : 's'})
          </span>
        </RepeatUntilContainer>
      )}
    </RepeatControlsContainer>
  );
};
