import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled, { css } from 'styled-components';

import { InputWrapper } from '@float/ui/deprecated/Input/styles';

import * as Animation from '../Earhart/Animation';
import * as Colors from '../Earhart/Colors';
import * as Elevations from '../Earhart/Elevations';
import * as Scrollbar from '../Earhart/Scrollbar';
import * as Typography from '../Earhart/Typography';

export const PopoverContent = styled(PopoverPrimitive.Content)`
  z-index: 1000003;
  .ReactVirtualized__List {
    ${Scrollbar.Default};

    height: auto !important;
    max-height: ${(p: { height: number }) => p.height}px;

    &:not(:empty) {
      ${Elevations.E150};

      border-radius: 6px;

      background-color: ${Colors.FIN.Lt.Emphasis.White};

      padding: 6px;
    }
  }
`;

export const RowWrapper = styled.div``;

export const RowHeader = styled.div`
  display: flex;
  align-items: center;

  min-height: 44px;

  padding: 10px 12px;

  color: ${Colors.FIN.Lt.Emphasis.Low};

  box-sizing: border-box;
`;

export const RowHeaderGroupName = styled.div`
  ${Typography.Label13.SB600};
  color: ${Colors.TCore.Emp.Medium11};
  flex-grow: 1;
`;

export const RowHeaderAll = styled.div`
  ${Typography.Label13.SB600};

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const RowLabel = styled.div<{
  $size?: string;
  disabled?: boolean;
  hasDescription?: boolean;
  highlighted?: boolean;
  isSelected?: boolean;
}>`
  ${(p) =>
    p.$size === 'large' ? Typography.Label16.R400 : Typography.Label14.M500};

  position: relative;

  display: flex;
  align-items: center;

  min-height: 44px;

  width: 100%;

  padding: 10px 12px;

  border-radius: 6px;

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  background-color: rgba(0, 0, 0, 0);

  cursor: pointer;

  box-sizing: border-box;

  white-space: pre;

  svg {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
  }

  ${(p) => p.theme.ie11MinHeightFix};

  ${(p) =>
    p.highlighted &&
    !p.disabled &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.High};
      svg {
        --svg-icon-color: ${Colors.FIN.Lt.Emphasis.High};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;

      color: ${Colors.FIN.Lt.Emphasis.Disabled};

      svg {
        --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Disabled};
      }
    `}

  > * {
    position: relative;
    z-index: 1;
    // move into its own layer to prevent animation glitch on hover on Safari
    will-change: opacity;
  }

  &:after {
    content: '';

    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 6px;

    background-color: ${Colors.FIN.Lt.Surface.Surf4};

    transition-property: transform, background-color, opacity;
    transition-duration: ${Animation.Settings.Duration.Normal},
      ${Animation.Settings.Duration.Short}, ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    opacity: 0;

    transform: scale(0.95, 0.9);

    z-index: 0;

    ${(p) =>
      p.highlighted &&
      !p.disabled &&
      css`
        opacity: 1;
        transform: none;
      `}
  }

  ${(p) =>
    p.hasDescription &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Medium};

      ${p.isSelected &&
      css`
        &:after {
          opacity: 1;
          transform: none;
          background-color: Colors.Radix.Primary.Flay[4];
        }
      `}

      ${p.highlighted &&
      css`
        color: ${Colors.FIN.Lt.Emphasis.High};
        &:after {
          background-color: ${Colors.Radix.Primary.Flay[5]};
        }
      `}

      ${p.disabled &&
      css`
        color: ${Colors.FIN.Lt.Emphasis.Disabled};
        &:after {
          background-color: ${Colors.Radix.Primary.Flay[5]};
        }
      `}

      &:active {
        color: ${Colors.FIN.Lt.Emphasis.High};
        :after {
          background-color: ${Colors.Radix.Primary.Flay[7]};
        }
      }
    `}
`;

export const RowIcon = styled.div`
  margin-right: 10px;
  max-height: 24px;
`;

export const RowIconRight = styled.div`
  margin-left: auto;
  max-height: 24px;
`;

export const ClearSelectedOptions = styled.button`
  background: none;
  outline: none;
  border: 0;
  cursor: pointer;

  &.icon:hover,
  &.icon:focus {
    svg {
      --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Low};
    }
  }
`;

export const StyledInputWrapper = styled(InputWrapper)<{
  $color?: string;
  $disabled?: boolean;
  minWidth?: number;
}>`
  ${(p) =>
    p.$color &&
    !p.$disabled &&
    css`
      ${Typography.TextM.SB600};

      button.icon {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Primary};
        }
      }

      &:before {
        border-color: ${Colors.FIN.Lt.Emphasis.Primary};
      }

      .input-container {
        input {
          color: ${Colors.FIN.Lt.Emphasis.Primary};

          &::placeholder {
            color: ${Colors.FIN.Lt.Emphasis.Primary};
          }
        }
      }
    `};

  .input-container {
    input {
      ${(p: { minWidth?: number }) =>
        p.minWidth && `min-width: ${p.minWidth}px;`};

      // non-searchable input shouldn't select text while clicking
      // https://app.asana.com/0/1201258914201276/1201339764992765/f
      // searchable input should be copyable via context menu.
      // https://app.asana.com/0/1201161864415673/1201722785608353/f
      &:read-only {
        pointer-events: none;
      }
    }
  }
`;
