import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { userCanUpdateThemself } from '@float/common/lib/rights';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelector } from '@float/common/store';
import { CurrentUser } from '@float/types';
import EHIconCheckCircle from '@float/ui/deprecated/Earhart/Icons/Icon/IconCheckCircle';
import EHIconCloseCircle from '@float/ui/deprecated/Earhart/Icons/Icon/IconCloseCircle';
import EHIconCursor from '@float/ui/deprecated/Earhart/Icons/Icon/IconCursor';
import EHIconLink from '@float/ui/deprecated/Earhart/Icons/Icon/IconLink';
import EHIconLogged from '@float/ui/deprecated/Earhart/Icons/Icon/IconLogged';
import EHIconSplit from '@float/ui/deprecated/Earhart/Icons/Icon/IconSplit';
import { List } from '@float/ui/deprecated/Earhart/List';
import { Hotkeys } from '@float/ui/deprecated/Hotkeys/Hotkeys';
import { CloseCircle as IconCloseCircle } from '@float/ui/deprecated/Icons/icon-close';
import IconLink from '@float/ui/deprecated/Icons/icon-link';
import IconStopwatch from '@float/ui/deprecated/Icons/icon-stopwatch';

import IconComplete from './icon-ctx-complete';
import IconDefault from './icon-ctx-default';
import ControlIconSplit from './icon-ctx-split';
import { TaskEditMode } from './types';

export const TASK_EDIT_MODES = TaskEditMode;

const ITEM_HEIGHT = 40;
const ITEM_WIDTH = 165;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
`;

const ADD_EDIT_OPTION = {
  mode: TASK_EDIT_MODES.ADD_EDIT,
  element: 'View',
  hotkey: 'a',
  icon: <EHIconCursor size="22" />,
  controlIcon: <IconDefault size="24" />,
};

const LINK_OPTION = {
  mode: TASK_EDIT_MODES.LINK,
  element: 'Link',
  hotkey: 'l',
  icon: <EHIconLink size="22" />,
  controlIcon: <IconLink size="24" />,
};

const COMPLETE_OPTION = {
  mode: TASK_EDIT_MODES.COMPLETE,
  element: 'Complete',
  hotkey: 'c',
  icon: <EHIconCheckCircle size="22" />,
  controlIcon: <IconComplete size="24" />,
};

const SPLIT_OPTION = {
  mode: TASK_EDIT_MODES.SPLIT,
  element: 'Split',
  hotkey: 's',
  icon: <EHIconSplit size="22" />,
  controlIcon: <ControlIconSplit size="24" />,
};

const DELETE_OPTION = {
  mode: TASK_EDIT_MODES.DELETE,
  element: 'Delete',
  hotkey: 'd',
  icon: <EHIconCloseCircle size="22" />,
  controlIcon: <IconCloseCircle size="24" />,
};

const LOG_TIME_OPTION = {
  mode: TASK_EDIT_MODES.LOG_TIME,
  element: 'Log',
  hotkey: 'o',
  icon: <EHIconLogged size="22" />,
  controlIcon: <IconStopwatch size="24" />,
};

export const ALL_TASK_EDIT_MODE_OPTIONS = [
  ADD_EDIT_OPTION,
  COMPLETE_OPTION,
  SPLIT_OPTION,
  LINK_OPTION,
  LOG_TIME_OPTION,
  DELETE_OPTION,
];

const getContextOption = (user: CurrentUser, logTimeView: boolean) => {
  const options = [ADD_EDIT_OPTION];

  if (userCanUpdateThemself(user)) {
    options[0].element = 'Add/Edit';
    if (!logTimeView) {
      options.push(SPLIT_OPTION);
      options.push(LINK_OPTION);
      options.push(COMPLETE_OPTION);
    }
    options.push(DELETE_OPTION);
  }

  if (logTimeView) {
    options.splice(1, 0, LOG_TIME_OPTION);
  }

  return options;
};

function getContextMenuPosition(
  options: {
    mode: TaskEditMode;
    element: string;
    hotkey: string;
    icon: React.JSX.Element;
    controlIcon: React.JSX.Element;
  }[],
  position: { left: number; top: number; viaControls?: boolean },
  cornerHeight: number,
) {
  let { left, top, viaControls } = position;
  const numItems = options.length;

  const requiredHeight = numItems * ITEM_HEIGHT + 12 + 30;
  const requiredWidth = ITEM_WIDTH + 30;

  if (viaControls) {
    const offset = numItems > 3 ? 20 : 5;
    return {
      left: left - 4,
      top: top - requiredHeight - (numItems * 5 + offset),
    };
  }

  const availableHeight = window.innerHeight - top;
  if (requiredHeight > availableHeight) {
    top -= requiredHeight - availableHeight;
  }

  const availableWidth = window.innerWidth - left;
  if (requiredWidth > availableWidth) {
    left -= requiredWidth - availableWidth;
  }

  return { left, top: top - cornerHeight - 40 };
}

function ContextMenu(props: {
  position: { left: number; top: number; viaControls?: boolean } | null;
  cornerWidth: number;
  cornerHeight: number;
  hide: () => void;
  actionMode: TaskEditMode;
  setActionMode: (mode: TaskEditMode) => void;
  setPosition: (
    position: { left: number; top: number; viaControls?: boolean } | null,
  ) => void;
  logTimeView: boolean;
}) {
  const {
    position,
    cornerWidth,
    cornerHeight,
    hide,
    actionMode,
    setActionMode,
    setPosition,
    logTimeView,
  } = props;

  const user = useAppSelector(getUser);
  const options = getContextOption(user, logTimeView);
  const checkCanRenderMenu =
    position && position.left >= cornerWidth && position.top >= cornerHeight;

  useEffect(() => {
    // make sure the current mode is valid in the current timeline view
    if (!options.find((x) => x.mode === actionMode)) {
      setActionMode(options[0].mode);
    }
  }, [logTimeView]); //eslint-disable-line

  if (!checkCanRenderMenu) {
    return <ContextShortcuts options={options} setActionMode={setActionMode} />;
  }

  return (
    <>
      <Background
        onClick={hide}
        onContextMenu={(e) => {
          e.preventDefault();
          setPosition({
            left: e.clientX,
            top: e.clientY,
          });
        }}
      />
      <ContextMenuModal
        user={user}
        options={options}
        logTimeView={logTimeView}
        style={{
          ...getContextMenuPosition(options, position, cornerHeight),
          position: 'fixed',
          backgroundColor: 'white',
          transform: 'translateY(35%) translateX(4px)',
          filter: `drop-shadow(0px -7px 12px rgba(165, 159, 190, 0.06))
            drop-shadow(2px 9px 18px rgba(9, 30, 66, 0.1))
            drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.15))`,
        }}
        actionMode={actionMode}
        setActionMode={(mode: TaskEditMode) => {
          setActionMode(mode);
          setPosition(null);
        }}
      />
    </>
  );
}

export default ContextMenu;

const ContextShortcuts = ({
  options,
  setActionMode,
}: {
  options: { mode: TaskEditMode; hotkey: string }[];
  setActionMode: (mode: TaskEditMode) => void;
}) => {
  const keyMap = useMemo(
    () => ({
      Escape: () => setActionMode(TASK_EDIT_MODES.ADD_EDIT),
      ...options.reduce(
        (acc, opt) => {
          acc[opt.hotkey] = () => setActionMode(opt.mode);
          return acc;
        },
        {} as Record<string, () => void>,
      ),
    }),
    [options, setActionMode],
  );

  return <Hotkeys noOverride keyMap={keyMap} />;
};

export const ContextMenuModal = ({
  user,
  logTimeView,
  actionMode,
  setActionMode,
  style,
  options,
}: {
  user: CurrentUser;
  logTimeView: boolean;
  actionMode: TaskEditMode;
  setActionMode: (mode: TaskEditMode) => void;
  style: React.CSSProperties;
  options: { mode: TaskEditMode; hotkey: string }[];
}) => {
  const ctxOptions = options || getContextOption(user, logTimeView);

  const onClickOption = (data: { mode: TaskEditMode }) => {
    setActionMode(data.mode);
  };

  return (
    <>
      <ContextShortcuts options={ctxOptions} setActionMode={setActionMode} />
      <List
        // @ts-expect-error - List is not typed
        appearance="flue"
        options={ctxOptions}
        value={actionMode}
        optionValueProp="mode"
        onClick={onClickOption}
        style={style}
      />
    </>
  );
};
