import React, { MouseEvent, useRef } from 'react';

import { Candidate } from '@float/common/search/selectors/getSearchAutocompleteResults/types';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { VirtualFilterTypes } from '@float/types/view';
import { Checkbox } from '@float/ui/components/Checkbox';
import EHIconClose from '@float/ui/deprecated/Earhart/Icons/Icon/IconClose';
import { List } from '@float/ui/primitives/List';
import { FilterValue } from 'components/SearchFilters/components/FilterValue';

import * as styles from './styles.css';

export type ItemProps = {
  checked?: boolean;
  className?: string;
  highlighted?: boolean;
  isCheckboxDisabled: boolean;
  isCheckboxReadOnly: boolean;
  isDeletable?: boolean;
  isSelectable?: boolean;
  item: Candidate;
  onClickCheckBox?: () => void;
  onDelete?: (item: Candidate) => void;
  onSelect?: (value: string) => void;
};

export function Item(props: ItemProps) {
  const {
    checked = false,
    className,
    highlighted = false,
    isCheckboxDisabled,
    isCheckboxReadOnly,
    isDeletable,
    isSelectable,
    item,
    onClickCheckBox,
    onDelete,
    onSelect,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (onSelect) onSelect(item.val);
  };

  const handleCheckboxClick = (e: MouseEvent) => {
    prevent(e);

    ref.current?.blur();

    if (onClickCheckBox) onClickCheckBox();
  };

  return (
    <List.Item.Root
      className={className}
      ref={ref}
      onClick={handleClick}
      role="option"
      selected={highlighted}
    >
      <List.Item.Value className={styles.itemValue}>
        <FilterValue type={item.type as VirtualFilterTypes} val={item.val} />
      </List.Item.Value>

      {isSelectable && (
        <List.Item.Icon>
          <Checkbox
            checked={checked}
            disabled={isCheckboxDisabled}
            onClick={handleCheckboxClick}
            readOnly={isCheckboxReadOnly}
            size="small"
          />
        </List.Item.Icon>
      )}

      {isDeletable && (
        <List.Item.Icon>
          <EHIconClose
            onClick={(e: MouseEvent) => {
              prevent(e);
              if (onDelete) onDelete(item);
            }}
          />
        </List.Item.Icon>
      )}
    </List.Item.Root>
  );
}
