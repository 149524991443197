import React from 'react';
import { t, Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { List } from '@float/ui/deprecated/Earhart/List';
import * as Label14 from '@float/ui/deprecated/Earhart/Typography/Label14';
import IconSort from '@float/ui/deprecated/Icons/icon-sort';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

const SortCriteria = styled.div`
  color: ${(p) => p.theme.emphasisHigh};
  padding: 0 3px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  width: 28px;
  height: 28px;

  &:hover {
    background-color: ${(p) => p.theme.stroke2};
  }

  @media (max-width: 1010px) {
    .sort-label {
      display: none;
    }
  }
`;

const Sort = styled.div`
  display: flex;
  height: 28px;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.stroke3};
  overflow: hidden;
  flex-shrink: 0;

  ${(p) =>
    p.hasInsights &&
    css`
      @media (max-width: 1010px) {
        position: absolute;
        left: 18px;
      }
    `}
`;

const SortOptionOrder = styled.span`
  ${Label14.R400};
  display: inline-block;
  margin-left: 2px;
`;

const defaultDescending = ['avail', 'start_date', 'end_date'];

function getPeopleViewSortOptions(props) {
  return (
    [
      {
        key: 'name-asc',
        value: 'name',
        label: (
          <span>
            <Trans>
              Name <SortOptionOrder>A-Z</SortOptionOrder>
            </Trans>
          </span>
        ),
        order: 'asc',
      },
      {
        key: 'name-desc',
        value: 'name',
        label: (
          <span>
            <Trans>
              Name <SortOptionOrder>Z-A</SortOptionOrder>
            </Trans>
          </span>
        ),
        order: 'desc',
      },
      {
        key: 'avail-desc',
        value: 'avail',
        label: (
          <span>
            <Trans>
              Unscheduled <SortOptionOrder>High-low</SortOptionOrder>
            </Trans>
          </span>
        ),
        clickable: !props.isLogTimeView && props.hasInsights,
        order: 'desc',
      },
      {
        key: 'avail-asc',
        value: 'avail',
        label: (
          <span>
            <Trans>
              Unscheduled <SortOptionOrder>Low-high</SortOptionOrder>
            </Trans>
          </span>
        ),
        clickable: !props.isLogTimeView && props.hasInsights,
        order: 'asc',
      },
      {
        key: 'department-asc',
        value: 'department',
        label: (
          <span>
            <Trans>
              Department <SortOptionOrder>A-Z</SortOptionOrder>
            </Trans>
          </span>
        ),
        order: 'asc',
      },
      {
        key: 'department-desc',
        value: 'department',
        label: (
          <span>
            <Trans>
              Department <SortOptionOrder>Z-A</SortOptionOrder>
            </Trans>
          </span>
        ),
        order: 'desc',
      },
      {
        key: 'job_title-asc',
        value: 'job_title',
        label: (
          <span>
            <Trans>
              Role <SortOptionOrder>A-Z</SortOptionOrder>
            </Trans>
          </span>
        ),
        order: 'asc',
      },
      {
        key: 'job_title-desc',
        value: 'job_title',
        label: (
          <span>
            <Trans>
              Role <SortOptionOrder>Z-A</SortOptionOrder>
            </Trans>
          </span>
        ),
        order: 'desc',
      },
    ]
      // remove non-clickable elements
      // (need to do strick check to ignore undefined values)
      .filter((item) => item.clickable !== false)
  );
}

function getProjectViewSortOptions(props) {
  return [
    {
      key: 'project_name-asc',
      value: 'project_name',
      label: t`Name A-Z`,
      order: 'asc',
    },
    {
      key: 'project_name-desc',
      value: 'project_name',
      label: t`Name Z-A`,
      order: 'desc',
    },
    {
      key: 'client_name-asc',
      value: 'client_name',
      label: t`Client A-Z`,
      order: 'asc',
    },
    {
      key: 'client_name-desc',
      value: 'client_name',
      label: t`Client Z-A`,
      order: 'desc',
    },
    {
      key: 'age-desc',
      values: ['start_date', 'end_date'],
      label: t`Age Newest-oldest`,
      order: 'desc',
    },
    {
      key: 'age-asc',
      values: ['start_date', 'end_date'],
      label: t`Age Oldest-newest`,
      order: 'asc',
    },
  ];
}

const buildPeopleViewSortOptions = (props) => {
  const isActive = (value, order) => {
    const {
      sked_sort_by = 'name',
      sked_sort_dir,
      sked_custom_sort,
    } = props.prefs;

    if (value === 'custom') {
      return !!sked_custom_sort;
    }

    return (
      sked_sort_by === value &&
      sked_custom_sort != 1 &&
      (order ? order === sked_sort_dir : true)
    );
  };

  const buildSortOption = ({ label, value, key, clickable, order }) => {
    return {
      key,
      label,
      value,
      clickable,
      order,
      selected: isActive(value, order),
      onSelect: () => {
        if (value === 'custom') {
          return props.updatePrefs({
            sked_custom_sort: 1,
          });
        }

        return props.updatePrefs({
          sked_sort_by: value,
          sked_sort_dir:
            order || (defaultDescending.includes(value) ? 'desc' : 'asc'),
          sked_custom_sort: 0,
        });
      },
    };
  };

  const peopleViewSortOptions = getPeopleViewSortOptions(props);

  if (props.prefs.custom_priority != null) {
    peopleViewSortOptions.push({
      value: 'custom',
      label: 'Custom',
    });
  }

  return peopleViewSortOptions.map(buildSortOption);
};

const buildProjectViewSortOptions = (props) => {
  const isActive = (value, order) => {
    const {
      sked_projview_sort_by = 'project_name',
      sked_projview_sort_dir = 'asc',
    } = props.prefs;

    return (
      sked_projview_sort_by === value &&
      (order ? order === sked_projview_sort_dir : true)
    );
  };

  const buildSortOption = ({ value, values, label, key, order }) => {
    const possibleValues = values || [value];
    const selectedValue = possibleValues.find((x) => isActive(x, order));

    return {
      key,
      label,
      values,
      value: value || selectedValue,
      selected: Boolean(selectedValue),
      order,
      onSelect: () => {
        const newValue = value || values[0];
        props.updatePrefs({
          sked_projview_sort_by: newValue,
          sked_projview_sort_dir:
            order || (defaultDescending.includes(newValue) ? 'desc' : 'asc'),
        });
      },
    };
  };

  const sortOptions = getProjectViewSortOptions(props);

  return sortOptions.map(buildSortOption);
};

function buildSortByOptions(props) {
  return props.viewType === 'people'
    ? buildPeopleViewSortOptions(props)
    : buildProjectViewSortOptions(props);
}

function SortMenu({ options }) {
  const onClickOption = (data) => {
    data.onSelect();
  };

  return <List options={options} onClick={onClickOption} />;
}

function SortDropdown({ children, value, order = '', options }) {
  return (
    <Popover
      key={`sort-${value}-${order}`}
      distance={5}
      className="menu"
      placement="bottom-start"
      arrow={false}
      content={<SortMenu options={options} />}
    >
      {children}
    </Popover>
  );
}

function SortOptions(props) {
  const sortByOptions = buildSortByOptions(props);
  const activeSortBy =
    sortByOptions.find((x) => x.selected) || sortByOptions[0];

  return (
    <Sort hasInsights={props.hasInsights}>
      <SortDropdown
        value={activeSortBy.value}
        order={activeSortBy.order}
        options={sortByOptions}
      >
        <div>
          <TextTooltip content={t`Sort by`} placement="top" className="hint">
            <SortCriteria>
              <IconSort />
            </SortCriteria>
          </TextTooltip>
        </div>
      </SortDropdown>
    </Sort>
  );
}

export default SortOptions;
