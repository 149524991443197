import React, { StrictMode, useState } from 'react';
import { Plural, t, Trans } from '@lingui/macro';

import {
  REPEAT_INTERVAL,
  REPEAT_INTERVAL_ORDER,
  RepeatIntervalKey,
} from '@float/common/components/taskModals/constants';
import { RepeatState } from '@float/types/repeatState';
import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect/DropdownSelect';

import { InputAllocationRepeatEndDatePicker } from './components/InputAllocationRepeatEndDatePicker';
import { InputAllocationRepeatReadOnlyControls } from './components/InputAllocationRepeatReadOnlyControls';
import { getRepeatingLabel } from './InputAllocationRepeat.helpers';

import * as styles from './InputAllocationRepeat.css';

export type InputAllocationRepeatProps = {
  getIsDateDisabled: (date: Moment) => boolean;
  hasRecurringExternalCalendar: boolean;
  isIntegrationSyncLocked: boolean;
  isReadOnly: boolean;
  minRepeatDate: Date;
  onChangeRepeatEndDate: (repeatEnd: Moment) => void;
  onChangeRepeatInterval: (value: RepeatIntervalKey) => void;
  repeatState: RepeatState;
  repeatEndDate: Date | null;
  repeatTimes: number;
  startDate: Date;
};

type RepeatOption = {
  value: RepeatIntervalKey;
  label: string;
  selectedLabel: string;
};

export const InputAllocationRepeat = ({
  getIsDateDisabled,
  hasRecurringExternalCalendar,
  isIntegrationSyncLocked,
  isReadOnly,
  minRepeatDate,
  onChangeRepeatEndDate,
  onChangeRepeatInterval,
  repeatEndDate,
  repeatState,
  repeatTimes,
  startDate,
}: InputAllocationRepeatProps) => {
  const [hasRepeatIntervalChanged, setHasRepeatIntervalChanged] =
    useState(false);

  if (isReadOnly || isIntegrationSyncLocked) {
    return (
      <InputAllocationRepeatReadOnlyControls
        repeatState={repeatState}
        repeatTimes={repeatTimes}
        repeatEndDate={repeatEndDate}
        isIntegrationSyncLocked={isIntegrationSyncLocked}
        hasRecurringExternalCalendar={hasRecurringExternalCalendar}
      />
    );
  }

  const options: RepeatOption[] = REPEAT_INTERVAL_ORDER.map((value) => {
    return {
      value,
      label: REPEAT_INTERVAL[value],
      selectedLabel: getRepeatingLabel(value, startDate),
    };
  });

  const hasRepeatingOptionSelected = repeatState !== RepeatState.NoRepeat;

  const handleRepeatIntervalChange = (option: RepeatOption) => {
    setHasRepeatIntervalChanged(true);

    onChangeRepeatInterval(option.value);
  };

  return (
    <StrictMode>
      <div className={styles.repeatControlsContainer}>
        <DropdownSelect
          appearance="text"
          value={repeatState}
          options={options}
          onChange={handleRepeatIntervalChange}
          ariaLabel={t`Repeat dropdown toggle`}
        />
        {hasRepeatingOptionSelected && (
          <div className={styles.repeatUntilContainer}>
            <Trans>
              <span className={styles.repeatUntilItem}>until</span>
              <InputAllocationRepeatEndDatePicker
                getIsDateDisabled={getIsDateDisabled}
                minRepeatDate={minRepeatDate}
                onChange={onChangeRepeatEndDate}
                repeatEndDate={repeatEndDate}
                repeatState={repeatState}
                isAutofocusEnabled={hasRepeatIntervalChanged}
              />
              <span className={styles.repeatUntilItem}>
                (<Plural value={repeatTimes} one={'# time'} other="# times" />)
              </span>
            </Trans>
          </div>
        )}
      </div>
    </StrictMode>
  );
};
