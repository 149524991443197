import React, { MouseEvent } from 'react';
import { Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated/Button/Button';
import { NoResultsIllustration } from '@float/ui/deprecated/Illustrations/NoResultsIllustration';

import { ScheduleError } from '../../../components/ScheduleError';
import { ErrorSearchNoResults } from '../../../components/SearchFilters/components/ErrorSearchNoResults';

type ErrorNoProjectsProps = {
  canCreateProjects: boolean;
  hasSearchFiltersApplied?: boolean;
  onClickAddProject: (e: MouseEvent) => void;
};

const ErrorNoProjects = (props: ErrorNoProjectsProps) => {
  const { canCreateProjects, hasSearchFiltersApplied, onClickAddProject } =
    props;

  if (hasSearchFiltersApplied) return <ErrorSearchNoResults />;

  return (
    <ScheduleError>
      <h2>
        <Trans>There are no projects to schedule</Trans>
      </h2>

      <NoResultsIllustration />

      {canCreateProjects && (
        <Button onClick={onClickAddProject}>
          <Trans>Add a project</Trans>
        </Button>
      )}
    </ScheduleError>
  );
};

export { ErrorNoProjects };
