import React from 'react';
import { Trans } from '@lingui/macro';

import { Rights } from '@float/common/lib/acl';
import { CurrentUser, Person } from '@float/types';
import { Button } from '@float/ui/deprecated/Button/Button';
import {
  DropdownActions,
  pushToRight,
} from '@float/ui/deprecated/DropdownActions';
import { IconArrowDown, IconTrash } from '@float/ui/deprecated/Earhart/Icons';
import IconExport from '@float/ui/deprecated/Earhart/Icons/Icon/IconExport';
import IconImport from '@float/ui/deprecated/Earhart/Icons/Icon/IconImport';

export type PersonActionsMenuProps = {
  person: Person;
  currentUser: CurrentUser;
  ical?: string;
  onCopyICalLink: (url: string) => void;
  onActiveChange: (active: boolean) => void;
  onDelete: () => void;
};

export function PersonActionsMenu(props: PersonActionsMenuProps) {
  const { currentUser, person, ical } = props;

  const canDelete = Rights.canDeletePeople(currentUser, {
    entity: person,
  });

  return (
    <DropdownActions
      side="top"
      align="end"
      className={pushToRight}
      content={
        <>
          {ical && (
            <DropdownActions.Item onClick={() => props.onCopyICalLink(ical)}>
              <Trans>Copy iCal link</Trans>
            </DropdownActions.Item>
          )}
          <DropdownActions.Item
            onClick={() => props.onActiveChange(!person.active)}
          >
            {person.active ? (
              <>
                <Trans>Move to archive</Trans> <IconImport />
              </>
            ) : (
              <>
                <Trans>Move to active</Trans> <IconExport />
              </>
            )}
          </DropdownActions.Item>
          {canDelete && (
            <DropdownActions.Item appearance="danger" onClick={props.onDelete}>
              <Trans>Delete</Trans> <IconTrash />
            </DropdownActions.Item>
          )}
        </>
      }
    >
      <Button appearance="clear" iconRight={IconArrowDown}>
        <Trans>Actions</Trans>
      </Button>
    </DropdownActions>
  );
}
