import { flatMap, forEach, transform } from 'lodash';
import {
  filterLinkFormatter,
  pctFormatter,
  rangeBarFormatter,
} from 'reports/helpers/tableFormatters';

import { Role } from '@float/types';

import {
  OverTimeReportData,
  ReportTimeItemData,
  RoleChildRow,
} from '../../../types';
import { DualRangeBarFormatter } from '../DualRangBarFormatter';
import {
  getCombinedRangeBar,
  getLoggedRangeBar,
  getScheduledRangeBar,
} from '../table.helpers';
import { getPctHeader, getPctValue } from './percentages';
import {
  buildEmptyPersonChild,
  buildEmptyRole,
  buildRoleNameCell,
  NO_ROLE_ID,
} from './roles.helpers';
import { TeamCapacityTableContext, TeamCapacityTableDataRaw } from './types';

const getAllPersonRoleIds = (
  personId: number,
  ctx: TeamCapacityTableContext,
) => {
  const { people, roles } = ctx;
  const person = people[personId];
  const role = person && roles[person.role_id!];

  return [role?.id || NO_ROLE_ID];
};

export const groupPeopleByRole = (
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) => {
  const { people, roles } = ctx;
  return transform(
    raw.capacity,
    (acc: Record<number, RoleChildRow>, capacity: number, personId: string) => {
      const person = people[personId];
      // sometimes person is undefined (maybe happens when a person has just delated & the reports returns a stale capacity)
      // https://rollbar.com/float/fe-web-app/items/2551/
      if (!person) return;

      const role = person && roles[person.role_id!];
      const roleId = role?.id || NO_ROLE_ID;

      if (!acc[roleId!]) {
        acc[roleId!] = buildEmptyRole(role);
      }

      // Initialize this person as a row in their role
      acc[roleId].children.people[personId] = buildEmptyPersonChild(
        personId,
        capacity,
      );

      // Add their capacity to the Role's total capacity
      acc[roleId].capacity += capacity;
    },
  );
};

export const calculateOvertimeByRole = (
  ot: OverTimeReportData,
  personId: string,
  byRole: Record<string, RoleChildRow>,
  ctx: TeamCapacityTableContext,
) => {
  const personRoles = getAllPersonRoleIds(Number(personId), ctx);
  personRoles.forEach((roleId) => {
    const record = byRole[roleId];
    if (!record) return;

    // calculate aggregate overtimes by Role
    record.overtime += ot.total;
    record.logged.overtime += ot.logged;
    record.combined.overtime += ot.logged + ot.future;

    // calculate aggregate overtimes by People within each Role
    const child = byRole[roleId].children.people[personId];
    if (!child) return;
    child.overtime += ot.total;
    child.logged.overtime += ot.logged;
    child.combined.overtime += ot.logged + ot.future;
  });
};
export const calculateTotalsByRole = (
  item: ReportTimeItemData,
  byRole: Record<string, RoleChildRow>,
  ctx: TeamCapacityTableContext,
) => {
  const personRoles = getAllPersonRoleIds(item.person_id, ctx);
  personRoles.forEach((roleId, i) => {
    const record = byRole[roleId];
    if (!record) return;

    // We want to aggregate the children by Role
    const key = item.person_id;
    if (!key) {
      console.error(item);
      throw Error('Expected item.person_id');
    }

    const child = record.children.people[key];

    // Record refers to the parent row (Role).
    // Child refers to the child row (Person).
    // The "root" suffix is used to get either the task/logged time data
    const isTimeoff = item.type === 'timeoff';
    if (isTimeoff) {
      if (child) {
        child.timeoff += item.hours.scheduled;
      }
      record.timeoff += item.hours.scheduled;
      return;
    }

    const isTask = item.type === 'task';
    const isLoggedTime = item.type === 'logged_time';
    if (isTask || isLoggedTime) {
      if (isLoggedTime && item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      const childRoot = isTask ? child : child?.logged;
      if (child) {
        childRoot.scheduled += item.hours.scheduled;
        child.combined.scheduled += isTask
          ? item.hours.future
          : item.hours.scheduled;
      }

      const recordRoot = isTask ? record : record.logged;
      recordRoot.scheduled += item.hours.scheduled;
      record.combined.scheduled += isTask
        ? item.hours.future
        : item.hours.scheduled;
      record.future.scheduled += item.hours.future;

      if (item.billable) {
        if (child) {
          childRoot.billable += item.hours.scheduled;
          child.combined.billable += isTask
            ? item.hours.future
            : item.hours.scheduled;
        }

        recordRoot.billable += item.hours.scheduled;
        record.combined.billable += isTask
          ? item.hours.future
          : item.hours.scheduled;
        record.future.billable += item.hours.future;

        if (item.tentative) {
          record.tentative.billable += item.hours.scheduled;
          record.future.tentative.billable += isTask
            ? item.hours.future
            : item.hours.scheduled;
        }
      } else {
        if (child) {
          childRoot.nonbillable += item.hours.scheduled;
          child.combined.nonbillable += isTask
            ? item.hours.future
            : item.hours.scheduled;
        }

        recordRoot.nonbillable += item.hours.scheduled;
        record.combined.nonbillable += isTask
          ? item.hours.future
          : item.hours.scheduled;
        record.future.nonbillable += item.hours.future;

        if (item.tentative) {
          record.tentative.nonbillable += item.hours.scheduled;
          record.future.tentative.nonbillable += isTask
            ? item.hours.future
            : item.hours.scheduled;
        }
      }
    }
  });
};

function breakdown(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const byRole = groupPeopleByRole(ctx, raw);

  forEach(raw.overtime, (ot, personId) =>
    calculateOvertimeByRole(ot, personId, byRole, ctx),
  );
  forEach(raw.totals, (item) => calculateTotalsByRole(item, byRole, ctx));

  return byRole;
}

export function getScheduledTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, roles } = ctx;

  const headers = [
    {
      label: 'Role',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Scheduled', width: 100 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 100 },
    { label: 'Overtime', width: 100 },
    {
      // @ts-expect-error There is a type mismatch between getPctHeader and TeamCapacityTableContext
      label: getPctHeader(ctx, 'scheduled'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };
  const byRole = breakdown(ctx, raw);

  const buildRoleRow = (o: RoleChildRow, roleId: string) => {
    const role = roles[roleId];
    const peopleRows = Object.values(o.children.people);

    return {
      id: roleId,
      data: [
        buildRoleNameCell(role),
        o.capacity,
        o.scheduled,
        o.billable,
        o.nonbillable,
        o.timeoff,
        o.overtime,
        // @ts-expect-error There is a type mismatch between getPctValue and RoleChildRow
        getPctValue(ctx, o, 'scheduled'),
        getScheduledRangeBar(o),
      ],
      children: [
        ...peopleRows.map((c) => {
          const person = people[c.person_id];
          return {
            data: [
              person.name,
              c.capacity,
              c.scheduled,
              c.billable,
              c.nonbillable,
              c.timeoff,
              c.overtime,
              // @ts-expect-error There is a type mismatch between getPctValue and RoleChildRow
              getPctValue(ctx, c, 'scheduled'),
              '',
            ],
          };
        }),
      ],
    };
  };

  const rows = flatMap(byRole, (o, roleId) => [buildRoleRow(o, roleId)]);

  return { headers, rows };
}
export function getLoggedTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, roles } = ctx;

  const headers = [
    {
      label: 'Role',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Logged', width: 100 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 100 },
    { label: 'Overtime', width: 100 },
    {
      // @ts-expect-error There is a type mismatch between getPctHeader and TeamCapacityTableContext
      label: getPctHeader(ctx, 'logged'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byRole = breakdown(ctx, raw);

  const buildRoleRow = (o: RoleChildRow, roleId?: string) => {
    const role = roleId && roles[roleId!];
    const peopleRows = Object.values(o.children.people);

    return {
      id: roleId,
      data: [
        buildRoleNameCell(role as Role),
        o.capacity,
        o.logged.scheduled,
        o.logged.billable,
        o.logged.nonbillable,
        o.timeoff,
        o.logged.overtime,
        // @ts-expect-error There is a type mismatch between getPctValue and RoleChildRow
        getPctValue(ctx, o, 'logged'),
        getLoggedRangeBar(o),
      ],
      children: [
        ...peopleRows.map((c) => {
          const person = people[c.person_id];
          return {
            data: [
              person.name,
              c.capacity,
              c.logged.scheduled,
              c.logged.billable,
              c.logged.nonbillable,
              c.timeoff,
              c.logged.overtime,
              // @ts-expect-error There is a type mismatch between getPctValue and RoleChildRow
              getPctValue(ctx, c, 'logged'),
              '',
            ],
          };
        }),
      ],
    };
  };

  const rows = flatMap(byRole, (o, roleId) => [buildRoleRow(o, roleId)]);

  return { headers, rows };
}
export function getCompareTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, roles } = ctx;

  const headers = [
    {
      label: 'Role',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Scheduled', width: 200 },
    { label: 'Logged', width: 200 },
    { label: 'Difference', width: 200 },
    {
      label: '',
      width: 135,
      formatter: DualRangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byRole = breakdown(ctx, raw);

  const buildRoleRow = (o: RoleChildRow, roleId: string) => {
    const role = roles[roleId];
    const peopleRows = Object.values(o.children.people);
    return {
      id: roleId,
      data: [
        buildRoleNameCell(role),
        o.capacity,
        o.scheduled,
        o.logged.scheduled,
        o.scheduled - o.logged.scheduled,
        [getLoggedRangeBar(o), getScheduledRangeBar(o)],
      ],
      children: [
        ...peopleRows.map((c) => {
          const person = people[c.person_id];
          return {
            data: [
              person.name,
              c.capacity,
              c.scheduled,
              c.logged.scheduled,
              c.scheduled - c.logged.scheduled,
              '',
            ],
          };
        }),
      ],
    };
  };

  const rows = flatMap(byRole, (o, roleId) => [buildRoleRow(o, roleId)]);

  return { headers, rows };
}
export function getCombinedTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, roles } = ctx;

  const headers = [
    {
      label: 'Role',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Past logged + Future sched.', width: 200 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 100 },
    { label: 'Overtime', width: 100 },
    {
      // @ts-expect-error There is a type mismatch between getPctHeader and TeamCapacityTableContext
      label: getPctHeader(ctx, 'combined'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byRole = breakdown(ctx, raw);

  const buildRoleRow = (o: RoleChildRow, roleId: string) => {
    const role = roles[roleId];
    const peopleRows = Object.values(o.children.people);

    return {
      id: roleId,
      data: [
        buildRoleNameCell(role),
        o.capacity,
        o.combined.scheduled,
        o.combined.billable,
        o.combined.nonbillable,
        o.timeoff,
        o.combined.overtime,
        // @ts-expect-error There is a type mismatch between getPctValue and RoleChildRow
        getPctValue(ctx, o, 'combined'),
        getCombinedRangeBar(o),
      ],
      children: [
        ...peopleRows.map((c) => {
          const person = people[c.person_id];
          return {
            data: [
              person.name,
              c.capacity,
              c.combined.scheduled,
              c.combined.billable,
              c.combined.nonbillable,
              c.timeoff,
              c.combined.overtime,
              // @ts-expect-error There is a type mismatch between getPctValue and RoleChildRow
              getPctValue(ctx, c, 'combined'),
              '',
            ],
          };
        }),
      ],
    };
  };

  const rows = flatMap(byRole, (o, roleId) => [buildRoleRow(o, roleId)]);

  return { headers, rows };
}
