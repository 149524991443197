import React from 'react';
import { t } from '@lingui/macro';

import { FilterToken as FilterTokenType } from '@float/types';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';
import { FilterButton } from '@float/web/components/SearchFilters/FilterButton';
import {
  FilterToken,
  FilterTokenProps,
} from '@float/web/components/SearchFilters/FilterToken';

import * as styles from './styles.css';

export type MoreFiltersProps = {
  filters: FilterTokenType[];
  visibleCount: number;
  open?: boolean;
  disabled?: boolean;
  forceNoOrBetweenTypes?: boolean;
  onClick?: () => void;
  onOpenChange?: (open: boolean) => void;
  onRemove?: (filter: FilterTokenType) => void;
  onFilterClick?: FilterTokenProps['onClick'];
  onChange?: FilterTokenProps['onChange'];
};

export const MoreFilters = React.forwardRef<HTMLDivElement, MoreFiltersProps>(
  (
    {
      filters,
      open,
      onOpenChange,
      visibleCount,
      disabled,
      forceNoOrBetweenTypes,
      onChange,
      onClick,
      onFilterClick,
      onRemove,
    },
    ref,
  ) => {
    if (filters.length <= visibleCount) return null;

    const hiddenFilters = filters.slice(visibleCount);

    return (
      <Popover
        // @ts-expect-error even if it is not defined in the types
        // it actually works...
        placement="bottom-start"
        arrow={false}
        offset={[0, 0]}
        open={open}
        onOpenChange={onOpenChange}
        ref={ref}
        className={styles.moreFilters}
        content={
          <>
            {hiddenFilters.map((filter, i) => {
              const position = i + visibleCount;

              return (
                <div key={i} className={styles.moreFiltersRow}>
                  <FilterToken
                    filter={filter}
                    index={position}
                    onClick={onFilterClick}
                    onChange={onChange}
                    onRemove={onRemove ? () => onRemove(filter) : undefined}
                    wrapValues
                    disabled={disabled}
                    prevFilter={filters[position - 1]}
                    forceNoOrBetweenTypes={forceNoOrBetweenTypes}
                  />
                </div>
              );
            })}
          </>
        }
      >
        <FilterButton
          onClick={onClick}
          aria-label={t`More filters`}
          variant="default"
        >
          +{hiddenFilters.length}
        </FilterButton>
      </Popover>
    );
  },
);
