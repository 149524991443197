import {
  isEligibleLinkSource as getIsEligibleLinkSource,
  isEligibleLinkTarget as getIsEligibleLinkTarget,
} from '@float/common/components/Schedule/actions/handleLinkInteraction';
import { ScheduleActions } from '@float/common/components/Schedule/Cell/MainCell/types';
import { TASK_EDIT_MODES } from '@float/common/components/Schedule/util/ContextMenu';
import { getPhaseById } from '@float/common/selectors/phases';
import { getProjectById } from '@float/common/selectors/projects';
import { getSerenaReduxData } from '@float/common/selectors/serena';
import { useAppSelectorStrict } from '@float/common/store';
import { DEFAULT_COLOR } from '@float/constants/colors';
import { CellItem } from '@float/types/cells';

import { CellStylesConfig } from '../hooks/useCellStyles';

export type GetCellStylesConfigProps = {
  item: CellItem<'timeoff'> | CellItem<'task'> | CellItem<'loggedTime'>;
  actionMode: string;
  getOverrideCursor: ScheduleActions['getOverrideCursor'];
  isItemSelected: ScheduleActions['isItemSelected'];
  linkInfo?: ScheduleActions['linkInfo'];
  isPrintMode?: boolean;
};

export function useGetCellStylesConfig(props: GetCellStylesConfigProps) {
  const {
    item,
    actionMode,
    getOverrideCursor,
    isItemSelected,
    linkInfo,
    isPrintMode,
  } = props;

  const color = useAppSelectorStrict((state) => {
    if (item.type === 'timeoff') {
      return DEFAULT_COLOR.toLowerCase();
    }

    const phase = getPhaseById(state, item.entity.phase_id ?? undefined);
    const project = getProjectById(state, item.entity.project_id);

    return (phase?.color || project?.color || DEFAULT_COLOR).toLowerCase();
  });

  const timeOffType = useAppSelectorStrict((state) => {
    if (item.type !== 'timeoff') {
      return undefined;
    }

    return state.timeoffTypes.timeoffTypes[item.entity.timeoff_type_id];
  });

  const isLinking = Boolean(linkInfo?.base);
  const isLinkOrigin = linkInfo?.base?.entityId === item.entityId;

  const isEligibleLinkSource: boolean | undefined = useAppSelectorStrict(
    (state) => {
      if (!isLinking && actionMode === TASK_EDIT_MODES.LINK) {
        return getIsEligibleLinkSource(getSerenaReduxData(state), item);
      }

      return undefined;
    },
  );

  let isEligibleLinkTarget: boolean | undefined = undefined;

  if (isLinking && !isLinkOrigin) {
    isEligibleLinkTarget = Boolean(getIsEligibleLinkTarget(linkInfo, item)[0]);
  }

  const config = {
    actionMode,
    cursor: getOverrideCursor(item),
    isLinking,
    isLinkOrigin,
    isPrintMode: Boolean(isPrintMode),
    isSelected: isItemSelected(item),
    isSelectedAsLink: linkInfo?.base?.entityId === item.entityId,
    timeOffType,
    isEligibleLinkSource,
    isEligibleLinkTarget,
  } satisfies CellStylesConfig;

  return {
    color,
    config,
  };
}
