import React from 'react';
import { isNil } from 'lodash';
import styled from 'styled-components';

import { getProjectBudgetStatusText } from '@float/common/lib/budget';
import { BudgetPriority } from '@float/types/project';
import IconWarningCircle from '@float/ui/deprecated/Icons/icon-warning-circle';

const StyledBudgetWrapper = styled.div`
  display: flex;

  &,
  & a {
    color: ${(p) =>
      p.overBudget ? p.theme.red : p.theme[p.color || 'emphasisMedium']};
  }
`;

export const shouldShowBudgetLine = (project) => {
  const { canSeeBudget, budget_type } = project || {};
  return budget_type && canSeeBudget;
};

export default class ProjectCardBudgetLine extends React.Component {
  render() {
    const {
      phase,
      hasPhase,
      project: { missingRate, canSeeBudget, budget_type, budget_priority },
      color,
    } = this.props;
    let {
      project: { budget_remaining, budget_total },
    } = this.props;

    if (!canSeeBudget) return null;

    if (budget_priority === BudgetPriority.Phase && !phase?.budget_total) {
      // We want to suppress the budget line if there isn't one set
      return null;
    }

    budget_remaining = isNil(budget_remaining)
      ? budget_total
      : budget_remaining;

    const overBudget = [1, 2].includes(budget_type)
      ? budget_remaining < 0
      : false;

    const linkText =
      budget_type === 0
        ? 'No budget'
        : getProjectBudgetStatusText({
            canSeeBudget,
            budget_type,
            budget_remaining,
            budget_total,
          });

    let prefixText = 'Budget: ';
    if (hasPhase) {
      if (budget_priority === BudgetPriority.Phase) {
        prefixText = 'Phase: ';
      } else {
        prefixText = 'Project: ';
      }
    }

    return (
      <StyledBudgetWrapper overBudget={overBudget} color={color}>
        {budget_type !== 0 && (
          <span style={{ marginRight: 3 }}>{prefixText}</span>
        )}
        <span>{linkText}</span>
        {missingRate && <IconWarningCircle style={{ marginLeft: 5 }} />}
      </StyledBudgetWrapper>
    );
  }
}
