import React from 'react';
import { Trans } from '@lingui/macro';
import { compact } from 'lodash';
import styled from 'styled-components';

import { Spacer } from '@float/ui/deprecated/Layout/Layout';

import { StatusFilter } from './StatusFilter';
import { TeamMode } from './TeamMode';

import * as styles from './styles.css';

const TabBarFiltersContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export default function TabBarFilters(props) {
  const taskItems = ['Confirmed', 'Tentative', 'Completed'];
  const timeOffItems = compact([
    props.timeoffApprovalsEnabled ? 'Approved' : 'Confirmed',
    'Tentative',
    props.timeoffApprovalsEnabled && 'Declined',
  ]);

  return (
    <TabBarFiltersContainer>
      {!props.hideTeamModeFilters && (
        <>
          <TeamMode {...props} />
          <Spacer size={8} />
        </>
      )}
      {props.isDraftProjectReport ? (
        <span className={styles.draftStatusLabel}>
          <Trans>All allocations are in Draft</Trans>
        </span>
      ) : (
        <>
          <StatusFilter
            {...props}
            items={taskItems}
            outerLabel="Allocations"
            innerLabel="Status:"
            status={props.taskStatus}
          />
          <Spacer size={8} />
          <StatusFilter
            {...props}
            items={timeOffItems}
            outerLabel="Time off"
            innerLabel="Status:"
            status={props.timeOffStatus}
          />
        </>
      )}
    </TabBarFiltersContainer>
  );
}
