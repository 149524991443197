import React from 'react';
import { sortBy } from 'lodash';
import ProjColor from 'manage/projects-v2/ProjectColor';
import styled from 'styled-components';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { ButtonAction } from '@float/ui/components/ButtonAction';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { Row } from '@float/ui/deprecated/Layout/Layout';
import {
  ModalContentPlaceholder,
  ModalTopGreySection,
} from '@float/ui/deprecated/Modal';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { addToAllProjects } from './modifiers/addToAllProjects';

const ProjectItem = styled(Row)`
  ${Typography.TextM.R400};

  align-items: center;
  flex: 0;

  width: 100%;
  min-height: 64px;

  color: ${Colors.FIN.Lt.Emphasis.High};

  border-top: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};

  padding: 4px 0;

  &:last-child {
    border-bottom: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};
  }
`;

const ProjectColor = styled(ProjColor)`
  position: relative;
  top: -12px;
  margin-right: 10px;
`;

const ProjectAndClientName = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  flex-grow: 1;
`;

const ProjectName = styled.div`
  ${Typography.TextL.M500};

  line-height: 1.4;
`;

const ClientName = styled.div``;

let self;

function getProjectOptions() {
  const groupedOptions = [];
  const { project_ids: assignedProjectIds = [] } = self.state.form;
  self.props.projectOptions.forEach((group) => {
    const options = [...group.options].filter(
      (p) => !assignedProjectIds.includes(+p.value),
    );
    if (options.length) {
      groupedOptions.push({ ...group, options });
    }
  });
  groupedOptions.unshift({
    name: '',
    value: -1,
    options: [
      {
        label: 'All projects',
      },
    ],
  });

  return groupedOptions;
}

export const getAssignedProjects = (person, projects) => {
  const project_ids = person.project_ids || [];

  const sortOrder = [
    (p) => p.client_name.toLowerCase(),
    (p) => p.project_name.toLowerCase(),
  ];

  return sortBy(
    project_ids.flatMap((id) => {
      const project = projects[id];
      if (!project) {
        return [];
      }

      return [
        {
          project_id: project.project_id,
          project_name: project.project_name,
          color: project.color,
          client_name: project.client_name || 'No Client',
        },
      ];
    }),
    sortOrder,
  );
};

function onProjectsChange(project_ids) {
  self.setState({
    form: { ...self.state.form, project_ids },
  });
}

function addToProject({ value, groupValue }) {
  if (groupValue === -1) {
    // User chose to add ALL projects
    onProjectsChange(
      addToAllProjects(self.state.form.project_ids, self.props.projectOptions),
    );
    return;
  }
  const project_ids = [...self.state.form.project_ids, +value];
  onProjectsChange(project_ids);
}

function addToGroupOfProjects(groupOptions) {
  const project_ids = [
    ...self.state.form.project_ids,
    ...groupOptions.map((go) => +go.value),
  ];
  onProjectsChange(project_ids);
}

function removeFromProject(id) {
  const project_ids = self.state.form.project_ids.filter((x) => x !== id);
  onProjectsChange(project_ids);
}

const onRemoveClick = (project, onConfirm) => (e) => {
  prevent(e);

  // show confirm dialog only if project being removed was not just added
  const { project_ids: savedProjectIds = [] } = self.props.modalSettings.person;
  if (savedProjectIds.includes(project.project_id)) {
    self.props.confirmDelete({
      title: 'Remove from project',
      message: (
        <>
          <p>
            <span>You're about to remove this person from </span>
            <strong>{project.project_name}</strong>.
          </p>
          <p>
            All scheduled and logged tasks allocated to this person for this
            project will be deleted.
          </p>
        </>
      ),
      onDelete: () => onConfirm(project.project_id),
    });
    return;
  }

  onConfirm(project.project_id);
};

export const ProjectsList = ({ projects, onRemove }) => {
  const readOnly = typeof onRemove !== 'function';
  return (
    <ModalContentPlaceholder isCentered={!projects.length}>
      {!projects.length && 'There are no projects assigned to this person.'}
      {projects.map((project) => (
        <ProjectItem key={project.project_id}>
          <ProjectColor color={project.color} />
          <ProjectAndClientName>
            <ProjectName>{project.project_name}</ProjectName>
            <ClientName>{project.client_name}</ClientName>
          </ProjectAndClientName>
          {!readOnly && (
            <ButtonAction
              icon={Icons.IconClose}
              onClick={onRemoveClick(project, onRemove)}
            />
          )}
        </ProjectItem>
      ))}
    </ModalContentPlaceholder>
  );
};

export default function renderPersonProjects(that) {
  self = that;

  const personProjectsOptions = getProjectOptions();

  const assignedProjects = getAssignedProjects(
    self.state.form,
    self.props.projects,
  );

  return (
    <>
      <ModalTopGreySection>
        <VirtualSelect
          label="Assign a project"
          placeholder="Type and select projects"
          visibleItems={6}
          noMargin
          autoFocus
          keepFocusAfterSelect
          groupedOptions={personProjectsOptions}
          onChange={addToProject}
          onSelectGroupAll={addToGroupOfProjects}
        />
      </ModalTopGreySection>
      <ProjectsList projects={assignedProjects} onRemove={removeFromProject} />
    </>
  );
}
