import {
  getCanUpdateEntity,
  getIsEntityLoggable,
  milestoneEditable,
} from '@float/common/lib/rights';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getUser } from '@float/common/selectors/currentUser';
import { getPeopleMap } from '@float/common/selectors/people';
import { getPhasesMapRaw } from '@float/common/selectors/phases';
import { getProjectsMap } from '@float/common/selectors/projects';
import { selectEnhancedProjectById } from '@float/common/selectors/projects/selectEnhancedProjectById';
import { CellItem } from '@float/types';

import { isItemEntityArchived } from '../Cell/MainCell/Item/box/utils/isItemEntityArchived';

export function getIsItemEditable(
  state: ReduxStateStrict,
  logTimeView: boolean,
  item: CellItem,
) {
  const isSidebarItem = 'isSidebarItem' in item && item.isSidebarItem;
  const user = getUser(state);

  if (isSidebarItem || item.type === 'timeRange') {
    return true;
  }

  if (item.type === 'milestone') {
    const project = selectEnhancedProjectById(state, item.entity.project_id);
    return Boolean(project && milestoneEditable(project, user));
  }

  if (logTimeView && item.type === 'timeoff') return false;
  if (item.type === 'loggedTime') {
    if (
      item.isInLockPeriod &&
      !getIsEntityLoggable(item.entity, {
        phases: getPhasesMapRaw(state),
        user,
        projects: getProjectsMap(state),
        isOnLockedDay: true,
      })
    ) {
      return false;
    }

    if (user.people_id == item.entity.people_id) return true;
    return getCanUpdateEntity(item.entity, {
      user,
      phases: getPhasesMapRaw(state),
      projects: getProjectsMap(state),
      timeoffTypes: state.timeoffTypes.timeoffTypes,
      people: getPeopleMap(state),
    });
  }

  if (!('entity' in item)) return false;

  if (
    isItemEntityArchived(item.entity, {
      phases: getPhasesMapRaw(state),
      projects: getProjectsMap(state),
    })
  )
    return false;

  const temporaryId =
    'temporaryId' in item.entity ? item.entity.temporaryId : undefined;
  const integrationStatus =
    'integration_status' in item.entity
      ? item.entity.integration_status
      : undefined;

  return (
    !temporaryId &&
    !integrationStatus &&
    getCanUpdateEntity(item.entity, {
      user,
      phases: getPhasesMapRaw(state),
      projects: getProjectsMap(state),
      timeoffTypes: state.timeoffTypes.timeoffTypes,
      people: getPeopleMap(state),
    })
  );
}
