import React, { HTMLAttributes, StrictMode, useState } from 'react';
import { Trans } from '@lingui/macro';
import cn from 'classnames';

import { Button } from '@float/ui/deprecated/Button/Button';

import ImportPeopleIllustration from './assets/ImportPeopleIllustration.svg';

import * as styles from './styles.css';

export type ImportPeoplePromptProps = {
  onClose?: () => void;
} & HTMLAttributes<HTMLDivElement>;

export const ImportPeoplePrompt = (props: ImportPeoplePromptProps) => {
  const { className, onClick, onClose } = props;

  const [closed, setClosed] = useState(false);

  const onDismiss = () => {
    setClosed(true);
    if (onClose) onClose();
  };

  if (closed) {
    return null;
  }

  return (
    <StrictMode>
      <div className={cn(styles.importPeoplePrompt, className)}>
        <img
          className={styles.svg}
          src={ImportPeopleIllustration}
          width={212}
        />

        <Button className={styles.button} onClick={onClick}>
          <Trans>Add team members</Trans>
        </Button>

        <Button
          className={styles.buttonCancel}
          appearance="clear"
          onClick={onDismiss}
        >
          <Trans>No, thanks</Trans>
        </Button>
      </div>
    </StrictMode>
  );
};
