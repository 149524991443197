import React, { useEffect } from 'react';
import { forEach } from 'lodash';
import { getSearchFilteredActivePeople, getUser } from 'selectors';

import Avatar from '@float/common/components/Avatar/Avatar';
import { Rights } from '@float/common/lib/acl';
import { useAppSelectorStrict } from '@float/common/store';
import { Person } from '@float/types/person';
import IconAllViewable from '@float/ui/deprecated/Icons/icon-all-viewable';

import {
  EditTaskModalEntityLengthGetter,
  EditTaskModalPartialStateForCallbacks,
} from '../../schedule/task/EditTaskModal.types';
import { calculateWorkDays, getTotalHours } from '../helpers/timeReducers';

export const ALL_VIEWABLE_VALUE = '*';

type AllViewableOption = { people_id: '*'; name: string };

const getPersonAvatar = (person: Person | AllViewableOption) => {
  if (person.people_id === ALL_VIEWABLE_VALUE) {
    return <IconAllViewable />;
  }

  return (
    <Avatar
      name={person.name}
      imageUrl={person.avatar_file}
      readOnly
      size="xs"
    />
  );
};

export const mapPersonToOption = (person: Person) => ({
  label: person.name,
  value: person.people_id,
  icon: getPersonAvatar(person),
});

type AssigneeOption = {
  label: string;
  value: number;
  icon: React.ReactNode;
};

export const useAssignablePeopleOptions = ({
  taskMode,
}: {
  taskMode: string;
}) => {
  const [people, setPeople] = React.useState<AssigneeOption[]>([]);

  const user = useAppSelectorStrict(getUser);
  const searchActivePeople = useAppSelectorStrict(
    getSearchFilteredActivePeople,
  );

  useEffect(() => {
    const assigneeOptions: AssigneeOption[] = [];

    forEach(searchActivePeople, (person) => {
      let canAssignToPerson = false;

      if (taskMode === 'task') {
        canAssignToPerson = Rights.canCreateTaskForPeople(user, [person]);
      } else if (taskMode === 'timeoff') {
        canAssignToPerson = Rights.canCreateTimeoffForPeople(user, [person]);
      } else if (taskMode === 'status') {
        canAssignToPerson = Rights.canCreateStatus(user, { person });
      }

      if (canAssignToPerson) {
        const option = mapPersonToOption(person);
        assigneeOptions.push(option);
      }
    });

    assigneeOptions.sort((a, b) => {
      const aName = String(a.label).toLowerCase();
      const bName = String(b.label).toLowerCase();
      return aName.localeCompare(bName);
    });

    setPeople(assigneeOptions);
  }, [user, searchActivePeople, taskMode]);

  return people;
};

export function getLengthAndTotalHours(
  calcEntityLength: EditTaskModalEntityLengthGetter,
  prevState: EditTaskModalPartialStateForCallbacks,
  peopleIds: number[],
) {
  const { length } = calculateWorkDays(calcEntityLength, {
    ...prevState,
    peopleIds,
  });
  const totalHours = getTotalHours(prevState.hoursPd, length);

  return { length, totalHours };
}
