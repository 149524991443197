import React from 'react';
import styled from 'styled-components';

import { Button } from '@float/ui/deprecated/Button/Button';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import { DownSmall as IconDownSmall } from '@float/ui/deprecated/Icons/iconChevronDownSmall';
import { Label } from '@float/ui/deprecated/Label';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';

const GalleryStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({ twoColumn }) => (twoColumn ? '300px' : '100px')};
`;

const GalleryItem = styled.div`
  width: ${({ twoColumn }) => (twoColumn ? '50%' : '100%')};
  margin: 0px;
`;

function Gallery({ children, twoColumn, index }) {
  return (
    <GalleryStyled twoColumn={twoColumn}>
      {children.map((child, i) => (
        <GalleryItem key={`gallery-item-${index}-${i}`} twoColumn={twoColumn}>
          {' '}
          {child}{' '}
        </GalleryItem>
      ))}
    </GalleryStyled>
  );
}

const taskLabels = ['Tasks', 'Allocations'];

export class ReportsCheckboxTooltip extends React.Component {
  toggleLabel(label) {
    const newCheckedState = {
      ...this.props.checked,
      [label]: !this.props.checked[label],
    };
    const labels = Object.keys(newCheckedState);
    const selected = labels.filter((key) => newCheckedState[key]);
    // Must have item checked
    if (!selected.length) {
      return;
    }
    if (this.props.outerLabel === 'People') {
      // Must have Active or Archived checked
      if (!selected.includes('Active') && !selected.includes('Archived')) {
        return;
      }
      // Must have one of these checked
      if (
        !selected.includes('Employees') &&
        !selected.includes('Contractors') &&
        !selected.includes('Placeholders')
      ) {
        return;
      }

      if (label === 'Active' || label === 'Archived') {
        const active = newCheckedState['Active'];
        const archived = newCheckedState['Archived'];
        const newValue = archived && active ? '2' : archived ? '0' : '1';
        this.props.updateSettings({ activeMode: newValue });
      } else {
        const newValue = [];
        if (newCheckedState['Employees']) {
          newValue.push(0);
        }
        if (newCheckedState['Contractors']) {
          newValue.push(1);
        }
        if (newCheckedState['Placeholders']) {
          newValue.push(2);
        }
        this.props.updateSettings({ activeTeamModes: newValue });
      }
    }
    if (taskLabels.includes(this.props.outerLabel)) {
      this.props.updateSettings({
        taskStatus: Object.keys(newCheckedState).filter(
          (key) => newCheckedState[key],
        ),
      });
    }
    if (this.props.outerLabel === 'Time off') {
      this.props.updateSettings({
        timeOffStatus: Object.keys(newCheckedState).filter(
          (key) => newCheckedState[key],
        ),
      });
    }
  }

  componentDidMount() {
    this.ensureAllLabelsAreCorrect();
  }

  ensureAllLabelsAreCorrect() {
    const { checked, items } = this.props;
    const checkedKeys = Object.keys(checked);
    const keysToInvalidate = new Set(checkedKeys);

    // It can happen that pops.checked contains keys that arent present in props.items
    // This is not suported and this is why we need to uncheck those keys.
    items.forEach((valideKey) => {
      keysToInvalidate.delete(valideKey);
    });

    if (keysToInvalidate.size === checkedKeys.length) {
      this.toggleLabel(items[0]);
    }

    keysToInvalidate.forEach((key) => {
      this.toggleLabel(key);
    });
  }

  getLabels() {
    const { checked, items } = this.props;

    const labels = new Set(items);

    for (const [key, isSelected] of Object.entries(checked)) {
      if (!isSelected) {
        labels.delete(key);
      }
    }

    if (labels.size === items.length) {
      return 'All';
    }

    return [...labels].join(', ');
  }

  render() {
    return (
      <Popover
        arrow={false}
        animation={false}
        placement={this.props.placement ?? 'bottom-end'}
        distance={8}
        content={
          <div>
            <Label style={{ textAlign: 'left' }}>
              {' '}
              {this.props.innerLabel}{' '}
            </Label>
            <Gallery twoColumn={this.props.twoColumn}>
              {this.props.items.map((label, i) => (
                <Checkbox
                  key={`checkbox-reports-${this.props.outerLabel}-${i}`}
                  onChange={() => {
                    this.toggleLabel(label);
                  }}
                  value={this.props.checked[label]}
                  label={label}
                />
              ))}
            </Gallery>
          </div>
        }
      >
        <Button
          appearance="ghost"
          size="small"
          iconRight={IconDownSmall}
          iconRightProps={{
            className: 'desktop',
          }}
          data-testid={`cx-tooltip.${this.props.outerLabel.toLowerCase()}`}
        >
          {this.props.outerLabel}:&nbsp;
          <span>{this.getLabels()} </span>
        </Button>
      </Popover>
    );
  }
}
