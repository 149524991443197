import React, { useEffect, useState } from 'react';

import { getNumberOfDigitsAfterDot } from '../../../helpers/getNumberOfDigitsAfterDot';
import { InputAllocationPercentageProps } from '../InputAllocationPercentage';
import {
  getAllocationHoursPerDayValue,
  getAllocationHoursTotalValueFromHoursPerDay,
  getAllocationHoursTotalValueFromPercent,
  getAllocationPercentage,
} from '../InputAllocationPercentage.helpers';

const MAX_ALLOWED_HOURS_TOTAL_VALUE = 9999;
const MAX_ALLOWED_PERCENT_VALUE = 999;

export type UseInputAllocationPercentageParams = Pick<
  InputAllocationPercentageProps,
  | 'hoursPerDay'
  | 'numberOfAllocationDaysInDateRange'
  | 'numberOfWorkingDayHoursInDateRange'
  | 'onChange'
>;

export const useInputAllocationPercentage = ({
  hoursPerDay,
  numberOfAllocationDaysInDateRange,
  numberOfWorkingDayHoursInDateRange,
  onChange,
}: UseInputAllocationPercentageParams) => {
  const [percentageInternalValue, setPercentageInternalValue] = useState<
    number | null
  >(() => {
    const hoursTotalInitialValue = getAllocationHoursTotalValueFromHoursPerDay(
      hoursPerDay,
      numberOfAllocationDaysInDateRange,
    );

    const percentageInitialValue = getAllocationPercentage(
      hoursTotalInitialValue,
      numberOfWorkingDayHoursInDateRange,
    );

    return percentageInitialValue;
  });

  const [hoursTotalInternalValue, setHoursTotalInternalValue] = useState<
    number | null
  >(() => {
    const hoursTotalInitialValue = getAllocationHoursTotalValueFromHoursPerDay(
      hoursPerDay,
      numberOfAllocationDaysInDateRange,
    );

    return hoursTotalInitialValue;
  });

  // When number of working day hours or number of allocation days changes
  // we want to re-calculate the total and hours per day values
  // TODO: lift those calculations to a higher level component https://linear.app/float-com/issue/BB-35/allocationtimesection-further-improvements
  useEffect(() => {
    const hoursTotal = getAllocationHoursTotalValueFromPercent(
      percentageInternalValue,
      numberOfWorkingDayHoursInDateRange,
    );

    const hoursPerDay = getAllocationHoursPerDayValue(
      hoursTotal,
      numberOfAllocationDaysInDateRange,
    );

    onChange({
      hoursPerDay,
      hoursTotal,
    });

    setHoursTotalInternalValue(hoursTotal);
  }, [numberOfAllocationDaysInDateRange, numberOfWorkingDayHoursInDateRange]); // eslint-disable-line

  const handlePercentageChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const value = e.currentTarget.value
      ? Number(e.currentTarget.value)
      : e.currentTarget.value;

    // No changes, don't emit any value updates
    if (percentageInternalValue === value) return;

    // Empty input case
    if (typeof value === 'string') {
      const percentage = null;

      const hoursTotal = numberOfWorkingDayHoursInDateRange;
      const hoursPerDay = getAllocationHoursPerDayValue(
        hoursTotal,
        numberOfAllocationDaysInDateRange,
      );

      onChange({
        hoursPerDay,
        hoursTotal,
      });

      setPercentageInternalValue(percentage);
      setHoursTotalInternalValue(hoursTotal);

      return;
    }

    // Disallow more than 2 digits after the dot
    if (getNumberOfDigitsAfterDot(value) > 2) return;

    // Disallow values greater than 9999
    if (value > MAX_ALLOWED_HOURS_TOTAL_VALUE) return;

    const percentage = value;

    const hoursTotal = getAllocationHoursTotalValueFromPercent(
      percentage,
      numberOfWorkingDayHoursInDateRange,
    );

    const hoursPerDay = getAllocationHoursPerDayValue(
      hoursTotal,
      numberOfAllocationDaysInDateRange,
    );

    onChange({
      hoursPerDay,
      hoursTotal,
    });

    setPercentageInternalValue(percentage);
    setHoursTotalInternalValue(hoursTotal);
  };

  const handleHoursTotalChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const value = e.currentTarget.value
      ? Number(e.currentTarget.value)
      : e.currentTarget.value;

    // No changes, don't emit any value updates
    if (hoursTotalInternalValue === value) return;

    // Empty input case
    if (typeof value === 'string') {
      const hoursTotal = null;
      const hoursPerDay = getAllocationHoursPerDayValue(
        hoursTotal,
        numberOfAllocationDaysInDateRange,
      );

      const percentage = getAllocationPercentage(
        hoursTotal,
        numberOfWorkingDayHoursInDateRange,
      );

      onChange({
        hoursPerDay,
        hoursTotal,
      });

      setPercentageInternalValue(percentage);
      setHoursTotalInternalValue(hoursTotal);

      return;
    }

    // Disallow more than 2 digits after the dot
    if (getNumberOfDigitsAfterDot(value) > 2) return;

    // Disallow values greater than 999
    if (value > MAX_ALLOWED_PERCENT_VALUE) return;

    const hoursTotal = value;
    const hoursPerDay = getAllocationHoursPerDayValue(
      hoursTotal,
      numberOfAllocationDaysInDateRange,
    );

    const percentage = getAllocationPercentage(
      hoursTotal,
      numberOfWorkingDayHoursInDateRange,
    );

    onChange({
      hoursPerDay,
      hoursTotal,
    });

    setPercentageInternalValue(percentage);
    setHoursTotalInternalValue(hoursTotal);
  };

  return {
    percentageInternalValue,
    hoursTotalInternalValue,
    handlePercentageChange,
    handleHoursTotalChange,
  };
};
