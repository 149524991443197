import React, { PropsWithChildren } from 'react';
import { FieldArrayPath, FieldArrayWithId } from 'react-hook-form';
import { Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated/Button/Button';

import { ProjectFormData } from '../../types';
import { useShowMoreRows } from './SidePanelTableRows.hooks';

import * as styles from './styles.css';

type SidePanelTableRowsProps<N extends FieldArrayPath<ProjectFormData>> = {
  name: N;
  watchUpdate: keyof FieldArrayWithId<ProjectFormData, N, 'id'>;
  rowRenderer: (
    field: FieldArrayWithId<ProjectFormData, N, 'id'>,
    index: number,
  ) => React.ReactNode;
};

export function SidePanelTableRows<N extends FieldArrayPath<ProjectFormData>>(
  props: PropsWithChildren<SidePanelTableRowsProps<N>>,
) {
  const { fields, inputField, inputFieldIndex, showButton, onClickShow } =
    useShowMoreRows(props.name, props.watchUpdate);

  return (
    <React.Fragment>
      {fields.map((field, index) => {
        return props.rowRenderer(field, index);
      })}
      {inputField && props.rowRenderer(inputField, inputFieldIndex)}
      {showButton && (
        <div className={styles.buttonRow}>
          <Button size="small" appearance="clear" onClick={onClickShow}>
            <Trans>Show more</Trans>
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}
