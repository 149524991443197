import React from 'react';
import { Trans } from '@lingui/macro';
import { LayoutProps, motion } from 'framer-motion';

import { useIsTimeoffRequest } from '@float/common/lib/timeoff/hooks/useIsTimeoffRequest';
import { CellsMap } from '@float/types/cells';
import { Person } from '@float/types/person';
import { RepeatState } from '@float/types/repeatState';
import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';
import { TimeoffType } from '@float/types/timeoffType';
import { FieldLabel } from '@float/ui/deprecated/Label';
import { AssociatedChanges } from '@float/web/components/taskModals/dom/AssociatedChanges';

import { TimeoffBalance } from '../../components/TimeoffBalance/TimeoffBalance';
import { TimeoffSelect } from '../../components/TimeoffSelect';
import { TimeoffSelectReadOnly } from '../../components/TimeoffSelectReadOnly';

import * as styles from './TimeoffTabContent.css';

type Props = {
  canSeeTimeoffBalance: boolean;
  disableAnimations: () => void;
  enableAnimations: () => void;
  endDate: Date;
  getAssignedElem: () => React.JSX.Element;
  getAssociatedChangesForEntity: CellsMap['_helpers']['getAssociatedChangesForEntity'];
  getExportedTaskCopiedFromState: (task: Task) => Task;
  getNotesElem: () => React.JSX.Element | null;
  getTimeoffStatusToggleButtons: () => React.JSX.Element | null;
  hoursPd: number;
  isApproveRejectMode: boolean;
  isLayoutAnimationEnabled: LayoutProps['layout'];
  isReadOnly: boolean;
  isRequestMode: boolean;
  isStatusMode: boolean;
  onChange: (nextTimeoffType: TimeoffType) => void;
  personId: Person['people_id'] | undefined;
  personStartDate: Person['start_date'];
  repeatEnd: Date;
  repeatState: RepeatState;
  startDate: Date;
  timeoff: Timeoff;
  timeoffType: TimeoffType;
  timeoffTypeId: TimeoffType['timeoff_type_id'];
  timeoffTypes: TimeoffType[];
};

export const TimeoffTabContent = (props: Props) => {
  const {
    canSeeTimeoffBalance,
    disableAnimations,
    enableAnimations,
    endDate,
    getAssignedElem,
    getAssociatedChangesForEntity,
    getExportedTaskCopiedFromState,
    getNotesElem,
    getTimeoffStatusToggleButtons,
    hoursPd,
    isApproveRejectMode,
    isLayoutAnimationEnabled,
    isReadOnly,
    isRequestMode,
    isStatusMode,
    onChange,
    personId,
    personStartDate,
    repeatEnd,
    repeatState,
    startDate,
    timeoff,
    timeoffType,
    timeoffTypeId,
    timeoffTypes,
  } = props;

  const isTimeoffRequest = useIsTimeoffRequest(timeoff, isReadOnly);

  return (
    <>
      {!isApproveRejectMode && (
        <motion.div
          className={styles.allocationTimeoffSection}
          layout={isLayoutAnimationEnabled}
        >
          {isReadOnly ? (
            <>
              <FieldLabel>
                <Trans>Time off</Trans>
              </FieldLabel>
              <TimeoffSelectReadOnly timeoffType={timeoffType} />
            </>
          ) : (
            <TimeoffSelect
              endDate={endDate}
              onChange={onChange}
              personId={personId}
              timeoffTypeId={timeoffTypeId}
              timeoffTypes={timeoffTypes}
            />
          )}

          {canSeeTimeoffBalance && (
            <div className={styles.timeoffBalance}>
              <TimeoffBalance
                endDate={endDate}
                hoursPd={hoursPd}
                ignoreTimeoffId={timeoff.timeoff_id}
                isTimeoffRequest={isTimeoffRequest}
                personId={personId}
                personStartDate={personStartDate}
                repeatEndDate={repeatState > 0 ? repeatEnd : null}
                repeatState={repeatState}
                startDate={startDate}
                timeoffType={timeoffType}
              />
            </div>
          )}
        </motion.div>
      )}

      <motion.div className={styles.section} layout={isLayoutAnimationEnabled}>
        {getTimeoffStatusToggleButtons()}
      </motion.div>

      {isApproveRejectMode && (
        <div className={styles.associatedChanges}>
          <AssociatedChanges
            task={timeoff}
            startDate={startDate}
            endDate={endDate}
            peopleId={personId}
            getExportedTaskCopiedFromState={getExportedTaskCopiedFromState}
            getAssociatedChangesForEntity={getAssociatedChangesForEntity}
          />
        </div>
      )}

      {!isStatusMode && (
        // The RichText component doesn't work well with the layout animations
        // so we turn off them during editing
        <motion.div
          className={styles.section}
          layout={isLayoutAnimationEnabled}
          onFocus={disableAnimations}
          onBlur={enableAnimations}
        >
          {getNotesElem()}
        </motion.div>
      )}

      {!isApproveRejectMode && !isRequestMode && (
        <motion.div
          className={styles.section}
          layout={isLayoutAnimationEnabled}
        >
          {getAssignedElem()}
        </motion.div>
      )}
    </>
  );
};
