import { search } from '@float/common/api3/search';
import {
  QueryApiParams,
  QueryApiResult,
} from '@float/common/api3/search.types';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { normalize, SearchAutocompleteCategory } from '../helpers';
import { SearchAutocompleteQueryItem } from '../selectors/getSearchAutocompleteResults';
import { REMOTE_TYPE_TO_FILTER } from './constants';

const CATEGORY_TYPE_TO_REMOTE_TYPE = {
  clients: 'client',
  departments: 'department',
  jobTitles: 'role',
  roles: 'role',
  people: 'people',
  managers: 'manager',
  personTags: 'peopleTag',
  personTypes: 'peopleType',
  projectStatuses: 'projectStatus',
  projectTags: 'projectTag',
  projects: 'project',
  projectOwners: 'projectOwner',
  tasks: 'task',
  taskStatuses: 'taskStatus',
  timeoffs: 'timeOffType',
  timeoffStatuses: 'timeOffStatus',
  phases: 'phase',
};

function formatItem(item: QueryApiResult): SearchQueryItem {
  const type = REMOTE_TYPE_TO_FILTER[item.type];
  const val = item.name;

  // Keeping this because it's used when sorting the items on sortFilteredCandidates
  const normalizedVal = normalize(val);

  switch (type) {
    case 'person':
    case 'timeoff':
    case 'project':
      return {
        type,
        val,
        normalizedVal,
        isActive: Object.values(item.meta ?? {}).some(
          (meta) => meta.isActive === 1,
        ),
        ids: item.ids,
      };
    case 'department':
      return {
        type,
        val,
        normalizedVal,
        id: item.ids[0],
        parent_id:
          Object.values(item.meta ?? {})[0]?.parent_department_id ?? null,
        ids: item.ids,
      };
    default:
      return {
        type,
        val,
        normalizedVal,
        ids: item.ids,
      };
  }
}
export type SearchQueryItem = SearchAutocompleteQueryItem & {
  ids: number[];
};

export const DEFAULT_PAGE_SIZE = 50;

export type AutocompleteQueryApiReturnValue = {
  items: SearchQueryItem[];
  count: number;
  hasNextPage: () => boolean;
  fetchNextPage: () => Promise<SearchQueryItem[]>;
};

export async function queryApi(params: {
  query: string;
  category?: SearchAutocompleteCategory;
}): Promise<AutocompleteQueryApiReturnValue> {
  const minQueryLength = featureFlags.isFeatureEnabled(
    FeatureFlag.SearchBeyondLimitsMinQueryLength,
  )
    ? 2
    : 0;

  if (params.query.length <= minQueryLength && !params.category) {
    return {
      items: [],
      count: 0,
      hasNextPage: () => false,
      fetchNextPage: async () => [],
    };
  }

  if (params.category === 'savedSearches') {
    return {
      items: [],
      count: 0,
      hasNextPage: () => false,
      fetchNextPage: async () => [],
    };
  }

  const category =
    params.category && CATEGORY_TYPE_TO_REMOTE_TYPE[params.category];

  const apiParams: QueryApiParams = {
    query: params.query,
  };

  if (category) {
    apiParams.filter = {
      key: 'category',
      value: category,
    };
    apiParams.pagination = {
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    };
  }

  const [items, pageCount, count] = await search.query(apiParams);

  let currentPage = 1;
  let allItems = items.map(formatItem);

  function hasNextPage() {
    return currentPage < pageCount;
  }

  async function fetchNextPage() {
    if (hasNextPage()) {
      currentPage += 1;

      apiParams.pagination = {
        page: currentPage,
        pageSize: DEFAULT_PAGE_SIZE,
      };

      const [items] = await search.query(apiParams);
      allItems = allItems.concat(items.map(formatItem));
    }

    return allItems;
  }

  return {
    items: allItems,
    hasNextPage,
    fetchNextPage,
    // The API has a bug where it returns a count that is less than the number of items
    // https://linear.app/float-com/issue/PS-1471/query-return-pagination-data-by-default-if-filter-is-provided-but-no
    count: Math.max(count, allItems.length),
  };
}
