import React from 'react';
import { forEach, get, isEmpty, isNil, isUndefined } from 'lodash';
import { compose } from 'redux';
import { sidePanelConnect } from 'sidePanel/sidePanelConnect';
import styled, { css } from 'styled-components';

import { Rights } from '@float/common/lib/acl';
import { toNum } from '@float/common/lib/budget';
import { trackEvent } from '@float/common/lib/gtm';
import { onEmitEvent } from '@float/libs/eventEmitter';
import {
  FeatureFlag,
  featureFlags,
  isFeatureEnabled,
} from '@float/libs/featureFlags';
import { BudgetType, ProjectStatus } from '@float/types';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { ReadOnlyField } from '@float/ui/deprecated/Field/ReadOnlyField';
import { Input } from '@float/ui/deprecated/Input';
import { Row } from '@float/ui/deprecated/Layout/Layout';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { getPersonProjectRate } from '@float/web/components/modals/ProjectModal/ProjectModal.helpers';

import PhaseDropdown from '../../../../elements/PhaseDropdown';
import { ProjectAddTask } from '../../../../taskModals/dom/ProjectAddTask';
import ProjectCardBudgetLine, {
  shouldShowBudgetLine,
} from '../../../../taskModals/dom/ProjectCardBudgetLine';
import { BudgetLabel } from '../../components/BudgetLabel/BudgetLabel';
import { getBudget } from './AllocationProjectSection.helpers';
import { DraftStatusProject } from './DraftStatusProject';

const StyledProjectModalLink = styled(TextButton)`
  display: flex;
  flex-shrink: 0;
  margin-left: auto !important;
`;

const ProjectContainer = styled.div`
  position: relative;
  width: 100%;

  opacity: ${(p) => (p.$loading ? 0.6 : 1)};

  ${(p) =>
    p.$hasError &&
    css`
      ${StyledProjectModalLink} {
        bottom: 32px;
      }
    `};
`;

const ProjectFootnote = styled.div`
  display: flex;
  align-items: flex-start;

  margin-top: 5px;
  gap: 5px;
`;

const ProjectBudgetContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  cursor: default;
  margin-right: 12px;

  a {
    text-decoration: none;
  }

  ${(p) => p.theme.text.small}
`;

export const getAssignableProjectOptions = ({
  integrationSyncLocked,
  projectsOptions,
  projectsFilteredOptions,
  isLegacyCalendarEvent,
}) => {
  if (integrationSyncLocked && isLegacyCalendarEvent) {
    // hide tentative projects and with locked task list
    return projectsFilteredOptions;
  }

  return projectsOptions;
};

class AllocationProjectSection extends React.Component {
  render() {
    const isTentative = get(this.props.project, 'tentative', false);
    const isDraft = get(this.props.project, 'status') === ProjectStatus.Draft;
    const isTentativeSynced =
      this.props.integrationSyncLocked &&
      isTentative &&
      this.props.isLegacyCalendarEvent;
    if (this.props.isReadOnly() || isTentativeSynced) {
      return this.getProjectsElemReadOnly();
    }
    const { user } = this.props;
    const projectId = get(this.props.project, 'project_id', null);
    const projectName = get(this.props.project, 'project_name');
    const Footnote = this.renderFootnote();
    const hasFootnote = !!Footnote;
    const groupedOptions = getAssignableProjectOptions(this.props);

    return (
      <ProjectContainer
        $loading={this.props.loading}
        $hasError={!isEmpty(this.props.errors)}
      >
        {isDraft ? (
          <DraftStatusProject projectName={projectName} />
        ) : (
          <VirtualSelect
            label="Project"
            placeholder=""
            creatable={Rights.canCreateProject(user)}
            nonNullable
            clearInputOnDropdownOpen={false}
            visibleItems={6}
            style={{
              flexBasis: '100%',
              marginBottom: hasFootnote ? 0 : 16,
            }}
            value={projectId}
            groupedOptions={groupedOptions}
            hideSelectedIcon
            onChange={this.onProjectSelect}
            errors={this.props.errors}
            autoFocus={!this.props.project}
          />
        )}
        {Footnote}
      </ProjectContainer>
    );
  }

  renderFootnote = () => {
    const projectId = get(this.props.project, 'project_id');
    const project = this.props.projectsMap[projectId] || {};

    const hasPhases = this.props.projectPhases[projectId];
    const phase = hasPhases && this.getPhase(this.props.projectPhases);

    const canModifyProjects =
      Rights.canUpdateProject(this.props.user) &&
      !this.props.integrationSyncLocked;

    // The projectsMap is coming from enhanced selector which determines
    // if the current user can see the project budget
    const canSeeBudgets = project?.canSeeBudget || false;

    const projectData = this.getProjectDataForBudgetLine();

    // If there is a selected phase budget we're displaying it, otherwise, we're displaying the project budget
    const budget = getBudget(this.props.budgets, projectId, phase);
    const phaseDropdown = hasPhases && this.renderPhaseDropdown();

    const budgetLine = this.props.isBudgetByTaskFeatureEnabled
      ? canSeeBudgets && (
          <BudgetLabel
            budget={budget}
            budgetType={this.props.project?.budget_type}
            currencyProps={this.props.currencyProps}
            displayExplicitLabel={hasPhases}
            allocationCost={this.props.allocationCost}
            allocationCostInitial={this.props.allocationCostInitial}
          />
        )
      : shouldShowBudgetLine(projectData) &&
        this.renderBudgetLine(projectId, projectData);

    const noTaskLine = this.renderNoTaskLine(phase || this.props.project);
    const editProjectButton = canModifyProjects && this.renderEditLink();

    const hasFootnotes =
      phaseDropdown || budgetLine || noTaskLine || editProjectButton;
    if (!hasFootnotes) {
      return null;
    }

    return (
      <ProjectFootnote style={{ marginBottom: 10 }}>
        <Row
          flexWrap="wrap"
          justifyContent="flex-start"
          flex={1}
          style={{ gap: 4 }}
        >
          {phaseDropdown}
          {budgetLine}
          {noTaskLine}
        </Row>
        {editProjectButton}
      </ProjectFootnote>
    );
  };

  getBudgetTooltipText({ missingRate }) {
    if (missingRate) {
      return `This allocation is not included because the assigned person does not have a rate set.`;
    }

    return `Includes changes made in this allocation.`;
  }

  /**
   * @deprecated remove this method after BudgetByTask feature is fully rolled out and the FF is removed
   */
  renderBudgetLine(projectId, projectData) {
    const isHourlyFee = projectData.budget_type === BudgetType.HourlyFee;
    const isSimpleLine = isHourlyFee || this.props.isReadOnly();
    const allPhases = this.props.projectPhases;
    const phase = this.getPhase(this.props.projectPhases);

    const budgetLine = (
      <ProjectBudgetContainer>
        <ProjectCardBudgetLine
          project={projectData}
          phase={phase}
          hasPhase={!!allPhases[projectId]?.length}
        />
      </ProjectBudgetContainer>
    );

    if (isSimpleLine) {
      return budgetLine;
    }

    if (projectId) {
      return (
        <Popover
          placement="bottom-start"
          content={
            <div style={{ textAlign: 'left' }}>
              {this.getBudgetTooltipText(projectData)}
            </div>
          }
        >
          {budgetLine}
        </Popover>
      );
    }

    return null;
  }

  renderNoTaskLine(projectOrPhase) {
    if (!projectOrPhase || !this.props.initialSyncDone) {
      return null;
    }

    if (!this.props.showNoTasksLine || this.props.loading) {
      return null;
    }

    if (this.props.isReadOnly()) {
      return null;
    }

    const canCreateTaskMeta = Rights.canCreateTaskMeta(this.props.user, {
      project: this.props.project,
    });

    return (
      <ProjectAddTask
        entity={projectOrPhase}
        canEdit={canCreateTaskMeta && !this.props.forceShowEditTaskField}
        skipAddTaskFade={this.props.skipAddTaskFade}
        onClickEdit={() => {
          return this.props.toggleForceShowTaskField?.();
        }}
      />
    );
  }

  getPhase = (options = this.props.phasesOptions) => {
    const projectId = get(this.props.project, 'project_id');
    const phases = options[projectId];
    if (!phases || !phases.length) return null;
    return phases.find((p) => p.phase_id == this.props.phaseId);
  };

  /**
   * @deprecated
   *
   * this doesn't consider people role rates
   * remove this method after BudgetByTask feature is fully rolled out and the FF is removed
   */
  mergeRates(project, phase) {
    const rates = {
      ...(phase?.people_rates || {}),
    };

    forEach(project.people_rates, (rate, personId) => {
      if (isUndefined(rates[personId]) || +rates[personId] === 0) {
        rates[personId] = rate;
      }
    });

    return rates;
  }

  /**
   * @deprecated
   *
   * this information is stored in the allocationCost and shared among all the components
   * remove this method after BudgetByTask feature is fully rolled out and the FF is removed
   */
  getBudgetCostDelta = (project, phase) => {
    const { budget_type } = project;

    if (
      ![
        BudgetType.TotalHours,
        BudgetType.TotalFee,
        BudgetType.HourlyFee,
      ].includes(budget_type)
    )
      return 0;
    if (!this.props.initialHours) return 0;

    if (!project?.non_billable && phase?.non_billable) {
      return { delta: 0, missingRate: false };
    }

    const hours = this.props.calcEntityTotalHours(this.props.asEntity());

    if (budget_type === BudgetType.TotalHours) {
      return {
        delta: hours.totalHours - this.props.initialHours.totalHours,
        missingRate: false,
      };
    } else {
      let totalMoneyAdded = 0;
      let missingRate = false;

      forEach(hours.hoursByPerson, (personHours, personId) => {
        const hourDelta =
          hours.hoursByPerson[personId] -
          (this.props.initialHours.hoursByPerson[personId] || 0);

        const rateData = {
          budget_type: project.budget_type,
          people_rates: this.mergeRates(project, phase),
          default_hourly_rate:
            phase?.default_hourly_rate || project.default_hourly_rate,
        };

        const rate = toNum(
          rateData.default_hourly_rate ||
            getPersonProjectRate(this.props.peopleMap[personId], rateData, {
              withCurrencySymbol: false,
            }),
        );

        if (!rate) {
          missingRate = true;
        }

        totalMoneyAdded += rate * hourDelta;
      });

      return { delta: totalMoneyAdded, missingRate };
    }
  };

  /**
   * @deprecated
   *
   * this information is stored in the allocationCost and shared among all the components
   * remove this method after BudgetByTask feature is fully rolled out and the FF is removed
   */
  getProjectDataForBudgetLine = () => {
    const projectId = get(this.props.project, 'project_id');
    const project = this.props.projectsMap[projectId] || {};
    const phase = this.getPhase(this.props.projectPhases);

    const { budget_type } = project;
    if (
      ![
        BudgetType.TotalHours,
        BudgetType.TotalFee,
        BudgetType.HourlyFee,
      ].includes(budget_type)
    ) {
      return {};
    }

    const { project_name, canSeeBudget, budget_priority } = project;

    const budget = this.props.budgets.projects[projectId];
    const phaseBudget = this.props.budgets.projects[phase?.phase_id];

    let {
      budget_used = 0,
      budget_remaining = 0,
      budget_total = 0,
    } = budget || {};

    if (budget_priority && phaseBudget) {
      budget_used = phaseBudget.budget_used;
      budget_total = phaseBudget.budget_total;
      budget_remaining = phaseBudget.budget_remaining;
    }

    if (isUndefined(budget_used)) budget_used = 0;

    budget_remaining = isNil(budget_remaining)
      ? budget_total
      : budget_remaining;
    budget_total = toNum(budget_total);
    budget_remaining = toNum(budget_remaining);

    const { delta, missingRate } = this.getBudgetCostDelta(project, phase);
    budget_used += delta;
    budget_remaining -= delta;

    return {
      project_name,
      canSeeBudget,
      budget_type,
      budget_used,
      budget_remaining,
      budget_total,
      budget_priority,
      missingRate,
    };
  };

  renderPhaseDropdown = () => {
    const projectId = get(this.props.project, 'project_id');

    if (this.props.isReadOnly()) {
      const phases = this.props.projectPhases[projectId];
      const curPhase = this.getPhase(this.props.projectPhases);
      if (phases?.length) {
        let name = curPhase?.phase_name || 'No phase';
        name = curPhase?.active ? name : `${name} (Archived)`;
        return (
          <ReadOnlyField type="phase" value={name} style={{ marginRight: 5 }} />
        );
      }
      return null;
    }

    const options = this.props.phasesOptions[projectId];

    return (
      <PhaseDropdown
        options={options}
        value={this.props.phaseId || 0}
        projectColor={get(this.props.project, 'color')}
        onChange={this.props.setPhaseId}
        style={{ marginRight: 5 }}
      />
    );
  };

  onProjectSelect = ({ value }) => {
    const project = this.props.projectsMap[value];
    if (project) {
      return this.props.selectProject(project);
    }
    trackEvent('task-modal-inline-project-create');
    this.addNewProjectModal({ project_name: value });
  };

  renderEditLink = () => {
    const canModifyProjects = Rights.canUpdateProject(this.props.user);
    if (!canModifyProjects || this.props.integrationSyncLocked) {
      return null;
    }

    const phase = this.getPhase();
    if (phase?.phase_id) {
      return (
        <StyledProjectModalLink
          appearance="flue-fill"
          onClick={(e) => {
            e.preventDefault();
            this.editPhaseModal(phase);
          }}
        >
          Edit phase
        </StyledProjectModalLink>
      );
    }

    return this.props.project ? (
      <StyledProjectModalLink
        appearance="flue-fill"
        onClick={this.editProjectModal}
      >
        Edit project
      </StyledProjectModalLink>
    ) : (
      <StyledProjectModalLink
        appearance="flue-fill"
        onClick={this.addNewProjectModal}
      >
        Add project
      </StyledProjectModalLink>
    );
  };

  editPhaseModal = (phase) => {
    const projectId = get(this.props.project, 'project_id');
    trackEvent('task-modal-edit-phase');
    if (
      isFeatureEnabled(FeatureFlag.PhaseSidePanel) &&
      isFeatureEnabled(FeatureFlag.SidePanelBesidesAllocationModal)
    ) {
      this.props.updateModal({
        modalType: 'taskModal',
        skipSidePanelAutoClose: true,
      });
      this.props.showPhaseSidePanel({
        phaseId: phase.phase_id,
        projectId: projectId,
      });
      return;
    }
    this.props.manageModal({
      visible: true,
      modalType: 'phaseModal',
      modalSettings: {
        phase,
        projectId,
        isAdding: false,
        editing: true,
        postSave: (pid) => {
          this.props.setPhaseId({ value: pid });
        },
      },
    });
  };

  editProjectModal = (newProject) => {
    const projectId = get(this.props.project, 'project_id');
    const foundProject = this.props.projectsMap[projectId];
    const isNewProject = newProject?.canEdit;
    if (foundProject || isNewProject) {
      // Temporarily need this injector feature while side panel is in development
      if (this.props.isReadOnly()) {
        return this.props.showProjectSidePanel({ projectId });
      }

      if (!isNewProject) {
        trackEvent('task-modal-edit-project');
      }

      if (isFeatureEnabled(FeatureFlag.SidePanelBesidesAllocationModal)) {
        const sidePanelProps = !isNewProject
          ? { projectId }
          : {
              projectName: newProject.project_name,
              afterSave: (id) => {
                if (
                  featureFlags.isFeatureEnabled(
                    FeatureFlag.CreateLayeredProject,
                  )
                ) {
                  onEmitEvent(id, this.props.selectProject);
                } else {
                  setTimeout(() => {
                    const project = this.props.projectsMap[id];
                    if (project) this.props.selectProject(project);
                  }, 0);
                }
              },
            };

        this.props.updateModal({
          modalType: 'taskModal',
          skipSidePanelAutoClose: true,
        });
        this.props.showProjectSidePanel(sidePanelProps);
        return;
      }

      this.props.manageModal({
        visible: true,
        modalType: 'projectModal',
        modalSettings: {
          project: isNewProject ? newProject : foundProject,
          isAdding: isNewProject,
          editing: true,
          hideToggle: true,
          hideDelete: true,
          hideTasksTab: false,
          postSave: (project) => {
            this.props.selectProject(project);
          },
        },
      });
    }
  };

  addNewProjectModal = (newProject) => {
    this.props.selectProject({ project_id: null });
    this.editProjectModal({
      ...(newProject || {}),
      canEdit: true,
    });
  };

  renderReadOnlyInput = (label, value, props = {}) => {
    const style = props.style || {};
    return (
      <Input
        appearance="underline"
        label={label}
        value={value}
        readOnly
        noBorder
        style={props.noMargin ? style : { marginBottom: 21, ...style }}
        {...props}
      />
    );
  };

  getProjectsElemReadOnly = () => {
    let name = '';

    if (this.props.task.project) {
      const { project_name, client_name, client_id, active } =
        this.props.task.project;
      name = client_id ? `${client_name} / ${project_name}` : project_name;

      if (!active) {
        name += ' (Archived)';
      }
    }

    return (
      <>
        {this.renderReadOnlyInput('Project', name, {
          style: {
            flex: '1 1 100%',
            marginBottom: 0,
          },
        })}
        {this.renderFootnote()}
      </>
    );
  };
}

export default compose(sidePanelConnect, withConfirm)(AllocationProjectSection);
