import { cloneDeep, isUndefined, union } from 'lodash';

import {
  AnyCell,
  CellChange,
  CellItem,
  CellItemWithEntity,
  RowId,
  TimeRange,
} from '@float/types/cells';
import { LoggedTime } from '@float/types/loggedTime';
import { Milestone } from '@float/types/milestone';
import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

import { getErrorMessage } from '../../../lib/errors';
import { getCanUpdateEntity } from '../../../lib/rights';
import { getEntityMeta, setEntityMeta } from './entityMeta';
import { GetAction, UseCellsReducerProps, UseCellsState } from './types';

function floorAverage(a: number, b: number) {
  return Math.floor((a + b) / 2);
}

export function dragMilestone(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'DRAG_ENTITY'>,
): UseCellsState {
  const {
    dates,
    maps,
    bimaps,
    cellHelpers: { buildCells, getApplicableCellKeys },
  } = props;
  const { cells } = state;

  const { items, dayDelta } = action;

  const [i] = items;
  const { type, entityId, colIdx } = i;
  const oldCellKeys = bimaps[type].delete(entityId);
  const entity = maps[type][entityId];

  if (!i.originalEntity) {
    i.originalEntity = cloneDeep(entity);
  }

  if (!i.baseValues) {
    setEntityMeta(entity, 'isPlaceholder', true);

    // @entity.length
    const entityLength = (entity as Milestone).length;

    if (!i.isStart) {
      entity.start_date = dates.fromNum(colIdx! * 7);
      entity.end_date = dates.addDays(entity.start_date, entityLength);
    }

    i.baseValues = {
      length: entityLength,
      start_date: entity.start_date,
      end_date: entity.end_date,
    };
  }

  entity.start_date = dates.addDays(i.baseValues.start_date, dayDelta);
  entity.end_date = dates.addDays(entity.start_date, i.baseValues.length! - 1);

  const newCellKeys = getApplicableCellKeys(entity);
  bimaps[type].assoc(entityId, newCellKeys);

  const affectedCellKeys = union(oldCellKeys, newCellKeys);
  buildCells(cells, affectedCellKeys, undefined);

  return { ...state, cells };
}

export function dragEntity(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'DRAG_ENTITY'>,
): UseCellsState {
  const {
    maps,
    bimaps,
    rowMetas,
    dates,
    logTimeView,
    logMyTimeView,
    lockPeriodDates,
    cellHelpers: {
      calcEntityEndDate,
      calcEntityLength,
      firstWorkDay,
      findWorkDay,
      getApplicableCellKeys,
      buildCells,
      unapplyChanges,
      applyChanges,
      countWorkDays,
      findNthWorkDay,
    },
  } = props;
  const { cells } = state;

  const { items, dayDelta, rowId, direction } = action;

  let affectedCellKeys: string[] = [];
  const pendingChanges: CellChange[] = [];

  // First, make sure that we have baseValues set for all of our items. We
  // want to keep track of the initial start/end/length values of our
  // selected items so that we can apply modifications correctly.
  items.forEach((i, idx) => {
    const { type, entityId, colIdx } = i;
    const rowIdToUse = items.length === 1 ? rowId : i.rowId!;

    // Remove the old task mappings
    const oldCellKeys = bimaps[type].delete(entityId);
    affectedCellKeys = union(affectedCellKeys, oldCellKeys);

    const entity = maps[type][entityId] ?? i.entity;

    if (!i.originalEntity) {
      i.originalEntity = cloneDeep(entity);
    }

    if (!i.baseValues) {
      setEntityMeta(entity, 'isPlaceholder', true);

      if (getEntityMeta(entity, 'isTaskReference')) {
        setEntityMeta(entity, 'isTaskReference', false);
      }

      if (
        !isUndefined(i.instanceCount) &&
        'allInstances' in entity &&
        entity.allInstances
      ) {
        const instance = entity.allInstances[i.instanceCount];

        entity.start_date = instance.start_date;
        entity.end_date = instance.end_date;

        setEntityMeta(entity, 'repeatInstances', undefined);
      }

      // If an entity has a temporaryId while it's being dragged, it's a
      // duplicate of an existing item. While we want to re-calculate
      // the length for items as they move between rows in the case of
      // multi-person full-day timeoffs, we don't want to calculate for
      // duplicates because a duplicate full-day timeoff shouln't be
      // altered based on other existing timeoffs.
      if (!getEntityMeta(entity, 'temporaryId')) {
        // @entity.length
        // @ts-expect-error The length prop should be part of every entity?
        entity.length = calcEntityLength(cells, rowIdToUse, entity);
      }

      if (items.length === 1) {
        if (
          'people_ids' in entity &&
          entity.people_ids &&
          entity.people_ids.length > 1
        ) {
          // @entity.length
          // We need to re-calculate the length based on the current rowId
          entity.length = calcEntityLength(cells, rowIdToUse, entity);
        }

        // If a task spans multiple weeks and the user started dragging on
        // anything other than the first chunk, we want to adjust the
        // dates to make it feel like they're dragging the first chunk.
        if (!i.isStart) {
          entity.start_date = firstWorkDay(
            cells,
            rowIdToUse,
            colIdx!,
            type === 'timeoff' ? (entity as Timeoff) : undefined,
          );
          entity.end_date = calcEntityEndDate(cells, rowIdToUse, entity);
        }
      }

      i.baseValues = {
        // @entity.length
        // @ts-expect-error
        length: entity.length,
        start_date: entity.start_date,
        end_date: entity.end_date,
        date: 'date' in entity ? entity.date : undefined,
      };
    }
  });

  // Next, we want to move the active dragged task's start date. We want
  // to move it ignoring all other selected full day timeoffs as they will
  // also be shifting, and we want to set the activeDragged item's dates
  // first since that's what the user is dragging and expects.

  if (!items[0].activeDragged) {
    throw Error('The first item must be the activeDragged item');
  }

  const [activeItem] = items;
  const activeEntity =
    maps[activeItem.type][activeItem.entityId] ?? activeItem.entity;
  // Align map's model length with dragged entity. For example, when we drag a multi-assigned task,
  // the model contain a single length value of the last person's task. We want to update it to the actual one.
  if (activeEntity) {
    // @entity.length
    // @ts-expect-error
    activeEntity.length = activeItem.baseValues!.length;
  }

  if (!activeEntity) {
    console.error('No activeEntity for item', activeItem);
  }

  const { start_date: prevStartDate } = activeEntity;
  const meta = rowMetas.get(rowId);

  try {
    activeEntity.start_date = findWorkDay(
      cells,
      rowId,
      dates.addDays(activeItem.baseValues!.start_date, dayDelta),
      direction,
      activeItem.type === 'timeoff' ? (activeEntity as Timeoff) : undefined,
      logTimeView,
      activeEntity,
      lockPeriodDates,
    );
  } catch (e) {
    if (getErrorMessage(e) !== 'PERSON_DATE_OUT_OF_RANGE') {
      throw e;
    }

    // When dragging a task to a new person, ensure it's bounded by the
    // new person's start date (if it exists).
    if (meta?.start_date) {
      activeEntity.start_date = dates.max(
        activeEntity.start_date,
        meta.start_date,
      );
    }
  }

  // Linked tasks cannot be dragged past their parent's start date
  if ('parent_task_id' in activeEntity && activeEntity.parent_task_id) {
    const parentEntity = maps.task[activeEntity.parent_task_id];

    // sometimes parentEntity is undefined
    // https://rollbar.com/float/fe-web-app/items/4849/
    if (parentEntity && parentEntity.start_date > activeEntity.start_date) {
      activeEntity.start_date = findWorkDay(
        cells,
        rowId,
        parentEntity.start_date,
        'R',
      );
    }
  }

  let preventDragToPerson = false;

  // If the target person has an end date set, we want to clamp the task such
  // that its length is unchanged.
  if (meta?.end_date) {
    try {
      const maxStartDate = findNthWorkDay(
        cells,
        rowId,
        meta.end_date,
        -activeItem.baseValues!.length! + 1,
        activeItem.type === 'timeoff' ? (activeEntity as Timeoff) : undefined,
      );

      if (activeEntity.start_date > maxStartDate) {
        activeEntity.start_date = maxStartDate;
      }
    } catch (e) {
      if (getErrorMessage(e) !== 'PERSON_DATE_OUT_OF_RANGE') {
        throw e;
      }

      // If the user tried to drag a task from person A to person B, and the
      // task's length is greater than the start/end dates of person B, prevent
      // moving the task to person B.
      preventDragToPerson = true;
    }
  }

  if (
    logMyTimeView &&
    activeItem.type === 'loggedTime' &&
    (!('isSidebarItem' in activeItem.entity) ||
      !activeItem.entity.isSidebarItem)
  ) {
    // LoggedTime entities in "my" view, cannot be dragged between weeks unless it's sidebar item.
    const [prevColIdx] = dates.toDescriptor(prevStartDate);
    const [nextColIdx] = dates.toDescriptor(activeEntity.start_date);

    if (prevColIdx !== nextColIdx) {
      activeEntity.start_date = prevStartDate;
    }
  }

  if (!preventDragToPerson) {
    try {
      activeEntity.end_date = calcEntityEndDate(cells, rowId, activeEntity);
      // @ts-expect-error mmmh why?
      activeEntity.date = activeEntity.start_date;
    } catch (e) {
      if (getErrorMessage(e) !== 'PERSON_DATE_OUT_OF_RANGE') {
        throw e;
      }
    }

    // Moving items between rows is only allowed if you have a single item
    // selected and that item isn't assigned to more than one person.
    if (items.length === 1) {
      const personId = meta?.personId!;

      const canEdit =
        !('timeoff_id' in activeEntity) ||
        getCanUpdateEntity(
          { ...activeEntity, people_ids: [personId] },
          {
            user: cells._helperData.user,
            phases: cells._helperData.phases,
            projects: cells._helperData.projects,
            timeoffTypes: cells._helperData.timeoffTypes,
            people: cells._helperData.people,
          },
        );

      if (canEdit && 'people_id' in activeEntity && activeEntity.people_id) {
        activeEntity.people_id = personId;
      }
      if (
        canEdit &&
        'people_ids' in activeEntity &&
        activeEntity.people_ids?.length === 1
      ) {
        activeEntity.people_ids = [personId];
      }
    }
  }

  const newCellKeys = getApplicableCellKeys(activeEntity);
  bimaps[activeItem.type].assoc(activeItem.entityId, newCellKeys);
  affectedCellKeys = union(affectedCellKeys, newCellKeys);
  buildCells(cells, affectedCellKeys, undefined);

  // Figure out how many work days the activeEntity was adjusted by.
  // This is the number of work days that we want to adjust the other
  // multiselected entities by as well.
  let activeEntityWorkDayStartDelta = countWorkDays(
    cells,
    activeItem.rowId,
    dates.min(activeEntity.start_date, activeItem.baseValues!.start_date),
    dates.max(activeEntity.start_date, activeItem.baseValues!.start_date),
    activeItem.type === 'timeoff' ? (activeEntity as Timeoff) : undefined,
  );
  if (activeEntity.start_date < activeItem.baseValues!.start_date!) {
    activeEntityWorkDayStartDelta *= -1;
  }

  if (items.length > 1) {
    items.slice(1).forEach((i) => {
      const entity = maps[i.type][i.entityId];

      try {
        entity.start_date = findNthWorkDay(
          cells,
          i.rowId,
          i.baseValues!.start_date,
          activeEntityWorkDayStartDelta,
          i.type === 'timeoff' ? (entity as Timeoff) : undefined,
        );
      } catch (e) {
        if (getErrorMessage(e) !== 'PERSON_DATE_OUT_OF_RANGE') {
          throw e;
        }
      }

      const itemMeta = rowMetas.get(i.rowId);
      // If the target person has an end date set, we want to clamp the task
      // such that its length is unchanged. This is the same block as a little
      // further up, but works on each multi-selected task independently.
      if (itemMeta?.end_date) {
        const maxStartDate = findNthWorkDay(
          cells,
          i.rowId,
          itemMeta.end_date,
          -i.baseValues!.length! + 1,
          i.type === 'timeoff' ? (entity as Timeoff) : undefined,
        );

        if (entity.start_date > maxStartDate) {
          entity.start_date = maxStartDate;
        }
      }

      entity.end_date = calcEntityEndDate(cells, i.rowId!, entity);

      const newKeys = getApplicableCellKeys(entity);
      bimaps[i.type].assoc(i.entityId, newKeys);
      affectedCellKeys = union(affectedCellKeys, newKeys);
      buildCells(cells, affectedCellKeys, undefined);
    });
  }

  // Apply associated changes to our items. For example, if you drag a
  // full-day timeoff such that it blocks out the last day of a task,
  // we want to also modify that task's end date.
  items.forEach((i, idx) => {
    const { type, entityId } = i;
    const entity = maps[type][entityId];
    const newKeys = bimaps[type].getFwd(entityId) as RowId[];

    affectedCellKeys = union(
      affectedCellKeys,
      unapplyChanges(state.pendingChanges),
      applyChanges(cells, newKeys, entity, pendingChanges, {
        dayDelta: activeEntityWorkDayStartDelta,
      }),
    );
  });

  buildCells(cells, affectedCellKeys, undefined);

  return { ...state, cells, pendingChanges };
}

export function dragEntityStop(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'DRAG_ENTITY_STOP'>,
): UseCellsState {
  const {
    maps,
    bimaps,
    dates,
    cellHelpers: { buildCells, unapplyChanges, applyChanges, countWorkDays },
  } = props;
  const { cells } = state;

  let affectedCellKeys: string[] = [];
  const changes: CellChange[] = [];
  const pendingChanges: CellChange[] = [];

  action.items.forEach((i) => {
    const { type, entityId, originalEntity } = i;

    setEntityMeta(maps[type][entityId], 'isPlaceholder', false);
    const entity = maps[type][entityId];
    const newKeys = bimaps[type].getFwd(entityId) as RowId[];

    let activeEntityWorkDayStartDelta = countWorkDays(
      cells,
      i.rowId,
      dates.min(entity.start_date, i.baseValues?.start_date),
      dates.max(entity.start_date, i.baseValues?.start_date),
      i.type === 'timeoff' ? (entity as Timeoff) : undefined,
    );
    if (i.baseValues && entity.start_date < i.baseValues.start_date) {
      activeEntityWorkDayStartDelta *= -1;
    }

    affectedCellKeys = union(
      affectedCellKeys,
      unapplyChanges(state.pendingChanges),
      applyChanges(cells, newKeys, entity, pendingChanges, {
        dayDelta: activeEntityWorkDayStartDelta,
      }),
    );

    if (type === 'timeRange') (entity as TimeRange).range_mode = 'custom';

    let isCreate = !!getEntityMeta(maps[type][entityId], 'temporaryId');
    if (type === 'loggedTime') {
      const logtime = originalEntity as LoggedTime;
      const entity = maps[type][entityId] as LoggedTime;
      isCreate =
        !!logtime.isTaskReference ||
        !logtime.logged_time_id ||
        logtime.logged_time_id.includes('.');

      if (isCreate) {
        entity.isTaskReference = true;
      }
    }

    changes.push({
      type,
      id: entityId,
      entity: maps[type][entityId],
      originalEntity,
      instanceCount: i.instanceCount,
      isCreate,
      trigger: 'DRAG_ENTITY_STOP',
    });
  });

  if (state.priorityChangedItems && state.priorityChangedItems.length) {
    state.priorityChangedItems.forEach((i) => {
      if (!changes.some((c) => c.type == i.type && c.id == i.entityId)) {
        const change = {
          type: i.type,
          id: i.entityId,
          entity: maps[i.type][i.entityId],
          originalEntity: cloneDeep(maps[i.type][i.entityId]),
          instanceCount: i.instanceCount,
        };

        const { originalEntity: oe } = change;
        const oldPriority = getEntityMeta(oe, '_old_priority');
        const oldPriorityInfo = getEntityMeta(oe, '_old_priority_info');

        if (oldPriority) (oe as LoggedTime).priority = oldPriority;
        if (oldPriorityInfo) (oe as Task).priority_info = oldPriorityInfo;

        setEntityMeta(oe, '_old_priority', undefined);
        setEntityMeta(oe, '_old_priority_info', undefined);

        changes.push(change);
      }
    });
  }

  buildCells(cells, affectedCellKeys, undefined);

  return {
    ...state,
    cells,
    pendingChanges: [],
    changes: [...changes, ...pendingChanges],
    priorityChangedItems: undefined,
  };
}

export function dragSort(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'DRAG_SORT'>,
) {
  const {
    maps,
    bimaps,
    cellHelpers: { buildCells, getOverlappingItems, getPriority, setPriority },
  } = props;
  const { cells } = state;

  const { rowId, direction, cellHour } = action;

  let { colIdx } = action;
  let cell: AnyCell;
  let item: CellItemWithEntity | undefined;
  do {
    cell = cells[`${rowId}:${colIdx}`];

    if (!cell) {
      // This can happen if this action arrives before a DRAG_ENTITY
      // action has a chance to complete, which can occur when a row
      // height change is happening while dragging horizontally (removing
      // overtime). It seems to be OK to return the state unmodified here.
      return state;
    }

    item = cell.items.find(
      (i: CellItem) => 'isPlaceholder' in i && i.isPlaceholder,
    ) as CellItemWithEntity | undefined;

    if (!item) colIdx += direction === 'R' ? 1 : -1;
  } while (!item);

  let overlappingItems: CellItemWithEntity[] = getOverlappingItems(cell, item);

  // For priority sorting in logged time mode, we only consider LoggedTime
  // entities. This is because TaskReferences are ephemeral and don't have
  // priority and timeoffs' priority values in the DB refer to schedule view.
  if (item.type === 'loggedTime') {
    overlappingItems = overlappingItems.filter((i) => i.type === 'loggedTime');
  }

  // If there's nothing overlapping, there's nothing to vertically sort.
  if (!overlappingItems.length) return state;

  let below: CellItemWithEntity | undefined = undefined;
  for (let i = overlappingItems.length - 1; i >= 0; i--) {
    const oi = overlappingItems[i];
    if (oi.y! + oi.h! / 2 > cellHour) {
      below = oi;
    }
  }

  let above: CellItemWithEntity | undefined = undefined;
  let aboveIdx: number | undefined = undefined;
  for (let i = 0; i < overlappingItems.length; i++) {
    const oi = overlappingItems[i];
    if (oi.y! + oi.h! / 2 < cellHour) {
      above = oi;
      aboveIdx = i;
    }
  }

  const priorityChangedItems: CellItemWithEntity[] = [];
  const entity = maps[item.type][item.entityId];
  const entityPriority = getPriority(rowId, entity);

  if (!above && below) {
    // User is trying to move the entity to the very top
    const belowPriority = getPriority(rowId, below.entity);
    if (entityPriority !== belowPriority - 10) {
      setPriority(rowId, entity, belowPriority - 10);
      priorityChangedItems.push(item);
    }
  }

  if (above && !below) {
    // User is trying to move the entity to the very bottom
    const abovePriority = getPriority(rowId, above.entity);
    if (entityPriority !== abovePriority + 10) {
      setPriority(rowId, entity, abovePriority + 10);
      priorityChangedItems.push(item);
    }
  }

  if (above && below) {
    // User is trying to move the entity in between two other things
    const abovePriority = getPriority(rowId, above.entity);
    const belowPriority = getPriority(rowId, below.entity);

    if (belowPriority - abovePriority >= 2) {
      // If the two other things already have different priorities and we
      // can slot the dragged entity in betweeen, just do that.
      const targetPriority = floorAverage(abovePriority, belowPriority);
      if (entityPriority !== targetPriority) {
        setPriority(rowId, entity, targetPriority);
        priorityChangedItems.push(item);
      }
    } else {
      // Otherwise, we'll need to adjust all of the items below where the
      // user is trying to position the dragged entity to give ourselves
      // some room.
      const targetPriority = abovePriority + 5;

      if (entityPriority !== targetPriority) {
        setPriority(rowId, entity, targetPriority);
        priorityChangedItems.push(item);
      }

      if (aboveIdx !== undefined) {
        for (let i = aboveIdx + 1; i < overlappingItems.length; i++) {
          const oi = overlappingItems[i];
          const curPriority = getPriority(rowId, oi.entity);
          setPriority(rowId, oi.entity, curPriority + 10);
          priorityChangedItems.push(oi);
        }
      }
    }
  }

  if (!priorityChangedItems.length) {
    return state;
  }

  const affectedCellKeys: string[] = [];
  priorityChangedItems.forEach((i) => {
    affectedCellKeys.push(...bimaps[i.type].getFwd(i.entityId));
  });
  buildCells(cells, affectedCellKeys, undefined);

  return { ...state, cells, priorityChangedItems };
}
