import React, { useState } from 'react';
import styled from 'styled-components';

import { DatePicker } from '@float/common/components/DatePicker/DatePicker';
import DayDots, { _styles } from '@float/common/components/DayDots';
import DayTooltip from '@float/common/components/DayTooltip';
import IconCalendar from '@float/ui/deprecated/Icons/icon-calendar';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

const { DotGroupContainer } = _styles;

const TopDatePickerWrapper = styled.div`
  width: 100%;
  height: 51px;
  min-height: 51px;
  background: white;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  display: flex;
  align-items: center;

  ${DotGroupContainer} {
    position: relative;
    height: 40px;
    width: 27px;
    align-self: flex-start;
    transform: rotate(180deg);
  }
`;

const CalendarButton = styled.button`
  cursor: pointer;
  outline: none;
  padding: 4px 4px 0 4px;
  border: 1px solid ${(p) => p.theme.grey};
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  margin-left: 9px;
  margin-right: 10px;
`;

const DailyDayCell = styled.div`
  height: 100%;
  font-size: 14px;
  line-height: 15px;
  color: ${(p) => p.theme.medGrey};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0.3px;
  font-weight: 500;

  div.day {
    margin-top: 3px;
    padding-bottom: 3px;
    color: black;
    font-weight: bold;
  }

  div.month {
    text-transform: uppercase;
  }
`;

const HoursContainer = styled.div`
  flex-grow: 1;
  text-align: right;
  align-self: flex-end;
  line-height: 34px;
  margin-right: 10px;
  font-weight: bold;
  color: ${(p) => p.theme.charcoalGrey};
`;

export default function TopDatePicker(props) {
  const {
    dates,
    suvSingleDay,
    setSuvSingleDay,
    mondayStart,
    highlights,
    loggedHours,
    logTimeView,
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipContent = (
    <DayTooltip
      highlights={highlights}
      onMilestoneClick={() => {}}
      closeTooltip={() => setTooltipOpen(false)}
    />
  );

  return (
    <TopDatePickerWrapper>
      <DatePicker
        firstOfWeek={mondayStart ? 1 : 0}
        onChange={(date) => {
          if (!date || !date.format) return;
          const dateStr = date.format('YYYY-MM-DD');
          setSuvSingleDay(dateStr);
        }}
      >
        <CalendarButton>
          <IconCalendar />
        </CalendarButton>
      </DatePicker>
      <DailyDayCell>
        <div className="month">{dates.toMonthShort(suvSingleDay)}</div>
        <div className="day">
          {dates.extractDay(suvSingleDay)}&nbsp;
          {dates.toDayStringLong(suvSingleDay)}
        </div>
      </DailyDayCell>
      {tooltipContent && (
        <TextTooltip
          distance={0}
          delay={250}
          content={tooltipContent}
          placement="bottom"
          className="interactive"
          zIndex={1}
          open={tooltipOpen}
          onOpenChange={setTooltipOpen}
        >
          <DayDots highlights={highlights} large />
        </TextTooltip>
      )}
      {logTimeView && <HoursContainer>{loggedHours}h</HoursContainer>}
    </TopDatePickerWrapper>
  );
}
