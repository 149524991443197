import React from 'react';
import styled from 'styled-components';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { IconChevronLeft } from '@float/ui/icons/essentials/IconChevronLeft';
import { IconChevronRight } from '@float/ui/icons/essentials/IconChevronRight';

export const DesktopOnlySpacer = styled(Spacer)`
  @media (max-width: 767px) {
    display: none;
  }
`;

// using the size/margin hack to make the icons bigger
const leftIcon = <IconChevronLeft size={28} style={{ margin: -2 }} />;
const rightIcon = <IconChevronRight size={28} style={{ margin: -2 }} />;

export default function WeekNav({
  dates,
  logMyTimeView,
  visibleRangeRef,
  actions,
  suvWeek,
  setSuvWeek,
}) {
  if (!visibleRangeRef.current) return null;

  return (
    <>
      <NavIconBtn
        noBorder
        icon={leftIcon}
        onClick={() =>
          logMyTimeView ? setSuvWeek(suvWeek - 1) : actions.scrollWeeks(-1)
        }
      />
      <NavIconBtn
        noBorder
        icon={rightIcon}
        onClick={() =>
          logMyTimeView ? setSuvWeek(suvWeek + 1) : actions.scrollWeeks(1)
        }
      />
      <Spacer size={8} />
      <NavIconBtn
        onClick={() =>
          logMyTimeView
            ? setSuvWeek(dates.getCurrentWeek())
            : actions.scrollToToday()
        }
      >
        Today
      </NavIconBtn>
      <DesktopOnlySpacer size={16} />
    </>
  );
}
