import React, { Component, useMemo, useState } from 'react';
import styled from 'styled-components';

import { formatAmount } from '@float/common/lib/budget';
import { Button } from '@float/ui/deprecated/Button/Button';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { Label } from '@float/ui/deprecated/Label';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

const Content = styled.div`
  p {
    margin-bottom: 30px;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.lightGrey};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: 0 15px 0 24px;
`;

const ClickToConfirm = styled(Label)`
  padding: 0;
  color: ${Colors.FIN.Lt.Emphasis.Medium};
  text-decoration: underline;
  cursor: pointer;
  user-select: none;

  ${IconWrapper} {
    background-color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Bg};

    svg {
      --svg-icon-color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Label};
    }
  }

  &.confirmed {
    color: ${Colors.FIN.Lt.Emphasis.High};
    text-decoration: none;

    ${IconWrapper} {
      background-color: ${Colors.FIN.Lt.Buttons.Danger.Default.Bg};

      svg {
        --svg-icon-color: ${Colors.FIN.Lt.Buttons.Danger.Default.Label};
      }
    }
  }
`;

const isFunction = (fn) => fn && typeof fn === 'function';

function getImpactPhrase(impact) {
  if (!impact) return null;

  if (typeof impact === 'boolean') {
    return ' including all their scheduled and logged tasks';
  }

  const { scheduledTasks, loggedTasks } = impact;
  let phrase = '';
  const formattedScheduledTasksCount =
    scheduledTasks && formatAmount(null, scheduledTasks);
  const formattedLoggedTasksCount =
    loggedTasks && formatAmount(null, loggedTasks);
  if (scheduledTasks) {
    phrase = `${formattedScheduledTasksCount} scheduled`;
    if (loggedTasks) {
      phrase += ` and ${formattedLoggedTasksCount} logged task${
        scheduledTasks === 1 && loggedTasks === 1 ? '' : 's'
      }`;
    } else {
      phrase += ` task${scheduledTasks === 1 ? '' : 's'}`;
    }
  } else if (loggedTasks) {
    phrase = `${formattedLoggedTasksCount} logged task${
      loggedTasks === 1 ? '' : 's'
    }`;
  }
  return (
    <span>
      {' '}
      including at least <strong>{phrase}</strong>
    </span>
  );
}

export const ConfirmDelete = ({
  title,
  message,
  item, // e.g. Project Name, 3 people etc.
  impact, // e.g. { scheduledTasks, loggedTasks }
  twoStep = true,
  deleteLabel = 'Delete',
  confirmationLabel,
  confirmationIcon,
  onDelete,
  onArchive,
  onCancel,
}) => {
  const canArchive = twoStep && isFunction(onArchive);
  const [deleteConfirmed, setDeleteConfirmed] = useState(!twoStep);
  const [deleting, setDeleting] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const cancelStyle = useState(
    canArchive ? { marginLeft: 'auto' } : undefined,
  )[0];
  const impactPhrase = useMemo(() => getImpactPhrase(impact), [impact]);

  if (!onDelete && !onArchive) return null;

  const inProgress = () => deleting || archiving;

  const onDeleteClick = () => {
    if (inProgress()) return;
    setDeleting(true);
    if (onDelete) {
      const promise = onDelete();
      if (promise?.then) {
        return promise.then(onCancel); // close after deleting
      }
      onCancel(); // close after deleting
      return promise;
    }
  };

  const onArchiveClick = () => {
    if (inProgress()) return;
    setArchiving(true);
    if (onArchive) onArchive();
  };

  const deleteBtn = (
    <Button
      appearance="danger"
      loader={deleting}
      disabled={!deleteConfirmed}
      onClick={onDeleteClick}
    >
      {deleteLabel}
    </Button>
  );

  if (!confirmationLabel) {
    confirmationLabel = `Click to confirm you want to proceed to ${deleteLabel.toLowerCase()}`;
  }

  return (
    <Modal
      isOpen
      shouldCloseOnEsc
      shouldCloseOnBgClick={false}
      onClose={onCancel}
    >
      {title && (
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
      )}
      <ModalBody>
        <Content>
          {message}
          {item && (
            <p>
              {item && (
                <>
                  Delete <strong>{item}</strong>
                </>
              )}
              {impactPhrase}?
            </p>
          )}
          {canArchive && (
            <p>To retain this data for reports, consider archiving instead.</p>
          )}
          {twoStep && (
            <ClickToConfirm
              className={deleteConfirmed && 'confirmed'}
              onClick={() => setDeleteConfirmed(!deleteConfirmed)}
            >
              <IconWrapper>
                {confirmationIcon || <Icons.IconTrash />}
              </IconWrapper>
              {confirmationLabel}
            </ClickToConfirm>
          )}
        </Content>
      </ModalBody>
      <ModalActions>
        {deleteConfirmed ? (
          deleteBtn
        ) : (
          <TextTooltip
            className="hover"
            content="Click the bin icon above to activate this button"
            placement="top"
          >
            {deleteBtn}
          </TextTooltip>
        )}
        {canArchive && (
          <Button
            appearance="primary"
            loader={archiving}
            onClick={onArchiveClick}
          >
            Archive
          </Button>
        )}
        <Button
          autoFocus
          appearance="secondary"
          style={cancelStyle}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  );
};

const withConfirmDelete = (Comp) => {
  return class WithConfirm extends Component {
    state = {
      isOpen: false,
    };

    confirmDelete = (options = {}) => {
      this.confirmOptions = options;
      this.setState({ isOpen: true });
    };

    close = () => {
      this.setState({ isOpen: false });
    };

    onCancel = () => {
      this.close();
      if (typeof this.confirmOptions.onCancel === 'function') {
        this.confirmOptions.onCancel();
      }
    };

    render() {
      const { innerRef, ...props } = this.props;
      return (
        <>
          <Comp
            {...props}
            ref={innerRef}
            confirmDelete={this.confirmDelete}
            confirmDeleteClose={this.close}
          />
          {this.state.isOpen && (
            <ConfirmDelete {...this.confirmOptions} onCancel={this.onCancel} />
          )}
        </>
      );
    }
  };
};

/**
 * @deprecated - use `withModalConfirmDelete` instead
 */
export default withConfirmDelete;
