import React from 'react';
import styled from 'styled-components';

import CheckerboardLight from '@float/common/assets/images/checkerboard-light.png';
// This resolves as expected in TS, once the file is converted it should work as expected
// eslint-disable-next-line import/no-unresolved
import { getDiagonalStripeGradient } from '@float/libs/styles/getDiagonalStripeGradient';

import colors from '../../Theme/colors';
import { LIGHT_PURPLE, MID_TEAL, PURPLE, TEAL } from '../constants';
import { getTimeoffHours } from './BarChart.helpers';

// Note that since we use ReactDOMServer to render these, we won't have the
// theme in context and must access colors directly from the component.

const Container = styled.div`
  width: 250px;
  padding: 4px 0 0;
`;

const Header = styled.header`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 5px;
`;

const Row = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: ${() => colors.charcoalGrey};
  text-align: left;
  line-height: 24px;
`;

const Desc = styled.div`
  flex-grow: 1;
`;

const RawValue = styled.div`
  text-align: right;
`;

const PercValue = styled.div`
  width: 50px;
  color: ${(p) => (p.overtime ? colors.red : colors.blueGrey)};
  text-align: right;
  font-size: 14px;
`;

const Hr = styled.hr`
  margin: 5px 0;
  border-top: 1px solid ${() => colors.lightGrey};
`;

const KeySquare = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 7px;
  display: inline-block;
  position: relative;
  top: 2px;

  &.teal {
    background-color: ${TEAL};
  }

  &.purple {
    background-color: ${PURPLE};
  }

  &.tentative-billable {
    background: url(${CheckerboardLight}), ${TEAL};
    background-repeat: repeat;
    background-size: 14px;
  }

  &.tentative-nonbillable {
    background: url(${CheckerboardLight}), ${MID_TEAL};
    background-repeat: repeat;
    background-size: 14px;
  }

  &.mid-teal {
    background-color: ${MID_TEAL};
  }

  &.light-purple {
    background-color: ${LIGHT_PURPLE};
  }

  &.diagonal {
    background: ${getDiagonalStripeGradient('#c1c1c1', -45)};
    background-size: 4px 4px;
  }
`;

const roundNum = (res) => Math.round(res * 100) / 100;
const roundPerc = (res) => (isNaN(res) ? 0 : Math.round(res * 100));

const ScheduledContent = ({ data, useFuture }) => {
  const root = useFuture ? data.future : data;
  const { scheduled, billable, nonbillable, tentative } = root;

  return (
    <>
      <Row>
        <Desc>Scheduled</Desc>
        <RawValue>{roundNum(scheduled)}</RawValue>
        <PercValue overtime={scheduled > data.capacity}>
          {roundPerc(scheduled / (data.capacity || 1))}%
        </PercValue>
      </Row>
      <Row>
        <Desc>
          <KeySquare className="teal" />
          Billable
        </Desc>
        <RawValue>{roundNum(billable)}</RawValue>
        <PercValue>{roundPerc(billable / scheduled)}%</PercValue>
      </Row>
      <Row>
        <Desc>
          <KeySquare className="mid-teal" />
          Non-billable
        </Desc>
        <RawValue>{roundNum(nonbillable)}</RawValue>
        <PercValue>{roundPerc(nonbillable / scheduled)}%</PercValue>
      </Row>
      {(tentative.billable > 0 || tentative.nonbillable > 0) && (
        <>
          <Row>
            <Desc>
              <KeySquare className="tentative-billable" />
              Tentative (Billable)
            </Desc>
            <RawValue>{roundNum(tentative.billable)}</RawValue>
            <PercValue>{roundPerc(tentative.billable / scheduled)}%</PercValue>
          </Row>
          <Row>
            <Desc>
              <KeySquare className="tentative-nonbillable" />
              Tentative (Non-billable)
            </Desc>
            <RawValue>{roundNum(tentative.nonbillable)}</RawValue>
            <PercValue>
              {roundPerc(tentative.nonbillable / scheduled)}%
            </PercValue>
          </Row>
        </>
      )}
    </>
  );
};

const LoggedContent = ({ data }) => {
  const { billable, nonbillable, total } = data.logged;

  return (
    <>
      <Row>
        <Desc>Logged</Desc>
        <RawValue>{roundNum(total)}</RawValue>
        <PercValue overtime={total > data.capacity}>
          {roundPerc(total / (data.capacity || 1))}%
        </PercValue>
      </Row>
      <Row>
        <Desc>
          <KeySquare className="purple" />
          Billable
        </Desc>
        <RawValue>{roundNum(billable)}</RawValue>
        <PercValue>{roundPerc(billable / total)}%</PercValue>
      </Row>
      <Row>
        <Desc>
          <KeySquare className="light-purple" />
          Non-billable
        </Desc>
        <RawValue>{roundNum(nonbillable)}</RawValue>
        <PercValue>{roundPerc(nonbillable / total)}%</PercValue>
      </Row>
    </>
  );
};

const TippyContent = ({
  data,
  showLogged,
  showCombined,
  noCapacity,
  noTentativeTimeoff,
  noTimeoff,
}) => {
  return (
    <Container>
      <Header>
        {data.tooltipStartDate}
        {data.tooltipEndDate && ` - ${data.tooltipEndDate}`}
      </Header>
      {!noCapacity && (
        <>
          <Row>
            <Desc>Capacity</Desc>
            <RawValue>{roundNum(data.capacity)}</RawValue>
            <PercValue />
          </Row>
          <Hr />
        </>
      )}
      {showLogged && <LoggedContent data={data} />}
      {showCombined && <div style={{ height: 10 }} />}
      {(!showLogged || showCombined) && (
        <ScheduledContent data={data} useFuture={showCombined} />
      )}
      {!noCapacity && (
        <>
          <Hr />
          <Row>
            <Desc>
              <KeySquare className="diagonal" />
              Time off
            </Desc>
            <RawValue>
              {roundNum(getTimeoffHours(data, noTentativeTimeoff))}
            </RawValue>
            <PercValue />
          </Row>
        </>
      )}
    </Container>
  );
};

export default TippyContent;
