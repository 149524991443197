import { useCallback, useRef, useState } from 'react';
import useMount from 'react-use/esm/useMount';

import { FloatStorageKey, storage } from '@float/libs/storage';

async function getStoredSuvWeek() {
  const week = await storage.getItem(FloatStorageKey.SerenaSUVWeek);
  if (week === 'null' || typeof week === 'undefined') return null;
  return Number(week);
}

function storeSuvWeek(val: number | null) {
  storage.setItem(FloatStorageKey.SerenaSUVWeek, String(val));
}

// Used to synchronize the schedule and log time tabs on the same week if the
// current user is a "Single User View" user (suv), and persist the current week
// to storage so that it can be restored on page load.
export const usePersistedCurrentWeekForSingleUserView = (
  dates: DatesManager,
) => {
  const [suvWeek, setSuvWeekState] = useState<number | null>(null);
  const suvWeekRef = useRef<number | null>(null);

  useMount(async () => {
    const currentWeek = dates.getCurrentWeek();
    const storedWeek = await getStoredSuvWeek();

    // If suvWeek has been set before the stored value is fetched, don't run the effect
    // as it would override the value set by setSuvWeek
    if (suvWeekRef.current !== null) return;

    if (typeof storedWeek === 'number') {
      setSuvWeek(currentWeek + storedWeek);
    } else {
      setSuvWeek(currentWeek);
    }
  });

  const setSuvWeek = useCallback(
    (val: number) => {
      setSuvWeekState(val);
      suvWeekRef.current = val;
    },
    [setSuvWeekState],
  );

  const setAndSaveSuvWeek = (val: number) => {
    if (typeof val === 'function') throw Error('not supported');

    const curWeek = dates.getCurrentWeek();

    if (val === curWeek - 1) storeSuvWeek(-1);
    else if (val === curWeek) storeSuvWeek(0);
    else if (val === curWeek + 1) storeSuvWeek(1);
    else storeSuvWeek(null);

    setSuvWeek(val);
  };

  return {
    suvWeek: suvWeek ?? dates.getCurrentWeek(),
    setSuvWeek,
    setAndSaveSuvWeek,
  };
};
