import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { formatDomains } from 'manage/people-v2/_helpers';
import { PersonAccess } from 'manage/people-v2/modals/PersonModal/access/PersonAccess';
import PersonModalHeader from 'manage/people-v2/modals/PersonModal/PersonModalHeader';
import { PersonModalTabs } from 'manage/people-v2/modals/PersonModal/PersonModalTabs';
import PersonPeople from 'manage/people-v2/modals/PersonModal/PersonPeople';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import { compose } from 'redux';

import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons/TextButton';
import { bindVal, getErrors } from '@float/ui/deprecated/helpers/forms';
import { Hr } from '@float/ui/deprecated/Hr/Hr';
import { Col, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { Modal } from '@float/ui/deprecated/Modal';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { withSnackbar } from '@float/ui/deprecated/Snackbar';
import { SecondaryText } from '@float/ui/deprecated/Text';
import TextAreaField from '@float/ui/deprecated/TextArea/TextAreaField';

import {
  addAccount,
  resendAccountInvite,
  updateGuest,
} from '../actions/accounts';
import { GuestAccountActionsMenu } from './GuestAccountActionsMenu';

const footnoteStyle = { marginTop: 30, marginBottom: -10 };

const MODAL_TYPE = 'guestAccountModal';

class GuestAccountModal extends React.Component {
  state = {
    isLoading: !!this.props.modalSettings.accountId,
    isSubmitting: false,
    activeTab: 'access',
    form: {
      account: {},
      name: '',
      email: '',
      account_type: 4,
      message: '',
      department_filter: null,
      management_group: {
        departments: [],
        people: [],
      },
    },
    formErrors: {
      name: [],
      email: [],
      account_type: [],
    },
  };

  componentDidMount() {
    this.setAccessOptions();
    this.setInitialFormData();
  }

  setAccessOptions = () => {
    const accessOptions = [
      { value: 4, label: 'Member' },
      { value: 7, label: 'Manager' },
    ];

    accessOptions.push({ value: 2, label: 'Admin' });

    if (this.isCurrentUserOwnerOrBilling()) {
      accessOptions.push({
        value: 5,
        label: 'Billing',
      });
    }
    this.setState({ accessOptions });
  };

  setInitialFormData = () => {
    const { accountId } = this.props.modalSettings;
    if (!accountId) {
      return;
    }

    const account = this.props.accounts.find((a) => a.account_id === accountId);
    if (account.department_filter?.length) {
      account.department_filter_set = true;
    }

    const initialForm = account
      ? {
          account: account,
          name: account.name,
          email: account.email,
          account_type: account.account_type,
          department_filter: account.department_filter,
          department_filter_set: account.department_filter?.length > 0,
          created: account.created,
        }
      : {};

    this.setState({
      isLoading: false,
      account,
      form: initialForm,
    });
  };

  isDomainRestrictedError = (e) => {
    const error = e[0];
    return (
      error?.field === 'email' && error?.message === 'Domain not permitted.'
    );
  };

  updateFormState = (updates) => {
    this.setState((ps) => {
      const nextForm = { ...ps.form, ...updates };
      const nextAcc = { ...nextForm.account, ...updates };

      // clear access form errors when updating the access role
      const errors = {};
      if (updates.account_type) {
        errors.formErrors = {
          ...this.state.formErrors,
          account_type: [],
        };
      }

      return {
        ...errors,
        form: {
          ...nextForm,
          account: nextAcc,
        },
      };
    });
  };

  setPersonAvatarRef = (el) => {
    this.personAvatar = el?.personAvatar;
  };

  close = () => {
    this.props.manageModal({ visible: false, modalType: MODAL_TYPE });
  };

  validate = () => {
    const { form } = this.state;

    const nameErrors = getErrors.name(form.name);
    const emailErrors = getErrors.email(form.email, {
      currentUserAccount: this.state.account,
      existingAccounts: this.props.accounts,
      existingPeople: this.props.people,
    });
    const accessErrors = getErrors.access(form.account);

    this.setState({
      formErrors: {
        name: nameErrors,
        email: emailErrors,
        account_type: accessErrors,
      },
    });

    return isEmpty(nameErrors) && isEmpty(emailErrors) && isEmpty(accessErrors);
  };

  isMemberSelected = (accountType) => accountType === 4;

  isOwnerOrBillingSelected = () =>
    [1, 5].includes(this.state.form.account_type);

  isCurrentUserAdmin = () => this.props.currentUser.account_tid === 2;

  isCurrentUserOwnerOrBilling = () =>
    [1, 5].includes(this.props.currentUser.account_tid);

  // does current user have lower rights than the one being edited?
  // e.g. admin editing an account owner
  isReadOnly = () => {
    return this.isCurrentUserAdmin() && this.isOwnerOrBillingSelected();
  };

  resendInvite = async (evt) => {
    prevent(evt);
    const res = await this.props.resendAccountInvite(
      this.props.modalSettings.accountId,
    );
    this.props.showSnackbar(`Invite for ${res.entity.name} resent.`);
    this.close();
  };

  getCleanAccountPayload = ({
    department_filter_set,
    account_type,
    ...form
  }) => {
    const dto = { ...form, account_type_id: account_type };
    if (!department_filter_set || !dto.department_filter?.length) {
      dto.department_filter = null;
    }

    return dto;
  };

  submit = async (evt) => {
    prevent(evt);

    if (this.validate()) {
      const account = this.getCleanAccountPayload(this.state.form);

      this.setState({ isSubmitting: true });

      const { accountId } = this.props.modalSettings;
      if (accountId) {
        this.personAvatar.save({ account_id: accountId });
        try {
          await this.props.updateGuest(accountId, account);
        } catch (e) {
          if (this.isDomainRestrictedError(e)) {
            this.setState({
              isSubmitting: false,
              formErrors: {
                email: [
                  `${formatDomains(this.props.domains)} account required.`,
                ],
              },
            });
            return;
          }
          throw e;
        }
        this.props.showSnackbar(`${account.name} updated.`);
      } else {
        let accountAdded = {};
        try {
          accountAdded = await this.props.addAccount(account);
        } catch (e) {
          if (this.isDomainRestrictedError(e)) {
            this.setState({
              isSubmitting: false,
              formErrors: {
                email: [
                  `${formatDomains(this.props.domains)} account required.`,
                ],
              },
            });
            return;
          }
          throw e;
        }
        const { payload: { account_id = null } = {} } = accountAdded;
        await this.personAvatar.save({ account_id });
        this.props.showSnackbar(`${account.name} added.`);
      }

      this.setState({ isSubmitting: false });
      this.close();
    }
  };

  handleDelete = () => {
    const { account } = this.state.form;

    this.props.confirm({
      title: 'Delete guest',
      message: `${account.name} will no longer be able to login.
              This action cannot be undone.
              Projects where they are the Project Owner will be
              assigned to the Account Owner.`,
      onConfirm: async () => {
        this.props.onDelete(account.account_id).then(() => {
          this.props.showSnackbar(`${account.name} deleted.`);
          this.close();
        });
      },
    });
  };

  renderActiveTabReadOnly = () => {
    switch (this.state.activeTab) {
      case 'access':
        return <PersonAccess />;
      case 'manages':
        return (
          <PersonPeople
            personName={this.state.form.name}
            account={this.state.form.account}
          />
        );
      default:
        return null;
    }
  };

  renderActiveTab = () => {
    const isNew = !this.props.modalSettings.accountId;
    const { form, formErrors } = this.state;
    const account = form.account || {};

    switch (this.state.activeTab) {
      case 'access':
        return (
          (!isEmpty(form.account) || isNew) && (
            <PersonAccess
              formErrors={formErrors}
              isNew={isNew}
              guestMode
              user={form}
              account={account}
              hideClearAccess={true}
              onChange={(change) => {
                this.updateFormState(change);
              }}
            />
          )
        );
      case 'manages':
        return (
          <PersonPeople
            account={account}
            onChange={(nextAccount) => {
              this.updateFormState(nextAccount);
            }}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const isNew = !this.props.modalSettings.accountId;
    const { form, formErrors, activeTab } = this.state;
    const isReadOnly = this.isReadOnly();

    return (
      <Modal isOpen onClose={this.close}>
        <form onSubmit={this.submit}>
          <Modal.Header
            style={{
              paddingBottom: 0,
              paddingRight: 32,
            }}
          >
            <Col alignItems="flex-start">
              <Modal.Title>{isNew ? 'Invite' : 'Update'} guest</Modal.Title>
              <Spacer size={22} />
              <PersonModalHeader
                ref={this.setPersonAvatarRef}
                readOnly={isReadOnly}
                account={form.account}
                form={form}
                initialPerson={form}
                formErrors={formErrors}
                onFieldChange={(fieldName, value) => {
                  const { onChange } = bindVal(this, fieldName);
                  onChange(value);
                }}
              />
              <PersonModalTabs
                guestMode
                form={form}
                initialPerson={form}
                activeTab={activeTab}
                formErrors={formErrors}
                onTabChange={(tabKey) => {
                  this.setState({ activeTab: tabKey });
                }}
              />
            </Col>
          </Modal.Header>
          <Modal.Body>
            {this.renderActiveTab()}
            {isNew && (
              <>
                <Hr />
                <TextAreaField
                  style={{ width: '100%' }}
                  label="Message"
                  {...bindVal(this, 'message')}
                />
                {/* <Spacer size={22} /> */}
                <SecondaryText style={footnoteStyle}>
                  When you invite them we'll send an email with login details.
                </SecondaryText>
                {!isNew &&
                  this.state.account &&
                  !this.state.account.accepted && (
                    <SecondaryText style={footnoteStyle}>
                      Invited on{' '}
                      {moment(this.state.account.created).format('DD MMM YYYY')}
                      .{' '}
                      <TextButton
                        appearance="flay-underline"
                        onClick={this.resendInvite}
                      >
                        Resend invite
                      </TextButton>
                    </SecondaryText>
                  )}
              </>
            )}
          </Modal.Body>
          <Modal.Actions style={{ paddingTop: 32 }}>
            {!isReadOnly && (
              <Button onClick={this.submit} loader={this.state.isSubmitting}>
                {isNew ? 'Invite' : 'Update'}
              </Button>
            )}
            <Button appearance="secondary" onClick={this.close}>
              {isReadOnly ? 'Close' : 'Cancel'}
            </Button>
            {!isNew && form.account_type != 1 && !isReadOnly && (
              <GuestAccountActionsMenu onDelete={this.handleDelete} />
            )}
          </Modal.Actions>
          <input type="submit" style={{ display: 'none' }} />
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  accounts: state.settingsAccounts.entities,
  people: state.people.people,
  domains: state.companyPrefs.domains,
});

const mapDispatchToProps = {
  addAccount,
  updateGuest,
  resendAccountInvite,
};

const Component = compose(withConfirm, withSnackbar)(GuestAccountModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  modalManagerHoc({
    Comp: Component,
    modalType: MODAL_TYPE,
  }),
);
