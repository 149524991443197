import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated/Button/Button';
import { Card } from '@float/ui/deprecated/Earhart/Cards';

import { StyledRow } from '../styles';
import SamlModal from './SamlModal';

type SamlAuthenticationSectionProps = {
  sso: {
    endpoint: string;
    issuer_url: string;
    certificate: string;
  };
  companyId: number;
  hasTimeTracking: boolean;
  hasPlusPack: boolean;
  ssoRequired: boolean;
  onSubmit: () => void;
};

export const SamlAuthenticationSection = (
  props: SamlAuthenticationSectionProps,
) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const isEnabled = props.hasTimeTracking || props.hasPlusPack;

  const onClickShowForm = () => {
    setPopupOpen(true);
  };

  const onClickClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <Card>
      {popupOpen && (
        <SamlModal
          sso={props.sso}
          onSubmit={props.onSubmit}
          close={onClickClosePopup}
          ssoRequired={props.ssoRequired}
        />
      )}

      <StyledRow>
        <h4>
          <Trans>SAML single sign-on (SSO)</Trans>
        </h4>
      </StyledRow>

      <StyledRow>
        <p>
          <Trans>
            Require your team to sign in using{' '}
            <a
              href="https://support.float.com/en/articles/3941805-microsoft-entra-id-formerly-azure-ad"
              target="_blank"
              rel="noopener noreferrer"
            >
              Entra ID
            </a>
            {', '}
            <a
              href="http://support.float.com/account-settings/onelogin-setup"
              target="_blank"
              rel="noopener noreferrer"
            >
              OneLogin{' '}
            </a>
            and{' '}
            <a
              href="http://support.float.com/account-settings/okta-setup"
              target="_blank"
              rel="noopener noreferrer"
            >
              Okta
            </a>
            . Select Float from your identity provider’s app directory. Learn
            more about setting up SSO{' '}
            <a
              href="https://intercom.help/float/en/articles/2360130-single-sign-on-and-google-sign-in"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </Trans>
        </p>
      </StyledRow>

      <StyledRow direction="row">
        <Button
          appearance="secondary"
          size="large"
          alignment="center"
          disabled={!isEnabled}
          onClick={onClickShowForm}
        >
          <Trans>Configure</Trans>
        </Button>
      </StyledRow>
    </Card>
  );
};
