import React from 'react';

import { addSubDepartmentIcon } from './helpers/addSubDepartmentIcon';
import { usePersonDepartment } from './hooks/usePersonDepartment';
import { PersonDepartmentProps } from './PersonDepartment.types';
import { PersonDepartmentView } from './views/PersonDepartmentView';

export const PersonDepartment = (props: PersonDepartmentProps) => {
  const {
    account,
    departmentId,
    departmentOptions,
    departments,
    formErrors,
    isLimitedByDepartment,
    onDepartmentChange,
    placeholder,
  } = props;

  const { canCreateDepartments, onChange } = usePersonDepartment({
    account,
    departmentId,
    onDepartmentChange,
  });

  const options = addSubDepartmentIcon(departmentOptions, departments);

  return (
    <PersonDepartmentView
      canClearSelection={!isLimitedByDepartment}
      canCreate={canCreateDepartments}
      departmentId={departmentId}
      departmentOptions={options}
      errors={formErrors}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
