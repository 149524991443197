import React from 'react';
import { createPortal } from 'react-dom';

import Theme from '@float/ui/deprecated/Theme';

import { ScheduleActions } from '../../../types';
import { MainCellItem } from '../../box/BoxElements/types';

import * as styles from './styles.css';

export type LinkArrowProps = {
  linkedArrowTargetRef?: React.MutableRefObject<SVGSVGElement | null>;
  item: MainCellItem;
  color: string;
  opacity?: number | string;
  allowRemove?: boolean;
  parent: MainCellItem;
  path: string;
  arrowPath: string;
  midpoint: {
    x: number;
    y: number;
  };
  actions: ScheduleActions;
};

const LinkArrow = (props: LinkArrowProps) => {
  const {
    actions,
    item,
    color,
    opacity,
    linkedArrowTargetRef,
    allowRemove,
    parent,
    path,
    arrowPath,
    midpoint,
  } = props;

  if (!linkedArrowTargetRef?.current) return null;

  const key = `${item.entityId}:${parent.entityId}`;

  return createPortal(
    <g
      className={styles.linkArrow}
      key={key}
      pointerEvents={actions.linkInfo?.base ? 'none' : 'auto'}
    >
      <path
        className={styles.linkArrowPath}
        d={path}
        stroke={color}
        opacity={opacity}
        strokeWidth="1"
        fill="none"
      />
      <path
        className={styles.linkArrowPath}
        d={path}
        stroke="transparent"
        strokeWidth="20"
        fill="none"
      />
      <path
        className={styles.linkArrowPath}
        d={arrowPath}
        fill={color}
        opacity={opacity}
      />
      {allowRemove && (
        <g style={{ transform: `translate(${midpoint.x}px, ${midpoint.y}px)` }}>
          <circle
            className={styles.linkArrowCircle}
            cx={0}
            cy={0}
            r="8"
            fill={Theme.charcoalGrey}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onClick={(event) => {
              event.stopPropagation();
              actions.linkDelete(item);
            }}
          />
          <path
            className={styles.removeIcon}
            fill="white"
            fillRule="evenodd"
            d="M17 8.4L15.6 7 12 10.6 8.4 7 7 8.4l3.6 3.6L7 15.6 8.4 17l3.6-3.6 3.6 3.6 1.4-1.4-3.6-3.6z"
          />
        </g>
      )}
    </g>,
    linkedArrowTargetRef.current,
  );
};

export default LinkArrow;
