import React, { CSSProperties, useState } from 'react';
import { Trans } from '@lingui/macro';

import * as styles from '@float/ui/deprecated/ColorPicker/styles.css';
import EHIconArrowDown from '@float/ui/deprecated/Earhart/Icons/Icon/IconArrowDown';
import { FieldLabel } from '@float/ui/deprecated/Label';

import { ColorPickerDropdown } from './ColorPickerDropdown/ColorPickerDropdown';
import { ColorPickerSwatch } from './ColorPickerDropdown/ColorPickerSwatch';

export type ColorPickerProps = {
  value: string;
  onChange: (value: string) => void;
  highContrast?: boolean;
  clientColors?: string[];
  recentColors?: string[];
  style?: CSSProperties;
};

export const ColorPicker: React.FC<ColorPickerProps> = ({
  value,
  onChange,
  highContrast,
  clientColors,
  recentColors,
  style,
}) => {
  const [open, setOpen] = useState(false);

  const toggle = () => (open ? setOpen(false) : setOpen(true));

  return (
    <div className={styles.colorPickerFieldWrapper} style={style}>
      <FieldLabel>
        <Trans>Color</Trans>
      </FieldLabel>

      <div className={styles.colorPickerInputWrapper}>
        <ColorPickerDropdown
          highContrastDefaultColors={highContrast}
          value={value}
          clientColors={clientColors}
          recentColors={recentColors}
          onChange={onChange}
          onOpenChange={setOpen}
          open={open}
        >
          <ColorPickerSwatch color={value} />
        </ColorPickerDropdown>
        <EHIconArrowDown onClick={toggle} />
      </div>
    </div>
  );
};
