import styled from 'styled-components';

import { Button } from '@float/ui/deprecated/Button/Button';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;

  & > div {
    margin-bottom: 0;
    margin-right: 16px;
  }

  ${
    // @ts-expect-error – Button is not typed
    Button.Styled
  } {
    margin-left: auto;
    margin-top: auto;
    min-width: 110px;
  }
`;

export const ScrollableList = styled.ul`
  margin-bottom: 42px;
  min-height: 150px;

  li {
    margin-left: 0;
  }
`;

export const NoTaskNames = styled.div`
  ${Typography.TextL.R400};

  line-height: 150px;

  color: ${Colors.FIN.Lt.Emphasis.Low};

  text-align: center;

  margin: auto;
`;

export const TasksHeader = styled.div`
  margin: 11px 0 9px;
  height: 34px;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  color: ${(p) => p.theme.blueGrey};
`;

export const MultiSelectActions = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Actions = styled.div`
  button + button {
    margin-left: 5px;
  }
`;

export const StyledCheckboxField = styled(Checkbox)`
  margin-top: 16px;
`;
