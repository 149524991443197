import React, { useRef } from 'react';
import { t, Trans } from '@lingui/macro';

import { useOnMount } from '@float/libs/hooks/useOnMount';
import { Input } from '@float/ui/deprecated/Input';

import { useInputAllocationHours } from './hooks/useInputAllocationHours';

import * as styles from './InputAllocationHours.css';

export type InputAllocationHoursProps = {
  hoursPerDay: number | null;
  hoursTotal: number | null;
  isReadOnly?: boolean;
  isTotalHoursInputDisabled?: boolean;
  isTotalHoursInputSubdued?: boolean;
  hasVaryingDailyHours?: boolean;
  hasHoursPerDaySuffix?: boolean;
  onChangeHoursPerDay: (hoursPerDay: number | null) => void;
  onChangeHoursTotal: (hoursTotal: number | null) => void;
  onBlurHoursPerDay: () => void;
  onBlurHoursTotal: () => void;
};

export const InputAllocationHours = (props: InputAllocationHoursProps) => {
  const {
    hoursPerDay,
    hoursTotal,
    isReadOnly,
    isTotalHoursInputDisabled,
    isTotalHoursInputSubdued,
    hasVaryingDailyHours,
    hasHoursPerDaySuffix,
    onChangeHoursPerDay,
    onChangeHoursTotal,
    onBlurHoursPerDay,
    onBlurHoursTotal,
  } = props;

  const hoursPerDayValue = hasVaryingDailyHours ? '' : hoursPerDay;
  const hoursPerDayPlaceholder = hasVaryingDailyHours ? '-' : undefined;
  const hoursTotalValue = hasVaryingDailyHours ? '' : hoursTotal;
  const hoursTotalPlaceholder = hasVaryingDailyHours ? '-' : undefined;

  const inputHoursPerDayRef = useRef<HTMLInputElement>();

  // When this component appears in the DOM we want it to focus,
  // so user can start typing without an extra click
  useOnMount(() => {
    if (isReadOnly) return;

    inputHoursPerDayRef.current?.focus();
  });

  const { handleHoursPerDayChange, handleHoursTotalChange } =
    useInputAllocationHours({
      hoursPerDay,
      hoursTotal,
      onChangeHoursPerDay,
      onChangeHoursTotal,
    });

  const handleHoursTotalKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    // When the total hours reaches the hours per day value we
    // want to start decreasing both values by 0.5
    if (
      e.target instanceof HTMLInputElement &&
      e.key === 'ArrowDown' &&
      hoursTotal === hoursPerDay
    ) {
      e.preventDefault();
      e.stopPropagation();

      // The hours per day value can't be less than 0.01
      const newValue = Math.max(0.01, Number(e.target.value) - 0.5);

      onChangeHoursPerDay(newValue);
      onChangeHoursTotal(newValue);
    }
  };

  const handleHoursPerDayKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onBlurHoursPerDay();
    }
  };

  const handleHoursTotalKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onBlurHoursTotal();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <Input
          inputRef={inputHoursPerDayRef}
          appearance="underline"
          type="number"
          hideArrows
          selectTextOnFocus
          value={hoursPerDayValue}
          placeholder={hoursPerDayPlaceholder}
          min={0.25}
          step={0.25}
          readOnly={isReadOnly}
          noBorder={isReadOnly}
          onChange={handleHoursPerDayChange}
          onKeyPress={handleHoursPerDayKeyPress}
          onBlur={onBlurHoursPerDay}
          isStaticPlaceholder
          ariaLabel={t`Hours per day`}
          suffix={
            hasHoursPerDaySuffix && !isReadOnly ? (
              <span className={styles.inputSuffix}>
                <Trans>h/day</Trans>
              </span>
            ) : undefined
          }
        />
      </div>
      <div className={styles.column}>
        <Input
          appearance="underline"
          inputClassName={
            !isReadOnly && isTotalHoursInputSubdued && styles.inputSubdued
          }
          type="number"
          hideArrows
          selectTextOnFocus
          value={hoursTotalValue}
          placeholder={hoursTotalPlaceholder}
          min={hoursPerDay}
          step={hoursPerDay}
          readOnly={isReadOnly || isTotalHoursInputDisabled}
          disabled={isTotalHoursInputDisabled}
          noBorder={isReadOnly}
          noBackground={isTotalHoursInputDisabled}
          onChange={handleHoursTotalChange}
          onKeyPress={handleHoursTotalKeyPress}
          onKeyDown={handleHoursTotalKeyDown}
          onBlur={onBlurHoursTotal}
          isStaticPlaceholder
          ariaLabel={t`Total hours`}
        />
      </div>
    </div>
  );
};
