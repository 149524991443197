import { trackEvent } from '@float/common/lib/analytics';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { AccountType } from '@float/types/account';
import { PageData } from '@float/types/pageData';

/**
 * Tracks the "Hide Phases" experiment (ACTEX-2) for users who match the
 * targeting criteria.
 *
 * This function sends an event to Intercom when a user is an account owner and
 * is part of the variation group where the "phases" section in the project side
 * panel is hidden.
 *
 * @param {PageData['user']} user - The user object, containing information
 * about the current user and their account.
 *
 * @remarks
 * This event is part of the ACTEX-2 "Hide Phases" experiment tracking, designed
 * to capture interactions with the variation group that does not see the phases
 * section. It triggers only for users with the `AccountOwner` role
 * (`AccountType.AccountOwner`).
 */

export const trackHidePhasesExperiment = (user: PageData['user']) => {
  if (
    user.user.account_tid === AccountType.AccountOwner &&
    featureFlags.isFeatureEnabled(
      FeatureFlag.ActivationSquadHidePhasesInProjectSidePanel,
    )
  ) {
    const data = {
      experiment_id: 'ACTEX-2',
      experiment_name: 'Hide Phases',
      team_id: user.user.cid,
    };

    trackEvent('Experiment Viewed', data);
  }
};
