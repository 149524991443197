import React, { useState } from 'react';
import { get, isEmpty, keyBy } from 'lodash';
import styled from 'styled-components';

import { Button } from '@float/ui/deprecated/Button/Button';
import { Checkbox } from '@float/ui/deprecated/Checkbox';
import { Modal } from '@float/ui/deprecated/Modal';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { Radio } from '@float/ui/deprecated/Radio/Radio';
import * as Theme from '@float/ui/deprecated/Theme';

const CheckBoxesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const CheckboxLabel = styled.p`
  color: #363d46;
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
  margin-bottom: 0 !important;
  cursor: pointer;
`;

const CheckboxInnerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const CheckboxSublabel = styled.p`
  color: #868d92;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0 !important;
  cursor: pointer;
  flex: 1;
`;

const stringifyMatchingPeople = (people) => {
  if (!people || !people.length) {
    return '';
  }
  if (people.length === 1) {
    return people[0].name;
  }
  if (people.length > 1) {
    const names = people.map((p) => p.name);
    const lastOne = names.pop();
    return names.join(',') + ' or ' + lastOne;
  }
};

const defaultLabelFn = ({ name }) => name;

const SetupModal = ({
  title,
  choices,
  setChoices,
  multiChoice = true,
  defaultValue = null,
  allOption,
  onClose,
  displaySimilarNames,
  footer,
  keyField = 'name',
  labelFn = defaultLabelFn,
}) => {
  const [localChoices, setLocalChoices] = useState(
    multiChoice ? keyBy(choices, keyField) : [defaultValue],
  );

  function setChoice(choice) {
    setLocalChoices({
      ...localChoices,
      [choice[keyField]]: {
        ...choice,
        import: !localChoices[choice[keyField]].import,
      },
    });
  }

  function setAll(value) {
    setLocalChoices(
      keyBy(
        Object.values(localChoices).map((choice) => ({
          ...choice,
          import: value,
        })),
        keyField,
      ),
    );
  }

  function setRadioChoice(key) {
    setLocalChoices(choices.filter((choice) => choice.key === key));
  }

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {multiChoice ? (
          <CheckBoxesList>
            {allOption && (
              <CheckboxWrapper>
                <CheckboxInnerWrapper>
                  <Checkbox
                    key={'all-choices'}
                    value={Object.values(localChoices).every((c) => c.import)}
                    label={allOption}
                    onChange={setAll}
                  />
                </CheckboxInnerWrapper>
                {displaySimilarNames &&
                  Object.values(localChoices).some(
                    (choice) => !isEmpty(choice.matchingPeople),
                  ) && (
                    <CheckboxSublabel>
                      Similar names already in Float...
                    </CheckboxSublabel>
                  )}
              </CheckboxWrapper>
            )}
            {Object.values(localChoices).map((choice) => (
              <CheckboxWrapper key={choice[keyField]}>
                <CheckboxInnerWrapper>
                  <Checkbox
                    value={choice.import}
                    onChange={() => setChoice(choice)}
                  />
                  <CheckboxLabel onClick={() => setChoice(choice)}>
                    {labelFn(choice)}
                  </CheckboxLabel>
                </CheckboxInnerWrapper>
                {displaySimilarNames && !isEmpty(choice.matchingPeople) && (
                  <CheckboxSublabel onClick={() => setChoice(choice)}>
                    {stringifyMatchingPeople(choice.matchingPeople)}
                  </CheckboxSublabel>
                )}
              </CheckboxWrapper>
            ))}
          </CheckBoxesList>
        ) : (
          <Radio
            onChange={setRadioChoice}
            value={get(localChoices, '[0].key')}
            iconType="default"
            options={choices.map((f) => ({
              ...f,
              value: f.key,
              label: labelFn(f),
            }))}
          />
        )}
        {footer && (
          <p style={{ color: Theme.blueGrey, margin: '25px 0 0' }}>{footer}</p>
        )}
        <Modal.Actions>
          <Button
            onClick={() => {
              setChoices(Object.values(localChoices));
              onClose();
            }}
          >
            Continue
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal.Body>
    </Modal>
  );
};

export default withConfirm(SetupModal);
