import { ProjectCreateOptions, ProjectInputData } from '@float/common/actions';
import { Milestone, ProjectMilestoneRecord } from '@float/types/milestone';
import {
  Phase,
  PhaseFormData,
  PhaseInputData,
  ProjectPhaseRecord,
} from '@float/types/phase';
import { Project, ProjectTeamMemberData } from '@float/types/project';
import { ProjectTaskRecord, TaskMeta } from '@float/types/task';

import { AccordionEntry } from './hooks/useAccordionState';

export type { ProjectPhaseRecord, ProjectTaskRecord, ProjectMilestoneRecord };

// The budget_type is undefined when it is not accessible by the user
export const isBudgetNotAccessible = (
  value: ProjectInputData['budget_type'],
): value is undefined => value === undefined;

export enum FormType {
  Phase = 'phase',
  PhaseTemplate = 'phaseTemplate',
  Project = 'project',
  ProjectTemplate = 'template',
}

export type ProjectFormData = {
  type: FormType;
  projectId?: number;
  templateId?: number;
  team: ProjectTeamMemberData[];
  phases: ProjectPhaseRecord[];
  tasks: ProjectTaskRecord[];
  milestones: ProjectMilestoneRecord[];
  project: ProjectInputData;
  // used only in the Phase side panel form
  phase?: PhaseInputData;
  // used only in the Project side panel form
  projectsByCode?: Record<string, Project['project_id'][]>;
};

export type ProjectFormInitialData = {
  type: FormType;
  templateId?: number;
  project?: Project;
  phases: Phase[];
  tasks: TaskMeta[];
  milestones: Milestone[];
  // used only in the Phase side panel form
  phase?: Phase;
  // used only in the Project side panel form
  projectsByCode?: Record<string, Project['project_id'][]>;
};

export type ProjectIdOrTemplateId =
  | { projectId: number; templateId?: never }
  | { templateId: number; projectId?: never }
  | { projectId?: never; templateId?: never };

export type ProjectEditData = ProjectIdOrTemplateId & {
  hideDelete: boolean;
  hideTasksTab: boolean;
  openSections?: AccordionEntry[];
  entityToAdd?: RowEntity;
  // name passed from the allocation modal when creating a new project on the fly
  projectName?: string;
  // status passed from the optional entrypoint - "pre project creation" modal
  status?: Project['status'];
  afterSave?: (id: number | string) => void;
};

export type TemplateData = ProjectIdOrTemplateId;

// The dirty fields on the project form which are of interest to the phase panel
// because they impact the phase form (e.g. phase, budget, status, etc.)
export type UnsavedProjectDataForPhase = {
  project: Partial<ProjectInputData>;
  teamDiff?: { add?: ProjectTeamMemberData[]; del?: number[] };
};

export type PhaseEditData = ProjectIdOrTemplateId & {
  phaseId?: number;
  // values from the project form that need to seed the phase form
  projectPhaseRecord?: ProjectPhaseRecord;
  // dates are used when creating new phase by dragging on the schedule
  startDate?: string;
  endDate?: string;
  // project fields to populate phase panel header and budget info (for new projects)
  unsavedProject?: Partial<UnsavedProjectDataForPhase>;
  // set to initialize the phase panel in template mode
  isTemplate?: boolean;
  afterSave?: (data: PhaseFormData) => void;
};

export enum RowEntity {
  Team = 'team',
  Phase = 'phase',
  Task = 'task',
  Milestone = 'milestone',
}

export type HandleProjectCreateProps = Pick<
  ProjectFormData,
  'project' | 'team'
> & {
  phases?: ProjectPhaseRecord[];
  tasks?: ProjectTaskRecord[];
  milestones?: ProjectMilestoneRecord[];
  options?: ProjectCreateOptions;
};

type ProjectUpdateFormData = {
  project: Partial<ProjectFormData['project']>;
  team: ProjectFormData['team'];
  teamDiff?: UnsavedProjectDataForPhase['teamDiff'];
};

export type HandleProjectUpdateProps = {
  projectId: number;
  update: ProjectUpdateFormData;
  currentValues?: ProjectUpdateFormData;
};
