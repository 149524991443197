import React from 'react';
import styled from 'styled-components';

import { Rights } from '@float/common/lib/acl';
import { personCanOnlyRequestTimeoff } from '@float/common/lib/rights';
import {
  CurrentUser,
  Person,
  Status,
  Task,
  TaskMode,
  Timeoff,
} from '@float/types';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';
import { StyledTab } from '@float/ui/deprecated/Tab/Tab';

import { canSeeStatusTab } from './acl/canSeeStatusTab';

const TabsContainer = styled.div`
  padding: 18px 0 12px 0;
  ${StyledTab} {
    align-items: center;
  }
`;

export type TaskModeTabsProps = {
  user: CurrentUser;
  peopleMap: Record<number, Person>;
  form: {
    task: (Task | Timeoff | Status) & { people?: Person };
    taskMode: TaskMode;
    isOffWork: boolean;
    peopleIds: number[];
  };
  onTabChange(taskMode: TaskMode): void;
};

function getTaskModeOptions({
  user,
  peopleMap,
  task,
  isOffWork,
  peopleIds,
}: {
  user: CurrentUser;
  peopleMap: Record<number, Person>;
  task: (Task | Timeoff | Status) & { people?: Person };
  isOffWork: boolean;
  peopleIds: number[];
}) {
  const placeholder = task.people?.people_type_id === 3;

  const isRequestingTimeoff =
    peopleIds.length === 1 && peopleMap[peopleIds[0]]
      ? personCanOnlyRequestTimeoff(peopleMap[peopleIds[0]], user)
      : false;

  const isTask = 'task_id' in task && Boolean(task.task_id);
  const isTimeoff =
    ('timeoff_id' in task && Boolean(task.timeoff_id)) || isRequestingTimeoff;
  const isStatus = 'status_id' in task && Boolean(task.status_id);

  const canDoNonTask = !isOffWork && !placeholder;
  const people = peopleIds.map((id) => peopleMap[id]);
  const canRenderTaskTab =
    !isTimeoff && !isStatus && Rights.canCreateTaskForPeople(user, people);
  const canRenderTimeoffTab =
    !isTask && !isStatus && Rights.canCreateTimeoffForPeople(user, people);
  const canRenderStatusTab =
    !isTask && !isTimeoff && canSeeStatusTab(user, people);

  return [
    canRenderTaskTab && {
      label: 'Allocation',
      value: 'task',
    },
    canRenderTimeoffTab &&
      canDoNonTask && { label: 'Time off', value: 'timeoff' },
    canRenderStatusTab && canDoNonTask && { label: 'Status', value: 'status' },
  ].filter(Boolean) as { label: string; value: TaskMode }[];
}

export const TaskModeTabs = (props: TaskModeTabsProps) => {
  const {
    user,
    peopleMap,
    form: { task, taskMode, isOffWork, peopleIds },
  } = props;

  const options = getTaskModeOptions({
    user,
    peopleMap,
    task,
    isOffWork,
    peopleIds,
  });

  return (
    <TabsContainer>
      <TextToggle<TaskMode>
        variant="tabSwitch"
        options={options}
        value={taskMode}
        onChange={({ value }) => props.onTabChange(value)}
      />
    </TabsContainer>
  );
};
