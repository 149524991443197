import React from 'react';
import classNames from 'classnames';

import { FloatLogo } from '@float/ui/components/FloatLogo';
import { Button } from '@float/ui/deprecated/Button/Button';
import IconLogout from '@float/ui/deprecated/Earhart/Icons/Icon/IconLogout';

import { StyledHeader, StyledHeaderLeft, StyledHeaderRight } from './styles';

const CompanySelectHeader = (props) => {
  const { className } = props;

  return (
    <StyledHeader className={classNames(className)}>
      <StyledHeaderLeft>
        <a href="https://www.float.com" title="Float">
          <FloatLogo height={26} />
        </a>
      </StyledHeaderLeft>

      <StyledHeaderRight>
        <Button appearance="ghost" icon={IconLogout} href="/logout" as="a">
          Sign out
        </Button>
      </StyledHeaderRight>
    </StyledHeader>
  );
};

export default CompanySelectHeader;
