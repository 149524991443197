import styled from 'styled-components';

import Colors from '@float/ui/deprecated/Theme/colors';

export const StyledContainer = styled.div`
  display: flex;

  background-color: ${Colors.white};

  &,
  * {
    box-sizing: border-box;
  }

  .sideNav {
    position: sticky;

    top: 0;
  }
`;

export const StyledRouteWrapper = styled.div`
  width: 100%;
  height: calc(var(--screen-height) - var(--app-margin-top, 0px));

  padding: 24px 40px;

  overflow-y: scroll;
`;
