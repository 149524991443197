import React from 'react';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';
import { NoTaskAllocation } from 'components/elements/NoTaskAllocation';

import { ActivityCircle, Item } from './Task.styles';
import { TaskActionsMenu } from './TaskActionsMenu';

export class Task extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.task.task_name,
      billable: props.task.billable,
      isEditing: false,
    };
  }

  toggleBillable = () => {
    this.setState(
      (ps) => ({ billable: !ps.billable }),
      () => this.submit(),
    );
  };

  toggleIsSelected = () => {
    this.props.onSelectionChange(
      this.props.task.task_name,
      !this.props.isSelected,
    );
  };

  handleChangeName = (evt) => {
    const name = evt.currentTarget.value;
    const isEditing = this.props.task.task_name !== name;
    this.setState({ name, isEditing, error: false });
  };

  handleKeydown = (evt) => {
    if (evt.keyCode === 13) {
      prevent(evt);
      this.submit();
    }

    if (evt.keyCode === 27) {
      prevent(evt);
      this.cancel(evt);
    }
  };

  submit = (evt) => {
    prevent(evt);
    const hadTaskName = !!this.props.task.task_name;
    const currentTaskNameEmpty = !this.state.name || this.state.name === '0';

    if (hadTaskName && currentTaskNameEmpty) {
      this.setState({ error: true });
      return;
    }

    if (
      this.props.exists({ taskName: this.state.name, ignore: this.props.task })
    ) {
      return;
    }

    this.setState({ isEditing: false });
    this.props.onUpdate({
      ...this.props.task,
      task_name: this.state.name,
      billable: this.state.billable,
    });
  };

  cancel = (evt) => {
    if (this.state.isEditing) {
      prevent(evt);
      this.setState({
        isEditing: false,
        error: false,
        name: this.props.task.task_name,
      });
    }
  };

  remove = (evt) => {
    prevent(evt);
    this.props.onRemove(this.props.task);
  };

  validName() {
    return this.state.name && this.state.name !== '0';
  }

  getTooltipContent() {
    const { currentUser, task } = this.props;
    const { count_tasks = 0, count_logged_time = 0 } = task;

    if (currentUser.time_tracking > 0) {
      let tooltipContent = `${count_tasks} scheduled task${
        count_tasks != 1 ? 's' : ''
      }`;
      tooltipContent += `, ${count_logged_time} logged task${
        count_logged_time != 1 ? 's' : ''
      }`;
      return tooltipContent;
    }
  }

  getActions() {
    const options = [
      {
        label: 'Delete',
        appearance: 'danger',
        onSelect: () => {
          this.remove();
        },
      },
    ];
    if (this.props.isBillableProject) {
      options.unshift({
        label: `Set as ${this.state.billable ? 'Non-billable' : 'Billable'}`,
        onSelect: () => {
          this.toggleBillable();
        },
      });
    }
    return options;
  }

  render() {
    const { task, isTaskNameInUse, isBillableProject } = this.props;

    let saveLink;
    let nonBillableTag;
    if (this.state.isEditing) {
      saveLink = (
        <TextButton appearance="flue-fill" onClick={this.submit}>
          Save
        </TextButton>
      );
    } else if (isBillableProject && !this.state.billable) {
      nonBillableTag = <span className="non-billable-tag">Non-billable</span>;
    }

    if (this.props.isReadOnly) {
      return (
        <Item className="read-only">
          <div className="name">
            <div className="cb-or-status">
              <TextTooltip placement="top" content={this.getTooltipContent()}>
                <ActivityCircle isInUse={isTaskNameInUse(task)} />
              </TextTooltip>
            </div>
            <div className="name-wrapper">
              <span className="name-input">
                {this.props.task.task_name || <NoTaskAllocation />}
              </span>
            </div>
          </div>
          <div className="controls">{nonBillableTag}</div>
        </Item>
      );
    }

    return (
      <Item className={this.props.isSelected ? 'selected' : undefined}>
        <div className="name">
          <div className="cb-or-status">
            <ActivityCircle isInUse={isTaskNameInUse(task)} />
            <Checkbox
              value={this.props.isSelected}
              onChange={this.toggleIsSelected}
            />
          </div>
          <div className={`name-wrapper ${this.state.error ? ' error' : ''}`}>
            <input
              className="name-input"
              onKeyDown={this.handleKeydown}
              onChange={this.handleChangeName}
              value={this.state.name}
              maxLength={150}
              placeholder={
                !this.props.task.task_name ? 'No task allocation' : null
              }
              data-lpignore
            />
            {this.state.error && <Icons.IconAlert className="icon-error" />}
          </div>
        </div>
        <div className="controls">
          {nonBillableTag}
          {saveLink}
          <TaskActionsMenu options={this.getActions()} />
        </div>
      </Item>
    );
  }
}

Task._styles = { Item, ActivityCircle };
