import React from 'react';
import { withRouter } from 'react-router-dom';
import createClass from 'create-react-class';
import { get, isEmpty } from 'lodash';
import { modalManagerConnect } from 'modalManager/modalManagerHoc';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import { sidePanelConnect } from 'sidePanel/sidePanelConnect';
import styled from 'styled-components';

import {
  useAppDispatchDecorator,
  useAppDispatchStrict,
  useAppSelectorStrict,
  useAppStore,
} from '@float/common/store';
import { withSnackbar } from '@float/ui/deprecated/Snackbar/withSnackbar';
import { updateDates } from '@float/web/actions/app';
import {
  requestActivity,
  updateSeenActivities,
} from '@float/web/activity/actions';

import ActivityHeader from './ActivityHeader';
import ActivityItemClick from './ActivityItemClick';

const TABS = {
  notifications: 'notifications',
  activity: 'activity',
};

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

const ActivityInner = createClass({
  getInitialState() {
    return {
      wasLoaded: false,
      items: this.getItems(),
    };
  },
  componentDidUpdate(prevProps) {
    const newState = {};
    const { wasLoaded } = this.state;
    const propChangesToDetect = ['search.filters', 'viewId'];
    const searchPropsChanged = propChangesToDetect.some(
      // These are from Redux so we can rely on immutability
      (path) => get(this.props, path) !== get(prevProps, path),
    );
    const shouldFetch = !wasLoaded || searchPropsChanged;

    if (shouldFetch) {
      this.props.dispatch(
        requestActivity({
          specialName: 'mainActivityFeed',
          toClear: true,
        }),
      );
      newState.wasLoaded = true;
    }

    if (
      prevProps.items !== this.props.items ||
      prevProps.accounts !== this.props.accounts
    ) {
      newState.items = this.getItems();
    }

    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  },
  getItems() {
    const items = this.props.items || [];
    const accounts = this.props.accounts || {};
    return items.map((item) => ({
      ...item,
      actioned_by: {
        ...item.actioned_by,
        // Get the avatar from the account if the account exists
        avatar: accounts[item.actioned_by.account_id]
          ? accounts[item.actioned_by.account_id].avatar
          : null,
      },
    }));
  },

  clickActivityItem(item) {
    ActivityItemClick(
      item,
      this.props.manageModal,
      this.props.updateDates,
      this.clickActivityItem,
      this.props.history,
      this.props.showSnackbar,
      this.props.showProjectSidePanel,
      this.props.store,
    );
  },

  onTabChange(selectedTab) {
    this.setState({ selectedTab });
  },

  getSelectedTab() {
    return this.state.selectedTab || this.props.defaultTab;
  },

  render() {
    const { onHide, activityIsOpen, defaultTab } = this.props;

    const tab = this.getSelectedTab();

    return (
      <Wrapper>
        <ActivityHeader
          selectedTab={tab}
          defaultTab={defaultTab}
          isOpen={activityIsOpen}
          onTabChange={this.onTabChange}
          onHide={onHide}
        />
      </Wrapper>
    );
  },
});

const selector = createSelector(
  [
    (state) => state.activityData.activityCollections.mainActivityFeed,
    (state) => state.notifications,
    (state) => state.search,
    (state) => state.accounts.accounts,
  ],
  (mainActivityFeed, notifications, search, accounts) => {
    const {
      items = [],
      allFetched = [],
      page,
      allSeen,
    } = mainActivityFeed || {};

    const defaultTab =
      notifications.loadState === 'LOADED' &&
      isEmpty(notifications.items) &&
      TABS.notifications;

    const fetchedCount = !allFetched ? 0 : allFetched.length;

    return {
      allSeen,
      items,
      allFetched,
      search,
      accounts,
      fetchedCount,
      activityPage: page || 1,
      defaultTab,
    };
  },
);

const Component = compose(
  sidePanelConnect,
  withRouter,
  withSnackbar,
)(ActivityInner);

function Activity(props) {
  const _updateSeenActivities = useAppDispatchDecorator(updateSeenActivities);
  const _updateDates = useAppDispatchDecorator(updateDates);
  const dispatch = useAppDispatchStrict();
  const stateProps = useAppSelectorStrict(selector);
  const store = useAppStore();
  return (
    <Component
      {...props}
      {...stateProps}
      updateSeenActivities={_updateSeenActivities}
      updateDates={_updateDates}
      dispatch={dispatch}
      store={store}
    />
  );
}

export default modalManagerConnect(Activity);
