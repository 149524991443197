import React from 'react';
import { Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated/Button/Button';
import { IconClose } from '@float/ui/deprecated/Earhart/Icons';

import { WelcomeProps } from '.';
import { WelcomeIllustration } from './components/WelcomeIllustration';

import * as styles from './styles.css';

export const WelcomeWithIllustration = (props: WelcomeProps) => {
  const { goNext, skipTour } = props;

  const onClickStart = () => {
    if (goNext) goNext();
  };

  const onClickClose = () => {
    if (skipTour) skipTour();
  };

  return (
    <div
      className={styles.welcomeWrapper}
      data-transition-enter-timeout="0.85"
      data-transition-exit-timeout="0.35"
    >
      <IconClose className={styles.iconClose} onClick={onClickClose} />

      <h1 className={styles.h1}>
        <Trans>Welcome to Float</Trans>
      </h1>

      <p className={styles.p}>
        <Trans>
          Before diving in, take a short tour to discover key functionality.
        </Trans>
      </p>

      <WelcomeIllustration className={styles.welcomeIllustration} />

      <Button onClick={onClickStart}>
        <Trans>Start tour</Trans>
      </Button>
    </div>
  );
};
