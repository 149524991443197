import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DateRangePicker from '@float/common/components/DatePicker/DateRangePicker';
import { getDateRangeText } from '@float/common/lib/utils';
import { getWeekBoundary } from '@float/libs/dates';
import { moment } from '@float/libs/moment';
import { getLastWeek, getNextWeek, getThisWeek } from '@float/libs/timeRange';
import { PaginationArrow } from '@float/ui/deprecated/DateRangePicker/DateRangePicker';
import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect';
import EHIconCalendar from '@float/ui/deprecated/Earhart/Icons/Icon/IconCalendar';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import Icons from '@float/ui/deprecated/Icons';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';

import { NavIconBtn } from '../NavIconBtn';

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;

  @media (max-width: 1010px) {
    display: none;
  }
`;

const DateContainer = styled.div`
  outline: none;
  cursor: pointer;
  color: ${(p) => p.theme.primary};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
`;

const WeekRangePickerContainer = styled.div`
  .DateRangePicker {
    margin: 0;
    width: 100%;
  }

  .DateRangePicker__Month {
    width: 100%;
    margin: 12px 0 0;
  }

  DateRangePicker__MonthDates {
    margin-top: 16px;
  }

  .DateRangePicker table tbody td:hover .hover-bg {
    background: none !important;
  }

  .DateRangePicker table tbody td.DateRangePicker__Date--otherMonth {
    opacity: 1 !important;

    .DateRangePicker__DateLabel {
      opacity: 0.25 !important;
    }
  }
`;

const ArrowsContainer = styled.div`
  button {
    cursor: pointer;
    outline: none;
    padding: 7px 12px 6px 13px;
    border: 1px solid ${(p) => p.theme.grey};

    &:first-child {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;

const RangeLabelContainer = styled.div`
  letter-spacing: -0.3px;
  margin-right: 10px;
`;

const TodayButton = styled.button`
  cursor: pointer;
  outline: none;
  padding: 5px 12px 8px 13px;
  border: 1px solid ${(p) => p.theme.grey};
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  margin-right: 10px;

  &:disabled {
    cursor: default;
  }
`;

const RangeSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 16px;
  border-bottom: 1px solid ${(p) => p.theme.black12};
`;

const RangeSelectorLabel = styled.span`
  ${Typography.Label16.SB600};
  margin-right: 12px;
`;

export const Styles = {
  TodayButton,
  ArrowsContainer,
  RangeLabelContainer,
  DateContainer,
};

function WeekArrows({ onLeftClick, onRightClick }) {
  return (
    <ArrowsContainer>
      <button onClick={onLeftClick}>
        <Icons.Left color="#868d92" />
      </button>
      <button onClick={onRightClick}>
        <Icons.Right color="#868d92" />
      </button>
    </ArrowsContainer>
  );
}

const getWeekText = (suvWeek, currentWeek) => {
  if (suvWeek === currentWeek - 1) {
    return 'Last week';
  }

  if (suvWeek === currentWeek) {
    return 'This week';
  }

  if (suvWeek === currentWeek + 1) {
    return 'Next week';
  }
};

function WeekRangePicker(props) {
  const {
    value,
    handleSelect,
    firstOfWeek,
    suvWeek,
    currentWeek,
    today,
    style,
    showToday = true,
    showArrows = true,
  } = props;

  const [overrideValue, setOverrideValue] = useState();
  const [tipShowing, setTipShowing] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (tipShowing) {
      const onBlur = () => {
        setTipShowing(false);
      };

      window.addEventListener('blur', onBlur);

      return () => {
        window.removeEventListener('blur', onBlur);
      };
    }
  }, [tipShowing]);

  const rangeMode = useMemo(() => {
    if (suvWeek === currentWeek - 1) return getLastWeek().value;
    if (suvWeek === currentWeek) return getThisWeek().value;
    if (suvWeek === currentWeek + 1) return getNextWeek().value;
    return 'custom';
  }, [suvWeek, currentWeek]);

  const rangeOpts = useMemo(() => {
    const opts = [getLastWeek(), getThisWeek(), getNextWeek()];
    if (!rangeMode || rangeMode === 'custom') {
      opts.push({ value: 'custom', label: 'Custom' });
    }
    return opts;
  }, [rangeMode]);

  function selectAndClose(date) {
    setOverrideValue(undefined);
    setTipShowing(false);
    handleSelect(date);
  }

  const [selectedStart, selectedEnd] = getWeekBoundary(value, firstOfWeek);
  const [start, end] = getWeekBoundary(overrideValue || value, firstOfWeek);
  const range = moment.range(start, end);
  const showDates = suvWeek < currentWeek - 1 || suvWeek > currentWeek + 1;

  return (
    <Wrapper style={style}>
      {showToday && (
        <TodayButton
          onClick={() => handleSelect(moment(today))}
          disabled={suvWeek === currentWeek}
        >
          Today
        </TodayButton>
      )}
      {showArrows && (
        <WeekArrows
          onLeftClick={() => {
            selectAndClose(moment(selectedStart).add(-1, 'week'));
          }}
          onRightClick={() => {
            selectAndClose(moment(selectedStart).add(1, 'week'));
          }}
        />
      )}

      <Popover
        className="menu datepicker"
        placement="bottom-start"
        maxWidth="300px"
        arrow={false}
        delay={1}
        open={tipShowing}
        onOpenChange={setTipShowing}
        onOpenAutoFocus={(evt) => {
          evt.preventDefault();
        }}
        distance={5}
        content={
          <WeekRangePickerContainer>
            <RangeSelectorContainer>
              <RangeSelectorLabel>Date range</RangeSelectorLabel>
              <DropdownSelect
                value={rangeMode}
                options={rangeOpts}
                align="bottom"
                minSelectWidth={185}
                tickSelectedOption
                tickRight
                onChange={(rangeOpt) => {
                  if (rangeOpt.value !== 'custom') {
                    selectAndClose(rangeOpt.start());
                  }
                }}
              />
            </RangeSelectorContainer>
            <DateRangePicker
              paginationArrowComponent={PaginationArrow}
              numberOfCalendars={1}
              selectionType="range"
              singleDateRange={true}
              weekSelection={true}
              value={range}
              firstOfWeek={firstOfWeek || 0}
              onSelect={(date) => {
                selectAndClose(getWeekBoundary(date, firstOfWeek)[0]);
              }}
              onHighlightDate={(date) => {
                setOverrideValue(date);
              }}
            />
          </WeekRangePickerContainer>
        }
      >
        <NavIconBtn
          iconLeft={<EHIconCalendar />}
          iconRight={<Icons.DownSmall />}
        >
          {getWeekText(suvWeek, currentWeek)}
          {showDates && (
            <span>
              {getDateRangeText({
                start: selectedStart.format('yyyy-MM-DD'),
                end: selectedEnd.format('yyyy-MM-DD'),
                includeYear: true,
                dateFormat: 'dd MMM',
                fullDateFormat: 'dd MMM yyyy',
              })}
            </span>
          )}
        </NavIconBtn>
      </Popover>
    </Wrapper>
  );
}

export default WeekRangePicker;
