import React from 'react';

import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect/DropdownSelect';
import { Card } from '@float/ui/deprecated/Earhart/Cards';
import { ToggleButton } from '@float/ui/deprecated/Toggle/ToggleButton';
import { ToggleGroup } from '@float/ui/deprecated/Toggle/ToggleGroup';

import {
  StyledColumn,
  StyledColumnContent,
  StyledColumnContentWithDropdown,
  StyledRow,
} from '../styles';

const TimeTrackingReminder = (props) => {
  const {
    logTimeFrequency,
    logTimeDay,
    weekdays,
    onLogTimeFrequencyChange,
    onLogTimeDayChange,
  } = props;

  return (
    <Card>
      <StyledRow>
        <h4>Time tracking reminder emails</h4>
      </StyledRow>

      <StyledRow>
        <p>Send a reminder email to anyone who hasn't logged enough time.</p>
      </StyledRow>

      <StyledRow direction="row">
        <StyledColumn>
          <h6>Frequency</h6>

          <StyledColumnContent>
            <ToggleGroup
              value={logTimeFrequency}
              onChange={onLogTimeFrequencyChange}
            >
              <ToggleButton value={0}>Never</ToggleButton>
              <ToggleButton value={2}>Daily</ToggleButton>
              <ToggleButton value={1}>Weekly</ToggleButton>
            </ToggleGroup>
          </StyledColumnContent>
        </StyledColumn>

        {logTimeFrequency === 1 && (
          <StyledColumn>
            <h6>When?</h6>
            <StyledColumnContentWithDropdown>
              <span>Each&nbsp;</span>

              <DropdownSelect
                value={Number(logTimeDay)}
                options={Object.keys(weekdays).map((key) => ({
                  value: Number(key),
                  label: weekdays[key],
                }))}
                minSelectWidth={150}
                tickSelectedOption
                tickRight
                onChange={onLogTimeDayChange}
              />

              <span>&nbsp;before 7am</span>
            </StyledColumnContentWithDropdown>
          </StyledColumn>
        )}

        {logTimeFrequency === 2 && (
          <StyledColumn>
            <h6>When?</h6>
            <StyledColumnContent>Each work day before 7am</StyledColumnContent>
          </StyledColumn>
        )}
      </StyledRow>

      <StyledRow>
        <h5>Who receives this email?</h5>
        <p>
          {logTimeFrequency === 2
            ? 'Anyone who has logged no time during the previous work day.'
            : 'Anyone who has logged less than 50% of their capacity during the previous work week.'}
        </p>
      </StyledRow>
    </Card>
  );
};

export default TimeTrackingReminder;
