import React from 'react';

import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import { StyledNavIcon } from './styles';

const BottomHoverTooltip = ({ title, children }) => {
  if (!title) {
    return children;
  }

  return (
    <TextTooltip
      className="hover"
      content={title}
      placement="bottom"
      distance={-3}
    >
      {children}
    </TextTooltip>
  );
};

const NavIcon = ({ children, className, title, onClick }) => {
  return (
    <BottomHoverTooltip title={title}>
      <StyledNavIcon className={className || ''} onClick={onClick}>
        {children}
      </StyledNavIcon>
    </BottomHoverTooltip>
  );
};

export default NavIcon;
