import styled, { css } from 'styled-components';

import * as Animation from '../Earhart/Animation';
import * as Colors from '../Earhart/Colors';
import * as Typography from '../Earhart/Typography';
import { FieldLabel } from '../Label';

export const FieldWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 0;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : undefined)};
  position: relative;

  ${({ $underline, disabled }) =>
    $underline &&
    disabled &&
    css`
      ${FieldLabel} {
        color: ${Colors.FIN.Lt.Emphasis.Disabled};
      }
    `}
`;

export const InputPrefix = styled.div`
  display: flex;
  align-self: center;
  margin-right: ${(p) => (p.isIcon ? 10 : 5)}px;
  color: ${Colors.FIN.Lt.Emphasis.Low};
  z-index: 1;
`;

export const InputSuffix = styled.div`
  display: flex;
  align-self: center;
  color: ${Colors.FIN.Lt.Emphasis.Low};
  z-index: 1;
`;

const PlaceholderStyles = (p) => css`
  color: ${Colors.FIN.Lt.Emphasis.Low};

  ${p.isStaticPlaceholder &&
  css`
    color: ${p.color || Colors.FIN.Lt.Emphasis.Medium};
  `}
`;

export const InputWrapper = styled.div`
  ${Typography.TextM.R400};

  display: flex;
  flex-wrap: wrap;
  position: relative;

  padding-left: ${(p) => (p.alignLeft ? 0 : 16)}px;
  padding-right: 10px;

  box-sizing: border-box;

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  ${(p) => p.theme.ie11MinHeightFix};

  ${(p) =>
    p.suffixPositionAuto &&
    css`
      display: flex;
      flex-wrap: nowrap;
    `}

  ${({ iconLeading }) =>
    iconLeading &&
    css`
      padding-left: 10px;
    `};

  &,
  input,
  div {
    ${({ acceptsKeystroke }) =>
      acceptsKeystroke
        ? `
        cursor: pointer;
        user-select: none;
      `
        : `
      cursor: text;
    `}
  }

  // Borders
  &:after,
  &:before {
    content: '';

    position: absolute;

    transition-property: transform, opacity, border-color;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    box-sizing: border-box;
  }

  // Inner border
  &:before {
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: ${Colors.FIN.Lt.Emphasis.White};

    border-width: ${(p) => (p.noBorder ? 0 : 1)}px;
    border-style: solid;
    border-color: var(--input-border-color, ${Colors.FIN.Lt.Stroke.Stroke2});
    border-radius: 5px;

    z-index: 1;

    ${(p) => {
      if (p.hasError)
        return css`
          border-color: ${Colors.FIN.Lt.Danger.Default};
        `;
    }}
  }

  // Outer border
  &:after {
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;

    border-width: 2px;
    border-style: solid;
    border-color: ${Colors.Primary.Flue.Light[6]};
    border-radius: 6px;

    background-color: ${Colors.Primary.Flue.Light[6]};

    opacity: 0;
    transform: scale(0.99, 0.9);

    z-index: 0;
  }

  ${({ $underline, $noPointerEvents, $disabled }) =>
    $underline &&
    css`
      ${Typography.TextXL.M500};

      padding-left: 0;
      padding-right: 0;

      &:before {
        background-color: rgba(0, 0, 0, 0);

        border: 0;
        border-radius: 0;
        border-bottom-width: ${(p) => (p.noBorder ? 0 : 1)}px;
        border-bottom-style: solid;
        border-bottom-color: ${Colors.FIN.Lt.Stroke.Stroke2};

        ${(p) => {
          if (p.hasError)
            return css`
              border-bottom-color: ${Colors.FIN.Lt.Danger.Default};
            `;
        }}
      }

      &:after {
        content: none;
      }

      // Interaction states
      ${!$noPointerEvents &&
      !$disabled &&
      css`
        &:hover {
          &:before {
            border-bottom-color: ${Colors.FIN.Lt.Stroke.Stroke3};
          }
        }

        ${(p) => {
          if (p.isFocused && !p.readOnly && !p.noBorder)
            return css`
              &:before {
                border-bottom-color: ${Colors.FIN.Lt.Emphasis
                  .Primary} !important;
              }
            `;
        }}
      `};

      ${({ $disabled }) =>
        $disabled &&
        css`
          &:before {
            border-color: ${Colors.FIN.Lt.Stroke.Stroke2};
            background-color: rgba(0, 0, 0, 0);
          }
        `};
    `};

  ${({ $medium }) =>
    $medium &&
    css`
      min-height: ${({ minHeight = 40 }) => minHeight}px;
    `};

  ${({ $large }) =>
    $large &&
    css`
      min-height: ${({ minHeight = 44 }) => minHeight}px;
    `};

  ${({ $xlarge }) =>
    $xlarge &&
    css`
      ${Typography.DisplayXS.M500};
      min-height: 40px;

      .input-container {
        input {
          ${Typography.DisplayXS.M500};
        }
      }
    `};

  ${({ $noPointerEvents, $disabled }) =>
    !$noPointerEvents &&
    !$disabled &&
    css`
      &:hover {
        &:before {
          border-color: ${Colors.FIN.Lt.Emphasis.Primary};
        }
      }
    `};

  ${(p) => {
    if (p.isFocused && !p.readOnly && !p.noBorder)
      return css`
        &:after {
          opacity: 1;
          transform: none;
        }
      `;
  }}

  .measure-this {
    position: absolute;
    height: 0;
    overflow: hidden;
    white-space: pre;
    letter-spacing: 0;
  }

  span.icon,
  button.icon {
    display: flex;
    align-items: center;

    max-width: 24px;

    z-index: 1;

    &.left {
      margin-right: 10px;
    }

    &.iconClickable {
      cursor: pointer;

      &:hover {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Low};
        }
      }
    }

    svg {
      --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};

      > * {
        transition-property: stroke, fill;
        transition-duration: ${Animation.Settings.Duration.Short};
        transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
      }
    }
  }

  .input-container {
    position: relative;
    flex: ${(p) => (p.suffixPositionAuto ? 0 : 1)};
    display: flex;
    align-items: center;

    z-index: 1;

    input {
      flex-grow: ${(p) => (p.autoSize ? 0 : 1)};
      box-sizing: border-box;
      border: none;
      border-radius: 0;
      background: none transparent;
      outline: none;
      width: ${(p) => (p.autoSize ? '1px' : '100%')};
      min-width: ${(p) => (p.autoSize ? 30 : 1)}px;
      padding: 0;
      color: ${(p) => p.color || Colors.FIN.Lt.Emphasis.High};

      transition-property: color;
      transition-duration: ${Animation.Settings.Duration.Normal};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

      ${({ hideArrows }) =>
        hideArrows
          ? css`
              ::-webkit-outer-spin-button,
              ::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              -moz-appearance: textfield;
            `
          : ''};

      ${(p) =>
        !p.searchable && !p.readOnly
          ? css`
              height: 100%;
            `
          : ''};

      ${(p) =>
        p.readOnly
          ? css`
              cursor: default;
            `
          : ''};

      ${(p) =>
        p.ellipsis &&
        css`
          text-overflow: ellipsis;
        `};

      ${(p) =>
        p.autoSize &&
        css`
          transition: none;
        `};

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        ${PlaceholderStyles}
      }

      ::placeholder,
      ::-webkit-input-placeholder {
        ${PlaceholderStyles};
      }

      :-ms-input-placeholder {
        ${PlaceholderStyles}
      }

      &::-webkit-contacts-auto-fill-button {
        visibility: hidden;
      }

      &::-ms-clear {
        display: none;
      }

      &[data-com-onepassword-filled],
      &[data-com-onepassword-filled]:focus {
        background-clip: text;
      }
    }

    svg {
      height: 100%;
    }

    .icon-down,
    .icon-search,
    .icon-close {
      cursor: pointer;
    }

    .icon-search {
      ${(p) => !p.noPadding && 'margin-right: 5px;'}
    }
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;

      &:before {
        border-color: ${Colors.FIN.Lt.Stroke.Stroke2};
        background-color: ${Colors.FIN.Lt.Surface.Surf2};
      }

      span.icon {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Disabled};
        }
      }

      .input-container {
        input {
          color: ${Colors.FIN.Lt.Emphasis.Disabled};

          &::placeholder {
            color: ${Colors.FIN.Lt.Emphasis.Disabled} !important;
          }
        }
      }
    `};

  ${({ noBackground }) =>
    noBackground &&
    css`
      &:before {
        /* border-color: ${Colors.FIN.Lt.Stroke.Stroke2}; */
        background-color: initial;
      }
    `}
`;

export const InputEditIcon = styled.span`
  display: flex;
  align-self: center;
  cursor: pointer;
  opacity: 0.7;
`;
