import { ProjectsOverviewTableContext } from '../../table/types';

export function getSheetColumns(
  ctx: ProjectsOverviewTableContext,
  columns: [string, () => string][],
) {
  return [...columns].filter((c) => {
    if (!ctx.timeTrackingEnabled && c[0].includes('Logged')) {
      return false;
    }
    if (!ctx.isProjectCodesEnabled && c[0].includes('Project code')) {
      return false;
    }
    return true;
  });
}
