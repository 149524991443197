import React from 'react';

import { Button } from '@float/ui/deprecated/Button/Button';

import { P, SubsectionAction, SubsectionContent } from './styles';

class TokenSection extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { onClick, toggleLoading } = this.props;
    toggleLoading(true);
    onClick().then(() => toggleLoading(false));
  }

  render() {
    const { isLoading } = this.props;
    return (
      <>
        <SubsectionContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <P>
              The Float API allows you to integrate your data with other
              applications. Use your unique API key to authenticate. Read our{' '}
              <a
                target="_blank"
                href="http://dev.float.com"
                rel="noopener noreferrer"
              >
                API Documentation
              </a>
              .
            </P>
          </div>
        </SubsectionContent>
        <SubsectionAction>
          <Button
            appearance="ghost"
            disabled={isLoading}
            onClick={this.onClick}
          >
            Regenerate
          </Button>
        </SubsectionAction>
      </>
    );
  }
}

export default TokenSection;
