import React, { useCallback, useEffect, useState } from 'react';
import { isNaN } from 'lodash';

import { calculatePrice } from '@float/common/lib/pricing';
import { noop } from '@float/libs/utils/noop';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Input } from '@float/ui/deprecated/Input';
import { FieldLabel } from '@float/ui/deprecated/Label';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';
import {
  withConfirm,
  WithConfirmExtraProps,
} from '@float/ui/deprecated/Modal/withConfirm';
import { onChatClick } from 'components/Nav/helpers';

import { StyledTotalPrice } from './styles';

export type UpdateSeatsModalProps = {
  initialSeats?: number;
  usedSeats?: number;
  isMonthly?: boolean;
  onSubmit?: (seats?: number) => Promise<void>;
  onClose?: () => void;
  isEnterprisePlan: boolean;
  isProPlan: boolean;
};

export const UpdateSeatsModal = withConfirm(
  ({
    initialSeats = 1,
    usedSeats = 0,
    isMonthly = true,
    onSubmit = Promise.resolve,
    onClose = noop,
    confirm,
    isEnterprisePlan,
    isProPlan,
  }: UpdateSeatsModalProps & WithConfirmExtraProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [seatCountInput, setSeatCountInput] = useState<string>(
      `${initialSeats}`,
    );
    const [seatCount, setSeatCount] = useState<number>(initialSeats);
    const [seatCountError, setSeatCountErrors] = useState<string[]>([]);
    const isUpdateDisabled = Boolean(
      !isDirty || seatCountError.length || isLoading,
    );

    useEffect(() => {
      const seatsInt = parseInt(seatCountInput, 10);

      // Ensures seatsInt is a valid number.
      // Validations happen on input change and on submission.
      if (isNaN(seatsInt)) {
        setSeatCount(0);
      } else {
        setSeatCount(seatsInt);
      }
    }, [seatCountInput]);

    // Note: Despite the new plans, prices and their calculations remain unchanged. Therefore, these calculations
    // still include references to legacy features such as time tracking and plus pack add-ons."
    const pricing = calculatePrice({
      plans: {}, // only calculates new plan prices
      isMonthly,
      peopleLimit: seatCount,
      hasPlusPack: isEnterprisePlan,
      hasTimeTracking: isEnterprisePlan || isProPlan,
    });

    const totalPrice = pricing.total === '0' ? '---' : `$${pricing.total}`;

    const getSeatValidations = useCallback(
      (seats: number, seatsValueRaw?: string) => {
        const validations: { errors: string[]; input: string } = {
          errors: [],
          input: seatsValueRaw || '',
        };

        if (seats >= 100_000) {
          validations.errors.push('Must be less than 100,000');
        } else if (seats < usedSeats) {
          validations.errors.push(
            'Cannot be less than number of people currently scheduled',
          );
        } else if (isNaN(seats)) {
          validations.errors.push('Must be 1 or more');
          validations.input = '';
        }

        return validations;
      },
      [usedSeats],
    );

    const handleSeatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isDirty) {
        setIsDirty(true);
      }

      const seatsValueRaw = e.currentTarget.value.replace(/\D/g, '');
      const seats = parseInt(seatsValueRaw, 10);
      const { errors, input } = getSeatValidations(seats, seatsValueRaw);

      if (errors.length) {
        setSeatCountErrors(errors);
        setSeatCountInput(input);
        return;
      }

      setSeatCountInput(seatsValueRaw);
      setSeatCountErrors([]);
    };

    const submit = useCallback(() => {
      const { errors } = getSeatValidations(seatCount);

      if (errors.length) {
        setSeatCountErrors(errors);
        return;
      }

      confirm({
        title: 'Confirm your new plan',
        message:
          'Your new plan will start immediately. You will be charged the new amount in your next billing cycle.',
        confirmLabel: 'Confirm',
        onConfirm: async () => {
          try {
            setIsLoading(true);
            await onSubmit(seatCount);
            setIsLoading(false);
          } catch (e) {
            confirm({
              title: `Error updating seats`,
              message: `Sorry, there was an error updating your seats. Please
              refresh the page to try again. If the error persists, please contact
              our support team.`,
              hideCancel: true,
              onConfirm: close,
            });
            setIsLoading(false);
          }
        },
      });
    }, [confirm, onSubmit, seatCount, getSeatValidations]);

    return (
      <Modal isOpen={true} onEnter={submit} onClose={onClose}>
        <ModalHeader>
          <ModalTitle style={{ fontSize: 22 }}>Update seats</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form noValidate onSubmit={submit}>
            <Input
              style={{ marginTop: 20 }}
              maxWidth={120}
              label="Total seats"
              value={seatCountInput}
              errors={seatCountError}
              onChange={handleSeatChange}
            />
            <FieldLabel style={{ marginTop: 20, marginBottom: 5 }}>
              {isMonthly ? 'Per month' : 'Per year'}
            </FieldLabel>
            <StyledTotalPrice>{totalPrice}</StyledTotalPrice>
          </form>
        </ModalBody>
        <ModalActions>
          <Button
            loader={isLoading}
            onClick={submit}
            disabled={isUpdateDisabled}
          >
            Update
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            appearance="clear"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            onClick={onChatClick}
          >
            Message support
          </Button>
        </ModalActions>
      </Modal>
    );
  },
);
