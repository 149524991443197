import styled from 'styled-components';

import { Button } from '@float/ui/deprecated/Button/Button';
import { Modal } from '@float/ui/deprecated/Modal';

export const StyledModalHeader = styled(Modal.Header)`
  padding: 24px 30px 3px 30px;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
`;

export const ScrollableListTitle = styled.div`
  margin-bottom: 10px;
`;

export const ScrollableList = styled.ul`
  margin-top: ${(p) => (p.hasErrors ? 4 : 30)}px;
  margin-bottom: 42px;
  min-height: 150px;

  li {
    box-sizing: border-box;
    display: flex;
    line-height: 43px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 18px;

    span.avatar-image {
      margin-right: 10px;
    }

    span.name {
      color: ${(p) => p.theme.charcoalGrey};
      width: 35.75%;
    }

    .email {
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: -0.2px;
      width: 50%;
      color: ${(p) => p.theme.blueGrey};
    }

    .name,
    .email {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      height: 100%;
    }

    div.controls {
      margin-left: auto;
      margin-right: 10px;
      display: flex;
      box-sizing: border-box;
      height: 43px;

      .icon-close-small {
        height: 100%;
        align-self: center;
        cursor: pointer;
      }
    }
  }
`;

export const NoTeam = styled.div`
  margin: auto;
  color: ${(p) => p.theme.blueGrey};
  text-align: center;
  line-height: 150px;
`;

export const NoTransform = styled.span`
  text-transform: none;
  margin-left: 2px;
`;

export const Timeframe = styled.div`
  display: flex;
`;
