import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, without } from 'lodash';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import {
  getActivePeopleWithEmailMap,
  getSearchFilteredActivePeopleWithEmailMap,
} from 'selectors';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { formatDate } from '@float/common/lib/utils';
import { logger } from '@float/libs/logger';
import { moment } from '@float/libs/moment';
import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';
import DateRangePicker from '@float/ui/deprecated/DateRangePicker/DateRangePicker';
import IconCloseSmall from '@float/ui/deprecated/Icons/icon-close';
import { Modal, ModalTopGreySection } from '@float/ui/deprecated/Modal';
import { useSnackbar } from '@float/ui/deprecated/Snackbar';
import TextAreaField from '@float/ui/deprecated/TextArea/TextAreaField';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { sendScheduleEmail } from '@float/web/settingsV2/components/Notifications/actions';

import * as helpers from './helpers';
import rangeOpts, { endOf, startOf } from './rangeOpts';
import * as styled from './styles';

const EmailScheduleModal = ({
  allPeopleMap,
  searchPeopleMap,
  deptMap,
  isSearchFilterApplied,
  firstOfWeek,
  manageModal,
}) => {
  const [peopleByDept, setPeopleByDept] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [customMessage, setCustomMessage] = useState('');
  const [rangeMode, setRangeMode] = useState('0');
  const [startDate, setStartDate] = useState(startOf('week')().toDate());
  const [endDate, setEndDate] = useState(endOf('week')().toDate());
  const [sendToErrors, setSendToErrors] = useState([]);
  const [sending, setSending] = useState(false);
  const momentRange = moment.range(moment(startDate), moment(endDate));
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSearchFilterApplied) {
      setSelectedIds(Object.keys(searchPeopleMap));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setPeopleByDept(
      helpers.getPeopleByDept(allPeopleMap, deptMap, selectedIds),
    );
    if (selectedIds.length && sendToErrors.length) {
      setSendToErrors([]);
    }
  }, [selectedIds]); // eslint-disable-line

  const onClose = () => {
    manageModal({
      visible: false,
      modalType: 'emailScheduleModal',
    });
  };

  const onRemove = (id) => {
    setSelectedIds(without(selectedIds, id));
  };

  const onAdd = (selected) => {
    if (selected.groupValue === -1) {
      // User chose to add ALL people
      setSelectedIds(Object.keys(allPeopleMap));
      return;
    }

    setSelectedIds([...selectedIds, selected.value]);
  };

  const onAddGroup = (groupOptions) => {
    setSelectedIds([...selectedIds, ...groupOptions.map((go) => go.value)]);
  };

  const onMessageChange = (msg) => {
    setCustomMessage(msg);
  };

  const onDateRangeChange = (start, end, range) => {
    setRangeMode(range);
    setStartDate(start.toDate());
    setEndDate(end.toDate());
  };

  const onSend = (e) => {
    preventDefaultAndStopPropagation(e);
    if (sending) {
      return;
    }

    if (!selectedIds || !selectedIds.length) {
      setSendToErrors(['Please select at least one person']);
      return;
    }

    setSending(true);
    sendScheduleEmail({
      people: selectedIds,
      range: rangeMode,
      msg: customMessage,
      action: 'yt0=Send',
      start: formatDate(startDate),
      end: formatDate(endDate),
    })
      .then(() => {
        setSending(false);
        onClose();
        showSnackbar('Email sent.');
      })
      .catch((err) => {
        logger.warn('Error sending email', err);
        setSending(false);
        onClose();

        // 'An error occurred.', - https://app.asana.com/0/1143359629310403/1147983668513761/f
        showSnackbar('Error sending email.');
      });
  };

  return (
    <Modal isOpen onClose={onClose}>
      <styled.StyledModalHeader>
        <Modal.Title>Email their schedule</Modal.Title>
        <styled.StyledButton
          forwardedAs="a"
          appearance="ghost"
          size="small"
          href="/site/emailScheduleSample"
          target="_blank"
        >
          See example
        </styled.StyledButton>
      </styled.StyledModalHeader>
      <Modal.Body>
        <ModalTopGreySection>
          <styled.Timeframe>
            <DateRangePicker
              label="Timeframe"
              hidePrevNext
              maximumDays={30}
              style={{ display: 'block' }}
              firstOfWeek={firstOfWeek}
              rangeOpts={rangeOpts}
              rangeMode={rangeMode}
              value={momentRange}
              arrowOffset={36}
              onChange={onDateRangeChange}
            />
          </styled.Timeframe>
        </ModalTopGreySection>

        <VirtualSelect
          hideClearIcon
          keepFocusAfterSelect
          label="Send to"
          visibleItems={6}
          placeholder=""
          groupedOptions={peopleByDept}
          onChange={onAdd}
          onSelectGroupAll={onAddGroup}
          errors={sendToErrors}
        />
        <styled.ScrollableList hasErrors={sendToErrors.length}>
          {selectedIds.map((id) => {
            const member = allPeopleMap[id];
            return (
              <li key={member.people_id}>
                <span className="avatar-image">
                  <PersonAvatar
                    personId={member.people_id}
                    {...helpers.avatarProps}
                  />
                </span>
                <span className="name">{member.name}</span>
                <div className="email">{member.email}</div>
                <div className="controls">
                  <span
                    onClick={() => onRemove(id)}
                    className="icon-close-small"
                  >
                    <IconCloseSmall />
                  </span>
                </div>
              </li>
            );
          })}
          {/* isEmpty(selectedIds) && (
            <styled.NoTeam>No people selected.</styled.NoTeam>
          ) */}
        </styled.ScrollableList>
        <TextAreaField
          label={
            <span>
              Message <styled.NoTransform>(Optional)</styled.NoTransform>
            </span>
          }
          value={customMessage}
          onChange={onMessageChange}
        />
      </Modal.Body>
      <Modal.Actions>
        <Button loader={sending} onClick={onSend}>
          Email schedule
          {selectedIds.length
            ? ` to ${selectedIds.length} ${
                selectedIds.length === 1 ? 'person' : 'people'
              }`
            : ''}
        </Button>
        <Button appearance="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const firstOfWeek = get(state.currentUser, 'start_work_week');
  const { filters = [] } = state.search;

  return {
    isSearchFilterApplied: !!(filters && filters.length),
    allPeopleMap: getActivePeopleWithEmailMap(state),
    searchPeopleMap: getSearchFilteredActivePeopleWithEmailMap(state),
    deptMap: state.departments.departments,
    firstOfWeek,
  };
};

export default modalManagerHoc({
  Comp: connect(mapStateToProps)(EmailScheduleModal),
  modalType: 'emailScheduleModal',
});
