import React, { CSSProperties } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { TIME_FORMAT } from '@float/common/lib/time';
import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { getHoursDisplayFormat } from '@float/common/lib/timer/displayHoursFormat';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { AllocationType, CellItem, CompanyPreferences } from '@float/types';

import { getNumRows } from '../utils/getNumRows';
import { getIconElements } from './getIconElements';
import { HourElements } from './HourElements';
import { PercentageElements } from './PercentageElements';
import { SingleRow } from './SingleRow';
import { SingleRowLoggedTime } from './SingleRowLoggedTime';
import { TextElements } from './TextElements';
import { BoxGetterProps } from './types';

import {
  boxInner,
  iconsContainer,
  iconsContainerBgVar,
  iconsContainerWrapper,
  singleRow,
  singleRowRightSide,
} from '../../EntityItemStyles.css';

interface BoxInnerRegularProps {
  item: CellItem<'task'> | CellItem<'timeoff'> | CellItem<'loggedTime'>;
  companyPrefs: CompanyPreferences;
  styles: CSSProperties;
  props: BoxGetterProps;
}

export const BoxInnerRegular: React.FC<BoxInnerRegularProps> = ({
  item,
  companyPrefs,
  styles,
  props,
}) => {
  const { hourHeight, suvSingleDay, logTimeView } = props;
  const { color, backgroundColor } = styles;
  const { entity, type } = item;

  const numRows = getNumRows(entity, hourHeight);

  const user = useAppSelectorStrict(getUser);

  const iconElements = getIconElements(item, color, suvSingleDay, user);

  const iconGroupWidth =
    type === 'loggedTime' ? 0 : iconElements.length * 15 + 2;

  const textElements = (
    <TextElements
      entity={entity}
      numRows={numRows}
      iconGroupWidth={iconGroupWidth}
      companyPrefs={companyPrefs}
      logTimeView={logTimeView}
      color={color}
    />
  );

  const fullDay = isFullDayTimeoff(entity);

  const pinnedTimeOff = type === 'timeoff' && !fullDay && item.y === 0;
  const isPercentAllocation =
    type === 'task' && entity.allocation_type === AllocationType.Percentage;

  const displayFormat = getHoursDisplayFormat(
    user.prefs,
  ) as keyof typeof TIME_FORMAT;

  if (numRows === 0 && !fullDay) {
    return (
      <div
        className={boxInner}
        data-zero-rows="true"
        data-full-day={Boolean(fullDay)}
        color={color}
      >
        <div className={singleRow} color={color}>
          <div className={singleRowRightSide}>
            {type === 'loggedTime' ? (
              <SingleRowLoggedTime
                item={item}
                color={color}
                suvSingleDay={suvSingleDay}
                displayFormat={displayFormat}
              />
            ) : (
              <SingleRow
                item={item}
                fullDay={fullDay}
                iconElements={iconElements}
              />
            )}
          </div>
          {textElements}
        </div>
      </div>
    );
  }

  return (
    <div
      className={boxInner}
      data-zero-rows="false"
      data-full-day={Boolean(fullDay)}
      data-pinned-timeoff={pinnedTimeOff}
    >
      {textElements}
      <div style={assignInlineVars({ [iconsContainerBgVar]: backgroundColor })}>
        <div
          className={iconsContainerWrapper}
          data-full-day={Boolean(fullDay)}
          data-pinned-timeoff={pinnedTimeOff}
        >
          <div className={iconsContainer}>{iconElements}</div>
        </div>

        {isPercentAllocation ? (
          <PercentageElements item={item} />
        ) : (
          <HourElements
            item={item}
            color={color}
            isFullDay={fullDay}
            displayFormat={displayFormat}
          />
        )}
      </div>
    </div>
  );
};
