import React from 'react';
import { t, Trans } from '@lingui/macro';

import {
  AlertBar,
  AlertBarVariantType,
} from '@float/ui/components/AlertBar/AlertBar';
import { CurrencyInput } from '@float/ui/deprecated/Input/CurrencyInput';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { IconWarningTriangle } from '@float/ui/icons/essentials/IconWarningTriangle';

import { usePersonTypes } from './hooks/usePersonTypes';
import { PersonTypeType } from './types';

import * as styles from './styles.css';

export const PersonType = (props: PersonTypeType) => {
  const {
    currencyErrors,
    currencyValue,
    currencyValuePlaceholder,
    currentUser,
    initialPerson,
    onCurrencyChange,
    onPersonTypeChange,
    person,
    personTypeOptions,
    personTypeErrors,
  } = props;

  const { personTypes, showPlaceholderLegacyAlert } = usePersonTypes({
    basePersonTypeOptions: personTypeOptions,
    initialPerson,
    person,
  });

  return (
    <div className={styles.personType}>
      <div className={styles.fields}>
        <VirtualSelect
          // @ts-expect-error VirtualSelect does not have types
          clearInputOnDropdownOpen={false}
          data-testid="personType"
          label={t`Type`}
          nonNullable
          onChange={onPersonTypeChange}
          options={personTypes}
          value={person.people_type_id}
          visibleItems={6}
          errors={personTypeErrors}
        />

        <CurrencyInput
          allowZero
          className={styles.inputCurrency}
          currentUser={currentUser}
          errors={currencyErrors}
          label={t`Hourly rate`}
          onChange={onCurrencyChange}
          value={currencyValue}
          placeholder={currencyValuePlaceholder}
        />
      </div>

      {showPlaceholderLegacyAlert && (
        <AlertBar
          icon={IconWarningTriangle}
          role="alert"
          type={AlertBarVariantType.Learn}
          message={
            <span>
              <Trans>
                Legacy Placeholders changed to an Employee or Contractor cannot
                be changed back to Legacy Placeholder.{' '}
                <a
                  href="https://support.float.com/en/articles/9214442-legacy-placeholders"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </Trans>
            </span>
          }
        />
      )}
    </div>
  );
};
