import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAccessRights, getIsPmSidebarLoaded } from 'selectors';

import IconSearch from '@float/ui/deprecated/Icons/icon-search';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { changeFilter, FILTER_TYPES } from '../actions';
import * as styled from './styles';

let _mounted = false;
const ASSIGNED_TO_ME = 'assignedToMe';

function PmSidebarSearch({
  isLoaded,
  searchContext,
  filterValue,
  isAcccessMemberViewOwn,
  onFilterChange,
}) {
  const onChange = useCallback(
    ({ value, groupValue: key, isCreate }) => {
      onFilterChange({
        key: isCreate ? 'contains' : key,
        value,
      });
    },
    [onFilterChange],
  );

  useEffect(() => {
    if (_mounted) {
      return;
    }

    if (isLoaded) {
      _mounted = true;
      if (isAcccessMemberViewOwn && !filterValue) {
        onChange({ groupValue: ASSIGNED_TO_ME, value: 'Assigned to me' });
      }
    }
  }, [isLoaded, isAcccessMemberViewOwn, filterValue, onChange]);

  return (
    <styled.Search>
      <VirtualSelect
        height={303}
        value={filterValue}
        groupedOptions={searchContext}
        prefix={<IconSearch size={19} />}
        hideDropdownIcon
        creatable
        createPrefix="Contains"
        clearInputOnDropdownOpen={false}
        onChange={onChange}
      />
    </styled.Search>
  );
}

const mapStateToProps = (state) => ({
  isLoaded: getIsPmSidebarLoaded(state),
  searchContext: state.pmSidebar.searchContext,
  filterValue: state.pmSidebar.filter[FILTER_TYPES.SEARCH].value,
  isAcccessMemberViewOwn: Boolean(getAccessRights(state).viewOnlyPeopleId),
});

const mapDispatchToProps = (dispatch) => ({
  onFilterChange: (filter) => dispatch(changeFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PmSidebarSearch);
