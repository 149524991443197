import React from 'react';

import { Button } from '@float/ui/deprecated/Button/Button';
import { Modal } from '@float/ui/deprecated/Modal';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';

const ConnectLimitModal = ({ onClose }) => {
  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Can't connect integration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        It’s only possible to connect one project management integration at a
        time.
        <Modal.Actions>
          <Button onClick={onClose}>OK</Button>
        </Modal.Actions>
      </Modal.Body>
    </Modal>
  );
};

export default withConfirm(ConnectLimitModal);
