import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { List } from '@float/ui/deprecated/Earhart/List';
import IconPlus from '@float/ui/deprecated/Icons/icon-plus';
import { TooltipWithHintAndMenu } from '@float/ui/deprecated/Tooltip/TooltipWithHintAndMenu';

function ActionMenu({ actionRows }) {
  const onClickOption = useCallback((data) => {
    data.callback();
  }, []);

  return (
    <List
      appearance="flue"
      options={actionRows}
      onClick={onClickOption}
      minWidth="205px"
    />
  );
}

function AddButton({ actionRows }) {
  const history = useHistory();

  const MenuIcon = useMemo(() => {
    return () => <ActionMenu actionRows={actionRows} />;
  }, [actionRows]);

  const NavIcon = useMemo(() => {
    return <IconPlus stroke="#fff" />;
  }, []);

  if (history.location.pathname == '/log-time') {
    return null;
  }

  return (
    <TooltipWithHintAndMenu
      distance={5}
      arrow={false}
      placement="bottom-end"
      hint="Add"
      menu={MenuIcon}
    >
      {(_, __, ___, toggleMenu) => (
        <NavIconBtn
          aria-label="Create"
          isPrimary
          icon={NavIcon}
          onClick={toggleMenu}
        />
      )}
    </TooltipWithHintAndMenu>
  );
}

export default AddButton;
