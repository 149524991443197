import React, { useCallback } from 'react';
import { compose } from 'redux';

import { deleteTemplate } from '@float/common/actions/projects';
import { Rights } from '@float/common/lib/acl';
import {
  useAppDispatchStrict,
  useAppSelectorStrict,
} from '@float/common/store';
import { ProjectTemplate } from '@float/types/project';
import Grid from '@float/ui/deprecated/Grid';
import IconTemplatePlus from '@float/ui/deprecated/Icons/icon-template-plus';
import { useSnackbar, withSnackbar } from '@float/ui/deprecated/Snackbar';
import {
  IWithModalConfirmDelete,
  withModalConfirmDelete,
} from '@float/web/modalManager/hoc/withModalConfirmDelete';
import {
  getAccessibleTemplates,
  getAccounts,
  getUser,
} from '@float/web/selectors';
import { showTemplateSidePanel } from '@float/web/sidePanel/actions';

import { NoTemplatesCreated } from './components/NoTemplatesCreated';
import { showModalConfirmTemplateDelete } from './helpers/showModalConfirmTemplateDelete';
import { useTemplateOpen } from './hooks/useTemplateOpen';
import {
  ProjectBlockNew,
  ProjectBlockNewText,
  TemplateBlockLabel,
} from './styles';
import { TemplateBlock } from './TemplateBlock';

interface ManageTemplatesListProps extends IWithModalConfirmDelete {
  isColumn: boolean;
  hideParent?: () => void;
  skipEdit?: boolean;
  skipNew?: boolean;
}

const ManageTemplatesListComponent: React.FC<ManageTemplatesListProps> = ({
  isColumn,
  hideParent,
  confirmDelete,
  confirmDeleteClose,
  skipEdit,
  skipNew,
}) => {
  const dispatch = useAppDispatchStrict();
  const currentUser = useAppSelectorStrict(getUser);
  const templates: ProjectTemplate[] = useAppSelectorStrict(
    getAccessibleTemplates,
  );
  const accounts = useAppSelectorStrict(getAccounts);

  const { showSnackbar } = useSnackbar();

  const handleNewTemplateOpen = useCallback(() => {
    const isHidingParent = hideParent && isColumn && templates.length > 0;

    if (isHidingParent) {
      hideParent();
    }

    hideParent?.();
    // @ts-expect-error showTemplateSidePanel still using AppDispatch
    dispatch(showTemplateSidePanel());
  }, [hideParent, isColumn, templates, dispatch]);

  const handleUseTemplateOpen = useTemplateOpen();

  const handleEditTemplateOpen = useCallback(
    (template: ProjectTemplate) => {
      const isHidingParent = hideParent && isColumn;

      if (isHidingParent) {
        hideParent();
      }

      hideParent?.();
      dispatch(
        // @ts-expect-error showTemplateSidePanel still using AppDispatch
        showTemplateSidePanel({ templateId: template.project_template_id }),
      );
    },
    [hideParent, isColumn, dispatch],
  );

  const deleteProjectTemplate = (
    templateId: ProjectTemplate['project_template_id'],
  ) => {
    return dispatch(deleteTemplate(templateId));
  };

  return (
    <div>
      <Grid
        collapsed={isColumn}
        // @ts-expect-error Grid is missing types
        AddNew={() =>
          Rights.canCreateProjectTemplate(currentUser) &&
          (!skipNew || templates.length === 0) ? (
            <ProjectBlockNew
              onClick={handleNewTemplateOpen}
              // @ts-expect-error ProjectBlockNew is missing types
              isColumn={isColumn}
            >
              <IconTemplatePlus size={40} />
              <ProjectBlockNewText style={{ marginTop: 14 }}>
                New template
              </ProjectBlockNewText>
            </ProjectBlockNew>
          ) : null
        }
        // @ts-expect-error Grid is missing types
        Empty={isColumn ? () => null : () => <NoTemplatesCreated />}
      >
        {templates.map((template) => (
          <TemplateBlock
            key={template.project_template_id}
            color={`#${template.color}`}
            title={template.project_name}
            author={{
              id: template.creator_id,
              name: accounts[template.creator_id]?.name || '',
            }}
            onUseClick={() => handleUseTemplateOpen(template, hideParent)}
            onEditClick={
              skipEdit ? undefined : () => handleEditTemplateOpen(template)
            }
            onDeleteClick={() =>
              showModalConfirmTemplateDelete(
                template,
                confirmDelete,
                confirmDeleteClose,
                deleteProjectTemplate,
                showSnackbar,
              )
            }
            isColumn={isColumn}
            isEditable={Rights.canUpdateProjectTemplate(currentUser, {
              template,
            })}
          />
        ))}
      </Grid>
      {templates.length === 0 && isColumn && (
        <>
          <TemplateBlockLabel>
            Create your team's first template
          </TemplateBlockLabel>
        </>
      )}
    </div>
  );
};

export const ManageTemplatesList = compose(withModalConfirmDelete)(
  withSnackbar(ManageTemplatesListComponent),
);
