import React, { useState } from 'react';
import { t } from '@lingui/macro';
import { useFailsafeActionDecorator } from 'lib/useFailsafeActionDecorator';
import { SnackbarMessages } from 'lib/useSnackbarWithUndoableAction';

import {
  applyView,
  deleteView,
  setViewPersonalState,
  setViewPinnedState,
  unapplyViewAction,
} from '@float/common/actions/views';
import { getIsStarterPlan } from '@float/common/selectors/companyPrefs';
import { getUser } from '@float/common/selectors/currentUser';
import { getCurrentView } from '@float/common/selectors/views';
import { useAppDispatchStrict, useAppSelector } from '@float/common/store';
import { SavedView } from '@float/types';
import { IconCheck } from '@float/ui/deprecated/Earhart/Icons';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';
import { ViewsSelector } from 'components/ViewsSelector';

import { ViewsModalContainer } from '../ViewsModalContainer';
import { ConfirmDeleteViewModal } from './ConfirmDeleteViewModal';
import { useViewsDataFetching } from './useViewsDataFetching';

const CONVERT_TO_SHARED_VIEW_MESSAGES: SnackbarMessages<SavedView> = {
  error: () => t`There was an issue while trying to share the View`,
  loading: ({ name: viewName }) => t`Converting “${viewName}” to a shared View`,
  success: ({ name: viewName }) => t`Converted “${viewName}” to a shared View`,
  undoError: ({ name: viewName }) =>
    t`Failed to revert “${viewName}” to a personal View`,
  undoLoading: ({ name: viewName }) =>
    t`Reverting “${viewName}” to a personal View`,
  undoSuccess: ({ name: viewName }) =>
    t`“${viewName}” reverted to a personal View`,
};

const CONVERT_TO_PERSONAL_VIEW_MESSAGES: SnackbarMessages<SavedView> = {
  error: () =>
    t`There was an issue while trying to converting the View to personal`,
  loading: ({ name: viewName }) =>
    t`Converting “${viewName}” to a personal View`,
  success: ({ name: viewName }) =>
    t`Converted “${viewName}” to a personal View`,
  undoError: ({ name: viewName }) =>
    t`Failed to revert “${viewName}” to a shared View`,
  undoLoading: ({ name: viewName }) =>
    t`Reverting “${viewName}” to a shared View`,
  undoSuccess: ({ name: viewName }) =>
    t`“${viewName}” reverted to a shared View`,
};

const PIN_VIEW_MESSAGES: SnackbarMessages<SavedView> = {
  error: () => t`There was an issue while trying to pin the View`,
  success: ({ name: viewName }) => t`“${viewName}” View pinned`,
  undoError: ({ name: viewName }) => t`Failed to unpin “${viewName}”`,
  undoLoading: ({ name: viewName }) => t`Unpinning “${viewName}”`,
  undoSuccess: ({ name: viewName }) => t`“${viewName}” unpinned successfully`,
};

const UNPIN_VIEW_MESSAGES: SnackbarMessages<SavedView> = {
  error: () => t`There was an issue while trying to unpin the View`,
  success: ({ name: viewName }) => t`“${viewName}” View unpinned`,
  undoError: ({ name: viewName }) => t`Failed to re-pin “${viewName}”`,
  undoLoading: ({ name: viewName }) => t`Re-pinning “${viewName}”`,
  undoSuccess: ({ name: viewName }) => t`“${viewName}” re-pinned successfully`,
};

const DELETE_VIEW_MESSAGES: SnackbarMessages<SavedView> = {
  error: () => t`There was an issue while trying to delete the View`,
  success: ({ name: viewName }) => t`“${viewName}” View deleted`,
  undoError: ({ name: viewName }) => t`“${viewName}” View restore failed`,
  undoLoading: ({ name: viewName }) => t`Restoring “${viewName}” View`,
  undoSuccess: ({ name: viewName }) => t`“${viewName}” restored`,
};

export function ViewsSelectorContainer({
  onAddFilterClick,
}: {
  onAddFilterClick: () => void;
}) {
  const [editingView, setViewEdit] = useState<SavedView | null>(null);
  const [deletingView, setViewDelete] = useState<SavedView | null>(null);

  const dispatch = useAppDispatchStrict();
  const views = useViewsDataFetching();
  const user = useAppSelector(getUser);
  const currentView = useAppSelector(getCurrentView);
  const isStarterPlan = useAppSelector(getIsStarterPlan);

  const { showSnackbar } = useSnackbar();

  const handleConvertToSharedView = useFailsafeActionDecorator(
    CONVERT_TO_SHARED_VIEW_MESSAGES,
    (view: SavedView) => dispatch(setViewPersonalState(view, false)),
  );

  const handleConvertToPersonalView = useFailsafeActionDecorator(
    CONVERT_TO_PERSONAL_VIEW_MESSAGES,
    (view: SavedView) => dispatch(setViewPersonalState(view, true)),
  );

  const handleViewPin = useFailsafeActionDecorator(
    PIN_VIEW_MESSAGES,
    (view: SavedView) => dispatch(setViewPinnedState(view, true)),
  );

  const handleViewUnpin = useFailsafeActionDecorator(
    UNPIN_VIEW_MESSAGES,
    (view: SavedView) => dispatch(setViewPinnedState(view, false)),
  );

  const handleDeleteView = useFailsafeActionDecorator(
    DELETE_VIEW_MESSAGES,
    async (view: SavedView) => {
      await dispatch(deleteView(view.id));

      setViewDelete(null);
    },
    'delete',
  );

  function handleViewSelect(view: SavedView) {
    const viewName = view.name;
    if (view.id === currentView?.id) {
      dispatch(unapplyViewAction());
      showSnackbar(t`“${viewName}” View unapplied`, {
        iconLeft: IconCheck,
      });
    } else {
      dispatch(applyView(view));

      showSnackbar(t`“${viewName}” View applied`, {
        iconLeft: IconCheck,
      });
    }
  }

  function handlePersonalChange(view: SavedView, personal: boolean) {
    if (personal) {
      handleConvertToPersonalView(view);
    } else {
      handleConvertToSharedView(view);
    }
  }

  function handlePinChange(view: SavedView, pinned: boolean) {
    if (pinned) {
      handleViewPin(view);
    } else {
      handleViewUnpin(view);
    }
  }

  return (
    <>
      <ViewsSelector
        views={views.data}
        error={views.error}
        loading={views.loading}
        onRetry={views.retry}
        currentView={currentView}
        isStarterPlan={isStarterPlan}
        user={user}
        onDelete={setViewDelete}
        onEdit={setViewEdit}
        onPersonalChange={handlePersonalChange}
        onViewSelect={handleViewSelect}
        onPinChange={handlePinChange}
        onAddFilterClick={onAddFilterClick}
      />
      {editingView && (
        <ViewsModalContainer
          onOpenChange={() => setViewEdit(null)}
          defaultValues={editingView}
        />
      )}
      {deletingView && (
        <ConfirmDeleteViewModal
          onClose={() => setViewDelete(null)}
          onConfirm={() => handleDeleteView(deletingView)}
          view={deletingView}
        />
      )}
    </>
  );
}
