import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';

const ELLIPSIS = '...';
export const MAX_PROJECT_CODE_LENGTH = 10;
export const MAX_CLIENT_NAME_LENGTH = 12;

export const calcMaxProjectCodeLength = (clientName: string): number => {
  // Client name exceeds max and leaves no additional space: use default project code max
  if (clientName.length > MAX_CLIENT_NAME_LENGTH) {
    return MAX_PROJECT_CODE_LENGTH;
  }

  // Client name is shorter than max: allow project code to take up all remaining space
  const remainingSpace = MAX_CLIENT_NAME_LENGTH - clientName.length;
  return MAX_PROJECT_CODE_LENGTH + remainingSpace;
};

export function truncateFields(
  projectCode: string,
  clientName: string,
): {
  projectCode: ReturnType<typeof trimTextForReadibility>;
  clientName: ReturnType<typeof trimTextForReadibility>;
} {
  const shouldTrimWhitespaces = true;
  const maxProjectCodeLength = calcMaxProjectCodeLength(clientName);

  const truncatedProjectCode = trimTextForReadibility(
    projectCode,
    maxProjectCodeLength,
    ELLIPSIS,
    shouldTrimWhitespaces,
  );
  const truncatedClientName = trimTextForReadibility(
    clientName,
    MAX_CLIENT_NAME_LENGTH,
    ELLIPSIS,
    shouldTrimWhitespaces,
  );

  return {
    projectCode: truncatedProjectCode,
    clientName: truncatedClientName,
  };
}
