import React from 'react';
import { connect } from 'react-redux';
import modalManagerHoc from 'modalManager/modalManagerHoc';

import { getUser } from '@float/common/selectors/currentUser';
import { Modal } from '@float/ui/deprecated/Modal';

import Integrations from './Integrations';

const MODAL_TYPE = 'calendarIntegrationsModal';
function CalendarIntegrationsModal(props) {
  const onClose = () => {
    props.manageModal({
      visible: false,
      modalType: MODAL_TYPE,
    });
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Calendar integrations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Integrations
          currentUser={props.currentUser}
          closeParentModal={onClose}
        />
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  currentUser: getUser(state),
});

export default modalManagerHoc({
  Comp: connect(mapStateToProps)(CalendarIntegrationsModal),
  modalType: MODAL_TYPE,
});
