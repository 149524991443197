import React from 'react';
import { Trans } from '@lingui/macro';

import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { TextButtonProps } from '@float/ui/deprecated/Earhart/Buttons/TextButton';
import { IconSwap } from '@float/ui/icons/essentials/IconSwap';

import * as styles from '../../EditTaskModal.css';

export type AllocationTypeToggleProps = {
  appearence?: TextButtonProps['appearance'];
  isAllocationByHoursPerDay: boolean;
  isAllocationByHoursSpecificTime: boolean;
  hasIcon: boolean;
  hasHoursPerDayLabel: boolean;
  onSelectHoursPerDay: () => void;
  onSelectHoursSpecificTime: () => void;
};

export const AllocationTypeToggle = (props: AllocationTypeToggleProps) => {
  const {
    appearence = 'flue-fill',
    isAllocationByHoursPerDay,
    isAllocationByHoursSpecificTime,
    hasIcon,
    hasHoursPerDayLabel,
    onSelectHoursSpecificTime,
    onSelectHoursPerDay,
  } = props;

  const allocationTypeSetSpecificTimeControl = (
    <div className={styles.timeSectionSpecificTimeToggleWrapper}>
      <TextButton
        appearance={appearence}
        onClick={onSelectHoursSpecificTime}
        // @ts-expect-error text button doesn't support essential icon types
        iconRight={hasIcon ? IconSwap : undefined}
      >
        <Trans>Specific time</Trans>
      </TextButton>
    </div>
  );

  const allocationTypeSetHoursPerDayControl = (
    <div className={styles.timeSectionTotalHoursToggleWrapper}>
      <TextButton
        appearance={appearence}
        onClick={onSelectHoursPerDay}
        // @ts-expect-error text button doesn't support essential icon types
        iconRight={hasIcon ? IconSwap : undefined}
      >
        {hasHoursPerDayLabel ? (
          <Trans>Hours/day</Trans>
        ) : (
          <Trans>Total hours</Trans>
        )}
      </TextButton>
    </div>
  );

  let allocationTypeToggle = null;

  if (isAllocationByHoursPerDay) {
    allocationTypeToggle = allocationTypeSetSpecificTimeControl;
  } else if (isAllocationByHoursSpecificTime) {
    allocationTypeToggle = allocationTypeSetHoursPerDayControl;
  }

  return allocationTypeToggle;
};
