import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import API3 from '@float/common/api3';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';
import { required, useInput } from '@float/ui/deprecated/helpers/formHooks';
import { Input } from '@float/ui/deprecated/Input';
import { Modal } from '@float/ui/deprecated/Modal';
import { useSnackbar } from '@float/ui/deprecated/Snackbar';

import { tryPersisting } from '../../ProjectModal.helpers';
import { sortTaskNames } from '../helpers/sortTaskNames';
import { Item } from './Task.styles';

const Tasks = styled.div`
  margin-bottom: 30px;
  border: 1px solid ${(p) => p.theme.medGrey};
  border-radius: 4px;

  ${Item}:first-child {
    border-top: 0;
  }

  ${Item}:last-child {
    border-bottom: 0;
  }
`;

export function MergeTaskNames({ tasks, renderTask, onClose, onSuccess }) {
  const initialRender = useRef(true);
  const sortedTasks = useMemo(() => sortTaskNames(tasks), [tasks]);
  const newTaskName = useInput('', {
    validate: required(''),
  });
  const [saving, setSaving] = useState(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (initialRender.current && sortedTasks && sortedTasks.length) {
      initialRender.current = false;
      const value = sortedTasks.find((t) => t.task_name).task_name;
      newTaskName.onChange({ target: { value } });
    }
  }, [sortedTasks, newTaskName]);

  const onMerge = async (e) => {
    prevent(e);
    if (saving || !newTaskName.validate()) return;
    setSaving(true);

    const res = await tryPersisting(
      async () => {
        const hasAtLeastOneBillableTask = tasks.some((t) => t.billable);
        const data = {
          task_meta_ids: tasks
            .filter((t) => !t.isNew)
            .map((t) => t.task_meta_id),
          task_name: newTaskName.value.trim(),
          billable: Number(hasAtLeastOneBillableTask),
        };

        if (!data.task_meta_ids.length) {
          // add project scenario
          return {
            isNew: true,
            task_meta_id: new Date().getTime(),
            task_name: data.task_name,
            billable: data.billable,
          };
        }

        return await API3.mergeTaskMeta(data);
      },
      { showSnackbar },
    );

    setSaving(false);
    if (res) {
      onSuccess(res.length ? res[0] : res);
      onClose();
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <form onSubmit={onMerge}>
        <Modal.Header>
          <Modal.Title>Merge {tasks.length} tasks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tasks>{sortedTasks.map((task) => renderTask(task, true))}</Tasks>
          <Input autoFocus label="New Task Name" {...newTaskName} />
        </Modal.Body>
        <Modal.Actions>
          <Button type="submit" loader={saving}>
            Merge tasks
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
}
