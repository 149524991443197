import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import { get } from 'lodash';
import styled from 'styled-components';

import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { Button } from '@float/ui/deprecated/Button/Button';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import jiraLogoImage from '@float/web/assets/images/jira_logo.svg';
import {
  addJiraCoInt,
  fetchJiraCoInts,
  fetchJiraConfig,
  fetchJiraPreload,
  linkJiraAccount,
  retryJiraPeopleSync,
} from '@float/web/integrations/actions/jira';

import OnOffStatus from '../OnOffStatus';
import ConnectLimitModal from '../shared/ConnectLimitModal';
import {
  P,
  Subsection,
  SubsectionAction,
  SubsectionContent,
  SubsectionLogo,
  SubsectionMessage,
  SubsectionStatus,
} from '../styles';
import trackJiraEvent from './trackJiraEvent';

const JiraLogo = styled.img`
  display: block;
  width: auto !important;
  height: 32px;
`;

const ContentWrapper = styled.div`
  flex-direction: column;
  margin-right: auto;
`;

const errors = {
  ADD_COINT_ERROR: 'There was a problem connecting to Jira. Please retry.',
  EXCEEDS_PEOPLE_CAP:
    'Failed to import people. Plan cap reached. Please upgrade your Float plan and retry.',
};

function JiraSubsection(props) {
  const {
    jira,
    startSetup,
    linkJiraAccount,
    fetchJiraPreload,
    fetchJiraConfig,
    retryJiraPeopleSync,
  } = props;
  const user = useAppSelectorStrict(getUser);
  const coInt = (jira.coInts || []).filter((ci) => ci.type === 'jira')[0];
  const jiraConfig = jira.jiraConfig;
  const error = Object.entries(get(coInt, 'ancillary.errors', {})).reduce(
    (result, [errorName, hasError]) => result || (hasError ? errorName : null),
    null,
  );
  const isInitStatus = get(coInt, 'status') === 1; // yet to complete setup
  const isSyncingStatus = get(coInt, 'status') === 2; // yet to complete setup
  const isEditMode = get(coInt, 'status') >= 3;
  const params = new URL(document.location).searchParams;
  const jiraSetupToken = params.get('jira_setup_token');
  const [retryPeopleSyncButtonEnabled, setRetryPeopleSyncButtonEnabled] =
    useState(true);
  const [showConnectLimit, setShowConnectLimit] = useState(false);

  useEffect(() => {
    /* eslint-disable no-use-before-define */
    props.fetchJiraCoInts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      jiraSetupToken &&
      jira.coIntsLoadState === 'LOAD_SUCCESS' &&
      !coInt &&
      !jira.linkJiraLoadState
    ) {
      linkJiraAccount(jiraSetupToken);
      setDisabled(true);
      setReadyStartSetup(true);
    }
  }, [
    jiraSetupToken,
    jira.coIntsLoadState,
    coInt,
    jira.linkJiraLoadState,
    linkJiraAccount,
  ]);

  useEffect(() => {
    if (isEditMode && !retryPeopleSyncButtonEnabled) {
      setRetryPeopleSyncButtonEnabled(true);
    }
  }, [isEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const [disabled, setDisabled] = useState(false);
  const [readyStartSetup, setReadyStartSetup] = useState(false);

  useEffect(() => {
    if (jiraSetupToken && coInt) {
      setDisabled(true);
      setReadyStartSetup(true);
      fetchJiraPreload(coInt.integrations_co_id);
    }
  }, [jiraSetupToken, coInt, fetchJiraPreload]);

  useEffect(() => {
    if (coInt && !jiraConfig) {
      fetchJiraConfig(coInt);
    }
  }, [coInt]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (jira.coIntsLoadState === 'LOAD_SUCCESS') {
      props.onCoIntStatusLoad(coInt ? coInt.status : null);
    }
  }, [jira.coIntsLoadState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDisabled(jira.initialImportStarted);
  }, [jira.initialImportStarted]);

  useEffect(() => {
    if (
      readyStartSetup &&
      jira.coIntsLoadState === 'LOAD_SUCCESS' &&
      jira.preloadLoadState === 'LOAD_SUCCESS' &&
      jira.fetchConfigLoadState !== 'LOADING'
    ) {
      window.history.replaceState(null, null, window.location.pathname);
      startSetup();
    }
  }, [
    jira.addCoIntLoadState,
    jira.coIntsLoadState,
    jira.fetchConfigLoadState,
    jira.preloadLoadState,
    jiraSetupToken,
    readyStartSetup,
    startSetup,
  ]);

  function onConnectAccountClick() {
    if (props.connectLimitReached) {
      setShowConnectLimit(true);
      return;
    }

    if (coInt) {
      setDisabled(true);
      setReadyStartSetup(true);
      fetchJiraPreload(coInt.integrations_co_id);
      fetchJiraConfig(coInt);
      return;
    }
    trackJiraEvent('Integration started', undefined, user.cid);
    window.open('https://marketplace.atlassian.com/apps/1222203/float');
  }

  function onEditClick() {
    setDisabled(true);
    setReadyStartSetup(true);
    fetchJiraPreload(coInt.integrations_co_id);
    fetchJiraConfig(coInt);
  }

  function onRetryPeopleSyncClick() {
    retryJiraPeopleSync(coInt.integrations_co_id);
    setRetryPeopleSyncButtonEnabled(false);
  }

  const disablePeopleCapError =
    error === 'EXCEEDS_PEOPLE_CAP' && !!jiraConfig && !jiraConfig.futurePeople;
  const showError = !!error && !disablePeopleCapError;

  return (
    <Subsection>
      <SubsectionLogo>
        <JiraLogo src={jiraLogoImage} />
      </SubsectionLogo>
      <SubsectionContent>
        <ContentWrapper>
          <P>
            <Trans>
              Import & sync projects, issues and people from{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.atlassian.com/software/jira"
              >
                Jira.{' '}
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.float.com/en/articles/3854834-jira"
              >
                Learn more
              </a>
              .
            </Trans>
          </P>
          {(isInitStatus || showError) && (
            <SubsectionMessage isError={showError}>
              {isInitStatus && (
                <span>{t`Finish setting up your Jira integration`}</span>
              )}
              {showError && <span>{errors[error]}</span>}
            </SubsectionMessage>
          )}
        </ContentWrapper>
      </SubsectionContent>
      <SubsectionStatus>
        <OnOffStatus
          isOn={!!(coInt && !isInitStatus)}
          isError={showError}
          customText={
            (showError && t`Error`) || (isSyncingStatus && t`Syncing`)
          }
        />
      </SubsectionStatus>
      <SubsectionAction>
        {error === 'EXCEEDS_PEOPLE_CAP' && showError && (
          <Button
            minWidth="100%"
            onClick={onRetryPeopleSyncClick}
            disabled={!retryPeopleSyncButtonEnabled}
            style={{
              marginBottom: '20px',
            }}
          >
            Retry Import
          </Button>
        )}
        {(!coInt || isInitStatus) && (
          <Button
            minWidth="100%"
            onClick={onConnectAccountClick}
            disabled={disabled}
            loader={false}
          >
            {isInitStatus
              ? disabled
                ? t`Loading...`
                : t`Continue Setup`
              : disabled
                ? t`Linking...`
                : t`Connect`}
          </Button>
        )}
        {isEditMode && (
          <Button appearance="secondary" minWidth="100%" onClick={onEditClick}>
            <Trans>Edit</Trans>
          </Button>
        )}
      </SubsectionAction>
      {showConnectLimit && (
        <ConnectLimitModal onClose={() => setShowConnectLimit(false)} />
      )}
    </Subsection>
  );
}

const mapStateToProps = (state) => ({
  jira: state.integrations.jira,
});

const mapDispatchToProps = {
  fetchJiraCoInts,
  fetchJiraPreload,
  fetchJiraConfig,
  addJiraCoInt,
  linkJiraAccount,
  retryJiraPeopleSync,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirm(JiraSubsection));
