import {
  useAppDispatchDecorator,
  useAppSelectorStrict,
} from '@float/common/store';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';
import { EXCLUDE_FOR_NEW_USERS } from '@float/web/store/onboardingManager/constants';
import { getOnboardingManagerStatus } from '@float/web/store/onboardingManager/selectors/manager';

export function useExcludePromptsForNewUsers() {
  const onboardingStatus = useAppSelectorStrict(getOnboardingManagerStatus);

  const hideNewFeaturesCallouts = useAppDispatchDecorator(() => {
    return updatePrompts(EXCLUDE_FOR_NEW_USERS, true);
  });

  useOnMount(() => {
    if (!onboardingStatus.completed) {
      hideNewFeaturesCallouts();
    }
  });
}
