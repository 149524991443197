import styled from 'styled-components';

import { TopGreySection as ModalTopGreySection } from '@float/ui/deprecated/Modal/ModalHeader/styles';

export const StyledModalInput = styled(ModalTopGreySection)`
  display: flex;

  input {
    height: 40px;
  }
`;
