import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';

import { ProjectFormData } from '../../types';

import * as styles from './styles.css';

export type CheckboxFieldProps = {
  name: FieldPath<ProjectFormData>;
  label?: string;
  disabled?: boolean;
  hideLabel?: boolean;
};

export function CheckboxField(props: CheckboxFieldProps) {
  return (
    <Controller
      name={props.name}
      render={({ field }) => (
        <Checkbox
          className={styles.checkbox}
          label={props.label}
          value={field.value}
          onChange={field.onChange}
          disabled={props.disabled}
          hideLabel={props.hideLabel}
        />
      )}
    />
  );
}
