import React, { useMemo } from 'react';
import { getPrefs } from 'selectors';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { trackEvent } from '@float/common/lib/analytics';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import EHIconDensityComfortable from '@float/ui/deprecated/Earhart/Icons/Icon/IconDensityComfortable';
import EHIconDensityCompact from '@float/ui/deprecated/Earhart/Icons/Icon/IconDensityCompact';
import EHIconDensitySpacious from '@float/ui/deprecated/Earhart/Icons/Icon/IconDensitySpacious';
import { List } from '@float/ui/deprecated/Earhart/List';
import IconViewControl from '@float/ui/deprecated/Icons/icon-view-control';
import { TooltipWithHintAndMenu } from '@float/ui/deprecated/Tooltip/TooltipWithHintAndMenu';
import { changeZoom } from '@float/web/actions/app';

const Menu = () => {
  const reduxDispatch = useAppDispatch();
  const zoomPref = useAppSelector((state) => {
    const { sked_zoom = 0 } = getPrefs(state);
    return sked_zoom;
  });

  const actionRows = [
    {
      icon: <EHIconDensityCompact />,
      name: 'Compact',
      value: 1,
    },
    {
      icon: <EHIconDensityComfortable />,
      name: 'Comfortable',
      value: 0,
    },
    {
      icon: <EHIconDensitySpacious />,
      name: 'Spacious',
      value: 2,
    },
  ];

  const onClick = (data) => {
    reduxDispatch(changeZoom(data.value));
    trackEvent('Vertical zoom set', { type: data.name });
  };

  return (
    <List
      appearance="flay"
      options={actionRows}
      value={zoomPref}
      onClick={onClick}
      minWidth="200px"
    />
  );
};

export default function VerticalZoom() {
  const Icon = useMemo(() => <IconViewControl />, []);
  const ActionMenu = useMemo(() => () => <Menu />, []);

  return (
    <TooltipWithHintAndMenu
      key="viewmode"
      distance={5}
      arrow={false}
      placement="bottom"
      hint="Density"
      menu={ActionMenu}
    >
      {(_, showMenu) => (
        <NavIconBtn isPrimary={false} onClick={showMenu} icon={Icon} />
      )}
    </TooltipWithHintAndMenu>
  );
}
