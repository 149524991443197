import { FloatStorageKey, storage } from '@float/libs/storage';

let enabled: boolean;

export const getIsDebugTaskNamesEnabled = async () => {
  // OPTIMIZATION: we read from localStorage once to avoid
  // possible performance issues related to reading from hard disk
  if (enabled === undefined) {
    enabled = Boolean(await storage.getItem(FloatStorageKey.DebugTaskNames));
  }

  return enabled;
};

export const setDebugTaskNames = (value: boolean) => {
  enabled = value;
  storage.setItem(FloatStorageKey.DebugTaskNames, value ? 'true' : '');
};
