import React from 'react';

import { Button } from '@float/ui/deprecated/Button/Button';

import { StyledNav, StyledPagination } from './styles';

export const StepNav = (props) => {
  const { step, stepsTotal, onClickNext } = props;

  return (
    <StyledNav>
      <StyledPagination>
        <p>
          {step} of {stepsTotal}
        </p>
      </StyledPagination>

      <Button size="small" onClick={onClickNext}>
        {step < stepsTotal ? 'Next' : 'Finish'}
      </Button>
    </StyledNav>
  );
};
