import { css } from 'styled-components';

import { getDiagonalStripeGradient } from '@float/libs/styles/getDiagonalStripeGradient';

import * as Colors from '../../Earhart/Colors';
import colors from '../colors';

export const DateRangePickerGlobalStyle = css`
  .DateRangePicker {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .DateRangePicker,
  .DateRangePicker * {
    box-sizing: border-box;
  }
  .DateRangePicker__Legend {
    color: #52575e;
    font-size: 14px;
    line-height: 16px;
    list-style-type: none;
    margin: 20px 0;
    padding: 0;
  }
  .DateRangePicker__LegendItem {
    display: inline-block;
    margin: 0 20px;
  }
  .DateRangePicker__LegendItemColor {
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    margin-right: 6px;
    vertical-align: text-bottom;
    width: 14px;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .DateRangePicker__LegendItemColor--selection {
    background-color: #ed5434;
  }
  .DateRangePicker__Month {
    color: #333;
    display: inline-block;
    margin: 0 20px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 275px;
  }
  .DateRangePicker__MonthDates {
    margin: 10px 0 0 0;
    width: 100%;
  }
  .DateRangePicker__WeekdayHeading,
  .DateRangePicker__Date {
    font-size: 14px;
    line-height: 1;
    padding: 10px 0;
    text-align: center;
    width: 14.285714285714286%;
  }
  .DateRangePicker__WeekdayHeading {
    border-bottom: 1px solid #f4f5f6;
    color: #000;
    font-weight: bold;
  }
  .DateRangePicker__WeekdayHeading abbr[title] {
    border-bottom-width: 0;
    color: #000;
    cursor: pointer;
    font-size: inherit;
    text-decoration: none;
  }
  .DateRangePicker__Date {
    border: 0 solid #f4f5f6;
    border-right-width: 1px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .DateRangePicker__Date:first-child {
    border-left-width: 1px;
  }
  .DateRangePicker__Date--weekend {
    // Adds a background color for weekends
    // background-color: #f6f7f9;
  }
  .DateRangePicker__Date--otherMonth {
    opacity: 0.25;
  }
  .DateRangePicker__Date--is-disabled {
    color: ${colors.black25} !important;
    background: ${getDiagonalStripeGradient(colors.midGrey, -45)};
    background-size: 5px 5px;
    cursor: default;
  }
  .DateRangePicker__Date--is-selected {
    color: #fff;
    z-index: 1;
  }
  .DateRangePicker__Date--is-highlighted {
    color: #333;
  }
  .DateRangePicker__Date--is-locked {
    background-color: #f8f8f8;
  }
  .DateRangePicker__CalendarDatePeriod {
    bottom: 0;
    position: absolute;
    top: 0;
  }
  .DateRangePicker__CalendarDatePeriod--am {
    left: 0;
    right: 50%;
  }
  .DateRangePicker__CalendarDatePeriod--pm {
    left: 50%;
    right: 0;
  }
  .DateRangePicker__CalendarSelection {
    background-color: #ed5434;
    border: 1px solid #eb401d;
    bottom: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 5px;
  }
  .DateRangePicker__CalendarSelection--inOtherMonth {
    opacity: 0.5;
  }
  .DateRangePicker__CalendarSelection--start {
    border-bottom-left-radius: 5px;
    border-right-width: 0;
    border-top-left-radius: 5px;
    left: 5px;
  }
  .DateRangePicker__CalendarSelection--end {
    border-bottom-right-radius: 5px;
    border-left-width: 0;
    border-top-right-radius: 5px;
    right: 5px;
  }
  .DateRangePicker__CalendarSelection--segment {
    border-left-width: 0;
    border-right-width: 0;
  }
  .DateRangePicker__CalendarSelection--single {
    border-radius: 8px;
    left: 5px;
    right: 5px;
  }
  span:has(+ .DateRangePicker__CalendarSelection--single) {
    color: white;
  }
  .DateRangePicker__CalendarHighlight {
    background-color: rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
    bottom: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 5px;
  }
  .DateRangePicker__CalendarHighlight--inOtherMonth {
    opacity: 0.5;
  }
  .DateRangePicker__CalendarHighlight--start {
    border-bottom-left-radius: 5px;
    border-right-width: 0;
    border-top-left-radius: 5px;
    left: 5px;
  }
  .DateRangePicker__CalendarHighlight--end {
    border-bottom-right-radius: 5px;
    border-left-width: 0;
    border-top-right-radius: 5px;
    right: 5px;
  }
  .DateRangePicker__CalendarHighlight--segment {
    border-left-width: 0;
    border-right-width: 0;
  }
  .DateRangePicker__CalendarHighlight--single {
    background-color: #fff;
    border: 1px solid #eb401d;
    border-radius: 8px;
    left: 5px;
    right: 5px;
  }
  .DateRangePicker__HalfDateStates {
    bottom: -50px;
    left: -50px;
    position: absolute;
    right: -50px;
    top: -50px;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .DateRangePicker__FullDateStates {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .DateRangePicker__DateLabel {
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 3;
  }

  .DateRangePicker__Month {
    width: 240px;

    &:nth-child(5) {
      margin-left: 30px;
    }
  }

  .DateRangePicker__PaginationArrow > .DateRangePicker__PaginationArrowIcon {
    border-width: 6px !important;
  }
  .DateRangePicker__PaginationArrow--previous:hover,
  .DateRangePicker__PaginationArrow--next:hover {
    background: transparent;
  }
  .DateRangePicker__PaginationArrow--previous:hover > div,
  .DateRangePicker__PaginationArrow--next:hover > div {
    color: #000;
  }
  .DateRangePicker__PaginationArrow--previous {
    left: 0;
  }
  .DateRangePicker__PaginationArrow--previous > div {
    right: 0;
    left: 0;
    color: #808080;
  }
  .DateRangePicker__PaginationArrow--previous:hover > div {
    border-right-color: #000;
  }
  .DateRangePicker__PaginationArrow--next {
    right: 0;
  }
  .DateRangePicker__PaginationArrow--next > div {
    left: initial;
    right: 0;
    color: #808080;
  }
  .DateRangePicker__PaginationArrow--next:hover > div {
    border-left-color: #000;
  }
  .DateRangePicker__Month {
    margin: 8px 0;
  }
  .DateRangePicker__MonthHeader span {
    font-size: 18px;
  }
  .DateRangePicker table {
    border-collapse: collapse;
  }
  .DateRangePicker table thead tr th {
    text-transform: uppercase;
    color: #9ea6aa;
    font-weight: 400;
    border-bottom: 0;
  }
  .DateRangePicker table thead tr th abbr {
    color: #9ea6aa;
  }
  .DateRangePicker table tbody {
    background: transparent;
  }
  .DateRangePicker table tbody td {
    border: none;
    overflow: hidden;
  }
  .DateRangePicker table tbody td.DateRangePicker__Date {
    color: ${colors.charcoalGrey};
  }
  .DateRangePicker table tbody td.DateRangePicker__Date--otherMonth {
    opacity: 0;
  }
  .DateRangePicker table tbody td:hover {
    :not(.DateRangePicker__Date--is-disabled):not(
        .DateRangePicker__Date--is-unselectable
      ) {
      color: ${colors.charcoalGrey} !important;

      .hover-bg {
        border-radius: 8px;
        background: ${Colors.Primary.Flue.Light[4]};
        width: 30px;
        height: 30px;
        position: absolute;
        top: 2px;
        left: 50%;
        margin-left: -15px;
        z-index: 2;
      }
    }
    &.DateRangePicker__Date--is-selected {
      span {
        color: #333;
      }
    }
  }
  .DateRangePicker table tbody td.DateRangePicker__Date--is-highlighted {
  }
  .DateRangePicker table tbody td .DateRangePicker__CalendarHighlight--single {
    display: none !important;
  }
  .DateRangePicker table tbody td.DateRangePicker__Date--is-selected {
  }
  .DateRangePicker
    table
    tbody
    td.DateRangePicker__Date--is-selected
    .DateRangePicker__CalendarSelection--segment {
    border: none;
    left: 0;
    right: 0;
    background-color: ${Colors.FIN.Lt.Surface.Surf5};
  }
  .DateRangePicker
    table
    tbody
    td.DateRangePicker__Date--is-selected.DateRangePicker__Date--weekend--is-selected {
    &:first-child {
      .DateRangePicker__CalendarSelection {
        left: 3px;
      }
      .DateRangePicker__CalendarSelection--segment {
        left: 3px;
        border-radius: 8px 0 0 8px;
      }
    }
    &:last-child {
      .DateRangePicker__CalendarSelection--segment {
        right: 2px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
  // Hides the circular selection
  td.DateRangePicker__Date--is-selected .DateRangePicker__CalendarSelection {
    border: none;
    background: ${colors.slateGrey};
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
  }

  .float-single-datepicker-container td.DateRangePicker__Date--is-selected {
    .DateRangePicker__CalendarSelection {
      background: ${colors.slateGrey};
    }
    &.DateRangePicker__Date--today > .DateRangePicker__DateLabel:after {
      background-color: ${colors.grey};
    }
  }

  .float-single-datepicker-container
    td.DateRangePicker__Date--is-selected
    span {
    color: white;
  }

  td.DateRangePicker__Date--is-selected {
    .DateRangePicker__CalendarSelection--start:after,
    .DateRangePicker__CalendarSelection--end:before {
      content: '';
      background-color: ${Colors.FIN.Lt.Surface.Surf5};
      width: 8px;
      height: 100%;
      position: absolute;
      z-index: -1;
    }
    .DateRangePicker__CalendarSelection--start:after {
      right: -2px;
    }
    .DateRangePicker__CalendarSelection--end:before {
      left: -2px;
    }
  }

  td.DateRangePicker__Date--is-selected.DateRangePicker__Date--weekend {
    &:last-child {
      .DateRangePicker__CalendarSelection--start:after {
        display: none;
      }
    }
    &:first-child {
      .DateRangePicker__CalendarSelection--end:before {
        display: none;
      }
    }
  }

  td.DateRangePicker__Date--is-selected
    .DateRangePicker__CalendarSelection--start,
  td.DateRangePicker__Date--is-selected
    .DateRangePicker__CalendarSelection--end {
    background-color: ${Colors.FIN.Lt.Emphasis.Disabled};
    border-radius: 8px;
  }
  /* td.DateRangePicker__Date--is-selected
    .DateRangePicker__CalendarSelection--start {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  td.DateRangePicker__Date--is-selected
    .DateRangePicker__CalendarSelection--end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  } */
  .DateRangePicker
    table
    tbody
    td.DateRangePicker__Date--is-selected
    .DateRangePicker__CalendarSelection--start
    + .DateRangePicker__DateLabel,
  td.DateRangePicker__Date--is-selected
    .DateRangePicker__CalendarSelection--end
    + .DateRangePicker__DateLabel {
    display: none;
  }

  .DateRangePicker__Date--today > .DateRangePicker__DateLabel:after {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${Colors.FIN.Lt.Emphasis.Primary};
    pointer-events: none;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -2px;
  }
`;
