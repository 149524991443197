import React from 'react';
import { connect } from 'react-redux';

import { Button } from '@float/ui/deprecated/Button/Button';

function SlackStart(props) {
  const { loading, connected } = props;
  const clientId = '&client_id=4133336183.292247667200';
  const redirect = `&redirect_uri=${props.redirectUri}`;
  const state = `&state=${props.cid}`;
  const handleOnClick = () => {
    window.open(
      `https://slack.com/oauth/v2/authorize?scope=incoming-webhook${clientId}${redirect}${state}`,
    );
  };
  if (connected) {
    return (
      <Button minWidth="100%" appearance="secondary" onClick={handleOnClick}>
        Edit
      </Button>
    );
  }
  return (
    <Button onClick={handleOnClick} disabled={loading}>
      {loading ? 'Loading...' : 'Add to Slack'}
    </Button>
  );
}

export default connect((state) => {
  return {
    cid: state.currentUser && state.currentUser.cid,
  };
})(SlackStart);
