import React, { MouseEventHandler, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { isViewPageAccessibleByMembers } from '@float/common/lib/rights';
import { noop } from '@float/libs/utils/noop';
import {
  FilterToken as FilterTokenValue,
  SavedView,
  SavedViewSettings,
} from '@float/types';
import { Button } from '@float/ui/deprecated/Button/Button';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles/TextToggle';
import { Modal } from '@float/ui/deprecated/Modal';
import { IconLayers } from '@float/ui/icons/essentials/IconLayers';
import { IconShare } from '@float/ui/icons/essentials/IconShare';

import { ConfirmModal } from '../ConfirmModal';
import { FiltersField } from './FiltersField';
import { ViewNameInput } from './ViewNameInput';

import * as styles from './styles.css';

export type ViewsDefaultValues = {
  id?: number;
  name?: string;
  filters: FilterTokenValue[];
  personal?: boolean;
  settings: SavedView['settings'];
};

export type ViewsFormPayload = {
  id?: number;
  name: string;
  filters: FilterTokenValue[];
  personal: boolean;
  settings: SavedView['settings'];
};

export type ViewsModalProps = {
  defaultValues: ViewsDefaultValues;
  isShareEnabled: boolean;
  onSubmit: (view: ViewsFormPayload) => Promise<void>;
  onOpenChange: (open: boolean) => void;
  isUniqueViewName: (name: string, personal: boolean) => boolean;
};

const shouldShowAccessAlert = (data: ViewsFormPayload) =>
  !data.id && !data.personal && !isViewPageAccessibleByMembers(data);

const getReadablePageName = (page: SavedViewSettings['page']): string => {
  switch (page) {
    case 'manage-people':
      return t`People`;
    case 'manage-projects':
      return t`Projects`;
    case 'log-time':
      return t`Log team`;
    case 'schedule-people':
      return t`Schedule`;
    case 'log-my-time':
      return t`Log my time`;
    case 'project-plan':
      return t`Project plan`;
    case 'people-report':
      return t`People report`;
    case 'projects-report':
      return t`Projects report`;
  }
};

export function ViewsModal(props: ViewsModalProps) {
  const form = useForm<ViewsFormPayload>({
    defaultValues: {
      personal: true,
      ...props.defaultValues,
    },
  });
  const [isAccessAlertModalVisible, setAccessAlertModalVisible] =
    useState(false);

  const handleCancel: MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    props.onOpenChange(false);
  };

  const handleClose = () => {
    props.onOpenChange(false);
  };

  const isUniqueViewName = (name: string) => {
    return props.isUniqueViewName(name, form.getValues().personal);
  };

  async function handleSubmit(data: ViewsFormPayload) {
    if (shouldShowAccessAlert(data) && !isAccessAlertModalVisible) {
      setAccessAlertModalVisible(true);
      return;
    }

    setAccessAlertModalVisible(false);

    try {
      await props.onSubmit(data);

      props.onOpenChange(false);
    } catch (err) {
      form.setError('root', {
        type: 'server-error',
      });
    }
  }

  const {
    formState: { errors },
  } = form;

  const personal = form.watch('personal');
  const pageName = getReadablePageName(form.watch('settings.page'));

  return (
    <Modal
      width={640}
      isOpen
      onClose={handleClose}
      shouldCloseOnEsc
      focusFirstEl
    >
      <FormProvider {...form}>
        <form
          className={styles.form}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <div className={styles.row}>
            {personal ? (
              <IconLayers title={t`Personal View`} size={32} />
            ) : (
              <IconShare title={t`Shared View`} size={32} />
            )}
            <ViewNameInput isUniqueViewName={isUniqueViewName} />
          </div>
          <div className={styles.row}>
            <label className={styles.label}>
              <Trans>Filter</Trans>
            </label>
            <FiltersField filters={props.defaultValues.filters} />
          </div>
          {props.isShareEnabled && (
            <div className={styles.row}>
              <label className={styles.label}>
                <Trans>Type</Trans>
              </label>
              <div>
                <Controller
                  name="personal"
                  control={form.control}
                  render={({ field }) => (
                    <TextToggle<boolean>
                      variant="tiny"
                      onChange={(option) => field.onChange(option.value)}
                      name="type"
                      value={field.value}
                      options={[
                        { label: t`Personal`, value: true },
                        { label: t`Shared`, value: false },
                      ]}
                    ></TextToggle>
                  )}
                ></Controller>
              </div>
            </div>
          )}
          {errors.root?.type === 'server-error' && (
            <div data-testid="server-error" className={styles.error}>
              <Trans>Save failed, try again</Trans>
            </div>
          )}
          <div className={styles.actions}>
            <Button type="submit" loader={form.formState.isSubmitting}>
              <Trans>Save View</Trans>
            </Button>
            <Button appearance="clear" onClick={handleCancel}>
              <Trans>Cancel</Trans>
            </Button>
          </div>
        </form>
      </FormProvider>
      {isAccessAlertModalVisible && (
        <ConfirmModal
          title={
            <div className={styles.alertMessage}>
              <Trans>
                This View will only be available to team members who can access
                the {pageName} page.
              </Trans>
            </div>
          }
          confirmLabel={t`Got it`}
          hideCancel
          onClose={noop}
          onConfirm={form.handleSubmit(handleSubmit)}
        />
      )}
    </Modal>
  );
}
