import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';
import { useSidePanel } from 'sidePanel/useSidePanel';

import { deleteTemplate as deleteTemplateAction } from '@float/common/actions';
import { useAppDispatchStrict } from '@float/common/store';
import { useModalControls } from '@float/ui/deprecated/Modal/hooks';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';
import { IconTemplatePlus } from '@float/ui/icons/essentials/IconTemplatePlus';
import { IconTrash2 } from '@float/ui/icons/essentials/IconTrash2';
import { ConfirmModal } from 'components/modals/ConfirmModal';

import { SidePanelMeatballMenu } from '../../components/SidePanelMeatballMenu';
import { ProjectFormData } from '../../types';
import { useTemplateOpenHandler } from './hooks/useTemplateOpenHandler';

import * as styles from '../ProjectHeaderSection/styles.css';

type ModalProps = ReturnType<typeof useModalControls>;

function Delete(props: { confirmModal: ModalProps }) {
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatchStrict();
  const { getValues } = useFormContext<ProjectFormData>();
  const name = getValues('project.project_name');
  const templateId = getValues('templateId');
  const { hideSidePanel } = useSidePanel();

  if (!props.confirmModal.isModalVisible) {
    return null;
  }

  const handleDelete = async () => {
    if (!templateId) return;

    try {
      await dispatch(deleteTemplateAction(templateId));
      hideSidePanel();
      showSnackbar(t`“${name}” deleted.`);
    } catch (err) {
      showSnackbar(t`“${name}” deletion failed.`);
    }
  };

  return (
    <ConfirmModal
      onClose={props.confirmModal.closeModal}
      onConfirm={handleDelete}
      title={t`Delete template`}
      message={t`Existing projects created from this template will be unaffected.`}
      confirmLabel={t`Delete`}
      confirmAppearance="danger"
    />
  );
}

export function TemplateActionsMenu() {
  const confirmDeleteModal = useModalControls();
  const onUseTemplate = useTemplateOpenHandler();
  const items = [
    {
      value: t`Use template`,
      icon: IconTemplatePlus,
      onClick: onUseTemplate,
    },
    {
      value: t`Delete`,
      icon: IconTrash2,
      onClick: confirmDeleteModal.openModal,
    },
  ];

  return (
    <>
      <SidePanelMeatballMenu
        className={styles.projectHeaderMenuButton}
        vertical
        items={items}
        trigger={{
          label: t`Template options`,
        }}
      />
      <Delete confirmModal={confirmDeleteModal} />
    </>
  );
}
