import React from 'react';

import { Department } from '@float/types/department';
import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';

export const addSubDepartmentIcon = (
  departmentOptions: VirtualSelectOption[],
  departments: Record<string, Department>,
) => {
  return departmentOptions.map((o) => {
    const department = departments[o.value!];

    return {
      ...o,
      icon: department?.parent_id ? (
        <IconArrowDownRight size={16} />
      ) : undefined,
    };
  });
};
