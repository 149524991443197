import { useCallback, useState } from 'react';
import useMount from 'react-use/esm/useMount';

import { trackEvent } from '@float/common/lib/gtm';
import { FloatStorageKey, storage } from '@float/libs/storage';

export type TimeRangeSettings = {
  insightsPreferredUnit: 'hours' | 'percentage';
};

export const TIME_RANGE_SETTINGS_DEFAULT_VALUE: TimeRangeSettings = {
  insightsPreferredUnit: 'hours',
};

export const useTimeRangeSettings = () => {
  const [timeRangeSettings, setTimeRangeSettings] = useState(
    TIME_RANGE_SETTINGS_DEFAULT_VALUE,
  );

  useMount(async () => {
    const value =
      (await storage.getItem(FloatStorageKey.TimeRangeSettingsCache)) ?? '';

    try {
      const parsedValue = JSON.parse(value);

      if (parsedValue) {
        setTimeRangeSettings(parsedValue);
      }
    } catch (e) {
      // Do nothing
    }
  });

  const setTimeRangeSetting = useCallback(
    (
      key: keyof TimeRangeSettings,
      value: TimeRangeSettings[keyof TimeRangeSettings],
    ) => {
      trackEvent('Toggle date range insights metric', {
        value,
      });

      const newTimeRangeSettings = {
        ...timeRangeSettings,
        [key]: value,
      };

      setTimeRangeSettings(newTimeRangeSettings);

      storage.setItem(
        FloatStorageKey.TimeRangeSettingsCache,
        JSON.stringify(newTimeRangeSettings),
      );
    },
    [timeRangeSettings, setTimeRangeSettings],
  );

  return {
    timeRangeSettings,
    setTimeRangeSetting,
  };
};
