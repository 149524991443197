import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';

import { selectDatesManager } from '../currentUser';
import { selectPersonById } from '../people/people';
import { getWorkDaysCountInRange } from './getWorkDaysCountInRange';
import { selectIsWorkDayGetter } from './selectIsWorkDayGetter';

type SelectPersonWorkDaysCountInRangeParams = {
  personId: number;
  startDate: string;
  endDate: string;
};

const selectParamPersonId = (
  state: ReduxStateStrict,
  params: SelectPersonWorkDaysCountInRangeParams,
) => params.personId;

const selectParamStartDate = (
  state: ReduxStateStrict,
  params: SelectPersonWorkDaysCountInRangeParams,
) => params.startDate;

const selectParamEndDate = (
  state: ReduxStateStrict,
  params: SelectPersonWorkDaysCountInRangeParams,
) => params.endDate;

const selectPerson = (
  state: ReduxStateStrict,
  params: SelectPersonWorkDaysCountInRangeParams,
) => selectPersonById(state, selectParamPersonId(state, params));

/**
 * This is a memoized selector for the schedule, designed to prevent unnecessary recomputations. The
 * memoization is performed per person, so it only accepts a single person ID as a parameter.
 */
export const selectPersonWorkDaysCountInRange = createSelector(
  [
    selectDatesManager,
    selectIsWorkDayGetter,
    selectPerson,
    selectParamStartDate,
    selectParamEndDate,
  ],
  (dates, getIsWorkDay, person, startDate, endDate) => {
    return getWorkDaysCountInRange({
      dates,
      start_date: startDate,
      end_date: endDate,
      people: [person],
      getIsWorkDay,
    });
  },
);
