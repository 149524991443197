import React from 'react';
import { get, useFormState } from 'react-hook-form';
import cn from 'classnames';

import { TooltipVariants } from '@float/ui/components/Tooltip/Tooltip.css';
import { Input } from '@float/ui/deprecated/Input';
import { QuickInputTooltip } from '@float/ui/primitives/QuickInput';

import * as styles from './styles.css';

export type BaseSidePanelInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size' | 'value'
> & {
  name: string;
  size?: 'xlarge' | 'medium';
  transparent?: string;
  prefix?: string;
  suffix?: string;
  compact?: boolean;
  variant?: 'spacious' | 'base' | 'compact';
  value: string;
};

export const BaseSidePanelInput = React.forwardRef<
  HTMLInputElement,
  BaseSidePanelInputProps
>((props, ref) => {
  const {
    autoFocus,
    className,
    name,
    size = 'medium',
    placeholder,
    id,
    transparent,
    disabled,
    variant,
    ...rest
  } = props;

  const { errors } = useFormState({
    name,
  });

  const hasErrors = Boolean(get(errors, name)) && !disabled;

  const noBorder = !hasErrors && transparent;
  const compact = variant === 'compact';

  return (
    <QuickInputTooltip
      open={hasErrors}
      name={name}
      variant={TooltipVariants.error}
      placement="right"
    >
      <div className={styles.inputInnerContainer}>
        <Input
          {...rest}
          disabled={disabled}
          inputId={id}
          inputRef={ref}
          className={cn(
            className,
            styles.input({
              status: hasErrors ? 'error' : 'default',
              size,
              background: transparent ? 'transparent' : 'default',
            }),
          )}
          placeholder={placeholder}
          autoFocus={autoFocus}
          noBorder={noBorder}
          nobackground={transparent}
          size={size}
          minHeight={compact ? 32 : undefined}
          hideArrows
        />
      </div>
    </QuickInputTooltip>
  );
});
