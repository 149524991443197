import React, { forwardRef, useCallback, useMemo } from 'react';
import { LayoutProps, motion } from 'framer-motion';

import { TimeString } from '@float/types/datesManager';
import { RepeatState } from '@float/types/repeatState';

import {
  InputAllocationDateRange,
  InputAllocationDateRangeProps,
} from '../../components/InputAllocationDateRange/InputAllocationDateRange';
import { InputAllocationDateRangeHeader } from '../../components/InputAllocationDateRangeHeader/InputAllocationDateRangeHeader';
import { InputAllocationRepeat } from '../../components/InputAllocationRepeat';
import { AllocationTimeSectionPayload } from '../../EditTaskModal.types';
import { useAllocationDaysHelpers } from '../../hooks/useAllocationDaysHelpers';
import { useAllocationRepeatControls } from '../../hooks/useAllocationRepeatControls';

import * as styles from '../../EditTaskModal.css';

export type StatusTimeSectionProps = {
  endDate: Date;
  hasRecurringExternalCalendar: boolean;
  isIntegrationSyncLocked: boolean;
  isIntervalRepeatable: boolean;
  isReadOnly: boolean;
  layout: LayoutProps['layout'];
  onChange: (data: AllocationTimeSectionPayload) => void;
  peopleIds: number[];
  repeatState: RepeatState;
  repeatEndDate: Date | null;
  startDate: Date;
  startTime: TimeString | null;
};

export const StatusTimeSection = forwardRef<
  HTMLDivElement,
  StatusTimeSectionProps
>((props, ref) => {
  const {
    endDate,
    hasRecurringExternalCalendar,
    isIntegrationSyncLocked,
    isIntervalRepeatable,
    isReadOnly,
    layout,
    onChange,
    peopleIds,
    repeatEndDate,
    repeatState,
    startDate,
  } = props;

  const { getIsWorkDay, getNumberOfAllocationDays } = useAllocationDaysHelpers({
    peopleIds,
  });

  const numberOfAllocationDays = useMemo(() => {
    return getNumberOfAllocationDays(startDate, endDate);
  }, [startDate, endDate, getNumberOfAllocationDays]);

  const getIsDateDisabled = useCallback(
    (date: Moment) => !getIsWorkDay(date.toDate()),
    [getIsWorkDay],
  );

  const handleDateRangeChange: InputAllocationDateRangeProps['onChange'] = (
    data,
  ) => {
    const { startDate, endDate } = data;

    onChange({
      startDate,
      endDate,
    });
  };

  const { repeatTimes, handleRepeatEndDateChange, handleRepeatIntervalChange } =
    useAllocationRepeatControls({
      startDate,
      repeatState,
      repeatEndDate,
      onChange,
    });

  return (
    <motion.div
      layout={layout}
      className={
        isReadOnly
          ? styles.timeSectionReadOnlyWrapper
          : styles.timeSectionWrapper
      }
      ref={ref}
    >
      <div className={styles.dateRangeColumn}>
        <InputAllocationDateRangeHeader
          numberOfAllocationDays={numberOfAllocationDays}
          startDate={startDate}
          endDate={endDate}
          hasIntervalSelect={false}
          onChange={handleDateRangeChange}
        />

        <InputAllocationDateRange
          startDate={startDate}
          endDate={endDate}
          isReadOnly={isReadOnly}
          getIsDateDisabled={getIsDateDisabled}
          onChange={handleDateRangeChange}
        />

        {isIntervalRepeatable && (
          <InputAllocationRepeat
            getIsDateDisabled={getIsDateDisabled}
            hasRecurringExternalCalendar={hasRecurringExternalCalendar}
            isIntegrationSyncLocked={isIntegrationSyncLocked}
            isReadOnly={isReadOnly}
            minRepeatDate={endDate}
            onChangeRepeatEndDate={handleRepeatEndDateChange}
            onChangeRepeatInterval={handleRepeatIntervalChange}
            repeatEndDate={repeatEndDate}
            repeatState={repeatState}
            repeatTimes={repeatTimes}
            startDate={startDate}
          />
        )}
      </div>
    </motion.div>
  );
});
