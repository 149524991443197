import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { cloneDeep, isEqual, isUndefined, pick } from 'lodash';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';

import * as milestoneActions from '@float/common/actions/milestones';
import * as peopleActions from '@float/common/actions/people';
import * as phaseActions from '@float/common/actions/phases';
import * as projectActions from '@float/common/actions/projects';
import API3 from '@float/common/api3';
import { Rights } from '@float/common/lib/acl';
import { handleFail } from '@float/common/lib/errors';
import { isTaskMetaUserCreated } from '@float/common/lib/scheduleUtils';
import { randomColor } from '@float/common/lib/utils';
import { provideStoreHoc } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { bindVal } from '@float/ui/deprecated/helpers/forms';
import { Input } from '@float/ui/deprecated/Input';
import { Modal } from '@float/ui/deprecated/Modal';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { withSnackbar } from '@float/ui/deprecated/Snackbar';
import { Tab } from '@float/ui/deprecated/Tab/Tab';
import withConfirmDelete from '@float/web/components/decorators/withConfirmDelete';
import withListeners from '@float/web/components/decorators/withListeners';
import modalManagerHoc from '@float/web/modalManager/modalManagerHoc';
import {
  getClientOptions,
  getCurrencyProps,
  getPeopleMap,
  getPhasesMapRaw,
  getProjectsMap,
  getProjectsSortedByCreation,
  getProjectTagByLabel,
  getUser,
} from '@float/web/selectors';
import { addClient } from '@float/web/settingsV2/actions/clients';

import PhaseModalActions, { savePhase } from './PhaseModalActions';
import ProjectDetails from './ProjectDetails';
import { getVisibleTasks } from './ProjectForm/helpers/getVisibleTasks';
import InfoFragment, { budgetOptions } from './ProjectForm/InfoFragment';
import MilestonesFragment from './ProjectForm/MilestonesFragment';
import TasksFragment from './ProjectForm/TasksFragment';
import TeamFragment from './ProjectForm/TeamFragment';
import { getFilteredTeam } from './ProjectModal.helpers';

const NameInputContainer = styled.div`
  flex-basis: 100%;

  input {
    width: 100%;
  }
`;

function getPhaseForm({ project, phase, milestones, taskNames }) {
  const form = cloneDeep(phase);

  form.newMilestones = cloneDeep(milestones);
  form.newTaskNames = cloneDeep(taskNames);
  form.budget_type = project.budget_type;
  form.budget_priority = project.budget_priority;
  form.locked_task_list = project.locked_task_list;

  const isHourlyFeeBudgetType = project.budget_type === 3;
  if (isHourlyFeeBudgetType) {
    // inherit from project in this scenario
    form.default_hourly_rate = project.default_hourly_rate;
  }

  return form;
}

function getNewPhaseFormForProject(project, phase) {
  const form = pick(cloneDeep(project), [
    'project_id',
    'tentative',
    'non_billable',
    'people_ids',
    'people_rates',
    'budget_type',
    'default_hourly_rate',
    'budget_priority',
    'locked_task_list',
    'notes',
    'notes_meta',
  ]);

  form.phase_name = '';
  form.color = randomColor();
  form.start_date = moment().format('YYYY-MM-DD');
  form.end_date = moment().format('YYYY-MM-DD');
  form.newMilestones = [];
  form.newTaskNames = [];

  if (phase) {
    if (phase.phase_id) {
      form.phase_name = phase.phase_name;
    }

    if (phase.start_date) {
      form.start_date = phase.start_date;
      form.end_date = phase.end_date;
    }
  }

  return form;
}

class PhaseModal extends React.Component {
  constructor(props) {
    super(props);

    const { projectId, phase } = props.modalSettings;

    this.isPhase = true;
    this.project = props.projects[projectId] || {};

    this.state = {
      isLoading: true,
      hiding: false,
      activeTab: props.modalSettings.defaultTab || 'info',
      editing: this.isEditing(),
      promptModal: null,
      isUpdating: false,
      milestones: [],
      taskNames: [],
      selectedTasks: {},
      headerCounts: {},
      form: {
        phase_name: '',
        newMilestones: [],
        newTaskNames: [],
        start_date: phase?.start_date || moment().format('YYYY-MM-DD'),
        end_date: phase?.end_date || moment().format('YYYY-MM-DD'),
      },
      formErrors: {},
      milestoneAdd: {
        name: '',
        startDate: moment(),
        endDate: moment(),
      },
      taskAdd: '',
    };

    this.milestoneNameRef = React.createRef();
    this.taskAddNameRef = React.createRef();
    this.phaseNameRef = React.createRef();
  }

  componentDidMount() {
    const { projectId, phase, editing } = this.props.modalSettings;

    const mountPromises = [this.props.actions.ensureProjectLoaded(projectId)];
    if (phase.phase_id) {
      mountPromises.push(this.props.actions.ensurePhaseLoaded(phase.phase_id));
      mountPromises.push(this.fetchMilestones());
      mountPromises.push(this.fetchTaskMeta());
      mountPromises.push(this.fetchPhaseRangeLimit());
    }

    Promise.all(mountPromises)
      .then((res) => {
        const [project, fullPhase, milestones, taskNames] = res;
        // Save the project that the phase is built off onto the modal instance
        // for ease of access later on.
        this.project = project;

        this.setState((ps) => {
          const newState = { isLoading: false };

          if (phase.phase_id) {
            newState.editing = editing && (ps.editing || this.project.canEdit);
            newState.milestones = milestones;
            newState.taskNames = taskNames;
            newState.form = getPhaseForm({
              project: this.project,
              phase: fullPhase,
              milestones,
              taskNames,
            });

            const visibleTasks = getVisibleTasks(
              taskNames,
              this.props.currentUser,
            );
            newState.headerCounts = {
              ...ps.headerCounts,
              milestones: milestones.length,
              tasks: visibleTasks.length,
            };
          } else {
            newState.form = getNewPhaseFormForProject(this.project, phase);
          }

          // For dirty state tracking
          this.initialPhase = cloneDeep(newState.form);

          return newState;
        });
      })
      .catch((e) => {
        handleFail(
          null,
          `Sorry, we were unable to complete that last action.
        Please reload this page and try again.`,
        );
        this.hide();
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.form.newTaskNames !== this.state.form.newTaskNames ||
      prevState.form.newMilestones !== this.state.form.newMilestones ||
      prevState.form.people_ids !== this.state.form.people_ids
    ) {
      const numMilestones = this.state.form.newMilestones.length;
      const numTasks = getVisibleTasks(
        this.state.form.newTaskNames,
        this.props.currentUser,
      ).length;
      const numTeam = getFilteredTeam(
        this.state.form,
        this.props.currentUser,
        this.props.people,
      ).length;

      this.setHeaderCounts({
        tasks: numTasks,
        team: numTeam,
        milestones: numMilestones,
      });
    }
  }

  // ---------------------------------------------------------------------------
  // !!! Helpers ---------------------------------------------------------------
  // ---------------------------------------------------------------------------

  fetchMilestones = () => {
    const { phase } = this.props.modalSettings;
    if (phase.phase_id) {
      // TODO: confirm server side filtering by phase_id works, and remove filter below
      return API3.getMilestones({
        query: { phase_id: phase.phase_id, 'per-page': 8000 },
      }).then((res) => res[0].filter((r) => r.phase_id === phase.phase_id));
    }
  };

  fetchTaskMeta = () => {
    const {
      phase: { phase_id },
    } = this.props.modalSettings;
    if (phase_id) {
      return API3.getTaskMeta({ phase_id }).then((res) =>
        res.filter((r) => isTaskMetaUserCreated(r)),
      );
    }
  };

  fetchPhaseRangeLimit = () => {
    const {
      phase: { phase_id },
    } = this.props.modalSettings;
    if (phase_id) {
      API3.getPhaseRangeLimit(phase_id).then((res) =>
        this.setState({ rangeLimit: res }),
      );
    }
  };

  startDateRangeLimit = (date) => {
    return (
      this.state.rangeLimit &&
      date.format('YYYY-MM-DD') > this.state.rangeLimit.maxStart
    );
  };

  endDateRangeLimit = (date) => {
    return (
      this.state.rangeLimit &&
      date.format('YYYY-MM-DD') < this.state.rangeLimit.minEnd
    );
  };

  getProjectName = () => {
    if (!this.project.client_name || this.project.client_name === 'No Client') {
      return this.project.project_name;
    }

    return `${this.project.client_name} / ${this.project.project_name}`;
  };

  isTaskNameInUse = (task) => {
    const { count_tasks = 0, count_logged_time = 0 } = task;
    return +count_tasks > 0 || +count_logged_time > 0;
  };

  noChangesMade = () => {
    const isReadOnly = !this.state.editing;
    return isReadOnly || isEqual(this.initialPhase, this.state.form);
  };

  isAdding = () => {
    return this.props.modalSettings.isAdding;
  };

  isEditing = () => {
    const { projects, currentUser } = this.props;
    const { editing, phase, projectId } = this.props.modalSettings;

    if (!isUndefined(editing)) {
      if (!editing) {
        return false;
      }
    }

    // If PM is added to Project team but not Phase team, they should
    // still be allowed to schedule tasks for Phases in tht Project.

    const project = projects[phase.project_id || projectId];
    const canEdit = Rights.canUpdatePhase(currentUser, {
      project,
      phase,
    });

    return this.isAdding() || canEdit;
  };

  isDuplicating = () => false;

  isTemplate = () => false;

  hasPhase = () => true;

  hasErrors = (fields) => {
    const { formErrors } = this.state;
    return fields.some((field) => (formErrors[field] || {}).length);
  };

  setHeaderCounts = (headerCounts) => {
    this.setState({ headerCounts });
  };

  setFormErrors = (formErrors, cb) => {
    this.setState(
      { formErrors: { ...this.state.formErrors, ...formErrors } },
      cb,
    );
  };

  resetBudgetFormErrors = (ps = this.state) => ({
    ...ps.formErrors,
    budget_total: [],
    people_rates: [],
    default_hourly_rate: [],
  });

  hide = (evt) => {
    preventDefaultAndStopPropagation(evt);
    this.setState({ isUpdating: false, promptModal: null });
    this.props.manageModal({
      visible: false,
      modalType: 'phaseModal',
    });
  };

  hideAfterCheckingForChanges = () => {
    if (this.noChangesMade()) {
      this.hide();
      return;
    }
    this.props.confirm({
      title: 'You have unsaved changes.',
      message: 'Are you sure you want to leave?',
      confirmLabel: 'Leave',
      cancelLabel: 'Cancel',
      onConfirm: this.hide,
    });
  };

  onEscapePressed = () => {
    if (!this.state.editing) {
      this.hide();
    }
  };

  setPromptModal = (promptModal) => {
    this.setState({ promptModal });
  };

  closePromptModal = () => {
    this.setState({ promptModal: null });
  };

  showMessage = (message) => {
    this.props.showSnackbar(message);
  };

  render() {
    const { editing, activeTab } = this.state;

    let fragment;
    if (editing) {
      if (activeTab === 'info') fragment = InfoFragment.call(this);
      if (activeTab === 'team') fragment = TeamFragment.call(this);
      if (activeTab === 'milestones') fragment = MilestonesFragment.call(this);
      if (activeTab === 'tasks') fragment = TasksFragment.call(this);
    } else {
      const readOnlyBudgetLabel =
        this.project.budget_type &&
        budgetOptions.find(
          (x) =>
            x.budget_type === this.project.budget_type &&
            !x.budget_priority === !this.project.budget_priority,
        )?.label;

      fragment = (
        <ProjectDetails
          activeTab={activeTab}
          currentUser={this.props.currentUser}
          project={this.props.phases[this.props.modalSettings.phase.phase_id]}
          projectTagByLabel={this.props.projectTagByLabel}
          people={this.props.people}
          clients={this.props.clients}
          accounts={this.props.accounts}
          milestones={this.state.milestones}
          taskNames={this.state.taskNames}
          managerAccountId={this.project.project_manager}
          isPhase
          budgetLabel={readOnlyBudgetLabel}
        />
      );
    }

    return createPortal(
      <Modal
        isOpen
        onClose={this.hide}
        onBgClick={this.hideAfterCheckingForChanges}
      >
        <form onSubmit={savePhase.bind(this)}>
          <Modal.Header className="pb-0">
            <NameInputContainer>
              <Input
                {...bindVal(this, 'phase_name')}
                placeholder="Phase name"
                ref={this.phaseNameRef}
                autoFocus
                noBorder
                size="xlarge"
                maxLength={125}
                readOnly={!editing}
              />
            </NameInputContainer>
            <Tab
              onClick={() => {
                this.setState({ activeTab: 'info' }, () => {
                  this.phaseNameRef.current.focusInput();
                });
              }}
              active={activeTab === 'info'}
              label="Info"
              hasError={this.hasErrors(['budget_total', 'default_hourly_rate'])}
            />
            <Tab
              counter={this.state.headerCounts.team}
              onClick={() => this.setState({ activeTab: 'team' })}
              active={activeTab === 'team'}
              label="Team"
              hasError={this.hasErrors(['people_rates'])}
            />
            <Tab
              counter={this.state.headerCounts.milestones}
              onClick={() => this.setState({ activeTab: 'milestones' })}
              active={activeTab === 'milestones'}
              label="Milestones"
            />
            {!this.props.modalSettings.hideTasksTab && (
              <Tab
                counter={this.state.headerCounts.tasks}
                onClick={() => this.setState({ activeTab: 'tasks' })}
                active={activeTab === 'tasks'}
                label={'Task list'}
              />
            )}
          </Modal.Header>
          <Modal.Body>{fragment}</Modal.Body>
          {editing && (
            <Modal.Actions style={{ paddingTop: 30 }}>
              {PhaseModalActions.call(this)}
            </Modal.Actions>
          )}
        </form>
        {this.state.promptModal &&
          createPortal(this.state.promptModal, document.body)}
      </Modal>,
      document.body,
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: getUser(state),
  accounts: state.accounts.accounts,
  clients: state.clients.clients,
  projectTagByLabel: getProjectTagByLabel(state),
  people: getPeopleMap(state),
  projects: getProjectsMap(state),
  phases: getPhasesMapRaw(state),
  projectsSortedByCreation: getProjectsSortedByCreation(state),
  clientOptions: getClientOptions(state),
  currencyConfig: getCurrencyProps(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(projectActions, dispatch),
    ...bindActionCreators(phaseActions, dispatch),
    ...bindActionCreators(peopleActions, dispatch),
    ...bindActionCreators(milestoneActions, dispatch),
    ...bindActionCreators({ addClient }, dispatch),
  },
});

const enhance = withListeners([
  {
    eventType: 'keyup',
    testFn: (e) => e.keyCode === 27,
    handler: 'onEscapePressed',
  },
]);

const Component = compose(
  withConfirm,
  withConfirmDelete,
  withSnackbar,
)(provideStoreHoc(PhaseModal));

export default modalManagerHoc({
  Comp: connect(mapStateToProps, mapDispatchToProps)(enhance(Component)),
  modalType: 'phaseModal',
});
