import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { updateUserTrackingData } from '@float/common/lib/intercom';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Label } from '@float/ui/deprecated/Label';
import Loader from '@float/ui/deprecated/Loader/Spinner';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';

import { checkIsConnected, disconnect, getOauthUrl } from './actions';

const DEFAULT_ERROR_MESSAGE = 'An error occurred.';

function trackSlackNotifyStatus(isConnected) {
  updateUserTrackingData('slackNotify', isConnected);
}

function getErrorMessageFromCode(code) {
  switch (code) {
    case 'access_denied':
      return 'Access denied.';
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
}

function NotificationSlackButton({ jwt }) {
  const [appUri, setAppUri] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [connecting, setConnecting] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [initError, setInitError] = useState(false);
  const popup = useRef(null);
  const { showSnackbar } = useSnackbar();

  const showMessage = useCallback(
    (message = DEFAULT_ERROR_MESSAGE) => {
      showSnackbar(message);
    },
    [showSnackbar],
  );

  const loadingDone = () => {
    setInitializing(false);
    setConnecting(false);
    setDisconnecting(false);
  };

  const checkOauthStatus = useCallback(() => {
    checkIsConnected()
      .then(({ app_uri }) => {
        setAppUri(app_uri || null);
        loadingDone();
      })
      .catch((err) => {
        console.log('err', err);
        setInitError(true);
        loadingDone();
      });
  }, []);

  const onConnectClick = (e) => {
    e.preventDefault();
    const url = `${getOauthUrl()}&state=${window.location.origin},${jwt}`;
    setConnecting(true);
    popup.current = window.open(url, '_blank');
  };

  const onDisconnectClick = (e) => {
    e.preventDefault();
    setDisconnecting(true);
    disconnect()
      .then((resp) => {
        if (resp) {
          setAppUri(null);
          showMessage('Slack app disconnected.');
          trackSlackNotifyStatus(false);
        }
        loadingDone();
      })
      .catch((err) => {
        console.log('err', err);
        loadingDone();
        showMessage();
      });
  };

  const receivePostMessage = useCallback(
    ({ data = {} } = {}) => {
      if (!data.floatSlackOauthMessageType) {
        return;
      }

      const { error, app_uri } = data;
      if (error) {
        setAppUri(null);
        showMessage(getErrorMessageFromCode(error));
        popup.current.close();
      } else {
        setAppUri(app_uri);
        showMessage('Slack app connected.');
        trackSlackNotifyStatus(true);
      }
      loadingDone();
    },
    [showMessage],
  );

  useEffect(() => {
    checkOauthStatus();
    window.addEventListener('message', receivePostMessage, false);
    return () => {
      window.removeEventListener('message', receivePostMessage, false);
    };
  }, [checkOauthStatus, receivePostMessage]);

  useEffect(() => {
    // This is in case postMessage fails. E.g. slack failure, browser issue etc.
    let timer;
    if (connecting) {
      timer = setInterval(() => {
        if (popup.current.closed) {
          clearInterval(timer);
          if (connecting) {
            checkOauthStatus();
          }
        }
      }, 500);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [connecting, checkOauthStatus]);

  if (initError) {
    return <Label color="red">{DEFAULT_ERROR_MESSAGE}</Label>;
  }

  if (initializing) {
    return <Loader width={140} height={36} />;
  }

  if (!appUri) {
    return (
      <Button
        loader={connecting}
        disabled={connecting}
        onClick={onConnectClick}
      >
        Connect Slack
      </Button>
    );
  }

  return (
    <Button
      loader={disconnecting}
      disabled={disconnecting}
      onClick={onDisconnectClick}
    >
      Disconnect Slack
    </Button>
  );
}

export default connect((state) => {
  return {
    jwt: state.jwt && state.jwt.accessToken,
  };
})(NotificationSlackButton);
