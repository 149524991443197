import React, { ChangeEvent, forwardRef } from 'react';
import { t } from '@lingui/macro';

import { ButtonAction } from '@float/ui/components/ButtonAction';
import { Input } from '@float/ui/deprecated/Input';
import { IconTrash } from '@float/ui/icons/essentials/IconTrash';

import { InputItemState } from '../AddForm/hooks/useInputList';

import * as styles from './styles.css';

const INPUT_MAX_LENGTH = 125;

export type InputItemProps = {
  disableRemove?: boolean;
  index: number;
  inputItemState: InputItemState;
  onChange: (index: number, value: string) => void;
  onClickRemove: (index: number) => void;
  onKeyDown?: (index: number, e: KeyboardEvent) => void;
};

export const InputItem = forwardRef<Input, InputItemProps>((props, ref) => {
  const {
    disableRemove,
    index,
    inputItemState,
    onChange,
    onClickRemove,
    onKeyDown,
  } = props;

  const { autoFocus, value, removable, disabled } = inputItemState;

  const onClickRemoveHandler = () => {
    onClickRemove(index);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = e;

    onChange(index, value);
  };

  const onInputKeyDown = (e: KeyboardEvent) => {
    if (onKeyDown) onKeyDown(index, e);
  };

  const label = t`First and last name`;

  return (
    <div className={styles.inputItem} data-testid="input-item">
      <span className={styles.inputItemIndex}>{index + 1}.</span>

      <Input
        ariaLabel={label}
        autoFocus={autoFocus}
        disabled={disabled}
        index={index}
        maxLength={INPUT_MAX_LENGTH}
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        placeholder={label}
        ref={ref}
        size="large"
        value={value.substring(0, INPUT_MAX_LENGTH)}
      />

      {removable && !disableRemove && (
        <div className={styles.actions}>
          <ButtonAction
            aria-label={t`Delete`}
            className={styles.buttonDelete}
            color="critical"
            icon={IconTrash}
            onClick={onClickRemoveHandler}
            size={20}
            tabIndex={-1}
          />
        </div>
      )}
    </div>
  );
});
