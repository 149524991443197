import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { withCurrentBreakpointCSSVariable } from '../helpers/responsive/withCurrentBreakpoint';
import { DateRangePickerGlobalStyle } from './DateRangePickerGlobalStyle';
import { LegacyStyle } from './LegacyStyle';
import { MarginHelpers } from './MarginGlobalStyle';
import { PrintStyle } from './PrintStyle';
import { TippyStyle } from './TippyStyle';

const GlobalStyle = createGlobalStyle`
  ${MarginHelpers}
  ${DateRangePickerGlobalStyle}
  ${TippyStyle}
  ${PrintStyle}
  ${LegacyStyle}

  :root {
    // On mobile browsers, 100vh is not equal to the visible screen height.
    // The viewport is obstructed by Safari/Chrome browser-specific control ( like sthe earch bar or the botton bar ).
    // To mitigate this issue, we'll update --screen-height to be the actual screen height.
   --screen-height: 100vh;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';

    ${withCurrentBreakpointCSSVariable};
  }

  html, body {
    background: ${({ theme }) => theme.surface1};
  }

  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
  }
`;

export function GlobalStyles() {
  return <GlobalStyle />;
}

export default GlobalStyles;
