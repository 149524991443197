import React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import { Button } from '@float/ui/deprecated/Button/Button';
import { Modal } from '@float/ui/deprecated/Modal';
import withListeners from 'components/decorators/withListeners';

// These are artifacts from an old calendar/configs/size file. They're
// only used here to position the "Schedule on a non-work day?" popup.
const size = {};
size.confirmWeekendModal = 120;
size.canvasTop = 80;
size.screenHeight = window.innerHeight + size.canvasTop;
size.headerHeight = 51.5;
size.top = 0;
size.cellWidth = 91;
size.left = 259.5 - size.cellWidth * 7;

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: !!this.props.checkbox };
    this.cancelButtonRef = React.createRef();
  }

  prevent = (evt) => {
    return evt.stopPropagation();
  };

  submit = () => {
    let args = null;
    const { checkbox, shouldCloseOnAnyAction, close, onSubmit } = this.props;
    if (checkbox) {
      args = { checked: this.state.checked };
    }
    if (shouldCloseOnAnyAction) {
      close();
    }
    if (typeof onSubmit === 'function') {
      return onSubmit(args);
    }
    return Promise.resolve();
  };

  onClick = (evt) => {
    if (this.prevented) {
      return;
    }
    this.prevented = true;
    const isKeyClick = evt.clientX === 0 && evt.clientY === 0;

    if (!isKeyClick) {
      this.submit(evt);
    }

    this.prevented = false;
  };

  cancel = (evt) => {
    if (evt) {
      evt.stopPropagation();
    }
    const { shouldCloseOnAnyAction, close, onCancel } = this.props;
    if (shouldCloseOnAnyAction) {
      close();
    }
    if (typeof onCancel === 'function') {
      return onCancel();
    }
    return Promise.resolve();
  };

  check = () => {
    return this.setState({ checked: !this.state.checked });
  };

  onEnterPressed = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (
      !this.props.ignoreSubmit &&
      document.activeElement === this.cancelButtonRef.current
    ) {
      return this.cancel(evt);
    }
    return this.submit(evt);
  };

  onEscapePressed = (evt) => {
    if (!this.props.ignoreCancel) {
      evt.preventDefault();
      evt.stopPropagation();
      this.cancel(evt);
    }
  };

  render() {
    const {
      title,
      message,
      ignoreSubmit,
      submitColor,
      submitLabel,
      submitClass,
      cancelColor,
      cancelClass,
      cancelLabel,
      ignoreCancel,
      textSize,
      checkbox,
      cell,
      newButtons,
      newModal,
    } = this.props;
    const style = { display: 'block' };
    let attachedToTop = null;
    // Position the modal above where the user clicked,
    // passed in through
    if (cell && cell.coords) {
      style.top = cell.coords.y - size.confirmWeekendModal - 10;
      style.left = cell.coords.x - 150;
      style.width = 300;
      attachedToTop = true;
    }
    if (cell != null && !cell.coords) {
      let topDistance =
        cell.people.people.top +
        size.canvasTop +
        size.headerHeight +
        size.top +
        (cell.people.people.height - 10);
      if (size.confirmWeekendModal + topDistance > size.screenHeight - 90) {
        attachedToTop = true;
        topDistance =
          topDistance -
          cell.people.people.height -
          size.confirmWeekendModal +
          20;
      }

      style.top = topDistance;
      style.left =
        cell.column * size.cellWidth - -size.left + size.cellWidth / 2;
      style.maxWidth = 225;
      style.marginLeft = -(style.maxWidth / 2);
    }
    const messageIsElement =
      typeof message === 'object' &&
      (<div />).constructor === message.constructor;

    if (newModal) {
      return (
        <Modal isOpen onClose={this.cancel}>
          {title && (
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
          )}
          <Modal.Body>
            {messageIsElement ? message : <p>{message}</p>}
          </Modal.Body>
          <Modal.Actions>
            {!ignoreSubmit && (
              <Button tabIndex="1" onClick={this.onClick}>
                {submitLabel}
              </Button>
            )}
            {!ignoreCancel && (
              <Button appearance="secondary" onClick={this.cancel}>
                {cancelLabel}
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      );
    }

    return createPortal(
      <div
        className={`popup-outer popup-top text-${textSize} popup-confirm
          ${cx({ small: !!cell })}
          ${cx({ bottomArrow: attachedToTop, topArrow: !attachedToTop })}`}
        onClick={this.cancel}
      >
        <div style={style} className="popup dialog in" onClick={this.prevent}>
          {title ? <header>{title}</header> : undefined}
          {messageIsElement ? message : <p>{message}</p>}
          <footer>
            {!ignoreSubmit ? (
              newButtons ? (
                <Button tabIndex="1" onClick={this.onClick}>
                  {submitLabel}
                </Button>
              ) : (
                <button
                  tabIndex="1"
                  className={`inputButton ${submitColor} ${submitClass}`}
                  onClick={this.onClick}
                >
                  {submitLabel}
                </button>
              )
            ) : undefined}
            {newButtons && <div style={{ width: '10px' }} />}
            {!ignoreCancel ? (
              newButtons ? (
                <Button appearance="secondary" onClick={this.cancel}>
                  {' '}
                  {cancelLabel}
                </Button>
              ) : (
                <button
                  tabIndex="2"
                  className={`inputButton ${cancelColor} ${cancelClass}`}
                  onClick={this.cancel}
                  ref={this.cancelButtonRef}
                >
                  {cancelLabel}
                </button>
              )
            ) : undefined}
            {checkbox != null ? (
              <div className="modal-checkbox">
                <input
                  tabIndex="3"
                  id="modal-checkbox"
                  type="checkbox"
                  checked={this.state.checked}
                  onChange={this.check}
                >
                  <label htmlFor="modal-checkbox">{checkbox}</label>
                </input>
              </div>
            ) : undefined}
          </footer>
        </div>
      </div>,
      document.body,
    );
  }
}

ConfirmModal.defaultProps = {
  title: '',
  message: '',
  textSize: 'medium',
  submitLabel: 'Submit',
  submitColor: 'green',
  submitClass: '',
  cancelLabel: 'Cancel',
  cancelColor: 'red',
  cancelClass: '',
  shouldCloseOnAnyAction: true,
  close: () => {},
  onSubmit: () => {},
  onCancel: () => {},
};

const enhance = withListeners([
  {
    eventType: 'keyup',
    testFn: (e) => e.keyCode === 13,
    handler: 'onEnterPressed',
  },
  {
    eventType: 'keyup',
    testFn: (e) => e.keyCode === 27,
    handler: 'onEscapePressed',
  },
]);

export default enhance(ConfirmModal);
