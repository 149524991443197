import { omit } from 'lodash';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import {
  BudgetPriority,
  BudgetType,
  Project,
  ProjectStatus,
  RawProject,
} from '@float/types';

import { projects as ProjectsAPI } from '../../api3/projects';
import { sanitizeFetchedProject } from '../projects.helpers';
import { PROJECTS_UPDATED } from './actionTypes';
import { ProjectApiPayload, ProjectInputData } from './types';

const mapChangesToProjectPayload = (data: Partial<ProjectInputData>) => {
  const payload: Partial<ProjectApiPayload> = {};

  for (const key of Object.keys(data) as Array<keyof ProjectInputData>) {
    switch (key) {
      case 'project_name':
        payload.name = data[key];
        break;
      case 'project_code':
        payload.project_code = data[key];
        break;
      case 'description':
        payload.notes = data[key];
        break;
      case 'common':
        payload.all_pms_schedule = data[key] ? 1 : 0;
        break;
      case 'active':
      case 'locked_task_list':
      case 'non_billable':
        payload[key] = data[key] ? 1 : 0;
        break;
      case 'tags':
      case 'notes_meta':
      case 'client_id':
      case 'project_manager':
      case 'color':
      case 'budget_total':
      case 'default_hourly_rate':
      case 'end_date':
      case 'start_date':
        // @ts-expect-error Typescript doesn't like this kind of dynamic logic
        // but this way it is easier to understand this function logic.
        payload[key] = data[key];
        break;
      case 'status':
        payload[key] = data[key];
        payload['tentative'] = data[key] === ProjectStatus.Tentative ? 1 : 0;
        break;
      case 'budget_priority':
        // budget total needs to be null if budget_priority is not project
        if (data[key] !== BudgetPriority.Project) {
          data['budget_total'] = null;
        }

        payload[key] = data[key];

        break;
      case 'budget_type':
        // Send null value for default hourly rate since they are saved in the form context
        // even in the below cases where they are not needed
        if (
          data[key] === BudgetType.Disabled ||
          data[key] === BudgetType.TotalHours
        ) {
          payload['default_hourly_rate'] = null;
          data['default_hourly_rate'] = null;
        }
        payload[key] = data[key];
        break;
      default:
        break;
    }
  }

  return payload;
};

export function updateProjectFromSidePanel(
  id: Project['project_id'],
  input: Partial<ProjectInputData>,
  team?: ProjectApiPayload['project_team'],
) {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    const data = mapChangesToProjectPayload(input);

    if (team) {
      if (!team.add) {
        data.project_team = team;
      } else {
        const mappedAdd = team.add.map((member) =>
          omit(member, 'isAssignedToPhaseOnly'),
        );
        data.project_team = {
          ...team,
          add: mappedAdd,
        };
      }
    }

    const response = await ProjectsAPI.updateProject({
      id,
      data,
      query: {
        expand: 'project_team,project_dates',
      },
    });

    const project = sanitizeFetchedProject(response) as RawProject;
    const prevProject = getState().projects.projects[id];

    dispatch({
      type: PROJECTS_UPDATED,
      project,
      prevProject,
    });

    return project;
  };
}
