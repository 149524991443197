import { flatMap, forEach, transform } from 'lodash';
import styled from 'styled-components';

import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import { Tag } from '@float/ui/deprecated/Earhart/Tags';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';

import {
  filterLinkFormatter,
  pctFormatter,
  rangeBarFormatter,
} from '../../../helpers/tableFormatters';
import { DualRangeBarFormatter } from '../DualRangBarFormatter';
import {
  getCombinedRangeBar,
  getLoggedRangeBar,
  getScheduledRangeBar,
} from '../table.helpers';
import { getPctHeader, getPctValue } from './percentages';

const buildEmptyPersonChild = (personId, capacity) => ({
  person_id: personId,
  capacity: capacity,
  scheduled: 0,
  billable: 0,
  nonbillable: 0,
  logged: {
    scheduled: 0,
    billable: 0,
    nonbillable: 0,
  },
  combined: {
    scheduled: 0,
    billable: 0,
    nonbillable: 0,
  },
  timeoff: 0,
});

const buildEmptyDepartment = (department) => {
  return {
    department_id: department?.id,
    capacity: 0,
    scheduled: 0,
    billable: 0,
    nonbillable: 0,
    tentative: {
      billable: 0,
      nonbillable: 0,
    },
    logged: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      overtime: 0,
    },
    combined: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      overtime: 0,
    },
    future: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      overtime: 0,
      tentative: {
        billable: 0,
        nonbillable: 0,
      },
    },
    timeoff: 0,
    overtime: 0,

    children: {
      people: {},
      departments: {},
    },
  };
};

function breakdown(ctx, raw) {
  const { people, departments, parentDepartments } = ctx;

  const byDept = transform(raw.capacity, (acc, capacity, personId) => {
    const person = people[personId];
    const department = person && departments[person.department_id];
    const parents = (department && parentDepartments[department.id]) || [];

    // sometimes person is undefined (maybe happens when a person has just delated & the reports returns a stale capacity)
    // https://rollbar.com/float/fe-web-app/items/2551/
    if (!person) return;

    const departmentId = department?.id;
    if (!acc[departmentId]) {
      acc[departmentId] = buildEmptyDepartment(department);
    }

    parents.forEach((parentDepId) => {
      const parent = departments[parentDepId];
      if (!acc[parentDepId]) {
        acc[parentDepId] = buildEmptyDepartment(parent);
      }

      acc[parentDepId].children.departments[departmentId] = acc[departmentId];

      // Add their capacity to the parent department's total capacity
      acc[parentDepId].capacity += capacity;
    });

    // Initialize this person as a row in their department

    acc[departmentId].children.people[personId] = buildEmptyPersonChild(
      personId,
      capacity,
    );

    // Add their capacity to the department's total capacity
    acc[departmentId].capacity += capacity;
  });

  const getAllPersonDepartmentIds = (personId) => {
    const person = people[personId];
    const department = person && departments[person.department_id];
    const parents = (department && parentDepartments[department.id]) || [];

    return [department?.id].concat(parents);
  };

  forEach(raw.overtime, (ot, personId) => {
    const personDepartments = getAllPersonDepartmentIds(personId);
    personDepartments.forEach((depId) => {
      if (!byDept[depId]) return;
      byDept[depId].overtime += ot.total;
      byDept[depId].logged.overtime += ot.logged;
      byDept[depId].combined.overtime += ot.logged + ot.future;
    });
  });

  raw.totals.forEach((item) => {
    const personDepartments = getAllPersonDepartmentIds(item.person_id);
    personDepartments.forEach((depId, i) => {
      const record = byDept[depId];
      if (!record) return;

      // We want to aggregate the children by person
      const key = item.person_id;
      if (!key) {
        console.error(item);
        throw Error('Expected item.person_id');
      }

      const isPersonDepartment = i == 0;
      if (isPersonDepartment && !record.children.people[key]) {
        record.children.people[key] = buildEmptyPersonChild(
          item.person_id,
          raw.capacity[item.person_id],
        );
      }

      const child = record.children.people[key];

      // Record refers to the parent row (people).
      // Child refers to the child row (project/timeoff).
      // The "root" suffix is used to get either the task/logged time data
      const isTimeoff = item.type === 'timeoff';
      if (isTimeoff) {
        if (child) {
          child.timeoff += item.hours.scheduled;
        }
        record.timeoff += item.hours.scheduled;
        return;
      }

      const isTask = item.type === 'task';
      const isLoggedTime = item.type === 'logged_time';
      if (isTask || isLoggedTime) {
        if (isLoggedTime && item.date >= ctx.loggedTimeBoundary) {
          return;
        }

        const childRoot = isTask ? child : child?.logged;
        if (child) {
          childRoot.scheduled += item.hours.scheduled;
          child.combined.scheduled += isTask
            ? item.hours.future
            : item.hours.scheduled;
        }

        const recordRoot = isTask ? record : record.logged;
        recordRoot.scheduled += item.hours.scheduled;
        record.combined.scheduled += isTask
          ? item.hours.future
          : item.hours.scheduled;
        record.future.scheduled += item.hours.future;

        if (item.billable) {
          if (child) {
            childRoot.billable += item.hours.scheduled;
            child.combined.billable += isTask
              ? item.hours.future
              : item.hours.scheduled;
          }

          recordRoot.billable += item.hours.scheduled;
          record.combined.billable += isTask
            ? item.hours.future
            : item.hours.scheduled;
          record.future.billable += item.hours.future;

          if (item.tentative) {
            record.tentative.billable += item.hours.scheduled;
            record.future.tentative.billable += isTask
              ? item.hours.future
              : item.hours.scheduled;
          }
        } else {
          if (child) {
            childRoot.nonbillable += item.hours.scheduled;
            child.combined.nonbillable += isTask
              ? item.hours.future
              : item.hours.scheduled;
          }

          recordRoot.nonbillable += item.hours.scheduled;
          record.combined.nonbillable += isTask
            ? item.hours.future
            : item.hours.scheduled;
          record.future.nonbillable += item.hours.future;

          if (item.tentative) {
            record.tentative.nonbillable += item.hours.scheduled;
            record.future.tentative.nonbillable += isTask
              ? item.hours.future
              : item.hours.scheduled;
          }
        }
      }
    });
  });

  return byDept;
}

const StyledIconArrowRight = styled(IconArrowDownRight)`
  flex-shrink: 0;
`;

export const buildDepartmentNameCell = (department, childDepartmentsCount) => {
  if (!department) {
    return {
      type: 'department',
      name: 'None',
      val: 'No Department',
    };
  }

  return {
    type: 'department',
    name: department.name,
    sortVal: department.name.toLowerCase(),
    val: (
      <>
        {!!department?.parent_id && (
          <>
            <StyledIconArrowRight size={16} />
            <Spacer size={8} />
          </>
        )}
        {department.name}
        <Spacer size={4} />
        {childDepartmentsCount > 0 && (
          <Tag
            size="xsmall"
            icon={IconArrowDownRight}
            style={{
              borderRadius: 4,
            }}
          >
            {childDepartmentsCount}
          </Tag>
        )}
      </>
    ),
  };
};

export function getScheduledTable(ctx, raw) {
  const { people, departments } = ctx;

  const headers = [
    {
      label: 'Department',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Scheduled', width: 100 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 100 },
    { label: 'Overtime', width: 100 },
    {
      label: getPctHeader(ctx, 'scheduled'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byDept = breakdown(ctx, raw);

  const buildDepartmentRow = (o, depId) => {
    const department = departments[depId];
    const peopleRows = Object.values(o.children.people);
    const departmentsRows = Object.values(o.children.departments);

    return {
      id: depId,
      data: [
        buildDepartmentNameCell(department, departmentsRows.length),
        o.capacity,
        o.scheduled,
        o.billable,
        o.nonbillable,
        o.timeoff,
        o.overtime,
        getPctValue(ctx, o, 'scheduled'),
        getScheduledRangeBar(o),
      ],
      children: [
        ...departmentsRows.map((c = {}) => {
          return buildDepartmentRow(c, c.department_id);
        }),
        ...peopleRows.map((c) => {
          const person = people[c.person_id];
          return {
            id: c.person_id,
            data: [
              person.name,
              c.capacity,
              c.scheduled,
              c.billable,
              c.nonbillable,
              c.timeoff,
              '-',
              getPctValue(ctx, c, 'scheduled'),
              '',
            ],
          };
        }),
      ],
    };
  };

  const rows = flatMap(byDept, (o, deptId) => {
    const department = departments[deptId];
    if (department?.parent_id) {
      return [];
    }

    return [buildDepartmentRow(o, deptId)];
  });

  return { headers, rows };
}

export function getLoggedTable(ctx, raw) {
  const { people, departments } = ctx;

  const headers = [
    {
      label: 'Department',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Logged', width: 100 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 100 },
    { label: 'Overtime', width: 100 },
    {
      label: getPctHeader(ctx, 'logged'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byDept = breakdown(ctx, raw);

  const buildDepartmentRow = (o, depId) => {
    const department = departments[depId];
    const peopleRows = Object.values(o.children.people);
    const departmentsRows = Object.values(o.children.departments);

    return {
      id: depId,
      data: [
        buildDepartmentNameCell(department, departmentsRows.length),
        o.capacity,
        o.logged.scheduled,
        o.logged.billable,
        o.logged.nonbillable,
        o.timeoff,
        o.logged.overtime,
        getPctValue(ctx, o, 'logged'),
        getLoggedRangeBar(o),
      ],
      children: [
        ...departmentsRows.map((c = {}) => {
          return buildDepartmentRow(c, c.department_id);
        }),
        ...peopleRows.map((c) => {
          const person = people[c.person_id];
          return {
            id: c.person_id,
            data: [
              person.name,
              c.capacity,
              c.logged.scheduled,
              c.logged.billable,
              c.logged.nonbillable,
              c.timeoff,
              '-',
              getPctValue(ctx, c, 'logged'),
              '',
            ],
          };
        }),
      ],
    };
  };

  const rows = flatMap(byDept, (o, deptId) => {
    const department = departments[deptId];
    if (department?.parent_id) {
      return [];
    }

    return [buildDepartmentRow(o, deptId)];
  });

  return { headers, rows };
}

export function getCompareTable(ctx, raw) {
  const { people, departments } = ctx;

  const headers = [
    {
      label: 'Department',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Scheduled', width: 200 },
    { label: 'Logged', width: 200 },
    { label: 'Difference', width: 200 },
    {
      label: '',
      width: 135,
      formatter: DualRangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byDept = breakdown(ctx, raw);

  const buildDepartmentRow = (o, depId) => {
    const department = departments[depId];
    const peopleRows = Object.values(o.children.people);
    const departmentsRows = Object.values(o.children.departments);

    return {
      id: depId,
      data: [
        buildDepartmentNameCell(department, departmentsRows.length),
        o.capacity,
        o.scheduled,
        o.logged.scheduled,
        o.scheduled - o.logged.scheduled,
        [getLoggedRangeBar(o), getScheduledRangeBar(o)],
      ],
      children: [
        ...departmentsRows.map((c = {}) => {
          return buildDepartmentRow(c, c.department_id);
        }),
        ...peopleRows.map((c) => {
          const person = people[c.person_id];
          return {
            id: c.person_id,
            data: [
              person.name,
              c.capacity,
              c.scheduled,
              c.logged.scheduled,
              c.scheduled - c.logged.scheduled,
              '',
            ],
          };
        }),
      ],
    };
  };

  const rows = flatMap(byDept, (o, deptId) => {
    const department = departments[deptId];
    if (department?.parent_id) {
      return [];
    }

    return [buildDepartmentRow(o, deptId)];
  });

  return { headers, rows };
}

export function getCombinedTable(ctx, raw) {
  const { people, departments } = ctx;

  const headers = [
    {
      label: 'Department',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Past logged + Future sched.', width: 200 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 100 },
    { label: 'Overtime', width: 100 },
    {
      label: getPctHeader(ctx, 'combined'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byDept = breakdown(ctx, raw);

  const buildDepartmentRow = (o, depId) => {
    const department = departments[depId];
    const peopleRows = Object.values(o.children.people);
    const departmentsRows = Object.values(o.children.departments);

    return {
      id: depId,
      data: [
        buildDepartmentNameCell(department, departmentsRows.length),
        o.capacity,
        o.combined.scheduled,
        o.combined.billable,
        o.combined.nonbillable,
        o.timeoff,
        o.combined.overtime,
        getPctValue(ctx, o, 'combined'),
        getCombinedRangeBar(o),
      ],
      children: [
        ...departmentsRows.map((c = {}) => {
          return buildDepartmentRow(c, c.department_id);
        }),
        ...peopleRows.map((c) => {
          const person = people[c.person_id];
          return {
            id: c.person_id,
            data: [
              person.name,
              c.capacity,
              c.combined.scheduled,
              c.combined.billable,
              c.combined.nonbillable,
              c.timeoff,
              '-',
              getPctValue(ctx, c, 'combined'),
              '',
            ],
          };
        }),
      ],
    };
  };

  const rows = flatMap(byDept, (o, deptId) => {
    const department = departments[deptId];
    if (department?.parent_id) {
      return [];
    }

    return [buildDepartmentRow(o, deptId)];
  });

  return { headers, rows };
}
