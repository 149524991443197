import React, { ChangeEvent } from 'react';
import { Trans } from '@lingui/macro';

import { Tag } from '@float/ui/components/Tag';
import { Button } from '@float/ui/deprecated/Button/Button';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';

import { ProjectTaskRecord } from '../../types';
import { useMergeTasks } from './index.hooks';
import { MergeTasksInput } from './MergeTasksInput';
import { ProjectTaskIndictator } from './ProjectTaskIndicator';

import * as styles from './styles.css';

export const MergeTasksModal: React.FC<{
  tasks: ProjectTaskRecord[];
  onClose: () => void;
}> = ({ tasks, onClose }) => {
  const [newTaskName, setNewTaskName] = React.useState('');
  const isUnnamedTask = React.useMemo(
    () => Boolean(tasks.find((t) => t.unnamedTask)),
    [tasks],
  );
  const handleFormSubmit = useMergeTasks(newTaskName, isUnnamedTask, onClose);
  const onTaskNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewTaskName(event.target.value);
  };

  const taskCount = tasks.length;

  return (
    <Modal isOpen onClose={onClose}>
      <form onSubmit={handleFormSubmit}>
        <ModalHeader>
          <ModalTitle>
            <Trans>Merge {taskCount} tasks</Trans>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className={styles.mergeTasksContainer}>
            {tasks.map((task) => (
              <div
                className={styles.mergeTaskRow}
                key={task.task_meta_id + task.task_name}
              >
                <div className={styles.mergeTaskItem}>
                  <ProjectTaskIndictator task={task} />
                  {task.unnamedTask ? (
                    <span className={styles.unnamedTask}>
                      <Trans>No task allocation</Trans>
                    </span>
                  ) : (
                    task.task_name
                  )}
                </div>
                {!task.billable && (
                  <Tag className={styles.taskTagNonbillable}>
                    <Trans>Non-billable</Trans>
                  </Tag>
                )}
              </div>
            ))}
          </div>
          <MergeTasksInput
            isUnnamedTask={isUnnamedTask}
            newTaskName={newTaskName}
            onChange={onTaskNameChange}
          />
        </ModalBody>
        <ModalActions>
          <Button
            type="submit"
            disabled={!newTaskName?.trim() && !isUnnamedTask}
          >
            <Trans>Merge tasks</Trans>
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
