import React, { Fragment, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Plural, Trans } from '@lingui/macro';
import cx from 'classnames';
import { modalManagerConnect } from 'modalManager/modalManagerHoc';
import { showProjectSidePanel as showProjectSidePanelAction } from 'sidePanel/actions';
import styled from 'styled-components';

import { Rights } from '@float/common/lib/acl';
import useNavigation from '@float/common/lib/hooks/use-navigation';
import { getUser } from '@float/common/selectors/currentUser';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppDispatchDecorator, useAppSelector } from '@float/common/store';
import useMedia from '@float/libs/web/hooks/useMedia';
import EHIconFolderPlus from '@float/ui/deprecated/Earhart/Icons/Icon/IconFolderPlus';
import EHIconLogged from '@float/ui/deprecated/Earhart/Icons/Icon/IconLogged';
import EHIconTimeoff from '@float/ui/deprecated/Earhart/Icons/Icon/IconTimeoff';
import EHIconUserPlus from '@float/ui/deprecated/Earhart/Icons/Icon/IconUserPlus';
import { Hotkeys } from '@float/ui/deprecated/Hotkeys/Hotkeys';
import IconMenu from '@float/ui/deprecated/Icons/icon-menu';
import { Col, Flex, Row, Spacer } from '@float/ui/deprecated/Layout/Layout';
import colors from '@float/ui/deprecated/Theme/colors';
import { IconTasks } from '@float/ui/icons/essentials/IconTasks';
import { triggerAction } from '@float/web/actions/app';
import { Search } from '@float/web/searchV2/Search';

import AddButton from './AddButton';
import { getViewTypeCounts } from './getViewTypeCounts';
import { getFriendlyLocation } from './helpers';
import { useNavState } from './NavContext';
import ScheduleViewType from './ScheduleViewType';
import SideNav from './SideNav';
import {
  StyledNav,
  StyledNavTitle,
  StyledNavTitleCount,
  StyledNavTitleName,
  StyledSearch,
} from './styles';
import { ViewTypeNav } from './ViewTypeNav';

const NavTitleTextPadding = styled.div`
  padding-left: 8px;
`;

function NavTitle({ location }) {
  let title;

  switch (location.pathname) {
    case '/':
      title = <ScheduleViewType />;
      break;
    case '/project-plan':
      title = (
        <NavTitleTextPadding>
          <Trans>Project plan</Trans>
        </NavTitleTextPadding>
      );
      break;
    case '/log-time':
      title = <NavTitleTextPadding>Log my time</NavTitleTextPadding>;
      break;
    case '/people':
      title = (
        <NavTitleTextPadding>
          <PeopleNavTitle />
        </NavTitleTextPadding>
      );

      break;
    case '/projects':
      title = (
        <NavTitleTextPadding>
          <ProjectsNavTitle />
        </NavTitleTextPadding>
      );
      break;
    case '/report':
      title = <NavTitleTextPadding>Report</NavTitleTextPadding>;
      break;
    default:
      break;
  }

  if (!title) return null;
  return (
    <>
      <StyledNavTitle>{title}</StyledNavTitle>
    </>
  );
}

const PeopleNavTitle = () => {
  const countByCategory = useAppSelector(getViewTypeCounts);
  const peopleCount = countByCategory?.people;

  return (
    <span>
      <StyledNavTitleCount>{peopleCount}</StyledNavTitleCount>{' '}
      <StyledNavTitleName>
        <Plural value={peopleCount} one="Person" other="People" />
      </StyledNavTitleName>
    </span>
  );
};

const ProjectsNavTitle = () => {
  const countByCategory = useAppSelector(getViewTypeCounts);
  const projectsCount = countByCategory?.projects;

  return (
    <span>
      <StyledNavTitleCount>{projectsCount}</StyledNavTitleCount>{' '}
      <StyledNavTitleName>
        <Plural value={projectsCount} one="Project" other="Projects" />
      </StyledNavTitleName>
    </span>
  );
};

const getGlobalAddActions = ({
  user,
  triggerAction,
  showModal,
  showProjectSidePanel,
  pageName,
  navigation,
  setViewType,
  setLogTimeViewType,
  logTimeViewType,
}) => {
  const navigateToTimeline = () => {
    if (pageName === 'timeline') return;

    navigation.goTo('/');
  };

  const scheduleTaskAction = {
    name: 'Allocate time',
    shortcut: 't',
    icon: <IconTasks />,
    callback: () => {
      navigateToTimeline();
      triggerAction('addNewTask');
    },
  };

  const logTimeAction = {
    name: 'Log time',
    shortcut: 'g',
    icon: <EHIconLogged />,
    callback: () => {
      const isGuest = +user.people_id === 0;
      if (!isGuest && !logTimeViewType) {
        navigation.goTo('/log-time');
      } else if (!logTimeViewType) {
        setLogTimeViewType(true);
        setViewType('people');
      }
      triggerAction('addNewLoggedTime');
    },
  };

  const addTimeoffAction = {
    name: 'Add time off',
    shortcut: 'i',
    icon: <EHIconTimeoff />,
    callback: () => {
      navigateToTimeline();
      triggerAction('addNewTimeoff');
    },
  };

  const addProjectAction = {
    name: 'Add project',
    shortcut: 'p',
    icon: <EHIconFolderPlus />,
    callback: () => {
      if (
        Rights.canViewProjectTemplate(user) &&
        !user.prefs?.project_from_scratch
      ) {
        showModal('ModalProjectFromTemplate');
        return;
      }
      showProjectSidePanel();
    },
  };

  const addPersonAction = {
    name: 'Add person',
    shortcut: 'e',
    icon: <EHIconUserPlus />,
    callback: () => {
      showModal('personModal', {
        person: {},
        isAdding: true,
        editing: true,
      });
    },
  };

  const actionRows = [];

  // This actions menu is highlighted during the product tour,
  // if we change the logic for each item visibility,
  // we might as well need to update the ProductTour config here:
  // app/OnboardingManager/components/ProductTour/config.js

  if (Rights.canCreateTask(user)) {
    actionRows.push(scheduleTaskAction);
  }

  if (Rights.canCreateLoggedTime(user)) {
    actionRows.push(logTimeAction);
  }

  if (Rights.canCreateTimeoff(user)) {
    actionRows.push(addTimeoffAction);
  }

  if (Rights.canCreateProject(user)) {
    actionRows.push(addProjectAction);
  }

  if (Rights.canCreatePeople(user)) {
    actionRows.push(addPersonAction);
  }

  return actionRows;
};

const GlobalActionShortcuts = ({ actionRows, showModal }) => {
  const keyMap = useMemo(() => {
    const globalShortcuts = [
      ...actionRows,
      {
        shortcut: '?',
        callback: () => showModal('shortcutModal'),
      },
    ];

    return globalShortcuts.reduce((acc, actionConfig) => {
      acc[actionConfig.shortcut] = actionConfig.callback;
      return acc;
    }, {});
  }, [actionRows, showModal]);

  return <Hotkeys keyMap={keyMap} />;
};

const NavSearch = ({ location, logTimeView, setViewType }) => {
  return (
    <StyledSearch>
      <Search
        location={location}
        logTimeView={logTimeView}
        setViewType={setViewType}
      />
    </StyledSearch>
  );
};

const Nav = ({
  className = '',
  companyPrefs,
  user,
  location,
  triggerAction,
  showModal,
}) => {
  const navigation = useNavigation();
  const history = useHistory();
  const isMobile = useMedia() === 'small';
  const navConfig = useNavState();
  const { logMyTimeView, logTimeViewType, setViewType, setLogTimeViewType } =
    useScheduleContext();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const pageName = getFriendlyLocation(history.location.pathname);
  const showProjectSidePanel = useAppDispatchDecorator(
    showProjectSidePanelAction,
  );
  const isReportPage = pageName === 'report';

  const actionRows = useMemo(
    () =>
      getGlobalAddActions({
        user,
        navigation,
        pageName,
        triggerAction,
        showModal,
        showProjectSidePanel,
        setViewType,
        setLogTimeViewType,
        logTimeViewType,
      }),
    [
      user,
      navigation,
      pageName,
      triggerAction,
      showModal,
      showProjectSidePanel,
      setViewType,
      setLogTimeViewType,
      logTimeViewType,
    ],
  );

  return (
    <StyledNav id="main-nav" className={cx(className, pageName)}>
      <Col>
        <Row
          className="nav-content"
          justifyContent="space-between"
          alignItems="center"
        >
          {isMobile ? (
            <Flex
              className="nav-content"
              justifyContent="flex-start"
              height={48}
              flex="0"
            >
              <div
                data-product-tour-id="schedule-mobile,navigation-mobile"
                onClick={() => {
                  setIsMobileNavOpen(true);
                }}
              >
                <IconMenu color={colors.charcoalGrey} />
              </div>
              <Spacer size="30" />
              <SimplePortal portalId="mobile-menu-portal">
                <SideNav
                  isMobileMenuOpen={isMobileNavOpen}
                  hideMobileSideNav={() => setIsMobileNavOpen(false)}
                  mobileControls={
                    isReportPage && (
                      <Row
                        height={48}
                        onClick={() => setIsMobileNavOpen(false)}
                      >
                        <ViewTypeNav />
                      </Row>
                    )
                  }
                />
              </SimplePortal>
            </Flex>
          ) : (
            <Flex
              className="nav-content left"
              justifyContent="flex-start"
              height={48}
              flex="1"
            >
              <NavTitle location={location} />
              <Spacer size={6} />
              {!logMyTimeView && isReportPage && (
                <>
                  <Spacer size={6} />
                  <ViewTypeNav />
                </>
              )}
              {navConfig.leftControls.length > 0 && (
                <>
                  <Spacer size={6} />
                  {navConfig.leftControls}
                </>
              )}
              <Spacer size={6} />
              <NavSearch
                location={location}
                logTimeView={logTimeViewType}
                setViewType={setViewType}
              />
            </Flex>
          )}
          {isMobile && (
            <Flex
              className="nav-content"
              justifyContent="center"
              height={60}
              flex="1"
            >
              <NavSearch
                location={location}
                logTimeView={logTimeViewType}
                setViewType={setViewType}
              />
            </Flex>
          )}
          <Flex
            className="nav-content right"
            justifyContent="space-between"
            flex="0"
          >
            <Flex justifyContent="flex-end">
              {navConfig.quickActionCtrls.map((ctrl, i, arr) => {
                const isLastCtrl = arr.length - 1 === i;
                return (
                  <Fragment key={i}>
                    {ctrl}
                    {!isLastCtrl && <Spacer size={8} />}
                  </Fragment>
                );
              })}
              <GlobalActionShortcuts
                actionRows={actionRows}
                showModal={showModal}
              />
              {actionRows.length > 0 && (
                <>
                  {!logMyTimeView && <Spacer size={8} />}
                  <div data-product-tour-id="add-button">
                    <AddButton actionRows={actionRows} />
                  </div>
                </>
              )}
            </Flex>
          </Flex>
        </Row>
      </Col>
    </StyledNav>
  );
};

const SimplePortal = ({ children, portalId }) => {
  const container = document.getElementById(portalId);

  if (!container) {
    return null;
  }

  return createPortal(children, container);
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  companyPrefs: state.companyPrefs,
  prompts: state.legacyOnboarding.prompts,
});

const mapDispatchToProps = {
  triggerAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(modalManagerConnect(Nav));
