import React from 'react';

import { getAccountTypeToLabelMap } from '@float/constants/accounts';
import { ReadOnlyField } from '@float/ui/deprecated/Field/ReadOnlyField';

let self;

export default function renderPersonAccessReadOnly(that) {
  self = that;
  const { form } = self.state;
  const account = form.account || {};
  const accountType = account.account_type || 0;

  return (
    <>
      <ReadOnlyField
        label="Access"
        value={
          accountType
            ? getAccountTypeToLabelMap()[accountType]
            : 'No access rights'
        }
        {...self.readOnlyProps}
      />
      {form.email && (
        <ReadOnlyField
          label="Email"
          value={form.email}
          {...self.readOnlyProps}
        />
      )}
    </>
  );
}
