import React, { StrictMode, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { History, LocationDescriptor } from 'history';
import { modalManagerConnect } from 'modalManager/modalManagerHoc';
import useMount from 'react-use/esm/useMount';

import { trackInteraction } from '@float/common/lib/trackInteraction';
import {
  getUser,
  getVisibleSettings,
} from '@float/common/selectors/currentUser';
import { useAppSelector, useAppSelectorStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Button } from '@float/ui/deprecated/Button/Button';
import EHIconCopy from '@float/ui/deprecated/Earhart/Icons/Icon/IconCopy';
import EHIconKey from '@float/ui/deprecated/Earhart/Icons/Icon/IconKey';
import { Input } from '@float/ui/deprecated/Input';
import zapierImage from '@float/web/assets/images/zapier_logo.png';
import { integrationTypes } from '@float/web/pmSidebar/integrationTypes';

import TokenSection from '../../containers/Integrations/TokenSection';
import Body from '../Body';
import RestrictedMessage from '../RestrictedMessage';
import { AsanaSetup, AsanaSubsection } from './Asana';
import { Paragraph, StyledSlack } from './Integrations.styles';
import { JiraSetup, JiraSubsection } from './Jira';
import { LinearCycleIntegration } from './LinearCycleIntegration';
import { Section } from './shared/Section';
import {
  ApiKeyLogoContainer,
  Subsection,
  SubsectionAction,
  SubsectionContent,
  SubsectionLogo,
  ZapierLogo,
} from './styles';
import { TeamworkSetup, TeamworkSubsection } from './Teamwork';
import { TrelloSetup, TrelloSubsection } from './Trello';
import { IntegrationName } from './types';

export * from './styles';

const setupNameToComponent = {
  jira: JiraSetup,
  teamwork: TeamworkSetup,
  asana: AsanaSetup,
  trello: TrelloSetup,
};

type IntegrationsProps = {
  userCanSee: ReturnType<typeof getVisibleSettings>;
  location: LocationDescriptor;
  history: History;
};

function Integrations(props: IntegrationsProps) {
  const { userCanSee, location, history } = props;

  const user = useAppSelectorStrict(getUser);

  const token = useAppSelector((state) => state.settingsJwt.apiKey);

  const [setupScreen, setSetupScreen] = useState<IntegrationName | null>(null);
  const [status, setStatus] = useState<
    Partial<Record<IntegrationName, number>>
  >({});
  const [integrated, setIntegrated] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState('');

  const isLinearIntegrationEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.LinearIntegration,
  );

  useMount(() => {
    trackInteraction('integrations', user);
  });

  useEffect(() => {
    const integrated = Object.entries(status).reduce(
      // @ts-expect-error `integrationTypes` aren't typed
      (acc, [key, value]) => (integrationTypes[key] && value > 0) || acc,
      false,
    );
    setIntegrated(integrated);
  }, [status]);

  useEffect(() => {
    if (copiedMessage !== '') {
      setTimeout(() => {
        setCopiedMessage('');
      }, 1000);
    }
  }, [copiedMessage]);

  const tokenToClipboard = (input: {
    select: () => void;
    setSelectionRange: (lower: number, upper: number) => void;
  }) => {
    input.select();
    input.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(token);

    setCopiedMessage(t`Copied!`);
  };

  if (!userCanSee.api) {
    return <RestrictedMessage />;
  }

  if (setupScreen) {
    const SetupScreenComponent = setupNameToComponent[setupScreen];
    return <SetupScreenComponent exitSetup={() => setSetupScreen(null)} />;
  }

  return (
    <StrictMode>
      <Body
        header={t`Integrations`}
        subheader={t`Import projects, tasks or people from your favorite apps to start scheduling projects in Float in seconds.`}
      >
        <Section heading={t`Project management`}>
          <AsanaSubsection
            history={history}
            startSetup={() => setSetupScreen(IntegrationName.Asana)}
            onCoIntStatusLoad={(asana: number) =>
              setStatus({ ...status, asana })
            }
            connectLimitReached={integrated && !status.asana}
          />
          <JiraSubsection
            startSetup={() => setSetupScreen(IntegrationName.Jira)}
            onCoIntStatusLoad={(jira: number) => setStatus({ ...status, jira })}
            connectLimitReached={integrated && !status.jira}
          />
          <TeamworkSubsection
            history={history}
            startSetup={() => setSetupScreen(IntegrationName.Teamwork)}
            onCoIntStatusLoad={(teamwork: number) =>
              setStatus({ ...status, teamwork })
            }
            connectLimitReached={integrated && !status.teamwork}
          />
          <TrelloSubsection
            startSetup={() => setSetupScreen(IntegrationName.Trello)}
            onCoIntStatusLoad={(trello: number) =>
              setStatus({ ...status, trello })
            }
            connectLimitReached={integrated && !status.trello}
          />
        </Section>

        {isLinearIntegrationEnabled && <LinearCycleIntegration />}

        <Section heading="Team messaging">
          <StyledSlack
            location={location}
            history={history}
            className="inputButton green"
          />
        </Section>

        <Section heading="Connect to anything">
          <Subsection>
            <SubsectionLogo>
              <ZapierLogo src={zapierImage} />
            </SubsectionLogo>
            <SubsectionContent>
              <Paragraph>
                <Trans>
                  Use{' '}
                  <a
                    href="https://zapier.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Zapier
                  </a>{' '}
                  to sync your Float data with your favorite apps. Choose from
                  1000's of applications and pre-built 'Zaps' to connect with in
                  just a few clicks.
                </Trans>
              </Paragraph>
            </SubsectionContent>
            <SubsectionAction>
              <Button
                onClick={() => window.open('https://zapier.com/zapbook/float')}
              >
                <Trans>View Zaps</Trans>
              </Button>
            </SubsectionAction>
          </Subsection>

          <Subsection>
            <SubsectionLogo>
              <ApiKeyLogoContainer>
                <EHIconKey />
                <p>
                  <Trans>API Key</Trans>
                </p>
              </ApiKeyLogoContainer>
            </SubsectionLogo>
            <TokenSection />
          </Subsection>

          <Subsection noParent>
            <SubsectionLogo>&nbsp;</SubsectionLogo>
            <Input
              size="large"
              readOnly
              value={copiedMessage || token || ''}
              icon={<EHIconCopy />}
              iconPosition="trailing"
              onClickIcon={tokenToClipboard}
            />
          </Subsection>
        </Section>
      </Body>
    </StrictMode>
  );
}

export default modalManagerConnect(Integrations);
