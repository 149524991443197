import styled, { css } from 'styled-components';

import { FIN } from '@float/theme';
import { Button } from '@float/ui/deprecated/Button/Button';
import { CheckboxLabel } from '@float/ui/deprecated/Checkbox/styles';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { Table } from '@float/ui/deprecated/Table/Table';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

const { Cell, HeaderCell } = Table._styles;

export { AddEntity } from 'manage/people-v2/styles';

export const ProjectsSection = styled.div`
  overflow: initial;

  .mobile {
    display: none;
  }

  ${Cell}.project {
    font-size: 16px;
    line-height: 1.38;
    font-weight: bold;
    position: relative;
    height: 100%;
  }

  ${Cell}.project, ${Cell}.client {
    color: ${(p) => p.theme.charcoalGrey};
  }

  ${Cell}.notes {
    svg:hover {
      path {
        stroke: ${(p) => p.theme.charcoalGrey};
      }
    }
  }

  ${Cell}.end {
    position: relative;
    overflow: visible;
  }

  .phase-row {
    ${Cell}.project {
      font-weight: 500;
      padding-left: 20px;
    }
  }

  ${Cell}.pm {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1010px) {
    ${Cell}, ${HeaderCell} {
      &.tags,
      &.statusTags,
      &.notes {
        display: none;
      }
    }
  }

  ${respondTo('small')(css`
    .desktop {
      display: none;
    }

    .mobile {
      display: initial;
    }

    ${Cell}.project {
      position: static;
      height: auto;

      ${CheckboxLabel} {
        display: none;
      }
    }

    ${Cell} {
      &.budget,
      &.start,
      &.end {
        display: none;
      }
    }

    ${Cell}.pm {
      position: absolute;
      right: 10px;
      top: 15px;
      width: 24px !important;
    }

    ${Cell} {
      &.statusTags {
        display: flex;
        margin-top: 4px;
      }
    }
  `)}
`;

export const Notes = styled.span`
  font-size: 12px;
  line-height: 1.33;
  color: ${(p) => p.theme.charcoalGrey};
  white-space: initial;
  word-break: break-all;
`;

export const ProjectNameContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;

  ${Table.HoverLinks} {
    right: ${(p) => (p.hasPhase ? 45 : 0)}px;
  }

  --svg-icon-color: ${FIN.Color.Icon.Secondary};

  ${({ isActive }) =>
    !isActive &&
    `
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Disabled}};
    `}

  ${respondTo('small')(css`
    ${Table.HoverLinks} {
      right: 20px;
    }
  `)}
`;

export const PhasesExpand = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;

  ${Typography.Label14.M500};
  color: ${({ disabled }) =>
    disabled ? Colors.FIN.Lt.Emphasis.Disabled : Colors.FIN.Lt.Emphasis.Medium};

  svg.icon-chevron-down-sm {
    transition:
      transform 0.1s linear,
      stroke 0.1s linear;
    transform: rotate(${(p) => (p.isOpen ? '0' : '-90deg')});

    path {
      stroke: ${({ disabled }) =>
        disabled
          ? Colors.FIN.Lt.Emphasis.Disabled
          : Colors.FIN.Lt.Emphasis.Medium};
    }
  }

  ${respondTo('small')(css`
    display: none;
  `)}
`;

export const QuickActionButton = styled(Button)`
  @media (max-width: 767px) {
    display: none;
  }
`;
