import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import cx from 'classnames';
import { modalManagerConnect } from 'modalManager/modalManagerHoc';
import {
  getIsScheduleLoading,
  getLockPeriodDates,
  getPeopleMap,
  getPmSidebarVisibility,
  getPointerEvents,
  getUser,
  shouldShowPmSidebar,
} from 'selectors';
import styled from 'styled-components';

import { getUserAccessRights } from '@float/common/selectors/userAccessRights';
import { ScheduleContextProvider } from '@float/common/serena/ScheduleContext';
import { FIN } from '@float/theme';
import { Col, Flex } from '@float/ui/deprecated/Layout/Layout';
import NoSession from '@float/web/components/modals/NoSession';
import SideNav from '@float/web/components/Nav/SideNav';

import People from '../../manage/people-v2/People';
import Projects from '../../manage/projects-v2/Projects';
import { Reports } from '../../reports/Reports';
import { Serena as SerenaSchedule } from '../../serena';
import withTitle from '../decorators/withTitle';
import { Modals } from '../modals';
import Nav from '../Nav/Nav';
import { NavProvider } from '../Nav/NavContext';
import SharedLinkNav from '../Nav/SharedLinkNav';
import { RouteWithGuard } from './RouteWithGuard';

const Wrapper = styled.div`
  ${({ noUserSelect }) => noUserSelect && 'user-select: none;'}
`;

const AppInner = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: initial;
  overflow: visible;

  &.schedule {
    // this is to not interfere with the scrollable schedule
    z-index: 2;
  }

  @media print {
    height: initial;
  }
`;

const SCHEDULE_MATCH = ['/', '/share/:token'];

class PortalComponent extends React.Component {
  state = {
    appLoading: true,
  };

  static getDerivedStateFromProps(props) {
    const { pathname } = props.location;

    // Note that Grid.jsx will manage scrollable for people and projects as it
    // will be based on the active sort

    if (pathname === '/') {
      return { route: 'schedule', scrollable: false };
    } else if (pathname === '/log-time') {
      return { route: 'log-time', scrollable: false };
    } else if (pathname === '/people') {
      return { route: 'people', scrollable: false };
    } else if (pathname === '/projects') {
      return { route: 'projects', scrollable: false };
    } else if (pathname.startsWith('/report')) {
      return { route: 'reports', scrollable: true };
    } else if (pathname.startsWith('/share')) {
      return { route: 'schedule', scrollable: false, isSharedLink: true };
    }

    return { route: '' };
  }

  onShowTour = () => {
    this.props.manageModal({ visible: true, modalType: 'tourModal' });
  };

  render() {
    const { isSharedLink } = this.state;
    const isMobile = window.innerWidth <= 767;

    const isGuest = !this.props.user.people_id;

    return (
      <Wrapper
        noUserSelect={!this.props.pointerEvents}
        className={`app-wrapper`}
      >
        {/*
            Pass data in context only if absolutely necessary.  If a passed prop
            changes frequently, that can have severe performance implications.
          */}
        <ScheduleContextProvider
          user={this.props.user}
          allPeople={this.props.allPeople}
          location={this.props.location}
          isSidebarOpen={this.props.isSidebarOpen}
          isSharedLink={isSharedLink}
          lockPeriodDates={this.props.lockPeriodDates}
        >
          <NoSession />
          <Modals />
          <NavProvider>
            {this.state.isSharedLink ? (
              <Flex alignItems="flex-start">
                <AppWrapper route={this.state.route}>
                  <SharedLinkNav />
                  <AppInner
                    className={cx(this.state.route, {
                      scrollable: this.state.scrollable,
                    })}
                    height={this.state.viewportHeight}
                  >
                    <Route
                      path={SCHEDULE_MATCH}
                      exact
                      render={withTitle('Schedule | Float', SerenaSchedule)}
                    />
                  </AppInner>
                </AppWrapper>
              </Flex>
            ) : (
              <Flex alignItems="flex-start">
                {isMobile ? <div id="mobile-menu-portal" /> : <SideNav />}
                <AppWrapper route={this.state.route}>
                  <Nav location={this.props.location} />
                  <AppInner
                    className={cx(this.state.route, {
                      scrollable: this.state.scrollable,
                    })}
                    height={this.state.viewportHeight}
                  >
                    <Switch>
                      <Route
                        path={SCHEDULE_MATCH}
                        exact
                        render={withTitle('Schedule | Float', SerenaSchedule)}
                      />
                      <Route
                        path="/project-plan"
                        render={withTitle(
                          'Project plan | Float',
                          SerenaSchedule,
                        )}
                      />
                      <Route
                        // Since `/manage` was defaulting to `people`
                        // We'll redirect all /manage routes to /people for backward compatibility
                        path="/manage"
                        render={() => <Redirect to="/people" />}
                      />
                      <RouteWithGuard
                        guard={this.props.userSpecs.hasManage}
                        path="/people"
                        redirect="/"
                        render={withTitle('People | Float', People)}
                      />
                      <RouteWithGuard
                        guard={this.props.userSpecs.hasManage}
                        redirect="/"
                        path="/projects"
                        render={withTitle('Projects | Float', Projects)}
                      />
                      <Route
                        path="/report"
                        render={withTitle('Report | Float', Reports)}
                      />
                      <RouteWithGuard
                        guard={!isGuest}
                        path="/log-time"
                        render={withTitle(
                          'Log my time | Float',
                          SerenaSchedule,
                        )}
                      />
                    </Switch>
                    <img className="edge-cursor" />
                  </AppInner>
                </AppWrapper>
              </Flex>
            )}
          </NavProvider>
        </ScheduleContextProvider>
      </Wrapper>
    );
  }
}

const AppWrapper = styled(Col)`
  height: calc(var(--screen-height) - var(--app-margin-top, 0px));
  background: ${FIN.Color.Background.Default};

  @media print {
    height: initial;
    overflow: initial;
  }
`;

const mapStateToProps = (state) => ({
  printMode: state.app.printMode,
  pointerEvents: getPointerEvents(state),
  isScheduleDataLoading: getIsScheduleLoading(state),
  shouldShowPmSidebar: shouldShowPmSidebar(state),
  user: getUser(state),
  allPeople: getPeopleMap(state),
  isSidebarOpen: getPmSidebarVisibility(state).isSidebarOpen,
  lockPeriodDates: getLockPeriodDates(state),
  userSpecs: getUserAccessRights(state),
});

const mapDispatchToProps = {};

const Portal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(modalManagerConnect(PortalComponent));

export { Portal };
