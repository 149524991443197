import React, {
  KeyboardEventHandler,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  addMinutes,
  getUse24HourFormat,
  toDbTime,
  toLongFriendlyTime,
} from '@float/common/lib/time';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { useAppSelectorStrict } from '@float/common/store';
import { FieldLabel } from '@float/ui/deprecated/Label';

import { inputStyles } from './styles';

const TimePickerContainer = styled.div`
  width: ${(p: { is24Hour: boolean }) => (p.is24Hour ? 85 : 91)}px;
  ${inputStyles}

  input {
    width: ${(p: { is24Hour: boolean }) => (p.is24Hour ? 60 : 130)}px;
    height: 40px;
  }
`;

function isValidInputValue(value: string) {
  if (getUse24HourFormat()) {
    return /^\d{0,2}:?\d{0,2}$/gi.test(value);
  }

  return /^\d{0,2}:?\d{0,2}(a|p)?m?$/gi.test(value);
}

type TimePickerProps = {
  label: React.ReactNode;
  style?: React.CSSProperties;
  ariaLabel?: string;
  value: string;
  onChange: (
    value: string | null,
    didChangeFromArrowKeyPress?: boolean,
  ) => void;
};

export const TimePicker = React.forwardRef((props: TimePickerProps, ref) => {
  // Opting-out from React Compiler to avoid issues with the isAm ref access
  'use no memo';
  const { ariaLabel, label, style = {}, value = '', onChange } = props;

  const companyPrefs = useAppSelectorStrict(getCompanyPrefs);

  const originalValue = useRef(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const isAm = useRef<boolean | null>(null);
  const [inputValue, setInputValue] = useState(
    toLongFriendlyTime(value, companyPrefs),
  );

  let inputValueWithHeuristics = inputValue;
  if (!/[ap]/.test(inputValue) && !getUse24HourFormat(companyPrefs)) {
    // eslint-disable-next-line react-compiler/react-compiler
    inputValueWithHeuristics += isAm.current ? 'am' : 'pm';
  }
  const dbFormatValue = toDbTime(inputValueWithHeuristics, 1);

  useEffect(() => {
    setInputValue(toLongFriendlyTime(value, companyPrefs));
    isAm.current = value < '12:00';
  }, [value, companyPrefs]);

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      inputRef.current?.focus();
      inputRef.current?.select();
    },
  }));

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    if (!isValidInputValue(evt.target.value)) return;

    let v = evt.target.value;

    if (v.length > inputValue.length && /^\d{2}$/.test(v)) {
      v += ':';
    }

    setInputValue(v);
  };

  function handleBlur() {
    onChange(dbFormatValue || originalValue.current);
    setInputValue(
      toLongFriendlyTime(dbFormatValue || originalValue.current, companyPrefs),
    );
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (evt) => {
    if (evt.key === 'ArrowUp') {
      if (dbFormatValue !== null) {
        onChange(toDbTime(addMinutes(dbFormatValue, 1), 1), true);
      }
    }

    if (evt.key === 'ArrowDown') {
      if (dbFormatValue !== null) {
        onChange(toDbTime(addMinutes(dbFormatValue, -1), 1), true);
      }
    }

    if (evt.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <TimePickerContainer
      style={{ ...style }}
      is24Hour={getUse24HourFormat(companyPrefs)}
    >
      {label && <FieldLabel>{label}</FieldLabel>}
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        aria-label={ariaLabel}
      />
    </TimePickerContainer>
  );
});

export default TimePicker;
