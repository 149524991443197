import { Person } from '@float/types/person';

type GetWorkDaysCountInRangeParams = {
  start_date: string;
  end_date: string;
  people: Person[];
  dates: DatesManager;
  getIsWorkDay: (
    person: Person,
    date: string,
    excludeTimeoffId?: number,
  ) => boolean;
  excludeTimeoffId?: number;
};

export function getWorkDaysCountInRange({
  start_date,
  end_date,
  people,
  dates,
  excludeTimeoffId,
  getIsWorkDay,
}: GetWorkDaysCountInRangeParams) {
  let workDaysCount = 0;

  if (!people) return workDaysCount;

  const start = dates.toNum(start_date);
  const end = dates.toNum(end_date);

  for (const person of people) {
    for (let i = start; i <= end; i++) {
      const dateStr = dates.fromNum(i);
      const isWorkDay = getIsWorkDay(person, dateStr, excludeTimeoffId);

      if (isWorkDay) {
        workDaysCount += 1;
      }
    }
  }

  return workDaysCount;
}
