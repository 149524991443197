import React, { ReactNode } from 'react';
import { t } from '@lingui/macro';

import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import * as styles from '../styles.css';

export function LogicalConjunctionButton(props: {
  isChangeBlocked: boolean;
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
}) {
  if (props.isChangeBlocked) {
    return (
      <TextTooltip
        className="hint"
        content={t`Cannot be changed between filters of different types`}
      >
        <button type="button" className={styles.logicalConjunction} disabled>
          {props.children}
        </button>
      </TextTooltip>
    );
  }

  return (
    <button
      type="button"
      className={styles.logicalConjunction}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
