import React, { forwardRef, useCallback } from 'react';
import cx from 'classnames';
import styled from 'styled-components';

import {
  ALL_TASK_EDIT_MODE_OPTIONS,
  ContextMenuModal,
} from '@float/common/components/Schedule/util/ContextMenu';
import {
  EarhartButton,
  EarhartControl,
} from '@float/common/components/Schedule/util/Controls/styles';
import { Primary } from '@float/common/earhart/colors';
import { trackInteraction } from '@float/common/lib/trackInteraction';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { TooltipWithHintAndMenu } from '@float/ui/deprecated/Tooltip/TooltipWithHintAndMenu';

const StyledControlButton = styled(EarhartButton)`
  background: ${Primary.Flue.Light['5']};
`;

const TaskEditModesMenu = forwardRef(
  ({ showMenu, isOpen, option, ...rest }, ref) => {
    return (
      <EarhartControl
        className="task-edit-modes"
        {...rest}
        ref={ref}
        onClick={showMenu}
      >
        <StyledControlButton
          equalPadding
          emphasisHigh
          className={cx({ hover: isOpen })}
        >
          {option.controlIcon ?? option.icon}
        </StyledControlButton>
      </EarhartControl>
    );
  },
);

export default function TaskEditModes({ user, actionMode, actions }) {
  const { logTimeView } = useScheduleContext();
  const { setActionMode } = actions;

  const showMenuHandler = useCallback(
    (showMenu) => (
      <ContextMenuModal
        user={user}
        logTimeView={logTimeView}
        actionMode={actionMode}
        setActionMode={(mode) => {
          showMenu(false);
          setTimeout(() => {
            setActionMode(mode);
          }, 0);
        }}
      />
    ),
    [actionMode, setActionMode, logTimeView, user],
  );

  const handleOpenChange = useCallback(() => {
    trackInteraction('scheduling modes', user, { pushToAnalytics: true });
  }, [user]);

  const option = ALL_TASK_EDIT_MODE_OPTIONS.find((x) => x.mode === actionMode);
  if (!option) {
    return null;
  }

  return (
    <TooltipWithHintAndMenu
      attachToRoot={true}
      placement="top"
      distance={5}
      hint={`${option.element} mode`}
      menu={showMenuHandler}
      onOpenChange={handleOpenChange}
    >
      {(isOpen, showMenu) => (
        <TaskEditModesMenu
          showMenu={showMenu}
          isOpen={isOpen}
          option={option}
        />
      )}
    </TooltipWithHintAndMenu>
  );
}
