import React, { useState } from 'react';

import { calculatePlaceholdersDowngrade } from '@float/common/lib/calculatePlaceholdersDowngrade';
import { Button } from '@float/ui/deprecated/Button/Button';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { onChatClick } from 'components/Nav/helpers';

import { DowngradeFromEnterpriseIcon } from '../Billing/common/PlanChangeIcons';
import { DowngradeAlert } from '../Billing/DowngradeAlert/DowngradeAlert';
import { DefaultModalProps } from '../Billing/TimeTrackingSection/types';
import { Placeholders } from '../constants';
import { StyledModalBody, StyledModalHeader } from './styles';

export type DowngradeModalProps = DefaultModalProps & { skipConfirm?: boolean };

export const DowngradeModal = withConfirm(
  ({ user, onSubmit, close, confirm, skipConfirm }: DowngradeModalProps) => {
    const [loading, setLoading] = useState(false);

    function submit() {
      if (!confirm) {
        setLoading(true);
        return onSubmit().then(() => {
          setLoading(false);
          close();
        });
      }
      const onConfirm = () => {
        setLoading(true);

        onSubmit().then(close);
      };

      if (skipConfirm) {
        onConfirm();
        return;
      }

      confirm({
        title: 'Confirm your new plan',
        message: `Your new plan will begin immediately. We’ll create a prorated transaction for any price changes to cover the remainder of this billing cycle.`,
        onConfirm,
        onCancel: close,
      });
    }

    const { canDowngrade, freePlaceholdersToPaidSeats, exceedingPlaceholders } =
      calculatePlaceholdersDowngrade({
        currentPeopleCount: user.people_count || 0,
        currentPeopleLimit: user.p_limit || 0,
        currentPlaceholderCount: user.placeholder_people || 0,
        nextPlaceholderLimit: Placeholders.Pro,
      });

    return (
      <Modal isOpen={true} onEnter={submit} onClose={close}>
        <ModalHeader>
          <DowngradeFromEnterpriseIcon />
          <StyledModalHeader>
            <ModalTitle>Downgrade to Pro</ModalTitle>
            <p>
              Your new plan will start immediately. You will be charged the new
              amount in your next billing cycle.
            </p>
          </StyledModalHeader>
        </ModalHeader>
        <ModalBody>
          <StyledModalBody>
            <h3>Switching from Enterprise? You'll lose access to:</h3>
            <ul>
              {freePlaceholdersToPaidSeats > 0 && (
                <li>
                  {freePlaceholdersToPaidSeats} of your included Placeholders
                </li>
              )}
              <li>Custom contracts and SLAs</li>
              <li>Priority 1:1 support</li>
              <li>Manual payment options</li>
            </ul>
            {!canDowngrade && (
              <>
                <br />
                <DowngradeAlert
                  exceedingPlaceholders={exceedingPlaceholders}
                  downgradingToPlan="Pro"
                />
              </>
            )}
          </StyledModalBody>
        </ModalBody>
        <ModalActions>
          <Button loader={loading} onClick={submit} disabled={!canDowngrade}>
            Downgrade
          </Button>
          <Button appearance="secondary" onClick={close}>
            Cancel
          </Button>
          <Button
            appearance="clear"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            onClick={onChatClick}
          >
            Message support
          </Button>
        </ModalActions>
      </Modal>
    );
  },
);
