import styled from 'styled-components';

import { Button } from '@float/ui/deprecated/Button/Button';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const DateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  margin-right: 20px;
  margin-top: 20px;
`;

export const InputWrapper = styled.div`
  width: 100%;

  & > div {
    margin-bottom: 0;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
  margin-top: auto;
  align-self: flex-end;

  ${Button.Styled} {
    width: 165px;
  }
`;

export const ScrollableList = styled.ul`
  margin-bottom: 20px;
  min-height: 150px;

  li {
    ${Typography.TextM.R400};

    display: flex;
    align-items: center;

    margin-left: 0;
    min-height: 64px;

    color: ${Colors.FIN.Lt.Emphasis.High};

    border-top: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};

    padding: 4px 0;

    box-sizing: border-box;

    &:last-child {
      border-bottom: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};
    }

    div.text-content {
      span.name {
        ${Typography.TextL.M500};
      }
    }

    div.controls {
      display: flex;

      margin-left: auto;

      button + button {
        margin-left: 2px;
      }
    }
  }
`;

export const NoMilestones = styled.div`
  ${Typography.TextL.R400};

  line-height: 150px;

  color: ${Colors.FIN.Lt.Emphasis.Low};

  text-align: center;

  margin: auto;
`;
