import { useMount } from 'react-use/esm';

import { PageData } from '@float/types/pageData';

/**
 * A custom hook that triggers a callback for new users during their first app
 * access.
 *
 * This hook checks if the user's `prompts` array is empty upon mount, which
 * indicates that the user is new and accessing the app for the first time. If
 * the condition is met, it invokes the provided callback function with the user
 * object.
 *
 * @param {PageData['user'] | undefined} user - The user object, containing data
 * about the user.
 * @param {(user: PageData['user']) => void} callback - A function to execute
 * for new users.
 *
 * @example
 * useInitialOnboardingEvent(user, (user) => {
 *   console.log('Tracking new user:', user);
 * });
 */

export const useInitialOnboardingEvent = (
  user: PageData['user'] | undefined,
  callback: (user: PageData['user']) => void,
) => {
  useMount(() => {
    if (user?.prompts.length === 0) {
      if (callback) callback(user);
    }
  });
};
