import { matchFilteredEntity } from '@float/common/search/helpers/matchFilteredEntity';
import { FilteredEntities } from '@float/common/search/types';
import { CellItem } from '@float/types/cells';
import { PersonProjectRow, PersonRow, ProjectRow } from '@float/types/rows';
import { BaseFilterToken } from '@float/types/view';

export function isGhostTask(
  row: PersonRow | PersonProjectRow | ProjectRow,
  item: CellItem<'task' | 'timeoff' | 'loggedTime'>,
  data: {
    filters: BaseFilterToken[];
    filteredEntities: FilteredEntities;
  },
) {
  // If we are on project plan and the task and the row project
  // are different, this is a ghost task
  if (
    item.type !== 'timeoff' &&
    'projectId' in row &&
    row.projectId &&
    row.projectId !== item.entity.project_id
  ) {
    return true;
  }

  // Items with temporary ids are those that are directly relevant to the
  // operation the user's currently trying to do, such as split, and we don't
  // want to render them as ghosts.
  if ('temporaryId' in item.entity && item.entity.temporaryId) return false;

  if (!data.filters.length) return false;

  return !matchFilteredEntity(data.filteredEntities, item.type, item.entityId);
}

export function isLoggedItemGhostTask(
  item: CellItem<'loggedTime'>,
  row: PersonRow | PersonProjectRow | ProjectRow,
  data: {
    filters: BaseFilterToken[];
    filteredEntities: FilteredEntities;
  },
) {
  if (
    'projectId' in row &&
    row.projectId &&
    row.projectId !== item.entity.project_id
  ) {
    return true;
  }

  if (!data.filters.length) return false;

  if (item.isTaskReference) {
    return !matchFilteredEntity(
      data.filteredEntities,
      'task',
      item.entity.task_id,
    );
  }

  return !matchFilteredEntity(data.filteredEntities, item.type, item.entityId);
}
