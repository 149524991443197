import styled from 'styled-components';

import { InputAlert } from '@float/ui/deprecated/Input/InputAlert';

/**
 * @deprecated convert to vanilla extract
 */
export const TaskNameContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`;

/**
 * @deprecated convert to vanilla extract
 */
export const ViewInPM = styled.label<{ compact: boolean }>`
  color: ${(p) => p.theme.emphasisMedium};
  cursor: pointer;
  text-decoration: underline;
  outline: none !important;
  position: absolute;
  right: 0;
  ${(p) => p.theme.text.small}
  bottom: ${(p) => (p.compact ? -20 : -34)}px;
  color: ${(p) => p.theme.blueGrey} !important;
`;

/**
 * @deprecated convert to vanilla extract
 */
export const OnboardingAddATaskLabel = styled(InputAlert)`
  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 100ms ease-out;
  }
`;
