import { useCallback } from 'react';
import { t } from '@lingui/macro';

import {
  SnackbarOptions,
  useSnackbar,
} from '@float/ui/deprecated/Snackbar/useSnackbar';

export function useSidePanelSnackbar() {
  const { showSnackbar } = useSnackbar();

  const showSuccessSnackbar = useCallback(
    (message: string, options: SnackbarOptions) => {
      showSnackbar(message, {
        autoCloseMs: 10000,
        linkAriaLabel: t`Open the side panel`,
        showClose: true,
        linkText: t`Open `,
        ...options,
      });
    },
    [showSnackbar],
  );

  return { showSnackbar, showSuccessSnackbar };
}
