import React from 'react';
import { Select, t, Trans } from '@lingui/macro';

import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { Button } from '@float/ui/components/Button';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { Modal } from '@float/ui/primitives/Modal';

import { ModalSwitchPersonProps } from '../../ModalSwitchPerson.types';
import { useSwitchPersonSelect } from './useSwitchPersonSelect';

export type ModalSwitchPersonSelectProps = {
  modalProps: ModalSwitchPersonProps['modalProps'];
  onClickSwitchPerson: (newPersonId: Person['people_id']) => void;
  person: Person;
  project: Project;
};

const getVirtualSelectSecondaryText = (person?: Person) => {
  const personName = person?.name || 'previous person';

  return t`The hourly rate for the new team member on this project will remain the same as ${personName}'s hourly rate on this project.`;
};

export const ModalSwitchPersonSelect = (
  props: ModalSwitchPersonSelectProps,
) => {
  const { modalProps, onClickSwitchPerson, person, project } = props;

  const {
    error,
    handleOnClickSwitchPerson,
    handlePersonSelect,
    newPersonId,
    peopleOptions,
  } = useSwitchPersonSelect(project, onClickSwitchPerson);

  const virtualSelectSecondaryText = getVirtualSelectSecondaryText(person);

  return (
    <Modal.Root {...modalProps}>
      <Modal.Layout.Header>
        <Modal.Title>
          <Select
            value={person?.name || 'person'}
            _person="Switch person"
            other={`Switch "${person?.name}"`}
          />
        </Modal.Title>
      </Modal.Layout.Header>
      <Modal.Description asChild>
        <VirtualSelect
          // @ts-expect-error virtual select is not typed so these props are not recognized
          errors={error}
          data-testid="teamMember"
          groupedOptions={peopleOptions}
          label={t`Choose a team member`}
          onChange={handlePersonSelect}
          onFocus={() => true} // fixes an autoFocus issue when rendered inside a modal
          placeholder={t`Type to filter`}
          secondaryText={virtualSelectSecondaryText}
          value={newPersonId}
          visibleItems={6}
        />
      </Modal.Description>

      <Modal.Layout.Footer>
        <Modal.Layout.ButtonGroup>
          <Button onClick={handleOnClickSwitchPerson}>
            <Trans>Switch person</Trans>
          </Button>
          <Modal.Close>
            <Button appearance="secondary-flay">
              <Trans>Cancel</Trans>
            </Button>
          </Modal.Close>
        </Modal.Layout.ButtonGroup>
      </Modal.Layout.Footer>
    </Modal.Root>
  );
};
