import API3 from '@float/common/api3';
import { ReduxStateStrict as ReduxState } from '@float/common/reducers/lib/types';
import { OLD_TYPE_TO_TYPE } from '@float/common/search/helpers';
import { getUser } from '@float/common/selectors/currentUser';
import { getCurrentViewId, getViewById } from '@float/common/selectors/views';
import {
  BaseFilterToken,
  CurrentUser,
  LegacyFilterTypes,
  RawView,
  RawViewFilter,
  SavedView,
} from '@float/types';

import { ViewPayload as RawPayload } from '../../api3/views';
import {
  AppDispatchStrict as AppDispatch,
  AppStoreStrict as AppStore,
} from '../../store';
import { trackViewEvent, trackViewUpdate } from './lib/tracking';
import { viewLastUsedStorage } from './lib/viewLastUsedStorage';
import { toBaseOperator, toRawFilters } from './views.helpers';

export const VIEWS_LOAD_START = 'VIEW_LOAD_START' as const;
export const VIEWS_LOAD_FAILED = 'VIEW_LOAD_FAILED' as const;
export const VIEWS_LOAD_FINISH = 'VIEW_LOAD_FINISH' as const;
export const VIEW_CREATED = 'VIEW_CREATED' as const;
export const VIEW_UPDATED = 'VIEW_UPDATED' as const;
export const VIEW_DELETED = 'VIEW_DELETED' as const;
export const VIEW_SHARED = 'VIEW_SHARED' as const;
export const VIEW_APPLIED = 'VIEW_APPLIED' as const;
export const VIEW_UNAPPLY = 'VIEW_UNAPPLY' as const;
export const INITIAL_VIEW_LOADED = 'INITIAL_VIEW_LOADED' as const;

export type ViewsLoadStartAction = {
  type: typeof VIEWS_LOAD_START;
};

export type ViewsLoadFailedAction = {
  type: typeof VIEWS_LOAD_FAILED;
};

export type ViewsLoadFinishAction = {
  type: typeof VIEWS_LOAD_FINISH;
  views: SavedView[];
};

export type ViewCreatedAction = {
  type: typeof VIEW_CREATED;
  view: SavedView;
};

export type ViewUpdatedAction = {
  type: typeof VIEW_UPDATED;
  view: SavedView;
  undoPayload: SavedView | null;
};

export type ViewDeletedAction = {
  type: typeof VIEW_DELETED;
  id: SavedView['id'];
  undoPayload: SavedView | null;
};

export type ViewAppliedAction = {
  type: typeof VIEW_APPLIED;
  view: SavedView;
};

export type ViewUnapplyAction = {
  type: typeof VIEW_UNAPPLY;
};

export type ViewPrefetchAction = {
  type: typeof INITIAL_VIEW_LOADED;
  view: SavedView;
};

export type ViewsAction =
  | ViewsLoadStartAction
  | ViewsLoadFailedAction
  | ViewsLoadFinishAction
  | ViewCreatedAction
  | ViewUpdatedAction
  | ViewDeletedAction
  | ViewAppliedAction
  | ViewPrefetchAction
  | ViewUnapplyAction;

function formatRawFilter(filter: RawViewFilter): BaseFilterToken {
  const type =
    OLD_TYPE_TO_TYPE[filter.type as LegacyFilterTypes] || filter.type;

  return {
    type,
    operator: toBaseOperator(filter.operator),
    val: filter.values,
  };
}

export async function formatView(data: RawView): Promise<SavedView> {
  return {
    ...data,
    lastUsed: (await viewLastUsedStorage.get(data.id)) || null,
    personal: Boolean(data.personal),
    pinned: Boolean(data.pinned),
    filters: data.filters.map(formatRawFilter),
  };
}

type ApiPayload = Pick<
  SavedView,
  'name' | 'filters' | 'personal' | 'pinned' | 'settings'
> & {
  account_ids?: {
    add: number[];
  };
};

export function formatViewPayload(data: ApiPayload) {
  const payload: RawPayload = {
    filters: toRawFilters(data.filters),
    name: data.name,
    personal: data.personal,
    pinned: data.pinned,
    settings: data.settings,
  };

  if (data.account_ids) {
    payload.account_ids = data.account_ids;
  }

  return payload;
}

export const ensureViewsLoaded =
  (refetch = false) =>
  async (dispatch: AppDispatch, getState: () => ReduxState) => {
    const loadState = getState().views?.loadState;

    if (loadState === 'LOADING') return;
    if (loadState === 'LOADED' && !refetch) return;

    try {
      dispatch({
        type: VIEWS_LOAD_START,
      });

      const rawViews = await API3.getAllViews();

      dispatch({
        type: VIEWS_LOAD_FINISH,
        views: await Promise.all(rawViews.map(formatView)),
      });
    } catch (e) {
      dispatch({
        type: VIEWS_LOAD_FAILED,
      });

      throw e;
    }
  };

export const viewCreatedAction = async (view: RawView) => ({
  type: VIEW_CREATED,
  view: await formatView(view),
});

export const createView =
  (payload: ApiPayload) => async (dispatch: AppDispatch) => {
    const rawView = await API3.createView({
      data: formatViewPayload(payload),
    });

    const action = await viewCreatedAction(rawView);
    dispatch(action);

    trackViewEvent('view-added', action.view);

    return action.view;
  };

export const setViewPersonalState =
  (view: SavedView, personal: boolean) => async (dispatch: AppDispatch) => {
    const update = {
      ...view,
      modified: new Date().toJSON(),
      personal,
    };

    dispatch({
      type: VIEW_UPDATED,
      view: update,
      undoPayload: view,
    });

    trackViewUpdate(update, view);

    try {
      await API3.updateView({
        data: formatViewPayload(update),
        id: view.id,
      });
    } catch (err) {
      // Reverting the change
      dispatch({
        type: VIEW_UPDATED,
        view,
        undoPayload: null,
      });

      throw err;
    }
  };

export const setViewPinnedState =
  (view: SavedView, pinned: boolean) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const user: CurrentUser = getUser(getState());

    // We want the last pinned to be at the top when ordering by most recent
    viewLastUsedStorage.update(view.id);

    const update = {
      ...view,
      modified: new Date().toJSON(),
      lastUsed: await viewLastUsedStorage.get(view.id),
      pinned,
    };

    dispatch({
      type: VIEW_UPDATED,
      view: update,
      undoPayload: view,
    });

    trackViewEvent(pinned ? 'view-pinned' : 'view-unpinned', view);

    try {
      await API3.updateView({
        data: formatViewPayload({
          ...update,
          // passing the account id to make the pinned state applied only for the current user
          account_ids: {
            add: [user.account_id],
          },
        }),
        id: view.id,
      });
    } catch (err) {
      // Reverting the change
      dispatch({
        type: VIEW_UPDATED,
        view,
        undoPayload: null,
      });

      throw err;
    }
  };

export const viewUpdatedAction = async (
  view: RawView,
  undoPayload: SavedView | null,
): Promise<ViewUpdatedAction> => ({
  type: VIEW_UPDATED,
  view: await formatView(view),
  undoPayload,
});

export const updateView =
  (id: number, payload: ApiPayload) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const prevViewValue = getViewById(getState(), id);

    const rawView = await API3.updateView({
      data: formatViewPayload(payload),
      id,
    });

    const action = await viewUpdatedAction(rawView, prevViewValue);

    if (prevViewValue) {
      trackViewUpdate(action.view, prevViewValue);
    }

    dispatch(action);
  };

export const viewDeletedAction =
  (id: number, undoPayload: SavedView | null) =>
  (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const currentViewId = getCurrentViewId(getState());

    dispatch({
      type: VIEW_DELETED,
      id,
      undoPayload,
    });

    // Remove the view id from the URL params
    if (id === currentViewId) {
      dispatch(unapplyViewAction());
    }
  };

export const deleteView =
  (id: number) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const view = getViewById(getState(), id);

    await API3.deleteView({
      id,
    });

    dispatch(viewDeletedAction(id, view));

    if (view) {
      trackViewEvent('view-removed', view);
    }
  };

export const unapplyViewAction = () => ({
  type: VIEW_UNAPPLY,
});
