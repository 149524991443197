import React from 'react';
import { FieldValues, get, useController, useFormState } from 'react-hook-form';
import { RecipeVariants } from '@vanilla-extract/recipes';
import classNames from 'classnames';

import {
  QuickInputField,
  QuickInputFieldProps,
} from '@float/ui/components/QuickInputField';

import { BaseSidePanelInputProps } from '../BaseSidePanelInput';

import * as styles from './styles.css';

export type SidePanelInputProps = Omit<
  BaseSidePanelInputProps,
  'value' | 'size' | 'defaultValue'
> & {
  valueOverride?: string;
  blockOutsideInteraction?: boolean;
  size?: NonNullable<RecipeVariants<typeof styles.sidePanelInput>>['fontSize'];
} & Pick<QuickInputFieldProps<FieldValues>, 'themeOverrides'>;

export function SidePanelInput(props: SidePanelInputProps) {
  const { valueOverride, name, disabled, variant, size, ...rest } = props;

  const { field } = useController({
    name: props.name,
  });

  const { errors } = useFormState({
    name,
  });

  const value = valueOverride || field.value || '';
  const hasErrors = Boolean(get(errors, name)) && !disabled;

  return (
    <QuickInputField
      {...rest}
      value={value}
      open={hasErrors}
      name={name}
      tooltipPlacement="right"
      disabled={disabled}
      variant={variant}
      blockOutsideInteraction={props.blockOutsideInteraction}
      className={classNames(
        rest.className,
        styles.sidePanelInput({
          fontSize: size || 'md',
        }),
      )}
    />
  );
}
