import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Intercom from '@intercom/messenger-js-sdk';
import { t } from '@lingui/macro';

import { trackEvent } from '@float/common//lib/analytics';
import { getShowUpgradeNotice } from '@float/common/selectors/currentUser';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';
import withUserCanSee from '@float/web/components/decorators/withUserCanSee';
import { fetchBillingInfo } from '@float/web/settingsV2/actions/account/billingInfo';

const TrialEndingSoon = ({
  showUpgradeNotice,
  trialDaysLeft,
  userCanSee,
  fetchBilling,
}) => {
  const { showSnackbar, closeSnackbar } = useSnackbar();
  const upgradePath = `/admin/billing`;

  const onNeedHelpClick = useCallback(() => {
    if (typeof Intercom !== 'undefined') {
      Intercom.show();
    }
  }, []);

  useEffect(() => {
    if (userCanSee.billing) {
      fetchBilling();
    }

    // close trial snackbar if component get dismounted (e.g. navigate to Team settings)
    // https://app.asana.com/0/1201258914201276/1201372035173705/f
    return () => {
      closeSnackbar('trial');
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!showUpgradeNotice) {
      closeSnackbar('trial');
      return;
    }
    const plural = trialDaysLeft === 1 ? '' : 's';
    const message =
      trialDaysLeft > 0
        ? t`${trialDaysLeft} day${plural} left on your trial.`
        : t`Your free trial has ended.`;

    showSnackbar(message, {
      id: 'trial',
      className: 'info',
      persist: true,
      loader: false,
      showClose: false,
      linkText: (
        <>
          {trialDaysLeft > 7 ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onNeedHelpClick();
                trackEvent('Trial Toast Clicked', { need_help: true });
              }}
            >
              {t`Need help?`}
            </a>
          ) : (
            <a
              href={upgradePath}
              onClick={() =>
                trackEvent('Trial Toast Clicked', { upgrade: true })
              }
            >
              {t`Upgrade`}
            </a>
          )}
        </>
      ),
      closeOnLinkClick: false,
    });
  }, [
    showUpgradeNotice,
    trialDaysLeft,
    showSnackbar,
    closeSnackbar,
    upgradePath,
    onNeedHelpClick,
  ]);

  return null;
};

const mapStateToProps = (state) => ({
  showUpgradeNotice: getShowUpgradeNotice(state).expiringSoon,
  trialDaysLeft: state.settingsBillingInfo.trial_days_left,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBilling: () => dispatch(fetchBillingInfo()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withUserCanSee(TrialEndingSoon));
