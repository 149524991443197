import { BudgetPriority, Project } from '@float/types/project';
import { TaskMeta } from '@float/types/task';

type GetIsBudgetLabelVisibleParams = {
  userCanSeeBudgets: boolean;
  project?: Project | null;
  taskMeta?: TaskMeta | null;
};

export const getIsBudgetLabelVisible = ({
  userCanSeeBudgets,
  project,
  taskMeta,
}: GetIsBudgetLabelVisibleParams) => {
  if (!project || !taskMeta) return false;

  if (!userCanSeeBudgets) return false;

  // We support the task budget label only for budgets by task case
  if (project.budget_priority !== BudgetPriority.Task) return false;

  // We always display budgets for non-billable projects with budget by task
  if (project.non_billable) return true;

  return taskMeta.billable === 1;
};

type GetIsBudgetLabelNotUtilizedWarningVisible = {
  userCanSeeBudgets: boolean;
  project?: Project | null;
  taskMeta?: TaskMeta | null;
};

export const getIsBudgetLabelNotUtilizedWarningVisible = ({
  userCanSeeBudgets,
  project,
  taskMeta,
}: GetIsBudgetLabelNotUtilizedWarningVisible) => {
  if (!project || !taskMeta) return false;

  if (!userCanSeeBudgets) return false;

  // We support the task budget warning label only for budgets by task case
  if (
    project.budget_priority !== BudgetPriority.Task &&
    project.budget_priority !== BudgetPriority.Phase
  )
    return false;

  // We don't display the warning on non-billable projects
  if (project.non_billable) return false;

  // Billable project with a non billable task
  return taskMeta.billable === 0;
};
