import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import cn from 'classnames';

import { useSeatCapacity } from '@float/common/lib/hooks/useSeatCapacity';
import { getUserAccessRights } from '@float/common/selectors/userAccessRights';
import { Modal, ModalBody } from '@float/ui/deprecated/Modal';
import { ScrollArea } from '@float/ui/primitives/ScrollArea';
import { UnsavedChangesConfirmModal } from 'components/modals/UnsavedChangesConfirmModal';

import { AddForm } from './components/AddForm/AddForm';
import { ButtonCard } from './components/ButtonCard/ButtonCard';

import IllustrationIntegrations from './assets/IllustrationIntegrations.svg';
import IllustrationUploadCSV from './assets/IllustrationUploadCSV.svg';

import * as styles from './styles.css';

export type QuickAddModalProps = {
  onClose: () => void;
  onClickUploadCSV: () => void;
};

export const QuickAddModal = (props: QuickAddModalProps) => {
  const { onClose, onClickUploadCSV } = props;

  const [isConfirmModalVisible, showConfirmModal] = useState(false);
  const { accessRights } = useSelector(getUserAccessRights);
  const seatCapacity = useSeatCapacity();
  const history = useHistory();

  const isFormDirty = useRef(false);

  const onCloseHandler = () => {
    if (isFormDirty.current) {
      showConfirmModal(true);
    } else {
      onClose();
    }
  };

  const onConfirmModalConfirm = () => {
    onClose();
  };

  const onConfirmModalCancel = () => {
    showConfirmModal(false);
  };

  const onFormChange = (isDirty: boolean = false) => {
    isFormDirty.current = isDirty;
  };

  const onClickSync = () => {
    history.push('admin/api');
  };

  return (
    <>
      <Modal isOpen onClose={onCloseHandler} dialogClassName={styles.modal}>
        <ModalBody className={styles.modalBody}>
          <div className={cn(styles.column, styles.columnLeft)}>
            <ScrollArea.Root>
              <ScrollArea.Content className={styles.scrollContent}>
                <AddForm
                  onClose={onCloseHandler}
                  onChange={onFormChange}
                  seatCapacity={seatCapacity}
                />
              </ScrollArea.Content>
            </ScrollArea.Root>
          </div>

          <div className={cn(styles.column, styles.columnRight)}>
            <ButtonCard
              label={t`Upload CSV`}
              description={
                <Trans>
                  Add your team to our CSV template then import them to Float
                </Trans>
              }
              onClick={onClickUploadCSV}
            >
              <img src={IllustrationUploadCSV} width={107} />
            </ButtonCard>

            {accessRights.isAccountOwner() && (
              <ButtonCard
                label={t`Sync with another app`}
                description={
                  <Trans>
                    Automatically import People, Projects and Tasks from your
                    favorite apps.
                  </Trans>
                }
                onClick={onClickSync}
              >
                <img src={IllustrationIntegrations} width={145} />
              </ButtonCard>
            )}
          </div>
        </ModalBody>
      </Modal>
      {isConfirmModalVisible && (
        <UnsavedChangesConfirmModal
          onConfirm={onConfirmModalConfirm}
          onCancel={onConfirmModalCancel}
          confirmAppearance="danger"
        />
      )}
    </>
  );
};
