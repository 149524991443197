import React from 'react';
import { connect } from 'react-redux';
import { get, groupBy, isEmpty, isEqual, isUndefined } from 'lodash';
import styled from 'styled-components';

import {
  ensureProjectsLoaded,
  fetchBudget,
} from '@float/common/actions/projects';
import Loader from '@float/common/components/elements/Loader';
import { trackIntegrationEvent } from '@float/common/lib/analytics';
import { Rights, userCanUpdateThemself } from '@float/common/lib/rights';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import { ReadOnlyField } from '@float/ui/deprecated/Field/ReadOnlyField';
import { bindVal } from '@float/ui/deprecated/helpers/forms';
import { Hr } from '@float/ui/deprecated/Hr/Hr';
import { Input } from '@float/ui/deprecated/Input';
import { Modal } from '@float/ui/deprecated/Modal';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import {
  addEditExtCalendar,
  fetchCalendarList,
} from '@float/web/integrations/actions/calendar';
import manageModal from '@float/web/modalManager/manageModalActionCreator';
import { InboundCalendarVersion } from '@float/web/pmSidebar/constants';
import {
  getCalendarProjectsOptions,
  getDefaultDropdownCalendarProject,
  getPeopleMap,
  getProjectPhases,
  getProjectsMap,
  getUser,
} from '@float/web/selectors';

import modalManagerHoc from '../../../modalManager/modalManagerHoc';
import ProjectsDropdown from '../../taskModals/dom/ProjectsDropdown';
import { REMOVE_MODE } from './constants';
import { EventsTimeZoneSelector } from './EventsTimeZoneSelector';
import IntegrationCalendarRemoveModal from './IntegrationCalendarRemoveModal';
import { IntegrationName } from './Integrations.constants';
import { Section, SectionHeading } from './Integrations.styles';

const configKeys = ['importAllEvents', 'eventsFilterWord', 'hideTitleAndDesc'];
export const IntegrationSourceName = {
  'google:calendar': 'Google',
  'office365:calendar': 'Outlook',
};
const removeModeDeleteKey = {
  [REMOVE_MODE.INBOUND]: 'deleteTasks',
  [REMOVE_MODE.OUTBOUND]: 'deleteEvents',
};

const Text = styled.p`
  font-size: 16px;
`;

const Label = styled(Text)`
  margin-bottom: 30px !important;
`;

class IntegrationCalendarEdit extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      form: {
        importAllEvents: true,
        eventsFilterWord: '',
        hideTitleAndDesc: false,
        outboundType: 'confirmedOnly',
        outboundResourceId: '',
        inboundOn: false,
        outboundOn: !userCanUpdateThemself(user),
        // set initial version based on Group By Task
        version: InboundCalendarVersion.V2,
        ignoreCompanyTimezone: false,
      },
      formErrors: {},
      errors: { project: [] },
      isRemoveModalOpen: false,
      removeMode: null,
      inboundNew: true,
      outboundNew: true,
    };

    const { extCalendar } = this.props.modalSettings;
    if (extCalendar) {
      this.state = {
        ...this.state,
        form: {
          ...this.state.form,
          prevExtCalendarId: extCalendar.ext_calendar_id,
          prevInboundResourceId: extCalendar.inboundResourceId,
          prevProjectId: extCalendar.projectId,
          inboundResourceId: extCalendar.inboundResourceId,
          inboundOn: !!extCalendar.inboundResourceId,
          projectId: extCalendar.projectId,
          importAllEvents: extCalendar.importAllEvents,
          eventsFilterWord: extCalendar.eventsFilterWord || '',
          hideTitleAndDesc: extCalendar.hideTitleAndDesc,
          outboundType: extCalendar.outboundType,
          outboundResourceId: extCalendar.outboundResourceId,
          outboundOn: !!extCalendar.outboundType,
          // backward-compatibility: we use V1 if no version available in the inbound calendar integration
          version: extCalendar.inboundResourceId
            ? extCalendar.version || InboundCalendarVersion.V1
            : InboundCalendarVersion.V2,
          // backward-compability: should use `false` when value is undefined, so using `!!` to properly init value
          ignoreCompanyTimezone: !!extCalendar.ignoreCompanyTimezone,
          prevIgnoreCompanyTimezone: !!extCalendar.ignoreCompanyTimezone,
        },
        inboundNew: !extCalendar.inboundResourceId,
        outboundNew: !extCalendar.outboundResourceId,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { extCalendar } = this.props.modalSettings;
    const { projectsMap, defaultProject } = this.props;
    if (!this.state.project) {
      if (
        extCalendar &&
        extCalendar.projectId &&
        projectsMap[extCalendar.projectId]
      ) {
        this.setState({
          project: projectsMap[extCalendar.projectId],
        });
      } else if (defaultProject && projectsMap[defaultProject.project_id]) {
        this.setState({ project: projectsMap[defaultProject.project_id] });
      }
    }

    const { addEditExtCalendarLoadState } = this.props.calendar;
    const { addEditExtCalendarLoadState: prevAddExtCalLoadState } =
      prevProps.calendar;
    if (
      addEditExtCalendarLoadState === 'LOAD_SUCCESS' &&
      prevAddExtCalLoadState !== addEditExtCalendarLoadState
    ) {
      this.close();
    }

    // validate project if it changed
    if (!isEqual(prevState.project, this.state.project)) {
      this.validateProjects();
    }
    // validate filter if stop importing all events
    if (!prevState.form.importAllEvents && this.state.form.importAllEvents) {
      this.validateFilterWord();
    }
  }

  componentDidMount() {
    const { integrationType } = this.props.modalSettings;

    this.props.ensureProjectsLoaded();
    this.props.fetchCalendarList(integrationType);
  }

  validate = (e) => {
    prevent(e);
    const validation = [this.validateFilterWord(), this.validateProjects()];
    return validation.every(Boolean);
  };

  validateProjects = () => {
    const projectErrors = [];
    const { form, project } = this.state;
    if (form.inboundOn && (!project || !project.project_name)) {
      projectErrors.push('Please select a Float project');
    }
    this.setState(({ formErrors, errors }) => ({
      formErrors: { ...formErrors, projectId: projectErrors },
      errors: { ...errors, project: projectErrors },
    }));
    return projectErrors.length === 0;
  };

  validateFilterWord = () => {
    const eventsFilterWordErrors = [];
    const { inboundOn, eventsFilterWord, importAllEvents } = this.state.form;
    const wordLength = eventsFilterWord.length;
    if (inboundOn && !importAllEvents && (wordLength < 3 || wordLength > 20)) {
      eventsFilterWordErrors.push('Must be between 3 and 20 characters long');
    }
    this.setState(({ formErrors }) => ({
      formErrors: { ...formErrors, eventsFilterWord: eventsFilterWordErrors },
    }));
    return eventsFilterWordErrors.length === 0;
  };

  onSubmit = (e) => {
    prevent(e);
    if (!this.validate(e)) {
      return;
    }
    const { extCalendar } = this.props.modalSettings;
    const { deleteEvents, deleteTasks, inboundOn, outboundOn } =
      this.state.form;
    const shouldRemoveInbound =
      extCalendar?.inboundResourceId && isUndefined(deleteTasks) && !inboundOn;
    const shouldRemoveOutbound =
      extCalendar?.outboundResourceId &&
      isUndefined(deleteEvents) &&
      !outboundOn;
    if (shouldRemoveInbound || shouldRemoveOutbound) {
      const removeMode =
        !inboundOn && !outboundOn
          ? REMOVE_MODE.BOTH
          : !inboundOn
            ? REMOVE_MODE.INBOUND
            : REMOVE_MODE.OUTBOUND;
      this.setState({
        isRemoveModalOpen: true,
        removeMode,
      });
    } else {
      this.setState({ isSubmitting: true });
      this.props.addEditExtCalendar({
        ...this.state.form,
        projectId: get(this.state, 'project.project_id'),
        type: this.props.modalSettings.integrationType,
      });
      // if it's initial import (no extCalendar), we close all modals and let async process notify user
      if (!extCalendar) {
        this.props.onClose(true);
      }
    }
  };

  close() {
    this.props.onClose();
  }

  onDisconnect = (e) => {
    prevent(e);
    const { extCalendar } = this.props.modalSettings;
    const removeMode =
      extCalendar.inboundResourceId && extCalendar.outboundType
        ? REMOVE_MODE.BOTH
        : extCalendar.inboundResourceId
          ? REMOVE_MODE.INBOUND
          : REMOVE_MODE.OUTBOUND;
    this.setState({
      isRemoveModalOpen: true,
      removeMode,
      form: {
        ...this.state.form,
        inboundResourceId: null,
        outboundType: null,
        outboundOn: false,
        inboundOn: false,
      },
    });
  };

  confirmRemove = (deleteData) => {
    const {
      removeMode,
      form: { inboundOn, outboundOn },
    } = this.state;
    const formData =
      removeMode === REMOVE_MODE.BOTH
        ? {
            [removeModeDeleteKey[REMOVE_MODE.INBOUND]]: deleteData,
            [removeModeDeleteKey[REMOVE_MODE.OUTBOUND]]: deleteData,
          }
        : {
            [removeModeDeleteKey[removeMode]]: deleteData,
          };
    const shouldClose = !inboundOn && !outboundOn;
    this.setState(
      {
        form: {
          ...this.state.form,
          ...formData,
        },
        isRemoveModalOpen: false,
        removeMode: null,
      },
      () => {
        this.onSubmit();

        const { integrationType } = this.props.modalSettings;
        trackIntegrationEvent(
          integrationType,
          'Integration disconnect',
          {
            deleteData,
            removeMode,
          },
          this.props.user.cid,
        );

        // if required, we close current and parent modals during a disconnect
        if (shouldClose) this.props.onClose(true);
      },
    );
  };

  closeRemoveModal = () => {
    this.setState({
      isRemoveModalOpen: false,
    });
  };

  // called from projectsElem
  isProjectOrPhaseTentative = () => false;

  canModifyProjects = () => {
    return Rights.canUpdateProject(this.props.user);
  };

  renderForm() {
    const { calendarList } = this.props.calendar;
    const { onClose, user } = this.props;
    const { extCalendar, integrationType } = this.props.modalSettings;
    const isEditMode = Boolean(extCalendar);
    const userCanEditSchedule = userCanUpdateThemself(user);

    const {
      inboundResourceId,
      importAllEvents,
      outboundResourceId,
      outboundType,
      inboundOn,
      outboundOn,
      version,
      ignoreCompanyTimezone = false,
    } = this.state.form;
    const outboundResourceName = calendarList.length
      ? (calendarList.find((c) => c.value === outboundResourceId) || {}).label
      : outboundResourceId;
    const { project, formErrors, inboundNew, outboundNew } = this.state;
    const submitDisabled =
      !!(
        extCalendar &&
        inboundResourceId === extCalendar.inboundResourceId &&
        project &&
        project.project_id === extCalendar.projectId &&
        configKeys.every(
          (key) =>
            this.state.form[key] === extCalendar[key] ||
            (!this.state.form[key] && !extCalendar[key]),
        )
      ) ||
      Object.values(formErrors).some((errArr) => !isEmpty(errArr)) ||
      (!inboundOn && !outboundOn);

    const filteredCalendarList = inboundNew
      ? calendarList
      : calendarList.filter((calendar) => calendar.value === inboundResourceId);

    const MY_CALENDARS = 'My Calendars';
    const OTHER_CALENDARS = 'Other Calendars';
    const calendarGroups = groupBy(filteredCalendarList, (cal) =>
      cal.ownCalendar ? MY_CALENDARS : OTHER_CALENDARS,
    );
    if (Array.isArray(calendarGroups[MY_CALENDARS])) {
      // ensure primary calendar is first option
      calendarGroups[MY_CALENDARS].sort((cal) => (cal.primary ? -1 : 1));
    }
    const groupedCalendarList = Object.keys(calendarGroups)
      // ensure MY_CALENDARS group comes first
      .sort((groupName) => (groupName === MY_CALENDARS ? -1 : 1))
      .map((groupName) => ({
        name: groupName,
        value: groupName,
        options: calendarGroups[groupName],
      }));

    // V2 has some small differences, which affects how we display a configuration
    const isNewVersion = version === InboundCalendarVersion.V2;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Modal.Header>
          <Modal.Title>{`Sync ${IntegrationName[integrationType]}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Label>
            {userCanEditSchedule
              ? `Customize how you’d like to sync your calendar and your schedule.`
              : `Send your scheduled tasks to a ${IntegrationName[integrationType]}.`}
          </Label>
          <EventsTimeZoneSelector
            value={ignoreCompanyTimezone}
            onChange={(value) =>
              this.setState({
                form: { ...this.state.form, ignoreCompanyTimezone: value },
              })
            }
          />
          {userCanEditSchedule && (
            <>
              <SectionHeading style={{ marginBottom: 25 }}>
                {IntegrationName[integrationType]}
              </SectionHeading>
              <Checkbox
                label="Send your calendar events to a Float project"
                style={{
                  fontSize: 16,
                  color: '#363d46',
                  marginBottom: 27,
                }}
                {...bindVal(this, 'inboundOn')}
              />
              {inboundOn && !!calendarList.length && (
                <VirtualSelect
                  label={IntegrationName[integrationType]}
                  height={200}
                  nonNullable
                  clearInputOnDropdownOpen={false}
                  groupedOptions={groupedCalendarList}
                  style={{ marginBottom: 30 }}
                  {...bindVal(this, 'inboundResourceId')}
                />
              )}
              {inboundOn && inboundResourceId && (
                <>
                  <VirtualSelect
                    label={'Import events'}
                    height={200}
                    nonNullable
                    clearInputOnDropdownOpen={false}
                    options={[
                      { value: true, label: 'All events' },
                      { value: false, label: 'Choose specific events' },
                    ]}
                    style={{ marginBottom: 30 }}
                    {...bindVal(this, 'importAllEvents')}
                  />
                  {!importAllEvents && (
                    <Input
                      label="Events containing..."
                      {...bindVal(
                        this,
                        'eventsFilterWord',
                        undefined,
                        Input.validators.regex(/^[a-z0-9]*$/i),
                      )}
                      onBlur={(e) => {
                        prevent(e);
                        this.validateFilterWord();
                      }}
                      style={{ marginBottom: 30 }}
                    />
                  )}
                  <Section>
                    <ProjectsDropdown
                      project={this.state.project}
                      projectsOptions={this.props.projectsOptions}
                      projectsMap={this.props.projectsMap}
                      canModifyProjects={this.canModifyProjects}
                      errors={this.state.errors.project}
                      manageModal={this.props.manageModal}
                      hasError={!isEmpty(this.state.errors)}
                      selectProject={this.selectProject}
                    />
                  </Section>
                  {isNewVersion && (
                    <Text>
                      Select a project, once imported you can reassign the event
                      to another project and optionally link to a task.
                    </Text>
                  )}
                  <Checkbox
                    label={
                      isNewVersion
                        ? 'Hide event titles from notes'
                        : 'Hide event titles and descriptions'
                    }
                    {...bindVal(this, 'hideTitleAndDesc')}
                  />
                </>
              )}
              <Hr style={{ margin: '27px 0 18px' }} />
              <SectionHeading style={{ marginBottom: 25, marginTop: 18 }}>
                Float
              </SectionHeading>
              <Checkbox
                label={`Send items from your schedule to a ${IntegrationName[integrationType]}`}
                style={{
                  fontSize: 16,
                  color: '#363d46',
                  marginBottom: 27,
                }}
                {...bindVal(this, 'outboundOn')}
              />
            </>
          )}
          {outboundOn && (
            <>
              <VirtualSelect
                label={'Float Tasks'}
                height={200}
                nonNullable
                clearInputOnDropdownOpen={false}
                options={[
                  {
                    value: 'confirmedOnly',
                    label: `All my confirmed allocations and time off`,
                  },
                  {
                    value: 'confirmedAndTentative',
                    label: `All my confirmed + tentative allocations and time off`,
                  },
                ]}
                style={{ marginBottom: 30 }}
                {...bindVal(this, 'outboundType')}
              />
              {outboundType &&
                (outboundNew ? (
                  <VirtualSelect
                    label={IntegrationName[integrationType]}
                    height={200}
                    nonNullable
                    clearInputOnDropdownOpen={false}
                    options={calendarList
                      .filter((c) => c.writable)
                      .sort((cal) => (cal.primary ? -1 : 1))}
                    creatable
                    disabled={isEditMode}
                    {...bindVal(this, 'outboundResourceId')}
                  />
                ) : (
                  <ReadOnlyField
                    style={{
                      maxWidth: '500px',
                      overflow: 'hidden',
                      display: 'block',
                      textOverflow: 'ellipsis',
                    }}
                    type="text"
                    label={IntegrationName[integrationType]}
                    value={outboundResourceName}
                  />
                ))}
              {outboundType && (
                <p
                  style={{
                    fontSize: '16px',
                    marginBottom: '30px',
                    marginTop: 20,
                    maxWidth: '442px',
                  }}
                >
                  Allocations and time off without a specific time will be
                  exported as all day events.
                </p>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Actions style={{ paddingTop: 13 }}>
          <Button
            type="submit"
            disabled={submitDisabled}
            loader={this.state.isSubmitting}
          >
            {isEditMode ? 'Update' : 'Save'}
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          {isEditMode && (
            <Button
              appearance="clear-danger"
              onClick={this.onDisconnect}
              style={{ marginLeft: 'auto' }}
            >
              Disconnect{' ' + IntegrationSourceName[integrationType]}
            </Button>
          )}
        </Modal.Actions>
      </form>
    );
  }

  render() {
    const { calendarList } = this.props.calendar;
    const { projectsOptions, onClose } = this.props;
    return (
      <Modal isOpen onClose={onClose}>
        {calendarList && calendarList.length && projectsOptions ? (
          this.renderForm()
        ) : (
          <Loader />
        )}
        {this.state.isRemoveModalOpen && (
          <IntegrationCalendarRemoveModal
            onClose={this.closeRemoveModal}
            onRemove={(deleteData) => this.confirmRemove(deleteData)}
            removeMode={this.state.removeMode}
          />
        )}
      </Modal>
    );
  }

  selectProject = (project) => {
    // We change the project, we must revert the task status
    const isTentative = this.isProjectOrPhaseTentative({ project });
    const prevIsTentative = this.isProjectOrPhaseTentative({
      project: this.project,
    });
    let { status } = this.props;
    if (isTentative !== prevIsTentative) {
      status = isTentative ? 1 : 2;
    }
    this.phaseManuallyChosen = false;
    this.setState({
      project,
      status,
      taskMetaId: null,
      clientName: project.client_name,
      clientId: project.client_id,
      errors: { ...this.props.errors, project: [] },
    });
  };
}

const mapStateToProps = (state) => {
  const user = getUser(state);
  return {
    calendar: state.integrations.calendar,
    projectsOptions: getCalendarProjectsOptions(state),
    peopleMap: getPeopleMap(state),
    projectsMap: getProjectsMap(state),
    defaultProject: getDefaultDropdownCalendarProject(state),
    projectPhases: getProjectPhases(state),
    user,
  };
};

const mapDispatchToProps = {
  ensureProjectsLoaded,
  fetchCalendarList,
  addEditExtCalendar,
  fetchBudget,
  manageModal,
};

export default modalManagerHoc({
  Comp: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withConfirm(IntegrationCalendarEdit)),
  modalType: 'integCalendarEditModal',
});
