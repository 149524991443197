import React, { forwardRef } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';
import { merge } from 'lodash';

import { IconPencilOutline } from '@float/ui/icons/essentials/IconPencilOutline';

import { BASE_TAG_THEME } from '../QuickInput.constants';
import {
  QuickInputSpacingVariants,
  QuickInputThemeOverrides,
} from '../QuickInput.types';
import { PopoutBorder } from './PopoutBorder';
import { Suffix } from './Suffix';

import * as styles from '../QuickInput.css';

const EDIT_ICON_SIZE = '20';

export interface QuickInputTagWrapperProps {
  children: React.ReactNode;
  disabled?: boolean;
  onWrapperClick: () => void;
  suffix?: string;
  themeOverrides?: QuickInputThemeOverrides;
  variant: QuickInputSpacingVariants;
}

export const QuickInputTagWrapper = forwardRef<
  HTMLDivElement,
  QuickInputTagWrapperProps
>(
  (
    { children, disabled, onWrapperClick, suffix, themeOverrides, variant },
    forwardedRef,
  ) => {
    const theme = merge(Object.create(null), BASE_TAG_THEME, themeOverrides);

    return (
      <div
        ref={forwardedRef}
        aria-disabled={disabled}
        className={styles.tagOuterWrapper}
        onClick={onWrapperClick}
        style={assignInlineVars(styles.themeVars, theme)}
      >
        <div
          className={cn(
            styles.tagContentWrapper,
            styles.paddingVariants[variant],
          )}
        >
          <div className={styles.tagOverflowWrapper}>
            <div className={styles.tagInnerWrapper}>{children}</div>
          </div>
          <Suffix>{suffix}</Suffix>
        </div>
        <IconPencilOutline size={EDIT_ICON_SIZE} className={styles.editIcon} />
        <PopoutBorder variant={variant} />
      </div>
    );
  },
);
