import { CurrentUser } from '@float/types/account';
import { Person } from '@float/types/person';

import { getWorkHours } from './getWorkHours';

export function getHasDifferentDailyWorkHoursInRange(
  dates: DatesManager,
  user: CurrentUser,
  startDate: string,
  endDate: string,
  people: Person[],
) {
  let workHoursValue = undefined;

  for (const person of people) {
    const start = dates.toNum(startDate);
    const end = dates.toNum(endDate);

    for (let dateNum = start; dateNum <= end; dateNum++) {
      const date = dates.fromNum(dateNum);

      const workHours = getWorkHours(dates, user, person, date);

      // Exclude non-working days
      if (workHours === 0) continue;

      if (typeof workHoursValue === 'undefined') workHoursValue = workHours;

      // There is at least one person with different daily working hours
      if (workHours !== workHoursValue) return true;
    }
  }

  return false;
}
