import React from 'react';
import { t } from '@lingui/macro';

import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';

import { useToggleSelectAllHandler } from './index.hooks';

export const TaskBulkCheckbox: React.FC = () => {
  const { onToggleSelectAll, someRowsSelected } = useToggleSelectAllHandler();
  return (
    <Checkbox
      semiChecked={someRowsSelected}
      onClick={onToggleSelectAll}
      label={t`Select all tasks`}
      hideLabel
    />
  );
};
