import React, { useEffect, useMemo } from 'react';
import { compact } from 'lodash';
import {
  getDepartmentOptions,
  getDepartments,
  getSubdepartments,
  getUser,
} from 'selectors';
import styled from 'styled-components';

import { Rights } from '@float/common/lib/acl';
import {
  getManagerAccess,
  getManagerAccessBit,
  getMemberAccess,
  setManagerAccess,
  setMemberAccess,
} from '@float/common/lib/acl/access';
import { useAppSelector } from '@float/common/store';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect/DropdownSelect';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import {
  IconArrowDownRight,
  IconForbidden,
  IconGlob,
  IconUser,
} from '@float/ui/deprecated/Earhart/Icons';
import EHIconEdit from '@float/ui/deprecated/Earhart/Icons/Icon/IconEdit';
import EHIconEye from '@float/ui/deprecated/Earhart/Icons/Icon/IconEye';
import EHIconFolder from '@float/ui/deprecated/Earhart/Icons/Icon/IconFolder';
import EHIconGlob from '@float/ui/deprecated/Earhart/Icons/Icon/IconGlob';
import IconBg from '@float/ui/deprecated/Earhart/Icons/IconBg';
import {
  TextToggle,
  TextToggleStyles,
} from '@float/ui/deprecated/Earhart/Toggles';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { Hr } from '@float/ui/deprecated/Hr/Hr';
import { Col, Row, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { MultiSelect } from '@float/ui/deprecated/MultiSelect';
import { IconBuilding } from '@float/ui/icons/essentials/IconBuilding';
import { IconUsers } from '@float/ui/icons/essentials/IconUsers';

const TextToggleField = styled(TextToggle)`
  ${TextToggleStyles.label} {
    min-width: 72px;
    text-align: center;
  }
`;

const AccessLabel = styled.span`
  ${Typography.Label18.M500};
  color: ${Colors.Core.Lt.Emp.High};
`;

const AccessLabelDescription = styled.span`
  ${Typography.Label12.R400};
  color: ${Colors.Core.Text.Default};
`;

const AccessDisplay = styled.span`
  display: inline-flex;
  align-items: center;
  ${Typography.Label14.SB600};
  color: ${Colors.TCore.Emp.Medium11};

  svg {
    --svg-icon-color: ${Colors.TCore.Emp.Medium11};
  }
`;

const AccessSectionRow = styled(Row)`
  min-height: 40px;
`;

const canSeeDepartmentId = (user, depId) => {
  const actorLimitedByDept = user.department_filter_all?.length > 0;
  if (!actorLimitedByDept) {
    return true;
  }

  return user.department_filter_all.includes(depId);
};

export const MultiDepartmentAccess = (props) => {
  const user = useAppSelector(getUser);

  const departments = useAppSelector(getDepartments);
  const departmentOptions = useAppSelector(getDepartmentOptions);
  const subDepartments = useAppSelector(getSubdepartments);
  const userDepartmentsIds = props.account.department_filter || [];

  const selectedDepartments = userDepartmentsIds
    .filter((depId) => canSeeDepartmentId(user, depId))
    .map((depId) => {
      // if the department name is not found is because it's a new department so
      // we fallback to the depId which will be the name of the department
      const label = departments[depId]?.name || depId;

      return {
        value: depId,
        label,
      };
    });

  const options = useMemo(() => {
    // if a parent department is selected, we shall hide sub-departments from the list
    const subDepartmentsToDelete = new Set();

    if (props.account.department_filter) {
      props.account.department_filter.forEach((depId) => {
        if (subDepartments[depId]) {
          subDepartments[depId].forEach((subDepId) => {
            subDepartmentsToDelete.add(subDepId);
          });
        }
      });
    }

    return departmentOptions
      .filter((option) => !subDepartmentsToDelete.has(option.value))
      .map((option) => {
        const department = departments[option.value];
        return {
          ...option,
          icon: department.parent_id && <IconArrowDownRight size={16} />,
        };
      });
  }, [
    props.account.department_filter,
    departments,
    departmentOptions,
    subDepartments,
  ]);

  return (
    <MultiSelect
      label="Departments"
      values={selectedDepartments}
      options={options}
      sort={false}
      visibleItems={6}
      // style={fieldStyle}
      errors={props.formErrors?.department_filter}
      onAdd={(o) => {
        props.onChange({
          department_filter: [...userDepartmentsIds, o.value],
        });
      }}
      onRemove={(o) => {
        props.onChange({
          department_filter: userDepartmentsIds.filter((id) => id !== o.value),
        });
      }}
    />
  );
};

export const MemberRightsControls = (props) => {
  const { access = 0 } = props.account;

  const memberAccess = getMemberAccess(props.account);
  const memberlimitedByDept = props.account?.department_filter_set;

  const user = useAppSelector(getUser);
  const actorLimitedByDept = user.department_filter?.length > 0;
  const initialAcc = props.initialPerson?.account;
  const initialCanViewEveryone =
    initialAcc &&
    (!initialAcc.department_filter || initialAcc.department_filter.length == 0);

  const canViewOptions = compact([
    (initialCanViewEveryone || !actorLimitedByDept) && EveryoneOption,
    DepartmentOption,
    !props.guestMode && ThemselfOption,
  ]);

  // cannot give what he doesn't have
  // can downgrade what he has

  const value = props.account.department_filter_set
    ? DepartmentOption.value
    : memberAccess.canView
      ? EveryoneOption.value
      : ThemselfOption.value;

  const onChange = (nextOption) => {
    switch (nextOption.value) {
      case EveryoneOption.value:
        props.onChange({
          department_filter: [],
          prev_department_filter: props.account?.department_filter,
          department_filter_set: false,
          access: setMemberAccess(access, 'canView', true),
        });
        break;
      case DepartmentOption.value:
        props.onChange({
          department_filter: props.account?.prev_department_filter || [],
          prev_department_filter: [],
          department_filter_set: true,
          access: setMemberAccess(access, 'canView', true),
        });
        break;
      case ThemselfOption.value:
        props.onChange({
          department_filter: [],
          prev_department_filter: props.account?.department_filter,
          department_filter_set: false,
          access: setMemberAccess(access, 'canView', false),
        });
        break;
    }
  };

  useEffect(() => {
    if (!props.guestMode) return;
    // if (props.account.account_id) return;

    const access = setMemberAccess(0, 'canView', true);
    props.onChange({
      access: access,
    });
  }, []); // eslint-disable-line

  return (
    <Col>
      <Col>
        <Row justifyContent="space-between" alignItems="center">
          <IconBg>
            <EHIconEye />
          </IconBg>
          <Spacer size={16} />
          <Col alignItems="flex-start">
            <AccessLabel>Can view</AccessLabel>
            <Spacer size={6} />
            <AccessLabelDescription>
              Who they can see on the schedule
            </AccessLabelDescription>
          </Col>
          <DropdownSelect
            appearance="default"
            withIconLeft={true}
            minSelectWidth={260}
            value={value}
            options={canViewOptions}
            onChange={onChange}
          />
        </Row>
        {memberlimitedByDept && (
          <>
            <Spacer size={16} />
            <Row>
              <MultiDepartmentAccess {...props} />
            </Row>
          </>
        )}
      </Col>
      {!props.guestMode && (
        <>
          <Hr style={{ margin: '16px 0px' }} />
          <AccessSectionRow justifyContent="space-between" alignItems="center">
            <IconBg>
              <EHIconEdit />
            </IconBg>
            <Spacer size={16} />
            <Col alignItems="flex-start">
              <AccessLabel>Can edit</AccessLabel>
              <Spacer size={6} />
              <AccessLabelDescription>
                Who they can edit on the schedule
              </AccessLabelDescription>
            </Col>
            <DropdownSelect
              value={
                memberAccess.canEdit ? ThemselfOption.value : NoOneOption.value
              }
              appearance="default"
              withIconLeft={true}
              minSelectWidth={260}
              options={[ThemselfOption, NoOneOption]}
              onChange={(nextOption) => {
                props.onChange({
                  access: setMemberAccess(
                    access,
                    'canEdit',
                    nextOption.value === ThemselfOption.value ? true : false,
                  ),
                });
              }}
            />
          </AccessSectionRow>
        </>
      )}
    </Col>
  );
};

export const ManagerRightsControls = (props) => {
  const { access = 0 } = props.account;

  const user = useAppSelector(getUser);
  const actorLimitedByDept = user.department_filter?.length > 0;

  const managerAccess = getManagerAccess(props.account);

  const createUpdatedAccess = (attribute, bool) => {
    return {
      access: setManagerAccess(access, attribute, bool),
    };
  };

  const canEnable = (attribute) => {
    return Rights.canUpdatePeople(user, {
      accessCheck: getManagerAccessBit(attribute),
      account: {
        ...props.account,
        ...createUpdatedAccess(attribute, true),
      },
    });
  };

  const initialAcc = props.initialPerson?.account;
  const initialCanViewEveryone =
    initialAcc &&
    (!initialAcc.department_filter || initialAcc.department_filter.length == 0);

  const editLimitedByDep = props.account?.department_filter_set;
  return (
    <Col>
      <Col>
        <Row justifyContent="space-between" alignItems="center">
          <IconBg>
            <EHIconEye />
          </IconBg>
          <Spacer size={16} />
          <Col alignItems="flex-start">
            <AccessLabel>Can view</AccessLabel>
            <Spacer size={6} />
            <AccessLabelDescription>
              Who they can see on the schedule
            </AccessLabelDescription>
          </Col>
          <DropdownSelect
            appearance="default"
            withIconLeft={true}
            minSelectWidth={260}
            value={
              editLimitedByDep ? DepartmentOption.value : EveryoneOption.value
            }
            options={compact([
              (initialCanViewEveryone || !actorLimitedByDept) && EveryoneOption,
              DepartmentOption,
            ])}
            onChange={(nextOption) => {
              switch (nextOption.value) {
                case DepartmentOption.value:
                  props.onChange({
                    department_filter_set: true,
                    department_filter:
                      props.account?.prev_department_filter || [],
                    prev_department_filter: [],
                  });
                  break;
                case EveryoneOption.value:
                  props.onChange({
                    department_filter_set: false,
                    department_filter: [],
                    prev_department_filter: props.account?.department_filter,
                  });
                  break;
              }
            }}
          />
        </Row>
        {editLimitedByDep && (
          <>
            <Spacer size={16} />
            <Row>
              {editLimitedByDep && <MultiDepartmentAccess {...props} />}
            </Row>
          </>
        )}
      </Col>
      {canEnable('isPeopleManager') && (
        <>
          <Hr style={{ margin: '16px 0px' }} />
          <AccessSectionRow justifyContent="space-between" alignItems="center">
            <IconBg>
              <IconUsers />
            </IconBg>
            <Spacer size={16} />
            <Col alignItems="flex-start">
              <AccessLabel>People Manager</AccessLabel>
              <Spacer size={6} />
              <AccessLabelDescription>
                Can schedule and approve time off requests
              </AccessLabelDescription>
            </Col>
            <TextToggleField
              name="people_manager"
              value={managerAccess.isPeopleManager ? 1 : 0}
              options={[
                { label: 'No', value: 0 },
                { label: 'Yes', value: 1 },
              ]}
              onChange={(o) => {
                props.onChange(createUpdatedAccess('isPeopleManager', o.value));
              }}
            />
          </AccessSectionRow>
        </>
      )}
      {canEnable('isProjectManager') && (
        <>
          <Hr style={{ margin: '16px 0px' }} />
          <AccessSectionRow justifyContent="space-between" alignItems="center">
            <IconBg>
              <EHIconFolder />
            </IconBg>
            <Spacer size={16} />
            <Col alignItems="flex-start">
              <AccessLabel>Project Manager</AccessLabel>
              <Spacer size={6} />
              <AccessLabelDescription>
                Can schedule tasks and manage projects
              </AccessLabelDescription>
            </Col>
            <TextToggleField
              name="project_manager"
              value={managerAccess.isProjectManager ? 1 : 0}
              options={[
                { label: 'No', value: 0 },
                { label: 'Yes', value: 1 },
              ]}
              onChange={(o) => {
                props.onChange(
                  createUpdatedAccess('isProjectManager', o.value),
                );
              }}
            />
          </AccessSectionRow>
        </>
      )}
      <Hr style={{ margin: '16px 0px' }} />
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <Col alignItems="flex-start">
          <AccessLabel>Additional permissions</AccessLabel>
          <Spacer size={16} />
          <Checkbox
            label="Create and edit people"
            value={managerAccess.canCreateEditPeople}
            onChange={(next) => {
              props.onChange(createUpdatedAccess('canCreateEditPeople', next));
            }}
          />
          {canEnable('canBudget') && (
            <>
              <Spacer size={12} />
              <Checkbox
                label="View everyone’s rates and their project budgets"
                value={managerAccess.canBudget}
                onChange={(next) => {
                  props.onChange(createUpdatedAccess('canBudget', next));
                }}
              />
            </>
          )}
        </Col>
      </AccessSectionRow>
    </Col>
  );
};

export const AdminViewRights = () => {
  return (
    <Col>
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <IconBg>
          <EHIconEye />
        </IconBg>
        <Spacer size={16} />
        <Col alignItems="flex-start">
          <AccessLabel>Can view</AccessLabel>
          <Spacer size={6} />
          <AccessLabelDescription>
            Who they can see on the schedule
          </AccessLabelDescription>
        </Col>
        <AccessDisplay>
          <Spacer xSize={8} />
          {EveryoneOption.label}
        </AccessDisplay>
      </AccessSectionRow>
      <Hr style={{ margin: '16px 0px' }} />
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <IconBg>
          <EHIconGlob />
        </IconBg>
        <Spacer size={16} />
        <Col alignItems="flex-start">
          <AccessLabel>Manages</AccessLabel>
        </Col>
        <AccessDisplay>
          <Spacer xSize={8} />
          {EveryThingOption.label}
        </AccessDisplay>
      </AccessSectionRow>
    </Col>
  );
};

export const BillingViewRights = () => {
  return (
    <Col>
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <IconBg>
          <EHIconEye />
        </IconBg>
        <Spacer size={16} />
        <Col alignItems="flex-start">
          <AccessLabel>Can view</AccessLabel>
          <Spacer size={6} />
          <AccessLabelDescription>
            Who they can see on the schedule
          </AccessLabelDescription>
        </Col>
        <AccessDisplay>
          <Spacer xSize={8} />
          {EveryoneOption.label}
        </AccessDisplay>
      </AccessSectionRow>
      <Hr style={{ margin: '16px 0px' }} />
      <AccessSectionRow justifyContent="space-between" alignItems="center">
        <IconBg>
          <EHIconGlob />
        </IconBg>
        <Spacer size={16} />
        <Col alignItems="flex-start">
          <AccessLabel>Manages</AccessLabel>
        </Col>
        <AccessDisplay>
          <Spacer xSize={8} />
          {EverythingBilling.label}
        </AccessDisplay>
      </AccessSectionRow>
    </Col>
  );
};

export const EveryoneOption = {
  label: 'Everyone',
  description: 'All users on account',
  icon: <IconGlob />,
  value: 1,
};

export const EveryThingOption = {
  label: 'All people, projects and settings',
  description: 'All people, projects and settings',
  value: 4,
  icon: <IconGlob />,
};

export const EverythingBilling = {
  label: 'Billing, all people, projects and settings',
  description: 'Billing, all people, projects and settings',
  value: 4,
  icon: <IconGlob />,
};

export const DepartmentOption = {
  label: 'Departments',
  description: 'Selected departments',
  icon: <IconBuilding />,
  value: 2,
};

export const ThemselfOption = {
  label: 'Themself',
  description: 'Just themself',
  value: 0,
  icon: <IconUser />,
};

export const NoOneOption = {
  label: 'No One',
  value: 3,
  description: 'No other people',
  icon: <IconForbidden />,
};
