import { sortBy, uniqBy } from 'lodash';

import { UNITS } from '@float/ui/deprecated/Chart/constants';

function createDatapointFromRaw(raw) {
  const dp = {
    date: raw.date,
    month: raw.month,
    week: raw.week,
    billable: 0,
    capacity: 0,
    nonbillable: 0,
    scheduled: 0,
    unscheduled: 0,
    tentative: {
      billable: 0,
      nonbillable: 0,
      timeoff: 0,
    },
    timeoff: 0,
    timeoff_days: 0,
    holiday: 0,
    holiday_days: 0,
    overtime: 0,
    logged: {
      billable: 0,
      nonbillable: 0,
      total: 0,
      overtime: 0,
    },
    future: {
      billable: 0,
      nonbillable: 0,
      scheduled: 0,
      overtime: 0,
      tentative: {
        billable: 0,
        nonbillable: 0,
        timeoff: 0,
      },
    },
    highlights: [],
  };

  return dp;
}

function createTotals() {
  return {
    capacity: 0,
    billable: 0,
    nonbillable: 0,
    feeCents: 0,
    tentative: {
      billable: 0,
      nonbillable: 0,
      timeoff: 0,
    },
    scheduled: 0,
    timeoff: 0,
    timeoff_days: 0,
    holiday: 0,
    holiday_days: 0,
    overtime: 0,
    unscheduled: 0,
    logged: {
      billable: 0,
      nonbillable: 0,
      total: 0,
      overtime: 0,
      unscheduled: 0,
      feeCents: 0,
    },
    combined: {
      billable: 0,
      nonbillable: 0,
      total: 0,
      overtime: 0,
      unscheduled: 0,
      feeCents: 0,
      tentative: {
        billable: 0,
        nonbillable: 0,
      },
    },
  };
}

// @test-export
export function cleanupHighlights(dp) {
  const uniqueHighlights = uniqBy(dp.highlights, (h) => {
    return h.milestone_id
      ? `ms:${h.milestone_id}`
      : h.holiday_id
        ? `h:${h.holiday_id}`
        : `tf:${h.timeoff_id}`;
  });

  dp.highlights = sortBy(uniqueHighlights, (x) => {
    const name = x.name || x.timeoff_notes;
    const start = x.start_date;
    const end = x.end_date;
    const prefix = x.milestone_id ? '1' : '0';
    return `${prefix}${start}${end}${name}`;
  });
}

export default function parseChartData(ctx, raw, highlights) {
  if (!raw)
    return {
      loggedTimeBoundaryIdx: 0,
      chartData: [],
      chartTotals: createTotals(),
    };

  const {
    dates,
    settings: { startDate, timeUnit },
    loggedTimeBoundary,
  } = ctx;
  const parsed = [];
  const totals = createTotals();

  let currentDp;
  let loggedTimeBoundaryIdx = -1;

  for (let i = 0; i < raw.length; i++) {
    const r = raw[i];

    if (r.date < startDate) continue;

    const h = highlights[r.date];

    // Find or create the appropriate datapoint for the record we're processing.
    // Effectively, this is how we're aggregating values for week/month.
    if (!currentDp || timeUnit === UNITS.DAY) {
      currentDp = createDatapointFromRaw(r);
      currentDp.tooltipStartDate = dates.inDDMMMYYYY(r.date);
      parsed.push(currentDp);
    }

    if (timeUnit === UNITS.WEEK) {
      if (currentDp.week !== r.week) {
        currentDp.tooltipEndDate = dates.inDDMMMYYYY(raw[i - 1].date);
        currentDp = createDatapointFromRaw(r);
        currentDp.tooltipStartDate = dates.inDDMMMYYYY(r.date);
        parsed.push(currentDp);
      }
    }

    if (timeUnit === UNITS.MONTH) {
      if (currentDp.month !== r.month) {
        currentDp.tooltipEndDate = dates.inDDMMMYYYY(raw[i - 1].date);
        currentDp = createDatapointFromRaw(r);
        currentDp.tooltipStartDate = dates.inDDMMMYYYY(r.date);
        parsed.push(currentDp);
      }
    }

    // We have to aggregate the highlights into the current datapoint in case
    // we're in week/month mode and there are multiple highlights to render.
    if (h && h.length) {
      currentDp.highlights.push(...h);
    }

    const scheduled =
      r.billable +
      r.nonbillable +
      r.tentative.billable +
      r.tentative.nonbillable;

    // Add the record's data to the current datapoint...
    currentDp.billable += r.billable + r.tentative.billable;
    currentDp.nonbillable += r.nonbillable + r.tentative.nonbillable;
    currentDp.tentative.billable += r.tentative.billable;
    currentDp.tentative.nonbillable += r.tentative.nonbillable;
    currentDp.tentative.timeoff += r.tentative.timeoff;
    currentDp.scheduled += scheduled;
    currentDp.capacity += r.capacity;
    currentDp.timeoff += r.timeoff.hours;
    currentDp.tentativeTimeoff += r.tentative.timeoff;
    currentDp.timeoff_days += r.timeoff.days;
    currentDp.holiday += r.holidays.hours;
    currentDp.holiday_days += r.holidays.days;

    // ... and add it to the totals as well
    totals.capacity += r.capacity;
    totals.billable += r.billable + r.tentative.billable;
    totals.nonbillable += r.nonbillable + r.tentative.nonbillable;
    totals.tentative.billable += r.tentative.billable;
    totals.tentative.nonbillable += r.tentative.nonbillable;
    totals.tentative.timeoff += r.tentative.timeoff;
    totals.scheduled += scheduled;
    totals.timeoff += r.timeoff.hours;
    totals.timeoff_days += r.timeoff.days;
    totals.holiday += r.holidays.hours;
    totals.holiday_days += r.holidays.days;

    // Sum up the fees as well if they exist (for project details summary/csv)
    if (r.fees) {
      totals.feeCents += r.fees.scheduled * 100;
    }

    currentDp.overtime += r.overtime.scheduled;
    currentDp.unscheduled = Math.max(
      0,
      currentDp.capacity + currentDp.overtime - currentDp.scheduled,
    );
    totals.overtime += r.overtime.scheduled;
    totals.unscheduled += Math.max(
      0,
      r.capacity + r.overtime.scheduled - scheduled + r.unassigned.scheduled,
    );

    // Aggregating the data requires potentially ignoring some loggedTime
    // records depending on the day/week/month setting. The general rule is that
    // we consider loggedTime up to the start of the current time unit.
    // Example: If the date range is July 7 - Sept 30, and today's date is
    // August 10th, and we're in month mode, we would want to remove any logged
    // time records from August 1st onwards.
    //
    // Additionally, note that we build the "combined" totals here as well based
    // on the above statement. We can do this because loggedTimeBoundary will
    // always be the start of a time unit.
    if (r.date >= loggedTimeBoundary) {
      if (loggedTimeBoundaryIdx === -1 && startDate < loggedTimeBoundary) {
        // This is the first record to ignore logged time from
        loggedTimeBoundaryIdx = parsed.length - 1;
      }

      totals.combined.billable += r.billable + r.tentative.billable;
      totals.combined.nonbillable += r.nonbillable + r.tentative.nonbillable;
      totals.combined.tentative.billable += r.tentative.billable;
      totals.combined.tentative.nonbillable += r.tentative.nonbillable;
      totals.combined.total += scheduled;

      totals.combined.overtime += r.overtime.scheduled;
      totals.combined.unscheduled += Math.max(
        0,
        r.capacity + r.overtime.scheduled - scheduled,
      );

      currentDp.future.billable += r.billable + r.tentative.billable;
      currentDp.future.nonbillable += r.nonbillable + r.tentative.nonbillable;
      currentDp.future.tentative.billable += r.tentative.billable;
      currentDp.future.tentative.nonbillable += r.tentative.nonbillable;
      currentDp.future.scheduled += scheduled;
      currentDp.future.overtime += r.overtime.logged;

      if (r.fees) {
        totals.combined.feeCents += r.fees.scheduled * 100;
      }
    } else {
      const logged = r.logged.billable + r.logged.nonbillable;

      if (r.fees) {
        totals.logged.feeCents += r.fees.logged * 100;
      }

      if (r.logged) {
        totals.logged.billable += r.logged.billable;
        totals.logged.nonbillable += r.logged.nonbillable;
        totals.logged.total += logged;
      }

      currentDp.logged.billable += r.logged.billable;
      currentDp.logged.nonbillable += r.logged.nonbillable;
      currentDp.logged.total += logged;
      currentDp.logged.overtime += r.overtime.logged;

      totals.combined.billable += r.logged.billable;
      totals.combined.nonbillable += r.logged.nonbillable;
      totals.combined.total += logged;

      totals.logged.overtime += r.overtime.logged;
      totals.combined.overtime += r.overtime.logged;

      totals.logged.unscheduled += Math.max(
        0,
        r.capacity + r.overtime.logged - logged + r.unassigned.logged,
      );
      totals.combined.unscheduled += Math.max(
        0,
        r.capacity + r.overtime.logged - logged + r.unassigned.logged,
      );

      if (r.fees) {
        totals.combined.feeCents += r.fees.logged * 100;
      }
    }
  }

  if (currentDp && timeUnit !== UNITS.DAY) {
    currentDp.tooltipEndDate = dates.inDDMMMYYYY(raw[raw.length - 1].date);
  }

  parsed.forEach((dp) => {
    cleanupHighlights(dp);
  });

  return { loggedTimeBoundaryIdx, chartData: parsed, chartTotals: totals };
}
