import React from 'react';
import { Trans } from '@lingui/macro';

import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import { OnOffToggle } from '@float/ui/deprecated/Earhart/Toggles/OnOffToggle';

import { useProjectCodesController } from './useProjectCodesController';

import * as styles from './styles.css';

const ProjectCodesSection = ({
  header,
  subheader,
}: {
  header: string;
  subheader: string;
}) => {
  const { isProjectCodesEnabled } = useProjectCodesPreference();
  const { toggleProjectCodes, isProjectCodesEnabledInternal } =
    useProjectCodesController({ isProjectCodesEnabled });

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h3>{header}</h3>

        <OnOffToggle
          isOn={isProjectCodesEnabledInternal}
          onChange={toggleProjectCodes}
        />
      </div>
      <p>
        {subheader}
        <span>
          &nbsp;
          <a
            href="https://support.float.com/en/articles/4806377-projects#h_7a33bfe4a1"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <Trans>Learn more</Trans>
          </a>
        </span>
      </p>
    </div>
  );
};

export { ProjectCodesSection };
