import React from 'react';
import { t } from '@lingui/macro';
import { difference, get, isArray } from 'lodash';
import { formatDomains } from 'manage/people-v2/_helpers';
import { DiscList } from 'manage/people-v2/styles';

import { copyToClipboard } from '@float/common/lib/copyToClipboard';
import { getDateString } from '@float/common/lib/utils';
import { searchService } from '@float/common/search/service';
import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';
import EHIconAlert from '@float/ui/deprecated/Earhart/Icons/Icon/IconAlert';
import { getErrors } from '@float/ui/deprecated/helpers/forms';
import { measureInteractivity } from '@float/web/perfMonitoring/interactivity';
import { showTimeToUpgradeConfirmModal } from 'components/modals/TimeToUpgrade';

import {
  checkIsPeopleManager,
  getStateDeltaOnAccessChange,
} from './access/PersonAccess';
import { removeExampleAvatar } from './helpers/removeExampleAvatar';
import { PersonActionsMenu } from './PersonActionsMenu';
import { toggleArchiveStatus } from './personModalActions.helpers';

let self;

function getDepartmentFilterErrors(self) {
  const { form } = self.state;
  const { parentDepartments } = self.props;

  const { department_id } = form;
  const account = form.account || {};
  const { department_filter, department_filter_set } = account;
  const departmentFilterCount = department_filter?.length;
  const errors = [];
  if (department_filter_set) {
    if (!departmentFilterCount) {
      errors.push(`Please select one or more departments.`);
      return errors;
    }

    if (department_id && !self.state.form.isAddingNewDepartment) {
      // If user is part of a department, the user should
      // always be able to view it's own department
      // or a parent department ( which includes user's department ).
      const canSeeOwnDepartment = [department_id]
        .concat(parentDepartments[department_id])
        .some((departmentId) =>
          department_filter.includes(parseInt(departmentId)),
        );
      if (!canSeeOwnDepartment) {
        errors.push(`View access needed to user's current department.`);
        return errors;
      }
    }
  }

  return errors;
}

function validate() {
  const { form } = self.state;
  const account = form.account || {};
  const { currentUser } = self.props;
  const isGuest = !currentUser.people_id;
  const isUserAcctOwner = Number(currentUser.account_tid) === 1;
  const isAddingAcctOwnerPerson = currentUser.email === form.email;
  const isGuestAccountOwnerTryingToGainAccess =
    isUserAcctOwner && isGuest && isAddingAcctOwnerPerson;

  const newState = {};
  const hasAccessRights = !!account.account_type;
  const emailErrors = getErrors.email(form.email, {
    currentUserAccount: self.props.modalSettings.person,
    existingAccounts: self.props.accounts,
    existingPeople: self.props.people,
    isRequired: hasAccessRights,
    skipDuplicateCheck: isGuestAccountOwnerTryingToGainAccess,
  });
  const defaultHourlyRateErrors = getErrors.amount(form.default_hourly_rate);
  const departmentFilterErrors = getDepartmentFilterErrors(self);

  newState.formErrors = {
    email: emailErrors,
    default_hourly_rate: defaultHourlyRateErrors,
    department_filter: departmentFilterErrors,
  };

  if (!(form.name || '').trim()) {
    newState.form = { ...form, name: '* New Person' };
  }

  if (isGuestAccountOwnerTryingToGainAccess) {
    const accountState = getStateDeltaOnAccessChange(1);
    newState.form = newState.form
      ? { ...newState.form, ...accountState }
      : { ...form, ...accountState };
  }

  self.setState(newState);
  return (
    !emailErrors.length &&
    !defaultHourlyRateErrors.length &&
    !departmentFilterErrors.length
  );
}

function onSaveError(errs) {
  errs = isArray(errs) ? errs : [errs];

  errs.forEach((err) => {
    if (err.field && err.field === '*' && err.message.includes('plan limit')) {
      self.setFormErrors({
        personType: [err.message],
      });
    } else if (err.field && err.field.includes('email') && err.message) {
      self.setFormErrors({
        email: [
          ['in use', 'exists', 'taken'].some((msg) => err.message.includes(msg))
            ? 'Email already in use on this team. Please try another.'
            : err.message,
        ],
      });
    } else if (
      err.Account &&
      err.Account.includes('Unable to create account')
    ) {
      self.setFormErrors({
        email: [
          `${formatDomains(self.props.companyPrefs.domains)} account required`,
        ],
      });
    } else if (err.field === 'default_hourly_rate') {
      self.setFormErrors({
        default_hourly_rate: ['Invalid amount.'],
      });
    } else {
      if (err.field && err.message) {
        return self.setFormErrors({
          [err.field]: [err.message],
        });
      }

      if (err && err.message) {
        return self.showMessage(err.message);
      }

      const isErrorObject = Object.keys(err).every((key) => {
        return Array.isArray(err[key]);
      });

      if (isErrorObject) {
        return self.setFormErrors(
          Object.keys(err).reduce((acc, key) => {
            const message = err[key];
            acc[key] = message;
            return acc;
          }, {}),
        );
      }

      return self.showMessage('An error occurred.');
    }
  });
}

function getProjectsUpdateRecords() {
  const oldProjectIds = self.props.modalSettings.person.project_ids;
  const newProjectIds = self.state.form.project_ids;
  const add = difference(newProjectIds, oldProjectIds);
  const del = difference(oldProjectIds, newProjectIds);

  return {
    add,
    del,
  };
}

async function triggerSave(newPerson) {
  self.setState({ isUpdating: true });

  // The object passed in is part of the React component state (formData).
  // Since we need to adjsut it for the point in time work hours changes, we
  // need to clone it.
  newPerson = { ...newPerson };

  if (self.state.workDaysHours.allDays) {
    newPerson.work_days_hours = self.state.workDaysHours.allDays;
  } else if (self.state.workDaysHours.history) {
    delete newPerson.work_days_hours;
    const historyAsObj = self.state.workDaysHours.history.reduce((acc, h) => {
      acc[h[0]] = h[1];
      return acc;
    }, {});
    newPerson.work_days_hours_history = historyAsObj;
  }

  const hasAccount = newPerson.account;
  if (hasAccount) {
    const isDepartmentFilterSet =
      newPerson.account.department_filter?.length > 0;
    newPerson.account.department_filter_set = isDepartmentFilterSet;
    const isPeopleManager = checkIsPeopleManager(newPerson.account);
    if (!isPeopleManager) {
      newPerson.management_group = {
        people: [],
        departments: [],
      };
    }
  }

  let person_id = null;
  try {
    person_id = await self.props.actions.savePerson(
      newPerson,
      getProjectsUpdateRecords(),
    );
  } catch (e) {
    self.setState({ isUpdating: false });
    onSaveError(e);
    return;
  }

  if (person_id) {
    await Promise.all([
      removeExampleAvatar(
        self.props.modalSettings.person,
        newPerson,
        self.header?.personAvatar.state.avatar,
        self.props.dispatch,
      ),
      self.header?.personAvatar?.save({ person_id }),
    ]);
  }

  self.showMessage(
    `${newPerson.name || '* New Person'} ${
      self.isAdding() ? 'added' : 'updated'
    }.`,
  );

  self.close();
}

function getStartEndDateChangeWarning(person, newPerson) {
  const previousStartDate = person.start_date;
  const previousEndDate = person.end_date;
  const postponedStartDate =
    newPerson.start_date &&
    (!previousStartDate || newPerson.start_date > previousStartDate) &&
    getDateString(newPerson.start_date);
  const preponedEndDate =
    newPerson.end_date &&
    (!previousEndDate || newPerson.end_date < previousEndDate) &&
    getDateString(newPerson.end_date);

  if (postponedStartDate || preponedEndDate) {
    if (postponedStartDate && preponedEndDate) {
      return `Are you sure? Any allocations scheduled before ${postponedStartDate} or after ${preponedEndDate} will be deleted forever.`;
    }

    if (postponedStartDate) {
      return `Are you sure? Any allocations scheduled before ${postponedStartDate} will be deleted forever.`;
    }

    if (preponedEndDate) {
      return `Are you sure? Any allocations scheduled after ${preponedEndDate} will be deleted forever.`;
    }
  }

  return null;
}

async function availabilityChangeWarning(newPerson) {
  if (self.isAdding() || !(await self.hasTasks())) {
    return false;
  }

  const { person } = self.props.modalSettings;

  const showWorkDaysWarning = self.state.workDaysHours.hasNonWorkDayBeenAdded;

  const startEndDateWarning = getStartEndDateChangeWarning(person, newPerson);
  const showWarning = showWorkDaysWarning || startEndDateWarning;

  if (showWarning) {
    self.props.confirmDelete({
      title: 'Update availability',
      message: (
        <p>
          {startEndDateWarning ||
            'Are you sure? Removing work days means that any allocations that were scheduled on these days will also be removed.'}
        </p>
      ),
      twoStep: true,
      deleteLabel: 'Update',
      confirmationLabel: 'Click to confirm you want to proceed',
      confirmationIcon: <EHIconAlert />,
      onDelete: () => triggerSave(newPerson),
    });
  }

  return showWarning;
}

async function save(newPerson) {
  if (await availabilityChangeWarning(newPerson)) {
    return;
  }

  const interactionId = newPerson.people_id ? 'UPDATE_PERSON' : 'ADD_PERSON';

  measureInteractivity.start(interactionId);
  await triggerSave(newPerson);
  measureInteractivity.complete(interactionId);
}

function toggleArchiveClick() {
  if (validate()) {
    setTimeout(
      () =>
        toggleArchiveStatus({
          companyPrefs: self.props.companyPrefs,
          confirm: self.props.confirm,
          newPerson: self.state.form,
          onLimitReached: () => showTimeToUpgradeConfirmModal(self.props),
          personNameElement: self.personName,
          triggerSave,
        }),
      0,
    );
  }
}

async function deletePerson(evt) {
  preventDefaultAndStopPropagation(evt);
  const { person } = self.props.modalSettings;
  const { currentUser } = self.props;
  const isOwnerDeletingSelf =
    get(person, 'account.account_id') === Number(currentUser.admin_id) &&
    Number(currentUser.account_tid) === 1;

  const peopleWithTasks = await searchService.getSelectorValue(
    self.props.store.getState(),
    'getPeopleWithTasks',
    [],
  );

  const impact = peopleWithTasks.has(person.people_id);

  const title = isOwnerDeletingSelf
    ? 'Remove yourself from schedule'
    : 'Delete Person';
  const message = isOwnerDeletingSelf ? (
    <p>
      Deleting yourself from the schedule will delete all your scheduled and
      logged allocations. You will keep your account access rights and appear as
      a Guest in the Account Settings.
    </p>
  ) : (
    <>
      <p>
        Archived people do not contribute to your subscription fee. You can
        restore Archived people on the People page.
      </p>
      <p>
        Deleting <strong>{person.name}</strong> will:
        <DiscList>
          <li>
            Delete their currently assigned allocations across all projects
          </li>
          <li>
            Delete their previously logged hours against allocations across all
            projects
          </li>
        </DiscList>
      </p>
      <p>Deleted people cannot be restored.</p>
    </>
  );

  self.props.confirmDelete({
    title,
    message,
    twoStep: impact,
    onCancel: () => {
      if (self && self.personName) {
        self.personName.focusInput();
      }
    },
    onDelete: () => {
      return self.props.actions.deletePerson(person.people_id).then(() => {
        self.showMessage(`${person.name} deleted.`);
        self.close();
      });
    },
    onArchive: !person.active || !impact ? undefined : toggleArchiveClick,
  });
}

export const savePerson = function (evt) {
  preventDefaultAndStopPropagation(evt);
  if (!self) {
    self = this;
  }

  if (self.noChangesMade() && !self.isAdding()) {
    self.close();
    return;
  }

  if (validate()) {
    setTimeout(() => save(self.state.form), 0);
  }
};

export default function renderPersonModalActions(that) {
  self = that;
  const { notesFetched, form } = self.state;
  const hasAccount = !!get(form, 'account.account_id');
  const isAdding = self.isAdding();
  const isCreatingAccount = get(form, 'account.account_type') && !hasAccount;

  // CS-800
  // Show 'Update & invite person' when user is editing email
  // of a pre-existing account that hasn't been accepted yet
  let emailChangedAndAccountIsNotAccepted = false;
  if (hasAccount) {
    const currentEmail = form.email;
    const previousEmail = form.account.email;
    const accountAccepted = form.account.accepted;
    emailChangedAndAccountIsNotAccepted =
      currentEmail !== previousEmail && !accountAccepted;
  }

  const saveLabel = isAdding
    ? `Add${isCreatingAccount ? ' & invite ' : ' '}person`
    : `Update${
        isCreatingAccount || emailChangedAndAccountIsNotAccepted
          ? ' & invite '
          : ' '
      }person`;

  async function handleCopyICalLink(link) {
    const success = await copyToClipboard(link);

    if (success) {
      self.showMessage(t`iCal schedule link copied to clipboard.`);
    } else {
      self.showMessage(t`Clipboard access denied.`);
    }
  }

  return (
    <>
      <Button
        type="submit"
        loader={self.state.isUpdating}
        disabled={!notesFetched || self.state.isUpdating}
      >
        {saveLabel}
      </Button>
      <Button
        appearance="secondary"
        style={{ marginRight: 0 }}
        onClick={self.close}
      >
        Cancel
      </Button>
      {!isAdding && (
        <PersonActionsMenu
          currentUser={self.props.currentUser}
          person={self.props.modalSettings.person}
          ical={self.state.ical}
          onActiveChange={toggleArchiveClick}
          onCopyICalLink={handleCopyICalLink}
          onDelete={deletePerson}
        />
      )}
    </>
  );
}
