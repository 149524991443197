import React, { useEffect, useState } from 'react';

import API3 from '@float/common/api3';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Card } from '@float/ui/deprecated/Earhart/Cards';

import Table from '../../Table';
import { StyledRow } from '../../Timeoffs/styles';
import CustomPublicHolidaysModal from './CustomPublicHolidaysModal';
import { findLocation, PublicHolidaysModal } from './PublicHolidaysModal';

function formatLocation([country, region]) {
  return `${country.label}, ${region.label}`;
}

export function PublicHolidaysTable() {
  const [openPublicHoliday, setOpenPublicHoliday] = useState();
  const [customHolidayModalProps, setCustomHolidayModalProps] = useState();
  const [holidays, setHolidays] = useState([]);
  const [countries, setCountries] = useState({ countries: [] });

  async function getHolidays() {
    return API3.getPublicHolidays();
  }

  async function getCountries() {
    return import('./regions').then((module) => {
      let defaultCountry;
      let defaultRegions;
      const newCountries = module.staticRegions.map((country) => {
        const countryWithValueData = {
          ...country,
          value: country.code,
          label: country.country,
        };
        countryWithValueData.regions = countryWithValueData.locations.map(
          (location) => ({
            value: location.code,
            label: location.location,
          }),
        );

        if (country.code === 'US') {
          defaultCountry = countryWithValueData;
          defaultRegions = countryWithValueData.regions;
        }
        return countryWithValueData;
      });

      return { countries: newCountries, defaultCountry, defaultRegions };
    });
  }

  useEffect(() => {
    let mounted = true;

    getCountries().then((data) => {
      if (mounted) {
        setCountries(data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    if (!openPublicHoliday) {
      getHolidays().then((data) => {
        if (mounted) {
          setHolidays(data);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [openPublicHoliday]);

  if (!countries.countries.length) return null;

  return (
    <Card>
      {openPublicHoliday && (
        <PublicHolidaysModal
          {...openPublicHoliday}
          {...countries}
          onClose={(needsUpdate) => {
            setOpenPublicHoliday();
            if (needsUpdate) {
              getHolidays();
            }
          }}
          setCustomHolidayModalProps={setCustomHolidayModalProps}
        />
      )}

      {customHolidayModalProps && (
        <CustomPublicHolidaysModal {...customHolidayModalProps} />
      )}
      <StyledRow>
        <h4>Public holidays</h4>
        <Button onClick={() => setOpenPublicHoliday({})}>Select</Button>
      </StyledRow>

      <StyledRow>
        <p>
          Select and manage your team’s regional public holidays. These days
          will be blocked out in the schedule for those assigned to a region
          automatically each year.
        </p>
      </StyledRow>

      <StyledRow>
        <Table
          headNames={['Name', 'Region', 'Days', 'People']}
          recordNames={['public holiday', 'public holidays']}
          rows={holidays.map(
            ({
              name,
              country_code,
              location_code,
              holiday_ids,
              people_ids,
              region_id,
            }) => ({
              data: [
                name,
                formatLocation(
                  findLocation(
                    countries.countries,
                    country_code,
                    location_code,
                    name,
                  ),
                ),
                holiday_ids.length,
                people_ids.length,
              ],
              id: region_id,
            }),
          )}
          onClickEdit={(row) => {
            setOpenPublicHoliday(
              holidays.find((holiday) => holiday.region_id === row.id),
            );
          }}
          rowActions={[
            {
              id: 'edit',
              label: 'Edit',
            },
          ]}
          columnsWidth={['30%', '30%', '17.2%', '14.4%']}
        />
      </StyledRow>
    </Card>
  );
}
