import React, { StrictMode, useRef } from 'react';
import { Plural, Trans } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';

import { SeatCapacity } from '@float/common/lib/hooks/useSeatCapacity';
import { Button } from '@float/ui/deprecated/Button/Button';
import { IconPlus } from '@float/ui/icons/essentials/IconPlus';
import { AnimateListItem } from '@float/ui/primitives/Animate/AnimateListItem/AnimateListItem';
import { AnimatePopUp } from '@float/ui/primitives/Animate/AnimatePopUp/AnimatePopUp';

import { AddFormWarning } from '../AddFormWarning/AddFormWarning';
import { InputItem } from '../InputItem/InputItem';
import { useInputList } from './hooks/useInputList';
import { useScrollElementIntoView } from './hooks/useScrollElementIntoView';
import { useSubmit } from './hooks/useSubmit';

import * as styles from './styles.css';

export type AddFormProps = {
  onClose: () => void;
  onChange: (isDirty?: boolean) => void;
  seatCapacity: SeatCapacity;
};

export const AddForm = (props: AddFormProps) => {
  const { onClose, onChange, seatCapacity } = props;

  const warningRef = useRef<HTMLDivElement>(null);
  const addFormWrapperRef = useRef<HTMLDivElement>(null);

  const { scrollElementIntoView } = useScrollElementIntoView();

  const onAddInput = (
    newInputCount: number,
    fromClipboard: boolean = false,
  ) => {
    if (fromClipboard) {
      scrollElementIntoView(addFormWrapperRef.current);
    } else if (!seatCapacity.canAddSeats(newInputCount)) {
      scrollElementIntoView(warningRef.current);
    }
  };

  const {
    addInput,
    inputCount,
    inputListState,
    onInputChange,
    onInputKeyDown,
    removeInput,
    setInputRef,
    addMethod,
  } = useInputList(seatCapacity, onAddInput, onChange);

  const { submit, isSubmitting } = useSubmit(
    inputListState,
    seatCapacity.seatsAvailable,
    onClose,
    onChange,
    addMethod,
  );

  return (
    <StrictMode>
      <motion.div
        className={styles.addFormWrapper}
        layout="position"
        ref={addFormWrapperRef}
      >
        <h1 className={styles.h1}>
          <Trans>Add team members</Trans>
        </h1>
        <h2 className={styles.h2}>
          <Trans>
            Enter anyone you'd like to schedule. You can invite them and add
            more details later.
          </Trans>
        </h2>

        <motion.form className={styles.form} layout="position">
          <p className={styles.p}>
            <Trans>
              Copy & paste up to <strong>25 people</strong> at once from a list
              or spreadsheet.
            </Trans>
          </p>

          <div className={styles.formInputList}>
            <AnimatePresence initial={false} mode="popLayout">
              {inputListState.map((inputItemState, index, total) => {
                return (
                  <AnimateListItem
                    className={styles.animatedListItem}
                    key={inputItemState.id}
                  >
                    <InputItem
                      disableRemove={total.length <= 1}
                      index={index}
                      inputItemState={inputItemState}
                      onChange={onInputChange}
                      onKeyDown={onInputKeyDown}
                      onClickRemove={removeInput}
                      ref={setInputRef}
                    />
                  </AnimateListItem>
                );
              })}
            </AnimatePresence>

            <motion.div layout="position">
              <Button
                appearance="clear"
                disabled={!seatCapacity.canAddSeats(inputCount.total)}
                icon={IconPlus}
                onClick={addInput}
                type="button"
              >
                <Trans>Add another</Trans>
              </Button>
            </motion.div>
          </div>

          <motion.div
            className={styles.seatCapacityWarning}
            ref={warningRef}
            layout="position"
          >
            {!seatCapacity.canAddSeats(inputCount.total) && (
              <AnimatePopUp>
                <AddFormWarning seatsAvailable={seatCapacity.seatsAvailable} />
              </AnimatePopUp>
            )}
          </motion.div>

          <motion.div className={styles.formActions} layout="position">
            <Button
              type="button"
              disabled={inputCount.valid === 0}
              onClick={submit}
              loader={isSubmitting}
            >
              <Plural
                value={inputCount.valid}
                _0={'Add people'}
                one={'Add # person'}
                other={'Add # people'}
              />
            </Button>

            <Button type="button" appearance="secondary" onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
          </motion.div>
        </motion.form>
      </motion.div>
    </StrictMode>
  );
};
