import React, { useEffect, useRef } from 'react';
import Flip from 'gsap/Flip';

import { StyledOverlay } from './styles';

export const StepOverlay = (props) => {
  const { className, anchor } = props;

  const { rect } = anchor || {};

  const { top, left, width, height } = rect || {};

  const ref = useRef();

  useEffect(() => {
    const delay = 0.25;

    if (top && left && width && height) {
      ref.current.classList.add('with-overlay');

      // animate from left to right on first highlight
      if (!ref.current.style.top) {
        ref.current.style.top = `${top}px`;
        ref.current.style.left = `-${width}px`;
        ref.current.style.width = `${width}px`;
        ref.current.style.height = `${height}px`;
      }

      // FLIP transition from current to next step
      const state = Flip.getState(ref.current);

      ref.current.style.top = `${top}px`;
      ref.current.style.left = `${left}px`;
      ref.current.style.width = `${width}px`;
      ref.current.style.height = `${height}px`;

      Flip.from(state, {
        duration: 0.5,
        clear: true,
        delay,
        ease: 'power3.inOut',
      });
    } else if (ref.current.style.top) {
      ref.current.style = null;
      ref.current.classList.remove('with-overlay');
    }
  }, [top, left, width, height]);

  return <StyledOverlay ref={ref} className={className} />;
};
