import styled, { css } from 'styled-components';

import { Core, FIN } from '@float/common/earhart/colors';
import { CheckboxLabel } from '@float/ui/deprecated/Checkbox/styles';
import { StyledDropdownSelect } from '@float/ui/deprecated/DropdownSelect/styles';
import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Elevations from '@float/ui/deprecated/Earhart/Elevations';
import * as Scrollbar from '@float/ui/deprecated/Earhart/Scrollbar';
import * as TextM from '@float/ui/deprecated/Earhart/Typography/TextM';
import { GridItemText } from '@float/ui/deprecated/Grid';
import { Grid, GridItem } from '@float/ui/deprecated/Grid/styles';
import { Modal, ModalBody, ModalHeader } from '@float/ui/deprecated/Modal';
import { StyledModalBody } from '@float/ui/deprecated/Modal/ModalBody/styles';
import {
  StyledModalDialog,
  StyledModalScrollingContainer,
} from '@float/ui/deprecated/Modal/ModalDialog/styles';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

export const TemplateBlockBase = styled(GridItem)`
  border-radius: 6px;
  padding: 0;
`;

export const ProjectBlockNew = styled(TemplateBlockBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 126px;
  border: 1px dashed #2e5fe8;
  border-radius: ${({ isColumn }) => (isColumn ? '12px' : '6px')};
  cursor: pointer;

  &:hover {
    & {
      border: 1px dashed #3551ae;
    }
    ${GridItemText} {
      color: #3551ae;
    }

    svg path {
      stroke: #3551ae;
    }
  }
`;

export const ProjectBlockNewText = styled(GridItemText)``;

export const TemplateBlockItemTitle = styled.h2`
  color: ${(p) => p.theme.charcoalGrey};
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  word-break: break-word;
  hyphens: auto;
  letter-spacing: -0.2px;
  ${({ isColumn }) =>
    !isColumn &&
    css`
      padding-right: 26px;
    `}
`;

export const TemplateBlockLabel = styled.h2`
  ${TextM.M500};
  color: ${FIN.Lt.Emphasis.Medium};
  margin-top: 4px;
  text-align: center;
`;

export const TemplateModalHeader = styled(ModalHeader)`
  justify-content: space-between;
  padding: 24px 30px 6px 30px;
`;

export const TemplateBlockItemAuthor = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TemplateBlockItemAuthorSpan = styled.span`
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  color: ${Core.Text.Secondary};
`;

export const TemplateBlockItemAuthorInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span {
    font-weight: 500;
    font-size: 13px;
    margin-left: 6px;
  }
`;

export const TemplateBlockItemAuthorInfoLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  flex: 1 1 auto;
  height: 100%;
`;

export const TemplateBlockItemActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  button + button {
    margin-left: 8px;
  }

  ${({ isEditable }) =>
    isEditable &&
    css`
      button {
        flex-grow: 1;
      }
    `}

  ${({ isColumn }) =>
    isColumn &&
    css`
      position: absolute;
      right: 20px;
      width: auto;
      height: 100%;
      top: 0;
      justify-content: center;
      align-items: center;

      button {
        box-shadow: 0px 0px 15px 15px #ffffff;
      }
    `}
`;

export const TemplateContent = styled.div`
  position: relative;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  padding: 12px 20px;

  overflow: hidden;

  z-index: 1;

  &:after {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    background-color: ${FIN.Lt.Emphasis.White};

    content: '';

    z-index: -1;

    transform: translate3d(0, 0, 0);

    transition-property: transform;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    pointer-events: none;
  }
`;

export const TemplateBlockItem = styled(TemplateBlockBase)`
  ${Elevations.E150};

  position: relative;

  display: flex;

  &:before,
  &:after {
    position: absolute;

    top: 0;
    left: -4px;

    width: calc(100% + 3px);
    height: 100%;

    border-radius: 6px;

    content: '';

    z-index: 0;

    transition-property: opacity;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    pointer-events: none;
  }

  &:before {
    width: 12px;
    background-color: ${(p) => p.color};
  }

  &:after {
    ${Elevations.E400};

    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 0.5;
    }

    ${TemplateContent} {
      &:after {
        transform: translate3d(3px, 0, 0);
      }
    }
  }

  ${({ isColumn }) =>
    isColumn
      ? css`
          min-height: 72px;

          ${TemplateContent} {
            justify-content: center;
          }
        `
      : css`
          height: 126px;
        `};

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
        `
      : ''};

  ${({ isEditable }) =>
    isEditable
      ? ''
      : css`
          pointer-events: none;
        `};

  h3 {
    color: ${(p) => p.theme.blueGrey};
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
  }

  & ${TemplateBlockItemActions} {
    display: none;
  }

  ${({ shouldShowActions }) =>
    shouldShowActions &&
    css`
      &:hover ${TemplateBlockItemAuthor} {
        display: none;
      }

      &:hover ${TemplateBlockItemActions} {
        display: flex;
      }
    `};
`;

export const TemplateBlockItemHeader = styled.header`
  position: relative;
  display: flex;
  flex-flow: row;
  width: 100%;
`;

export const TemplateModalBody = styled(ModalBody)``;

export const TemplateBlockPersonLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  font-size: 13px;
  line-height: 13px;
  color: #242c39;
`;

export const ProjectBlockActionsContainer = styled.div`
  position: absolute;

  top: 0;
  right: 1px;

  transform: translate(50%, -6px);

  ${StyledDropdownSelect} {
    display: flex;
  }
`;

export const ManageTemplatesModalWrapper = styled(Modal)`
  ${StyledModalDialog} {
    width: calc(100% - 64px);
    max-width: 1176px;
  }

  ${StyledModalBody} {
    ${Scrollbar.Default};

    flex-grow: 1;

    padding: 20px 18px 0 30px;

    overflow-y: scroll;

    > div {
      ${Grid} {
        ${GridItem} {
          & + ${GridItem} {
            margin-left: 20px;
            margin-bottom: 20px;
          }

          ${respondTo(
            'medium',
            '>',
          )(css`
            width: calc(25% - 15px);

            &:nth-child(4n + 1) {
              margin-left: 0;
            }
          `)};

          ${respondTo('medium')(css`
            width: calc(50% - 10px);

            &:nth-child(2n + 1) {
              margin-left: 0;
            }
          `)};

          ${respondTo('small')(css`
            width: 100%;

            & + ${GridItem} {
              margin-left: 0;
              margin-bottom: 0;
              margin-top: 20px;
            }

            &:last-child {
              margin-bottom: 20px;
            }
          `)};
        }
      }
    }
  }

  ${StyledModalScrollingContainer} {
    align-items: flex-start;
  }
`;

export const TemplateModalActions = styled.div`
  display: flex;
  flex-direction: row;

  ${CheckboxLabel} {
    margin-right: 16px;
  }
`;
