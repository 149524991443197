import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isUndefined } from 'lodash';
import Papa from 'papaparse';

import useKeyPress from '@float/common/lib/useKeyPress';
import { moment } from '@float/libs/moment';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Checkbox } from '@float/ui/deprecated/Checkbox';
import EHIconAttach from '@float/ui/deprecated/Earhart/Icons/Icon/IconAttach';
import EHIconDownload from '@float/ui/deprecated/Earhart/Icons/Icon/IconDownload';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';
import { useSnackbar } from '@float/ui/deprecated/Snackbar';

import { submitImportData } from '../actions';
import { getTypeString } from '../helpers';
import ImportErrors from './ImportErrors';
import { STATUS } from './ImportModal.constants';
import { download } from './ImportModal.helpers';
import * as styled from './styles';
import UploadButton from './UploadButton';

import ImgImportFile from './import-file.svg';
import ImgSync from './import-integration.svg';

const Wrapper = ({ type, children, onCancel, noBgTransition = false }) => (
  <Modal isOpen onClose={onCancel} noBgTransition={noBgTransition}>
    <ModalHeader>
      <ModalTitle>Import {type === 'people' ? type : 'projects'}</ModalTitle>
    </ModalHeader>
    {children}
  </Modal>
);

const getPrePopulateTargetLabel = (type) => {
  switch (type) {
    case 'people':
      return 'team to update people';
    case 'project':
      return 'projects';
    default:
      return type;
  }
};

const MAX_ROWS = 2000;

export const STEPS = {
  INITIAL: 'INITIAL',
  FILE: 'FILE',
  ERROR: 'ERROR',
  DUPLICATE: 'DUPLICATE',
};

const getPayloadForProcess = (result) => {
  const ignore = result.errors.map((r) => r.row);
  const update = result.duplicates.map((r) => r.row);
  if (result.update) {
    return { ignore, update };
  }
  return { ignore: [...ignore, ...update] };
};

function ImportModal({
  type,
  isAccountOwner,
  onSave,
  onCancel,
  beforeValidate,
  validateRow,
  noBgTransition,
  initialStep,
  ...props
}) {
  const getFirstStep = () => {
    if (initialStep) return initialStep;

    if (props.processId) {
      if (props.errors.length) {
        return STEPS.ERROR;
      } else if (props.duplicates.length) {
        return STEPS.DUPLICATE;
      }
      // if a background process is triggering this,
      // there must be an error or duplicate.
      onCancel();
      return STEPS.DEFAULT;
    }

    return isAccountOwner ? STEPS.INITIAL : STEPS.FILE;
  };

  const [step, setStep] = useState(() => getFirstStep());
  const [prepopulate, setPrepopulate] = useState(false);
  const [processId, setProcessId] = useState(props.processId);
  const [errors, setErrors] = useState(props.errors || []);
  const [duplicates, setDuplicates] = useState(props.duplicates || []);
  const [rows, setRows] = useState([]);
  const [status, setStatus] = useState(STATUS.DEFAULT);
  const { showSnackbar } = useSnackbar();
  const keyHandlers = useState([{ keyCode: 27, handler: onCancel }])[0];
  useKeyPress(keyHandlers);

  const changePrepopulate = (isChecked) => {
    setPrepopulate(isChecked);
  };

  const downloadCSVTemplate = () => {
    const params = [`type=${type}`];

    if (prepopulate) {
      params.push('prepopulate=1');
    }

    const filename =
      type === 'people'
        ? `People_${moment().format('YYYY-MM-DD')}.csv`
        : `Projects_${moment().format('YYYY-MM-DD')}.csv`;

    download(`/api/f2/bulkupload?${params.join('&')}`, filename);
  };

  const submit = (result = {}) => {
    const pid = isUndefined(result.processId) ? processId : result.processId;
    const data = pid ? getPayloadForProcess(result) : result.rows;

    submitImportData({ data, type: getTypeString(type), processId: pid })
      .then((newProcessId) => {
        // This gets dismissed by an async_process live update
        showSnackbar('Import in progress', {
          id: newProcessId,
          loader: true,
          persist: true,
        });
        onSave();
      })
      .catch((err) => {
        showSnackbar((err && err.message) || 'An error occurred.');
        setStatus(STATUS.DEFAULT);
      });
  };

  const submitIgnoringDuplicates = () => {
    const rowsToSubmit = rows.filter((x) => !x.update);
    setStatus(STATUS.SAVING_IGNORING_ERRORS);
    submit({ rows: rowsToSubmit, errors, duplicates });
  };

  const submitUpdatingDuplicates = () => {
    setStatus(STATUS.SAVING);
    submit({ rows, errors, duplicates, update: true });
  };

  const submitIgnoringErrors = () => {
    if (duplicates && duplicates.length) {
      setStatus(STATUS.DEFAULT);
      setStep(STEPS.DUPLICATE);
      return;
    }

    setStatus(STATUS.SAVING_IGNORING_ERRORS);
    submit({ rows, errors, duplicates });
  };

  const onFileChange = (evt) => {
    const file = evt.currentTarget.files && evt.currentTarget.files[0];
    if (!file) {
      return;
    }

    setStatus(STATUS.SAVING);
    setProcessId(null);

    const finalResult = {
      processId: null,
      rows: [],
      duplicates: [],
      errors: [],
    };
    let rowIndex = 0;
    let maxRowsExceeded = false;

    Papa.parse(file, {
      worker: true,
      skipEmptyLines: true,
      header: true,
      step: (result) => {
        rowIndex += 1;

        if (rowIndex === 1) {
          beforeValidate(result.data);
        } else if (rowIndex > 2000) {
          maxRowsExceeded = true;
          return;
        }

        const { data, errors, duplicateRef } = validateRow(result.data);
        data.row = rowIndex;

        if (errors && errors.length) {
          finalResult.errors.push({
            row: rowIndex,
            message: errors,
          });
        } else if (duplicateRef) {
          data.update = true; // denotes row is a duplicate
          finalResult.duplicates.push({
            row: rowIndex,
            message: [duplicateRef],
          });
        }

        if (!errors || !errors.length) {
          finalResult.rows.push(data);
        }
      },
      complete: () => {
        if (maxRowsExceeded) {
          showSnackbar(`Limit to ${MAX_ROWS} rows per upload.`, {
            className: 'error',
          });
        }

        setRows(finalResult.rows);
        setErrors(finalResult.errors);
        setDuplicates(finalResult.duplicates);

        if (finalResult.errors.length) {
          setStatus(STATUS.DEFAULT);
          setStep(STEPS.ERROR);
          return;
        }

        if (finalResult.duplicates.length) {
          setStatus(STATUS.DEFAULT);
          setStep(STEPS.DUPLICATE);
          return;
        }

        submit(finalResult);
      },
    });
  };

  const changeStepUploadFile = () => {
    setStep(STEPS.FILE);
  };

  const InitialPrompt = step === STEPS.INITIAL && (
    <ModalBody>
      <styled.Cards>
        <styled.Card onClick={changeStepUploadFile}>
          <styled.Img src={ImgImportFile} />
          <styled.Title>Upload a file</styled.Title>
          <styled.Description>
            {type === 'people'
              ? 'Add your team to the CSV template then import them to Float.'
              : 'Use the CSV template to import and update Projects.'}
          </styled.Description>
        </styled.Card>
        <styled.Card as={Link} to="admin/api">
          <styled.Img src={ImgSync} />
          <styled.Title>Sync with another app</styled.Title>
          <styled.Description>
            Automatically import People, Projects and Tasks from your favorite
            apps.
          </styled.Description>
        </styled.Card>
      </styled.Cards>
    </ModalBody>
  );

  const FilePrompt = step === STEPS.FILE && (
    <ModalBody>
      <styled.Content>
        <div>
          <strong>1. Build your list</strong>
        </div>
        <p>
          This template provides all the fields you’ll need.{' '}
          <a
            href={
              type === 'people'
                ? 'http://support.float.com/account-settings/importing'
                : 'http://support.float.com/en/articles/3075784-importing-projects'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            See the guide
          </a>{' '}
          for help.
        </p>
        <Checkbox
          className="mb-2"
          value={!!prepopulate}
          label={`Pre-populate with existing ${getPrePopulateTargetLabel(
            type,
          )}`}
          onChange={changePrepopulate}
        />
        <Button
          appearance="secondary"
          icon={EHIconDownload}
          onClick={downloadCSVTemplate}
        >
          Download {type} template
        </Button>
        <p className="mt-3 mb-2">
          <strong>2. Upload your CSV</strong>
        </p>
        <UploadButton
          loader={status === STATUS.SAVING}
          icon={EHIconAttach}
          onChange={onFileChange}
        />
      </styled.Content>
    </ModalBody>
  );

  const partialSuccess = +props.success > 0;
  const ErrorPrompt = step === STEPS.ERROR && (
    <ImportErrors
      itemType={type}
      status={status}
      errors={errors}
      allowIgnore={Boolean(processId || rows.length) && !partialSuccess}
      onFileChange={onFileChange}
      onIgnore={submitIgnoringErrors}
    />
  );

  const DuplicatePrompt = step === STEPS.DUPLICATE && (
    <ImportErrors
      itemType={type}
      status={status}
      errors={duplicates}
      allowIgnore={Boolean(processId) || duplicates.length < rows.length}
      onUpdateDuplicates={submitUpdatingDuplicates}
      onIgnore={submitIgnoringDuplicates}
    />
  );

  return (
    <Wrapper type={type} onCancel={onCancel} noBgTransition={noBgTransition}>
      {InitialPrompt}
      {FilePrompt}
      {ErrorPrompt}
      {DuplicatePrompt}
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  isAccountOwner: Number(state.currentUser.account_tid) === 1,
});

export default connect(mapStateToProps)(ImportModal);
