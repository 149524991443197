import React from 'react';
import styled, { css } from 'styled-components';

import { getPhasesMapRaw } from '../selectors/phases';
import { getProjectsMap } from '../selectors/projects';
import { useAppSelectorStrict } from '../store';

import { gridBackground } from './Schedule/Cell/TopCell/styles.css';

export const HOLIDAY_COLOR = '9B9B9B';

const Circle = styled.div`
  ${(p) => `
  width: ${p.size}px;
  height: ${p.size}px;
  position: ${p.position || 'absolute'};
  border-radius: 50%;
`}
  background-color: ${(p) => p.color};
  right: calc(${(p) => p.baseOffset}px + ${(p) => p.right}px);
  border: 1px solid transparent;

  .${gridBackground} & {
    pointer-events: all;
  }
`;

const DotGroupContainer = styled.div`
  position: relative;

  height: 10px;
  align-self: center;
  outline: none !important;

  [data-date-view-type='monthly'] & {
    padding-top: 2px;
  }

  ${(p) =>
    p.logMyTimeView &&
    css`
      transform: rotate(180deg) translateY(0.5px);
    `};
`;

function DayDots(
  { highlights, dayWidth, large, reportsView, logMyTimeView, ...rest },
  ref,
) {
  const phases = useAppSelectorStrict(getPhasesMapRaw);
  const projects = useAppSelectorStrict(getProjectsMap);

  if (!highlights || !highlights.length) return null;

  let dotWidth = large ? 12 : 8;
  let dotGroupWidth = 140;
  let maxDotsToShow = 12;
  let baseOffset = 8;

  if (dayWidth <= 180) {
    dotGroupWidth = 60;
    maxDotsToShow = 7;
  }

  if (dayWidth <= 160) {
    dotGroupWidth = 45;
    maxDotsToShow = 6;
  }

  if (dayWidth <= 140) {
    dotGroupWidth = 30;
    maxDotsToShow = 5;
  }

  if (dayWidth <= 90) {
    dotGroupWidth = 22;
    maxDotsToShow = 5;
  }

  if (dayWidth <= 27) {
    dotGroupWidth = 15;
    dotWidth = 5;
    maxDotsToShow = 3;
    baseOffset = 5;
  }

  const circles = highlights.slice(0, maxDotsToShow);

  const spacing = Math.min(
    large ? 12 : 8,
    Math.floor(dotGroupWidth / circles.length),
  );
  if (dayWidth === 27) {
    // Month mode requires the circles to be centered below the date. Since
    // we're manually calculating the pixel position, we have to adjust to
    // ensure they stay centered.
    if (circles.length === 1) baseOffset = 10;
  }

  if (reportsView) {
    baseOffset = dayWidth / 2 - (spacing * circles.length) / 2;
  }

  return (
    <DotGroupContainer
      {...rest}
      ref={ref}
      logMyTimeView={logMyTimeView}
      style={{ width: baseOffset * 2 + spacing * circles.length }}
    >
      {circles.map((c, i) => {
        let key;
        if (c.holiday_id) key = `holiday-${c.holiday_id}`;
        if (c.milestone_id) key = `milestone-${c.milestone_id}`;
        if (c.region_holiday_id) key = `regionholiday-${c.region_holiday_id}`;

        let color = c.color || HOLIDAY_COLOR;
        if (c.milestone_id) {
          if (
            c.phase_id &&
            phases?.[c.phase_id] &&
            phases?.[c.phase_id]?.color
          ) {
            ({ color } = phases[c.phase_id]);
          } else if (projects?.[c.project_id])
            ({ color } = projects[c.project_id]);
        }

        return (
          <Circle
            key={key}
            color={`#${color}`}
            right={i * spacing}
            baseOffset={baseOffset}
            size={dotWidth}
            position={reportsView && 'fixed'}
          />
        );
      })}
    </DotGroupContainer>
  );
}

export default React.forwardRef(DayDots);

export const _styles = { Circle, DotGroupContainer };
