import React, { ReactNode } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cx from 'classnames';

import { isLinkRemovable } from '@float/common/components/Schedule/actions/handleLinkInteraction';
import { useViewportContext } from '@float/common/components/Schedule/util/ViewportContext';
import { getUser } from '@float/common/selectors/currentUser';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { useAppSelectorStrict } from '@float/common/store';
import { CellItem, CellsMap } from '@float/types';

import { ScheduleActions } from '../../types';
import { BaseEntityLayer, BaseEntityLayerProps } from '../BaseEntityLayer';
import LinkArrow from './components/LinkArrow';
import { useLinkArrowCurve } from './hooks/useLinkArrowCurve';
import { getLinkArrowColor } from './utils/getLinkArrowColor';

import { linkArrowColorVar, linkedLayer } from './styles.css';

type LinkedLayerProps = {
  item: CellItem<'timeoff'> | CellItem<'task'>;
  row: PersonRow | PersonProjectRow | ProjectRow;
  wrapperRef?: React.RefObject<HTMLDivElement>;
  linkedArrowTargetRef?: React.MutableRefObject<SVGSVGElement | null>;
  cells: CellsMap;
  className?: string;
  children?: ReactNode;
  actions: ScheduleActions;
  linkOpacity?: string | number;
} & BaseEntityLayerProps;

const LinkedLayer = React.forwardRef<HTMLDivElement, LinkedLayerProps>(
  (props, ref) => {
    const {
      item,
      row,
      wrapperRef,
      linkedArrowTargetRef,
      cells,
      className,
      ...rest
    } = props;

    const { parentLinkCount } = useViewportContext();
    const linkArrowColor = useAppSelectorStrict((state) =>
      getLinkArrowColor(
        {
          phases: state.phases.phases,
          projects: state.projects.projects,
        },
        item,
      ),
    );
    const linkRemovable = useAppSelectorStrict((state) =>
      isLinkRemovable(
        {
          phases: state.phases.phases,
          projects: state.projects.projects,
          user: getUser(state),
        },
        item,
      ),
    );
    const linkArrowCurve = useLinkArrowCurve(
      cells,
      item,
      linkedArrowTargetRef,
      wrapperRef,
      row,
    );

    const parentTaskId =
      'parent_task_id' in item.entity ? item.entity.parent_task_id : null;

    const hasChild = 'has_child' in item.entity ? item.entity.has_child : null;
    const peopleIds = 'people_ids' in item.entity ? item.entity.people_ids : [];

    const isFirstPerson =
      row.type === 'person' && peopleIds[0] == row.data.people_id;
    const showLinkArrowCurve = !!(parentTaskId && linkArrowCurve);
    const hasLeftLinkFragment =
      item.isStart && parentTaskId && !showLinkArrowCurve;
    const hasRightLinkFragment =
      item.isEnd && hasChild && !parentLinkCount[item.entityId];

    return (
      <BaseEntityLayer
        className={cx(className, linkedLayer, {
          'with-left-link-fragment': isFirstPerson && hasLeftLinkFragment,
          'with-right-link-fragment': isFirstPerson && hasRightLinkFragment,
        })}
        ref={ref}
        style={assignInlineVars({
          [linkArrowColorVar]: linkArrowColor,
        })}
        {...rest}
      >
        {rest.children}
        {showLinkArrowCurve && (
          <LinkArrow
            actions={rest.actions}
            item={item}
            color={linkArrowColor}
            opacity={rest.linkOpacity}
            linkedArrowTargetRef={linkedArrowTargetRef}
            allowRemove={linkRemovable}
            {...linkArrowCurve}
          />
        )}
      </BaseEntityLayer>
    );
  },
);

export default LinkedLayer;
