import { CurrentUser } from '@float/types/account';
import { Person } from '@float/types/person';
import { FilterToken } from '@float/types/view';

import { findFirstPersonTimeoffable } from './findFirstPersonTimeoffable';
import { Row } from './findPersonRowMatch';
import { getDefaultPerson } from './getDefaultPerson';

export function getDefaultPersonForTimeoffModal(
  rows: Row[],
  props: {
    allPeople: Record<number, Person>;
    people: Record<number, Person>;
    user: CurrentUser;
    filters: FilterToken[];
  },
) {
  const firstTaskablePerson = findFirstPersonTimeoffable(rows, props);

  return getDefaultPerson(firstTaskablePerson, props);
}
