import React, { MouseEvent, useRef } from 'react';

import { useCellStyles } from '@float/common/lib/cellStylesManager/hooks/useCellStyles';
import { useGetCellStylesConfig } from '@float/common/lib/cellStylesManager/utils/useGetCellStylesConfig';
import { useTaskMetasPrefetch } from '@float/common/lib/hooks/useTaskMetasPrefetch';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { useAppSelectorStrict } from '@float/common/store';
import { AllocationType, CellItem } from '@float/types';

import { Dimensions, ScheduleActions } from '../../../types';
import { BaseEntityLayer } from '../../BaseEntityLayer';
import { BoxInner } from '../BoxElements/BoxInner';

import { entityItemBox } from '../../EntityItemStyles.css';

export type BoxEntityItemProps = {
  actions: ScheduleActions;
  dimensions: Dimensions;
  dayWidth: number;
  item: CellItem<'timeoff'> | CellItem<'task'>;
  printMode?: boolean;
  rounded: boolean;
  hourHeight: number;
};

/**
 * Moved the update outside of the component.
 *
 * This way the React Compiler won't complain about it and we know that's a safe update
 */
function setItemBoxRef(
  item: CellItem<'timeoff'> | CellItem<'task'>,
  node: HTMLDivElement | null,
) {
  item._boxRef = node;
}

export const BoxEntityItem = (props: BoxEntityItemProps) => {
  const { actions, dayWidth, dimensions, item, printMode, rounded } = props;

  const { entity, isEnd, isStart } = item;

  const companyPrefs = useAppSelectorStrict(getCompanyPrefs);

  const { color, config } = useGetCellStylesConfig({
    item,
    actionMode: actions.actionMode,
    getOverrideCursor: actions.getOverrideCursor,
    isItemSelected: actions.isItemSelected,
    linkInfo: actions.linkInfo,
    isPrintMode: printMode,
  });

  const styles = useCellStyles(item, color, config);

  const { prefetchTaskMetas } = useTaskMetasPrefetch();

  const onMouseEnter = () => {
    actions.trackHoveredItem(item);

    if (item.type === 'task') {
      prefetchTaskMetas(item.entity.project_id);
    }
  };

  const onMouseLeave = () => {
    actions.trackHoveredItem(null);
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const setBoxRef = (node: HTMLDivElement | null) => {
    ref.current = node;
    setItemBoxRef(item, node);
  };

  const isPercentageAllocation =
    'task_id' in item.entity &&
    item.entity.allocation_type === AllocationType.Percentage;

  const onMouseDown = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (event.button !== 0) return;

    const element = ref.current;

    if (!element) return;
    if (!element.contains(event.target as HTMLElement)) {
      // The user clicked on a tooltip
      return;
    }

    const rect = element.getBoundingClientRect();
    const { height } = rect;

    // Edge uses left/top for unknown reasons
    const offsetX = rect.x || rect.left;
    const offsetY = rect.y || rect.top;

    // @entity.length
    const length = 'length' in entity ? entity.length ?? 1 : 1;
    const dragVersionWidth = dayWidth * Math.min(5, length) - 7;
    const baseLayerStyle = {
      width: dragVersionWidth,
      height: dimensions.height,
      boxShadow: window.TASK_DRAG_SHADOW || '',
    };

    const dragVersion = (
      <BaseEntityLayer
        rounded={rounded}
        roundedLarge={isPercentageAllocation}
        isStart
        isEnd
        style={baseLayerStyle}
      >
        <div
          className={entityItemBox}
          data-rounded={rounded}
          data-rounded-large={isPercentageAllocation}
          data-is-start
          data-is-end
          style={styles}
        >
          <BoxInner
            dayWidth={dayWidth}
            itemWidth={dragVersionWidth}
            item={item}
            companyPrefs={companyPrefs}
            styles={styles}
            props={props}
          />
        </div>
      </BaseEntityLayer>
    );

    actions.setDragItem({
      clientX: event.clientX,
      clientY: event.clientY,
      element: dragVersion,
      item,
      offsetX,
      offsetY,
      shiftKey: event.shiftKey,
      width: dragVersionWidth,
      height,
    });
  };

  return (
    <div
      className={entityItemBox}
      data-testid={`box-entity-${item.type}`}
      data-is-end={isEnd}
      data-is-start={isStart}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={setBoxRef}
      data-rounded={rounded}
      data-rounded-large={isPercentageAllocation}
      style={styles}
    >
      <BoxInner
        dayWidth={dayWidth}
        itemWidth={item.w}
        item={item}
        companyPrefs={companyPrefs}
        styles={styles}
        props={props}
      />
    </div>
  );
};
