import { t } from '@lingui/macro';
import {
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

export const getOptionsByValue = () => {
  const today = startOfDay(new Date());

  return {
    weekCurrent: {
      value: 'weekCurrent',
      label: t`This week`,
      dateStart: startOfWeek(today),
      dateEnd: startOfDay(endOfWeek(today)),
    },
    weekNext: {
      value: 'weekNext',
      label: t`Next week`,
      dateStart: startOfWeek(addWeeks(today, 1)),
      dateEnd: startOfDay(endOfWeek(addWeeks(today, 1))),
    },
    monthCurrent: {
      value: 'monthCurrent',
      label: t`This month`,
      dateStart: startOfMonth(today),
      dateEnd: startOfDay(endOfMonth(today)),
    },
    monthNext: {
      value: 'monthNext',
      label: t`Next month`,
      dateStart: startOfMonth(addMonths(today, 1)),
      dateEnd: startOfDay(endOfMonth(addMonths(today, 1))),
    },
    custom: {
      value: 'custom',
      label: t`Custom `,
      dateStart: null,
      dateEnd: null,
    },
  };
};

export const getOptions = () => {
  const optionsByValue = getOptionsByValue();

  return [
    optionsByValue['weekCurrent'],
    optionsByValue['weekNext'],
    optionsByValue['monthCurrent'],
    optionsByValue['monthNext'],
    optionsByValue['custom'],
  ];
};

export const getOptionSelected = (startDate: Date, endDate: Date) => {
  const optionsByValue = getOptionsByValue();
  const options = getOptions();

  const optionSelected =
    options.find(
      (option) =>
        option.dateStart &&
        option.dateEnd &&
        isSameDay(startDate, option.dateStart) &&
        isSameDay(endDate, option.dateEnd),
    ) ?? optionsByValue.custom;

  return optionSelected;
};
