import React from 'react';
import { t } from '@lingui/macro';

import { DatePicker } from '@float/common/components/DatePicker/DatePicker';
import { Core } from '@float/common/earhart/colors';
import IconRight from '@float/ui/deprecated/Icons/icon-chevron-right';
import { Input } from '@float/ui/deprecated/Input';
import { IconChevronRight } from '@float/ui/icons/essentials/IconChevronRight';

import { useInputAllocationDateRange } from './hooks/useInputAllocationDateRange';

import * as styles from './InputAllocationDateRange.css';

export type InputAllocationDateRangeProps = {
  startDate: Date;
  endDate: Date;

  isReadOnly: boolean;
  isFixedEndDate?: boolean | undefined;
  getIsDateDisabled: (date: Moment) => boolean;
  onChange: (data: { startDate: Date; endDate: Date }) => void;
  onChangeIsFixedEndDate?: (value: boolean) => void;
};

export const InputAllocationDateRange = (
  props: InputAllocationDateRangeProps,
) => {
  const {
    startDate,
    endDate,
    isReadOnly,
    isFixedEndDate,
    getIsDateDisabled,
    onChange,
    onChangeIsFixedEndDate,
  } = props;

  const {
    startDateMoment,
    startDateFormatted,
    endDateMoment,
    endDateFormatted,
    handleStartDateChange,
    handleEndDateChange,
  } = useInputAllocationDateRange({
    startDate,
    endDate,
    onChange,
  });

  return (
    <div className={styles.fieldDateRange}>
      {isReadOnly && (
        <>
          <Input
            ariaLabel={t`Start date`}
            className={styles.inputReadOnly}
            value={startDateFormatted}
            appearance="underline"
            readOnly
            noBorder
          />
          <div className={styles.dateRangeArrowIconWrapper}>
            <IconRight color={Core.Text.Secondary} />
          </div>
          <Input
            ariaLabel={t`End date`}
            className={styles.inputReadOnly}
            value={endDateFormatted}
            appearance="underline"
            readOnly
            noBorder
          />
        </>
      )}

      {!isReadOnly && (
        <>
          <DatePicker
            ariaLabel={t`Start date popover`}
            inputAriaLabel={t`Start date`}
            disableDayFn={getIsDateDisabled}
            height={40}
            inputWidth={124}
            value={startDateMoment}
            onChange={handleStartDateChange}
          />

          <div className={styles.dateRangeArrowIconWrapper}>
            <IconChevronRight
              size={32}
              color={Core.Text.Secondary}
              noScalingStroke={true}
            />
          </div>

          <DatePicker
            ariaLabel={t`End date popover`}
            inputAriaLabel={t`End date`}
            disableDayFn={getIsDateDisabled}
            height={40}
            inputWidth={124}
            value={endDateMoment}
            minimumDate={startDateMoment}
            onChange={handleEndDateChange}
            fixedEndDateToggleValue={isFixedEndDate}
            onFixedEndDateToggleValueChange={onChangeIsFixedEndDate}
          />
        </>
      )}
    </div>
  );
};
