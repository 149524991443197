import React from 'react';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import styled from 'styled-components';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Modal } from '@float/ui/deprecated/Modal';

const DeleteSavedFilterModal = styled(Modal)`
  z-index: 9999;
`;

class DeleteBookmarkModal extends React.Component {
  state = {};

  static getDerivedStateFromProps(props) {
    return {
      name: props.managedModals.deleteBookmarkModal.modalSettings.term,
    };
  }

  hide = (evt) => {
    prevent(evt);
    this.props.manageModal({
      visible: false,
      modalType: 'deleteBookmarkModal',
    });
  };

  del = async (evt) => {
    prevent(evt);
    await this.props.onDelete({ name: this.state.name });
    this.hide();
  };

  render() {
    return (
      <DeleteSavedFilterModal
        isOpen
        ref={this.props.modalRef}
        onClose={this.hide}
      >
        <Modal.Header>
          <Modal.Title>Delete saved filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You're about to delete the filter
            <strong> {this.state.name}</strong>. Are you sure?
          </p>
        </Modal.Body>
        <Modal.Actions>
          <Button autoFocus onClick={this.del}>
            Delete
          </Button>
          <Button appearance="secondary" onClick={this.hide}>
            Nevermind
          </Button>
        </Modal.Actions>
      </DeleteSavedFilterModal>
    );
  }
}

export default modalManagerHoc({
  Comp: DeleteBookmarkModal,
  modalType: 'deleteBookmarkModal',
});
