import React, { useCallback } from 'react';
import { Provider, useStore } from 'react-redux';
import ReactDOMServer from 'react-dom/server';

export function useRenderComponentToString() {
  const store = useStore();
  return useCallback(
    (component: React.ReactNode) =>
      ReactDOMServer.renderToString(
        <Provider store={store}>{component}</Provider>,
      ),
    [store],
  );
}

export function withRenderComponentToString<
  P extends {
    renderComponentToString: ReturnType<typeof useRenderComponentToString>;
  },
  T extends React.ComponentType<P>,
>(Component: T) {
  return (props: Omit<P, 'renderComponentToString'>) => {
    const renderComponentToString = useRenderComponentToString();
    return (
      // @ts-expect-error Couldn't find a way to correctly type the public api and make TS happy here
      <Component {...props} renderComponentToString={renderComponentToString} />
    );
  };
}
