import { usePlatformSafeResizeObserver } from '@float/libs/hooks/usePlatformSafeResizeObserver';

const supportsResizeObserver = typeof ResizeObserver !== 'undefined';

export const useResizeObserver = (
  targetRef: { current: Element | null } | null = { current: null },
  handler: (entry?: ResizeObserverEntry) => void,
  { enabled = true, wait = 500 } = {},
) => {
  return usePlatformSafeResizeObserver(
    targetRef,
    handler,
    ResizeObserver,
    (entries: ResizeObserverEntry[]) => {
      requestAnimationFrame(() => handler(entries[0]));
    },
    { enabled: enabled && supportsResizeObserver, wait },
  );
};
