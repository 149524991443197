export function removeEmoji(text: string): string {
  if (!text || typeof text !== 'string') {
    return text;
  }

  return text
    .replace(/[^\d\s\w][\p{Emoji}\u200D]+/gu, '') // Remove emoji ZWJ sequences
    .replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '') // Remove remaining emojis and pictographs
    .replace(/[\u{FE00}-\u{FE0F}]/gu, '') // Remove emoji variation selectors
    .replace(/[\u{1F3FB}-\u{1F3FF}]/gu, '') // Remove emoji skin tone modifiers
    .replace(/\u200D/g, '') // Clean up any remaining zero-width joiners
    .replace(/\s+/g, ' ') // Remove double spaces and trim
    .trim();
}
