import React, { CSSProperties } from 'react';

import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { getUser } from '@float/common/selectors/currentUser';
import { getPhaseById } from '@float/common/selectors/phases';
import { selectEnhancedProjectById } from '@float/common/selectors/projects/selectEnhancedProjectById';
import { useAppSelectorStrict } from '@float/common/store';
import { AllocationType, CellItem } from '@float/types';
import IconSyncWhite from '@float/ui/deprecated/Icons/icon-sync-white';

import { formatHours } from '../utils/formatHours';
import { getNumRows } from '../utils/getNumRows';
import { getIconElements } from './getIconElements';
import { BoxGetterProps } from './types';

import {
  narrowBoxInner,
  narrowHours,
  narrowText,
} from '../../EntityItemStyles.css';

interface BoxInnerNarrowProps {
  item: CellItem<'task'> | CellItem<'timeoff'>;
  styles: CSSProperties;
  props: BoxGetterProps;
}

export const BoxInnerNarrow: React.FC<BoxInnerNarrowProps> = ({
  item,
  styles,
  props,
}) => {
  const { color } = styles;
  const { hourHeight } = props;
  const { entity } = item;

  const user = useAppSelectorStrict(getUser);
  const timeoffType = useAppSelectorStrict((state) => {
    if (item.type === 'timeoff') {
      return state.timeoffTypes.timeoffTypes[item.entity.timeoff_type_id];
    }
    return undefined;
  });

  const project = useAppSelectorStrict((state) => {
    if (item.type === 'task') {
      return selectEnhancedProjectById(state, item.entity.project_id);
    }
    return undefined;
  });

  const phase = useAppSelectorStrict((state) => {
    if (item.type === 'task' && item.entity.phase_id) {
      return getPhaseById(state, item.entity.phase_id) ?? undefined;
    }
    return undefined;
  });

  const numRows = getNumRows(entity, hourHeight) + 1;
  const fullDay = isFullDayTimeoff(entity);

  const elements = [];

  const isPercentAllocation =
    'task_id' in item.entity &&
    item.entity.allocation_type === AllocationType.Percentage;

  if (!fullDay) {
    elements.push(
      <div
        className={narrowHours}
        key="hours"
        data-more-than-one-row={numRows >= 2}
      >
        <div>{isPercentAllocation ? '%' : formatHours(entity, true)}</div>
      </div>,
    );
  }

  if (numRows >= 2) {
    const iconElements = getIconElements(item, color, undefined, user);
    if (item.type === 'task' && item.entity.integration_status === 1) {
      iconElements.unshift(<IconSyncWhite key="sync" color={color} />);
    }
    iconElements.reverse().splice(numRows - 1, iconElements.length);
    elements.push(...iconElements);
  }

  if (item.type === 'timeoff') {
    if (
      elements.length < numRows &&
      timeoffType &&
      timeoffType.timeoff_type_name
    ) {
      elements.push(
        <div className={narrowText} key="t">
          {timeoffType.timeoff_type_name.substring(0, 1)}
        </div>,
      );
    }
  } else {
    const taskName = item.entity.name;

    if (elements.length < numRows && taskName) {
      elements.push(
        <div className={narrowText} key="n">
          {taskName.substring(0, 1)}
        </div>,
      );
    }
    if (elements.length < numRows && !taskName) {
      if (phase?.phase_name) {
        elements.push(
          <div className={narrowText} key="n">
            {phase.phase_name.substring(0, 1)}
          </div>,
        );
      }
    }

    // Sometimes project does not exist in local store. Probably an
    // intermediate render after a project delete op?
    if (project) {
      if (elements.length < numRows && project.project_name) {
        elements.splice(
          elements.length - (taskName ? 1 : 0),
          0,
          <div className={narrowText} key="pn">
            {project.project_name.substring(0, 1)}
          </div>,
        );
      }
      if (elements.length < numRows && project.client_name !== 'No Client') {
        elements.splice(
          elements.length -
            ((taskName ? 1 : 0) + (project.project_name ? 1 : 0)),
          0,
          <div className={narrowText} key="cn">
            {project.client_name.substring(0, 1)}
          </div>,
        );
      }
    }
  }

  return (
    <div
      className={narrowBoxInner}
      data-more-than-one-row={numRows >= 2}
      data-single-row={numRows === 1}
      data-full-day={Boolean(fullDay)}
      data-pinned-timeoff={item.type === 'timeoff' && !fullDay && item.y === 0}
    >
      {elements}
    </div>
  );
};
