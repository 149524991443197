/* eslint-disable react-compiler/react-compiler */
/* This file would be very risky and difficult to compile with React Compiler */
/* so we skip it for now */

import React, {
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isUndefined } from 'lodash';

import { FloatAppPlatform } from '@float/constants/app';
import { config } from '@float/libs/config';
import { todayManager } from '@float/libs/dates';
import { useSafeContext } from '@float/libs/hooks/useSafeContext';
import { getInitialTimeRange } from '@float/libs/timeRange';
import { CurrentUser } from '@float/types';

import { setLogTimeViewAction, setViewTypeAction } from '../actions/appInfo';
import { useTimeRangeSettings } from '../components/Schedule/insights/useTimeRangeSettings';
import { usePrintContext } from '../contexts/PrintContext';
import { userCanOnlyViewThemself } from '../lib/rights';
import {
  selectIsLogTimeViewType,
  selectScheduleViewType,
} from '../selectors/appInfo/scheduleView';
import { getRightLeftHiddenDaysCount } from '../selectors/currentUser';
import { useAppDispatchDecorator, useAppSelector } from '../store';
import useCells from './Data/useCells';
import useRowMetas from './Data/useRowMetas';
import { usePersistedCurrentWeekForSingleUserView } from './hooks/usePersistedCurrentWeekForSingleUserView';
import {
  ScheduleContextProviderProps,
  ScheduleContextType,
} from './ScheduleContext.types';
import getScrollbarSize from './util/getScrollbarSize';
import { getViewType } from './util/getViewType';
import useDates from './util/useDates';

const ScheduleContext = createContext<ScheduleContextType | null>(null);

export const BASE_SCROLL_OFFSET_WEEKS = 50;
export const DAY_WIDTH = {
  MONTH: 27,
  WEEK: 90,
  DAY: 245,
};
const isElectron = config.platform === FloatAppPlatform.Electron;
const isTimer = isElectron;

// Synchronized person id should only persist for the length of the current
// session.
let SUV_CURRENT_PERSON_ID: null | number = null;

function getPrefs(user: CurrentUser) {
  const isGuestUser = !user.people_id;

  const mondayStart = user.start_work_week === 1;

  const prefs = {
    ...getRightLeftHiddenDaysCount(user, mondayStart),
    mondayStart,
    dayWidth: DAY_WIDTH.WEEK,
    hourHeight: 16,
    viewType: user.prefs.sked_view_type || 'people',
    meFilter: Boolean(user.prefs.me_filter) && !isGuestUser,
    forceSingleUserView: userCanOnlyViewThemself(user),
    timeIncrementUnit: null, // It appears this property may be unused
  };

  if (!isUndefined(user.prefs.sked_view)) {
    if (isTimer) {
      const availableWidth = window.innerWidth;
      prefs.dayWidth = Math.max(140, Math.floor(availableWidth));
    } else {
      const sked_view = Number(user.prefs.sked_view);
      if (sked_view === 0) prefs.dayWidth = DAY_WIDTH.DAY;
      if (sked_view === 1) prefs.dayWidth = DAY_WIDTH.WEEK;
      if (sked_view === 2) prefs.dayWidth = DAY_WIDTH.MONTH;
    }
  }

  if (!isUndefined(user.prefs.sked_zoom)) {
    const sked_zoom = Number(user.prefs.sked_zoom);
    if (isTimer) {
      prefs.hourHeight = 56;
    } else {
      if (sked_zoom === 0) prefs.hourHeight = 16;
      if (sked_zoom === 1) prefs.hourHeight = 8;
      if (sked_zoom === 2) prefs.hourHeight = 56;
    }
  }

  return prefs;
}

function isSuvSingleDay(windowWidth: number, user: CurrentUser) {
  const canOnlyViewThemself = userCanOnlyViewThemself(user);
  return windowWidth <= 740 && canOnlyViewThemself;
}

export function ScheduleContextProvider(props: ScheduleContextProviderProps) {
  'use no memo';

  const { location = { pathname: '' }, isSidebarOpen, isSharedLink } = props;
  const {
    dayWidth: dayWidthPrefs,
    forceSingleUserView: forceSingleUserViewPrefs,
    hourHeight: hourHeightPrefs,
    leftHiddenDays: leftHiddenDaysPrefs,
    meFilter: meFilterPrefs,
    mondayStart: mondayStartPrefs,
    rightHiddenDays: rightHiddenDaysPrefs,
    timeIncrementUnit: timeIncrementUnitPrefs,
    viewType: viewTypePrefs,
  } = getPrefs(props.user);
  const hasTimeTracking = props.user.time_tracking > 0;

  const scrollWrapperRef = useRef<HTMLDivElement>();
  const boundaryRef = useRef();
  const cornerRef = useRef();
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [scrollWrapperHeight, setScrollWrapperHeight] = useState(0);
  const [numCols, setNumCols] = useState(BASE_SCROLL_OFFSET_WEEKS * 2);
  const [fetchDataEnabled, setFetchDataEnabled] = useState(false);
  const printMode = usePrintContext().isPrinting;
  const [singleUserView, _setSingleUserView] = useState(isTimer);
  const [logTimeView, setLogTimeView] = useState(
    props.isLogTimeView || location.pathname === '/log-time',
  );
  const logTimeViewType = useAppSelector(selectIsLogTimeViewType);
  const setLogTimeViewType = useAppDispatchDecorator(setLogTimeViewAction);

  const [mondayStart, setMondayStart] = useState(mondayStartPrefs);
  const [leftHiddenDays, setLeftHiddenDays] = useState(leftHiddenDaysPrefs);
  const [rightHiddenDays, setRightHiddenDays] = useState(rightHiddenDaysPrefs);
  const [dayWidth, setDayWidth] = useState(dayWidthPrefs);
  const [hourHeight, setHourHeight] = useState(hourHeightPrefs);
  const viewType = useAppSelector(selectScheduleViewType);
  const setViewType = useAppDispatchDecorator(setViewTypeAction);
  const [timeIncrementUnit, setTimeIncrementUnit] = useState(
    timeIncrementUnitPrefs,
  );
  const [timeRange, setTimeRange] = useState(() =>
    getInitialTimeRange(props.user, mondayStart),
  );
  const { timeRangeSettings, setTimeRangeSetting } = useTimeRangeSettings();
  const [lockPeriodDates, setLockPeriodDates] = useState(props.lockPeriodDates);

  useEffect(() => {
    setDayWidth(dayWidthPrefs);
    setHourHeight(hourHeightPrefs);
    setLeftHiddenDays(leftHiddenDaysPrefs);
    setMondayStart(mondayStartPrefs);
    setRightHiddenDays(rightHiddenDaysPrefs);
    setTimeIncrementUnit(timeIncrementUnitPrefs);
  }, [
    dayWidthPrefs,
    hourHeightPrefs,
    leftHiddenDaysPrefs,
    mondayStartPrefs,
    rightHiddenDaysPrefs,
    timeIncrementUnitPrefs,
  ]);

  const dates = useDates(mondayStart, leftHiddenDays, rightHiddenDays);

  const currentWeek = dates.getCurrentWeek();

  const { suvWeek, setAndSaveSuvWeek, setSuvWeek } =
    usePersistedCurrentWeekForSingleUserView(dates);

  const [suvHeight, setSuvHeight] = useState(0);
  const [suvPersonId, _setSuvPersonId] = useState(
    SUV_CURRENT_PERSON_ID || props.user.people_id,
  );
  const [suvSingleDay, setSuvSingleDay] = useState(() => {
    return props.singleDayView || isSuvSingleDay(window.innerWidth, props.user)
      ? todayManager.getToday()
      : null;
  });

  const [baseColOffset, setBaseColOffset] = useState(() => {
    return dates.getCurrentWeek() - BASE_SCROLL_OFFSET_WEEKS;
  });

  // Tasks can only be two years long. Therefore, as long as we cover the
  // generated date map by a year, any start/end dates during fetching should
  // exist in the map prior to the result.
  dates.ensureRange(
    Math.max(0, baseColOffset - 104),
    baseColOffset + numCols + 104,
  );

  const isSingleUserView =
    singleUserView ||
    logTimeView ||
    (viewType === 'people' && meFilterPrefs) ||
    (viewType === 'people' && forceSingleUserViewPrefs);

  const setSingleUserView = useCallback((val: boolean) => {
    setFetchDataEnabled(false);
    _setSingleUserView(val);
  }, []);

  const setSuvPersonId = useCallback((val: number | null) => {
    if (typeof val === 'function') throw Error('not supported');

    _setSuvPersonId(val);
    SUV_CURRENT_PERSON_ID = val;
  }, []);

  const value = useMemo(() => {
    const res = {
      scrollWrapperRef,
      boundaryRef,
      baseColOffset,
      numCols,
      leftHiddenDays,
      rightHiddenDays,
      numDays: 7 - leftHiddenDays - rightHiddenDays,
      singleUserView: isSingleUserView,
      setSingleUserView,
      dayWidth,
      hourHeight,
      mondayStart,
      setBaseColOffset,
      setNumCols,
      setMondayStart,
      setDayWidth(newWidth: number) {
        setFetchDataEnabled(false);
        setDayWidth(newWidth);
      },
      setHourHeight,
      dates,
      fetchDataEnabled,
      setFetchDataEnabled,
      viewType,
      setViewType,
      printMode,
      suvWeek,
      setSuvWeek: setAndSaveSuvWeek,
      currentWeek,
      timeIncrementUnit,
      suvPersonId,
      setSuvPersonId,
      suvSingleDay,
      setSuvSingleDay,
      setSuvHeight,
      setLogTimeViewType,

      // earhart
      cornerRef,
      timeRange,
      setTimeRange,
      timeRangeSettings,
      setTimeRangeSetting,
      lockPeriodDates,
      setLockPeriodDates,
      hasTimeTracking,
      isSharedLink,
      logTimeView: logTimeView || logTimeViewType,
      logMyTimeView: logTimeView,
      isTimer,
      logTimeViewType,
      hasVerticalScrollbar: true,
      scrollbarSize: 0,
      availableWidth: 0,
      numVisibleWeeks: 0,
    };

    const hasVerticalScrollbar = scrollWrapperHeight <= suvHeight + 55;

    if (isTimer && isSingleUserView) {
      res.hourHeight = 64;
      res.hasVerticalScrollbar = hasVerticalScrollbar;
    } else if (isSingleUserView && viewType === 'people') {
      res.hourHeight = 64;
      res.hasVerticalScrollbar = hasVerticalScrollbar;
    }

    if (logTimeView) {
      const sidebarWidth = isSidebarOpen ? 400 : 0;
      res.scrollbarSize = getScrollbarSize();
      res.baseColOffset = suvWeek;
      const availableWidth =
        windowInnerWidth -
        (hasVerticalScrollbar ? res.scrollbarSize - 2 : -1) -
        sidebarWidth;
      res.dayWidth = Math.max(
        140,
        Math.floor(
          (availableWidth - 61) / (7 - leftHiddenDays - rightHiddenDays),
        ),
      );
      res.numCols = 1;
      res.availableWidth = availableWidth;
      res.viewType = 'people';
      res.hasVerticalScrollbar = hasVerticalScrollbar;

      if (suvSingleDay) {
        res.dayWidth = availableWidth;
      }
    }

    return res;
  }, [
    scrollWrapperRef,
    boundaryRef,
    baseColOffset,
    numCols,
    leftHiddenDays,
    rightHiddenDays,
    dates,
    mondayStart,
    dayWidth,
    hourHeight,
    fetchDataEnabled,
    viewType,
    printMode,
    timeIncrementUnit,
    isSingleUserView,
    setSingleUserView,
    isSidebarOpen,
    currentWeek,
    suvWeek,
    suvPersonId,
    setSuvPersonId,
    windowInnerWidth,
    scrollWrapperHeight,
    suvSingleDay,
    suvHeight,
    logTimeView,
    setLogTimeViewType,
    setViewType,
    setAndSaveSuvWeek,

    // earhart
    cornerRef,
    logTimeViewType,
    timeRange,
    timeRangeSettings,
    setTimeRangeSetting,
    lockPeriodDates,
    hasTimeTracking,
    isSharedLink,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowInnerWidth(window.innerWidth);

      if (scrollWrapperRef.current) {
        setScrollWrapperHeight(
          scrollWrapperRef.current.getBoundingClientRect().height,
        );
      }
    };

    window.addEventListener('resize', handleResize);

    // Ensure the initial dimensions are set correctly after we load.
    handleResize();
    const timer = setTimeout(handleResize, 500);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const isSingleDay =
      props.singleDayView || isSuvSingleDay(windowInnerWidth, props.user);

    if (suvSingleDay && !isSingleDay) {
      setSuvSingleDay(null);
      return;
    }

    if (suvSingleDay === null && isSingleDay) {
      setSuvSingleDay(todayManager.getToday());
    }

    if (suvSingleDay) {
      setSuvWeek(dates.toDescriptor(suvSingleDay)[0]);
    }
  }, [
    windowInnerWidth,
    props.user,
    dates,
    props.singleDayView,
    suvSingleDay,
    setSuvWeek,
  ]);

  // TODO Don't use a useEffect to align the states
  // https://linear.app/float-com/issue/PI-72/create-a-single-source-of-truth-for-the-viewtype
  useLayoutEffect(() => {
    const path = location.pathname;
    setLogTimeView(props.isLogTimeView || path === '/log-time');
    const isTimeline = ['/', '/project-plan', '/log-time'].includes(path);
    if (!isTimeline) {
      setFetchDataEnabled(false);
      return;
    }

    const updatedViewType = getViewType({
      logTimeView,
      path,
      viewType,
      viewTypePref: viewTypePrefs,
    });

    if (updatedViewType !== viewType) {
      setViewType(updatedViewType);
    }

    // eslint-disable-next-line
  }, [location.pathname, props.isLogTimeView, logTimeView]);

  const rowMetas = useRowMetas(value, props.user, props.allPeople);
  const cellsWrapper = useCells(value, rowMetas, window.innerWidth);
  const cellsDispatch = cellsWrapper.dispatch;

  const valueWithCells = useMemo(() => {
    return { ...value, rowMetas, cellsWrapper };

    // Since we mutate cells directly, we need to force a re-render whenever cells
    // signals that it has rebuilt anything (by _lastUpdatedAt or changes).
  }, [
    value,
    rowMetas,
    cellsWrapper,
    cellsWrapper.cells._lastUpdatedAt,
    cellsWrapper.changes,
  ]);

  // Single day mode uses a different minimum item hour height, which means
  // we need to rebuild cells. Note that we use a boolean here since
  // suvSingleDay represents the number of the current day, but we only need
  // to rebuild when we enter/leave single day mode.
  const isSingleDay = !!isSingleUserView;
  useEffect(() => {
    cellsDispatch({ type: 'REBUILD_ALL_CELLS' });
  }, [cellsDispatch, hourHeight, isSingleDay, logTimeView]);

  return <ScheduleContext.Provider value={valueWithCells} {...props} />;
}

export const useScheduleContext = () =>
  useSafeContext(ScheduleContext, 'ScheduleContext');
