import React from 'react';

import {
  BarConfig,
  RangeBar,
} from '@float/ui/deprecated/Chart/RangeBar/RangeBar';

export const DualRangeBarFormatter = (val?: [BarConfig[], BarConfig[]]) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: 90, height: 12, marginBottom: 2 }}>
        <RangeBar series={(val && val[0]) || []} asPercent={false} />
      </div>
      <div style={{ width: 90, height: 12 }}>
        <RangeBar series={(val && val[1]) || []} asPercent={false} />
      </div>
    </div>
  );
};
