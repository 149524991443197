import React from 'react';
import { ThemeConsumer } from 'styled-components';

export default function (props: {
  size?: number | string;
  color?: string;
  strokeWidth?: number | string;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}) {
  const { size = 24, color, strokeWidth = '1.5' } = props;

  return (
    <ThemeConsumer>
      {(theme) => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 24 24"
          className={`icon-close-small ${props.className || ''}`}
          focusable={false}
          onClick={props.onClick}
          style={props.style}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 9L9 15.5"
            stroke={color || theme.emphasisLow}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 9L15.5 15.5"
            stroke={color || theme.emphasisLow}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </ThemeConsumer>
  );
}

export const Close = ({
  color,
  size = 14,
}: {
  color?: string;
  size?: number | string;
}) => {
  return (
    <ThemeConsumer>
      {(theme) => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
            fill={color || theme.emphasisLow}
          />
        </svg>
      )}
    </ThemeConsumer>
  );
};

/**
 *
 * @param {{ size?: number, style?: import('react').CSSProperties, color?: string }} param0
 * @returns
 */
export const CloseCircle = ({
  size = '24',
  style,
  color,
}: {
  size?: number | string;
  style?: React.CSSProperties;
  color?: string;
}) => {
  return (
    <ThemeConsumer>
      {(theme) => (
        <svg
          style={style}
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="stroke"
            d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12Z"
            stroke={color || theme.emphasisLow}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="stroke"
            d="M9.75 9.75L14.25 14.25"
            stroke={color || theme.emphasisLow}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="stroke"
            d="M14.25 9.75L9.75 14.25"
            stroke={color || theme.emphasisLow}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </ThemeConsumer>
  );
};
