import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { ErrorText, SecondaryText } from '@float/ui/deprecated/Text';

const ForgotPassword = (props) => {
  const { data, onClick } = props;
  return (
    <SecondaryText className="reset">
      {!data.sent && (
        <>
          Forgot your password?{' '}
          <TextButton
            appearance="flue-underline"
            onClick={onClick}
            disabled={data.sending}
          >
            {data.sending ? 'Please wait' : 'Reset'}
          </TextButton>
        </>
      )}

      {data.sent && data.succeeded && (
        <>
          <b>Email sent,</b> please check your inbox.
        </>
      )}

      {data.sent && !data.succeeded && (
        <ErrorText>
          {data.message || "The email couldn't be sent."}
          &nbsp;{' '}
          <TextButton
            appearance="flue-underline"
            onClick={onClick}
            disabled={data.sending}
          >
            {data.sending ? 'Please wait' : 'Retry'}
          </TextButton>
        </ErrorText>
      )}
    </SecondaryText>
  );
};

export default ForgotPassword;
