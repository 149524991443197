import React from 'react';
import styled from 'styled-components';

import IconSyncWhiteLrg from '@float/ui/deprecated/Icons/icon-sync-white-lrg';
import { LinkLabel } from '@float/ui/deprecated/Label';
import colors from '@float/ui/deprecated/Theme/colors';

import { getTaskModalInitialState } from '../getTaskModalInitialState';

let etmThis;

const IntegrationSyncSection = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 0;
  align-content: center;
  justify-content: space-between;
  align-items: center;
`;

const IconAndTitle = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  align-items: center;
`;

const SyncUnsyncLink = styled(LinkLabel)`
  display: flex;
  flex: 0;
  margin-left: 10px;
`;

const InlineEllipsis = styled.span`
  max-width: ${(p) => p.maxWidth || 300}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  margin: auto !important;
  vertical-align: bottom;
`;

const resetIntegrationReadOnlyFields = () => {
  const { originalTaskUnmodified, status, taskName } = etmThis.state;
  const { isNewTask, insert, mode } = etmThis.props.modalSettings;
  const task = {
    ...originalTaskUnmodified,
    status,
    name: taskName,
  };
  etmThis.setState(
    getTaskModalInitialState.call(etmThis, task, isNewTask, insert, mode),
  );
};

const onSyncUnsyncClick = (e) => {
  e.preventDefault();
  const { integrationSyncLocked } = etmThis.state;
  if (!integrationSyncLocked) {
    resetIntegrationReadOnlyFields();
  }
  etmThis.setState({
    integrationSyncLocked: !integrationSyncLocked,
  });
};

const renderSyncStripText = (integrationSyncLocked, eventName, extCalendar) => {
  if (integrationSyncLocked) {
    const calendarName = extCalendar && extCalendar.name;
    const displayName = eventName || calendarName;
    return (
      <p>
        Synced with:{' '}
        {displayName && (
          <>
            ‘<InlineEllipsis maxWidth={300}>{displayName}</InlineEllipsis>’
          </>
        )}
        {!eventName && ' calendar'}
      </p>
    );
  }
  return (
    <p>
      {eventName ? (
        <span>
          ‘<InlineEllipsis maxWidth={220}>{eventName}</InlineEllipsis>’
        </span>
      ) : (
        'This event'
      )}{' '}
      will be unsynced on update
    </p>
  );
};

export const getIntegrationSyncedElem = (self) => {
  etmThis = self;
  const { task, integrationSyncLocked } = etmThis.state;
  const { extCalendar } = etmThis.props;
  const readOnly = etmThis.isReadOnly();
  const eventName = task.ext_calendar_event_name;
  return (
    <IntegrationSyncSection integrationSyncLocked={integrationSyncLocked}>
      <IconAndTitle>
        <IconSyncWhiteLrg
          color={colors.emphasisHigh}
          style={{ marginRight: 10 }}
        />
        {renderSyncStripText(integrationSyncLocked, eventName, extCalendar)}
      </IconAndTitle>
      {!readOnly && (
        <SyncUnsyncLink onClick={onSyncUnsyncClick}>
          {integrationSyncLocked ? 'Unsync' : 'Resync'}
        </SyncUnsyncLink>
      )}
    </IntegrationSyncSection>
  );
};
