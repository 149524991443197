import { ensureViewsLoaded } from '@float/common/actions/views';
import {
  getViewsIsLoading,
  getViewsIsLoadingFailed,
  getViewsList,
} from '@float/common/selectors/views';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';

export function useViewsDataFetching() {
  const data = useAppSelector(getViewsList);
  const loading = useAppSelector(getViewsIsLoading);
  const error = useAppSelector(getViewsIsLoadingFailed);

  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  async function fetchViewsData() {
    try {
      await dispatch(ensureViewsLoaded());
    } catch (err) {
      showSnackbar(`Failed to fetch Views data`);
    }
  }

  useOnMount(() => {
    fetchViewsData();
  });

  return {
    data,
    loading,
    error,
    retry: fetchViewsData,
  };
}
