import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { Tag } from '@float/ui/deprecated/Earhart/Tags';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { CurrencyInput } from '@float/ui/deprecated/Input/CurrencyInput';

export const ScrollableList = styled.ul`
  margin-bottom: 42px;
  min-height: 150px;

  li {
    ${Typography.TextM.R400};

    display: flex;
    align-items: center;

    margin-left: 0;
    min-height: 43px;

    color: ${Colors.FIN.Lt.Emphasis.High};

    border-top: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};

    box-sizing: border-box;

    &:last-child {
      border-bottom: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};
    }

    span.avatar-image {
      margin-right: 10px;
    }

    span.name {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ${(p) =>
      p.noTopBorder &&
      css`
        &:first-child {
          border-top: 0;
        }
      `}

    svg {
      height: 100%;
    }

    .controls {
      display: flex;
      align-items: center;
      margin-left: auto;
      box-sizing: border-box;

      ${CurrencyInput._styles.FieldWrapper} {
        width: 90px; // https://app.asana.com/0/0/1201317322718183/f
        margin: 4px 0;
      }

      .per-hour {
        margin-left: 18px;
        margin-right: 12px;
      }

      &.error {
        ${CurrencyInput._styles.InputWrapper} {
          border-bottom-color: ${(p) => p.theme.red};
        }

        .per-hour {
          color: ${(p) => p.theme.red};
        }
      }
    }
  }

  .rates-error {
    --svg-icon-color: ${(p) => p.theme.red};

    padding: 0;

    margin: 13px 0 11px;

    svg {
      margin-left: 4px;
      margin-right: 11px;
    }
  }
`;

export const NoTeam = styled.div`
  ${Typography.TextL.R400};

  line-height: 150px;

  color: ${Colors.FIN.Lt.Emphasis.Low};

  text-align: center;

  margin: auto;
`;

export const StyledTag = styled(Tag)`
  margin-left: 10px;
`;
