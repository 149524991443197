import { isObject, keyBy } from 'lodash';

import {
  Person,
  Phase,
  Project,
  ProjectStatus,
  RawTask,
  RepeatState,
  SearchTask,
  Task,
} from '@float/types';

import {
  BATCH_DELETE_TASK_SUCCESS,
  BATCH_UPSERT_TASK_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  DELETE_TASK_META_SUCCESS,
  DELETE_TASK_SUCCESS,
  FETCH_TASKS,
  FETCH_TASKS_FAILURE,
  FETCH_TASKS_SUCCESS,
  INSERT_TASK_SUCCESS,
  INSERT_TIMEOFF_SUCCESS,
  LINKED_TASKS_UPDATE_SUCCESS,
  LOGGED_TIME_DELETED,
  MERGE_TASK_META_SUCCESS,
  PEOPLE_BULK_DELETED,
  PEOPLE_DELETED,
  PERSON_PROJECTS_UPDATE,
  PHASES_DELETED,
  PHASES_UPDATED,
  PROJECTS_BULK_DELETED,
  PROJECTS_BULK_UPDATED,
  PROJECTS_DELETED,
  PROJECTS_IMPORTED,
  PROJECTS_UPDATED,
  REPLACE_TASK_SUCCESS,
  REPLACE_TIMEOFF_SUCCESS,
  SEARCH_CONTEXT_LOAD_FINISH,
  SPLIT_TASK,
  SPLIT_TASK_START,
  UPDATE_TASK,
  UPDATE_TASK_BATCH,
  UPDATE_TASK_FAILURE,
  UPDATE_TASK_META_SUCCESS,
  UPDATE_TASK_SUCCESS,
} from '../actions';
import {
  formatTask,
  formatTaskId,
  formatTaskPriority,
  toNumberList,
} from '../lib/formatters';
import { omit, omitBy, omitOne } from '../lib/omit';

type TaskMetaUpdate = {
  task_meta_id: number;
  task_name: string;
  billable: Task['billable'];
};

export type TaskState = {
  tasks: Record<string, Task | SearchTask>;
  splitting: Record<string, boolean>;
  fetchedRootIds: Record<string, unknown>;
  isLoading: boolean;
  taskError?: any;
  fullyHydrated?: boolean;
};

export const DEFAULT_STATE: TaskState = {
  tasks: {},
  fetchedRootIds: {},
  splitting: {},
  isLoading: false,
};

export const formatTaskFieldsFromOptimisticUpdate = (task: RawTask): Task => {
  return {
    action: task.action,
    billable: task.billable ? 1 : 0,
    created_by: task.created_by,
    created: task.created,
    end_date: task.end_date,
    ext_calendar_event_id:
      task.ext_calendar_event_id && String(task.ext_calendar_event_id),
    ext_calendar_event_name: task.ext_calendar_event_name,
    ext_calendar_id: task.ext_calendar_id && String(task.ext_calendar_id),
    ext_calendar_recur_id:
      task.ext_calendar_recur_id && String(task.ext_calendar_recur_id),
    hours: task.hours,
    integration_status: Number(task.integration_status || 0),
    modified_by: task.modified_by,
    modified: task.modified,
    name: task.name ?? '',
    notes_meta: task.notes_meta || null,
    notes: task.notes || '',
    parent_task_id: task.parent_task_id,
    people_ids: task.people_id
      ? [task.people_id]
      : task.people_ids?.sort() || [],
    phase_id: task.phase_id,
    priority_info: formatTaskPriority(task),
    project_id: task.project_id,
    repeat_end_date: task.repeat_end_date || null,
    repeat_state: task.repeat_state ? (+task.repeat_state as RepeatState) : 0,
    root_task_id: task.root_task_id,
    start_date: task.start_date,
    start_time: task.start_time || '',
    status: task.status,
    task_id: String(task.task_id),
    task_meta_id: task.task_meta_id,
    allocation_type: task.allocation_type,

    data_type: 'full',

    // metadata that shouldn't be here
    instanceCount: 0,
  };
};

const hasProjectTentativeChanged = (
  currentProject: { tentative: unknown },
  prevProject?: { tentative: unknown },
) => {
  return prevProject && prevProject.tentative != currentProject.tentative;
};

const hasParentStatusChanged = (
  current: { status?: ProjectStatus },
  previous?: { status: ProjectStatus },
) => {
  if (!previous) return false;

  const statusDataExists =
    typeof previous.status !== 'undefined' &&
    typeof current.status !== 'undefined';

  if (!statusDataExists) return false;

  return previous.status !== current.status;
};

const hasProjectNonBillableChanged = (
  currentProject: { non_billable: unknown },
  prevProject?: { non_billable: unknown },
) => {
  return prevProject && prevProject.non_billable != currentProject.non_billable;
};

function handleFKDelete(
  state: TaskState,
  ids: (string | number)[] | undefined,
  foreignKey: 'project_id' | 'phase_id' | 'task_meta_id',
) {
  const idSet = new Set(toNumberList(ids));

  if (idSet.size === 0) return state;

  let deleted = false;

  const tasks = omitBy(state.tasks, (task) => {
    const id = (task as Task)[foreignKey];

    if (id && idSet.has(+id)) {
      deleted = true;
      return true;
    }

    return false;
  });

  if (!deleted) {
    return state;
  }

  return { ...state, tasks };
}

function handleRelatedDataUpdate<P extends keyof Task>(
  state: TaskState,
  ids: number[],
  payload: Partial<Pick<Task, P>>,
  foreignKey: 'project_id' | 'phase_id' | 'task_meta_id',
) {
  const propKeys = Object.keys(payload) as P[];

  if (propKeys.length === 0 || ids.length === 0) {
    return state;
  }

  const idSet = new Set(ids);

  const tasks = { ...state.tasks };
  let updated = false;

  for (const task of Object.values(tasks) as Task[]) {
    const id = task[foreignKey];
    if (
      id &&
      idSet.has(+id) &&
      propKeys.some((key) => task[key] !== payload[key])
    ) {
      updated = true;
      tasks[task.task_id] = {
        ...task,
        ...payload,
      };
    }
  }

  if (!updated) {
    return state;
  }

  return { ...state, tasks };
}

function handlePeopleDeleted(state: TaskState, ids: number[]) {
  if (!ids.length) {
    return state;
  }

  const tasks: TaskState['tasks'] = {};

  for (const task of Object.values(state.tasks)) {
    if (task.people_ids.length === 1 && ids.includes(task.people_ids[0])) {
      // delete the task
      continue;
    }

    if (task.people_ids.some((id) => ids.includes(id))) {
      // remove the person from people_ids
      tasks[task.task_id] = {
        ...task,
        people_ids: task.people_ids.filter((id) => !ids.includes(id)),
      };
    } else {
      // keep the task
      tasks[task.task_id] = task;
    }
  }

  return { ...state, tasks };
}

export type TaskAction =
  | {
      type: typeof SEARCH_CONTEXT_LOAD_FINISH;
      context: {
        tasks: {
          ids: Record<
            number,
            {
              personIds: number[];
              projectId: number;
              phaseId: number;
              billable: Task['billable'];
              status: Task['status'];
            }
          >;
          val: string;
        }[];
      };
      rebuild?: boolean;
    }
  | {
      type: typeof FETCH_TASKS;
    }
  | {
      type: typeof FETCH_TASKS_SUCCESS;
      skipFormatting: true;

      shouldRefresh: boolean;
      rootTaskId?: number;
      items: Record<string, Task>;
      rebuild?: boolean;
    }
  | {
      type: typeof FETCH_TASKS_SUCCESS;
      skipFormatting: false;

      shouldRefresh: boolean;
      rootTaskId?: number;
      items: Record<string, RawTask>;
      rebuild?: boolean;
    }
  | {
      type: typeof FETCH_TASKS_FAILURE;
      error: any;
    }
  | {
      type: typeof CREATE_TASK;
      item: RawTask;
    }
  | {
      type: typeof CREATE_TASK_SUCCESS;
      item: RawTask;
      isLiveUpdate?: boolean;
    }
  | {
      type: typeof CREATE_TASK_FAILURE;
      item: RawTask;
    }
  | {
      type: typeof UPDATE_TASK;
      item: RawTask;
      isLiveUpdate?: boolean;
    }
  | {
      type: typeof UPDATE_TASK_BATCH;
      tasks: RawTask[];
    }
  | {
      type: typeof UPDATE_TASK_SUCCESS;
      item: RawTask;
      previous: Task;
      isLiveUpdate?: boolean;
    }
  | {
      type: typeof UPDATE_TASK_FAILURE;
      item: Pick<
        Task,
        'task_id' | 'integration_status' | 'root_task_id' | 'parent_task_id'
      >;
      previous: Task;
      error?: unknown;
    }
  | {
      type: typeof SPLIT_TASK_START;
      taskId: string;
    }
  | {
      type: typeof SPLIT_TASK;
      newTask?: RawTask;
      updatedTask?: RawTask;
      updatedTaskId: string;
      previous: Task;
      undoBatchId: unknown;
      otherAffectedTasks?: RawTask[];
    }
  | {
      type: typeof DELETE_TASK_SUCCESS;
      item: RawTask;
    }
  | {
      type:
        | typeof REPLACE_TASK_SUCCESS
        | typeof REPLACE_TIMEOFF_SUCCESS
        | typeof INSERT_TASK_SUCCESS
        | typeof INSERT_TIMEOFF_SUCCESS;
      response: {
        task?: RawTask[];
        delete?: {
          task?: Pick<Task, 'task_id'>[];
        };
      };
      item?: RawTask;
    }
  | {
      type: typeof LINKED_TASKS_UPDATE_SUCCESS;
      item: { tasks?: RawTask[] };
    }
  | {
      type: typeof BATCH_UPSERT_TASK_SUCCESS;
      item: { tasks?: RawTask[] };
    }
  | {
      type: typeof BATCH_DELETE_TASK_SUCCESS;
      item: {
        delete?: {
          extCalendarId?: number;
          extRecurIds?: number[];
          extEventIds?: number[];
        };
      };
    }
  | {
      type: typeof PERSON_PROJECTS_UPDATE;
      personId: number;
      update: { del: number[]; add: number[] };
    }
  | {
      type: typeof PROJECTS_DELETED;
      projectId: number;
    }
  | {
      type: typeof PROJECTS_BULK_DELETED;
      ids: number[];
    }
  | {
      type: typeof PHASES_DELETED;
      phaseIds: number[];
    }
  | {
      type: typeof PROJECTS_UPDATED;
      project?: Project;
      prevProject?: Project;
    }
  | {
      type: typeof PHASES_UPDATED;
      phases?: Phase[];
      prevPhases?: Phase[];
    }
  | {
      type: typeof PROJECTS_BULK_UPDATED;
      ids?: number[];
      fields?: {
        non_billable?: 0 | 1;
        tentative?: 0 | 1;
        status?: ProjectStatus;
      };
    }
  | {
      type: typeof PROJECTS_IMPORTED;
      result?: Project[];
    }
  | {
      type: typeof PEOPLE_DELETED;
      person?: Person;
    }
  | {
      type: typeof PEOPLE_BULK_DELETED;
      ids: number[];
    }
  | {
      type: typeof LOGGED_TIME_DELETED;
      task_id?: number;
    }
  | {
      type: typeof UPDATE_TASK_META_SUCCESS;
      item: TaskMetaUpdate;
    }
  | {
      type: typeof DELETE_TASK_META_SUCCESS;
      ids?: number[];
    }
  | {
      type: typeof MERGE_TASK_META_SUCCESS;
      ids?: string[];
      item: TaskMetaUpdate;
    };

const tasks = (state = DEFAULT_STATE, action: TaskAction) => {
  switch (action.type) {
    case SEARCH_CONTEXT_LOAD_FINISH: {
      const tasks = action.rebuild ? {} : { ...state.tasks };

      for (const { val, ids } of action.context.tasks) {
        for (const [task_id, task] of Object.entries(ids)) {
          if (!tasks[task_id]) {
            tasks[task_id] = {
              data_type: 'search',
              task_id,
              name: val,
              people_ids: task.personIds || [],
              project_id: task.projectId,
              phase_id: task.phaseId,
              billable: task.billable,
              status: task.status,
            };
          }
        }
      }

      return {
        ...state,
        tasks,
      };
    }

    case FETCH_TASKS:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_TASKS_SUCCESS: {
      const tasks = action.shouldRefresh ? {} : { ...state.tasks };

      // The new tasks are already formatted
      if (action.skipFormatting) {
        Object.assign(tasks, action.items);
      } else {
        for (const task of Object.values(action.items)) {
          const id = task.task_id;

          if (
            action.rebuild ||
            !tasks[id] ||
            tasks[id].data_type === 'search'
          ) {
            tasks[id] = formatTask(task);
          }
        }
      }

      const fetchedRootIds = action.rootTaskId
        ? { ...state.fetchedRootIds, [action.rootTaskId]: true }
        : state.fetchedRootIds;

      return {
        ...state,
        tasks,
        fetchedRootIds,
        isLoading: false,
        fullyHydrated: true,
      };
    }

    case FETCH_TASKS_FAILURE:
      return {
        ...state,
        taskError: action.error,
        isLoading: false,
      };

    case CREATE_TASK: {
      const tasks = { ...state.tasks };

      const task = formatTask(action.item);

      tasks[action.item.createdTs] = task;

      return { ...state, tasks };
    }

    case CREATE_TASK_SUCCESS: {
      const task = formatTask(action.item);

      task.replacesTempId = action.item.createdTs;

      const tasks = omitOne(state.tasks, action.item.createdTs?.toString());
      tasks[task.task_id] = task;

      return { ...state, tasks };
    }

    case CREATE_TASK_FAILURE: {
      const tasks = omitOne(state.tasks, action.item.createdTs?.toString());

      return { ...state, tasks };
    }

    case UPDATE_TASK_BATCH: {
      const tasks = { ...state.tasks };

      for (const task of action.tasks) {
        tasks[task.task_id] = formatTask(task);
      }

      return {
        ...state,
        tasks,
      };
    }

    case UPDATE_TASK: {
      if (!action.item) {
        console.log('Trying to update task without item');
        return state;
      }

      const tasks = { ...state.tasks };
      const task_id = action.item.task_id;

      if (action.isLiveUpdate) {
        tasks[task_id] = formatTask(action.item, formatTaskId(action.item));
      } else {
        tasks[task_id] = formatTaskFieldsFromOptimisticUpdate(action.item);
      }

      // Set root_task_id on a linked task's root task,
      // so it is immediately recognized as a linked task
      if (action.item.root_task_id) {
        const rootTask = tasks[action.item.root_task_id] as Task;
        if (rootTask && !rootTask.root_task_id) {
          rootTask.root_task_id = action.item.root_task_id;
        }
      }

      return {
        ...state,
        tasks,
      };
    }

    case UPDATE_TASK_SUCCESS: {
      const { item, previous } = action;

      // ensure the updated integration_status and root_task_id is set from api3
      if (
        !isObject(item) ||
        (item.integration_status === previous.integration_status &&
          item.root_task_id == previous.root_task_id &&
          item.parent_task_id == previous.parent_task_id)
      ) {
        return state;
      }

      const tasks = { ...state.tasks };

      const taskId = item.task_id;

      const oldTask = tasks[taskId];

      // The task is coming from the search api, so it's outside the view
      if (oldTask.data_type === 'search') return state;

      const newTask = {
        ...oldTask,
        integration_status: item.integration_status,
        root_task_id: item.root_task_id,
        parent_task_id: item.parent_task_id,
      };

      // If the live update for linked tasks arrived before the response for
      // updating the tasks (typically happens when removing links via ETM),
      // we might have a newer version than the server response. Therefore,
      // we only want to update the root_task_id and parent_task_ids from the
      // server response if the previous value matches what's currently in Redux
      if (oldTask.root_task_id !== previous.root_task_id) {
        newTask.root_task_id = oldTask.root_task_id;
      }
      if (oldTask.parent_task_id !== previous.parent_task_id) {
        newTask.parent_task_id = oldTask.parent_task_id;
      }

      tasks[taskId] = newTask;

      return { ...state, tasks };
    }

    case UPDATE_TASK_FAILURE: {
      const tasks = { ...state.tasks };
      tasks[formatTaskId(action.item)] = action.previous;

      return {
        ...state,
        tasks,
      };
    }

    case SPLIT_TASK_START: {
      const tasks = { ...state.tasks };
      const { taskId } = action;

      return {
        ...state,
        tasks,
        splitting: { ...state.splitting, [taskId]: true },
      };
    }

    case SPLIT_TASK: {
      let tasks = { ...state.tasks };
      const { updatedTask, updatedTaskId } = action;

      if (action.newTask) {
        const newTask = formatTask(action.newTask);
        newTask.replacesTempId = action.newTask.createdTs;
        tasks[newTask.task_id] = newTask;
      }

      // Sometimes updatedTask is undefined
      // https://app.rollbar.com/a/float/fix/item/fe-web-app/5180
      if (updatedTask) {
        tasks[updatedTask.task_id] =
          formatTaskFieldsFromOptimisticUpdate(updatedTask);
      } else {
        tasks = omitOne(tasks, updatedTaskId);
      }

      action.otherAffectedTasks?.forEach((task) => {
        tasks[task.task_id] = formatTask(task);
      });

      return {
        ...state,
        tasks,
        splitting: { ...state.splitting, [updatedTaskId]: false },
      };
    }

    case DELETE_TASK_SUCCESS: {
      return {
        ...state,
        tasks: omitOne(state.tasks, formatTaskId(action.item)),
      };
    }

    case REPLACE_TASK_SUCCESS:
    case INSERT_TASK_SUCCESS:
    case REPLACE_TIMEOFF_SUCCESS:
    case INSERT_TIMEOFF_SUCCESS: {
      const {
        task: tasksToUpdate = [],
        delete: { task: tasksToDelete = [] } = {},
      } = action.response || {};

      if (!tasksToUpdate.length && !tasksToDelete.length) {
        return state;
      }

      const tasks: TaskState['tasks'] = omit(
        state.tasks,
        tasksToDelete.map(({ task_id }) => task_id.toString()),
      );

      tasksToUpdate.forEach((update) => {
        if (update.task_id) {
          const task = formatTask(update);

          tasks[task.task_id] = task;

          if (action.item && action.item.temporaryId) {
            task.replacesTempId = action.item.temporaryId;
          }
        }
      });

      return { ...state, tasks };
    }

    case LINKED_TASKS_UPDATE_SUCCESS:
    case BATCH_UPSERT_TASK_SUCCESS: {
      if (!action.item.tasks?.length) {
        return state;
      }

      const tasks = {
        ...state.tasks,
      };

      for (const task of action.item.tasks) {
        tasks[task.task_id] = formatTask(task);
      }

      return { ...state, tasks };
    }

    case BATCH_DELETE_TASK_SUCCESS: {
      const {
        delete: { extCalendarId, extRecurIds = [], extEventIds = [] } = {},
      } = action.item;
      const deleteAllForCalendar = !extRecurIds.length && !extEventIds.length;

      const tasks = omitBy(state.tasks, (task) => {
        if (task.data_type === 'search') return false;

        // integration_status of 0 means it is unsynced and therefore should not be deleted
        if (
          task.ext_calendar_id === String(extCalendarId) &&
          task.integration_status > 0
        ) {
          if (deleteAllForCalendar) {
            return true;
          } else {
            if (
              task.ext_calendar_recur_id &&
              extRecurIds.map(String).includes(task.ext_calendar_recur_id)
            ) {
              return true;
            }
            if (
              task.ext_calendar_event_id &&
              extEventIds.map(String).includes(task.ext_calendar_event_id)
            ) {
              return true;
            }
          }
        }

        return false;
      });

      return { ...state, tasks };
    }

    case PERSON_PROJECTS_UPDATE: {
      const { update, personId } = action;

      // we need to react only to the deletes
      if (update.del.length === 0) return state;

      const tasks: TaskState['tasks'] = {};

      const deletedProjects = new Set(update.del);

      for (const task of Object.values(state.tasks)) {
        const peopleIds = task.people_ids;

        if (
          deletedProjects.has(task.project_id) &&
          peopleIds.includes(personId)
        ) {
          if (peopleIds.length > 1) {
            tasks[task.task_id] = {
              ...task,
              people_ids: peopleIds.filter((id) => id !== personId),
            };
          }
        } else {
          tasks[task.task_id] = task;
        }
      }

      return { ...state, tasks };
    }

    case PROJECTS_DELETED:
      return handleFKDelete(state, [action.projectId], 'project_id');

    case PROJECTS_BULK_DELETED:
      return handleFKDelete(state, action.ids, 'project_id');

    case PHASES_DELETED:
      return handleFKDelete(state, action.phaseIds, 'phase_id');

    case PROJECTS_UPDATED: {
      const { project, prevProject } = action;

      if (!project || !project.project_id) {
        return state;
      }

      const payload: Partial<Pick<Task, 'status' | 'billable'>> = {};

      if (hasProjectTentativeChanged(project, prevProject)) {
        // @ts-expect-error this works thanks to casting
        payload.status = project.tentative == '1' ? 1 : 2;
      }

      if (hasParentStatusChanged(project, prevProject)) {
        // If parent status data available, use that to derive task status
        payload.status = project.status;
      }

      if (hasProjectNonBillableChanged(project, prevProject)) {
        payload.billable = project.non_billable ? 0 : 1;
      }

      return handleRelatedDataUpdate(
        state,
        [project.project_id],
        payload,
        'project_id',
      );
    }

    case PHASES_UPDATED: {
      const { phases = [], prevPhases = [] } = action;
      // TODO: Change the action to accept only one phase
      const phase = phases && phases.length && phases[0];
      const prevPhase = prevPhases && prevPhases.length && prevPhases[0];
      if (!phase || !prevPhase) {
        return state;
      }

      const payload: Partial<Pick<Task, 'status' | 'billable'>> = {};

      if (hasProjectTentativeChanged(phase, prevPhase)) {
        // @ts-expect-error this works thanks to casting
        payload.status = phase.tentative == '1' ? 1 : 2;
      }

      if (hasParentStatusChanged(phase, prevPhase)) {
        // If parent status data available, use that to derive task status
        payload.status = phase.status;
      }

      if (hasProjectNonBillableChanged(phase, prevPhase)) {
        payload.billable = phase.non_billable ? 0 : 1;
      }

      return handleRelatedDataUpdate(
        state,
        [phase.phase_id],
        payload,
        'phase_id',
      );
    }

    case PROJECTS_BULK_UPDATED: {
      const { ids, fields } = action;

      if (!ids?.length || !fields) return state;

      const payload: Partial<Pick<Task, 'status' | 'billable'>> = {};

      if (fields.tentative !== undefined) {
        payload.status = fields.tentative === 1 ? 1 : 2;
      }
      if (fields.status !== undefined) {
        // If status data available, use that to derive task status
        payload.status = fields.status;
      }
      if (fields.non_billable !== undefined) {
        payload.billable = fields.non_billable === 1 ? 0 : 1;
      }

      return handleRelatedDataUpdate(state, ids, payload, 'project_id');
    }

    case PROJECTS_IMPORTED: {
      const { result: projects } = action;
      if (!projects || !projects.length) {
        return state;
      }

      const projectsByIds = keyBy(projects, 'project_id');

      const tasks = { ...state.tasks };

      let updated = false;

      for (const task of Object.values(tasks)) {
        const project = projectsByIds[task.project_id];
        if (project) {
          updated = true;
          tasks[task.task_id] = {
            ...task,
            billable: project.non_billable ? 0 : 1,
          };
        }
      }

      if (!updated) {
        return state;
      }

      return { ...state, tasks };
    }

    case PEOPLE_DELETED: {
      const { person } = action;

      if (!person) return state;

      return handlePeopleDeleted(state, [person.people_id]);
    }
    case PEOPLE_BULK_DELETED: {
      const { ids = [] } = action;

      return handlePeopleDeleted(state, ids);
    }

    case LOGGED_TIME_DELETED: {
      if (action.task_id) {
        const tasks = { ...state.tasks };
        const task = { ...tasks[action.task_id] };

        // If a logged time that references a task was deleted, we need to make
        // sure that we replace the task object with a new one in this state.
        // Doing this will cause task references to be regenerated to ensure
        // the log time view stays accurate. Note that this behavior is only
        // triggered if a user cmd+z's after logging a task reference.
        tasks[task.task_id] = task;

        return {
          ...state,
          tasks,
        };
      }
      return state;
    }

    case UPDATE_TASK_META_SUCCESS: {
      if (!action.item?.task_meta_id) return state;

      const taskMetaId = +action.item.task_meta_id;

      const payload = {
        name: action.item.task_name,
        billable: action.item.billable,
      };

      return handleRelatedDataUpdate(
        state,
        [taskMetaId],
        payload,
        'task_meta_id',
      );
    }

    case MERGE_TASK_META_SUCCESS: {
      if (!action.item) {
        return state;
      }

      const ids = toNumberList(action.ids);

      const payload = {
        name: action.item.task_name,
        billable: action.item.billable,
      };

      return handleRelatedDataUpdate(state, ids, payload, 'task_meta_id');
    }

    case DELETE_TASK_META_SUCCESS: {
      return handleFKDelete(state, action.ids, 'task_meta_id');
    }

    default: {
      return state;
    }
  }
};

export default tasks;
