import {
  API3_BASE_URI,
  COMPANY_SELECT_PATH,
  LOGIN_PATH,
  RESOURCES_WITH_TOKEN_AUTH,
  SETTINGS_BASE_URI,
  SETTINGS_RESOURCES,
  TOP_LEVEL_RESOURCES,
  UPDATE_PASSWORD_PATH,
} from './makeRequest.constants';

export const getBaseOrigin = (
  preventRelativeHostname: boolean,
  appHostname?: string,
) => `${preventRelativeHostname ? appHostname : ''}/`;

export const getSettingsOrigin = (apiHostname?: string) =>
  `${apiHostname}/${SETTINGS_BASE_URI}`;

export const getApi3Origin = (
  preventRelativeHostname: boolean,
  appHostname?: string | undefined,
) => `${preventRelativeHostname ? appHostname : ''}/${API3_BASE_URI}`;

export const getEndpointConfig = (
  preventRelativeHostname: boolean,
  appHostname: string | undefined,
  apiHostname: string | undefined,
  resource: string | undefined,
) => {
  let apiOrigin: string;
  let isApi3 = true;

  if (resource && SETTINGS_RESOURCES.includes(resource)) {
    apiOrigin = getSettingsOrigin(apiHostname);
    isApi3 = false;
  } else if (resource && TOP_LEVEL_RESOURCES.includes(resource)) {
    apiOrigin = getBaseOrigin(preventRelativeHostname, appHostname);
    isApi3 = false;
  } else {
    apiOrigin = getApi3Origin(preventRelativeHostname, appHostname);
  }

  const shouldUseAccessToken =
    isApi3 || (resource && RESOURCES_WITH_TOKEN_AUTH.includes(resource));

  return { apiOrigin, shouldUseAccessToken };
};

// Float2 responses have varying structures. For error handling, we might encounter responses formatted as:
// [{ "0": { field: "...", message: "..." } }], or:
// [{ field: "...", message: "..." }]
export const normalizeServerError = (serverError: any) => {
  if (typeof serverError !== 'object' || serverError === null) {
    return serverError;
  }

  const hasNumericKeys = Object.keys(serverError).some(
    (key) => !isNaN(Number(key)),
  );

  if (!hasNumericKeys) {
    return serverError;
  }

  const normalizedError: { [key: string]: any } = {};

  return Object.entries(serverError).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      // e.g, { "0": { field: "..." }}
      return { ...acc, ...value };
    }
    // e.g., { "0": "..." }
    acc[key] = value;
    return acc;
  }, normalizedError);
};

export const handleRedirects = (redirected: boolean, url: string) => {
  let didRedirect = false;

  if (redirected) {
    if (
      url.endsWith(LOGIN_PATH) ||
      url.endsWith(UPDATE_PASSWORD_PATH) ||
      url.endsWith(COMPANY_SELECT_PATH)
    ) {
      window.location.replace(url);
      didRedirect = true;
    }
  }

  return didRedirect;
};
