import { useState } from 'react';
import useMount from 'react-use/esm/useMount';

import { getIsDebugTaskNamesEnabled } from '@float/libs/devtools/debugTaskNames';

export const useIsDebugTaskNamesEnabled = () => {
  const [enabled, setEnabled] = useState(false);

  useMount(async () => {
    const enabled = await getIsDebugTaskNamesEnabled();

    setEnabled(enabled);
  });

  return enabled;
};
