import React from 'react';

export default function ({
  size = '24',
  style,
}: {
  size?: number | string;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M5.47727 17.5H8.02273C8.42439 17.5 8.75 17.1604 8.75 16.7414V7.25861C8.75 6.83964 8.42439 6.5 8.02273 6.5H5.47727C5.07561 6.5 4.75 6.83964 4.75 7.25861V16.7414C4.75 17.1604 5.07561 17.5 5.47727 17.5Z"
        stroke="#344765"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M15.9773 17.5H18.5227C18.9244 17.5 19.25 17.1604 19.25 16.7414V7.25861C19.25 6.83964 18.9244 6.5 18.5227 6.5H15.9773C15.5756 6.5 15.25 6.83964 15.25 7.25861V16.7414C15.25 17.1604 15.5756 17.5 15.9773 17.5Z"
        stroke="#344765"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12.5 18C12.5 18.2761 12.2761 18.5 12 18.5C11.7239 18.5 11.5 18.2761 11.5 18C11.5 17.7239 11.7239 17.5 12 17.5C12.2761 17.5 12.5 17.7239 12.5 18Z"
        stroke="#344765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12.5 6C12.5 6.27614 12.2761 6.5 12 6.5C11.7239 6.5 11.5 6.27614 11.5 6C11.5 5.72386 11.7239 5.5 12 5.5C12.2761 5.5 12.5 5.72386 12.5 6Z"
        stroke="#344765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12.5 10C12.5 10.2761 12.2761 10.5 12 10.5C11.7239 10.5 11.5 10.2761 11.5 10C11.5 9.72386 11.7239 9.5 12 9.5C12.2761 9.5 12.5 9.72386 12.5 10Z"
        stroke="#344765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12.5 14C12.5 14.2761 12.2761 14.5 12 14.5C11.7239 14.5 11.5 14.2761 11.5 14C11.5 13.7239 11.7239 13.5 12 13.5C12.2761 13.5 12.5 13.7239 12.5 14Z"
        stroke="#344765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
