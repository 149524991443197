import React from 'react';

import { ButtonAction } from '@float/ui/components/ButtonAction';
import { Row } from '@float/ui/deprecated/Layout/Layout';
import { IconKebabDots } from '@float/ui/icons/essentials/IconKebabDots';

import { ActivityCircle, Item } from './Task.styles';

export function MockTaskItem({ name }) {
  return (
    <Item className="read-only">
      <div className="name">
        <div className="cb-or-status">
          <ActivityCircle isInUse={false} />
        </div>
        <div className="name-wrapper">
          <Row justiContent="flex-start" className="name-input">
            {name}
          </Row>
        </div>
      </div>
      <div className="controls">
        <ButtonAction icon={IconKebabDots} disabled={true} />
      </div>
    </Item>
  );
}
