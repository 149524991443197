import React from 'react';
import styled from 'styled-components';

import { TimePicker } from '@float/common/components/DatePicker/TimePicker';
import { addMinutes, toFriendlyTime } from '@float/common/lib/time';
import { moment } from '@float/libs/moment';

const TimeContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;

  width: 85px;

  margin-right: 20px;

  input {
    width: 100%;
  }
`;

let etmThis;

function setHoursPd(newState) {
  const start = moment(`2010-01-01 ${newState.startTime}`);
  const end = moment(`2010-01-01 ${newState.endTime}`);
  const minutes = end.diff(start, 'minutes');
  newState.hoursPd = +(minutes / 60).toFixed(2);
}

function handleStartTimeChange(value) {
  if (value > '23:58') value = '23:58';

  etmThis.setState((ps) => {
    const newState = { startTime: value, endTime: ps.endTime };

    if (newState.startTime !== ps.startTime) {
      etmThis.startTimeManuallyChanged = true;
    }

    if (!etmThis.endTimeManuallyChanged) {
      newState.endTime = addMinutes(value, Math.round(ps.hoursPd * 60));
    }

    if (newState.endTime <= value) {
      newState.endTime = addMinutes(value, 1);
    }

    setHoursPd(newState);
    return newState;
  });
}

function handleEndTimeChange(value, changeFromArrowKey) {
  if (value < '00:01') value = '00:01';

  etmThis.setState((ps) => {
    const newState = { startTime: ps.startTime, endTime: value };

    if (newState.endTime !== ps.endTime) {
      etmThis.endTimeManuallyChanged = true;
    }

    if (newState.startTime >= value) {
      if (changeFromArrowKey) {
        newState.startTime = addMinutes(value, -1);
      } else {
        newState.startTime = value;
        newState.endTime = addMinutes(value, 1);
      }
    }

    setHoursPd(newState);
    newState.totalHours = newState.hoursPd * ps.length;
    return newState;
  });
}

const setStartTimeRef = (el) => {
  etmThis.startTimeRef = el;
};

export const getStartTimeElem = (self, companyPrefs) => {
  etmThis = self;
  const readOnly = etmThis.isReadOnly() || etmThis.state.integrationSyncLocked;
  return (
    <TimeContainer readOnly={readOnly}>
      {readOnly ? (
        etmThis.renderReadOnlyInput(
          'Time',
          toFriendlyTime(etmThis.state.startTime, companyPrefs),
          {
            noMargin: true,
            style: { flex: 1 },
          },
        )
      ) : (
        <TimePicker
          ref={setStartTimeRef}
          label="Time"
          value={etmThis.state.startTime}
          onChange={handleStartTimeChange}
        />
      )}
    </TimeContainer>
  );
};

export const getEndTimeElem = (self, companyPrefs) => {
  etmThis = self;
  const readOnly = etmThis.isReadOnly() || etmThis.state.integrationSyncLocked;

  return (
    <TimeContainer readOnly={readOnly}>
      {readOnly ? (
        etmThis.renderReadOnlyInput(
          <span>&nbsp;</span>,
          toFriendlyTime(etmThis.state.endTime, companyPrefs),
          {
            noMargin: true,
            style: { flex: 1 },
          },
        )
      ) : (
        <TimePicker
          label="&nbsp;"
          value={etmThis.state.endTime}
          onChange={handleEndTimeChange}
        />
      )}
    </TimeContainer>
  );
};
