import { useSelector } from 'react-redux';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { selectIsProjectCodesEnabled } from '../selectors/projects';

export const useProjectCodesPreference = () => {
  const isProjectCodesFeatureFlagEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.ProjectCodes,
  );

  const isProjectCodesCompanyPrefEnabled = useSelector(
    selectIsProjectCodesEnabled,
  );
  return {
    isProjectCodesEnabled: Boolean(
      isProjectCodesFeatureFlagEnabled && isProjectCodesCompanyPrefEnabled,
    ),
    isProjectCodesFeatureFlagEnabled,
  };
};
