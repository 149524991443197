import { cloneDeep, flatMap } from 'lodash';

import { getLoggedTimeHours } from '@float/common/serena/util/getLoggedTimeHours';
import { config } from '@float/libs/config';

import buildItem from '../Cell/MainCell/buildItem';

// Using a collator is a bit faster than directly calling .localeCompare
const collator = new Intl.Collator(config.locale);

function extractSingleDayItems(cell, subCol) {
  if (!cell) return { items: [], scheduledHours: 0 };

  // In Single Day View, we always want w = 1 and x = 0. Extract the items that
  // are relevant for this specific day, clone them, and adjust.
  const items = flatMap(cell.items, (item) => {
    if (item.x > subCol) return [];
    if (item.x + item.w - 1 < subCol) return [];

    const clone = cloneDeep(item);
    clone.w = 1;
    clone.x = 0;
    clone.isStart = true;
    clone.isEnd = true;

    // We want to keep referential integrity on the entity as it the object
    // equality influences loading/deleting.
    clone.entity = item.entity;

    return clone;
  });

  // Additionally, gaps in between tasks are not necessary. Vertically compact
  // the day's items. Note that we have to sort the items first, then set the
  // y values, then return to the stable sorted version.
  items.sort((a, b) => a.sortIdx - b.sortIdx);
  let curY = 0;
  items.forEach((ci, idx) => {
    if (!ci.h) return;
    ci.y = curY;
    curY += ci.h;
  });
  items.sort((a, b) => collator.compare(a.key, b.key));

  return { items, scheduledHours: curY };
}

export function getContent(props, dayNum) {
  const {
    cells,
    dates,
    suvPersonId,
    dayWidth,
    hourHeight,
    actions,
    row,
    rowMeta,
    logTimeView,
    logMyTimeView,
  } = props;

  const buildItemProps = {
    dayWidth,
    actions,
    hourHeight,
    row,
    rowMeta,
  };

  const day = dates.fromNum(dayNum);
  const [colIdx, subCol] = dates.toDescriptor(day);
  const cell =
    cells[`${logTimeView ? 'logged_time' : 'person'}-${suvPersonId}:${colIdx}`];

  const extraProps = { suvSingleDay: day, logTimeView, logMyTimeView };

  const inner = [];

  if (rowMeta && rowMeta.getNonWorkDayItems(day)) {
    rowMeta.getNonWorkDayItems(day).forEach((i) => {
      if (i.x === subCol) {
        const clone = cloneDeep(i);
        clone.x = 0;
        inner.push(buildItem(buildItemProps, clone, { suvSingleDay: day }));
      }
    });
  }

  const startsAfter = rowMeta && rowMeta.start_date && rowMeta.start_date > day;
  const endsBefore = rowMeta && rowMeta.end_date && rowMeta.end_date < day;

  if (startsAfter || endsBefore) {
    if (rowMeta.getDailyWorkHours(day)[subCol] !== 0) {
      inner.push(
        buildItem(
          buildItemProps,
          {
            type: 'nonWorkDay',
            key: `nonWorkDay:${0}`,
            x: 0,
            w: 1,
          },
          extraProps,
        ),
      );
    }
  }

  const overtimeItems = rowMeta && rowMeta.getOvertimeItems(day, cell);

  if (cell && cell.dayHours && overtimeItems && overtimeItems.length) {
    // By definition, there is no overtime on a one-off day as the entire day
    // is overtime.
    const isPrevented = cell.items.some(
      (ci) => ci.type === 'oneOff' && ci.x <= subCol && ci.x + ci.w > subCol,
    );

    const isOvertime =
      cell.dayHours[subCol] > rowMeta.getDailyWorkHours(day)[subCol];

    if (!isPrevented && isOvertime) {
      const clone = cloneDeep(overtimeItems[subCol]);
      clone.x = 0;
      inner.push(buildItem(buildItemProps, clone, extraProps));
    }
  }

  const { items, scheduledHours } = extractSingleDayItems(cell, subCol);

  inner.push(...items.map((i) => buildItem(buildItemProps, i, extraProps)));

  return { items: inner, scheduledHours };
}

export function getLoggedHours(props, dayNum) {
  const { cells, dates, suvPersonId, logTimeView, filters, filteredEntities } =
    props;

  const day = dates.fromNum(dayNum);
  const [colIdx, subCol] = dates.toDescriptor(day);
  const cell =
    cells[`${logTimeView ? 'logged_time' : 'person'}-${suvPersonId}:${colIdx}`];

  return getLoggedTimeHours(cell?.items, { filteredEntities, filters }, subCol)
    .logged;
}
