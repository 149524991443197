import React, { ReactNode, useState } from 'react';
import { t } from '@lingui/macro';
import modalManagerHoc from 'modalManager/modalManagerHoc';

import { noop } from '@float/libs/utils/noop';
import { Button } from '@float/ui/deprecated/Button/Button';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';

export type ConfirmModalProps = {
  cancelAppearance?: string;
  cancelLabel?: ReactNode;
  confirmAppearance?: string;
  confirmLabel?: ReactNode;
  hideCancel?: boolean;
  message?: ReactNode;
  // Triggered when the user clicks on the "Cancel" button
  onCancel?: () => void;
  // Triggered when the user clicks on the "Confirm" button
  onConfirm?: () => void | Promise<void>;
  // Triggered when the user clicks on the Cancel or Confirm button.
  onClose?: () => void;
  shouldCloseOnEsc?: boolean;
  title?: ReactNode;
  center?: boolean;
};

export function ConfirmModal({
  cancelAppearance = 'secondary',
  cancelLabel,
  confirmAppearance,
  confirmLabel,
  hideCancel,
  message,
  onCancel = noop,
  onClose = noop,
  onConfirm = noop,
  shouldCloseOnEsc = true,
  title,
  center,
}: ConfirmModalProps) {
  const [loading, setLoading] = useState(false);

  function handleCancelClick(e?: MouseEvent) {
    e?.stopPropagation();
    onCancel();
    onClose();
  }

  return (
    <Modal
      isOpen={true}
      center={center}
      shouldCloseOnBgClick={false}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onClose={handleCancelClick}
    >
      {title && (
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
      )}
      {message && <ModalBody>{message}</ModalBody>}
      <ModalActions>
        <Button
          appearance={confirmAppearance}
          autoFocus
          loader={loading}
          onClick={async (e: MouseEvent) => {
            e.stopPropagation();
            const promise = onConfirm();

            if (promise?.then) {
              setLoading(true);
              try {
                await promise;

                onClose();
              } catch (err) {}

              setLoading(false);

              return;
            }

            onClose();
          }}
        >
          {confirmLabel || t`OK`}
        </Button>
        {!hideCancel && (
          <Button appearance={cancelAppearance} onClick={handleCancelClick}>
            {cancelLabel || t`Cancel`}
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
}

function ManagedConfirmModalWrapper(props: {
  manageModal: (params: { visible: false; modalType: 'ConfirmModal' }) => void;
  modalSettings: ConfirmModalProps;
}) {
  const handleClose = () => {
    props.manageModal({
      visible: false,
      modalType: 'ConfirmModal',
    });

    if (typeof props.modalSettings.onClose === 'function') {
      props.modalSettings.onClose();
    }
  };

  return <ConfirmModal {...props.modalSettings} onClose={handleClose} />;
}

export const ManagedConfirmModal = modalManagerHoc({
  Comp: ManagedConfirmModalWrapper,
  modalType: 'ConfirmModal',
});
