//  Since prompts is a varchar(255) field in db, and list of comma-separated prompts stored in it might grow over

import { PROMPTS } from '@float/constants/prompts';
import { PromptId } from '@float/types/prompts';

import { OnboardingSurveyData } from './types';

/**
 * Fill this array with all the feature callouts prompts
 *
 * It is used to mark these propmpts as done for the users that are
 * landing on the onboarding process.
 */
export const EXCLUDE_FOR_NEW_USERS: Array<PromptId> = [
  PROMPTS.dateRangeInsightsPercentage,
  PROMPTS.sidePanelSplash,
  PROMPTS.projectSetupTour,
  PROMPTS.phasesInTemplatesSplash,
  PROMPTS.allocationByTotalHours,
  PROMPTS.projectPlanInNav,
  PROMPTS.allocationByPercentage,
];

export const OnboardingSurveyConfig = {
  'Sign up': {
    prompts: [PROMPTS.welcomeGetToKnowYou],
  },
  Goals: {
    prompts: [PROMPTS.onboardingSurveyGoals],
  },
  'Add project': {
    prompts: [PROMPTS.welcomeAddProject],
  },
  'Add people': {
    prompts: [PROMPTS.welcomeAddPerson],
  },
  'Setup complete': {
    excludeFromNavigation: true,
    prompts: [PROMPTS.onboardingSurveySuccess],
  },
};

export const OnboardingSurveyForExampleData: Partial<OnboardingSurveyData> = {
  'Sign up': {
    prompts: [PROMPTS.welcomeGetToKnowYou],
  },
  'Setup complete': {
    excludeFromNavigation: true,
    prompts: [PROMPTS.onboardingSurveySuccess],
  },
};
