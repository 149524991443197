import React from 'react';

import { formatPercentage } from '@float/common/lib/budget/helpers/getPercentage';
import { selectPersonWorkDaysCountInRange } from '@float/common/selectors/schedule/selectPersonWorkDaysCountInRange';
import { selectPersonWorkHoursInRange } from '@float/common/selectors/schedule/selectPersonWorkHoursInRange';
import { useAppSelectorWithParams } from '@float/common/store';
import { CellItem } from '@float/types';

import { getCellItemPersonId } from '../utils/getCellItemPersonId';

import { percentageElement } from '../../EntityItemStyles.css';

export type PercentageElementsProps = {
  item: CellItem<'task'>;
};

export const PercentageElements = ({ item }: PercentageElementsProps) => {
  const personId = getCellItemPersonId(item.rowId);

  const workHoursInRange = useAppSelectorWithParams(
    selectPersonWorkHoursInRange,
    {
      personId,
      startDate: item.entity.start_date,
      endDate: item.entity.end_date,
    },
  );

  const workDaysCount = useAppSelectorWithParams(
    selectPersonWorkDaysCountInRange,
    {
      personId,
      startDate: item.entity.start_date,
      endDate: item.entity.end_date,
    },
  );

  const workHoursAllocated = workDaysCount * (item.entity.hours ?? 0);

  if (!workHoursInRange || !workHoursAllocated) return null;

  const percentage = formatPercentage(workHoursAllocated / workHoursInRange);

  return <div className={percentageElement}>{percentage}</div>;
};
