import React from 'react';

import { getDisplayNotesExcerpt } from '@float/common/lib/notes';
import { displayHoursFormatIsTime } from '@float/common/lib/timer/displayHoursFormat';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';
import { getUser } from '@float/common/selectors/currentUser';
import { getPhaseById } from '@float/common/selectors/phases';
import { selectEnhancedProjectById } from '@float/common/selectors/projects/selectEnhancedProjectById';
import { useAppSelectorStrict } from '@float/common/store';
import { config } from '@float/libs/config';
import { CellItem } from '@float/types';

import { isArchived } from './box/utils/isArchived';

import * as styles from './LoggedTimeItemTooltip.css';

interface LoggedTimeItemTooltipContentProps {
  item: CellItem<'loggedTime'>;
}

export const LoggedTimeItemTooltipContent: React.FC<
  LoggedTimeItemTooltipContentProps
> = ({ item }) => {
  const { entity } = item;
  const elements = [];

  const project = useAppSelectorStrict((state) =>
    selectEnhancedProjectById(state, entity.project_id),
  );

  const phase = useAppSelectorStrict((state) => {
    const phase = entity.phase_id && getPhaseById(state, entity.phase_id);
    return phase || undefined;
  });

  const user = useAppSelectorStrict(getUser);

  let clientElement = null;
  if (project) {
    clientElement =
      project.client_name !== 'No Client' ? project.client_name : null;
    if (isArchived(project, phase)) {
      if (clientElement) {
        clientElement += ' (Archived)';
      } else {
        clientElement = '(Archived)';
      }
    }
  }

  if ('name' in entity && entity.name) {
    elements.push(entity.name);
  } else if (phase && phase.phase_name) {
    elements.push(phase.phase_name);
  }
  if (project && project.project_name) elements.push(project.project_name);
  if (clientElement) elements.push(clientElement);

  const shouldDisplayAsTime =
    config.isNativeTimerApp || displayHoursFormatIsTime(user.prefs);

  const hoursStr = shouldDisplayAsTime
    ? formatDecimalHoursAsClockTime(entity.hours)
    : `${entity.hours}h`;

  return (
    <div
      className={styles.bottomHoverTooltip}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={styles.tooltipLeft}>
        {elements.join(' / ')}
        {entity.notes && entity.notes.length > 0 && (
          <p className={styles.tooltipLeftContent}>
            {getDisplayNotesExcerpt({ notes: entity.notes })}
          </p>
        )}
      </div>
      <div className={styles.tooltipRight}>
        <div className={styles.tooltipHours}>{hoursStr}</div>
      </div>
    </div>
  );
};
