import React from 'react';
import { connect } from 'react-redux';

import { getUser } from '@float/common/selectors/currentUser';
import { Button } from '@float/ui/deprecated/Button/Button';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';

import modalManagerHoc from '../../modalManager/modalManagerHoc';

export const CANCEL = 0;
export const ONLY_THIS = 1;
export const THIS_AND_FUTURE = 2;
const labels = {
  task: 'allocation',
  timeoff: 'time off',
  status: 'status',
};
const pluralLabels = {
  task: 'allocations',
  timeoff: 'time offs',
  status: 'statuses',
};

class UpdateRepeatingModal extends React.Component {
  state = {};
  hidePopup = (state) => {
    this.props.modalSettings.callback(state);
    this.props.manageModal({
      visible: false,
      modalType: 'updateRepeatingModal',
    });
  };

  updateThisTask = () => {
    this.hidePopup(ONLY_THIS);
  };

  updateFutureTasks = () => {
    this.hidePopup(THIS_AND_FUTURE);
  };

  disableUpdating = () => {
    this.hidePopup(CANCEL);
  };

  render() {
    const {
      modalSettings: { changeType },
    } = this.props;
    const label = labels[changeType];
    const pluralLabel = pluralLabels[changeType];

    return (
      <Modal
        isOpen
        center
        onEnter={this.updateThisTask}
        onClose={this.disableUpdating}
      >
        <ModalHeader>
          <ModalTitle>{`${
            this.removing ? 'Removing' : 'Updating'
          } a repeating ${label}`}</ModalTitle>
        </ModalHeader>
        <ModalBody>How to apply this change?</ModalBody>
        <ModalActions>
          <Button autoFocus onClick={this.updateThisTask}>
            This {changeType === 'task' && label}
          </Button>
          <Button onClick={this.updateFutureTasks}>
            This and all future {pluralLabel}
          </Button>
          <Button appearance="secondary" onClick={this.disableUpdating}>
            Cancel
          </Button>
        </ModalActions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const user = getUser(state);
  return {
    user,
  };
};

export default modalManagerHoc({
  Comp: connect(mapStateToProps)(UpdateRepeatingModal),
  modalType: 'updateRepeatingModal',
});
