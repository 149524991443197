import React from 'react';
import styled from 'styled-components';

import { milestoneEditable } from '@float/common/lib/rights';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import Icons from '@float/ui/deprecated/Icons';
import Theme from '@float/ui/deprecated/Theme';
import colors from '@float/ui/deprecated/Theme/colors';

import { getUser } from '../selectors/currentUser';
import { getPhasesMapRaw } from '../selectors/phases';
import { getProjectsMap } from '../selectors/projects';
import { useAppSelectorStrict } from '../store';
import { HOLIDAY_COLOR } from './DayDots';
import { MilestoneCircle } from './Schedule/Cell/MainCell/Item/MilestoneCircle';

const MilestoneTooltipContainer = styled.div`
  text-align: left;
  width: 280px;

  header {
    color: ${colors.emphasisHigh};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 6px;
    padding-bottom: 8px;
    margin-top: 14px;
    &:first-child {
      margin-top: 5px;
    }
    border-bottom: 1px solid ${colors.stroke1};
  }

  span.name {
    font-size: 12px;
    font-weight: 400;
    color: ${colors.emphasisHigh};
    flex-grow: 1;
    line-height: 16px;
  }

  ul {
    margin-top: 9px;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    &.clickable {
      cursor: pointer;
    }

    svg {
      min-width: 24px;
      opacity: 0;
    }

    &:hover svg {
      opacity: 1;
    }

    div.edit {
      height: 18px;
      margin-top: -5px;
      align-self: center;
    }
  }
`;

const LargeCircle = styled.div`
  min-width: 10px;
  margin-right: 4px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #${(p) => p.color};
`;

export default function DayTooltip({
  highlights,
  onMilestoneClick,
  closeTooltip,
}) {
  const user = useAppSelectorStrict(getUser);
  const projects = useAppSelectorStrict(getProjectsMap);
  const phases = useAppSelectorStrict(getPhasesMapRaw);

  if (!highlights || !highlights.length) return null;

  const milestones = highlights.filter((h) => h.milestone_id);
  const holidays = highlights.filter(
    (h) => h.holiday_id || h.region_holiday_id,
  );
  const hasProjects = Boolean(projects);

  return (
    <MilestoneTooltipContainer>
      {!!milestones.length && (
        <>
          <header>Milestones</header>
          <ul>
            {milestones.map((m) => {
              const project = (hasProjects && projects[m.project_id]) || null;
              const canEdit = project && milestoneEditable(project, user);

              if (hasProjects && !project) return null;

              let projectName = m.project_name;

              if (!projectName && project) {
                projectName = projectName || project.project_name;
              }

              const phase = m.phase_id && phases?.[m.phase_id];

              return (
                <li
                  className={canEdit ? 'clickable' : ''}
                  key={m.milestone_id}
                  onMouseDown={prevent}
                  onClick={
                    !canEdit
                      ? undefined
                      : (e) => {
                          prevent(e);
                          closeTooltip();
                          onMilestoneClick(m, { allowPhaseSelection: true });
                        }
                  }
                >
                  <MilestoneCircle project={project} phase={phase} />
                  <span className="name">
                    {m.name} / {projectName}
                  </span>
                  {canEdit && (
                    <div className="edit">
                      <Icons.Pencil color={Theme.medGrey} />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
      {!!holidays.length && (
        <>
          <header>Holidays</header>
          <ul>
            {holidays.map((h) => (
              <li key={h.holiday_id || h.timeoff_notes}>
                <LargeCircle color={HOLIDAY_COLOR} />
                <span className="name">
                  {h.region_holiday_id ? h.timeoff_notes : h.name}
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
    </MilestoneTooltipContainer>
  );
}
