export function shallowDiffers(prev, next) {
  for (const attribute in prev) {
    if (!(attribute in next)) {
      return true;
    }
  }
  for (const attribute in next) {
    if (prev[attribute] !== next[attribute]) {
      return true;
    }
  }
  return false;
}

export function areEqualExtractStyle(prevProps, nextProps) {
  const { style: prevStyle, ...prevRest } = prevProps;
  const { style: nextStyle, ...nextRest } = nextProps;

  return (
    !shallowDiffers(prevStyle, nextStyle) && !shallowDiffers(prevRest, nextRest)
  );
}

export function areEqualExtractStyleLog(name) {
  function shallowDiffersLog(prev, next) {
    for (const attribute in prev) {
      if (!(attribute in next)) {
        console.log(
          `${name} areEqual fails (${attribute})`,
          prev[attribute],
          next[attribute],
        );
        return true;
      }
    }

    for (const attribute in next) {
      if (prev[attribute] !== next[attribute]) {
        console.log(
          `${name} areEqual fails (${attribute})`,
          prev[attribute],
          next[attribute],
        );
        return true;
      }
    }

    return false;
  }

  return (prevProps, nextProps) => {
    const { style: prevStyle, ...prevRest } = prevProps;
    const { style: nextStyle, ...nextRest } = nextProps;

    return (
      !shallowDiffersLog(prevStyle, nextStyle) &&
      !shallowDiffersLog(prevRest, nextRest)
    );
  };
}
