import { ErrorInfo } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { ErrorName } from '@float/constants/errors';

export const sendErrorToDatadog = (
  error: Error,
  info: ErrorInfo,
  name: ErrorName,
) => {
  const errorForDatadog = new Error(error.message);
  errorForDatadog.name = name;
  errorForDatadog.stack = info.componentStack ?? undefined;
  errorForDatadog.cause = error;

  datadogRum.addError(errorForDatadog);
};
