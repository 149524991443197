import React, { Fragment } from 'react';

import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import { HoursInput } from '@float/ui/deprecated/Input';

import { Space, Title, Wrapper } from './WorkDay.styled';

export default ({
  label,
  index,
  onChangeHours,
  onToggleDay,
  hours,
  hoursStep = 0.01,
  hoursMin = 0.01,
  onFocus,
  onBlur,
  isSelected,
}) => {
  return (
    <Wrapper index={index}>
      <Checkbox
        tabIndex="-1"
        onChange={() => onToggleDay(index)}
        value={isSelected}
      />
      <Title>{label}</Title>
      <Space />
      {isSelected && (
        <Fragment>
          <HoursInput
            onChange={(updateHours) => onChangeHours(updateHours, index)}
            onFocus={onFocus}
            onBlur={onBlur}
            value={hours}
            maxWidth={78}
            min={hoursMin}
            step={hoursStep}
            suffix="h"
          />
        </Fragment>
      )}
    </Wrapper>
  );
};
