import React from 'react';
import { Trans } from '@lingui/macro';
import { isAfter } from 'date-fns';
import { usePromptTracker } from 'OnboardingManager/hooks/usePromptTracker';

import { isPaidPlan } from '@float/common/selectors/companyPrefs';
import { PROMPTS } from '@float/constants/prompts';
import { parseFloatDate } from '@float/libs/dates';
import { Button } from '@float/ui/deprecated/Button/Button';
import { AnchorData, Callout } from '@float/ui/deprecated/Callout/Callout';
import { useWebAppSelectorStrict } from '@float/web/lib/store';

import * as styles from './AllocationByPercentageCallout.css';

const anchor: AnchorData = {
  anchors: [
    {
      id: 'allocation-by-percentage-callout',
      config: {
        arrowPosition: '50px',
        position: 'bottom',
        alignment: 'start',
        offset: {
          y: -47,
          x: -10,
        },
      },
    },
  ],
};

export const AllocationByPercentageCallout = () => {
  const [done, setDone] = usePromptTracker(PROMPTS.allocationByPercentage);
  const isNonTrialAccount = useWebAppSelectorStrict(isPaidPlan);

  const today = new Date();

  const expireDate = parseFloatDate('2025-01-22');

  const isExpired = isAfter(today, expireDate);

  const visible = !done && !isExpired && isNonTrialAccount;

  return (
    <Callout
      visible={visible}
      anchorData={anchor}
      onClose={setDone}
      className={styles.wrapper}
    >
      <Callout.Content className={styles.content}>
        <Callout.Title>
          <Trans>New: Allocate in percentage</Trans>
        </Callout.Title>

        <Callout.Paragraph className={styles.description}>
          <Trans>
            Switch between hours and percentage allocations with the drop-down.
            Mix & match to best fit your team's needs.
          </Trans>
        </Callout.Paragraph>
      </Callout.Content>

      <Callout.ActionBar className={styles.actionBar}>
        <a
          href="https://support.float.com/en/articles/4188692-allocate-time"
          target="_blank"
          rel="noreferrer"
        >
          <Button appearance="ghost" onClick={setDone}>
            <Trans>Learn more</Trans>
          </Button>
        </a>
        <Button appearance="primary" onClick={setDone}>
          <Trans>Got it</Trans>
        </Button>
      </Callout.ActionBar>
    </Callout>
  );
};
