import React, { useMemo } from 'react';
import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types';
import {
  IconStatusConfirmed,
  IconStatusDraft,
  IconStatusTentative,
} from '@float/ui/components/StatusIcons';

function getStatusDropdownOptions(allowDraftOption: boolean) {
  const draftOption = {
    value: ProjectStatus.Draft,
    label: t`Draft`,
    description: t`Admins and account owners view from the Project plan`,
    icon: <IconStatusDraft />,
  };

  const options = [
    {
      value: ProjectStatus.Tentative,
      label: t`Tentative`,
      description: t`All team members can view but are not notified`,
      icon: <IconStatusTentative />,
    },
    {
      value: ProjectStatus.Confirmed,
      label: t`Confirmed`,
      description: t`Team members can view and receive notifications`,
      icon: <IconStatusConfirmed />,
    },
  ];

  if (allowDraftOption) {
    options.unshift(draftOption);
  }

  return options;
}

export function useStatusDropdownOptions(allowDraftOption: boolean) {
  return useMemo(
    () => getStatusDropdownOptions(allowDraftOption),
    [allowDraftOption],
  );
}
