import { CurrentUser } from '@float/types/account';
import { Person } from '@float/types/person';
import { FilterToken } from '@float/types/view';

import { findFirstTaskablePerson } from './findFirstTaskablePerson';
import { Row } from './findPersonRowMatch';
import { getDefaultPerson } from './getDefaultPerson';

export function getDefaultPersonForTaskModal(
  rows: Row[],
  props: {
    allPeople: Record<number, Person>;
    people: Record<number, Person>;
    user: CurrentUser;
    filters: FilterToken[];
  },
) {
  const firstTaskablePerson = findFirstTaskablePerson(rows, props);

  return getDefaultPerson(firstTaskablePerson, props);
}
