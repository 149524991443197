import React, {
  CSSProperties,
  MouseEvent,
  ReactElement,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { plural, t, Trans } from '@lingui/macro';
import { get, isEqual, sortBy } from 'lodash';
import { ActionCreator, bindActionCreators, compose, Dispatch } from 'redux';

import { fetchCompanyPrefs } from '@float/common/actions/companyPrefs';
import {
  PeopleProjectViewStatus,
  updateMultiUserPrefs,
  updateUserPref,
} from '@float/common/actions/currentUser';
import * as peopleActions from '@float/common/actions/people';
import { ensureRolesLoaded } from '@float/common/actions/roles';
import Loader from '@float/common/components/elements/Loader';
import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { Core } from '@float/common/earhart/colors';
import { Rights } from '@float/common/lib/acl';
import { getManagerAccess } from '@float/common/lib/acl/access';
import { isLegacyPlaceholderType } from '@float/common/lib/people/isLegacyPlaceholderType';
import { appendFilter } from '@float/common/lib/url';
import { AllActions } from '@float/common/reducers';
import { ReduxState } from '@float/common/reducers/lib/types';
import { getRoles } from '@float/common/selectors/roles';
import { getActiveFilters } from '@float/common/selectors/views';
import {
  AppStoreStrict,
  provideStoreHoc,
  useAppSelector,
} from '@float/common/store';
import { getAccountTypeToLabelMap } from '@float/constants/accounts';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import useMedia from '@float/libs/web/hooks/useMedia';
import { Account, CurrentUser, FilterToken, Person } from '@float/types';
import { CompanyPreferences } from '@float/types/companyPreferences';
import {
  IconArchive,
  IconArrowDownRight,
  IconBolt,
  IconFolder,
  IconPencil,
  IconTrash,
  IconUserCheck,
} from '@float/ui/deprecated/Earhart/Icons';
import EHIconImport from '@float/ui/deprecated/Earhart/Icons/Icon/IconImport';
import IconBg from '@float/ui/deprecated/Earhart/Icons/IconBg';
import IconAddPerson from '@float/ui/deprecated/Icons/icon-add-person';
import { Flex, Row, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { PageBody } from '@float/ui/deprecated/Layout/PageBody';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { withSnackbar } from '@float/ui/deprecated/Snackbar';
import { Table } from '@float/ui/deprecated/Table/Table';
import { Tag } from '@float/ui/deprecated/Table/Tag';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';
import { IconUsers } from '@float/ui/icons/essentials/IconUsers';

import 'actions/roles';

import manageModal from 'modalManager/manageModalActionCreator';
import useMount from 'react-use/esm/useMount';
import {
  getAccurateNameByAccountId,
  getActiveIntegrations,
  getDepartments,
  getDirectManagedPeopleByAccountId,
  getHavePeopleWithContextBeenLoaded,
  getParentDepartments,
  getPeopleMap,
  getPeopleTagByLabel,
  getProjectsByClient,
  getTagsLoaded,
  getUser,
} from 'selectors';

import { ensureDepartmentsLoaded } from '@float/common/actions/departments';
import {
  ensureContextLoaded,
  setPlaceholder,
} from '@float/common/actions/search';
import * as tagsActions from '@float/common/actions/tags';
import { getSearchFilteredPeople } from '@float/common/search/selectors/people';
import { PROMPTS } from '@float/constants/prompts';
import { IconReport } from '@float/ui/icons/essentials/IconReport';
import { IconSchedule } from '@float/ui/icons/essentials/IconSchedule';
import ImportPrompt from '@float/web/components/legacyOnboarding/InAppNotifications/ImportPrompt';
import { STEPS } from '@float/web/importCsv/ImportModal/ImportModal';
import {
  IWithModalConfirmDelete,
  withModalConfirmDelete,
} from '@float/web/modalManager/hoc/withModalConfirmDelete';
import { WebAppState } from '@float/web/reducers/types';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';
import { hasSeatsAvailable } from 'components/modals/helpers/hasSeatsAvailable';
import { useNavUpdater } from 'components/Nav/NavContext';

import { showTimeToUpgradeConfirmModal } from '../../components/modals/TimeToUpgrade';
import SyncIcon from '../../integrations/SyncIcon';
import { dispatchViewLoad } from '../../perfMonitoring/pageLoad';
import { getSortedTags } from './_helpers';
import { ImportPeoplePrompt } from './components/ImportPeoplePrompt/ImportPeoplePrompt';
import { PeopleQuickFilters } from './components/PeopleQuickFilters';
import { QuickAddModal } from './components/QuickAddModal/QuickAddModal';
import { ErrorNoPeople } from './ErrorNoPeople';
import { getFilteredPeople } from './helpers/getFilteredPeople';
import { showModalConfirmDelete } from './helpers/showModalConfirmDelete';
import ImportPeopleModal from './import-people/ImportPeopleModal';
import AssignProjectModal from './modals/AssignProject';
import { BulkEditPeopleModal } from './modals/BulkEditPeopleModal/BulkEditPeopleModal';
import { canBulkActivate } from './modals/PersonModal/helpers/canBulkActivate';
import { PEOPLE_COLUMNS, PEOPLE_STATUS } from './People.constants';
import * as styled from './styles';
import { IBulkUpdate } from './types';

import ImgImportPeople from './import-people/import-people.svg';

const PromptId = PROMPTS.peopleImport;

const PeopleQuickActions = ({
  showImportPeopleModal,
}: {
  showImportPeopleModal: (evt: MouseEvent<HTMLButtonElement>) => void;
}) => {
  const navCtx = useNavUpdater();
  const user = useAppSelector(getUser);
  const isMobile = useMedia() === 'small';

  useEffect(() => {
    const quickActionControls = [];
    const canImport = Rights.canCreatePeople(user);
    if (!isMobile && canImport) {
      quickActionControls.push(
        <NavIconBtn
          isPrimary={false}
          iconLeft={<EHIconImport size={20} />}
          onClick={showImportPeopleModal}
        >
          Import
        </NavIconBtn>,
      );
    }
    navCtx.registerQuickActionCtrls(quickActionControls);
    return () => {
      navCtx.registerQuickActionCtrls([]);
    };
  }, [showImportPeopleModal]);

  return null;
};

type AccessCellProps = {
  accountId: number;
  accounts: Record<number, Account>;
};

export const AccessCell = (props: AccessCellProps) => {
  const account = props.accounts[props.accountId];

  const accountType = account?.account_type;

  const access = accountType ? getAccountTypeToLabelMap()[accountType] : null;

  if (!access) {
    return null;
  }

  return (
    <Row
      style={{
        padding: 5,
        paddingLeft: 0,
      }}
    >
      {access}
      <Spacer size={6} />
      <AccessIcons account={account} />
    </Row>
  );
};

type AccessIconsProps = {
  account: Account;
};

const AccessIcons = (props: AccessIconsProps) => {
  const account = props.account;
  const isManager = account?.account_type === 7;
  const managerAccess = isManager && getManagerAccess(account);

  if (!managerAccess) {
    return null;
  }

  return (
    <>
      {managerAccess.isProjectManager && (
        <TextTooltip
          key="project-manager"
          placement="top"
          content={'Project Manager'}
        >
          <IconBg
            size={24}
            className="outline"
            style={{
              outlineStyle: 'solid',
              outlineWidth: '2px',
              zIndex: 2,
            }}
          >
            <IconFolder size={16} />
          </IconBg>
        </TextTooltip>
      )}
      {managerAccess.isPeopleManager && (
        <TextTooltip
          key="people-manager"
          placement="top"
          content={'People Manager'}
        >
          <IconBg
            size={24}
            className="outline"
            style={{
              outlineStyle: 'solid',
              outlineWidth: '2px',
              zIndex: 1,
              ...(managerAccess.isProjectManager
                ? {
                    // The second icon should have a lighter background as per figma specs
                    background: Core.Surface.IconWhite,
                    marginLeft: -4,
                  }
                : {}),
            }}
          >
            <IconUsers size={16} />
          </IconBg>
        </TextTooltip>
      )}
    </>
  );
};

type ManagesTagProps = {
  managerAccountId: number;
};

export const ManagesTag = (props: ManagesTagProps) => {
  const { managerAccountId } = props;
  const accurateNameByAccountId = useAppSelector(getAccurateNameByAccountId);
  const directManagedPeopleByPeopleId = useAppSelector(
    getDirectManagedPeopleByAccountId,
  );

  const accountName = accurateNameByAccountId[managerAccountId];
  const managedPeopleIds = directManagedPeopleByPeopleId[managerAccountId];
  const managedPeopleCount = managedPeopleIds?.length;

  const showManagesTag = accountName && managedPeopleCount;
  if (!showManagesTag) {
    return null;
  }

  const tooltipContent = `Manages ${managedPeopleCount} ${
    managedPeopleCount > 1 ? 'people' : 'person'
  }`;

  return (
    <TextTooltip key="more-tags" placement="top" content={tooltipContent}>
      {/* Since tags doesn't accept extra props we wrap it with a div to make the tooltip work */}
      <div>
        <Tag
          appearance="dynamic"
          color="flue"
          tag={{
            label: managedPeopleCount,
            to: appendFilter({ manager: accountName }, { pathname: '/manage' }),
          }}
        />
      </div>
    </TextTooltip>
  );
};

export type ConfirmConfig = {
  cancelLabel?: string;
  confirmButtonAppearance?: string;
  confirmLabel: string;
  hideConfirm?: boolean;
  message: ReactElement | string;
  onCancel?: () => void;
  onConfirm: () => void;
  showLoaderOnConfirm?: boolean;
  title: string;
};

type PeopleProps = {
  accounts: ReduxState['accounts'];
  actions: {
    bulkDeletePeople: (ids: number[]) => Promise<unknown>;
    bulkUpdatePeople: (
      ids: number[],
      fields?: { active: number },
    ) => Promise<unknown>;
    updateMultiUserPrefs: typeof updateMultiUserPrefs;
    updateUserPref: typeof updateUserPref;
    ensurePeopleLoaded: typeof peopleActions.ensurePeopleLoaded;
    ensureDepartmentsLoaded: typeof ensureDepartmentsLoaded;
    ensureTagsLoaded: typeof tagsActions.ensureTagsLoaded;
    ensureRolesLoaded: typeof ensureRolesLoaded;
    manageModal: ActionCreator<typeof manageModal>;
    setPlaceholder: typeof setPlaceholder;
  };
  activeIntegrations: Array<{ type: string; label: string; coIntId: number }>;
  companyPrefs: CompanyPreferences;
  confirm: (config: ConfirmConfig) => void;
  confirmClose: () => void;
  currentUser: CurrentUser;
  departments: ReturnType<typeof getDepartments>;
  dismissPrompt: () => void;
  filters: FilterToken[];
  hasBeenLoaded: number;
  history: {
    replace: (location: {
      query: { access?: number };
      pathname: string;
    }) => void;
  };
  importPromptDismissed: boolean;
  location: { query: { access: number }; pathname: string };
  parentDepartments: ReturnType<typeof getParentDepartments>;
  people: Person[];
  printMode: string;
  projectsByClient: ReturnType<typeof getProjectsByClient>;
  searchFilteredPeople: Array<Person>;
  showSnackbar: (message: string, options?: unknown) => void;
  store: AppStoreStrict;
  tagByLabel: ReturnType<typeof getPeopleTagByLabel>;
} & IWithModalConfirmDelete;

type RowConfig = {
  key: string;
  preventSelect: boolean;
  render: () => React.JSX.Element;
  style: CSSProperties;
};

type PeopleState = {
  accessMode: number;
  filteredPeople: Array<Person | RowConfig>;
  filteredPeopleCount: { active: number; archived: number };
  modal: {
    data: number[];
    type: string;
    noBgTransition: boolean;
    initialStep: string;
  };
  peopleView: number;
  sort: {
    type: string | undefined;
    dir: 'asc' | 'desc';
  };
};

class People extends React.Component<PeopleProps> {
  state: PeopleState = {
    filteredPeople: [],
    filteredPeopleCount: { active: 0, archived: 0 },
    accessMode: 0,
    peopleView: 1,
    modal: { data: [], type: '', noBgTransition: false, initialStep: '' },
    sort: {
      type: 'name',
      dir: 'asc',
    },
  };

  hasActiveSelected = false;

  table: Table | null = null;

  static getDerivedStateFromProps(props: PeopleProps) {
    const s = {
      peopleView: 1,
      accessMode: Number(get(props, 'location.query.access', 0)),
      sort: {
        type: 'name' as string | undefined,
        dir: 'asc' as string | undefined,
      },
    };

    if (get(props, 'currentUser.prefs.people_sort_by')) {
      const peopleSortBy = props.currentUser.prefs.people_sort_by;
      // Manually rename job_title for groupBy roles to work correctly
      s.sort = {
        type: peopleSortBy === 'job_title' ? 'role_id' : peopleSortBy,
        dir: 'asc',
      };
    }

    if (get(props, 'currentUser.prefs.people_sort_dir')) {
      s.sort.dir = props.currentUser.prefs.people_sort_dir;
    }

    if (get(props, 'currentUser.prefs.people_view')) {
      s.peopleView = Number(props.currentUser.prefs.people_view);
    }

    return s;
  }

  isLoaded = () => {
    // Note that we only want to render the loader on the very first page load.
    // Subsequent renders might trigger a full people update, but we don't want
    // to unmount any components (like the import modal) to show the spinner.
    return this.props.hasBeenLoaded;
  };

  // ---------------------------------------------------------------------------
  // !!! React Lifecycle -------------------------------------------------------
  // ---------------------------------------------------------------------------

  componentDidMount() {
    this.props.actions.ensurePeopleLoaded();
    this.props.actions.ensureDepartmentsLoaded();
    this.props.actions.ensureTagsLoaded();
    this.props.actions.ensureRolesLoaded();
    this.filterPeople();
  }

  componentDidUpdate(prevProps: PeopleProps, prevState: PeopleState) {
    const propChangesToDetect = [
      'filters',
      'searchFilteredPeople',
      'people',
      'currentUser.prefs.me_filter',
    ];

    const stateChangesToDetect = ['sort', 'peopleView', 'accessMode'];

    const propsChanged = propChangesToDetect.some(
      // These are from Redux so we can rely on immutability
      (path) => get(this.props, path) !== get(prevProps, path),
    );

    const stateChanged = stateChangesToDetect.some(
      (path) => !isEqual(get(this.state, path), get(prevState, path)),
    );

    if (this.isLoaded() && (propsChanged || stateChanged)) {
      this.filterPeople();
    }
  }

  // ---------------------------------------------------------------------------
  // !!! Actions ---------------------------------------------------------------
  // ---------------------------------------------------------------------------

  changeSortBy = async (value: string, sortOrder: string) => {
    this.props.actions.updateMultiUserPrefs({
      people_sort_by: value,
      people_sort_dir: sortOrder,
    });
  };

  changeSortOrder = (value: 'asc' | 'desc') => {
    if (!['asc', 'desc'].includes(value)) {
      return;
    }
    if (value !== this.state.sort.dir) {
      this.props.actions.updateUserPref('people_sort_dir', value);
    }
  };

  onChangeFilterActive = (isSelected: boolean) => {
    let newValue = PEOPLE_STATUS.ACTIVE_ARCHIVED;
    if (!isSelected) {
      if (this.state.peopleView !== PEOPLE_STATUS.ACTIVE_ARCHIVED) {
        return;
      }
      newValue = PEOPLE_STATUS.ARCHIVED;
    }

    this.props.actions.updateUserPref('people_view', `${newValue}`);
  };

  onChangeFilterArchived = (isSelected: boolean) => {
    let newValue = PEOPLE_STATUS.ACTIVE_ARCHIVED;
    if (!isSelected) {
      if (this.state.peopleView !== PEOPLE_STATUS.ACTIVE_ARCHIVED) {
        return;
      }
      newValue = PEOPLE_STATUS.ACTIVE;
    }
    this.props.actions.updateUserPref('people_view', `${newValue}`);
  };

  onChangeFilterAccess = (accessMode: boolean) => {
    const {
      location: { pathname, query = {} as { access?: number } },
    } = this.props;

    if (accessMode) {
      query.access = 1;
    } else {
      delete query.access;
    }

    this.setState({ accessMode }, () => {
      this.props.history.replace({ pathname, query });
    });
  };

  onChangeSort = ({ value }: { value: string }) => {
    const [sort, order] = value.split('-');
    this.changeSortBy(sort, order);
  };

  onImport = () => {
    if (!this.props.importPromptDismissed) {
      this.props.dismissPrompt();
    }
    this.hideModal();
  };

  // ---------------------------------------------------------------------------
  // !!! Helpers ---------------------------------------------------------------
  // ---------------------------------------------------------------------------

  rowRenderer = (row: RowConfig | Person) => {
    if ((row as RowConfig).render) {
      return row;
    }

    const p = row as Person;

    return {
      name: { label: this.renderName(p), value: p.name },
      role: {
        label: this.renderRoleName(p),
        value: this.getRoleName(p),
      },
      department: {
        label: this.renderDepartment(p),
        value: this.getDepartmentValue(p),
      },
      access: {
        label: this.renderAccess(p),
        value: this.getAccessRights(p) || 'No access rights',
      },
      manages: this.renderManages(p),
      tags: this.renderTags(p),
      statusTags: this.renderStatusTags(p),

      key: p.people_id,
      canEdit: p.canEdit,
      className: p.people_type_id === 3 ? 'text-light' : '',
      onClick: (evt: MouseEvent) => {
        evt.preventDefault();
        if (!p.canEdit) {
          this.showPersonModal(p);
          return;
        }
        this.showPersonModal(p);
      },
    };
  };

  getDepartmentValue(p: Person | RowConfig, defaultName = 'No department') {
    const { departments, parentDepartments } = this.props;
    const deptId = get(p, 'department_id', null);

    if (deptId === null || deptId === 0) {
      return defaultName;
    }

    const department = departments[deptId];
    const parentDeps = parentDepartments[deptId];
    const mostParent =
      parentDeps && departments[parentDeps[parentDeps.length - 1]];

    if (!mostParent && department) {
      return department.name;
    }

    if (mostParent) {
      return mostParent.name;
    }

    return defaultName;
  }

  getRoleName = (p: Person) => {
    if (p.role) {
      return p.role.name;
    }

    return '';
  };

  getAccessRights = (p: Person) => {
    let access = '';
    if (p.account_id) {
      const account = this.props.accounts.accounts[p.account_id];
      if (account?.account_type) {
        access = getAccountTypeToLabelMap()[account.account_type];
      }
    }

    return access;
  };

  filterPeople = () => {
    const { searchFilteredPeople, currentUser } = this.props;

    const { filteredPeople, filteredPeopleCount } = getFilteredPeople(
      searchFilteredPeople,
      this.state.peopleView,
      this.state.accessMode,
    );

    const isDescSort = this.state.sort.dir === 'desc';
    const isDescNameSort = isDescSort && this.state.sort.type === 'name';

    const sorted: Array<Person | RowConfig> = sortBy(
      filteredPeople,
      (p: Person) => {
        let sortVal = '';

        if (this.state.sort.type === 'department') {
          sortVal = this.getDepartmentValue(p, 'zNo Department').toLowerCase();
        }

        if (this.state.sort.type === 'role') {
          sortVal = (p?.role?.name || 'zno role').toLowerCase();
        }

        if (this.state.sort.type === 'access') {
          sortVal = 'zno account access';
          if ((p as Person).account_id) {
            const account =
              this.props.accounts.accounts[(p as Person).account_id];
            if (account?.account_type) {
              sortVal = (
                getAccountTypeToLabelMap()[account.account_type] || sortVal
              ).toLowerCase();
            }
          }
        }

        // Make sure that "Placeholder" people are always at the bottom, even
        // if we're sorting backwards.
        let prefix = isDescNameSort ? 'z' : 'a';
        if ((p as Person).people_type_id === 3) {
          prefix = isDescNameSort ? 'a' : 'z';
        }

        return `${sortVal}${prefix}${get(p, 'name', '').toLowerCase()}`;
      },
    );

    if (isDescSort) {
      sorted.reverse();
    }

    const searchFiltersApplied = !!this.props.filters.length;
    const peopleCount = filteredPeople.length;
    const isLoaded = this.isLoaded();
    const showImportPrompt =
      isLoaded &&
      !this.props.importPromptDismissed &&
      !searchFiltersApplied &&
      peopleCount &&
      Rights.canCreatePeople(currentUser);

    if (showImportPrompt) {
      sorted.push({
        key: 'import-prompt',
        preventSelect: true,
        render: this.renderImportPrompt,
        style: {
          height: 340,
          padding: 20,
          background: 'transparent',
          cursor: 'initial',
        },
      });
    }

    this.setState({ filteredPeople: sorted, filteredPeopleCount });
    this.setPlaceholder(filteredPeople);
  };

  setPlaceholder = (filteredPeople: Array<Person | RowConfig>) => {
    const active = filteredPeople.filter((p) => (p as Person).active).length;
    const archived = filteredPeople.length - active;

    if (this.state.peopleView === 1) {
      this.props.actions.setPlaceholder(
        `${active} ${active === 1 ? 'person' : 'people'}`,
      );
    }

    if (this.state.peopleView === 0) {
      this.props.actions.setPlaceholder(
        `${archived} ${archived === 1 ? 'person' : 'people'}`,
      );
    }

    if (this.state.peopleView === 2) {
      const text = `${active} active, ${archived} archived`;
      this.props.actions.setPlaceholder(text);
    }
  };

  clearSelected = () => {
    this.table?.toggleSelectAll();
  };

  isFilteredByAccess = () => {
    const { location: { query = {} as { access?: number } } = {} } = this.props;
    return !!query.access;
  };

  // ---------------------------------------------------------------------------
  // !!! Modal / Multi Actions -------------------------------------------------
  // ---------------------------------------------------------------------------

  afterBulkUpdate = ({ clearSelection = false } = {}) => {
    if (clearSelection) {
      this.clearSelected();
    }
    this.props.confirmClose();
    this.props.confirmDeleteClose();
    this.setState({ modal: {} });
  };

  afterAssignedToProject = () => {
    const ids = this.state.modal.data;
    const title =
      ids.length > 1 ? `${ids.length} people` : this.props.people[ids[0]].name;

    this.afterBulkUpdate();
    this.showMessage(`${title} updated.`);
  };

  bulkUpdate = ({
    ids,
    fields,
    messageSuffix = 'updated',
    updateFn,
  }: IBulkUpdate) => {
    const title =
      ids.length > 1
        ? `${ids.length} people`
        : this.props.people[ids[0]]?.name || '';

    let fn = updateFn;
    if (!fn) {
      fn =
        messageSuffix === 'deleted'
          ? this.props.actions.bulkDeletePeople
          : this.props.actions.bulkUpdatePeople;
    }

    return (
      fn as (ids: number[], fields?: { active: number }) => Promise<unknown>
    )(ids, fields)
      .then(() => {
        this.afterBulkUpdate({ clearSelection: messageSuffix !== 'updated' });
        this.showMessage(`${title} ${messageSuffix}.`);
      })
      .catch((err) => {
        this.afterBulkUpdate();
        this.showMessage((err && err.message) || 'An error occurred.');
      });
  };

  showMessage = (message: string) => {
    this.props.showSnackbar(message);
  };

  hideModal = (clearSelection: boolean = false) => {
    this.setState({ modal: {} });
    if (clearSelection === true) {
      this.clearSelected();
    }
  };

  archive = (ids: number[]) => {
    this.props.confirm({
      title: plural(ids.length, {
        one: `Archive ${this.props.people[ids[0]].name}?`,
        other: 'Archive # people?',
      }),
      confirmLabel: plural(ids.length, {
        one: `Archive ${this.props.people[ids[0]].name}`,
        other: 'Archive # people',
      }),

      showLoaderOnConfirm: true,
      message: (
        <>
          <p>
            <Trans>What this means:</Trans>
          </p>
          <ul>
            <li>
              <Trans>All historical data will be retained in Float</Trans>
            </li>
            <li>
              <Trans>
                Any access is removed, so they will no longer be able to sign in
              </Trans>
            </li>
          </ul>
          <p>
            <Trans>
              Anyone you archive can be restored from the People page.
            </Trans>
          </p>
        </>
      ),
      confirmButtonAppearance: 'danger',
      onConfirm: () => {
        this.bulkUpdate({
          ids,
          fields: { active: 0 },
          messageSuffix: 'archived',
        });
      },
    });
  };

  activate = (ids: number[]) => {
    const { people, companyPrefs } = this.props;
    const toActivate = ids.map((id) => people[id]).filter((p) => !p.active);

    if (!canBulkActivate(toActivate, companyPrefs)) {
      showTimeToUpgradeConfirmModal(this.props);
      return;
    }

    const title = ids.length > 1 ? `${ids.length} people` : people[ids[0]].name;
    this.props.confirm({
      title: 'Move to active',
      confirmLabel: 'Move to active',
      showLoaderOnConfirm: true,
      message: (
        <p>
          <span>Move </span>
          <strong>{title}</strong>
          <span> to active?</span>
        </p>
      ),
      onConfirm: () => {
        this.bulkUpdate({
          ids,
          fields: { active: 1 },
          messageSuffix: 'activated',
        });
      },
    });
  };

  delete = async (ids: number[]) => {
    const { bulkUpdate, props } = this;
    const { people, store, confirmDelete } = props;

    showModalConfirmDelete(ids, people, store, confirmDelete, bulkUpdate);
  };

  getModal = () => {
    const { type, data, noBgTransition, initialStep } = this.state.modal;
    if (!type) return null;

    switch (type) {
      case 'bulkEdit':
        return (
          <BulkEditPeopleModal
            ids={data}
            onCancel={this.hideModal}
            onUpdate={this.bulkUpdate}
          />
        );
      case 'assignProject':
        return (
          <AssignProjectModal
            data={data}
            projectsByClient={this.props.projectsByClient}
            hide={this.hideModal}
            onSuccess={this.afterAssignedToProject}
          />
        );
      case 'import':
        return (
          <ImportPeopleModal
            onSave={this.onImport}
            onClose={this.hideModal}
            noBgTransition={noBgTransition}
            initialStep={initialStep}
          />
        );

      case 'quickAdd':
        return (
          <QuickAddModal
            onClose={this.hideModal}
            onClickUploadCSV={this.showImportPeopleModal}
          />
        );
      default:
        console.error(`Unknown modal type ${type}`);
        return null;
    }
  };

  getMultiSelectActions = (selected: number[]) => {
    const actions = [
      {
        label: 'Edit',
        icon: IconPencil,
        action: (data: number[]) => {
          const isEditingSingleItem = data.length === 1;
          if (isEditingSingleItem) {
            const personId = data[0];
            const person = this.props.people[personId];
            if (person) {
              this.showPersonModal(person);
            }
            return;
          }

          this.setState({ modal: { type: 'bulkEdit', data } });
        },
      },
      {
        label: 'Assign to project',
        icon: IconUserCheck,
        action: (data: number[]) =>
          this.setState({ modal: { type: 'assignProject', data } }),
      },
    ];
    this.hasActiveSelected = selected.some((id) =>
      Object.values(this.props.people).some(
        (p) => p.active && p.people_id === Number(id),
      ),
    );

    if (this.hasActiveSelected) {
      actions.push({
        label: 'Archive',
        icon: IconArchive,
        action: this.archive,
      });
    }

    const hasArchivedSelected = selected.some((id) =>
      Object.values(this.props.people).some(
        (p) => !p.active && p.people_id === Number(id),
      ),
    );

    if (hasArchivedSelected) {
      actions.push({
        label: 'Activate',
        icon: IconBolt,
        action: this.activate,
      });
    }

    const canDeleteEveryoneSelected = selected.every((id) => {
      const person = this.props.people[id];
      return Rights.canDeletePeople(this.props.currentUser, {
        entity: person,
      });
    });

    if (canDeleteEveryoneSelected) {
      actions.push({
        label: 'Delete',
        icon: IconTrash,
        action: this.delete,
      });
    }

    return actions;
  };

  showUpgradeModalIfApplicable = () => {
    if (!hasSeatsAvailable(this.props.companyPrefs)) {
      showTimeToUpgradeConfirmModal(this.props);
      return true;
    }
    return false;
  };

  showAddPersonModal = (e: MouseEvent) => {
    e.preventDefault();
    if (this.showUpgradeModalIfApplicable()) {
      return;
    }
    this.props.actions.manageModal({
      visible: true,
      modalType: 'personModal',
      modalSettings: {
        person: {},
        isAdding: true,
        editing: true,
      },
    });
  };

  showPersonModal = (person: Person) => {
    this.props.actions.manageModal({
      visible: true,
      modalType: 'personModal',
      modalSettings: {
        person,
        editing: true,
        isEditable: person.canEdit,
      },
    });
  };

  showImportPeopleModal = (evt?: MouseEvent) => {
    if (evt) evt.preventDefault();

    // disable modal bg transition when coming from quickAdd modal
    const isComingFromQuickAddModal = this.state.modal.type === 'quickAdd';
    const noBgTransition = isComingFromQuickAddModal;
    const initialStep = isComingFromQuickAddModal ? STEPS.FILE : '';

    this.setState({
      modal: {
        type: 'import',
        noBgTransition,
        initialStep,
      },
    });
  };

  showQuickAddPeopleModal = () => {
    this.setState({ modal: { type: 'quickAdd' } });
  };

  // ---------------------------------------------------------------------------
  // !!! Render ----------------------------------------------------------------
  // ---------------------------------------------------------------------------

  renderName = (p: Person) => () => {
    const isMobile = window.innerWidth <= 740;
    const activeIntegration = this.props.activeIntegrations.find(
      ({ coIntId }) => Number(p.integrations_co_id) === coIntId,
    );

    return (
      <styled.NameContainer
        isActive={Boolean(p.active)}
        hasPmIcon={!!activeIntegration}
      >
        <PersonAvatar
          readOnly
          personId={p.people_id}
          size={isMobile ? 'mobile' : 'xs'}
          tooltip={false}
          disabled={!p.active}
        />
        <Flex
          style={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Table.Text disabled={!p.active}>{p.name}</Table.Text>
          {activeIntegration && (
            <SyncIcon
              itemType="person"
              integrationType={
                activeIntegration.label || activeIntegration.type
              }
              disabled={!p.active}
            />
          )}
          <Spacer axis="x" />
          {!!p.active && (
            <Table.HoverLinks>
              <Table.HoverLinkIcon
                to={`/?people=${encodeURIComponent(p.name)}`}
                icon={<IconSchedule />}
                className="schedule"
                label="Schedule"
              />
              <Table.HoverLinkIcon
                to={`/report?people=${encodeURIComponent(p.name)}`}
                icon={<IconReport />}
                className="desktop"
                label="Report"
              />
            </Table.HoverLinks>
          )}
        </Flex>
      </styled.NameContainer>
    );
  };

  renderRoleName = (p: Person) => () => {
    if (!p.role) {
      return <Table.EmptyCell disabled={!p.active} />;
    }

    return (
      <Table.HoverLink
        disabled={!p.active}
        // There appear to be some issues with typings related to Location
        // @ts-expect-error
        to={appendFilter({ jobTitle: p.role.name }, this.props.location)}
      >
        {p.role.name}
      </Table.HoverLink>
    );
  };

  renderDepartment = (p: Person) => () => {
    const { departments } = this.props;
    const deptName = get(p, 'department.name', null);

    if (!deptName) {
      return <Table.EmptyCell disabled={!p.active} />;
    }

    const deptId = get(p, 'department_id', null);
    const parentId =
      deptId !== null ? departments[deptId].parent_id : undefined;

    return (
      <Table.HoverLink
        disabled={!p.active}
        // There appear to be some issues with typings related to Location
        // @ts-expect-error
        to={appendFilter({ department: deptName }, this.props.location)}
      >
        {parentId && <IconArrowDownRight size={16} />}
        <Spacer size={4} />
        {deptName}
      </Table.HoverLink>
    );
  };

  renderAccess = (p: Person) => {
    if (!p.account_id) {
      return <Table.EmptyCell disabled={!p.active} />;
    }

    return (
      <Table.Text disabled={!p.active}>
        <AccessCell
          accounts={this.props.accounts.accounts}
          accountId={p.account_id}
        />
      </Table.Text>
    );
  };

  renderManages = (p: Person) => () => {
    return <ManagesTag managerAccountId={p.account_id} />;
  };

  renderTags = (p: Person) => () => {
    const { tagByLabel } = this.props;
    const tags = getSortedTags(p.tags).map((t) => ({
      to: appendFilter({ personTag: t }, this.props.location),
      color: tagByLabel[t]?.color,
      label: t,
    }));
    return <Table.Tags tags={tags} shouldResize appearance="dynamic" />;
  };

  renderStatusTags = (p: Person) => () => {
    const statusTags = [];

    if (p.employee_type === 0 && p.people_type_id !== 3) {
      statusTags.push({
        label: 'Part-time',
        to: appendFilter({ personType: 'Part-time' }, this.props.location),
      });
    }

    if (p.people_type_id === 2) {
      statusTags.push({
        label: 'Contractor',
        to: appendFilter({ personType: 'Contractor' }, this.props.location),
      });
    }

    if (p.people_type_id === 3) {
      const isPersonLegacyPlaceholderType = isLegacyPlaceholderType({
        person: p,
      });

      let label = t`Placeholder`;
      if (isPersonLegacyPlaceholderType) {
        label = t`Placeholder (legacy)`;
      }

      statusTags.push({
        label,
        to: appendFilter({ personType: 'Placeholder' }, this.props.location),
      });
    }

    return (
      <Table.Tags
        tags={statusTags}
        ellipsis={false}
        shouldResize
        appearance="dynamic"
      />
    );
  };

  renderActions = () => {
    const { currentUser } = this.props;
    const { filteredPeopleCount, peopleView, sort } = this.state;

    return (
      <>
        {featureFlags.isFeatureEnabled(
          FeatureFlag.ActiveandArchivedManageFilter,
        ) ? (
          <PeopleQuickFilters
            activeCount={filteredPeopleCount.active}
            archivedCount={filteredPeopleCount.archived}
            canCreate={Rights.canCreatePeople(currentUser)}
            currentCheckboxValue={this.isFilteredByAccess()}
            currentFilterValue={
              peopleView.toString() as PeopleProjectViewStatus
            }
            onCheckboxChange={this.onChangeFilterAccess}
            onClickCreate={this.showAddPersonModal}
          />
        ) : (
          <Table.Viewing
            actionLeft={
              Rights.canCreatePeople(this.props.currentUser) && (
                <styled.AddEntity
                  className="desktop"
                  size="xsmall"
                  appearance="secondary"
                  onClick={this.showAddPersonModal}
                >
                  <IconAddPerson />
                </styled.AddEntity>
              )
            }
            optionsLeft={[
              {
                label: 'Active',
                isSelected: [
                  PEOPLE_STATUS.ACTIVE,
                  PEOPLE_STATUS.ACTIVE_ARCHIVED,
                ].includes(peopleView),
                onChange: this.onChangeFilterActive,
              },
              {
                label: 'Archived',
                isSelected: [
                  PEOPLE_STATUS.ARCHIVED,
                  PEOPLE_STATUS.ACTIVE_ARCHIVED,
                ].includes(peopleView),
                onChange: this.onChangeFilterArchived,
              },
            ]}
            optionsRight={[
              {
                label: 'Account access',
                isSelected: this.isFilteredByAccess(),
                onChange: this.onChangeFilterAccess,
              },
            ]}
          />
        )}

        <Table.ActionsGroup>
          <Table.Sort
            value={sort}
            columns={PEOPLE_COLUMNS}
            onChange={this.onChangeSort}
          />
        </Table.ActionsGroup>
      </>
    );
  };

  renderImportPrompt = () => {
    if (featureFlags.isFeatureEnabled(FeatureFlag.ManageQuickAdd)) {
      return (
        <ImportPeoplePrompt
          onClick={this.showQuickAddPeopleModal}
          onClose={this.props.dismissPrompt}
        />
      );
    }

    return (
      <ImportPrompt
        img={ImgImportPeople}
        label="Import your team"
        onClick={this.showImportPeopleModal}
        onClose={this.props.dismissPrompt}
      />
    );
  };

  render() {
    const hasSearchFiltersApplied = !!this.props.filters.length;
    const noItems = !this.state.filteredPeople.length;
    const isLoaded = this.isLoaded();
    const canEditSomePeople =
      this.state.filteredPeople.filter((person) => (person as Person).canEdit)
        .length > 0;
    const canCreatePeople = Rights.canCreatePeople(this.props.currentUser);

    return (
      <styled.PeopleSection className="people-section" id="people">
        <PeopleQuickActions
          showImportPeopleModal={this.showImportPeopleModal}
        />
        <PageBody>
          {isLoaded ? (
            <>
              <Table
                ref={(el) => {
                  this.table = el;
                }}
                selectable={
                  canEditSomePeople &&
                  Rights.canUpdatePeople(this.props.currentUser)
                }
                columns={PEOPLE_COLUMNS}
                rows={this.state.filteredPeople}
                rowRenderer={this.rowRenderer}
                sortBy={this.state.sort.type}
                sortOrder={this.state.sort.dir}
                nonGroupKeys={['name', 'manages']}
                printMode={this.props.printMode}
                onSortByChange={this.changeSortBy}
                onSortOrderChange={this.changeSortOrder}
                renderActions={this.renderActions}
                getMultiSelectActions={this.getMultiSelectActions}
              />
              <DispatchManageViewLoad />
            </>
          ) : (
            <Loader />
          )}
          {isLoaded && noItems && (
            <ErrorNoPeople
              canCreatePeople={canCreatePeople}
              hasSearchFiltersApplied={hasSearchFiltersApplied}
              onClickAddPerson={this.showAddPersonModal}
            />
          )}
        </PageBody>
        {this.getModal()}
      </styled.PeopleSection>
    );
  }
}

function DispatchManageViewLoad() {
  useMount(() => {
    dispatchViewLoad('Manage');
  });

  return null;
}

const mapStateToProps = (state: WebAppState) => ({
  accounts: state.accounts,
  departments: getDepartments(state),
  parentDepartments: getParentDepartments(state),
  search: state.search,
  tagByLabel: getPeopleTagByLabel(state),
  people: getPeopleMap(state),
  roles: getRoles(state),
  currentUser: getUser(state),
  companyPrefs: state.companyPrefs,
  searchFilteredPeople: getSearchFilteredPeople(state),
  projectsByClient: getProjectsByClient(state),
  hasBeenLoaded:
    getHavePeopleWithContextBeenLoaded(state) && getTagsLoaded(state),
  printMode: state.app.printMode,
  activeIntegrations: getActiveIntegrations(state),
  importPromptDismissed:
    +state.currentUser.account_tid !== 1 ||
    state.legacyOnboarding.prompts.includes(PromptId),
  filters: getActiveFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AllActions>) => ({
  actions: {
    ...bindActionCreators(
      {
        setPlaceholder,
        updateUserPref,
        updateMultiUserPrefs,
        manageModal,
        fetchCompanyPrefs,
        ensureContextLoaded,
        ensureDepartmentsLoaded,
        ensureRolesLoaded,
      },
      dispatch,
    ),
    ...bindActionCreators(
      tagsActions as unknown as ActionCreator<unknown>,
      dispatch,
    ),
    ...bindActionCreators(
      peopleActions as unknown as ActionCreator<unknown>,
      dispatch,
    ),
  },
  dismissPrompt: () => dispatch(updatePrompts(PromptId)),
});

const Component = compose(
  withConfirm,
  withModalConfirmDelete,
  withSnackbar,
)(provideStoreHoc(People)) as React.ComponentType<{}>;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
