import React from 'react';
import classNames from 'classnames';

import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { useAppSelectorStrict } from '@float/common/store';
import { CellsMap } from '@float/types';

import { ScheduleActions } from '../types';
import { MainCellItem } from './box/BoxElements/types';
import { getDimensions } from './helpers';
import LinkArrow from './LinkedLayer/components/LinkArrow';
import { useLinkArrowCurve } from './LinkedLayer/hooks/useLinkArrowCurve';
import { getLinkArrowColor } from './LinkedLayer/utils/getLinkArrowColor';

import { placeHolderItemBox } from './PlaceholderItem.css';

type PlaceholderItemProps = {
  actions: ScheduleActions;
  cells: CellsMap;
  dayWidth?: number;
  fullDay?: boolean;
  hourHeight?: number;
  item: MainCellItem;
  linkedArrowTargetRef?: React.MutableRefObject<SVGSVGElement | null>;
  row?: PersonRow | PersonProjectRow | ProjectRow;
  suvSingleDay?: string | null;
  wrapperRef?: React.RefObject<HTMLDivElement>;
};

/**
 * Moved the update outside of the component.
 *
 * This way the React Compiler won't complain about it and we know that's a safe update
 */
function setBoxRef(item: MainCellItem, node: HTMLDivElement | null) {
  item._boxRef = node;
}

function PlaceholderItem({
  actions,
  cells,
  dayWidth,
  fullDay,
  hourHeight,
  item,
  linkedArrowTargetRef,
  row,
  suvSingleDay,
  wrapperRef,
}: PlaceholderItemProps) {
  const dimensions = getDimensions(item, dayWidth, hourHeight, suvSingleDay);

  const linkArrowCurve = useLinkArrowCurve(
    cells,
    item,
    linkedArrowTargetRef,
    wrapperRef,
    row,
  );

  const color = useAppSelectorStrict((state) => {
    return getLinkArrowColor(
      {
        phases: state.phases.phases,
        projects: state.projects.projects,
      },
      item,
    );
  });

  // @ts-expect-error Selection shouldn't have parent_task_id
  const parentTaskId = item.entity?.parent_task_id;

  return (
    <div
      className={classNames('ItemWrapper', placeHolderItemBox)}
      ref={(node) => {
        setBoxRef(item, node);
      }}
      style={{
        ...dimensions,
        borderRadius: fullDay ? 0 : undefined,
      }}
      data-is-start={item.isStart}
      data-is-end={item.isEnd}
    >
      {parentTaskId && linkArrowCurve && (
        <LinkArrow
          actions={actions}
          item={item}
          color={color}
          opacity={1}
          linkedArrowTargetRef={linkedArrowTargetRef}
          allowRemove={false}
          {...linkArrowCurve}
        />
      )}
    </div>
  );
}

export default PlaceholderItem;
