import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import { getDepartmentOptions } from 'selectors';
import styled, { useTheme } from 'styled-components';

import { updateCompanyShareLinkPrefs } from '@float/common/actions/companyPrefs';
import API3 from '@float/common/api3';
import { trackEvent } from '@float/common/lib/analytics';
import {
  getAllProjectsOptions,
  getClientOptions,
  getDefaultDropdownProject,
} from '@float/common/selectors/projects';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import IconShare from '@float/ui/deprecated/Icons/icon-share';
import { Input } from '@float/ui/deprecated/Input';
import { Label } from '@float/ui/deprecated/Label';
import { Modal } from '@float/ui/deprecated/Modal';
import { useSnackbar } from '@float/ui/deprecated/Snackbar';
import { ToggleGroup } from '@float/ui/deprecated/Toggle/ToggleGroup';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import withUserCanSee from 'components/decorators/withUserCanSee';

const { CheckboxLabel } = Checkbox._styles;
const shareLinkEnabledOptions = [
  { value: true, label: 'Enabled' },
  { value: false, label: 'Disabled' },
];
const scheduleDisplayOptions = [
  { value: 0, label: 'Comfortable' },
  { value: 1, label: 'Compact' },
  { value: 2, label: 'Spacious' },
];

const StyledSection = styled.div`
  color: ${(p) => p.theme.charcoalGrey};

  ${CheckboxLabel} {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
`;

const StyledHr = styled.hr`
  border-color: ${(p) => p.theme.grey};
  margin: 32px 0 25px;
`;

const StyledRegenerate = styled.a`
  color: ${(p) => p.theme.blueGrey} !important;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
`;

const StyledPreview = styled.div`
  color: ${(p) => p.theme.blueGrey} !important;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  visibility: ${(p) => (p.genLoaded ? 'visible' : 'hidden')};
`;

const StyledSmallText = styled.span`
  color: ${(p) => p.theme.blueGrey} !important;
  font-size: 14px;
  font-weight: normal;
`;

const SharedLinkInputContainer = styled.div`
  margin: 0 0 7px;
  display: flex;

  ${Input._styles.FieldWrapper} {
    flex-grow: 1;
  }

  ${Input._styles.InputWrapper} {
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    background-color: #e0e0e0;

    input {
      font-weight: normal;
      font-size: 16px;
      padding: 0 15px;
      color: ${(p) => p.theme.charcoalGrey};
      text-overflow: ellipsis;
    }
  }
`;

const CopyLinkContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 700;
  letter-spacing: -0.1px;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding: 0px 20px;
  color: ${(p) => (p.disabled ? '#999' : 'black')};
  max-height: 44px;
  background-color: #ffffff;
`;

export const generateSharedLink = async ({ filters } = {}) => {
  try {
    const { token } = await API3.createSharedLink({
      filters,
    });
    return token;
  } catch (e) {
    console.error(e);
  }
};

export const regenerateSharedLinks = async () => {
  try {
    await API3.cancelPastSharedLinks();
  } catch (e) {
    console.error(e);
  }
};

const ShareLinkModal = ({
  shareLinkEnabledPref,
  shareLinkAllMembersEnabledPref,
  manageModal,
  updateShareLinkPref,
  projectOptions,
  clientOptions,
  departmentOptions,
  defaultProject,
  isAccOwner,
  companyHostname,
  deptFilterIds,
}) => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const el = useRef(null);
  const [shareLinkEnabled, setShareLinkEnabled] =
    useState(shareLinkEnabledPref);
  const [otherTeamMembersEnabled, setOtherTeamMembersEnabled] = useState(
    shareLinkAllMembersEnabledPref,
  );

  const [viewFilter, setViewFilter] = useState('all');
  const [schedDisplay, setSchedDisplay] = useState(0);
  const viewFilterOptions = [
    { label: "Everyone's schedule", value: 'all' },
    !isEmpty(projectOptions) && { label: 'Project', value: 'project' },
    !isEmpty(clientOptions) && { label: 'Client', value: 'client' },
    !isEmpty(departmentOptions) && {
      label: 'Department',
      value: 'department',
    },
  ].filter(Boolean);
  const [project, setProject] = useState(
    defaultProject ? defaultProject.project_id : null,
  );
  const [client, setClient] = useState(get(clientOptions, '[0].value', null));
  const [department, setDepartment] = useState(
    get(departmentOptions, '[0].value', null),
  );

  const hide = (evt) => {
    prevent(evt);
    manageModal({
      visible: false,
      modalType: 'shareLinkModal',
    });
  };

  const changeShareLinkEnabled = (value) => {
    updateShareLinkPref({
      share_link_enabled: value ? 1 : 0,
    });
    setShareLinkEnabled(value);
  };

  const changeOtherTeamMembersEnabled = (value) => {
    updateShareLinkPref({
      share_link_all_members_enabled: value ? 1 : 0,
    });
    setOtherTeamMembersEnabled(value);
  };

  const copyToClipboard = () => {
    const node = el.current && el.current.input;
    if (!node) {
      return;
    }

    node.select();
    document.execCommand('copy');
    showSnackbar('Share link copied to clipboard.');
  };

  const generateSharedLinkOptions = useCallback(
    () => ({
      filters: {
        project_id: viewFilter === 'project' ? project : undefined,
        client_id: viewFilter === 'client' ? client : undefined,
        department_id: viewFilter === 'department' ? department : undefined,
      },
    }),
    [viewFilter, project, client, department],
  );

  const [genLoading, setGenLoading] = useState(false);
  const [sharedLinkToken, setSharedLinkToken] = useState(null);

  const onGenerate = useCallback(async () => {
    const options = generateSharedLinkOptions();
    if (viewFilter === 'all' || Object.values(options.filters).some(Boolean)) {
      setSharedLinkToken(null);
      setGenLoading(true);

      const token = await generateSharedLink(options);
      setSharedLinkToken(token);
      setGenLoading(false);
      trackEvent('Shared link generated', {
        filterType: viewFilter,
        displaySetting: `${
          scheduleDisplayOptions[schedDisplay]?.label || schedDisplay
        }`.toLowerCase(),
      });
    }
  }, [viewFilter, schedDisplay, generateSharedLinkOptions]);

  const onRegenerate = useCallback(async () => {
    setSharedLinkToken(null);
    await regenerateSharedLinks();
    showSnackbar('Links regenerated.');
  }, [showSnackbar]);

  useEffect(() => {
    setSharedLinkToken(null);
  }, [shareLinkEnabled, viewFilter, project, client, department]);

  const link = useMemo(
    // possibly use companyHostname here
    () =>
      sharedLinkToken
        ? `https://${window.location.hostname}/share/${sharedLinkToken}${
            schedDisplay ? `?sz=${schedDisplay}` : ''
          }`
        : null,
    [companyHostname, sharedLinkToken, schedDisplay], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const genLoaded = !!link && !genLoading;

  return (
    <Modal isOpen onClose={hide}>
      <Modal.Header>
        <Modal.Title>Share a link</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingBottom: 32,
          // minHeight: canTasksBeScheduledForThisPerson ? 594 : 0,
        }}
      >
        {isAccOwner && (
          <>
            <StyledSectionTitle>
              Share a read-only version of the schedule
            </StyledSectionTitle>
            <ToggleGroup
              label="Share Link"
              appearance="stitched"
              value={shareLinkEnabled}
              disableUnselect
              options={shareLinkEnabledOptions}
              onChange={changeShareLinkEnabled}
              optionStyle={{ marginBottom: 20 }}
            />
            {shareLinkEnabled && (
              <>
                <Checkbox
                  label={`Enable link sharing for other team members`}
                  value={otherTeamMembersEnabled}
                  onChange={changeOtherTeamMembersEnabled}
                  style={{ fontSize: 16, color: theme.charcoalGrey }}
                />
                <StyledSmallText>
                  <StyledRegenerate onClick={() => onRegenerate()}>
                    Regenerate links
                  </StyledRegenerate>{' '}
                  to cancel any previously shared links
                </StyledSmallText>
              </>
            )}
            {shareLinkEnabled && <StyledHr />}
          </>
        )}
        {shareLinkEnabled && (
          <>
            <StyledSection>
              <StyledSectionTitle>Link generator</StyledSectionTitle>
              <p style={{ fontSize: 16 }}>
                Select a schedule view. A link will be generated for each
                different view.
              </p>
              <VirtualSelect
                label={'Link can view'}
                height={200}
                nonNullable
                clearInputOnDropdownOpen={false}
                options={viewFilterOptions}
                style={{ marginBottom: 30 }}
                value={viewFilter}
                onChange={({ value }) => setViewFilter(value)}
              />
              {viewFilter === 'project' && (
                <VirtualSelect
                  label=""
                  visibleItems={6}
                  noMargin
                  autoFocus
                  keepFocusAfterSelect
                  value={project}
                  groupedOptions={projectOptions}
                  style={{ marginBottom: 30 }}
                  onChange={({ value }) => setProject(value)}
                />
              )}
              {viewFilter === 'client' && (
                <VirtualSelect
                  label=""
                  visibleItems={6}
                  noMargin
                  autoFocus
                  keepFocusAfterSelect
                  value={client}
                  options={clientOptions}
                  style={{ marginBottom: 30 }}
                  onChange={({ value }) => setClient(value)}
                />
              )}
              {viewFilter === 'department' && (
                <VirtualSelect
                  label=""
                  visibleItems={6}
                  noMargin
                  autoFocus
                  keepFocusAfterSelect
                  value={department}
                  options={departmentOptions}
                  style={{ marginBottom: 30 }}
                  onChange={({ value }) => setDepartment(value)}
                />
              )}
              <VirtualSelect
                label={'Schedule display setting'}
                visibleItems={6}
                width={240}
                nonNullable
                clearInputOnDropdownOpen={false}
                options={scheduleDisplayOptions}
                style={{ marginBottom: 30 }}
                value={schedDisplay}
                onChange={({ value }) => setSchedDisplay(value)}
              />
              <Button
                onClick={() => onGenerate()}
                loader={genLoading}
                disabled={genLoading}
                style={{ padding: '0 40px' }}
              >
                Generate link
              </Button>
              {link && (
                <>
                  <Label style={{ marginTop: 20, fontWeight: 500 }}>LINK</Label>
                  <SharedLinkInputContainer>
                    <Input
                      placeholder="Loading..."
                      ref={el}
                      value={genLoaded ? link : ''}
                      readOnly
                    />
                    {document.queryCommandSupported &&
                      document.queryCommandSupported('copy') && (
                        <CopyLinkContainer
                          title="Copy link"
                          onClick={genLoaded ? copyToClipboard : undefined}
                          disabled={genLoading}
                        >
                          Copy
                        </CopyLinkContainer>
                      )}
                  </SharedLinkInputContainer>
                  <StyledPreview
                    genLoaded={genLoaded}
                    onClick={() => genLoaded && window.open(link)}
                  >
                    See a preview
                    <IconShare
                      color={theme.blueGrey}
                      style={{ marginLeft: 7 }}
                    />
                  </StyledPreview>
                </>
              )}
            </StyledSection>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { people_id: personId, account_type_id: accountTypeId } =
    state.currentUser;
  const isAccOwner = accountTypeId === 1;
  const { companyPrefs } = state;
  const shareLinkEnabledPref = Boolean(companyPrefs.share_link_enabled);
  const shareLinkAllMembersEnabledPref = Boolean(
    companyPrefs.share_link_all_members_enabled,
  );

  return {
    isAccOwner,
    personId,
    deptFilterIds: state.currentUser.department_filter,
    shareLinkEnabledPref,
    shareLinkAllMembersEnabledPref,
    projectOptions: getAllProjectsOptions(state),
    clientOptions: getClientOptions(state),
    departmentOptions: getDepartmentOptions(state),
    defaultProject: getDefaultDropdownProject(state),
    companyHostname: companyPrefs.company_hostname,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateShareLinkPref: (data) => dispatch(updateCompanyShareLinkPrefs(data)),
});

export default modalManagerHoc({
  Comp: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withUserCanSee(ShareLinkModal)),
  modalType: 'shareLinkModal',
});
