import React, { useState } from 'react';
import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types/project';
import { CalloutDraftStatus } from '@float/web/components/modals/ModalProjectFromTemplate/components/CalloutDraftStatus';

import { ComboboxField, ComboboxFieldProps } from '../components/ComboboxField';
import { useStatusFieldOptions } from './StatusField.hooks';

import * as styles from './StatusField.styles.css';

type StatusFieldName = 'project.status' | 'phase.status';

type StatusFieldProps<N extends StatusFieldName> = {
  name: N;
  onChange?: ComboboxFieldProps<N>['onChange'];
};

export function StatusField<N extends StatusFieldName>({
  name,
  onChange,
}: StatusFieldProps<N>) {
  const entity = name.includes('phase') ? 'phase' : 'project';
  const { options, readOnly } = useStatusFieldOptions(entity);
  const hasDraftOption = options.find(
    (option) => option.value === ProjectStatus.Draft,
  );
  const [isOpen, setIsOpen] = useState(false);
  // Need to keep the dropdown forced open while the user interacts with the callout.
  const [forcedOpen, setForcedOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    if (!forcedOpen) {
      setIsOpen(open);
    }
  };

  return (
    <>
      <ComboboxField
        readOnly={readOnly}
        name={name}
        label={t`Status`}
        options={options}
        className={styles.statusField}
        searchable={false}
        clearInputOnDropdownOpen={false}
        onChange={(value) => {
          if (onChange) {
            onChange(value);
          }
        }}
        data-testid="projectStatusFieldDropdown"
        nonNullable
        gapBetweenItems={4}
        onOpenChange={onOpenChange}
        forcedOpen={forcedOpen}
      />
      {isOpen && hasDraftOption && (
        <CalloutDraftStatus onOpenChange={setForcedOpen} />
      )}
    </>
  );
}
