import React from 'react';
import { PeopleChildRow, RoleChildRow } from 'reports/types';

import { Role } from '@float/types/role';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';

export const NO_ROLE_ID = -1;

export const buildEmptyPersonChild = (
  personId: string,
  capacity: number,
): PeopleChildRow => ({
  billable: 0,
  capacity: capacity,
  combined: {
    scheduled: 0,
    billable: 0,
    nonbillable: 0,
    overtime: 0,
  },
  logged: {
    scheduled: 0,
    billable: 0,
    nonbillable: 0,
    overtime: 0,
  },
  nonbillable: 0,
  overtime: 0,
  person_id: personId,
  scheduled: 0,
  timeoff: 0,
});

export const buildEmptyRole = (role: Role): RoleChildRow => {
  return {
    billable: 0,
    capacity: 0,
    children: {
      people: {},
    },
    combined: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      overtime: 0,
    },
    future: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      overtime: 0,
      tentative: {
        billable: 0,
        nonbillable: 0,
      },
    },
    logged: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      overtime: 0,
    },
    nonbillable: 0,
    overtime: 0,
    role_id: role?.id || NO_ROLE_ID,
    scheduled: 0,
    tentative: {
      billable: 0,
      nonbillable: 0,
    },
    timeoff: 0,
  };
};

export const buildRoleNameCell = (role?: Role) => {
  if (!role) {
    return {
      type: 'jobTitle',
      name: 'None',
      val: 'No Role',
    };
  }

  return {
    type: 'jobTitle',
    name: role.name,
    sortVal: role.name.toLowerCase(),
    val: (
      <>
        {role.name}
        <Spacer size={4} />
      </>
    ),
  };
};
