import {
  viewCreatedAction,
  viewDeletedAction,
  viewUpdatedAction,
} from '@float/common/actions/views';
import { userCanSeeView } from '@float/common/lib/rights';
import { getViewById } from '@float/common/selectors/views';
import {
  AppDispatchStrict as AppDispatch,
  AppStoreStrict as AppStore,
} from '@float/common/store';
import { LiveUpdateNotification, RawView, SavedView } from '@float/types';

function formatViewLiveUpdatePayload(
  rawView: RawView,
  prevView: SavedView | null,
) {
  // The pinned value is per user but given the LiveUpdates architecture
  // we always get the pinned value of the user that triggered the LU
  // So, instead of trusting the LU data, we trust the local state
  const pinned = prevView ? prevView.pinned : false;

  return {
    ...rawView,
    pinned,
  };
}

export async function handleViewLiveUpdate(
  notification: LiveUpdateNotification,
  dispatch: AppDispatch,
  getState: AppStore['getState'],
) {
  if (notification.type !== 'view') return;

  const state = getState();
  const { currentUser } = state;

  switch (notification.action) {
    case 'create': {
      const rawView = formatViewLiveUpdatePayload(
        notification.af_item.after_data,
        null,
      );

      if (userCanSeeView(currentUser, rawView)) {
        dispatch(await viewCreatedAction(rawView));
      }

      break;
    }
    case 'update': {
      const payload = notification.af_item.after_data;

      // TODO Remove this line when we fix the LU payload
      // https://linear.app/float-com/issue/FT-1789/bug-if-one-user-pinunpin-a-shared-view-it-deletes-the-view-for-another
      if (!('personal' in payload)) return;

      const prevView = getViewById(state, payload.id);
      const rawView = formatViewLiveUpdatePayload(payload, prevView);

      const canSee = userCanSeeView(currentUser, rawView);

      // The view has become inaccessible for the user
      if (!canSee && prevView) {
        dispatch(viewDeletedAction(rawView.id, null));
      }
      // The view has been shared
      else if (canSee && !prevView) {
        dispatch(await viewCreatedAction(rawView));
      } else {
        dispatch(await viewUpdatedAction(rawView, null));
      }

      break;
    }
    case 'delete': {
      dispatch(viewDeletedAction(notification.af_item.before_data.id, null));
      break;
    }
  }
}
