import { useCallback, useState } from 'react';
import { t } from '@lingui/macro';

import { updateCompanyPrefsV2 } from '@float/common/actions/companyPrefs';
import { useAppDispatchStrict } from '@float/common/store';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';

export const useProjectCodesController = ({
  isProjectCodesEnabled,
}: {
  isProjectCodesEnabled: boolean;
}) => {
  const dispatch = useAppDispatchStrict();
  const { showSnackbar, showError } = useSnackbar();

  const [isProjectCodesEnabledInternal, setIsProjectCodesEnabledInternal] =
    useState(isProjectCodesEnabled);

  const toggleProjectCodes = useCallback(
    async (isEnabled: boolean) => {
      try {
        setIsProjectCodesEnabledInternal(isEnabled); // optimistic update
        await dispatch(
          // cast as 0 | 1 to satisfy float2 intvar validation
          updateCompanyPrefsV2({ project_codes: Number(isEnabled) }),
        );
        showSnackbar(
          isEnabled
            ? t`Project codes enabled successfully.`
            : t`Project codes disabled successfully.`,
        );
      } catch {
        setIsProjectCodesEnabledInternal(!isEnabled); // rollback optimistic update
        showError(
          isEnabled
            ? t`Failed to enable Project codes.`
            : t`Failed to disable Project codes.`,
        );
      }
    },
    [dispatch, showSnackbar, showError],
  );

  return { toggleProjectCodes, isProjectCodesEnabledInternal };
};
