import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { TopGreySection as ModalTopGreySection } from '@float/ui/deprecated/Modal/ModalHeader/styles';

export const StyledModalInput = styled(ModalTopGreySection)`
  display: flex;

  padding: 0;

  background-color: transparent;

  gap: 8px;
`;

export const StyledBoxItem = styled.div`
  display: inline-flex;
  flex-direction: column;

  width: 33%;
  min-width: 80px;

  & + div {
    margin-left: 24px;
  }
`;

export const StyledBox = styled.div`
  display: flex;

  width: 100%;

  border-radius: 8px;

  padding: 20px;

  background-color: ${Colors.TCore.Surface.Surf2};
`;
