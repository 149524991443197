import React, { useMemo, useRef } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { AvatarImage } from '@float/common/components/Avatar/AvatarImage';
import { isNewPlaceholderType } from '@float/common/lib/people/isNewPlaceholderType';
import { getHoursDisplayFormat } from '@float/common/lib/timer/displayHoursFormat';
import { getSearchFilteredProjects } from '@float/common/search/selectors/projects';
import { getUser } from '@float/common/selectors/currentUser';
import { getPeopleTagByLabel } from '@float/common/selectors/tags';
import { getActiveFilters } from '@float/common/selectors/views';
import { PersonRow } from '@float/common/serena/Data/useScheduleRows';
import {
  useAppSelector,
  useAppSelectorStrict,
  useAppStoreStrict,
} from '@float/common/store';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import {
  LoggedTimeCell,
  Person,
  PersonCell,
  Project,
  ProjectCell,
  SkedZoom,
} from '@float/types';
import { DownSmall as IconDownSmall } from '@float/ui/deprecated/Icons/iconChevronDownSmall';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { Table } from '@float/ui/deprecated/Table/Table';

import { useLogAllTasksInTimeRange } from '../../hooks/useLogAllTasksInTimeRange';
import { getRelevantInsightType } from '../../insights/helpers';
import { InsightForLogTeam } from '../../insights/InsightForLogTeam/InsightForLogTeam';
import { InsightForSchedule } from '../../insights/InsightForSchedule/InsightForSchedule';

import * as styles from './PersonCard.css';

export function getPersonStatusToday(
  currentWeekCell: ProjectCell | PersonCell | LoggedTimeCell | undefined,
  dayOfWeek: number,
) {
  const defaultStatus: Partial<{ name?: string; color: string }> = {};
  if (!currentWeekCell?.items) {
    return defaultStatus;
  }

  const item = currentWeekCell.items.find(
    (i) => i.type === 'status' && i.x === dayOfWeek,
  );
  const { entity: status } = item || {};
  if (status && 'status_id' in status) {
    return {
      name: status.status_name || status.status_type_name,
      color: status.color,
    };
  }

  return defaultStatus;
}

export const usePersonTagsWithColor = (person: Person) => {
  const peopleTagByLabel = useAppSelector(getPeopleTagByLabel);
  const peopleTagsWithColor = useMemo(
    () =>
      person.tags.map((tag) => ({
        ...tag,
        color: peopleTagByLabel[tag.name]?.color,
      })),
    [person.tags, peopleTagByLabel],
  );

  return peopleTagsWithColor;
};

export type PersonCardProps = {
  currentWeekCell: ProjectCell | PersonCell | LoggedTimeCell | undefined;
  person: Person;
  actions: {
    showAddTaskModal: (person: Person) => void;
    showPersonModal: (person: Person) => void;
    removeFromProjects: (person: Person, projects: Project[]) => void;
    showSidebarMenu: (
      position: React.MutableRefObject<unknown>,
      options: { title: string; action: () => void }[],
    ) => void;
    setDragItem: (...args: unknown[]) => void;
  };
  timeRangeSettings?: { insightsPreferredUnit?: string };
  logTimeView: boolean;
  rowHeight: number;
  dayOfWeek: number;
  row: PersonRow;
  rowGroupTop: number;
};

function PersonCard(props: PersonCardProps) {
  const {
    avatar_file,
    name,
    department,
    job_title,
    people_type_id,
    people_id: personId,
  } = props.person;

  const positionRef = useRef<SVGSVGElement>(null);
  const tags = usePersonTagsWithColor(props.person);
  const isPersonNewPlaceholderType = isNewPlaceholderType({
    person: props.person,
  });

  const { logTimeView, rowHeight, timeRangeSettings } = props;
  const { showAddTaskModal, showPersonModal, removeFromProjects } =
    props.actions;

  const insight = useAppSelectorStrict(
    (state) => state.timeRange.insights.byPerson?.[personId],
  );
  const insightsLoading = useAppSelectorStrict(
    (state) => state.timeRange.loadState === 'INITIAL',
  );
  const currentUser = useAppSelectorStrict(getUser);
  const hasProjectFilter = useAppSelectorStrict((state) =>
    getActiveFilters(state).some((f) => f.type === 'project'),
  );

  const { shared_link_view: isSharedLink } = currentUser;
  const type = currentUser.account_tid;
  const isLowEmphasis = people_type_id === 3 && !isPersonNewPlaceholderType;
  const personStatusToday = getPersonStatusToday(
    props.currentWeekCell,
    props.dayOfWeek,
  );
  const relevantInsightType = getRelevantInsightType('people', logTimeView);

  const store = useAppStoreStrict();

  const showMenu = (e: React.MouseEvent) => {
    prevent(e);

    const options = [];

    if (type != 4) {
      options.push({
        title: 'Allocate time',
        action: () => showAddTaskModal(props.person),
      });
    }

    options.push({
      title: 'View profile',
      action: () => showPersonModal(props.person),
    });

    if (hasProjectFilter && type !== 4) {
      options.push({
        title: 'Remove from project',
        action: () =>
          removeFromProjects(
            props.person,
            getSearchFilteredProjects(store.getState()),
          ),
      });
    }

    props.actions.showSidebarMenu(positionRef, options);
  };

  const hoursDisplayFormat = getHoursDisplayFormat(currentUser.prefs);

  const { isLoading, logAllTasksInTimeRange } = useLogAllTasksInTimeRange();

  const logAll = () => {
    return logAllTasksInTimeRange({ peopleIds: [personId] });
  };

  const isCompactView = currentUser.prefs?.sked_zoom === SkedZoom.Compact;

  return (
    <div
      className={styles.wrapper}
      data-interactive={!isSharedLink}
      onMouseDown={(event) => {
        if (event.button !== 0 || isSharedLink) return;
        props.actions.setDragItem({
          type: 'person-card',
          element: <div />,
          invisible: true,
          row: props.row,
          rowGroupTop: props.rowGroupTop,
          rowHeight,
          person: props.person,
          clientX: event.clientX,
          clientY: event.clientY,
        });
      }}
    >
      <div className={styles.personCardContent}>
        <div
          className={styles.avatar({
            type: 'personCard',
          })}
        >
          {/*@ts-expect-error AvatarImage is not typed */}
          <AvatarImage
            name={name}
            imageUrl={avatar_file}
            size="sm2"
            style={{ position: 'absolute' }}
            statusText={personStatusToday.name}
            statusColor={personStatusToday.color}
            isNewPlaceholderType={isPersonNewPlaceholderType}
          />
        </div>
        <div
          className={styles.personName({
            emphasis: isLowEmphasis ? 'low' : 'high',
            density: isCompactView ? 'compact' : 'default',
          })}
        >
          <div className={styles.personNameText}>{name}</div>
          {props.person.canEdit && (
            <div
              className={styles.menuBtn}
              onMouseDown={prevent}
              onClick={showMenu}
              data-testid="person-menu"
            >
              <IconDownSmall ref={positionRef} />
            </div>
          )}
        </div>
        {job_title && (
          <>
            <Spacer size={1} inline={false} />
            <div
              className={styles.jobTitle({
                emphasis: isLowEmphasis ? 'low' : 'high',
                density: isCompactView ? 'compact' : 'default',
              })}
            >
              {job_title}
            </div>
          </>
        )}
        {department && (
          <>
            <Spacer size={2} inline={false} />
            <div
              className={styles.departmentName({
                emphasis: isLowEmphasis ? 'low' : 'high',
                density: isCompactView ? 'compact' : 'default',
              })}
            >
              {department.name}
            </div>
          </>
        )}
        <Table.Tags
          className={styles.tags({
            density: isCompactView ? 'compact' : 'default',
          })}
          tags={tags}
          size={isCompactView ? 'xxsmall' : 'xsmall'}
        />
      </div>
      {!insightsLoading && (
        <div
          className={styles.insightWrapper({
            density: isCompactView ? 'compact' : 'default',
          })}
          style={assignInlineVars({
            [styles.rowHeightVar]: `${rowHeight}px`,
          })}
          onMouseDown={logTimeView ? prevent : undefined}
        >
          {relevantInsightType === 'schedule' && (
            <InsightForSchedule
              insight={insight}
              displayMetric={
                timeRangeSettings?.insightsPreferredUnit === 'percentage'
                  ? 'utilization'
                  : 'capacity'
              }
            />
          )}
          {relevantInsightType === 'logTeam' && (
            <InsightForLogTeam
              insight={insight}
              hoursDisplayFormat={hoursDisplayFormat}
              onClick={logAll}
              isLoading={isLoading}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(PersonCard);
