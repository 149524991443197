import React from 'react';
import styled from 'styled-components';

import { IconQuestion } from '@float/ui/deprecated/Icons/IconQuestion';
import {
  TextTooltip,
  TooltipProvider,
} from '@float/ui/deprecated/Tooltip/TextTooltip';

const StyledHintIconWrapper = styled.span`
  margin-left: 2px;

  // Compensate for SVG viewport alignment
  position: relative;
  margin-top: -4px;
  top: 3px;
`;

export const HintPrompt = ({ content }: { content: string }) => (
  <TooltipProvider>
    <TextTooltip
      className="hint"
      content={content}
      placement="top"
      distance={4}
    >
      <StyledHintIconWrapper>
        <IconQuestion size="16" />
      </StyledHintIconWrapper>
    </TextTooltip>
  </TooltipProvider>
);
