import { isArray, isEmpty } from 'lodash';
import qs from 'query-string';

import { Resources } from '@float/common/api3/constants';
import { config } from '@float/libs/config';
import { capitalize } from '@float/libs/utils/string/capitalize';
import { toDash } from '@float/libs/utils/strings';

import request, { fetchReport } from '../lib/request';
import { autoLogTime } from './autoLogTime';
import { bulkUpdate } from './bulkUpdate';
import { clients } from './clients';
import { currencies } from './currencies';
import { departments } from './departments';
import { exampleData } from './exampleData';
import { makeRequest } from './makeRequest';
import { phases } from './phases';
import { projects } from './projects';
import { projectTemplates } from './projectTemplates';
import { roles } from './roles';
import { tasks } from './tasks';
import { timeoffTypes } from './timeoffTypes';
import { timers } from './timers';
import { views } from './views';

const settingsUriOpts = { hostname: '/admin-api', version: '' };
const shareUriOpts = { hostname: '/share-api', version: '' };

const methods = {
  get: 'GET',
  getAll: 'GET',
  create: 'POST',
  update: 'PATCH',
  delete: 'DELETE',
};

const getPluralValue = (value, plural) => {
  return plural ? capitalize(plural) : `${capitalize(value)}s`;
};

/* Allow to create CRUD bundle by using spread operator.
  For ex: ...curCreator('person', 'people', ['get', 'getAll'], resource);
*/
const crudCreator = (
  entityType,
  plural,
  types = ['get', 'getAll', 'create', 'update', 'delete'],
  resource = plural || `${entityType}s`,
) =>
  types.reduce((result, type) => {
    const pre = type === 'getAll' ? 'get' : type;
    const capitalized =
      type === 'getAll'
        ? getPluralValue(entityType, plural)
        : capitalize(entityType);
    return {
      ...result,
      [`${pre}${capitalized}`]: (params) =>
        makeRequest({
          ...params,
          resource: toDash(resource),
          method: methods[type],
        }),
    };
  }, {});

// @deprecated - refactoring methods away from crudCreator and this barrel file and into individual modules.
export default {
  ...autoLogTime,
  ...clients,
  ...currencies,
  ...departments,
  ...exampleData,
  ...phases,
  ...projects,
  ...projectTemplates,
  ...timers,
  ...views,
  ...roles,
  ...tasks,
  ...timeoffTypes,
  bulkUpdate,
  ...crudCreator('tag'),
  ...crudCreator('person', 'people', ['getAll', 'update', 'delete']),
  ...crudCreator('timeoff'),
  ...crudCreator('holiday'),
  ...crudCreator('timeoffType'),
  ...crudCreator('milestone'),

  getPerson: (params, abortSignal) =>
    request.get(`people/${params.id}`, null, {
      version: 'f3',
      signal: abortSignal,
    }),

  getTimeoffBalance: (params) => {
    const query = {
      date: params.date,
      peopleId: params.peopleId,
    };
    if (params.ignoreTimeoffId) {
      query.ignoreTimeoffId = params.ignoreTimeoffId;
    }

    return request.get(
      `svc/ra/reports/timeoffs/balance/${params.timeoffTypeId}`,
      query,
      {
        version: '',
        contentType: 'application/json',
        json: true,
        jwt: true,
        hostname: '',
      },
    );
  },

  getAllTimeoffBalance: (params) =>
    request.get(
      'svc/ra/reports/timeoffs/balance/all',
      { date: params.date },
      {
        version: '',
        contentType: 'application/json',
        json: true,
        jwt: true,
        hostname: '',
      },
    ),

  createTaskMeta: (params) =>
    request.post('task-meta', params, { version: 'f3' }),

  updateTaskMeta: (params, id) =>
    request.put(`task-meta/${id}`, params, { version: 'f3' }),

  deleteTaskMeta: (id) =>
    request.del(`task-meta/${id}`, null, { version: 'f3' }),

  bulkDeleteTaskMeta: (task_meta_ids) =>
    request.post('task-meta/bulk-delete', { task_meta_ids }, { version: 'f3' }),

  mergeTaskMeta: (params) =>
    request.post('task-meta/merge', params, { version: 'f3' }),

  updateTaskStatusForRepeatingTask: (params, id) =>
    request.post(`tasks/${id}/status`, params, { version: 'f3' }),

  // TODO: Double-check if method below `signIn` is called at all
  signIn: (params) =>
    request.post(
      'token',
      {
        ...params,
        ...(config.requestClientId
          ? { client_id: config.requestClientId }
          : {}),
      },
      { version: 'f3' },
    ),

  generateMagicLink: ({ email, device_id }) => {
    return request.post(
      'magic',
      {
        email,
        device_id,
      },
      {
        version: 'f3',
        // API hostname is needed in order to apply the sanity limit of 10 requests
        hostname: config.api.apiHostname,
        contentType: 'application/x-www-form-urlencoded',
        headers: {
          'X-Device-Id': device_id,
        },
      },
      undefined,
      // Ensure there's no retry
      2,
    );
  },

  mobileSignInWithMagicToken: ({ magic_token, device_id }) => {
    return request.post(
      'token',
      {
        client_id: 'mobile',
        magic_token,
        device_id,
        ...(config.requestClientId
          ? { client_id: config.requestClientId }
          : {}),
      },
      {
        version: 'f3',
        contentType: 'application/x-www-form-urlencoded',
      },
      undefined,
      // Ensure there's no retry
      2,
    );
  },

  mobileSignInWithPassword: ({ username, password }) => {
    return request.post(
      'token',
      {
        client_id: 'mobile',
        username,
        password,
        external_source: config.isNativeTimerApp,
        ...(config.requestClientId
          ? { client_id: config.requestClientId }
          : {}),
      },
      {
        version: 'f3',
        contentType: 'application/x-www-form-urlencoded',
      },
      undefined,
      // Ensure there's no retry
      2,
    );
  },

  mobileSignInWithApple: ({ code }) => {
    return request.post(
      'token',
      {
        client_id: 'mobile',
        sso_type: 'apple',
        sso_code: code,
        external_source: config.isNativeTimerApp,
        ...(config.requestClientId
          ? { client_id: config.requestClientId }
          : {}),
      },
      {
        version: 'f3',
        contentType: 'application/x-www-form-urlencoded',
      },
      undefined,
      // Ensure there's no retry
      2,
    );
  },

  mobileSignInWithGoogle: ({ token }) => {
    return request.post(
      'token',
      {
        client_id: 'mobile',
        sso_type: 'google',
        sso_token: token,
        external_source: config.isNativeTimerApp,
        ...(config.requestClientId
          ? { client_id: config.requestClientId }
          : {}),
      },
      {
        version: 'f3',
        contentType: 'application/x-www-form-urlencoded',
      },
      undefined,
      // Ensure there's no retry
      2,
    );
  },

  refreshToken: ({ refresh_token }) => {
    return request.post(
      'token',
      {
        refresh_token,
        external_source: config.isNativeTimerApp,
        ...(config.requestClientId
          ? { client_id: config.requestClientId }
          : {}),
      },
      {
        version: 'f3',
        contentType: 'application/x-www-form-urlencoded',
      },
    );
  },

  updatePushToken: ({ token, type, experienceId }) => {
    return request.put(
      'svc/nf/push',
      { token, type, experienceId },
      {
        version: '',
        contentType: 'application/json',
        json: true,
        jwt: true,
      },
    );
  },

  notifyOnlineStatus: () => {
    return request.get('svc/nf/ping', null, {
      version: '',
      hostname: '',
      jwt: true,
    });
  },

  getAccounts: (params) =>
    makeRequest({
      ...params,
      resource: 'accounts',
      method: 'GET',
    }),

  getTags: () => request.get(`tags`, null, { version: 'f3' }),

  createTag: (tag) => request.post('tags', tag, { version: 'f3' }),

  updateTag: (tag) =>
    request.put(`tags/${tag.tags_id}`, tag, { version: 'f3' }),

  deleteTag: (tag) =>
    request.del(`tags/${tag.tags_id}`, null, { version: 'f3' }),

  getTagsGroups: () => request.get(`tags-groups`, null, { version: 'f3' }),

  createTagGroup: (tagGroup) =>
    request.post('tags-groups', tagGroup, { version: 'f3' }),

  updateTagGroup: (tagGroup) =>
    request.put(`tags-groups/${tagGroup.id}`, tagGroup, { version: 'f3' }),

  deleteTagGroup: (tagGroup) =>
    request.del(`tags/${tagGroup.id}`, null, { version: 'f3' }),

  createAccount: (params) =>
    request.post('accounts', params.data, { version: 'f3' }),
  getAccount: (params) =>
    request.get(`accounts/${params.id}?expand=management_group`, null, {
      version: 'f3',
    }),
  getAccountV3: (params) =>
    request.get(`accounts/${params.id}`, params.query, { version: 'f3' }),
  updateAccount: (params) =>
    makeRequest({
      data: params.data,
      id: params.id,
      method: 'PUT',
      options: settingsUriOpts,
      resource: Resources.Profile,
      shouldUseFormData: true,
    }),
  updateAccountV3: (params) =>
    request.put(`accounts/${params.id}`, params.data, { version: 'f3' }),
  updateGuest: (params) =>
    request.put(
      `accounts/${params.id}?expand=accepted,guest,project_count,management_group`,
      params.data,
      { version: 'f3' },
    ),
  deleteAccount: (params) =>
    request.del(`accounts/${params.id}`, null, { version: 'f3' }),

  changeAccountOwner: (params) =>
    request.form('change-account-owner', params.data, settingsUriOpts),

  fetchCurrentUser: () =>
    request.get(
      'me-api?expand=managers',
      { json: 1 },
      { version: '', hostname: '' },
    ),

  resendAccountInvite: (params) =>
    request.form('resendInvite', params.data, { hostname: '', version: '' }),

  getAccountTypes: () =>
    request
      .get('accessrights', { account_settings: 2 })
      // make old responses compatible with api3
      .then((entities) => [entities, 1]),

  getInvoices: (params) =>
    makeRequest({
      ...params,
      resource: 'list-invoices',
      method: 'GET',
    }),

  getApiKey: (params) =>
    makeRequest({
      ...params,
      resource: 'api-token',
      method: 'GET',
    }),

  getTimezones: (params) =>
    makeRequest({
      ...params,
      resource: 'timezones',
      method: 'GET',
    }),

  /**
   * Utilizes old form-style submission.
   */
  updateCompanyPrefs: (params) =>
    request.form('work-time', params.data, settingsUriOpts),

  updateCompanyPrefsV2: (params) =>
    request.put('companyprefs', params.data, settingsUriOpts),

  saveCompanyPrompts: async (prompts) =>
    await request.put(
      'companyprefs',
      { 'prompts[]': prompts.join(',') },
      settingsUriOpts,
    ),

  updateSecurityPrefs: (params) =>
    request.form('security', params.data, settingsUriOpts),

  updateCompanyShareLinkPrefs: (params) =>
    request.post('share-link-prefs', params.data, settingsUriOpts),

  validateCompanyHostname: (params) =>
    request.get('ck-host', params.data, settingsUriOpts),

  updateCompanyNameHostname: (params) =>
    request.form('update-company-hostname', params.data, settingsUriOpts),

  fetchCompanyPrefs: (params) =>
    request.get('companyprefs', null, settingsUriOpts),

  getBillingInfo: () => request.get('billing-info', null, settingsUriOpts),

  updateBillingPlan: (params) =>
    request.form('update-billing-plan', params.data, settingsUriOpts),

  startTimeTrackingTrial: () =>
    request.form('start-time-tracking-trial', null, settingsUriOpts),

  cancelTimeTrackingTrial: () =>
    request.form('cancel-time-tracking-trial', null, settingsUriOpts),

  startProTrial: () => request.form('start-pro-trials', null, settingsUriOpts),

  cancelProTrial: () =>
    request.form('cancel-pro-trials', null, settingsUriOpts),

  updateTimeTracking: (params) =>
    request.form('update-time-tracking', params.data, settingsUriOpts),

  pauseSubscription: (params) =>
    makeRequest({
      ...params,
      resource: 'pause-subscription',
      method: 'POST',
    }),

  deleteCompany: (params) =>
    makeRequest({
      ...params,
      resource: 'delete-account',
      method: 'DELETE',
    }),

  fetchCompanySso: () => request.get('get-company-sso', null, settingsUriOpts),

  getMyCompanies: () =>
    request.get('me-api/my-companies', null, { hostname: '', version: '' }),

  login: ({ email, password, companyId }) => {
    const formData = new FormData();
    formData.append('LoginForm[email]', email);
    formData.append('LoginForm[password]', password);
    formData.append('LoginForm[company_id]', companyId);

    return request.form('login', formData, {
      version: '',
      hostname: '',
    });
  },

  addToCompany: async ({ email, password }) => {
    const formData = new FormData();
    formData.append('multi_account[email]', email);
    formData.append('multi_account[password]', password);

    let res = await request.form('me-api/exist', formData, {
      version: '',
      hostname: '',
    });

    if (typeof res === 'string') {
      // Note that failrues will not be parseable and this will throw an Error
      res = JSON.parse(res);
    }

    return res;
  },

  getAllAccounts: () =>
    request.get('accounts?expand=management_group,accepted', null, {
      version: 'f3',
    }),

  getAllPeople: () => {
    return request.get('people/all?lean=1&expand=managers', null, {
      version: 'f3',
    });
  },

  // Hotfix: /all endpoint doesn't exsit for departments
  // increasing the number to 2000 should mitigate all issues.
  // We'll followup right after by create a `/all` endpoint on API3.
  getAllDepartments: () =>
    request.get(`departments?per-page=2000`, null, { version: 'f3' }),

  getAllPeopleById: (personId) =>
    request.get(`people/all/${personId}`, null, { version: 'f3' }),

  getAllClients: ({ page = 1, perPage = 25000, sort = 'project_id' } = {}) => {
    const q = {};
    if (page) q['internal_pagination'] = page;
    if (perPage) q['per-page'] = perPage;
    if (sort) q['sort'] = sort;

    return request.get(`clients?${qs.stringify(q)}`, null, {
      version: 'f3',
    });
  },

  getUserPrefs: async () => {
    try {
      const r = await request.get('user-prefs', null, { version: 'f3' });
      if (!r || !r.prefs) return {};
      return r.prefs;
    } catch (e) {
      console.error(e);
      return {};
    }
  },

  saveUserPrefs: async (prefs) => {
    if (isEmpty(prefs)) return;
    await request.post('user-prefs', prefs, { version: 'f3' });
  },

  saveUserPrompts: async (prompts) => {
    if (isEmpty(prompts)) return;

    // the request body needs to be sent in the following format:
    // prompts[]=32&prompts[]=33
    let args = prompts.map((prompt) => `prompts[]=${prompt}`);
    args = args.join('&');

    await request.post('prompts', args, {
      version: '',
      hostname: '',
      ignoreEncoding: true,
    });
  },

  fetchBudgetUsage: async (
    projectIds,
    { cache = true, includeArchived = false } = {},
  ) => {
    const url = new URL(
      config.reports.getLegacyPath('projects/budget'),
      config.reports.domain,
    );

    if (projectIds) {
      url.searchParams.append(
        'project_id',
        isArray(projectIds) ? projectIds.join(',') : projectIds,
      );
    }
    if (includeArchived) {
      url.searchParams.append('archived', 1);
    }
    const json = await fetchReport(url, { cache });
    return json;
  },

  getPublicHolidays: () => {
    return request.get(`holidays/regions`, null, {
      version: 'f3',
    });
  },

  getRegionalHolidays: async (country, region) => {
    return request.get(`holidays/regions/${country}/${region}`, null, {
      version: 'f3',
    });
  },

  createRegionalHolidays: (regionData) => {
    return request.post(`holidays/regions`, regionData, {
      version: 'f3',
    });
  },

  updateRegionalHolidays: (regionData) => {
    return request.put(`holidays/regions/${regionData.region_id}`, regionData, {
      version: 'f3',
    });
  },

  deleteRegionalHolidays: (regionId) => {
    return request.del(`holidays/regions/${regionId}`, null, {
      version: 'f3',
    });
  },

  createCustomRegionalHoliday: (regionId, payload) => {
    return request.post(`holidays/regions/${regionId}/custom`, payload, {
      version: 'f3',
    });
  },

  updateCustomRegionalHoliday: (regionId, payload) => {
    return request.put(
      `holidays/regions/${regionId}/custom/${payload.region_holiday_id}`,
      payload,
      {
        version: 'f3',
      },
    );
  },

  deleteCustomRegionalHoliday: (regionId, payload) => {
    return request.del(
      `holidays/regions/${regionId}/custom/${payload.region_holiday_id}`,
      null,
      {
        version: 'f3',
      },
    );
  },

  createSharedLink: ({ filters, scheduleView }) => {
    return request.post(
      `create-shared-link`,
      {
        filters,
        schedule_view: scheduleView,
      },
      shareUriOpts,
    );
  },

  cancelPastSharedLinks: () => {
    return request.post(`cancel-past-links`, {}, shareUriOpts);
  },

  fetchLockLoggedTimeConfig: () =>
    request.get('log-time-lock', null, { version: 'f3' }),

  upsertLockLoggedTimeConfig: (active, interval, frequency) =>
    request.post(
      'log-time-lock',
      { active: active ? 1 : 0, interval, frequency },
      { hostname: '', version: '' },
    ),
};
