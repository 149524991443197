import React from 'react';
import { isEqual } from 'lodash';

import { Button } from '@float/ui/deprecated/Button/Button';
import { Card } from '@float/ui/deprecated/Earhart/Cards';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles/TextToggle';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { withSnackbar } from '@float/ui/deprecated/Snackbar';
import { Table } from '@float/ui/deprecated/Table/Table';

import YearTableFilter, {
  DEFAULT_YEAR,
} from '../../containers/Holidays/YearTableFilter';
import HolidaysTable from '../../containers/Timeoffs/HolidaysTable';
import TimeoffTypesTable from '../../containers/Timeoffs/TimeoffTypesTable';
import Body from '../Body';
import { PublicHolidaysTable } from '../Holidays/PublicHolidays/PublicHolidaysTable';
import RestrictedMessage from '../RestrictedMessage';
import {
  StyledLabel,
  StyledLink,
} from '../TimeTracking/LockTimeLoggingSettingsCard/styles';
import BalanceTableCell from './BalanceTableCell';
import ColorNameTableCell from './ColorNameTableCell';
import HolidayModal from './modals/HolidayModal';
import TimeoffTypeModal from './modals/TimeoffTypeModal';
import { StyledRow } from './styles';

const ADD_TIMEOFF_TYPE_POPUP = 'addTimeoffType';
const EDIT_TIMEOFF_TYPE_POPUP = 'editTimeoffType';
const ADD_HOLIDAY_POPUP = 'addHoliday';
const EDIT_HOLIDAY_POPUP = 'editHoliday';

const TIMEOFF_TYPE = {
  ACTIVE: 1,
  ARCHIVED: 0,
};

class Timeoffs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupFor: false,
      timeoffApprovalsEnabled: props.timeoffApprovalsEnabled,
      holidaysYear: DEFAULT_YEAR,
      timeoffTypeFilter: [TIMEOFF_TYPE.ACTIVE],
    };

    this.onChangeYearTableFilter = this.onChangeYearTableFilter.bind(this);
  }

  onClickAddTimeoffType = () => {
    this.setState({
      popupFor: { type: ADD_TIMEOFF_TYPE_POPUP },
    });
  };

  onClickEditTimeoffType = (id) => {
    this.setState({
      popupFor: { type: EDIT_TIMEOFF_TYPE_POPUP, id },
    });
  };

  onClickAddHoliday = () => {
    this.setState({
      popupFor: { type: ADD_HOLIDAY_POPUP },
    });
  };

  onClickEditHoliday = (id) => {
    this.setState({
      popupFor: { type: EDIT_HOLIDAY_POPUP, id },
    });
  };

  onClickFormCloseButton = () => {
    this.setState({
      popupFor: false,
    });
  };

  onChangeYearTableFilter(holidaysYear) {
    this.setState({
      holidaysYear,
    });
  }

  renderAddTimeoffTypeBtn() {
    return <Button onClick={this.onClickAddTimeoffType}>Add</Button>;
  }

  renderAddHolidayBtn() {
    return <Button onClick={this.onClickAddHoliday}>Add</Button>;
  }

  handleTimeoffApprovalsToggle = ({ value }) => {
    const isEnabled = value == 'On';
    this.props.showSnackbar(
      `Time off approvals ${isEnabled ? 'enabled' : 'disabled'}.`,
    );
    this.setState({ timeoffApprovalsEnabled: isEnabled });
    this.props.onSubmit({ timeoff_approvals: isEnabled ? 1 : 0 });
  };

  toggleTableFilter = (timeoffType, isActive) => {
    const { timeoffTypeFilter } = this.state;
    if (isActive) {
      this.setState({
        timeoffTypeFilter: [...timeoffTypeFilter, timeoffType],
      });
    } else {
      if (timeoffTypeFilter.length === 1) {
        return; // noop: should always have at least one filter
      }
      this.setState({
        timeoffTypeFilter: timeoffTypeFilter.filter(
          (filter) => filter !== timeoffType,
        ),
      });
    }
  };

  render() {
    const { popupFor, timeoffTypeFilter } = this.state;
    const newHoliday = popupFor.type === ADD_HOLIDAY_POPUP;
    const editHoliday = popupFor.type === EDIT_HOLIDAY_POPUP;
    const newTimeoffType = popupFor.type === ADD_TIMEOFF_TYPE_POPUP;
    const editTimeoffType = popupFor.type === EDIT_TIMEOFF_TYPE_POPUP;

    if (!this.props.userCanSee.timeoffs) {
      return <RestrictedMessage />;
    }

    return (
      <Body
        header="Time off"
        subheader="Set the types of time off that can be assigned and the holidays that you'd like to block out in the schedule."
      >
        {(newHoliday || editHoliday) && (
          <HolidayModal
            close={this.onClickFormCloseButton}
            newHoliday={newHoliday}
            holiday={editHoliday && this.props.getHolidayById(popupFor.id)}
          />
        )}

        {(newTimeoffType || editTimeoffType) && (
          <TimeoffTypeModal
            close={this.onClickFormCloseButton}
            newTimeoffType={newTimeoffType}
            timeoffType={
              editTimeoffType && this.props.getTimeoffTypeById(popupFor.id)
            }
          />
        )}

        <Card>
          <StyledRow>
            <h4>Time off approvals</h4>
            <TextToggle
              options={[
                { value: 'Off', label: 'Off' },
                { value: 'On', label: 'On' },
              ]}
              value={this.state.timeoffApprovalsEnabled ? 'On' : 'Off'}
              onChange={this.handleTimeoffApprovalsToggle}
              toggle
            />
          </StyledRow>
          <StyledRow>
            <StyledLabel>
              Require your team to get manager approval for time off requests.{' '}
              <StyledLink
                href="https://support.float.com/en/articles/6702104-time-off-approvals"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </StyledLink>
            </StyledLabel>
          </StyledRow>
        </Card>

        <Card>
          <StyledRow justifyContent="space-between">
            <h4>Time off types</h4>
            {this.renderAddTimeoffTypeBtn()}
          </StyledRow>

          <Spacer size={20} />
          <StyledRow>
            <Table.Viewing
              optionsLeft={[
                {
                  label: 'Active',
                  isSelected: timeoffTypeFilter.includes(TIMEOFF_TYPE.ACTIVE),
                  onChange: (isActive) =>
                    this.toggleTableFilter(TIMEOFF_TYPE.ACTIVE, isActive),
                },
                {
                  label: 'Archived',
                  isSelected: timeoffTypeFilter.includes(TIMEOFF_TYPE.ARCHIVED),
                  onChange: (isActive) =>
                    this.toggleTableFilter(TIMEOFF_TYPE.ARCHIVED, isActive),
                },
              ]}
            />
          </StyledRow>
          <Spacer size={30} />
          <StyledRow>
            <TimeoffTypesTable
              recordNames={['timeoff type', 'timeoff types']}
              displayRowCount={false}
              headNames={['Time off', 'Balance', 'Days']}
              headNameKeys={['timeoff_type_name']}
              emptyMessage={
                isEqual(timeoffTypeFilter, [TIMEOFF_TYPE.ARCHIVED])
                  ? 'There are no archived Time off types.'
                  : 'There are no Time off types.'
              }
              cellRenderers={[ColorNameTableCell, BalanceTableCell]}
              onClickEdit={(row) => this.onClickEditTimeoffType(row.id)}
              filter={(row) => {
                return timeoffTypeFilter.includes(row.entity.active);
              }}
              rowActions={[
                {
                  id: 'edit',
                  label: 'Edit',
                },
              ]}
            />
          </StyledRow>
        </Card>

        <PublicHolidaysTable />

        <Card>
          <StyledRow>
            <h4>Team holidays</h4>
            {this.renderAddHolidayBtn()}
          </StyledRow>

          <StyledRow>
            <p>
              Create your own custom holidays to block out the schedule for all
              team members.
            </p>
          </StyledRow>

          <StyledRow>
            <YearTableFilter onChange={this.onChangeYearTableFilter} />
          </StyledRow>

          <StyledRow>
            <HolidaysTable
              recordNames={['holiday', 'holidays']}
              displayRowCount={false}
              headNames={['Holiday', 'Start date', 'End date']}
              headNameKeys={['name', 'date', 'end_date']}
              onClickEdit={(row) => this.onClickEditHoliday(row.id)}
              year={this.state.holidaysYear}
              rowActions={[
                {
                  id: 'edit',
                  label: 'Edit',
                },
              ]}
            />
          </StyledRow>
        </Card>
      </Body>
    );
  }
}

Timeoffs.propTypes = {};

export default withSnackbar(Timeoffs);
