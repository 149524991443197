import React from 'react';
import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import Icons from '@float/ui/deprecated/Icons';

const colorFromTheme = ({ color, theme }) =>
  theme[color] || color || theme.primary;

export const StyledTab = styled.div`
  font-size: 13px;
  min-height: 40px;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 4px;
  margin-right: 24px;
  color: ${colorFromTheme};
  border-bottom: 3px solid transparent;
  cursor: pointer;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  ${(p) => p.theme.ie11MinHeightFix}
  ${({ active, color }) =>
    active
      ? css`
          pointer-events: none;
          &:after {
            content: '';
            width: 100%;
            height: 3px;
            position: absolute;
            bottom: -3px;
            left: 0;
            background-color: ${colorFromTheme};
            border-radius: 6px;
          }
        `
      : color
        ? css`
            opacity: 0.6;
            &:hover {
              opacity: 1;
            }
          `
        : css`
            color: ${(p) => p.theme.emphasisMedium};
          `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Disabled};
      pointer-events: none;
    `};

  .tab-error-icon {
    top: 2px;
    position: relative;
    left: 8px;
  }
`;

const TabLabel = styled.div`
  font-weight: 600;
`;

const TabCounter = styled.div`
  margin-left: 5px;
`;

const TabValue = ({ hasError, active, counter }) => {
  if (hasError && !active) {
    return <Icons.Alert className="tab-error-icon" />;
  }
  if (counter) {
    return <TabCounter>{counter}</TabCounter>;
  }
  return null;
};

/**
 * @type {*}
 */
export const Tab = React.forwardRef(
  (
    {
      label,
      onClick,
      counter,
      active,
      hasError,
      disabled,
      color,
      tooltip,
      style,
      ...rest
    },
    ref,
  ) => (
    <StyledTab
      ref={ref}
      color={color}
      disabled={disabled}
      style={style}
      onClick={onClick}
      active={active}
      {...rest}
    >
      <TabLabel>{label}</TabLabel>
      <TabValue hasError={hasError} active={active} counter={counter} />
      {tooltip}
    </StyledTab>
  ),
);

export { TabLabel };

export default Tab;
