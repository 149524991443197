import styled from 'styled-components';

import { ErrorText, SecondaryText } from '@float/ui/deprecated/Text';

export const StyledFieldGroup = styled.div`
  margin-bottom: 20px;

  &:nth-last-child(2) {
    margin-bottom: 32px;
  }

  .reset {
    text-align: right;

    ${ErrorText} {
      margin: 0;
      padding: 0;
    }
  }
`;

export const StyledExpandableContainer = styled.div`
  position: relative;

  width: calc(100% + 4px);

  max-height: var(--max-height, 0);

  padding: 0 4px;

  overflow: hidden;

  transition: max-height 0.25s ease-in-out;
`;

export const StyledInput = styled.div`
  position: relative;

  margin-bottom: 20px;

  .currentPassword {
    ${SecondaryText} {
      position: absolute;

      top: 100%;
    }
  }
`;
