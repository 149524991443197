import React, { useCallback } from 'react';
import { getPrefs, getUser } from 'selectors';
import { showTemplateSidePanel } from 'sidePanel/actions';

import { updateUserPref } from '@float/common/actions/currentUser';
import { Rights } from '@float/common/lib/acl';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Checkbox } from '@float/ui/deprecated/Checkbox/Checkbox';
import IconTemplatePlus from '@float/ui/deprecated/Icons/icon-template-plus';
import { ModalTitle } from '@float/ui/deprecated/Modal';

import { ManageTemplatesList } from './ManageTemplatesList';
import {
  ManageTemplatesModalWrapper,
  TemplateModalActions,
  TemplateModalBody,
  TemplateModalHeader,
} from './styles';

function ManageTemplatesModal({ onClose }) {
  const currentUser = useAppSelector(getUser);
  const prefs = useAppSelector(getPrefs);
  const dispatch = useAppDispatch();

  const handleNewTemplateOpen = useCallback(() => {
    onClose();
    dispatch(showTemplateSidePanel());
  }, [dispatch, onClose]);

  const toggleStartFromScratch = useCallback(async () => {
    dispatch(
      updateUserPref('project_from_scratch', !prefs.project_from_scratch),
    );
  }, [prefs.project_from_scratch, dispatch]);

  return (
    <ManageTemplatesModalWrapper
      isOpen
      center
      onClose={onClose}
      width="calc(100% - 64px)"
      style={{ height: 690 }}
    >
      <TemplateModalHeader>
        <ModalTitle>Manage project templates</ModalTitle>
        <TemplateModalActions>
          <Checkbox
            label="Show templates when I create new projects"
            value={!prefs.project_from_scratch}
            onChange={toggleStartFromScratch}
          />
          {Rights.canCreateProjectTemplate(currentUser) && (
            <Button
              appearance="tertiary"
              size="small"
              icon={IconTemplatePlus}
              onClick={handleNewTemplateOpen}
            >
              New template
            </Button>
          )}
        </TemplateModalActions>
      </TemplateModalHeader>
      <TemplateModalBody>
        <ManageTemplatesList hideParent={onClose} />
      </TemplateModalBody>
    </ManageTemplatesModalWrapper>
  );
}

export default ManageTemplatesModal;
