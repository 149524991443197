import styled from 'styled-components';

import { FIN } from '@float/common/earhart/colors';
import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect/DropdownSelect';
import { TextL, TextM, TextXXL } from '@float/ui/deprecated/Earhart/Typography';

const { StyledDropdownLabel } = DropdownSelect._styles;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};

  color: ${FIN.Lt.Emphasis.High};

  & + & {
    margin-top: 20px;
  }

  h4 {
    ${TextXXL.SB600};
  }

  h5 {
    ${TextL.SB600};
  }

  h6 {
    ${TextM.M500};
  }

  h5 + p {
    margin-top: 2px;
  }

  p {
    ${TextM.R400};

    color: ${FIN.Lt.Emphasis.Medium};
  }

  a {
    ${TextM.SB600};

    color: ${FIN.Lt.Buttons.Primary.Default.Bg};

    &:hover {
      color: ${FIN.Lt.Buttons.Primary.Hover.Bg};
    }
  }
`;

export const StyledColumn = styled.div`
  flex: 0.5;

  display: flex;
  flex-direction: column;
`;

export const StyledColumnContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  margin-top: 14px;
`;

export const StyledColumnContentWithDropdown = styled(StyledColumnContent)`
  > * + * {
    margin-left: 5px;
  }

  ${StyledDropdownLabel} {
    display: flex;
    justify-content: space-between;
    min-width: 140px;
  }
`;
