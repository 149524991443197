import React from 'react';
import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { CurrencyInput } from '@float/ui/deprecated/Input';

import { FieldLabel } from '../Label';
import { TokenWrapper } from '../MultiSelect/styles';
import TextArea from '../TextArea/TextArea';

const TextValue = styled.span`
  ${Typography.TextL.M500};

  min-height: 40px;

  color: ${Colors.FIN.Lt.Emphasis.High};
`;

const ScrollableTextValue = styled(TextValue)`
  display: block;
  overflow-y: auto;
  max-height: 123px;
`;

const ColorCircle = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: #${(p) => p.color};
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${TokenWrapper} {
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Phase = styled.div`
  ${Typography.Label12.SB600};
  color: ${Colors.FIN.Lt.Emphasis.High};
`;

const getValueComponent = (type, value, rest) => {
  if (type === 'text') {
    return <TextValue {...rest}>{value}</TextValue>;
  }

  if (type === 'textarea') {
    return <TextArea value={value} readOnly {...rest} />;
  }

  if (type === 'color') {
    return <ColorCircle color={value} {...rest} />;
  }

  if (type === 'scrollableText') {
    return <ScrollableTextValue {...rest}>{value}</ScrollableTextValue>;
  }

  if (type === 'tags') {
    return (
      <TagContainer>
        {value.map((t) => {
          const label = t.name || t.label;
          return (
            <TokenWrapper key={label} color={t.color}>
              {label}
            </TokenWrapper>
          );
        })}
      </TagContainer>
    );
  }

  throw Error(`Unknown type [${type}] for ReadOnlyField`);
};

export const ReadOnlyField = ({
  label,
  type = 'text',
  value,
  labelPadding,
  wrapperStyle,
  ...rest
}) => {
  if (type === 'phase') {
    return <Phase {...rest}>{value}</Phase>;
  }

  if (type === 'currency') {
    return (
      <CurrencyInput
        readOnly
        noBorder
        alignLeft
        minHeight={20}
        label={label}
        value={value}
        style={{
          marginBottom: 22,
        }}
        {...rest}
      />
    );
  }

  return (
    <div style={wrapperStyle}>
      {label && (
        <FieldLabel
          style={{
            marginBottom: labelPadding ?? (type === 'tags' ? 10 : 8),
          }}
        >
          {label}
        </FieldLabel>
      )}
      {getValueComponent(type, value, rest)}
    </div>
  );
};

export default ReadOnlyField;
