import { SerenaState } from 'selectors';

import { AccountType, Person } from '@float/types';

export function getDefaultPerson(
  defaultPerson: Person | null,
  {
    allPeople,
    people,
    user,
    filters,
  }: Pick<SerenaState, 'allPeople' | 'people' | 'user' | 'filters'>,
) {
  const isMember = user.account_type_id === AccountType.Member;

  if (defaultPerson === null) {
    // A member can only schedule a task for themseleves
    // if there is no taskable person in the filters results, use the user person as default
    if (isMember && user.people_id) return allPeople[user.people_id] ?? null;

    return null;
  }

  const isSelfAssignee = defaultPerson.people_id === user.people_id;

  // The user can only self-assign
  if (isMember && isSelfAssignee) return defaultPerson;

  // There is a search active with only one person
  if (isFilteringByOnePerson({ people, user, filters })) return defaultPerson;

  return null;
}
function isFilteringByOnePerson({
  user,
  filters,
}: Pick<SerenaState, 'people' | 'user' | 'filters'>) {
  if (user.prefs?.me_filter) return true;

  if (filters.length !== 1) return false;

  const [filter] = filters;

  if (filter.type === 'person') {
    if (Array.isArray(filter.val)) return filter.val.length === 1;

    return true;
  }

  return false;
}
