import React from 'react';
import { t } from '@lingui/macro';
import { getVisibleSettings } from 'selectors';

import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import { useAppSelectorStrict } from '@float/common/store';
import { Card } from '@float/ui/deprecated/Earhart/Cards';

import AddClient from '../../containers/Clients/AddClient';
import ClientsTable from '../../containers/Clients/ClientsTable';
import Body from '../Body';
import RestrictedMessage from '../RestrictedMessage';
import { DeleteMessage } from './DeleteMessage';
import { ProjectCodesSection } from './ProjectCodesSection';
import { StyledRow } from './styles';

const Clients = () => {
  const userCanSee = useAppSelectorStrict(getVisibleSettings);
  const { isProjectCodesFeatureFlagEnabled } = useProjectCodesPreference();

  if (!userCanSee.clients) {
    return <RestrictedMessage />;
  }

  const header = isProjectCodesFeatureFlagEnabled
    ? t`Clients & Project codes`
    : t`Clients`;

  const subheader = isProjectCodesFeatureFlagEnabled
    ? t`Manage the Clients you group your Projects into and set the visibility of Project codes.`
    : t`Add, edit and remove the Clients you group your Projects into.`;

  return (
    <Body header={header} subheader={subheader}>
      {isProjectCodesFeatureFlagEnabled && (
        <Card>
          <StyledRow>
            <ProjectCodesSection
              header={t`Project codes`}
              subheader={t`Enable dedicated Project codes for your Projects.`}
            />
          </StyledRow>
        </Card>
      )}
      <Card>
        {isProjectCodesFeatureFlagEnabled && (
          <StyledRow>
            <h3>Clients</h3>
          </StyledRow>
        )}
        <StyledRow>
          <AddClient placeholder={t`Add a Client`} />
        </StyledRow>

        <StyledRow>
          <ClientsTable
            recordNames={['client', 'clients']}
            displayRowCount
            headNames={['Clients', 'Projects']}
            headNameKeys={['name', 'project_count']}
            confirmDeleteProps={DeleteMessage}
            rowActions={[
              {
                id: 'edit',
                label: t`Edit`,
              },
              {
                id: 'delete',
                label: t`Delete`,
              },
            ]}
          />
        </StyledRow>
      </Card>
    </Body>
  );
};

export default Clients;
