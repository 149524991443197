import { divisionOperation, multiplyOperation } from '@float/libs/utils/floats';

export const getAllocationHoursPerDayValue = (
  hoursTotal: number | null,
  numberOfAllocationDays: number,
) => {
  if (!hoursTotal) return 0;
  if (numberOfAllocationDays === 0) return 0;

  return divisionOperation(hoursTotal, numberOfAllocationDays);
};

export const getAllocationPercentage = (
  allocationHoursTotal: number | null,
  allocationHoursTotalAvailable: number,
) => {
  if (!allocationHoursTotal) return 100;
  if (!allocationHoursTotalAvailable) return 100;

  return divisionOperation(
    100 * allocationHoursTotal,
    allocationHoursTotalAvailable,
  );
};

export const getAllocationHoursTotalValueFromHoursPerDay = (
  hoursPerDay: number | null,
  numberOfAllocationDays: number,
) => {
  if (!hoursPerDay) return 0;
  if (numberOfAllocationDays === 0) return 0;

  return multiplyOperation(hoursPerDay, numberOfAllocationDays);
};

export const getAllocationHoursTotalValueFromPercent = (
  percentage: number | null,
  numberOfWorkingDayHoursInDateRange: number,
) => {
  if (!percentage) return 0;

  return divisionOperation(
    percentage * numberOfWorkingDayHoursInDateRange,
    100,
  );
};
