import React from 'react';
import get from 'lodash/get';

import { RowMetaItem } from '@float/common/serena/Data/useRowMetas.helpers';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import {
  CellItem,
  CellItemWithEntity,
  CellsMap,
  DatesManager,
} from '@float/types';

import EntityItem from './Item/EntityItem';
import HolidayItem from './Item/HolidayItem';
import LockedDayItem from './Item/LockedDayItem';
import DefaultLoggedTimeItem from './Item/LoggedTimeItem';
import MilestoneItem from './Item/MilestoneItem';
import NonWorkDayItem from './Item/NonWorkDayItem';
import OneOffItem from './Item/OneOffItem';
import OvertimeItem from './Item/OvertimeItem';
import PhaseItem from './Item/PhaseItem';
import PlaceholderItem from './Item/PlaceholderItem';
import StatusItem from './Item/StatusItem';
import TimeRangeItem from './Item/TimeRangeItem';
import { ScheduleActions } from './types';

export type Row = PersonRow | PersonProjectRow | ProjectRow;

type BuildItemProps = {
  actions: ScheduleActions;
  customLoggedTimeItem?: React.JSXElementConstructor<
    React.ComponentProps<typeof DefaultLoggedTimeItem>
  > | null;
  dayWidth: number;
  hourHeight?: number;
  itemsInited?: unknown;
  linkedArrowTargetRef?: React.MutableRefObject<SVGSVGElement | null>;
  row?: Row;
  rowMeta?: RowMetaItem;
  totalHeight?: number;
};

type BuildItemExtraProps = {
  boundaryRef: React.MutableRefObject<HTMLElement | undefined>;
  cells: CellsMap;
  dates: DatesManager;
  isEntityResizing?: boolean;
  isTimer?: boolean;
  itemIdx: number;
  logMyTimeView?: boolean;
  logTimeView?: boolean;
  printMode: boolean;
  scrollWrapperRef: React.MutableRefObject<HTMLElement | undefined>;
  singleUserView: boolean;
  suvSingleDay: string | null | undefined;
  viewType: string | undefined;
  isForced?: boolean;
  wrapperRef: React.RefObject<HTMLDivElement>;
};

export default function buildItem(
  props: BuildItemProps,
  i: CellItem,
  extraProps: BuildItemExtraProps,
) {
  const {
    row,
    rowMeta,
    actions,
    dayWidth,
    totalHeight,
    hourHeight = 0,
    itemsInited,
    customLoggedTimeItem: CustomLoggedTimeItem,
  } = props;
  const { viewType } = extraProps;
  const isProjectPlanView = viewType === 'projects';

  if (i.type === 'selection') {
    if (row && row.type === 'person' && 'projectId' in row) {
      if (
        (i as CellItem<'selection'>).entity.projectViewProjectId !==
        row.projectId
      )
        return null;
    }

    return (
      <PlaceholderItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        hourHeight={hourHeight}
        fullDay
        suvSingleDay={extraProps.suvSingleDay}
        cells={extraProps.cells}
        actions={actions}
        linkedArrowTargetRef={props.linkedArrowTargetRef}
        wrapperRef={extraProps.wrapperRef}
        row={row}
      />
    );
  }

  if (row && 'isPlaceholder' in i && i.isPlaceholder) {
    // Always render placeholders in people view
    let shouldRender = viewType === 'people' || i.type === 'timeRange';
    if (!shouldRender && isProjectPlanView) {
      if (row?.type == 'project') {
        // Always render milestone placeholders
        shouldRender = true;
      } else if (row?.type === 'person' && 'projectId' in row) {
        // Only render placeholders on the active row
        shouldRender =
          row.projectId ==
          (get((i as CellItemWithEntity).entity, 'project_id') ||
            get((i as CellItemWithEntity).entity, 'projectViewProjectId'));
      }
    }

    if (shouldRender) {
      return (
        <PlaceholderItem
          key={i.key}
          item={i}
          dayWidth={dayWidth}
          hourHeight={hourHeight}
          fullDay={Boolean(get((i as CellItemWithEntity).entity, 'full_day'))}
          suvSingleDay={extraProps.suvSingleDay}
          cells={extraProps.cells}
          actions={actions}
          linkedArrowTargetRef={props.linkedArrowTargetRef}
          wrapperRef={extraProps.wrapperRef}
          row={row}
        />
      );
    }
  }

  if (row && i.type === 'loggedTime') {
    const LoggedTimeItem = CustomLoggedTimeItem || DefaultLoggedTimeItem;
    return (
      <LoggedTimeItem
        key={i.key}
        item={i}
        actions={actions}
        dayWidth={dayWidth}
        hourHeight={hourHeight}
        row={row}
        boundaryRef={extraProps.boundaryRef}
        suvSingleDay={extraProps.suvSingleDay}
        logMyTimeView={extraProps.logMyTimeView}
        // @ts-expect-error These are props for the custom LoggedTimeItem
        isTimer={extraProps.isTimer}
        itemIdx={extraProps.itemIdx}
        itemsInited={itemsInited}
      />
    );
  }

  if (row && i.type === 'task') {
    return (
      <EntityItem
        key={i.key}
        item={i}
        actions={actions}
        dayWidth={dayWidth}
        hourHeight={hourHeight}
        row={row}
        boundaryRef={extraProps.boundaryRef}
        suvSingleDay={extraProps.suvSingleDay}
        wrapperRef={extraProps.wrapperRef}
        linkedArrowTargetRef={props.linkedArrowTargetRef}
        cells={extraProps.cells}
        isProjectPlanView={isProjectPlanView}
      />
    );
  }

  if (
    row &&
    i.type === 'timeoff' &&
    !(i as CellItem<'timeoff'>).entity.region_holiday_id
  ) {
    return (
      <EntityItem
        key={i.key}
        item={i}
        actions={actions}
        dayWidth={dayWidth}
        hourHeight={hourHeight}
        row={row}
        rowMeta={rowMeta}
        boundaryRef={extraProps.boundaryRef}
        printMode={extraProps.printMode}
        suvSingleDay={extraProps.suvSingleDay}
        cells={extraProps.cells}
      />
    );
  }

  if (row && i.type === 'nonWorkDay') {
    return (
      <NonWorkDayItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        actions={actions}
        suvSingleDay={extraProps.suvSingleDay}
        row={row}
      />
    );
  }

  if (row && i.type === 'lockedDay') {
    return (
      <LockedDayItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        suvSingleDay={extraProps.suvSingleDay}
        isEntityResizing={extraProps.isEntityResizing}
        row={row}
      />
    );
  }

  if (i.type === 'oneOff') {
    return (
      <OneOffItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        suvSingleDay={extraProps.suvSingleDay}
      />
    );
  }

  if (row) {
    if (
      i.type === 'holiday' ||
      (i.type === 'timeoff' &&
        (i as CellItem<'timeoff'>).entity.region_holiday_id)
    ) {
      return (
        <HolidayItem
          key={i.key}
          item={i}
          dayWidth={dayWidth}
          actions={actions}
          suvSingleDay={extraProps.suvSingleDay}
          row={row}
        />
      );
    }
  }

  if (i.type === 'overtime') {
    return (
      <OvertimeItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        hourHeight={hourHeight}
        suvSingleDay={extraProps.suvSingleDay}
        isForced={extraProps.isForced}
      />
    );
  }

  if (i.type === 'milestone') {
    return (
      <MilestoneItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        hourHeight={hourHeight}
        actions={actions}
        boundaryRef={extraProps.boundaryRef}
        wrapperRef={extraProps.wrapperRef}
        scrollWrapperRef={extraProps.scrollWrapperRef}
      />
    );
  }

  if (i.type === 'phase') {
    return (
      <PhaseItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        hourHeight={hourHeight}
        actions={actions}
        boundaryRef={extraProps.boundaryRef}
        wrapperRef={extraProps.wrapperRef}
        scrollWrapperRef={extraProps.scrollWrapperRef}
      />
    );
  }

  if (i.type === 'status') {
    return (
      <StatusItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        actions={actions}
        boundaryRef={extraProps.boundaryRef}
      />
    );
  }

  if (i.type === 'timeRange') {
    return (
      <TimeRangeItem
        key={i.key}
        item={i}
        dayWidth={dayWidth}
        totalHeight={totalHeight}
        actions={actions}
        wrapperRef={extraProps.wrapperRef}
        scrollWrapperRef={extraProps.scrollWrapperRef}
        singleUserView={extraProps.singleUserView}
        dates={extraProps.dates}
      />
    );
  }

  return null;
}
