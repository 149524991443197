import {
  cloneDeep,
  forEach,
  isArray,
  isEqual,
  isUndefined,
  map,
  omit,
  reduce,
} from 'lodash';

import { normalizeColor } from '@float/libs/colors/utils/normalizeColor';
import {
  Project,
  ProjectStatus,
  ProjectTemplate,
  RawPhase,
  RawProject,
  RawProjectTemplate,
  RawTask,
  TemplatePhase,
} from '@float/types';

import * as actions from '../actions';
import { formatAmount } from '../lib/budget';
import { fastObjectSpread } from '../lib/fast-object-spread';
import { omitOne } from '../lib/omit';
import { TAG_TYPE } from '../selectors/tags';
import {
  BudgetsLoadFailedAction,
  BudgetsLoadFinishActionType,
  BudgetsLoadStartAction,
} from '../store/budgets/budgets.actions';
import { handleRemoveTag, handleUpdateTag } from './lib/applyTagUpdate';

export const DEFAULT_STATE: ProjectsState = {
  projects: {},
  // Tracks the project that has been fetched using the `getProject` to get people_rates and full notes
  fullyLoaded: [],
  syncIcon: {},
  templates: {},
  loadState: 'UNLOADED',
  budgetsLoadState: 'UNLOADED',
  templatesLoadState: 'UNLOADED',
  archivedProjectsLoaded: false,
  archivedBudgetsLoaded: false,
};

export function getStatus(rawData: RawProject | RawProjectTemplate | RawPhase) {
  if (rawData.status !== undefined) return rawData.status;

  if (rawData.tentative !== undefined) {
    return rawData.tentative === 1
      ? ProjectStatus.Tentative
      : ProjectStatus.Confirmed;
  }

  return ProjectStatus.Confirmed;
}

const mapProject = (p: RawProject) => {
  const proj: Partial<Project> = {
    // Attributes shared with search context
    project_id: p.project_id,
    project_name: p.name || p.project_name,
    project_code: p.project_code,
    active: p.active === 1,
    project_manager: Number(p.project_manager),

    // Attributes not present in search context
    description: p.notes,
    notes_meta: p.notes_meta,
    common: p.all_pms_schedule === 1,
    start_date: p.project_dates?.start_date || p.start_date,
    end_date: p.project_dates?.end_date || p.end_date,
    set_dates: {
      start_date: p.set_dates?.start_date,
      end_date: p.set_dates?.end_date,
    },
    created: p.created,
    modified: p.modified,
    budget_type: p.budget_type,
    budget_priority: p.budget_priority,
    locked_task_list: p.locked_task_list,
    budget_total: formatAmount(null, p.budget_total || p.budget),
    default_hourly_rate: formatAmount(null, p.default_hourly_rate),
    integrations_co_id: p.integrations_co_id,
    ext_resource_id: p.ext_resource_id,
    ext_calendar_count: p.ext_calendar_count || 0,
    calculated_end_date: p.calculated_end_date,
    calculated_start_date: p.calculated_start_date,
    status: getStatus(p),
  };

  if (!isUndefined(p.people_ids)) proj.people_ids = p.people_ids;
  if (!isUndefined(p.project_team)) {
    proj.people_ids = map(p.project_team, (pt) => Number(pt.people_id));
    proj.people_rates = reduce(
      p.project_team,
      (acc, pt) => {
        acc[pt.people_id] = formatAmount(null, pt.hourly_rate);
        return acc;
      },
      {} as Record<number, string | null>,
    );
  }

  // Attributes not present in the all endpoint. Define them conditionally so
  // that the merge ignores them if necessary
  if (!isUndefined(p.tags)) proj.tags = p.tags;
  if (!isUndefined(p.client_id)) proj.client_id = p.client_id;
  if (!isUndefined(p.color)) proj.color = normalizeColor(p.color);
  if (!isUndefined(p.tentative)) proj.tentative = p.tentative === 1;
  if (!isUndefined(p.non_billable)) proj.non_billable = p.non_billable === 1;

  return proj;
};

function mapTemplatePhases(phases: TemplatePhase[] = []) {
  if (!phases?.length) return [];

  return phases.map((phase) => ({
    ...phase,
    phase_name: phase.name,
    budget_total: formatAmount(null, phase.budget_total),
    active: true,
  }));
}

const mapTemplate = (p: RawProjectTemplate) => {
  const template: ProjectTemplate = {
    // Attributes shared with search context
    project_template_id: p.project_template_id,
    project_name: p.name || p.project_name || '',
    active: p.active === 1,
    locked_task_list: p.locked_task_list,
    project_manager: Number(p.project_manager),

    // Attributes not present in search context
    description: p.notes,
    common: p.all_pms_schedule === 1,
    start_date: p.project_dates?.start_date || p.start_date,
    end_date: p.project_dates?.end_date || p.end_date,
    created: p.created,
    modified: p.modified,
    creator_id: p.creator_id,
    team_people: p.team_people,
    budget_type: p.budget_type,
    budget_priority: p.budget_priority,
    milestones: p.milestones,
    phases: mapTemplatePhases(p.phases as TemplatePhase[]),
    task_metas: p.task_metas,
    budget_total: formatAmount(null, p.budget_total || p.budget),
    default_hourly_rate: formatAmount(null, p.default_hourly_rate),
    integrations_co_id: p.integrations_co_id,
    ext_resource_id: p.ext_resource_id,
    ext_calendar_count: p.ext_calendar_count || 0,
    people_ids: p.team_people?.map((p) => p.id) ?? p.people_ids ?? [],
    status: getStatus(p),
  };

  if (!isUndefined(p.team_people)) {
    template.people_rates = reduce(
      p.team_people,
      (acc, pt) => {
        acc[pt.id] = formatAmount(null, pt.hourly_rate);
        return acc;
      },
      {} as Record<number, string | null>,
    );
  }

  // Attributes not present in the all endpoint. Define them conditionally so
  // that the merge ignores them if necessary.
  if (!isUndefined(p.tags)) template.tags = p.tags;
  if (!isUndefined(p.client_id)) template.client_id = p.client_id;
  if (!isUndefined(p.color)) template.color = p.color;
  if (!isUndefined(p.tentative)) template.tentative = p.tentative === 1;
  if (!isUndefined(p.duration)) template.duration = p.duration;
  if (!isUndefined(p.non_billable))
    template.non_billable = p.non_billable === 1;

  return template;
};

// TODO Make this function more safe
const mergeProjects = (a: Project, b?: RawProject) => {
  if (!b) return a;

  const newProject = mapProject(b);
  let mergedProject = newProject;

  if (a) {
    mergedProject = fastObjectSpread(a, newProject, {
      start_date: newProject.start_date || a.start_date,
      end_date: newProject.end_date || a.end_date,
    });
  }

  if (isUndefined(mergedProject.people_ids)) mergedProject.people_ids = [];
  if (isUndefined(mergedProject.tags)) mergedProject.tags = [];

  return mergedProject as Project;
};

const mergeTemplates = (
  a: ProjectTemplate = {} as ProjectTemplate,
  b?: RawProjectTemplate,
) => {
  if (!b) return a;

  const newProject = mapTemplate(b);

  const mergedProject = fastObjectSpread(a, newProject, {
    start_date: newProject.start_date || a.start_date,
    end_date: newProject.end_date || a.end_date,
  });

  return mergedProject;
};

const addPersonToProject = (
  state: ProjectsState,
  action: {
    personId?: number;
    projectId: number;
    peopleIds?: number[];
  },
) => {
  const { projectId, personId } = action;
  const peopleIds = action.peopleIds || [];
  if (personId && !peopleIds.includes(personId)) {
    peopleIds.push(personId);
  }

  const project = state.projects[projectId] || { people_ids: [] };
  if (!project || !project.people_ids) {
    return state;
  }

  const addedPeopleIds: number[] = [];

  peopleIds.forEach((persId) => {
    if (!project.people_ids.includes(persId)) {
      addedPeopleIds.push(persId);
    }
  });

  if (!addedPeopleIds.length) {
    // don't want to mutate state in this scenario
    return state;
  }

  return {
    ...state,
    projects: {
      ...state.projects,
      [projectId]: {
        ...project,
        people_ids: [...project.people_ids, ...addedPeopleIds],
      },
    },
  };
};

export type ProjectsState = {
  projects: Record<number, Project>;
  // Tracks the project that has been fetched using the `getProject` to get people_rates and full notes
  fullyLoaded: number[];
  syncIcon: Record<
    number,
    {
      loadState: 'LOADED' | 'LOADING' | 'LOAD_FAILED';
      data: Record<number, unknown>;
    }
  >;
  templates: Record<number, ProjectTemplate>;
  archivedProjectsLoaded: boolean;
  archivedBudgetsLoaded: boolean;
  loadState: 'LOADED' | 'LOADING' | 'LOAD_FAILED' | 'UNLOADED';
  budgetsLoadState: 'LOADED' | 'LOADING' | 'LOAD_FAILED' | 'UNLOADED';
  templatesLoadState: 'LOADED' | 'LOADING' | 'FAILED' | 'UNLOADED';
};

export type ProjectAction =
  | {
      type: typeof actions.UNMOUNT_SETTINGS_v2;
    }
  | actions.ProjectsLoadStartAction
  | actions.ProjectsLoadFinishAction
  | actions.ProjectsLoadFailedAction
  | {
      type: typeof actions.PROJECTS_UPDATED | typeof actions.PROJECT_LOADED;
      project: RawProject;
    }
  | {
      type: typeof actions.PROJECT_UPDATE_FAILED;
      prevValue: Project;
    }
  | BudgetsLoadStartAction
  | BudgetsLoadFailedAction
  | BudgetsLoadFinishActionType
  | {
      type: typeof actions.TEMPLATES_LOAD_START;
    }
  | {
      type: typeof actions.TEMPLATES_LOAD_FAILED;
    }
  | {
      type: typeof actions.TEMPLATES_LOAD_FINISH;
      templates: RawProjectTemplate[];
    }
  | {
      type: typeof actions.TEMPLATES_UPDATED;
      template: RawProjectTemplate;
    }
  | {
      type: typeof actions.TEMPLATE_LOADED;
      template: RawProjectTemplate;
    }
  | {
      type: typeof actions.TEMPLATES_DELETED;
      templateId: number;
    }
  | {
      type: typeof actions.PERSON_PROJECTS_UPDATE;
      personId: number;
      update: {
        add: number[];
        del: number[];
      };
    }
  | {
      type: typeof actions.PEOPLE_PROJECT_ADDED;
      personId?: number;
      projectId: number;
      peopleIds?: number[];
    }
  | {
      type: typeof actions.PROJECTS_DATES_UPDATED;
      projects: {
        project_id: number;
        start_date?: string;
        end_date?: string;
      }[];
    }
  | actions.ProjectsBulkUpdatedAction
  | {
      type: typeof actions.PROJECTS_IMPORTED;
      result?: RawProject[];
    }
  | {
      type: typeof actions.PROJECTS_DELETED;
      projectId: number;
    }
  | {
      type: typeof actions.PROJECTS_BULK_DELETED;
      ids?: number[];
    }
  | {
      type: typeof actions.DELETE_CLIENT_SUCCESS;
      id: number;
    }
  | {
      type: typeof actions.PROJECTS_BULK_CLONED;
      result: Record<number, Project[]>;
    }
  | {
      type: typeof actions.PROJECTS_LOAD_SYNC_ICONS;
      coIntId: number;
    }
  | {
      type: typeof actions.PROJECTS_LOAD_SYNC_ICON_FAILED;
      coIntId: number;
    }
  | {
      type: typeof actions.PROJECTS_SET_SYNC_ICONS;
      coIntId: number;
      data?: { projectId: number; syncIcon: unknown }[];
    }
  | {
      type: typeof actions.TAGS_UPDATED;
      tags: { type: number; tags_id: number; name: string }[];
    }
  | {
      type: typeof actions.TAGS_DELETED;
      tags: { type: number; tags_id: number; name: string }[];
    };

type ActionsFromTask = {
  type:
    | typeof actions.CREATE_TASK_SUCCESS
    | typeof actions.UPDATE_TASK
    | typeof actions.REPLACE_TASK_SUCCESS
    | typeof actions.INSERT_TASK_SUCCESS;
  item: RawTask;
};

type ActionsFromLoggedTime = {
  type: typeof actions.LOGGED_TIME_CREATED;
  loggedTime: {
    project_id: number;
    people_id: number;
    phase_id: string;
  };
};

const projects = (
  state: ProjectsState = DEFAULT_STATE,
  action: ProjectAction | ActionsFromTask | ActionsFromLoggedTime,
) => {
  switch (action.type) {
    case actions.UNMOUNT_SETTINGS_v2: {
      // Settings v2 is self-contained until we merge it into the main reducers.
      // Therefore, we need to reload data when the user navigates away from
      // settings v2 in case they made changes there.
      return {
        ...state,
        loadState: 'UNLOADED',
      };
    }

    case actions.PROJECTS_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case actions.PROJECTS_LOAD_FINISH: {
      // Note that the API3 response for /projects DOES NOT consist of a
      // superset of the data here, so we MUST merge.
      const newProjects = action.forceLoad
        ? {}
        : fastObjectSpread(state.projects);

      if (action.projects) {
        for (const p of action.projects) {
          newProjects[p.project_id] = mergeProjects(
            state.projects[p.project_id],
            p,
          );
        }
      }

      if (action.archivedProjects) {
        for (const p of action.archivedProjects) {
          newProjects[p.project_id] = mergeProjects(
            state.projects[p.project_id],
            p,
          );
        }
      }

      return {
        ...state,
        loadState: 'LOADED',
        archivedProjectsLoaded:
          action.includeArchived || state.archivedProjectsLoaded || false,
        projects: newProjects,
      };
    }

    case actions.PROJECTS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case actions.TEMPLATES_LOAD_START: {
      return {
        ...state,
        templatesLoadState: 'LOADING',
      };
    }

    case actions.TEMPLATES_LOAD_FINISH: {
      return {
        ...state,
        templatesLoadState: 'LOADED',
        templates: action.templates.reduce(
          (total, next) => {
            total[next.project_template_id] = mapTemplate(next);
            return total;
          },
          {} as ProjectsState['templates'],
        ),
      };
    }

    case actions.TEMPLATES_LOAD_FAILED: {
      return {
        ...state,
        templatesLoadState: 'FAILED',
      };
    }

    case actions.TEMPLATES_UPDATED:
    case actions.TEMPLATE_LOADED: {
      const id = action.template.project_template_id;
      const prevTemplate = state.templates[id];
      const newTemplate = mergeTemplates(prevTemplate, action.template);

      // Updating this state is expensive, as it has dowstream effects
      // like multiple selector re-evaluations and re-renders.
      if (isEqual(prevTemplate, newTemplate)) {
        return state;
      }

      return {
        ...state,
        templates: {
          ...state.templates,
          [id]: newTemplate,
        },
      };
    }

    case actions.TEMPLATES_DELETED: {
      return {
        ...state,
        templates: omitOne(state.templates, String(action.templateId)),
      };
    }

    case actions.PERSON_PROJECTS_UPDATE: {
      const projects = { ...state.projects };

      for (const id of action.update.add) {
        projects[id] = {
          ...projects[id],
          people_ids: projects[id].people_ids.concat(action.personId),
        };
      }

      for (const id of action.update.del) {
        projects[id] = {
          ...projects[id],
          people_ids: projects[id].people_ids.filter(
            (id) => id !== action.personId,
          ),
        };
      }

      return {
        ...state,
        projects,
      };
    }

    case actions.PEOPLE_PROJECT_ADDED: {
      return addPersonToProject(state, action);
    }

    case actions.PROJECT_UPDATE_FAILED: {
      const projects = { ...state.projects };
      projects[action.prevValue.project_id] = action.prevValue;
      return {
        ...state,
        projects,
      };
    }

    case actions.PROJECTS_UPDATED:
    case actions.PROJECT_LOADED: {
      const id = action.project.project_id;
      const prevProject = state.projects[id];
      const newProject = mergeProjects(prevProject, action.project);

      // Updating this state is expensive, as it has dowstream effects
      // like multiple selector re-evaluations and re-renders.
      if (isEqual(prevProject, newProject)) {
        return state;
      }

      return {
        ...state,
        fullyLoaded: state.fullyLoaded.concat(id),
        projects: {
          ...state.projects,
          [id]: newProject,
        },
      };
    }

    case actions.PROJECTS_DATES_UPDATED: {
      const { projects } = action;
      if (!projects || !projects.length) {
        return state;
      }

      const newProjects = fastObjectSpread(state.projects);
      let updated = false;
      let currProject;

      projects.forEach((p) => {
        currProject = newProjects[p.project_id];

        if (!currProject) {
          return;
        }

        if (
          currProject.start_date !== p.start_date ||
          currProject.end_date !== p.end_date
        ) {
          updated = true;
          newProjects[p.project_id] = {
            ...currProject,
            start_date: p.start_date,
            end_date: p.end_date,
          };
        }
      });

      return updated ? { ...state, projects: newProjects } : state;
    }

    case actions.PROJECTS_BULK_UPDATED: {
      type BULK_UPDATE_FIELDS = (typeof action)['fields'];

      const { ids, fields } = action;
      const newProjects = { ...state.projects };
      forEach(ids, (id) => {
        if (newProjects[id]) {
          newProjects[id] = { ...newProjects[id] };

          forEach(fields, (value, key) => {
            if (key === 'tags') {
              const { add, del } = value as BULK_UPDATE_FIELDS['tags'];

              if (del && del.length) {
                newProjects[id][key] = newProjects[id][key].filter(
                  (x) => !del.includes(x),
                );
              }

              if (add && add.length) {
                const existingTags = newProjects[id][key];
                add.forEach((t) => {
                  if (!existingTags.includes(t)) {
                    newProjects[id][key].push(t);
                  }
                });
              }
            } else if (key === 'creator_id') {
              newProjects[id].project_manager = value as number;
            } else if (key === 'budget') {
              newProjects[id].budget_total = formatAmount(
                null,
                value as number,
              );
            } else if (key === 'tentative') {
              const isTentative = value === 1;
              newProjects[id].tentative = isTentative;
              newProjects[id].status = isTentative
                ? ProjectStatus.Tentative
                : ProjectStatus.Confirmed;
            } else {
              // @ts-expect-error
              newProjects[id][key] = value;
            }
          });
        }
      });
      return {
        ...state,
        projects: newProjects,
      };
    }

    case actions.PROJECTS_IMPORTED: {
      const { result } = action;
      if (!result || !result.length) {
        return state;
      }
      const newProjects = { ...state.projects };
      result.forEach((p) => {
        newProjects[p.project_id] = mergeProjects(
          state.projects[p.project_id],
          p,
        );
      });
      return {
        ...state,
        projects: newProjects,
      };
    }

    case actions.PROJECTS_DELETED: {
      return {
        ...state,
        projects: omitOne(state.projects, String(action.projectId)),
      };
    }

    case actions.PROJECTS_BULK_DELETED: {
      const { ids } = action;
      if (!ids || !ids.length) return state;

      return {
        ...state,
        projects: omit(state.projects, ids),
      };
    }

    case actions.DELETE_CLIENT_SUCCESS: {
      const { id: clientId = null } = action;

      if (!clientId) {
        return state;
      }

      const relatedProjects: Project['project_id'][] = [];

      Object.values(state.projects).forEach((project) => {
        if (project.client_id === clientId)
          relatedProjects.push(project.project_id);
      });

      const projects = Object.assign({}, state.projects);

      relatedProjects.forEach((projectId) => {
        projects[projectId].client_id = undefined;
        projects[projectId].client_name = '';
      });

      return { ...state, projects };
    }

    case actions.PROJECTS_BULK_CLONED: {
      const { result } = action;
      const newProjects = { ...state.projects };
      forEach(result, (clonedProjects, originalProjectId) => {
        forEach(clonedProjects, (clonedProject) => {
          const newProject = cloneDeep(newProjects[Number(originalProjectId)]);
          newProjects[clonedProject.project_id] = {
            ...newProject,
            ...clonedProject,
          };
        });
      });
      return {
        ...state,
        projects: newProjects,
      };
    }

    case actions.PROJECTS_LOAD_SYNC_ICONS: {
      const { coIntId } = action;
      return {
        ...state,
        syncIcon: {
          ...state.syncIcon,
          [coIntId]: {
            loadState: 'LOADING',
            data: {},
          },
        },
      };
    }

    case actions.PROJECTS_LOAD_SYNC_ICON_FAILED: {
      const { coIntId } = action;
      return {
        ...state,
        syncIcon: {
          ...state.syncIcon,
          [coIntId]: {
            loadState: 'LOAD_FAILED',
            data: {},
          },
        },
      };
    }

    case actions.PROJECTS_SET_SYNC_ICONS: {
      const { coIntId, data = [] } = action;
      const newValue = data.reduce(
        (acc, elem) => {
          const { projectId, syncIcon } = elem;
          acc[projectId] = syncIcon;

          return acc;
        },
        {} as Record<number, unknown>,
      );

      return {
        ...state,
        syncIcon: {
          ...state.syncIcon,
          [coIntId]: {
            loadState: 'LOADED',
            data: newValue,
          },
        },
      };
    }

    case actions.CREATE_TASK_SUCCESS:
    case actions.UPDATE_TASK:
    case actions.REPLACE_TASK_SUCCESS:
    case actions.INSERT_TASK_SUCCESS: {
      let item = action.item;
      if (!item) return state;

      // Logged time created
      if (isArray(item)) item = item[0];

      const {
        project_id: projectId,
        people_id: personId,
        people_ids: peopleIds,
        phase_id: phaseId = 0,
      } = item;

      if (+phaseId! !== 0) {
        // Task created for a specific phase,
        // no need to add person to project team.
        return state;
      }

      return addPersonToProject(state, { projectId, personId, peopleIds });
    }

    case actions.LOGGED_TIME_CREATED: {
      let item = action.loggedTime;
      if (!item) return state;

      // Logged time created
      if (isArray(item)) item = item[0];

      const {
        project_id: projectId,
        people_id: personId,
        phase_id: phaseId = 0,
      } = item;

      if (+phaseId !== 0) {
        // Task created for a specific phase,
        // no need to add person to project team.
        return state;
      }

      return addPersonToProject(state, { projectId, personId, peopleIds: [] });
    }

    case actions.TAGS_UPDATED:
      return handleUpdateTag(state, action, {
        key: 'projects',
        id: 'project_id',
        type: TAG_TYPE.PROJECT,
      });

    case actions.TAGS_DELETED:
      return handleRemoveTag(state, action, {
        key: 'projects',
        id: 'project_id',
        type: TAG_TYPE.PROJECT,
      });

    default: {
      return state;
    }
  }
};

export { default as milestones } from './milestones';

export default projects;
