import { t } from '@lingui/macro';
import { format } from 'date-fns';

import { startTimer, stopTimer } from '@float/common/actions';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppDispatch } from '@float/common/store';
import { noop } from '@float/libs/utils/noop';
import { LoggedTime, Timer } from '@float/types';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';

type LogTimeModalSubmit = (
  hours: number,
  createNewLoggedTime: boolean,
  overrideDate?: string,
) => Promise<[{ loggedTime: LoggedTime }]>;

export type UseTimerControlsParams = {
  actions: {
    scrollToToday: () => void;
    isTodayVisible: () => boolean;
  };
  currentDate: string;
  currentHours: number;
  isRemoving: boolean;
  isSaving: boolean;
  isSuggestedTask: boolean;
  onClose: () => void;
  submit: LogTimeModalSubmit;
  validate: () => boolean;
};

export type UseScheduleContext = {
  setSuvWeek: (suvWeek: number) => void;
  currentWeek: number;
  logMyTimeView: boolean;
};

export function useTimerControls(params: UseTimerControlsParams) {
  const {
    actions,
    currentDate,
    currentHours,
    isRemoving,
    isSaving,
    isSuggestedTask,
    onClose = noop,
    submit,
    validate,
  } = params;

  const dispatch = useAppDispatch();
  const { setSuvWeek, currentWeek, logMyTimeView } =
    useScheduleContext() as UseScheduleContext;
  const { showSnackbar } = useSnackbar();

  async function handleTimerStart() {
    if (!validate() || isSaving || isRemoving) return;

    const today = format(Date.now(), 'yyyy-MM-dd');

    const createNewLoggedTime = today !== currentDate;
    const hours = isSuggestedTask || createNewLoggedTime ? 0 : currentHours;

    const [{ loggedTime }] = await submit(hours, createNewLoggedTime, today);

    await dispatch(
      startTimer({
        logged_time_id: loggedTime.logged_time_id,
        date: loggedTime.date,
        people_id: loggedTime.people_id,
        project_id: loggedTime.project_id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    );

    if (!actions.isTodayVisible()) {
      const date = format(Date.now(), 'dd MMM yyyy');
      showSnackbar(t`Timer started on ${date}.`, {
        linkText: t`View today`,
        onLinkClick: () => {
          if (logMyTimeView) {
            setSuvWeek(currentWeek);
          } else {
            actions.scrollToToday();
          }
        },
      });
    }

    onClose();
  }

  async function handleTimerStop(timer: Timer) {
    await dispatch(stopTimer(timer._id));
  }

  return { handleTimerStart, handleTimerStop };
}
