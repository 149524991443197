import React, { CSSProperties } from 'react';

import { DAY_WIDTH } from '@float/common/serena/ScheduleContext';
import { CellItem, CompanyPreferences } from '@float/types';

import { BoxInnerNarrow } from './BoxInnerNarrow';
import { BoxInnerRegular } from './BoxInnerRegular';
import { BoxGetterProps } from './types';

interface BoxInnerProps {
  dayWidth: number;
  itemWidth: number;
  item: CellItem<'task'> | CellItem<'timeoff'>;
  companyPrefs: CompanyPreferences;
  styles: CSSProperties;
  props: BoxGetterProps;
}

export const BoxInner: React.FC<BoxInnerProps> = ({
  dayWidth,
  itemWidth,
  item,
  companyPrefs,
  styles,
  props,
}) => {
  if (dayWidth === DAY_WIDTH.MONTH && itemWidth === 1) {
    return <BoxInnerNarrow item={item} styles={styles} props={props} />;
  } else {
    return (
      <BoxInnerRegular
        item={item}
        companyPrefs={companyPrefs}
        styles={styles}
        props={props}
      />
    );
  }
};
