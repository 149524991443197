import { useRef, useState } from 'react';
import { MotionGlobalConfig } from 'framer-motion';

import { SeatCapacity } from '@float/common/lib/hooks/useSeatCapacity';
import { useStateCallback } from '@float/common/lib/hooks/useStateCallback';
import { Input } from '@float/ui/deprecated/Input';

import { useListFromClipboard } from './useListFromClipboard';

const CONFIG = {
  min: 3,
};

export enum QuickAddMethod {
  Manual = 'manual',
  Paste = 'paste',
}
export type InputItemState = {
  autoFocus: boolean;
  disabled: boolean;
  id: string;
  removable: boolean;
  value: string;
};

export interface InputListState {
  addInput: () => void;
  addMethod: QuickAddMethod;
  inputCount: { total: number; valid: number };
  inputListState: InputItemState[];
  onInputChange: (index: number, value: string) => void;
  onInputKeyDown: (index: number, e: KeyboardEvent) => void;
  removeInput: (index: number) => void;
  setInputRef: (input: Input) => void;
}

const getInputItemDefaultState = (
  overrides?: Partial<InputItemState>,
): InputItemState => ({
  autoFocus: false,
  disabled: false,
  id: '',
  removable: true,
  value: '',
  ...overrides,
});

const getInputListDefaultState = (seatCapacity: SeatCapacity) => {
  const total = Math.max(Math.min(CONFIG.min, seatCapacity.seatsAvailable), 1);

  return Array.from(Array(total).keys()).map((index) =>
    getInputItemDefaultState({
      id: `${Date.now()}-${index}`,
      autoFocus: index === 0,
      removable: seatCapacity.canAddSeats(index),
      disabled: !seatCapacity.canAddSeats(index),
    }),
  );
};

const getInputCount = (state: InputItemState[]) => {
  const total = state.length;
  const valid = state.filter(
    (input) => input.value.trim() !== '' && !input.disabled,
  ).length;

  return {
    total,
    valid,
  };
};

export const useInputList = (
  seatCapacity: SeatCapacity,
  onAddInput: (newInputCount: number, fromClipboard?: boolean) => void,
  onChange: (isDirty: boolean) => void,
): InputListState => {
  const inputsRef = useRef<Input[]>([]);

  const [addMethod, setAddMethod] = useState<QuickAddMethod>(
    QuickAddMethod.Manual,
  );

  const [inputListState, setInputListState] = useStateCallback(() =>
    getInputListDefaultState(seatCapacity),
  );

  const inputCount = getInputCount(inputListState);

  if (onChange) onChange(inputCount.valid > 0);

  const setInputRef = (input: Input) => {
    if (input) {
      const { index } = input.props;

      inputsRef.current[index] = input;
    }
  };

  const addInput = () => {
    const nextIndex = inputListState.length;

    const nextInputState = getInputItemDefaultState({
      autoFocus: true,
      id: `${Date.now()}-${nextIndex}`,
    });

    setInputListState([...inputListState, nextInputState], (state) => {
      onAddInput(state.length);
    });
  };

  const removeInput = (index: number) => {
    let nextInputListState = [...inputListState];

    nextInputListState.splice(index, 1);

    nextInputListState = refreshInputDisabledState(nextInputListState);

    setInputListState(nextInputListState);
  };

  const onInputChange = (index: number, value: string) => {
    const nextInputListState = inputListState.map(
      (inputItem, inputItemIndex) => {
        if (inputItemIndex === index) {
          return { ...inputItem, value };
        }

        return inputItem;
      },
    );

    setInputListState(nextInputListState);
  };

  const onInputKeyDown = (index: number, e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const currentValue = inputListState[index].value.trim();
      const isCurrentValueEmpty = currentValue === '';

      if (isCurrentValueEmpty) return;

      const nextInputIndex = index + 1;
      const nextInputState = inputListState[nextInputIndex];

      // focus the next input field if there's one
      if (nextInputState) {
        const nextInput = inputsRef.current[nextInputIndex];
        nextInput.focusInput();
      }
      // otherwise add another one if there's seat capacity
      else if (seatCapacity.canAddSeats(inputCount.total)) {
        addInput();
        return;
      }
    }
  };

  const onPasteFromClipboard = (values: string[]) => {
    const inputsToAdd = values.map((value, index: number) => {
      return getInputItemDefaultState({
        autoFocus: false,
        id: inputListState[index]?.id || `${Date.now()}-${index}`,
        value,
        removable: seatCapacity.canAddSeats(index),
        disabled: !seatCapacity.canAddSeats(index),
      });
    });

    window.requestIdleCallback(() => {
      MotionGlobalConfig.skipAnimations = true;

      setAddMethod(QuickAddMethod.Paste);

      setInputListState(inputsToAdd, (state) => onAddInput(state.length, true));

      setTimeout(() => {
        MotionGlobalConfig.skipAnimations = false;
      }, 1000);
    });
  };

  const refreshInputDisabledState = (inputItemList: InputItemState[]) => {
    return inputItemList.map((inputItemState, index) => {
      return {
        ...inputItemState,
        removable: seatCapacity.canAddSeats(index),
        disabled: !seatCapacity.canAddSeats(index),
      };
    });
  };

  useListFromClipboard(onPasteFromClipboard);

  return {
    addInput,
    addMethod,
    inputCount,
    inputListState,
    onInputChange,
    onInputKeyDown,
    removeInput,
    setInputRef,
  };
};
