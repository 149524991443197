import { Project, ProjectOption } from '@float/types';

export const getProjectOption = (
  proj: Project,
  hasDescription: boolean = false,
): ProjectOption => {
  const {
    project_code: projectCode,
    project_id: id,
    project_name: projectName,
    client_name: clientName,
    client_id: clientId,
    color,
  } = proj;
  const description = hasDescription ? projectCode : null;

  return {
    label: projectName,
    description,
    value: `${id}`,
    client_name: clientName,
    selectedLabel: clientId ? `${clientName} / ${projectName}` : projectName,
    color,
  };
};
