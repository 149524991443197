import React, { useEffect, useMemo } from 'react';

import Avatar from '@float/common/components/Avatar/Avatar';
import colors from '@float/ui/deprecated/Theme/colors';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { getDepartmentFilteredActivePeople } from '../selectors/people';
import { useAppSelectorStrict } from '../store';

const getPersonAvatar = (person) => {
  return (
    <Avatar
      name={person.name}
      imageUrl={person.avatar_file}
      readOnly
      size="xs"
    />
  );
};

export default function SUVPeopleSelector(props) {
  const { suvPersonId, setSuvPersonId, isMobile } = props;

  const managablePeople = useAppSelectorStrict((state) =>
    getDepartmentFilteredActivePeople(state),
  );

  // If the selected person was deleted or archived, select the first available
  // person to prevent the empty state.
  useEffect(() => {
    if (!managablePeople.length) return;
    if (!managablePeople.some((p) => p.people_id == suvPersonId)) {
      setSuvPersonId(managablePeople[0].people_id);
    }
  }, [managablePeople, suvPersonId, setSuvPersonId]);

  const options = useMemo(() => {
    return managablePeople.map((person) => ({
      label: person.name,
      value: person.people_id,
      icon: isMobile ? undefined : getPersonAvatar(person),
    }));
  }, [managablePeople, isMobile]);

  return (
    <VirtualSelect
      value={suvPersonId}
      options={options}
      visibleItems={6}
      width={isMobile ? window.innerWidth : 250}
      inputHeight={isMobile ? 44 : 24}
      noBorder
      noPadding
      smallText
      nonNullable
      prefix={isMobile ? 'Viewing: ' : undefined}
      clearInputOnDropdownOpen={false}
      onChange={(val) => {
        setSuvPersonId(val.value);
      }}
      style={{
        border: isMobile ? undefined : `1px solid ${colors.grey}`,
        borderRadius: 4,
        minWidth: isMobile ? window.innerWidth : 250,
        maxWidth: isMobile ? window.innerWidth : 250,
      }}
      inputWrapperStyle={{
        padding: '4px 10px 4px 10px',
      }}
    />
  );
}
