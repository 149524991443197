import styled from 'styled-components';

import * as Colors from '@float/common/earhart/colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { ModalBody } from '@float/ui/deprecated/Modal';

export const StyledModalBody = styled(ModalBody)`
  ${Typography.TextL.R400}
`;

export const ModalFooter = styled.div`
  border-top: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};
  padding-top: 12px;
`;

export const PauseSubscriptionLink = styled.button`
  ${Typography.Label14.SB600};

  color: ${Colors.FIN.Lt.Buttons.Primary.Default.Bg};

  &:hover {
    color: ${Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
  }
`;

export const ErrorMessage = styled.div`
  ${Typography.TextS.SB600};
  color: ${Colors.FIN.Lt.Buttons.Danger.Default.Bg};
`;

export const StyledRow = styled.div<{ $margin: number }>`
  margin-bottom: ${(props) => props.$margin}px;
`;
