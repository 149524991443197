import { promiseMap } from '@float/common/lib/promise';
import { AppDispatch, AppStore } from '@float/common/store';
import { getPersonTypeToLabelMap } from '@float/constants/people';
import { Person } from '@float/types';

import { trackEvent } from '../../lib/analytics';
import { chunkArray } from '../../lib/chunkArray';
import request from '../../lib/request';
import { addDepartment } from '../departments';
import { trackPersonDepartmentChanges } from '../lib/trackPersonDepartmentChanges';
import { trackRateChanges } from '../lib/trackRateChanges';
import { assignRoleToJobTitle } from '../roles';
import {
  mapV3PersonToSave,
  PersonFormData,
  ProjectsUpdateRecords,
  ProjectsUpdateRecordsPayload,
} from './helpers/mapV3PersonToSave';
import { sanitizeFetchedPerson } from './helpers/sanitizeFetchedPerson';
import {
  applyRequestMarFeaturePatch,
  ensureOnlyOneDirectManager,
  fetchPersonAccount,
  PEOPLE_UPDATED,
  updatePersonProjectsAction,
} from './people';
import { updatePlanCount } from './updatePlanCount';

function createPersonApi(
  payload: Partial<ReturnType<typeof mapV3PersonToSave>>,
): Promise<Person> {
  return request.post(`people`, payload, { version: 'f3' });
}

function updatePersonApi(
  id: number,
  payload: Partial<ReturnType<typeof mapV3PersonToSave>>,
): Promise<Person> {
  return request.put(`people/${id}`, payload, {
    version: 'f3',
  });
}

const PROJECT_UPDATE_CHUNK_SIZE = 35;
const PROJECT_UPDATE_CONCURRENCY = 5;

/**
 * Stores the person projects in chunks to avoid timeouts on the server side.
 */
async function savePersonProjects(
  id: number,
  projects: ProjectsUpdateRecordsPayload,
) {
  let chunks: { projects: ProjectsUpdateRecordsPayload }[] = [];

  if (projects.add) {
    chunks = chunks.concat(
      chunkArray(projects.add, PROJECT_UPDATE_CHUNK_SIZE).map((chunk) => ({
        projects: {
          add: chunk,
        },
      })),
    );
  }

  if (projects.del) {
    chunks = chunks.concat(
      chunkArray(projects.del, PROJECT_UPDATE_CHUNK_SIZE).map((chunk) => ({
        projects: {
          del: chunk,
        },
      })),
    );
  }

  await promiseMap(chunks, (chunk) => updatePersonApi(id, chunk), {
    concurrency: PROJECT_UPDATE_CONCURRENCY,
  });
}

export function savePerson(
  data: PersonFormData,
  projectsUpdateRecords?: ProjectsUpdateRecords,
) {
  return async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const state = getState();

    // When creating a new department, the name is passed as a string through `department_id`
    let department_id: number | undefined = undefined;

    if (typeof data.department_id === 'string') {
      const deptRes = await addDepartment({ name: data.department_id })(
        dispatch,
        getState,
      );
      department_id = deptRes.payload.department_id;

      // after creating the deparment we also need to update the account
      // department_filter in case this department is part of it
      if (
        data.account &&
        data.account.department_filter &&
        data.account.department_filter.length > 0
      ) {
        data.account.department_filter = data.account.department_filter.map(
          (departmentId) => {
            // if account deparment filter id matches the original new
            // department name passed as string through `department_id` we
            // should update that reference to the new id returned by the
            // addDeparment endpoint
            if (data.department_id === departmentId)
              return department_id as number;

            return departmentId;
          },
        );
      }
    } else if (typeof data.department_id === 'number') {
      department_id = data.department_id;
    }

    const person = {
      ...data,
      department_id,
    };

    await dispatch(assignRoleToJobTitle(person));

    trackPersonDepartmentChanges(person, state);

    const updateReq = mapV3PersonToSave(person, projectsUpdateRecords);
    applyRequestMarFeaturePatch(updateReq);

    let personId = updateReq.people_id;
    let response;
    let isNew = false;

    const { projects, ...payload } = updateReq;

    if (personId) {
      response = await updatePersonApi(personId, payload);
    } else {
      isNew = true;
      response = await createPersonApi(payload);
      personId = Number(response.people_id);
    }

    if (projects) {
      await savePersonProjects(personId, projects);
    }

    const limitedByDepartmentCount =
      data.account &&
      data.department_filter &&
      data.department_filter.length > 0
        ? data.department_filter.length
        : null;

    trackEvent(`Person ${isNew ? 'added' : 'updated'}`, {
      type: getPersonTypeToLabelMap()[response.people_type_id],
      emailSet: Boolean(response.email),
      accessSet: Boolean(response.account_id),
      viewAccessCount: limitedByDepartmentCount,
    });

    const from_rate =
      state.people.people[personId]?.default_hourly_rate || null;
    const to_rate = response?.default_hourly_rate || null;

    trackRateChanges({
      from_rate,
      to_rate,
      scope: 'person',
      page: 'person modal',
      person_id: personId,
    });

    const newPerson = sanitizeFetchedPerson(response);

    // Note that we want to dispatch the account update first because if a
    // person has an account_id, we definitely want to be able to look it up.
    const nextAccount = await dispatch(fetchPersonAccount(newPerson));

    if (nextAccount) {
      ensureOnlyOneDirectManager(nextAccount, dispatch, getState);
    }

    dispatch({
      type: PEOPLE_UPDATED,
      person: newPerson,
      regionHolidayIds: person.regionHolidayIds,
      isNew,
    });
    dispatch(updatePlanCount);

    if (projectsUpdateRecords) {
      dispatch(updatePersonProjectsAction(personId, projectsUpdateRecords));
    }

    return personId;
  };
}
