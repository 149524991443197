import { PROMPTS } from '@float/constants/prompts';
import { config } from '@float/libs/config';
import { PromptId } from '@float/types/prompts';
import { getOnboardingSurveyConfig } from '@float/web/store/onboardingManager/selectors/survey';
import { OnboardingSurveyDataEntry } from '@float/web/store/onboardingManager/types';

import { getPromptsFromUrl } from './getPromptsFromUrl';

/**
 * On non-prod environments we can override the user/company prompts via a query
 * string on the URL:
 *
 * /?prompts       - user hasn't seen any prompt
 * /?prompts=29    - user has seen the 'Get to know you' prompt
 * /?prompts=29,23 - user has seen the 'Get to know you' and 'Add task' prompt
 *
 * @returns the prompts from the URL or the user prompts in case there's no
 * override
 */

export const parsePrompts = (
  userPrompts: Array<PromptId>,
  companyPrompts: Array<PromptId>,
) => {
  // Determine the prompts from URL override or merge user and company prompts
  const overriddenPrompts =
    !config.isStagingOrProduction && getPromptsFromUrl();
  const prompts =
    overriddenPrompts ||
    Array.from(new Set([...userPrompts, ...companyPrompts]));

  // Get the onboarding survey prompts from the survey configuration
  const surveyConfig = getOnboardingSurveyConfig();
  const surveyPrompts = Object.values(surveyConfig)
    .flatMap((entry: OnboardingSurveyDataEntry) => entry.prompts)
    .filter((prompt) => prompt !== PROMPTS.onboardingSurveySuccess);

  // If all survey prompts are completed, add the success prompt
  const allSurveyPromptsCompleted = surveyPrompts.every((prompt) =>
    prompts.includes(prompt),
  );

  if (allSurveyPromptsCompleted) {
    prompts.push(PROMPTS.onboardingSurveySuccess);
  }

  return prompts;
};
