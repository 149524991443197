import React from 'react';
import { isEmpty } from 'lodash';
import { DEFAULT_TAG_COLOR } from 'selectors/tags';

import { getPersonTypeToLabelMap } from '@float/constants/people';
import { ReadOnlyField } from '@float/ui/deprecated/Field/ReadOnlyField';
import { Row, Spacer } from '@float/ui/deprecated/Layout/Layout';

import { ReadOnlyManagedBy } from './PersonInfo';

let self;

export default function renderPersonInfoReadOnly(that) {
  self = that;
  const { form } = self.state;

  return (
    <>
      {form.job_title && (
        <ReadOnlyField
          label="Role"
          value={form.job_title}
          {...self.readOnlyProps}
        />
      )}
      {!form.department_id ? null : (
        <ReadOnlyField
          label="Department"
          value={self.props.departments[form.department_id].name}
          {...self.readOnlyProps}
        />
      )}
      {!isEmpty(form.tags) && (
        <ReadOnlyField
          type="tags"
          label="Tags"
          value={form.tags.map((tag) => {
            const { peopleTagByLabel } = self.props;
            const color = peopleTagByLabel[tag.label]?.color
              ? `#${peopleTagByLabel[tag.label].color}`
              : DEFAULT_TAG_COLOR;

            return {
              ...tag,
              color,
            };
          })}
          {...self.readOnlyProps}
        />
      )}

      <Row>
        <ReadOnlyField
          label="Type"
          value={getPersonTypeToLabelMap()[form.people_type_id]}
          {...self.readOnlyProps}
        />
        <Spacer size={32} />
        <ReadOnlyField
          type="currency"
          label="Hourly rate"
          currentUser={self.props.currentUser}
          value={form.default_hourly_rate || form.default_hourly_rate_role}
          {...self.readOnlyProps}
        />
      </Row>
      {!isEmpty(form.managers) && (
        <ReadOnlyManagedBy personId={form.people_id} />
      )}
    </>
  );
}
