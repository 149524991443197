import React from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { TCore } from '@float/common/earhart/colors';
import { Rights } from '@float/common/lib/acl';
import { useAppSelector } from '@float/common/store';
import * as Label16 from '@float/ui/deprecated/Earhart/Typography/Label16';
import { bindVal } from '@float/ui/deprecated/helpers/forms';
import { Input } from '@float/ui/deprecated/Input';
import { FieldLabel } from '@float/ui/deprecated/Label';
import { Col, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { MultiSelect } from '@float/ui/deprecated/MultiSelect';
import { userCanSeeBudgets } from '@float/web/selectors';
import {
  getAccurateNameByAccountId,
  getDirectManagersByPeopleId,
} from '@float/web/selectors/people';
import { DEFAULT_TAG_COLOR } from '@float/web/selectors/tags';

import { PersonDepartment } from './components/PersonDepartment';
import { PersonRole } from './components/PersonRole/PersonRole';
import { PersonType } from './components/PersonType/PersonType';
import { getRateInputPlaceholderValue } from './PersonInfo.helpers';

let self;

function onPersonTypeChange({ value }) {
  self.setFormState({ people_type_id: value });
}

const MangerNames = styled.span`
  ${Label16.R400};
  color: ${TCore.Emp.High12};
  padding-left: 2px;
`;

export const ReadOnlyManagedBy = ({ personId }) => {
  const accurateNameByAccountId = useAppSelector(getAccurateNameByAccountId);
  const directManagersByPeopleId = useAppSelector(getDirectManagersByPeopleId);
  const managers = directManagersByPeopleId[personId] || [];

  if (isEmpty(managers)) {
    return null;
  }

  return (
    <>
      <FieldLabel>Managed by</FieldLabel>
      <Col alignItems="flex-start">
        <Spacer size={6} />
        <MangerNames>
          {managers
            .map((accountId) => accurateNameByAccountId[accountId])
            .join(', ')}
        </MangerNames>
      </Col>
    </>
  );
};

export default function renderPersonInfo(that) {
  self = that;

  const {
    currencyConfig,
    currentUser,
    departmentOptions,
    peopleTagByLabel,
    personTypeOptions,
    roles,
    rolesOptions,
    tagOptions,
  } = self.props;

  const { form } = self.state;
  const { initialPerson } = self;

  const peopleTagsWithColor = form.tags.map((tag) => {
    const color = peopleTagByLabel[tag.label]?.color
      ? `#${peopleTagByLabel[tag.label].color}`
      : DEFAULT_TAG_COLOR;

    return {
      ...tag,
      color,
    };
  });

  const { value: roleId, onChange: onRoleChange } = bindVal(
    self,
    'role_id',
    null,
  );

  const hasCustomRate = !!form.default_hourly_rate;
  const canSeeBudgets = userCanSeeBudgets(currentUser);
  const showRates = canSeeBudgets;

  const {
    value: currencyValue,
    onChange: onCurrencyChange,
    errors: currencyErrors,
  } = bindVal(
    self,
    'default_hourly_rate',
    undefined,
    Input.validators.number(6),
  );

  const currencyValuePlaceholder = getRateInputPlaceholderValue(
    form.role_id,
    roles,
  );

  // ACT-62: Add placeholder text to People modal -
  // https://linear.app/float-com/issue/ACT-62
  //
  // Determines if custom placeholder text should be displayed in the People
  // modal. Custom placeholder text will appear if no options are available for
  // departments, tags, or roles, providing guidance for new users on how to
  // complete fields in an empty modal.
  //
  // This check is needed to make sure it runs only once per modal open (e.g.,
  // placeholder text does not change when submitting an update)
  if (self.shouldRenderInitialPlaceholder === undefined) {
    self.shouldRenderInitialPlaceholder =
      Object.values(departmentOptions).length === 0 &&
      Object.values(tagOptions).length === 0 &&
      Object.values(rolesOptions).length === 0;
  }

  return (
    <div>
      <PersonRole
        roleId={roleId}
        rolesOptions={rolesOptions}
        currencyConfig={currencyConfig}
        hasCustomRate={hasCustomRate}
        showRates={showRates}
        onRoleChange={onRoleChange}
        placeholder={
          self.shouldRenderInitialPlaceholder
            ? t`e.g., Creative Director, Engineer, Account Manager`
            : t`No role`
        }
      />
      <div style={{ marginBottom: 22 }}>
        <PersonDepartment
          account={self.state.form.account}
          departmentId={self.state.form.department_id}
          departmentOptions={departmentOptions}
          departments={self.props.departments}
          formErrors={self.state.formErrors?.department_id}
          isLimitedByDepartment={self.isUserLimitedByDepartment()}
          onDepartmentChange={self.setFormState}
          placeholder={
            self.shouldRenderInitialPlaceholder
              ? t`e.g., Creative & Design, Engineering, Client Services`
              : t`No department`
          }
        />
      </div>
      <MultiSelect
        creatable={Rights.canCreateTags(currentUser, { tag: { type: 2 } })}
        style={{ marginBottom: 22 }}
        values={peopleTagsWithColor}
        options={tagOptions}
        visibleItems={6}
        errors={self.state.formErrors.tags}
        onAdd={(tag) => {
          self.setFormErrors({
            tags: [],
          });
          self.setFormState({
            tags: [...(form.tags || []), tag],
          });
        }}
        placeholder={
          self.shouldRenderInitialPlaceholder
            ? t`e.g., Illustration, Executive, On-Call`
            : t`No tags`
        }
        onRemove={(tag) => {
          self.setFormErrors({
            tags: [],
          });
          self.setFormState({
            tags: form.tags.filter((t) => t.value !== tag.value),
          });
        }}
      />

      <PersonType
        currencyErrors={currencyErrors}
        currencyValue={currencyValue}
        currencyValuePlaceholder={currencyValuePlaceholder}
        currentUser={currentUser}
        initialPerson={initialPerson}
        onCurrencyChange={onCurrencyChange}
        onPersonTypeChange={onPersonTypeChange}
        person={form}
        personTypeOptions={personTypeOptions}
        personTypeErrors={self.state.formErrors.personType}
      />

      <ReadOnlyManagedBy personId={form.people_id} />
    </div>
  );
}
