import React from 'react';
import { Trans } from '@lingui/macro';
import cs from 'classnames';

import * as styles from '../../EditTaskModal.css';

export type InputAllocationPercentageHeaderProps = {
  allocationTypeSelect: React.ReactNode | undefined;
};

export const InputAllocationPercentageHeader = (
  props: InputAllocationPercentageHeaderProps,
) => {
  const { allocationTypeSelect } = props;

  const hasAllocationTypeSelect = typeof allocationTypeSelect !== 'undefined';

  return (
    <div
      className={cs(
        styles.timeSectionColumnHeader,
        hasAllocationTypeSelect &&
          styles.timeSectionColumnHeaderWithLeadingButton,
      )}
    >
      {hasAllocationTypeSelect && (
        <div className={styles.timeSectionColumnHeaderCell}>
          {allocationTypeSelect}
        </div>
      )}

      {!hasAllocationTypeSelect && (
        <div className={styles.timeSectionColumnHeaderCell}>
          <label className={styles.fieldLabel}>
            <Trans>Percentage</Trans>
          </label>
        </div>
      )}

      <div className={styles.timeSectionColumnHeaderCell}>
        <label className={styles.fieldLabel}>
          <Trans>Total hours</Trans>
        </label>
      </div>
    </div>
  );
};
