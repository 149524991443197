import React, { memo, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { kebabCase } from 'lodash';

import {
  StyledBg,
  StyledContent,
  StyledLabel,
  StyledRadioButton,
  StyledSubLabel,
} from './styles';

const RadioButton = memo((props) => {
  const {
    className = '',
    name,
    label = null,
    subLabel = null,
    value = null,
    selected = false,
    onChange,
    ...rest
  } = props;

  const id = useState(`${name}_${kebabCase(label)}`)[0];

  const inputRadio = useRef();

  const onClickHandler = useCallback(
    (e) => {
      e.currentTarget.blur();

      if (onChange) onChange(value);
    },
    [onChange, value],
  );

  const onKeyPressHandler = useCallback(
    (e) => {
      // on click enter or space
      if (e.charCode == 13 || e.charCode == 32) {
        if (onChange) onChange(value);
      }
    },
    [onChange, value],
  );

  return (
    <StyledRadioButton
      className={classNames(className)}
      $selected={selected}
      onClick={onClickHandler}
      onKeyPress={onKeyPressHandler}
      data-testid={`radio-${id}`}
      {...rest}
    >
      <StyledBg />

      <StyledContent>
        <StyledLabel htmlFor={id}>
          {label}
          {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
        </StyledLabel>

        <input
          ref={inputRadio}
          id={id}
          type="radio"
          checked={selected}
          name={name}
          value={value}
          tabIndex={-1}
          readOnly
        />
      </StyledContent>
    </StyledRadioButton>
  );
});

export default RadioButton;
