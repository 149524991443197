import React from 'react';

import { Button } from '@float/ui/deprecated/Earhart/Buttons';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { StyledForm } from './styles';

const Form = (props) => {
  const { accounts, onSubmit, fieldLink, disabled } = props;

  // Event callbacks

  const onChange = (e) => {
    fieldLink.onChange({ target: e });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (onSubmit) onSubmit(e);
  };

  return (
    <StyledForm noValidate onSubmit={onFormSubmit}>
      <VirtualSelect
        size="large"
        label="Account Owner"
        visibleItems={6}
        nonNullable
        onChange={onChange}
        options={accounts}
        value={fieldLink.value?.value}
      />

      <Button size="large" disabled={disabled}>
        Transfer
      </Button>
    </StyledForm>
  );
};

export default Form;
