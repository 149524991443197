import React from 'react';
import { useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { Radix } from '@float/common/earhart/colors';
import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import * as Accordion from '@float/ui/components/Accordion';
import {
  ModalConfirm,
  ModalConfirmAppearance,
} from '@float/ui/components/Modals/ModalConfirm';
import { Tag } from '@float/ui/components/Tag';
import { useModalLocal } from '@float/ui/hooks/useModalLocal';

import { EntityStatusTag } from '../../components/EntityStatusTag';
import { FormFieldsSection } from '../../components/FormFieldsSection';
import { SectionErrorTag } from '../../components/SectionErrorTag';
import { BillableField } from '../../fields/BillableField';
import { ClientField } from '../../fields/ClientField';
import { ManagersEditRightsField } from '../../fields/ManagersEditRightsField';
import { NotesField } from '../../fields/NotesField';
import { ProjectCodeField } from '../../fields/ProjectCodeField';
import { ProjectDatesField } from '../../fields/ProjectDatesField';
import { ProjectDurationField } from '../../fields/ProjectDurationField';
import { ProjectOwnerField } from '../../fields/ProjectOwnerField';
import { ProjectTagsField } from '../../fields/ProjectTagsField';
import { StatusField } from '../../fields/StatusField';
import { AccordionEntry } from '../../hooks/useAccordionState';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { useNonBillableChangeConfirmModal } from '../../hooks/useNonBillableChangeConfirmModal';
import { useStatusChangeHandler } from '../../hooks/useStatusChangeHandler';
import { ProjectFormData } from '../../types';

import { datePickerActiveColor } from '../../components/DateRangeField/styles.css';
import * as styles from '../../styles.css';

function ProjectInfoHeaderOwner() {
  const ownerId = useWatch<ProjectFormData>({
    name: 'project.project_manager',
  });

  const isTemplate = useIsTemplate();

  if (isTemplate) {
    return null;
  }

  return !ownerId ? null : (
    <PersonAvatar accountId={ownerId} readOnly size="xs" />
  );
}

function ProjectInfoHeader() {
  const isNonBillable = useWatch<ProjectFormData>({
    name: 'project.non_billable',
  });

  return (
    <div className={styles.projectAccordionSummary}>
      <div className={styles.showWhenCollapsed}>
        <ProjectInfoHeaderOwner />
        <EntityStatusTag entity="project" />
        {isNonBillable ? (
          <Tag color="primary">
            <Trans>Non-billable</Trans>
          </Tag>
        ) : (
          <Tag color="primary">
            <Trans>Billable</Trans>
          </Tag>
        )}
      </div>
      <SectionErrorTag fields={['project.end_date', 'project.project_code']} />
    </div>
  );
}

export const ProjectInfoSection = () => {
  const isTemplate = useIsTemplate();
  const { isProjectCodesEnabled } = useProjectCodesPreference();

  const showProjectCodeField = isProjectCodesEnabled && !isTemplate;

  const modal = useModalLocal();
  const onProjectStatusChange = useStatusChangeHandler();
  const {
    onProjectBillableStateChange: onProjectBillableChange,
    updatePhasesBillable,
    resetBillableState: resetBillableField,
  } = useNonBillableChangeConfirmModal(modal);

  return (
    <Accordion.Item value={AccordionEntry.info}>
      <Accordion.Header
        data-callout-id="project-setup-collapse"
        className={styles.projectAccordionHeader}
        info={<ProjectInfoHeader />}
      >
        <Trans>Info</Trans>
      </Accordion.Header>
      <Accordion.Content
        style={assignInlineVars({
          [datePickerActiveColor]: Radix.Primary.Flay['6'],
        })}
      >
        <FormFieldsSection>
          {showProjectCodeField && <ProjectCodeField />}
          {!isTemplate && <ProjectOwnerField />}
          <StatusField name="project.status" onChange={onProjectStatusChange} />
          <BillableField onChange={onProjectBillableChange} />
          <ClientField />
          <ProjectTagsField />
          {!isTemplate && <ProjectDatesField />}
          {isTemplate && <ProjectDurationField />}
          <NotesField />
          <ManagersEditRightsField />
        </FormFieldsSection>
      </Accordion.Content>
      <ModalConfirm
        modalProps={modal.props}
        appearance={ModalConfirmAppearance.Danger}
        primaryButtonLabel={t`Update project`}
        title={t`Update project to non-billable?`}
        description={t`Updating your project to non-billable will update all of your phases and tasks to non-billable as well.`}
        onClickConfirm={updatePhasesBillable}
        onOpenChange={resetBillableField}
      />
    </Accordion.Item>
  );
};
