import React from 'react';
import { isArray } from 'lodash';

import { capitalize } from '@float/libs/utils/string/capitalize';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Modal } from '@float/ui/deprecated/Modal';

import { getTypeString } from '../helpers';
import { STATUS } from './ImportModal.constants';
import * as styled from './styles';
import UploadButton from './UploadButton';

function serverError(errors) {
  if (!errors || !errors.length) {
    return false;
  }

  // In case of a non-row specific error (e.g. plan cap exceeded),
  //  server sets row to -1 in response.
  return errors.some((err) => +err.row < 0);
}

function getErrorTitle({ count, itemType, hasServerError, duplicate }) {
  if (hasServerError) {
    return `${capitalize(getTypeString(itemType))} can't be imported`;
  }

  const typeString = getTypeString(itemType, count);
  if (duplicate) {
    return `Update ${count} ${typeString} already in Float?`;
  }

  return `${count} ${typeString} can’t be imported`;
}

const ImportErrors = ({
  errors,
  itemType,
  status,
  allowIgnore,
  onIgnore,
  onFileChange,
  onUpdateDuplicates,
}) => {
  const duplicate = !!onUpdateDuplicates;
  const count = errors.length;
  const typeString = getTypeString(itemType, count);
  const hasServerError = serverError(errors);

  return (
    <>
      <Modal.Body>
        <styled.Title>
          {getErrorTitle({ count, itemType, hasServerError, duplicate })}
        </styled.Title>
        {!duplicate && !hasServerError && (
          <styled.Description>
            The following rows can’t be imported until the errors are fixed…
          </styled.Description>
        )}
        <styled.Table>
          <styled.Tr>
            <styled.Th>Row</styled.Th>
            <styled.Th>
              {duplicate ? capitalize(typeString) : 'Errors'}
            </styled.Th>
          </styled.Tr>
          {errors.map((err) => {
            const messages = isArray(err.message) ? err.message : [err.message];
            const row = +err.row + 1;
            return (
              <styled.Tr key={err.row}>
                <styled.Td>{row ? row : '-'}</styled.Td>
                <styled.Td duplicate={duplicate}>
                  {messages.map((m, i) => (
                    <div key={`msg-${err.row}-${i}`}>{m}</div>
                  ))}
                </styled.Td>
              </styled.Tr>
            );
          })}
        </styled.Table>
        {duplicate && (
          <styled.SmallText>
            This will update the attributes based on your CSV. It won’t affect
            their existing Tasks or Projects.
          </styled.SmallText>
        )}
      </Modal.Body>
      <Modal.Actions>
        {duplicate ? (
          <Button
            loader={status === STATUS.SAVING}
            onClick={onUpdateDuplicates}
          >
            Update {errors.length} {typeString}
          </Button>
        ) : (
          <UploadButton
            loader={status === STATUS.SAVING}
            label="Upload new file"
            onChange={onFileChange}
          />
        )}
        {allowIgnore && !hasServerError && (
          <Button
            loader={status === STATUS.SAVING_IGNORING_ERRORS}
            onClick={onIgnore}
          >
            {duplicate
              ? `Don't import ${count === 1 ? 'this' : 'these'} ${typeString}`
              : `Continue and don't import these rows`}
          </Button>
        )}
      </Modal.Actions>
    </>
  );
};

export default ImportErrors;
