import { getIsEntityEditable } from '@float/common/lib/rights';
import { getUser } from '@float/common/selectors/currentUser';
import { getPhasesMapRaw } from '@float/common/selectors/phases';
import { getProjectsMap } from '@float/common/selectors/projects';
import { UseCellsAction } from '@float/common/serena/Data/useCells/types';
import { AppStore } from '@float/common/store';
import { TaskEditMode } from '@float/constants/tasks';
import { CurrentUser } from '@float/types/account';
import { CellItem, CellItemWithEntity, CellsMap } from '@float/types/cells';
import { Phase } from '@float/types/phase';
import { Project } from '@float/types/project';

export function isEligibleLinkSource(
  data: {
    user: CurrentUser;
    phases: Record<number, Phase>;
    projects: Record<number, Project>;
  },
  item: CellItem,
) {
  if (item.type !== 'task') return false;

  const target = item.entity;

  if (target.integration_status > 0) return false;
  if (target.repeat_state > 0) return false;

  return getIsEntityEditable(item.entity, {
    user: data.user,
    phases: data.phases,
    projects: data.projects,
  });
}

export function isEligibleLinkTarget(
  linkInfo: { base: CellItemWithEntity<'task'> },
  item: CellItem,
) {
  if (item.type !== 'task') return [false, 'NOT_TASK'];

  const source = linkInfo.base.entity;
  const target = item.entity;

  if (target.task_id == source.task_id) return [false, 'IS_SELF'];
  if (target.parent_task_id) return [false, 'HAS_PARENT'];
  if (target.integration_status > 0) return [false, 'IS_SYNCED'];
  if (target.repeat_state > 0) return [false, 'IS_REPEAT'];
  if (target.start_date < source.start_date) return [false, 'IS_IN_PAST'];

  // We've already checked the project permissions on the link source,
  // so we just have to ensure they're using the same project.
  if (target.project_id != source.project_id) return [false, 'DIFF_PROJECT'];
  if (source.phase_id !== target.phase_id) return [false, 'DIFF_PHASE'];

  return [true];
}

export function isLinkRemovable(
  data: {
    user: CurrentUser;
    phases: Record<number, Phase>;
    projects: Record<number, Project>;
  },
  item: CellItem,
) {
  if (item.type !== 'task') return false;

  if (!data.projects[item.entity.project_id]?.active) {
    return false;
  }

  return getIsEntityEditable(item.entity, {
    user: data.user,
    phases: data.phases,
    projects: data.projects,
  });
}

export function handleLinkCancel(props: {
  setActionMode: (mode: TaskEditMode) => void;
}) {
  const { setActionMode } = props;
  setActionMode(TaskEditMode.ADD_EDIT);
}

export function handleLinkClick(
  props: {
    cells: CellsMap;
    dispatch: (value: UseCellsAction) => void;
    linkInfo: { base: CellItemWithEntity<'task'> } | null;
    setActionMode: (mode: TaskEditMode) => void;
    setLinkInfo: (
      linkInfo: { base: CellItemWithEntity<'task'> } | null,
    ) => void;
    mousePositionRef: { current: { eligibleLinkTarget: CellItem | null } };
    store: AppStore;
  },
  item: CellItem<'task'>,
) {
  const { cells, dispatch, linkInfo, setLinkInfo, mousePositionRef, store } =
    props;

  if (!linkInfo) {
    if (
      isEligibleLinkSource(
        {
          user: getUser(store.getState()),
          phases: getPhasesMapRaw(store.getState()),
          projects: getProjectsMap(store.getState()),
        },
        item,
      )
    ) {
      setLinkInfo({ base: cells._helpers.getLinkSourceItem(item) });
      return;
    } else {
      return handleLinkCancel(props);
    }
  }

  if (!isEligibleLinkTarget(linkInfo, item)[0]) {
    return handleLinkCancel(props);
  }

  dispatch({
    type: 'LINK_CONFIRM',
    parent: linkInfo.base,
    child: item,
  });

  setLinkInfo(null);
  mousePositionRef.current.eligibleLinkTarget = null;
}

export function handleConnectorClick(
  props: {
    dispatch: (value: UseCellsAction) => void;
  },
  item: CellItem<'task'>,
) {
  const { dispatch } = props;

  dispatch({
    type: 'LINK_DELETE',
    item,
  });
}
