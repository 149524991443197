import React, { useMemo } from 'react';
import { Link, Route } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { compose } from 'redux';
import { DEFAULT_TAG_COLOR, isPeopleTag, TAG_TYPE } from 'selectors/tags';

import Loader from '@float/common/components/elements/Loader';
import { Card, CardRow } from '@float/ui/deprecated/Earhart/Cards';
import { Col, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { withSnackbar } from '@float/ui/deprecated/Snackbar';

import Body from '../Body';
import { TabsLink } from '../Common/TabsLink/TabsLink';
import { TabsNav } from '../Common/TabsNav/TabsNav';
import RestrictedMessage from '../RestrictedMessage';
import AddEntry from '../Table/AddEntry';
import { ColorCell } from '../Table/cell/ColorCell';
import { WindowScrollerTable } from './WindowScrollerTable';

const getRowProps = ({ tag, entities, accounts }) => {
  const numEntities = entities.length;
  const tagNameEncoded = encodeURIComponent(tag.name);
  let link = numEntities;

  // People
  if (tag.type === TAG_TYPE.PEOPLE && numEntities > 0) {
    link = (
      <Link to={`/people?personTag=${tagNameEncoded}`}>{numEntities}</Link>
    );
  }

  // Projects
  if (tag.type === TAG_TYPE.PROJECT && numEntities > 0) {
    link = (
      <Link to={`/projects?projectTag=${tagNameEncoded}`}>{numEntities}</Link>
    );
  }

  return {
    key: tag.name,
    editConfig: ['text', null, null, 'color'],
    display: [
      null,
      link,
      null,
      <ColorCell key={tag.name} color={`#${tag.color}`} />,
    ],
    data: [
      tag.name,
      entities.length,
      accounts[tag.created_by]?.name,
      tag.color,
    ],
  };
};

const Tags = (props) => {
  const { userCanSee, pageBodyRef } = props;

  const peopleTagTableRows = useMemo(() => {
    return props.peopleTags.map(({ tag, entities }) =>
      getRowProps({ tag, entities, accounts: props.accounts }),
    );
  }, [props.peopleTags, props.accounts]);

  const projectTagTableRows = useMemo(() => {
    return props.projectTags.map(({ tag, entities }) =>
      getRowProps({ tag, entities, accounts: props.accounts }),
    );
  }, [props.projectTags, props.accounts]);

  if (!userCanSee.adminForTags) {
    return <RestrictedMessage />;
  }

  const getTagFromRow = (row, tags) => {
    const rowData = Object.values(tags).find(({ tag }) => row.key === tag.name);
    return rowData?.tag;
  };

  const createDeleteConfirmHandler = (tag) => {
    return () => {
      return props
        .deleteTag(tag)
        .then((res) => {
          props.showSnackbar(`${tag.name} deleted.`);
        })
        .catch((err) => {
          props.showSnackbar(`Error: ${tag.name} couldn't be deleted.`);
        });
    };
  };

  const handleEditTag = async (nextName, nextColor, row, tags) => {
    const tag = getTagFromRow(row, tags);
    if (!tag) {
      return Promise.reject(`Error: ${nextName} couldn't be updated.`);
    }

    try {
      const updatedTag = await props.updateTag({
        type: tag.type,
        tags_id: tag.tags_id,
        name: nextName,
        color: nextColor,
      });

      return updatedTag;
    } catch (err) {
      props.showSnackbar(`Error: ${nextName} couldn't be updated.`);
      return Promise.reject(`Error: ${nextName} couldn't be updated.`);
    }
  };

  const createAddTagHandler = (tagType) => (tagName) => {
    const accountId = props.currentUser.account_id;

    const tag = {
      name: tagName,
      type: tagType,
      color: DEFAULT_TAG_COLOR.slice(1), // remove '#'
      created_by: accountId,
      modified_by: accountId,
    };

    const tags = isPeopleTag(tag) ? props.peopleTags : props.projectTags;
    const isAlreadyDefined = tags.find(({ tag: t }) => t.name === tagName);
    if (isAlreadyDefined) {
      return Promise.reject(`Error: "${tagName}" already exists.`);
    }

    return props.createTag(tag);
  };

  return (
    <Body
      header={t`Tags`}
      subheader={t`Manage the Tags you apply to Projects and People.`}
    >
      {props.isLoading ? (
        <Card>
          <CardRow>
            <Loader style={{ position: 'relative', left: 0, marginLeft: 0 }} />
          </CardRow>
        </Card>
      ) : (
        <Card>
          <CardRow>
            <TabsNav>
              <li>
                <TabsLink to="/admin/tags">
                  People <span>{props.peopleTags.length}</span>
                </TabsLink>
              </li>

              <li>
                <TabsLink to="/admin/tags/projects">
                  Projects <span>{props.projectTags.length}</span>
                </TabsLink>
              </li>
            </TabsNav>
          </CardRow>

          <CardRow>
            <Route
              exact
              path="/admin/tags"
              render={(routerProps) => (
                <Col alignItems="flex-start" justifyContent="flex-start">
                  <AddEntry
                    placeholder={t`Add a People Tag`}
                    secondaryText={t`64 character Max`}
                    maxLength={64}
                    isLoading={props.isLoadingPeopleTag}
                    onSubmit={createAddTagHandler(2)}
                  />
                  <Spacer size={24} />
                  <WindowScrollerTable
                    windowScrollRef={pageBodyRef}
                    emptyText={t`There are no People Tags.`}
                    tableHeaders={[
                      { label: t`Tags`, key: 'name' },
                      { label: t`People using`, key: 'count' },
                      { label: t`Created by`, key: 'create_by' },
                      { label: t`Color`, key: 'color' },
                    ]}
                    columnsWidth={['60%', '10%', '10%', '10%']}
                    rows={peopleTagTableRows}
                    rowActions={[
                      {
                        id: 'edit',
                        label: t`Edit`,
                        onSubmit: (row, nextValue) => {
                          const nextName = nextValue[0];
                          const nextColor = nextValue[3];
                          return handleEditTag(
                            nextName,
                            nextColor,
                            row,
                            props.peopleTags,
                          );
                        },
                        config: {
                          maxLength: 64,
                        },
                      },
                      {
                        id: 'delete',
                        label: t`Delete`,
                        onClick: (row) => {
                          const rowData = Object.values(props.peopleTags).find(
                            ({ tag }) => row.key === tag.name,
                          );

                          props.confirm({
                            title: t`Delete Person Tag`,
                            message: (
                              <div>
                                <Trans>
                                  Delete Tag and remove from assigned People?
                                </Trans>
                              </div>
                            ),
                            onConfirm: createDeleteConfirmHandler(rowData.tag),
                          });
                        },
                      },
                    ]}
                  />
                </Col>
              )}
            />
            <Route
              exact
              path="/admin/tags/projects"
              render={(routerProps) => (
                <Col alignItems="flex-start" justifyContent="flex-start">
                  <AddEntry
                    placeholder={t`Add a Project Tag`}
                    isLoading={props.isLoadingProjectTag}
                    secondaryText={t`64 character Max`}
                    maxLength={64}
                    onSubmit={createAddTagHandler(1)}
                  />
                  <Spacer size={24} />
                  <WindowScrollerTable
                    windowScrollRef={pageBodyRef}
                    emptyText={t`There are no Project Tags.`}
                    tableHeaders={[
                      { label: t`Tags`, key: 'name' },
                      { label: t`Projects using`, key: 'count' },
                      { label: t`Created by`, key: 'create_by' },
                      { label: t`Color`, key: 'color' },
                    ]}
                    columnsWidth={['60%', '10%', '10%', '10%']}
                    rows={projectTagTableRows}
                    rowActions={[
                      {
                        id: 'edit',
                        label: t`Edit`,
                        onSubmit: (row, nextValue) => {
                          const nextName = nextValue[0];
                          const nextColor = nextValue[3];
                          return handleEditTag(
                            nextName,
                            nextColor,
                            row,
                            props.projectTags,
                          );
                        },
                        config: {
                          maxLength: 64,
                        },
                      },
                      {
                        id: 'delete',
                        label: t`Delete`,
                        onClick: (row) => {
                          const rowData = Object.values(props.projectTags).find(
                            ({ tag }) => row.key === tag.name,
                          );
                          props.confirm({
                            title: t`Delete Project Tag`,
                            message: (
                              <div>
                                <Trans>
                                  Delete Tag and remove from assigned Projects?
                                </Trans>
                              </div>
                            ),
                            onConfirm: createDeleteConfirmHandler(rowData.tag),
                          });
                        },
                      },
                    ]}
                  />
                </Col>
              )}
            />
          </CardRow>
        </Card>
      )}
    </Body>
  );
};

Tags.propTypes = {};

export default compose(withConfirm, withSnackbar)(Tags);
