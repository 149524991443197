import React, { useState } from 'react';

import { Button } from '@float/ui/deprecated/Earhart/Buttons';
import { Input } from '@float/ui/deprecated/Input';

import { StyledForm } from './styles';

const Form = (props) => {
  const { onSubmit, disabled, fieldLinkName, fieldLinkHostname } = props;

  const [isEditing, setIsEditing] = useState(false);

  // Event callbacks

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (onSubmit) onSubmit(e);
  };

  const onChange = (e) => {
    const val = {
      target: {
        value: e.target.value.replace(/\s/g, ''),
      },
    };

    fieldLinkHostname.onChange(val);
  };

  const onClickCallback = (e) => {
    if (!isEditing) {
      e.preventDefault();

      setIsEditing(true);
    }
  };

  return (
    <StyledForm noValidate onSubmit={onFormSubmit}>
      <Input
        size="large"
        label="Team name"
        value={fieldLinkName.value}
        disabled={!isEditing}
        onChange={fieldLinkName.onChange}
      />

      <Input
        size="large"
        label="Team URL"
        value={fieldLinkHostname.value}
        onChange={onChange}
        autoSize
        placeholder="your-team-url"
        suffix=".float.com"
        suffixPositionAuto
        disabled={!isEditing}
      />

      <Button
        appearance={isEditing ? 'primary' : 'secondary'}
        size="large"
        alignment="center"
        disabled={isEditing ? disabled : false}
        onClick={onClickCallback}
        style={{ minWidth: 74 }}
      >
        {isEditing ? 'Save' : 'Edit'}
      </Button>
    </StyledForm>
  );
};

export default Form;
