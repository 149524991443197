import React, { useMemo } from 'react';
import { t } from '@lingui/macro';

import { SavedView } from '@float/types';
import { MeatballMenu } from '@float/ui/components/MeatballMenu';
import { IconLayers } from '@float/ui/icons/essentials/IconLayers';
import { IconPencil } from '@float/ui/icons/essentials/IconPencil';
import { IconPin } from '@float/ui/icons/essentials/IconPin';
import { IconShare } from '@float/ui/icons/essentials/IconShare';
import { IconTrash } from '@float/ui/icons/essentials/IconTrash';
import { ListItemData } from '@float/ui/primitives/List/types';

import { AccessControlProps } from './types';

export type ViewEditActionsProps = {
  acl: AccessControlProps;
  className?: string;
  iconSize?: number;
  onDelete: (view: SavedView) => void;
  onEdit: (view: SavedView) => void;
  onPersonalChange: (view: SavedView, personal: boolean) => void;
  onPinChange: (view: SavedView, pinned: boolean) => void;
  sideOffset?: number;
  view: SavedView;
};

export const ViewEditActions = (props: ViewEditActionsProps) => {
  const {
    acl,
    className,
    iconSize,
    onDelete,
    onEdit,
    onPersonalChange,
    onPinChange,
    sideOffset,
    view,
  } = props;

  const canDelete = acl.canDelete(view);
  const canEdit = acl.canEdit(view);
  const canPinView = acl.canEdit(view, { pinned: !view.pinned });
  const canShare = acl.canShare(view);
  const canConvertToPersonal = canEdit && view.personal === false;

  const items = useMemo(() => {
    const o: Array<ListItemData> = [];

    if (canEdit) {
      o.push({
        icon: IconPencil,
        onClick: () => onEdit(view),
        value: t`Edit View`,
      });
    }

    if (canPinView) {
      o.push({
        icon: IconPin,
        onClick: () => onPinChange(view, !view.pinned),
        value: view.pinned ? t`Unpin` : t`Pin to top`,
      });
    }

    if (canShare) {
      o.push({
        icon: IconShare,
        onClick: () => onPersonalChange(view, false),
        value: t`Convert to shared View`,
      });
    }

    if (canConvertToPersonal) {
      o.push({
        icon: IconLayers,
        onClick: () => onPersonalChange(view, true),
        value: t`Convert to personal View`,
      });
    }

    if (canDelete) {
      o.push({ separator: true });
      o.push({
        icon: IconTrash,
        onClick: () => onDelete(view),
        value: t`Delete View`,
      });
    }

    return o;
  }, [
    canConvertToPersonal,
    canDelete,
    canEdit,
    canPinView,
    canShare,
    onDelete,
    onEdit,
    onPersonalChange,
    onPinChange,
    view,
  ]);

  return (
    <MeatballMenu
      sideOffset={sideOffset}
      className={className}
      trigger={{
        label: t`Open the view actions menu`,
        iconSize,
      }}
      items={items}
    />
  );
};
