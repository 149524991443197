import React from 'react';

import { Input } from '@float/ui/deprecated/Input';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { getStartWeekOnSelectOptions } from './Preferences.helpers';

function ReadOnlyStartWeekSubText() {
  return (
    <span>
      The day cannot be changed when variable work days are in use. Please see{' '}
      <a
        href="https://support.float.com/en/articles/28942-change-your-work-days-and-hours"
        target="_blank"
        rel="noopener noreferrer"
      >
        this help article
      </a>{' '}
      for more info.
    </span>
  );
}

const StartWeekOnField = ({ idPrefix, fieldLink, people, workDaysHours }) => {
  // Note: Rotating work day hours history is complicated when switching
  // between Monday and Sunday start work weeks. For now, we're disallowing
  // the change if any history exists.

  const readonly =
    people.some((p) => p.work_days_hours_history?.length) ||
    workDaysHours.history?.length;

  return readonly ? (
    <Input
      label="Start week on"
      size="large"
      value={fieldLink.value?.label}
      readOnly
      noPointerEvents
      secondaryText={<ReadOnlyStartWeekSubText />}
    />
  ) : (
    <VirtualSelect
      label="Start week on"
      visibleItems={6}
      nonNullable
      onChange={(e) => fieldLink.onChange({ target: e })}
      options={getStartWeekOnSelectOptions()}
      value={fieldLink.value?.value}
      size="large"
    />
  );
};

export default StartWeekOnField;
