import React from 'react';

import { Card } from '@float/ui/deprecated/Earhart/Cards';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';

import linkState, {
  isValid,
  linkAt,
  validateLinks,
} from '../../form/linkState';
import { StyledRow } from '../styles';
import Form from './Form';

class TeamNameURL extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultFieldState();
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickClosePopup = this.onClickClosePopup.bind(this);
    linkState(this, 'name').check((x) => x != '', 'Name is required.');
    linkState(this, 'hostname').check((x) => this.validateUrl(x));
  }
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.name !== this.props.name) {
      linkAt(this, 'name').set(nextProps.name, false);
    }
    if (nextProps.hostname !== this.props.hostname) {
      linkAt(this, 'hostname').set(nextProps.hostname, false);
    }
  }

  onSubmit = () => {
    const { hostname } = this.state;

    if (!validateLinks(this)) {
      return;
    }
    if (this.props.hostname != hostname) {
      this.props.confirm({
        title: 'Change URL',
        message: `
          Are you sure? On confirmation you will be redirected to
          your new URL. Any links to the old URL may no longer work.`,
        hideOnConfirm: true,
        onConfirm: this.doSubmit,
      });

      return;
    }
    this.doSubmit();
  };

  onClickClosePopup() {
    this.setState({ popupFor: false });
  }

  doSubmit = () => {
    const { onSubmit } = this.props;
    const { name, hostname } = this.state;
    this.setState({ isLoading: true });
    onSubmit({
      name,
      hostname,
    }).then((json) => {
      this.setState({ isLoading: false });
      this.props.showSnackbar('Team name & URL updated.');
      if (json.reload_url) {
        location.href = json.reload_url; // eslint-disable-line
      }
    });
  };

  validateUrl(url) {
    const { validateUrl, hostname } = this.props;
    const urlLink = linkAt(this, 'hostname');
    if (this.validateUrlTimer) clearTimeout(this.validateUrlTimer);
    if (url == hostname) return true;
    this.validateUrlTimer = setTimeout(() => {
      this.setState({ isLoading: true });
      validateUrl(url).then((resp) => {
        this.setState({ isLoading: false });
        if (resp != 'ok') {
          urlLink.setError('This hostname is already taken.');
        }
      });
    }, 500);
    return true;
  }

  getDefaultFieldState() {
    const { name, hostname } = this.props;
    return {
      popupFor: false,
      name,
      hostname,
    };
  }

  localValuesUnchanged() {
    const { name, hostname } = this.props;
    return this.state.name == name && this.state.hostname == hostname;
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Card>
        <StyledRow>
          <h5>Team name &amp; URL</h5>
        </StyledRow>

        <StyledRow>
          <p>Update your team name and your team's unique login URL.</p>
        </StyledRow>

        <StyledRow>
          <Form
            isLoading={isLoading}
            onSubmit={this.onSubmit}
            disabled={
              this.localValuesUnchanged() || !isValid(this) || isLoading
            }
            fieldLinkName={linkAt(this, 'name')}
            fieldLinkHostname={linkAt(this, 'hostname')}
          />
        </StyledRow>
      </Card>
    );
  }
}

export default withConfirm(TeamNameURL);
