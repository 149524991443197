import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getUser } from '@float/common/selectors/currentUser';
import IconCloseSmall from '@float/ui/deprecated/Icons/icon-close';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';

const Content = styled.div`
  text-align: left;
  font-weight: 500;
  max-width: 300px;
  color: ${(p) => p.theme.blueGrey};
`;

const Close = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;

  path {
    fill: ${(p) => p.theme.slateGrey};
  }

  &:hover {
    path {
      fill: ${(p) => p.theme.midGrey};
    }
  }
`;

const Wrapper = styled.div`
  color: white;
  font-size: 14px;
  max-width: 210px;
  padding: 9px 0 12px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Header = styled.div`
  text-align: center;
  font-weight: normal;
  margin-bottom: 10px;
`;

const Button = styled.button`
  font-weight: 600;
  color: white;
  border-radius: 4px;
  background: #555555;
  padding: 4px 10px;
`;

class TourTip extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    const { promptKey, prompts } = this.props;
    const shouldShowTour = !prompts.includes(promptKey);
    if (shouldShowTour) {
      setTimeout(this.open, 100);
    }
  }

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
    this.props.updatePromptShown(this.props.promptKey);
  };

  render() {
    const { children, tooltipProps } = this.props;
    const { content, ...rest } = tooltipProps;
    return (
      <Popover
        placement="right"
        open={this.state.isOpen}
        content={
          <Content>
            {!this.props.hideCloseButton && (
              <Close onClick={this.close}>
                <IconCloseSmall />
              </Close>
            )}
            {content}
          </Content>
        }
        {...rest}
      >
        {children}
      </Popover>
    );
  }
}

TourTip.Wrapper = Wrapper;
TourTip.Header = Header;
TourTip.Button = Button;

const mapStateToProps = (state) => ({
  currentUser: getUser(state),
  prompts: state.legacyOnboarding.prompts,
});

const mapDispatchToProps = (dispatch) => ({
  updatePromptShown: (prompt) => dispatch(updatePrompts(prompt)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TourTip);
