import { Rights } from '@float/common/lib/acl';
import { CurrentUser } from '@float/types/account';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';
import { useWebAppSelector } from '@float/web/lib/store';
import { getSubdepartments, getUser } from '@float/web/selectors';

import {
  PersonDepartmentChange,
  PersonDepartmentProps,
} from '../PersonDepartment.types';

export const usePersonDepartment = ({
  departmentId,
  account,
  onDepartmentChange,
}: Pick<
  PersonDepartmentProps,
  'departmentId' | 'account' | 'onDepartmentChange'
>) => {
  const user: CurrentUser = useWebAppSelector(getUser);
  const canCreateDepartments = Rights.canCreateDepartment(user);
  const subDepartments = useWebAppSelector(getSubdepartments);

  const onChange = (option: VirtualSelectOption) => {
    const { isCreate } = option;
    const nextDepartmentId = isCreate ? option.value : +option.value!;
    const newState: PersonDepartmentChange = {
      department_id: nextDepartmentId || 0,
      isAddingNewDepartment: Boolean(isCreate),
    };

    if (newState.department_id === departmentId) {
      return;
    }

    // make sure selected department is a part of viewable departments
    // unless a parent department is already in the list
    if (
      nextDepartmentId &&
      account &&
      'department_filter_set' in account &&
      account.department_filter_set
    ) {
      const currentDepartmentFilter = account.department_filter || [];
      const canViewNextDepartment = currentDepartmentFilter.some(
        (departmentId) => {
          return subDepartments[departmentId]?.includes(
            Number(nextDepartmentId),
          );
        },
      );

      if (!canViewNextDepartment) {
        newState.account = {
          ...account,
          department_filter: [
            // @ts-expect-error new departments are passed as strings
            ...currentDepartmentFilter.filter(
              (depId) =>
                depId !== nextDepartmentId &&
                !subDepartments[Number(nextDepartmentId)]?.includes(depId),
            ),
            // @ts-expect-error new departments are passed as strings
            nextDepartmentId,
          ],
        };
      }
    }

    onDepartmentChange(newState);
  };

  return { onChange, canCreateDepartments };
};
