import React, { useEffect, useMemo, useRef, useState } from 'react';
import { find, map, sortBy } from 'lodash';
import styled from 'styled-components';

import { StatusIcon } from '@float/common/components/StatusIcon/StatusIcon';
import { DEFAULT_STATUS_COLOR } from '@float/constants/colors';
import { Button } from '@float/ui/deprecated/Button/Button';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { Input } from '@float/ui/deprecated/Input';

const StatusInputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  margin: 6px 0 10px;
  position: relative;

  .input-container input {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
`;

const SelectedStatusColorIcon = styled(StatusIcon)`
  position: absolute;

  top: 34px;
  left: 14px;

  z-index: 2;
`;

const StatusClear = styled.div`
  position: absolute;
  top: 32px;
  right: 10px;

  width: 24px;
  height: 24px;

  cursor: pointer;

  z-index: 1;

  svg {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
  }
`;

const StatusOptions = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const StyledStatusOption = styled.div`
  margin-bottom: 5px;

  button {
    width: 100%;
    justify-content: flex-start;
  }
`;

const StatusText = styled.span`
  margin-left: 12px;
`;

const StyledStatusIcon = styled(StatusIcon)`
  vertical-align: -2px;
`;

const StatusOption = ({ children, onClick }) => {
  return (
    <StyledStatusOption>
      <Button appearance="secondary" onClick={onClick}>
        {children}
      </Button>
    </StyledStatusOption>
  );
};

function isCustomOption(option) {
  return option.status_type_name === 'Custom';
}

function StatusInput({ text, options, errors, readOnly, onChange, onEnter }) {
  const inputRef = useRef({});
  const orderedOptions = useMemo(
    () => sortBy(options, ['sort_order']),
    [options],
  );
  const [customStatusId, customStatusColor] = useMemo(() => {
    const option = find(options, (o) => isCustomOption(o)) || {};
    const color = option.color || DEFAULT_STATUS_COLOR;
    const id = option.status_type_id || null;
    return [id, color];
  }, [options]);
  const [selectedId, setSelectedId] = useState(customStatusId);
  const isAnyOptionSelected = !!text;

  const getSelectedIdByText = (value = '') => {
    const option =
      find(
        options,
        (o) => o.status_type_name.toUpperCase() === value.trim().toUpperCase(),
      ) || {};
    return option.status_type_id || customStatusId;
  };

  const getColorById = (id = selectedId) => {
    id = !id ? customStatusId : id;
    return (id && options[id] && options[id].color) || customStatusColor;
  };

  const onInputChange = (e) => {
    const { value } = e.target;
    const id = getSelectedIdByText(value);
    const color = getColorById(id);
    onChange({ text: value, selectedId: id, color });
  };

  const onClear = () => {
    onChange({
      text: '',
      selectedId: customStatusId,
      color: customStatusColor,
    });
  };

  const onSelect = (id) => {
    onChange({
      text: options[id].status_type_name,
      selectedId: id,
      color: getColorById(id),
    });
    inputRef.current.focusInput();
  };

  useEffect(() => {
    const id = getSelectedIdByText(text);
    if (selectedId !== id) {
      setSelectedId(id);
    }
  }, [text]); //eslint-disable-line

  return (
    <>
      <StatusInputContainer>
        <SelectedStatusColorIcon color={getColorById()} />
        <Input
          ref={inputRef}
          autoFocus
          label="Set a status"
          placeholder="Custom status"
          value={text || ''}
          errors={errors}
          readOnly={readOnly}
          noPointerEvents={readOnly}
          onChange={onInputChange}
          onEnter={onEnter}
        />
        {isAnyOptionSelected && !readOnly && (
          <StatusClear onClick={onClear}>
            <Icons.IconClose />
          </StatusClear>
        )}
      </StatusInputContainer>

      <StatusOptions>
        {isAnyOptionSelected
          ? undefined
          : map(orderedOptions, (opt) =>
              isCustomOption(opt) ? null : (
                <StatusOption
                  key={opt.status_type_id}
                  onClick={(e) => {
                    e.preventDefault();
                    onSelect(opt.status_type_id);
                  }}
                >
                  <StyledStatusIcon color={opt.color} />
                  <StatusText>{opt.status_type_name}</StatusText>
                </StatusOption>
              ),
            )}
      </StatusOptions>
    </>
  );
}

export default StatusInput;
