import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import OutsideHandler from '@float/common/components/elements/OutsideHandler';
import EHIconClose from '@float/ui/deprecated/Earhart/Icons/Icon/IconClose';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { LabelWrapper, MultiSelectContainer, TokenWrapper } from './styles';

export function MultiSelect(props) {
  const {
    size = 'medium',
    label = '',
    values,
    keyboardHighlight,
    sort = true,
    onAdd,
    onRemove,
    onReplace,
    hideLabel,
    inputId,
    placeholder,
    ...rest
  } = props;

  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const vsRef = useRef();
  const preventNextFocus = useRef(false);

  const sortedValues = useMemo(() => {
    if (!values) return [];
    if (!sort) return values;

    return values.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    );
  }, [values, sort]);

  function handleBlur() {
    setHighlightedIndex(null);
  }

  function onInputKeyDown(evt) {
    if (evt.key === 'ArrowLeft') {
      if (vsRef.current.state.inputVal.length === 0 && values.length) {
        evt.preventDefault();
        vsRef.current.hideDropdown();

        const index = highlightedIndex ?? values.length;
        setHighlightedIndex(Math.max(0, index - 1));

        return true;
      }
    }

    if (evt.key === 'ArrowRight') {
      if (vsRef.current.state.inputVal.length === 0 && values.length) {
        evt.preventDefault();

        let index = highlightedIndex ?? values.length;
        index++;

        if (index >= values.length) {
          setHighlightedIndex(null);
          vsRef.current.inputRef.current.focus();
          vsRef.current.showDropdown();
        } else {
          setHighlightedIndex(index);
        }

        return true;
      }
    }

    if (
      highlightedIndex !== null &&
      (evt.key === 'Backspace' || evt.key === 'Delete')
    ) {
      setHighlightedIndex(null);
      onRemove(sortedValues[highlightedIndex]);
      vsRef.current.inputRef.current.focus();
      vsRef.current.showDropdown();
      return true;
    }

    setHighlightedIndex(null);

    return false;
  }

  const Label = hideLabel ? null : (
    <LabelWrapper>
      <span>{label || 'Tags'}</span>
    </LabelWrapper>
  );

  return (
    <MultiSelectContainer
      highlightedIndex={highlightedIndex}
      className={classNames({
        medium: size === 'medium',
        large: size === 'large',
      })}
    >
      <VirtualSelect
        size={size}
        vsRef={(t) => (vsRef.current = t)}
        label={Label}
        maxLength={32}
        keepFocusAfterSelect
        hideClearIcon
        hideDropdownIcon
        disallowedOptions={values}
        inputId={inputId}
        placeholder={sortedValues.length === 0 ? placeholder : ''}
        {...rest}
        onChange={(val) => {
          if (highlightedIndex !== null) {
            onReplace(sortedValues[highlightedIndex], val);
          } else {
            onAdd(val);
          }
          setHighlightedIndex(null);
        }}
        onEmptyBackspace={() => {
          if (sortedValues.length) {
            onRemove(sortedValues[sortedValues.length - 1]);
          }
        }}
        onInputKeyDown={keyboardHighlight && onInputKeyDown}
        onInputMouseDown={() => {
          preventNextFocus.current = true;
        }}
        onFocus={() => {
          if (preventNextFocus.current) {
            preventNextFocus.current = false;
            return;
          }
          if (values.length === 1 && onReplace) {
            setHighlightedIndex(0);
          }
        }}
      >
        {sortedValues.map((t, idx) => {
          const isClickable = values.length === 1 && onReplace;

          const inner = (
            <TokenWrapper
              key={t.value ?? t.label}
              appearance="dynamic"
              size="large"
              color={t.color}
              icon={!t.static && EHIconClose}
              trailingIcon
              border={idx === highlightedIndex}
              isClickable={isClickable}
              onClick={
                isClickable
                  ? () => {
                      setHighlightedIndex(0);
                      vsRef.current.inputRef.current.focus();
                      vsRef.current.showDropdown();
                    }
                  : null
              }
              clickIconCallback={() => onRemove(t)}
            >
              {t.label}
            </TokenWrapper>
          );

          return isClickable ? (
            <OutsideHandler
              key={0}
              noWrapper
              onClickOutside={() => {
                // Need to trigger this on the next event loop tick so that we
                // still have the value of isReplacing when the click event
                // triggers on the list item.
                setTimeout(handleBlur);
              }}
            >
              {inner}
            </OutsideHandler>
          ) : (
            inner
          );
        })}
      </VirtualSelect>
    </MultiSelectContainer>
  );
}

export default MultiSelect;
