import { get, isUndefined } from 'lodash';

import { setCachedResponse } from '@float/common/lib/cachedFetch';
import socket from '@float/common/lib/liveUpdates/socket';
import { userCanViewOthers } from '@float/common/lib/rights';
import { isActive } from '@float/common/lib/utils';
import { config } from '@float/libs/config';

const isOnRootRoute = () => window.location.pathname === '/';
const isOnOneOfRoutes = (routes) => !!routes.find((x) => isActive(x));

export const isOnSchedulePage = () =>
  isOnRootRoute() || isOnOneOfRoutes(['my-schedule', 'schedule-v2']);

export const isInitiatedByCurrentUser = (data, currentUser) => {
  if (data.external_source) {
    return false;
  }

  const { author_uuid, admin } = data;
  const { account_id: authorId } = admin || {};
  if (author_uuid) {
    return author_uuid == socket.uuid;
  }
  return authorId && authorId == `${currentUser.admin_id}`;
};

function haveAttributesChanged(notification, attrs) {
  const before = notification.af_item?.before_data;
  return before && attrs.some((attr) => !isUndefined(before[attr]));
}

function hasPhaseDateChanged(notification) {
  return haveAttributesChanged(notification, ['start_date', 'end_date']);
}

function wasPersonRemovedFromPhaseTeam(notification) {
  const before = notification.af_item?.before_data || {};
  const after = notification.af_item?.after_data || {};
  return before.people_ids?.length > after.people_ids?.length;
}

function hasLinkedTaskChanged(notification) {
  return haveAttributesChanged(notification, [
    'root_task_id',
    'parent_task_id',
  ]);
}

export const shouldNotificationBeProcessedForCurrentUser = (data) => {
  const type = get(data, 'notification.type');

  if (
    data.notification.scenario !== undefined &&
    data.notification.scenario === 'example_data'
  ) {
    return true;
  }

  // See if we're just removing / replacing a list of tasks / timeoffs
  if (['replace', 'bulk_update'].includes(data.notification.action)) {
    return Boolean(
      data.notification.result ||
        ['task', 'timeoff', 'logged_time'].includes(type),
    );
  }

  if (['project_dates', 'task_meta', 'budget_utilization'].includes(type)) {
    return true;
  }

  const action = get(data, 'notification.action');
  if (
    [
      'batch_upsert',
      'batch_delete',
      'bulk_delete',
      'sync_complete',
      'import_complete',
      'preload_complete',
      'preload_error',
    ].includes(action)
  ) {
    return true;
  }

  if (action === 'bulk_clone' && type === 'milestones') {
    return true;
  }

  if (action === 'duplicate' && type === 'project') {
    return true;
  }

  if (action === 'update_via_project' && type === 'people') {
    // This occurs when a person's default_hourly_rate is set by a project
    return true;
  }

  if (action === 'import' && type !== 'holidays') {
    return true;
  }

  if (action === 'delete' && type === 'account_co') {
    return true;
  }

  if (
    type === 'phase' &&
    action === 'update' &&
    (hasPhaseDateChanged(data.notification) ||
      wasPersonRemovedFromPhaseTeam(data.notification))
  ) {
    // Phase date change can happen as a side effect of task being expanded.
    // Phase team changes can happen as a side effect of person being removed
    // from project.
    return true;
  }

  if (type === 'task') {
    if (get(data, 'notification.af_item.batch_id')) {
      // This can happen as a side effect.
      // E.g. Removing or switching people from project.
      return true;
    }

    return hasLinkedTaskChanged(data.notification);
  }

  if (type === 'client' && action === 'delete') {
    // need to delete projects, tasks etc. associated with the client
    return true;
  }

  if (type === 'logged_time' && action === 'delete') {
    // could be as a result of people being removed from project
    return true;
  }

  return false;
};

function isDepartmentMatch(
  departmentFilterIds,
  itemDepartmentId,
  subDeptsByParentDeptId,
) {
  return departmentFilterIds.some((deptFilterId) => {
    const matchesParentDept = deptFilterId == itemDepartmentId;
    if (matchesParentDept) return true;

    const subDepts = subDeptsByParentDeptId?.[`${deptFilterId}`];
    const matchesSubDept = subDepts?.some(
      (subDept) => subDept == itemDepartmentId,
    );
    return matchesSubDept;
  });
}

export const isUserAuthorizedToView = (
  afItem,
  currentUser,
  subDeptsByParentDeptId,
) => {
  const { account_tid: accountTypeId } = currentUser || {};
  if ([1, 2, 5].some((x) => x == accountTypeId)) {
    return true;
  }

  const activityFeedItem = afItem || {};

  const { people_id: peopleId, department_filter: departmentFilterIds = null } =
    currentUser;
  const { department_id: itemDepartmentId = null, item_type: type } =
    activityFeedItem;

  let itemPeopleId = activityFeedItem.people_ids;
  if (!itemPeopleId || !itemPeopleId.length) {
    itemPeopleId = activityFeedItem.people_id;
  }

  // this is to keep the logic consistent between AF BE and FE
  const typesToFilter = ['task', 'timeoff', 'people', 'department'];
  if (typesToFilter.includes(type)) {
    if (departmentFilterIds?.length) {
      if (Array.isArray(itemDepartmentId)) {
        return itemDepartmentId.some((id) =>
          isDepartmentMatch(departmentFilterIds, id, subDeptsByParentDeptId),
        );
      }

      return isDepartmentMatch(
        departmentFilterIds,
        itemDepartmentId,
        subDeptsByParentDeptId,
      );
    }

    const canViewOthers = userCanViewOthers(currentUser);
    if (!canViewOthers) {
      // view their schedule
      return (
        (Array.isArray(itemPeopleId) &&
          itemPeopleId.find((id) => id == peopleId)) ||
        itemPeopleId == peopleId
      );
    }

    return canViewOthers;
  }

  return true;
};

export const cacheBudgetResponse = (budgets) => {
  if (!budgets?.length) return;
  const projectIds = budgets.map((x) => x.project_id);
  const baseUrl = new URL(
    config.reports.getLegacyPath('projects/budget'),
    config.reports.domain,
  );
  const url = `${baseUrl}?project_id=${projectIds.join(',')}`;
  setCachedResponse({ key: url, value: budgets });
};

function getActivityData(afItem, key) {
  return get(afItem, `after_data.${key}`) || get(afItem, `before_data.${key}`);
}

export function accessRightsChanged(currentUser, notification) {
  const afItem = notification?.af_item;
  const currentUserModified =
    afItem?.item_type === 'account_co' &&
    getActivityData(afItem, 'account_id') === currentUser?.account_id;

  if (!currentUserModified) {
    return false;
  }

  if (afItem.activity_type === 'delete') return true;

  const before = afItem.before_data || {};
  const significantKeys = [
    'account_type_id',
    'active',
    'department_filter',
    'access',
    'management_group',
  ];
  return Object.keys(before).some((x) => significantKeys.includes(x));
}
