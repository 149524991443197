import React from 'react';

import { Button } from '@float/ui/deprecated/Button/Button';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';

import modalManagerHoc from '../../modalManager/modalManagerHoc';

function getModalTitle({ isTimeoff }: { isTimeoff: boolean }) {
  if (isTimeoff) {
    return 'Deleting a time off';
  }

  return 'Deleting an allocation';
}

function getModalBody({ isTimeoff }: { isTimeoff: boolean }) {
  let entityName = 'allocation';

  if (isTimeoff) {
    entityName = 'timeoff';
  }

  return (
    `This ${entityName} is assigned to multiple people. ` +
    `Would you like to delete the ${entityName} for all people or just this person?`
  );
}

type DeleteMultiAssignModalResponse = 'cancel' | 'deleteAll' | 'deleteThis';

type DeleteMultiAssignModalProps = {
  modalSettings: {
    isTimeoff: boolean;
    callback: (answer: DeleteMultiAssignModalResponse) => void;
  };
  manageModal: (params: {
    visible: false;
    modalType: 'deleteMultiAssignModal';
  }) => void;
};

function DeleteMultiAssignModalBase(props: DeleteMultiAssignModalProps) {
  function handleClose(answer: DeleteMultiAssignModalResponse) {
    props.modalSettings.callback(answer);
    props.manageModal({
      visible: false,
      modalType: 'deleteMultiAssignModal',
    });
  }

  const { isTimeoff } = props.modalSettings;

  return (
    <Modal
      isOpen
      center
      onEnter={() => handleClose('deleteAll')}
      onClose={() => handleClose('cancel')}
    >
      <ModalHeader>
        <ModalTitle>{getModalTitle({ isTimeoff })}</ModalTitle>
      </ModalHeader>
      <ModalBody>{getModalBody({ isTimeoff })}</ModalBody>
      <ModalActions>
        <Button autoFocus onClick={() => handleClose('deleteAll')}>
          All people
        </Button>
        <Button onClick={() => handleClose('deleteThis')}>This person</Button>
        <Button appearance="secondary" onClick={() => handleClose('cancel')}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  );
}

export const DeleteMultiAssignModal = modalManagerHoc({
  Comp: DeleteMultiAssignModalBase,
  modalType: 'deleteMultiAssignModal',
});
