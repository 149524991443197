import React from 'react';

import TrialEndingSoon from '@float/web/components/legacyOnboarding/InAppNotifications/TrialEndingSoon';
import KeyboardShortcutModal from '@float/web/components/legacyOnboarding/KeyboardShortcutModal/KeyboardShortcutModal';
import PersonModal from '@float/web/manage/people-v2/modals/PersonModal/PersonModal';

import NotificationSettingsModal from '../Nav/NotificationSettingsModal';
import ShareLinkModal from '../Nav/ShareLinkModal';
import CalendarIntegrationsModal from './CalendarIntegrationsModal/CalendarIntegrationsModal';
import IntegrationCalendarDisconnectedModal from './CalendarIntegrationsModal/IntegrationCalendarDisconnectedModal';
import { ManagedConfirmModal } from './ConfirmModal';
import ErrorModal from './ErrorModal';
import { InsightsPercentageCallout } from './InsightsPercentageCallout/InsightsPercentageCallout';
import { ModalProjectFromTemplate } from './ModalProjectFromTemplate';
import { ModalSwitchPerson } from './ModalSwitchPerson';
import MyInfoModal from './MyInfoModal/MyInfoModal';
import ProjectModal from './ProjectModal';
import PhaseModal from './ProjectModal/PhaseModal';

export const Modals = () => {
  return (
    <>
      <MyInfoModal />
      <CalendarIntegrationsModal />
      <IntegrationCalendarDisconnectedModal />
      <NotificationSettingsModal />
      <ShareLinkModal />
      <PersonModal />
      <ProjectModal />
      <PhaseModal />
      <ErrorModal />
      <ManagedConfirmModal />
      <KeyboardShortcutModal />
      <TrialEndingSoon />
      <InsightsPercentageCallout />

      {/* Modals migrated to @ui/primitives/Modal */}
      <ModalSwitchPerson />
      <ModalProjectFromTemplate />
    </>
  );
};
