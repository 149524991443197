import { createGlobalStyle, css, keyframes } from 'styled-components';

import Colors from '@float/ui/deprecated/Theme/colors';

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const pulsateBorder = css`
  border: 2px solid ${Colors.blue};
  opacity: 0;
  will-change: opacity;
  animation: ${fadeIn} 0.6s ease-out forwards;
`;

export const InAppGuideStyle = createGlobalStyle`
  .in-app-guide-modal {
    z-index: 10000;

    .icon-close-small {
      position: absolute;
      top: 16px;
      right: 16px;

      path {
        fill: ${Colors.charcoalGrey};
      }
    }
  }
`;

export const modalStyle = {
  borderRadius: 10,
  marginTop: 150,
  overflow: 'hidden',
  boxShadow: '0 6px 30px 5px rgba(0,0,0,.12)',
};
