import { FloatStorageKey, storage } from '@float/libs/storage';

export function createViewLastUsedStorage() {
  let data: Record<number, string> | null = null;

  // Lazily read from local storage at the first get request
  async function readFromStorage(): Promise<Record<number, string>> {
    const value = (await storage.getItem(FloatStorageKey.ViewLastUsed)) ?? '{}';

    try {
      return JSON.parse(value);
    } catch (err) {
      // Do nothing
    }

    return {};
  }

  return {
    async get(id: number) {
      if (data === null) {
        data = await readFromStorage();
      }

      return data[id];
    },
    async update(id: number) {
      if (data === null) {
        data = await readFromStorage();
      }

      data[id] = new Date().toJSON();

      await storage.setItem(FloatStorageKey.ViewLastUsed, JSON.stringify(data));
    },
  };
}

export const viewLastUsedStorage = createViewLastUsedStorage();
