import React from 'react';
import { t } from '@lingui/macro';

import { Department } from '@float/types/department';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';

type Props = {
  canClearSelection?: boolean;
  canCreate: boolean;
  departmentId: Department['department_id'];
  departmentOptions: VirtualSelectOption[];
  errors?: string[];
  onChange: (option: VirtualSelectOption) => void;
  placeholder?: string;
};

export const PersonDepartmentView = (props: Props) => {
  const {
    canClearSelection,
    canCreate,
    departmentId,
    departmentOptions,
    errors,
    onChange,
    placeholder,
  } = props;

  return (
    <VirtualSelect
      // @ts-expect-error VirtualSelect does not have types
      clearInputOnDropdownOpen={false}
      creatable={canCreate}
      errors={errors}
      hideClearIcon={!canClearSelection}
      label={t`Department`}
      nonNullable={!canClearSelection}
      onChange={onChange}
      options={departmentOptions}
      placeholder={placeholder}
      value={departmentId}
      visibleItems={6}
    />
  );
};
