import { useEffect, useState } from 'react';

export function useStorageSyncedState<T>(key: string, defaultValue: T) {
  const [val, setVal] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem(key) || '') || defaultValue;
    } catch (e) {
      return defaultValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(val));
  }, [key, val]);

  return [val, setVal];
}
