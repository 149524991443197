import React, { useState } from 'react';

import { ConfirmDeleteTeam } from '@float/common/components/ConfirmDeleteTeam';
import { useAppSelector } from '@float/common/store';
import { Button } from '@float/ui/deprecated/Button/Button';
import { Card } from '@float/ui/deprecated/Earhart/Cards';

import { StyledRow } from '../styles';
import { StyledColumnRow } from './styles';

function DeleteTeam() {
  const [modal, setModal] = useState<'delete-team' | null>(null);

  const companyName = useAppSelector(
    (state) => state.companyPrefs.company_name,
  ) as string;

  return (
    <Card>
      {modal === 'delete-team' && (
        <ConfirmDeleteTeam
          onClose={() => setModal(null)}
          companyName={companyName}
        />
      )}
      <StyledRow>
        <h5>Delete team</h5>
      </StyledRow>

      <StyledColumnRow>
        <p>
          Deleting a Float team cannot be undone. We'll delete all of your
          projects, people and data immediately and permanently.
        </p>
        <Button
          appearance="danger"
          size="small"
          onClick={() => {
            setModal('delete-team');
          }}
        >
          Delete team
        </Button>
      </StyledColumnRow>
    </Card>
  );
}

export default DeleteTeam;
