import React, { useRef } from 'react';
import { t } from '@lingui/macro';

import { useOnMount } from '@float/libs/hooks/useOnMount';
import { Input } from '@float/ui/deprecated/Input';

import { useInputAllocationPercentage } from './hooks/useInputAllocationPercentage';

import * as styles from './InputAllocationPercentage.css';

export type InputAllocationPercentageProps = {
  hoursPerDay: number | null;
  isReadOnly: boolean;
  numberOfAllocationDaysInDateRange: number;
  numberOfWorkingDayHoursInDateRange: number;
  onChange: (data: {
    hoursPerDay: number | null;
    hoursTotal: number | null;
  }) => void;
  onBlurPercentage: () => void;
  onBlurHoursTotal: () => void;
};

export const InputAllocationPercentage = (
  props: InputAllocationPercentageProps,
) => {
  const {
    hoursPerDay,
    isReadOnly,
    numberOfAllocationDaysInDateRange,
    numberOfWorkingDayHoursInDateRange,
    onChange,
    onBlurPercentage,
    onBlurHoursTotal,
  } = props;

  const inputPercentage = useRef<HTMLInputElement>();

  const isTotalInputDisabled =
    numberOfWorkingDayHoursInDateRange === 0 ||
    numberOfAllocationDaysInDateRange === 0;

  // When this component appears in the DOM we want it to focus,
  // so user can start typing without an extra click
  useOnMount(() => {
    if (isReadOnly) return;

    inputPercentage.current?.focus();
  });

  const {
    percentageInternalValue,
    hoursTotalInternalValue,
    handlePercentageChange,
    handleHoursTotalChange,
  } = useInputAllocationPercentage({
    hoursPerDay,
    numberOfAllocationDaysInDateRange,
    numberOfWorkingDayHoursInDateRange,
    onChange,
  });

  const handlePercentageKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onBlurPercentage();
    }
  };

  const handleHoursTotalKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onBlurHoursTotal();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <Input
          inputRef={inputPercentage}
          appearance="underline"
          type="number"
          hideArrows
          selectTextOnFocus
          value={percentageInternalValue ?? ''}
          min={1}
          step={1}
          readOnly={isReadOnly}
          noBorder={isReadOnly}
          onChange={handlePercentageChange}
          onKeyPress={handlePercentageKeyPress}
          isStaticPlaceholder
          ariaLabel={t`Percentage`}
          suffix={!isReadOnly && <span className={styles.inputSuffix}>%</span>}
        />
      </div>
      <div className={styles.column}>
        <Input
          appearance="underline"
          type="number"
          hideArrows
          selectTextOnFocus
          value={hoursTotalInternalValue ?? ''}
          min={0.5}
          step={0.5}
          readOnly={isReadOnly}
          noBorder={isReadOnly}
          disabled={isTotalInputDisabled}
          onChange={handleHoursTotalChange}
          onKeyPress={handleHoursTotalKeyPress}
          isStaticPlaceholder
          ariaLabel={t`Total hours`}
        />
      </div>
    </div>
  );
};
