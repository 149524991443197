import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import { BaseSidePanelInput } from '../components/BaseSidePanelInput';
import { FormField } from '../components/FormFieldsSection';

const FIELD_NAME = 'project.project_code';

const ProjectCodeField = () => {
  const { setValue, getValues } = useFormContext();

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      // Need to prevent the default submit behaviour
      e.preventDefault();
      (e.target as HTMLInputElement).blur();
    }
  }, []);

  const onChange = useCallback(
    (arg: React.ChangeEvent<HTMLInputElement>) => {
      setValue(FIELD_NAME, arg.target.value, {
        shouldDirty: false,
        shouldValidate: true,
        shouldTouch: false,
      });
    },
    [setValue],
  );

  return (
    <FormField
      label={t`Project code`}
      alignLabel="center"
      htmlFor={FIELD_NAME}
      stopClickPropagation
    >
      <BaseSidePanelInput
        type="text"
        inputMode="text"
        name={FIELD_NAME}
        value={getValues(FIELD_NAME)}
        placeholder={t`Add project code`}
        size="medium"
        variant="spacious"
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </FormField>
  );
};

export { ProjectCodeField };
