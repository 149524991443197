import React, { forwardRef } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';
import { merge } from 'lodash';

import { BASE_INPUT_THEME } from '../QuickInput.constants';
import {
  QuickInputSpacingVariants,
  QuickInputThemeOverrides,
} from '../QuickInput.types';
import { PopoutBorder } from './PopoutBorder';

import * as styles from '../QuickInput.css';

export interface QuickInputFieldWrapperProps {
  children: React.ReactNode;
  disabled?: boolean;
  onWrapperClick: () => void;
  themeOverrides?: QuickInputThemeOverrides;
  variant: QuickInputSpacingVariants;
  className?: string;
  prefix?: string;
  suffix?: string;
  readOnly?: boolean;
}

export const QuickInputFieldWrapper = forwardRef<
  HTMLDivElement,
  QuickInputFieldWrapperProps
>(
  (
    {
      children,
      disabled,
      onWrapperClick,
      themeOverrides,
      variant,
      className,
      prefix,
      suffix,
      readOnly,
    },
    forwardedRef,
  ) => {
    const theme = merge(Object.create(null), BASE_INPUT_THEME, themeOverrides);

    return (
      <div
        aria-disabled={disabled}
        aria-readonly={readOnly}
        className={cn(styles.inputOuterWrapper, className)}
        onClick={onWrapperClick}
        style={assignInlineVars(styles.themeVars, theme)}
        ref={forwardedRef}
      >
        {Boolean(prefix) && (
          <div className={styles.prefixWrapper}>{prefix}</div>
        )}
        {children}
        {Boolean(suffix) && (
          <div className={styles.suffixWrapper}>{suffix}</div>
        )}
        <PopoutBorder variant={variant} />
      </div>
    );
  },
);
