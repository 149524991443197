import React from 'react';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { trackEvent } from '@float/common/lib/analytics';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import EHIconCalendar from '@float/ui/deprecated/Earhart/Icons/Icon/IconCalendar';
import EhIconChevronDropdown from '@float/ui/deprecated/Earhart/Icons/Icon/IconChevronDropdown';
import { List } from '@float/ui/deprecated/Earhart/List';
import { TooltipWithHintAndMenu } from '@float/ui/deprecated/Tooltip/TooltipWithHintAndMenu';

const MODES = [
  { value: 0, name: 'Days', width: 245 },
  { value: 1, name: 'Weeks', width: 90 },
  { value: 2, name: 'Months', width: 27 },
];

function Menu({ value, onChange }) {
  return (
    <List
      appearance="flay"
      options={MODES}
      value={Number(value)}
      minWidth="185px"
      onClick={onChange}
    />
  );
}

export default function HorizontalZoom({ actions }) {
  const { dayWidth, setDayWidth } = useScheduleContext();

  const onChange = (mode) => {
    // Optimistically update the width and save afterwards
    setDayWidth(mode.width);
    setTimeout(() => {
      actions.updatePrefs({ sked_view: mode.value });
      trackEvent('Horizontal zoom set', { type: mode.name });
    }, 750);
  };

  const currentMode = MODES.find((m) => m.width == dayWidth) || MODES[0];

  return (
    <TooltipWithHintAndMenu
      key="zoom-horizontal"
      distance={5}
      arrow={false}
      placement="bottom"
      hint="Zoom"
      menu={() => <Menu value={currentMode.value} onChange={onChange} />}
    >
      {(_, showMenu) => (
        <NavIconBtn
          isPrimary={false}
          onClick={showMenu}
          iconLeft={<EHIconCalendar />}
          iconRight={<EhIconChevronDropdown size={16} />}
        >
          {currentMode.name}
        </NavIconBtn>
      )}
    </TooltipWithHintAndMenu>
  );
}
