import { isArchived } from '@float/common/components/Schedule/Cell/MainCell/Item/box/utils/isArchived';
import { AnyEntity, Phase, Project } from '@float/types';

export const isItemEntityArchived = (
  entity: AnyEntity,
  {
    projects,
    phases,
  }: {
    projects: Record<number, Project>;
    phases: Record<number, Phase>;
  },
) => {
  const phaseId = 'phase_id' in entity && entity.phase_id;
  const projectId = 'project_id' in entity && entity.project_id;

  const phase = phaseId ? phases[phaseId] : undefined;
  const project = projectId ? projects[projectId as number] : undefined;

  return isArchived(project, phase);
};
