import { CurrentUser } from '@float/types/account';
import { Person } from '@float/types/person';

import { getWorkHours } from './getWorkHours';

type GetWorkHoursInRangeParams = {
  start_date: string;
  end_date: string;
  people: Person[];
  dates: DatesManager;
  user: CurrentUser;
  getIsWorkDay: (
    person: Person,
    date: string,
    excludeTimeoffId?: number,
  ) => boolean;
};

export function getWorkHoursInRange({
  start_date,
  end_date,
  people,
  dates,
  user,
  getIsWorkDay,
}: GetWorkHoursInRangeParams) {
  let workHours = 0;

  if (!people) return workHours;

  const start = dates.toNum(start_date);
  const end = dates.toNum(end_date);

  for (const person of people) {
    for (let i = start; i <= end; i++) {
      const dateStr = dates.fromNum(i);

      const isWorkDay = getIsWorkDay(person, dateStr);

      // Exclude full day holidays and timeoffs
      if (!isWorkDay) continue;

      workHours += getWorkHours(dates, user, person, dateStr);
    }
  }

  return workHours;
}
