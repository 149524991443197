import React from 'react';
import { t } from '@lingui/macro';
import { differenceBy, get, isArray, isEmpty, isEqual, noop } from 'lodash';

import { duplicateProject } from '@float/common/actions/projects';
import API3 from '@float/common/api3';
import { formatToDbString, isAmountEmpty } from '@float/common/lib/budget';
import { userCanSeeBudgets } from '@float/common/selectors/projects';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { capitalize } from '@float/libs/utils/string/capitalize';
import { Button } from '@float/ui/deprecated/Button/Button';

import { ProjectActionsMenu } from './ProjectActionsMenu';
import { getDeleteImpact } from './ProjectForm/TasksFragment';
import { validate } from './ProjectModal.helpers';
import { TemplateActionsMenu } from './TemplateActionsMenu';

export function formatBudgetFields(newProject) {
  ['budget_total', 'default_hourly_rate'].forEach((key) => {
    if (!isAmountEmpty(newProject[key])) {
      newProject[key] = formatToDbString(newProject[key]);
    }
  });
}

export function setProjectRoster(
  currentUser,
  newProject,
  people,
  initialProject,
  isNew,
) {
  const projectTeamWasChanged =
    !initialProject ||
    isNew ||
    !isEqual(newProject.people_ids, initialProject.people_ids) ||
    !isEqual(newProject.people_rates, initialProject.people_rates);

  if (projectTeamWasChanged) {
    const canSeeBudgets = userCanSeeBudgets(currentUser);
    newProject.project_team = {
      set: newProject.people_ids
        .filter((id) => !!people[id])
        .map((id) => {
          const obj = { people_id: id };

          if (canSeeBudgets) {
            obj.hourly_rate = formatToDbString(newProject.people_rates[id]);
          }

          return obj;
        }),
    };
  }

  delete newProject.people_ids;
  delete newProject.people_rates;
}

export function setTemplateRoster(currentUser, newTemplate) {
  const canSeeBudgets = userCanSeeBudgets(currentUser);

  newTemplate.team_people = newTemplate.people_ids.map((pid) => {
    const obj = {
      id: pid,
    };

    if (canSeeBudgets) {
      obj.hourly_rate = formatToDbString(newTemplate.people_rates[pid]);
    }

    return obj;
  });

  delete newTemplate.people_ids;
  delete newTemplate.people_rates;
}

function setTemplateMilestones(newProject) {
  newProject.milestones = [];
}

function setTemplateTaskMetas(newTaskNames, newProject) {
  newProject.task_metas =
    newTaskNames?.map((taskName) => {
      const taskNameItem = { ...taskName };
      delete taskNameItem.isNew;
      return taskNameItem;
    }) ?? [];
}

export function onError(errs) {
  this.setState({ isUpdating: false });
  errs = isArray(errs) ? errs : [errs];
  errs.forEach((err) => {
    if (err.field && err.message) {
      this.setFormErrors({
        [err.field]: [err.message],
      });

      return this.showMessage(capitalize(err.message));
    }

    if (err && err.message) {
      return this.showMessage(capitalize(err.message));
    }

    const isErrorObject = Object.keys(err).every((key) => {
      return Array.isArray(err[key]);
    });

    if (isErrorObject) {
      return this.setFormErrors(
        Object.keys(err).reduce((acc, key) => {
          const message = err[key];
          acc[key] = message;
          return acc;
        }, {}),
      );
    }

    return this.showMessage('An error occurred.');
  });
}

function extractTemplateFromProject(form, currentUser) {
  const { newTaskNames, ...newProject } = form;
  setTemplateRoster(currentUser, newProject);
  setTemplateTaskMetas(newTaskNames, newProject);
  setTemplateMilestones(newProject);
  newProject.project_id = undefined;
  newProject.project_name = `${newProject.project_name} - Template`;
  return newProject;
}

export async function saveProject(evt, skipValidation = false) {
  prevent(evt);
  if (!this.state.editing) {
    return;
  }

  if (this.noChangesMade() && !this.isAdding() && !this.isTemplate()) {
    this.hide();
    return;
  }

  const { newMilestones, newTaskNames, ...newProject } = this.state.form;
  formatBudgetFields(newProject);
  if (this.isTemplate()) {
    setTemplateRoster(this.props.currentUser, newProject);
    setTemplateMilestones(newProject);
    setTemplateTaskMetas(newTaskNames, newProject);
  } else {
    setProjectRoster(
      this.props.currentUser,
      newProject,
      this.props.people,
      this.initialProject,
      this.isAdding(),
    );
  }
  if (!skipValidation && !validate.call(this, newProject, this.props.people)) {
    return;
  }

  this.setState({ isUpdating: true });
  if (!newProject.client_id) {
    newProject.client_id = null;
  } else if (typeof newProject.client_id === 'string') {
    try {
      const clientRes = await this.props.actions.addClient({
        name: newProject.client_id,
      });
      newProject.client_id = clientRes.payload.client_id;
    } catch (ex) {
      const errors = isArray(ex) ? ex : [ex];
      errors.forEach((e) => {
        if (e.field === 'name') {
          e.field = 'client_id';
        }
      });

      onError.call(this, errors);
      return;
    }
  }

  // First, create or update the project. (The update could be done in
  // parallel with the other calls, but the create has to block to give us a
  // project id. For simplicity, we'll block on both here.)
  let projectId = this.isTemplate()
    ? newProject.project_template_id
    : newProject.project_id;

  let project;

  if (this.props.modalSettings.isAdding) {
    newProject.active = true;

    if (isEmpty(newProject.project_name)) {
      newProject.project_name = `* New ${
        this.isTemplate() ? 'Template' : 'Project'
      }`;
    }

    try {
      if (this.isTemplate()) {
        await this.props.actions.createTemplate(newProject);
        this.props.showSnackbar(`${newProject.project_name} created.`);
        this.hide();
        return;
      } else if (this.isDuplicating()) {
        const processId = await duplicateProject(
          newProject,
          this.initialProject,
        );
        this.props.showSnackbar(`Duplicating ${newProject.project_name}`, {
          id: processId,
          persist: true,
          loader: true,
        });
        this.hide();
        return;
      } else {
        const { fromTemplate } = this.props.modalSettings;
        const createRes = await this.props.actions.createProject(newProject, {
          fromTemplate,
        });
        projectId = createRes.project_id;
        project = createRes;
      }
    } catch (err) {
      onError.call(this, err);
      return;
    }
  } else {
    try {
      if (this.isTemplate()) {
        await this.props.actions.updateTemplate(
          newProject.project_template_id,
          newProject,
          false,
        );
        this.hide();
        return;
      } else {
        project = await this.props.actions.updateProject(
          newProject.project_id,
          newProject,
          false,
        );
      }
    } catch (err) {
      onError.call(this, err);
      return;
    }
  }

  // We'll push all other operations onto this array and wait for completion
  const promises = [];

  {
    // Add / remove milestones
    const toRemove = differenceBy(
      this.state.milestones,
      newMilestones,
      'milestone_id',
    );
    const toAdd = newMilestones.filter((m) => m.isNew);
    const toUpdate = newMilestones.filter((m) => {
      if (m.isNew) return false;
      const oldMilestone = this.state.milestones.find(
        (om) => om.milestone_id === m.milestone_id,
      );
      return (
        oldMilestone.date !== m.date ||
        oldMilestone.end_date !== m.end_date ||
        oldMilestone.name !== m.name
      );
    });

    promises.push(
      ...toRemove.map((m) =>
        this.props.actions.removeMilestone({
          milestone_id: m.milestone_id,
          force_skip_af: true,
        }),
      ),
    );

    const formatDate = (date) => {
      return date ? moment(date).format('YYYY-MM-DD') : null;
    };

    promises.push(
      ...toAdd.map((m) =>
        this.props.actions.createMilestone({
          project_id: projectId,
          name: m.name,
          date: formatDate(m.date),
          end_date: formatDate(m.end_date),
          force_skip_af: true,
        }),
      ),
    );

    promises.push(
      ...toUpdate.map((m) =>
        this.props.actions.updateMilestone({
          id: m.milestone_id,
          project_id: projectId,
          name: m.name,
          date: formatDate(m.date),
          end_date: formatDate(m.end_date),
          force_skip_af: true,
        }),
      ),
    );
  }

  {
    // Add tasks
    // Note: Only handling toAdd here (bulk add in case of project create).
    // All other interactions persist inline.
    const toAdd = newTaskNames.filter((t) => t.isNew);

    promises.push(
      ...toAdd.map((t) =>
        API3.createTaskMeta({
          project_id: projectId,
          task_name: t.task_name,
          billable: t.billable,
        }),
      ),
    );
  }

  try {
    await Promise.all(promises);
  } catch (err) {
    onError.call(this, err);
    return;
  }

  if (this.props.modalSettings.postSave) {
    this.props.modalSettings.postSave(project);
  }

  this.showMessage(
    `${newProject.project_name} ${
      this.props.modalSettings.isAdding ? 'added' : 'updated'
    }.`,
  );

  this.hide();
}

function handleProjectTempleteDelete() {
  const { project } = this.props.modalSettings;
  const projectName = project.project_name;

  const impact = getDeleteImpact.call(this);
  this.props.confirmDelete({
    title: 'Delete Template',
    item: projectName,
    impact,
    twoStep: Boolean(impact),
    onDelete: () => {
      this.props.actions.deleteTemplate(project.project_template_id);
      this.hide();
      this.showMessage(t`"${projectName}" deleted.`);
    },
  });
}

function handleDuplicateProject() {
  this.props.manageModal({
    visible: true,
    modalType: 'projectModal',
    modalSettings: {
      project: this.props.modalSettings.project,
      isAdding: true,
      duplicating: true,
    },
  });
}

function handleCreateTemplate() {
  this.props.manageModal({
    visible: true,
    modalType: 'projectModal',
    modalSettings: {
      isTemplate: true,
      isAdding: true,
      fromProject: true,
      project: extractTemplateFromProject(
        this.state.form,
        this.props.currentUser,
      ),
    },
  });
}

function handleActiveChange(active) {
  const updateArchiveStatus = (newActiveStatus) => {
    this.setState(
      (ps) => ({
        form: {
          ...ps.form,
          active: newActiveStatus,
        },
      }),
      () => saveProject.call(this, null, true),
    );
  };
  const onCancel = get(this.projectNameRef, 'current.focusInput') || noop;

  if (!active) {
    this.props.confirm({
      title: 'Move to archive',
      message: (
        <p>
          <span>Archive </span>
          <strong>{this.state.form.project_name}</strong>
          <span>?</span>
        </p>
      ),
      onConfirm: async () => {
        updateArchiveStatus(false);
      },
      onCancel,
    });
  } else {
    this.props.confirm({
      title: 'Move to active',
      message: (
        <p>
          <span>Move </span>
          <strong>{this.state.form.project_name}</strong>
          <span> to active?</span>
        </p>
      ),
      onConfirm: async () => {
        updateArchiveStatus(true);
      },
      onCancel,
    });
  }
}

function handleProjectDelete() {
  const { project } = this.props.modalSettings;
  const impact = getDeleteImpact.call(this);

  const updateArchiveStatus = (newActiveStatus) => {
    this.setState(
      (ps) => ({
        form: {
          ...ps.form,
          active: newActiveStatus,
        },
      }),
      () => saveProject.call(this, null, true),
    );
  };

  this.props.confirmDelete({
    title: 'Delete Project',
    item: project.project_name,
    impact,
    twoStep: Boolean(impact),
    onDelete: () => {
      this.props.actions.deleteProjectV2(project.project_id).then(() => {
        this.hide();
        this.showMessage(`${project.project_name} deleted.`);
      });
    },
    onArchive:
      !project.active || !impact
        ? undefined
        : () => {
            updateArchiveStatus(false);
            this.hide();
          },
  });
}

export default function ProjectModalActions() {
  const { isAdding, hideDelete, project } = this.props.modalSettings;
  const isProjectTemplate = this.isTemplate();
  const { currentUser } = this.props;

  const label = isAdding
    ? this.isDuplicating() && !isProjectTemplate
      ? 'Duplicate'
      : 'Create'
    : 'Update';

  const target = isProjectTemplate ? 'template' : 'project';

  const isMultiSelect = Object.values(this.state.selectedTasks).length > 0;
  const showActions = !isAdding && !hideDelete && !isMultiSelect;

  return (
    <>
      <Button
        type="submit"
        loader={this.state.isUpdating || this.state.isLoading}
        disabled={this.state.isLoading}
      >
        {label} {target}
      </Button>
      <Button appearance="secondary" onClick={this.hide}>
        Cancel
      </Button>
      {showActions ? (
        isProjectTemplate ? (
          <TemplateActionsMenu
            projectTemplate={project}
            currentUser={currentUser}
            onDelete={handleProjectTempleteDelete.bind(this)}
          />
        ) : (
          <ProjectActionsMenu
            project={project}
            currentUser={currentUser}
            onDuplicateProject={handleDuplicateProject.bind(this)}
            onCreateTemplate={handleCreateTemplate.bind(this)}
            onActiveChange={handleActiveChange.bind(this)}
            onDelete={handleProjectDelete.bind(this)}
          />
        )
      ) : null}
    </>
  );
}
