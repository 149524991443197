import React, { MouseEvent } from 'react';
import { t, Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated/Button/Button';
import { NoResultsIllustration } from '@float/ui/deprecated/Illustrations/NoResultsIllustration';

import { ScheduleError } from '../../../components/ScheduleError';
import { ErrorSearchNoResults } from '../../../components/SearchFilters/components/ErrorSearchNoResults';

type ErrorNoPeopleProps = {
  canCreatePeople: boolean;
  hasSearchFiltersApplied?: boolean;
  isSharedLink?: boolean;
  onClickAddPerson: (e: MouseEvent) => void;
};

const ErrorNoPeople = (props: ErrorNoPeopleProps) => {
  const {
    canCreatePeople,
    hasSearchFiltersApplied = false,
    isSharedLink = false,
    onClickAddPerson,
  } = props;

  if (hasSearchFiltersApplied) return <ErrorSearchNoResults />;

  return (
    <ScheduleError>
      <h2>
        {isSharedLink
          ? t`There are no people in this schedule`
          : t`There are no people to schedule`}
      </h2>

      <NoResultsIllustration />

      {canCreatePeople && (
        <Button onClick={onClickAddPerson}>
          <Trans>Add a team member</Trans>
        </Button>
      )}
    </ScheduleError>
  );
};

export { ErrorNoPeople };
