import React, { useCallback, useState } from 'react';
import { get, uniq } from 'lodash';
import styled from 'styled-components';

import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { Input } from '@float/ui/deprecated/Input';
import { Modal } from '@float/ui/deprecated/Modal';
import { ErrorText } from '@float/ui/deprecated/Text';

import CustomPublicHolidayDates, { format } from './CustomPublicHolidayDates';

const InputWrapper = styled.div`
  width: 100%;

  & > div {
    margin-bottom: 0;
  }
`;

const AddDateRow = styled.div`
  flex-basis: 100%;
  margin-top: 10px;
`;

function getInitialDates(props, key) {
  if (!props[key]?.length) return [];
  return props[key].map((x) => format(moment(x)));
}

function haveDatesChanged(arr1, arr2) {
  const length1 = arr1?.length;
  const length2 = arr2?.length;
  if (!length1 && !length2) return true;
  if (length1 !== length2) return true;
  return arr1.join(',') !== arr2.join(',');
}

function getSanitizedDates(dates) {
  return uniq(dates.filter((x) => x && x !== 'Invalid date').sort());
}

function updateDates(values, newValue, index) {
  if (!values?.length) return [newValue];
  return values.map((prevVal, i) => {
    if (index !== i) return prevVal;
    return newValue;
  });
}

export default function CustomPublicHolidaysModal(props) {
  const [region_holiday_id] = useState(
    props.region_holiday_id || `${Date.now()}${Math.random()}`,
  );
  const [name, setName] = useState(props.name || '');
  const [datesThisYear, _setDatesThisYear] = useState(() => {
    return getInitialDates(props, props.thisYear);
  });
  const [datesNextYear, setDatesNextYear] = useState(() => {
    return getInitialDates(props, props.nextYear);
  });

  const [nameError, setNameError] = useState();
  const [dateError, setDateError] = useState();

  const setDatesThisYear = useCallback(
    (newDates, index) => {
      _setDatesThisYear(newDates);
      // For UX: Sync next year's holiday with this year's when adding first date
      // or when next year's date is empty.
      if (newDates.length === 1 || !get(datesNextYear, `[${index}]`, '')) {
        const newDateNextYear = format(moment(newDates[index]).add(1, 'year'));
        const newValues = updateDates(datesNextYear, newDateNextYear, index);
        setDatesNextYear(newValues);
      }
    },
    [datesNextYear],
  );

  function submit(evt) {
    prevent(evt);

    let isValid = true;

    if (!name) {
      setNameError('Name is required');
      isValid = false;
    } else {
      setNameError();
    }

    const newDatesThisYear = getSanitizedDates(datesThisYear);
    const newDatesNextYear = getSanitizedDates(datesNextYear);

    if (!newDatesThisYear.length && !newDatesNextYear.length) {
      setDateError('At least one date is required');
      isValid = false;
    }

    if (isValid) {
      props.submit({
        region_holiday_id,
        name,
        [props.thisYear]: newDatesThisYear,
        [props.nextYear]: newDatesNextYear,
        isUpdate:
          !String(region_holiday_id).includes('.') &&
          (name !== props.name ||
            haveDatesChanged(newDatesThisYear, props[props.thisYear]) ||
            haveDatesChanged(newDatesNextYear, props[props.nextYear])),
      });
    }
  }

  function onDelete(evt) {
    prevent(evt);

    props.submit({
      region_holiday_id,
      isDelete: true,
    });
  }

  const onDateChange = ({ key, value, index }) => {
    let updateFn;
    let prevValues;
    if (key === props.thisYear) {
      updateFn = setDatesThisYear;
      prevValues = datesThisYear;
    } else if (key === props.nextYear) {
      updateFn = setDatesNextYear;
      prevValues = datesNextYear;
    }

    if (!updateFn) {
      console.error('[onDateChange] Invalid key', { key, value, index });
      return;
    }

    const newValues = updateDates(prevValues, value, index);
    updateFn(newValues, index);
  };

  const addDates = () => {
    _setDatesThisYear(datesThisYear.concat(['']));
    setDatesNextYear(datesNextYear.concat(['']));
  };

  const isAdd = !props.region_holiday_id;

  return (
    <Modal isOpen onClose={props.close}>
      <form noValidate onSubmit={submit}>
        <Modal.Header>
          <Modal.Title style={{ lineHeight: '40px' }}>
            {isAdd ? 'Add a' : 'Edit'} custom holiday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexWrap: 'wrap' }}>
          <InputWrapper>
            <Input
              label="Name"
              value={name}
              onChange={(evt) => setName(evt.currentTarget.value)}
              autoFocus
              errors={nameError ? [nameError] : null}
            />
          </InputWrapper>
          <CustomPublicHolidayDates
            thisYear={props.thisYear}
            nextYear={props.nextYear}
            datesThisYear={datesThisYear}
            datesNextYear={datesNextYear}
            onChange={onDateChange}
            addDates={addDates}
          />
          {dateError && (
            <ErrorText style={{ width: '100%' }}>{dateError}</ErrorText>
          )}
          <AddDateRow>
            <TextButton appearance="flue-fill" onClick={addDates}>
              Add
            </TextButton>
          </AddDateRow>
        </Modal.Body>
        <Modal.Actions style={{ paddingTop: 35 }}>
          <Button type="submit">{isAdd ? 'Add' : 'Update'} holiday</Button>
          <Button
            appearance="secondary"
            style={{ marginRight: 0 }}
            onClick={props.close}
          >
            Cancel
          </Button>
          {!isAdd && (
            <Button
              appearance="clear-danger"
              style={{
                marginLeft: 'auto',
                marginRight: 0,
              }}
              iconRight={Icons.IconTrash}
              onClick={onDelete}
            >
              Delete
            </Button>
          )}
        </Modal.Actions>
      </form>
    </Modal>
  );
}
