import React, { useRef } from 'react';

import { Rights } from '@float/common/lib/rights';
import { getUser } from '@float/common/selectors/currentUser';
import { getPeopleMap } from '@float/common/selectors/people';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { useAppSelector, useAppSelectorStrict } from '@float/common/store';
import { CellItem } from '@float/types';

import { lockedDayItem } from './LockedDayItem.css';

type LockedDayItemProps = {
  dayWidth: number;
  isEntityResizing?: boolean;
  item: CellItem<'lockedDay'>;
  row: PersonRow | PersonProjectRow | ProjectRow;
  suvSingleDay?: string | null;
};

function LockedDayItem(props: LockedDayItemProps) {
  const { item, dayWidth, suvSingleDay, isEntityResizing, row } = props;
  const { x = 0 } = item;
  const didMouseDown = useRef(false);

  const left = x * dayWidth + 1;
  let width: string | number = dayWidth - 1;

  if (suvSingleDay) {
    width = 'calc(100% - 1px)';
  }

  const peopleMap = useAppSelector(getPeopleMap);
  const person = row.type === 'person' && peopleMap[row.data.people_id];

  const canCreateLoggedTime = useAppSelectorStrict((state) =>
    Rights.canCreateLoggedTime(getUser(state), {
      person,
      entity: {
        isInLockPeriod: true,
      },
      ignore: [
        'isLoggedTimeProjectOwner',
        'onLoggedTimeProjectTeam',
        'loggedTimeProjectIsCommon',
      ],
    }),
  );

  return (
    <div
      className={lockedDayItem}
      onMouseDown={(event) => {
        if (!canCreateLoggedTime) {
          event.stopPropagation();
        }

        if (event.button !== 0) return;
        didMouseDown.current = true;
      }}
      onMouseUp={() => {
        if (!didMouseDown.current) return;
        didMouseDown.current = false;
      }}
      data-is-entity-resizing={Boolean(isEntityResizing)}
      style={{
        left,
        width,
        top: suvSingleDay ? 0 : -1,
        height: suvSingleDay ? '100%' : '101%', // 101% is to account for the top: -1 just above.
      }}
    />
  );
}

export default LockedDayItem;
