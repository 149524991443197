import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { isUndefined } from 'lodash';

import { ToggleButton } from '@float/ui/deprecated/Toggle/ToggleButton';
import { ToggleGroup } from '@float/ui/deprecated/Toggle/ToggleGroup';

function getDefaultValue({ projects, ids, key }) {
  const defaultValue = projects[ids[0]][key];
  if (
    !isUndefined(defaultValue) &&
    ids.every((id) => projects[id][key] === defaultValue)
  ) {
    return +defaultValue || 0;
  }
  return 0;
}

class ProjectBillable extends React.Component {
  componentDidMount() {
    this.setDefaultValue();
  }

  onNonBillableChange = (val) => {
    this.props.onChange({ non_billable: val });
  };

  getFields = async () => {
    const { value } = this.props;
    const fields = {};
    if (value.non_billable !== this.initialValue.non_billable) {
      fields.non_billable = value.non_billable;
    }
    return fields;
  };

  setDefaultValue = () => {
    const { ids, projects } = this.props;
    this.initialValue = {
      non_billable: getDefaultValue({ projects, ids, key: 'non_billable' }),
    };
    this.props.onChange(this.initialValue);
  };

  render() {
    const { non_billable = 0 } = this.props.value;
    return (
      <ToggleGroup
        appearance="stitched"
        disableUnselect
        value={non_billable}
        onChange={this.onNonBillableChange}
      >
        <ToggleButton value={0}>
          <Trans>Billable</Trans>
        </ToggleButton>
        <ToggleButton value={1}>
          <Trans>Non-billable</Trans>
        </ToggleButton>
      </ToggleGroup>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects.projects,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ProjectBillable,
);
