import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { throttle } from 'lodash';

import { useAppSelectorStrict } from '@float/common/store';

import { getPath } from '../hooks/useLinkArrowCurve';
import { getLinkArrowColor } from '../utils/getLinkArrowColor';
import { LinkInfo } from './types';

const TARGET_FPS = 100;

type MousePosition = {
  eligibleLinkTarget: LinkInfo['base'];
  rawX: number;
  rawY: number;
};

type LinkCursorArrowProps = {
  linkedArrowTargetRef: React.MutableRefObject<SVGSVGElement>;
  totalHeight: number;
  totalWidth: number;
  cornerWidth: number;
  headerHeight: number;
  linkInfo?: LinkInfo;
  mousePositionRef: React.MutableRefObject<MousePosition>;
};

const LinkCursorArrow = React.memo((props: LinkCursorArrowProps) => {
  const {
    linkInfo,
    cornerWidth,
    headerHeight,
    linkedArrowTargetRef,
    mousePositionRef,
  } = props;

  const [path, setPath] = useState<null | string>(null);
  const [endCirclePos, setEndCirclePos] = useState<null | {
    x: number;
    y: number;
  }>(null);

  useEffect(() => {
    const baseElement = linkInfo?.base?._boxRef?.offsetParent as HTMLElement;
    const baseCellElement = (baseElement?.offsetParent as HTMLElement)
      ?.offsetParent as HTMLElement;
    if (!baseCellElement) return;

    const from = {
      x:
        baseElement.offsetLeft +
        baseElement.offsetWidth +
        baseCellElement.offsetLeft,
      y:
        Number(
          // @ts-expect-error quite a dynamic access, can't ensure safety with static checks
          baseCellElement.offsetParent.attributes['data-translate-y'].value,
        ) +
        baseElement.offsetTop +
        baseElement.offsetHeight / 2,
    };

    let didMouseLeave = false;

    const handleMouseMove = (e: MouseEvent) => {
      if (didMouseLeave) return;

      if (e.clientY <= headerHeight || e.clientX <= cornerWidth) {
        setPath(null);
      } else {
        if (mousePositionRef.current.eligibleLinkTarget?._boxRef) {
          const toElement = mousePositionRef.current.eligibleLinkTarget._boxRef
            .offsetParent as HTMLElement;
          const wrapperElement = (toElement.offsetParent as HTMLElement)
            .offsetParent as HTMLElement;

          const to = {
            x: wrapperElement.offsetLeft + toElement.offsetLeft,
            y:
              Number(
                // @ts-expect-error quite a dynamic access, can't ensure safety with static checks
                wrapperElement.offsetParent.attributes['data-translate-y']
                  .value,
              ) +
              toElement.offsetTop +
              toElement.offsetHeight / 2,
          };

          setEndCirclePos(to);
          setPath(getPath(from, to));
        } else {
          const to = {
            x: mousePositionRef.current.rawX,
            y: mousePositionRef.current.rawY,
          };

          if (typeof to.x !== 'undefined' && typeof to.y !== 'undefined') {
            setPath(getPath(from, to, { straightLine: true }));
            setEndCirclePos(to);
          }
        }
      }
    };

    const throttledMouseMove = throttle(handleMouseMove, 1000 / TARGET_FPS, {
      leading: true,
      trailing: true,
    });

    const mouseLeave = () => {
      didMouseLeave = true;
      setPath(null);
    };

    const mouseEnter = () => {
      didMouseLeave = false;
    };

    window.addEventListener('mousemove', throttledMouseMove);
    window.document.addEventListener('mouseleave', mouseLeave);
    window.document.addEventListener('mouseenter', mouseEnter);

    return () => {
      setPath(null);
      window.removeEventListener('mousemove', throttledMouseMove);
      window.document.removeEventListener('mouseleave', mouseLeave);
      window.document.removeEventListener('mouseenter', mouseEnter);
    };
  }, [linkInfo, cornerWidth, headerHeight, mousePositionRef]);

  const color = useAppSelectorStrict((state) =>
    linkInfo?.base
      ? getLinkArrowColor(
          {
            phases: state.phases.phases,
            projects: state.projects.projects,
          },
          linkInfo?.base,
        )
      : undefined,
  );

  if (!path) return null;
  if (!linkInfo?.base) return null;

  return createPortal(
    <>
      <path
        d={path}
        stroke={color}
        strokeWidth="1"
        fill="transparent"
        pointerEvents="none"
        clipPath="url(#linkedTaskLineClip)"
      />
      {endCirclePos && (
        <circle
          stroke={color}
          strokeWidth="1"
          cx={endCirclePos.x}
          cy={endCirclePos.y}
          r={3}
          fill={color}
          pointerEvents="none"
        />
      )}
    </>,
    linkedArrowTargetRef.current,
  );
});

export default LinkCursorArrow;
