import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { FIN } from '@float/common/earhart/colors';
import { getNumberSeparators } from '@float/common/lib/budget/helpers/getNumberSeparators';
import { config } from '@float/libs/config';
import { IconWarningTriangle } from '@float/ui/deprecated/Earhart/Icons';
import EHIconAlert from '@float/ui/deprecated/Earhart/Icons/Icon/IconAlert';
import Icons from '@float/ui/deprecated/Icons';
import { FieldLabel } from '@float/ui/deprecated/Label';
import { ErrorText, SecondaryText } from '@float/ui/deprecated/Text';

import {
  FieldWrapper,
  InputEditIcon,
  InputPrefix,
  InputSuffix,
  InputWrapper,
} from './styles';

class InputField extends React.Component {
  state = {
    inputStyle: {},
  };

  /**
   * @type {null | HTMLInputElement}
   */
  input = null;

  constructor(props) {
    super(props);

    this.suffixRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => this.updateValueSize(), 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateValueSize();
    }
  }

  setReferenceWidthRef = (el) => {
    this.referenceElement = el;
  };

  setInputContainerRef = (el) => {
    this.inputContainer = el;
  };

  setInputRef = (el) => {
    this.input = el;

    if (typeof this.props.inputRef === 'function') {
      this.props.inputRef(el);
    } else if (this.props.inputRef && 'current' in this.props.inputRef) {
      this.props.inputRef.current = el;
    }
  };

  focusInput = () => {
    this.input.focus();
    this.input.select();
  };

  onFocus = (evt) => {
    this.setState({ isFocused: true });
    const input = evt?.currentTarget ?? this.input;
    const { onFocus, selectTextOnFocus } = this.props;
    if (selectTextOnFocus) {
      input.select();
    }
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  onBlur = (evt) => {
    this.setState({ isFocused: false });
    const { onBlur } = this.props;
    if (typeof onBlur === 'function') {
      onBlur(evt);
    }
  };

  onClickIconHandler = () => {
    if (this.props.onClickIcon) this.props.onClickIcon(this.input);
  };

  updateValueSize = () => {
    if (!this.referenceElement) {
      return;
    }

    if (!this.maxWidth) {
      if (this.props.suffixPositionAuto) {
        if (this.suffixRef.current) {
          this.maxWidth =
            this.inputContainer.parentElement.offsetWidth -
            this.suffixRef.current.offsetWidth -
            16 -
            10;
        }
      } else {
        this.maxWidth = this.inputContainer.offsetWidth - 24;
      }
    }

    const newWidth = this.referenceElement.offsetWidth + 2;
    if (newWidth <= this.maxWidth) {
      this.setState({
        valueSize: newWidth,
      });
    }
  };

  render() {
    const hasError = !isEmpty(this.props.errors);
    const {
      className,
      labelClassName,
      // just added this prop in here to reach the
      // font-size
      inputClassName,
      // Required for labeling
      inputId,
      appearance,
      size,
      label,
      value,
      defaultValue,
      disabled,
      noPointerEvents,
      noBackground,
      placeholder,
      secondaryText,
      autoSize,
      color,
      icon,
      iconPosition,
      prefix,
      suffix,
      minHeight,
      maxWidth,
      type,
      readOnly,
      alignLeft,
      isStaticPlaceholder,
      hideArrows,
      inputStyle,
      skipErrorsIcon,
      inlineErrorIcon,
      suffixPositionAuto,
      noBorder,
      placeholderIcon,
      style,
      onClickIcon,
      dataName,
      data1pIgnore,
      ariaLabel,
    } = this.props;

    const { isFocused } = this.state;

    const inputType = readOnly && type === 'number' ? 'text' : type; // to hide spin buttons across browsers
    const isAutoSize =
      autoSize && !isFocused && !hasError && !suffixPositionAuto;
    const showPencilPlaceholder = Boolean(
      value && value.length === 0 && placeholderIcon,
    );

    return (
      <FieldWrapper
        $underline={appearance === 'underline'}
        className={className}
        disabled={disabled}
        maxWidth={maxWidth}
        style={style}
      >
        {label && (
          <FieldLabel htmlFor={inputId} className={labelClassName}>
            {label}
          </FieldLabel>
        )}

        <InputWrapper
          $underline={appearance === 'underline'}
          $medium={size === 'medium'}
          $large={size === 'large'}
          $xlarge={size === 'xlarge'}
          $iconLeading={icon && iconPosition === 'leading'}
          $noPointerEvents={noPointerEvents}
          $disabled={disabled}
          hideArrows={hideArrows}
          hasError={hasError}
          noBorder={noBorder}
          alignLeft={alignLeft}
          noBackground={noBackground}
          isFocused={isFocused}
          autoSize={isAutoSize}
          suffixPositionAuto={suffixPositionAuto}
          color={color}
          minHeight={minHeight}
          isStaticPlaceholder={isStaticPlaceholder}
          readOnly={readOnly}
          onClick={() => !disabled && this.input.focus()}
        >
          {prefix && <InputPrefix>{prefix}</InputPrefix>}

          <div ref={this.setInputContainerRef} className="input-container">
            {icon && iconPosition === 'leading' && (
              <span
                className={cx('icon', 'left', {
                  iconClickable: !!onClickIcon,
                })}
                onClick={this.onClickIconHandler}
              >
                {icon}
              </span>
            )}
            <input
              ref={this.setInputRef}
              id={inputId}
              type={inputType}
              autoFocus={this.props.autoFocus}
              name={this.props.name}
              readOnly={readOnly}
              required={this.props.required}
              placeholder={placeholder}
              value={value}
              defaultValue={defaultValue}
              min={this.props.min}
              max={this.props.max}
              step={this.props.step}
              maxLength={this.props.maxLength}
              style={{
                color,
                ...inputStyle,
                ...(autoSize ? { width: this.state.valueSize } : {}),
              }}
              className={inputClassName}
              onChange={this.props.onChange}
              onKeyDown={this.props.onKeyDown}
              onKeyPress={this.props.onKeyPress}
              onPaste={this.props.onPaste}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onWheel={this.props.onWheel}
              tabIndex={disabled ? -1 : 0}
              data-name={dataName}
              data-lpignore
              data-1p-ignore={data1pIgnore ?? true}
              disabled={disabled}
              aria-label={ariaLabel}
            />

            {hasError && !skipErrorsIcon && (
              <>
                <EHIconAlert color={FIN.Lt.Danger.Default} />
              </>
            )}

            {showPencilPlaceholder && (
              <InputEditIcon
                onClick={this.focusInput}
                style={{
                  position: 'absolute',
                  left: this.state.valueSize,
                }}
              >
                {showPencilPlaceholder ? (
                  React.cloneElement(showPencilPlaceholder, { color })
                ) : (
                  <Icons.Pencil color={color} />
                )}
              </InputEditIcon>
            )}

            {isAutoSize && (
              <InputEditIcon onClick={this.focusInput}>
                <Icons.Pencil color={color} />
              </InputEditIcon>
            )}
          </div>

          {suffix && <InputSuffix ref={this.suffixRef}>{suffix}</InputSuffix>}

          {icon && iconPosition === 'trailing' && (
            <span
              className={cx('icon', 'right', {
                iconClickable: !!onClickIcon,
              })}
              onClick={this.onClickIconHandler}
            >
              {icon}
            </span>
          )}

          <span ref={this.setReferenceWidthRef} className="measure-this">
            {value || placeholder}
          </span>
        </InputWrapper>

        {hasError &&
          this.props.errors.map((e) =>
            e ? (
              <ErrorText key={e}>
                {inlineErrorIcon && <IconWarningTriangle />} {e}
              </ErrorText>
            ) : null,
          )}

        {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
      </FieldWrapper>
    );
  }
}

InputField.defaultProps = {
  appearance: 'default',
  icon: null,
  iconPosition: 'leading',
  type: 'text',
  label: '',
  size: 'medium',
  autoSize: false,
  disabled: false,
  suffixPositionAuto: false,
  noPointerEvents: false,
  style: {},
  errors: [],
};

InputField._styles = { FieldWrapper, InputWrapper, InputPrefix, InputSuffix };

InputField.validators = {
  number: (maxLength) => {
    return (val) => {
      const { decimal, thousand } = getNumberSeparators(config.locale);

      const validChars = new RegExp(`^[\\d\\${decimal}\\${thousand}]*$`);
      if (!validChars.test(val)) return false;

      let [left, right] = val.split(decimal);
      left = left.replace(new RegExp(`\\${thousand}`, 'g'), '');
      if (right) {
        right = right.replace(new RegExp(`\\${thousand}`, 'g'), '');
      }

      if (left.length > maxLength) return false;
      if (right && right.length > 2) return false;

      return true;
    };
  },
  regex: (regexp) => {
    return (val) => !!(val || '').match(regexp);
  },
};

export const Input = InputField;

export default InputField;
