import React, { useState } from 'react';
import styled from 'styled-components';

import { getPercentage } from '@float/common/lib/budget';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { PCT_MODES } from '@float/ui/deprecated/Chart/constants';
import { DownSmall as IconDownSmall } from '@float/ui/deprecated/Icons/iconChevronDownSmall';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

// @ts-expect-error VirtualSelect doesn't have the ts typings
const { RowLabel } = VirtualSelect._styles;

const PercentageSelectWrapper = styled.div`
  font-size: 16px;
  width: 205px;

  ${RowLabel} {
    padding: 0 15px;
    &:hover {
      background-color: ${(p) => p.theme.superLightGrey};
    }
  }
`;

const PercentageSelectContainer = styled.div`
  display: flex;
  align-items: center;
  outline: none;
`;

const IconWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.midGrey};
  border-radius: 4px;
  margin-left: 8px;
  height: 20px;
  width: 20px;
  display: flex;

  svg.icon-chevron-down-sm {
    min-width: 18px;
    transform: rotate(0);
  }
`;

function PercentageSelector({
  ctx,
  type,
}: {
  ctx: {
    updateSettings?: (settings: {
      percentageMode: (typeof PCT_MODES)[keyof typeof PCT_MODES];
    }) => void;
    percentageMode?: (typeof PCT_MODES)[keyof typeof PCT_MODES];
  };
  type: string;
}) {
  const [open, setOpen] = useState(false);
  const { updateSettings, percentageMode } = ctx;
  let shortLabel = '';
  let label = '';

  if (type === 'logged') {
    label = 'Logged';
    shortLabel = 'Logged %';
  } else if (type === 'combined') {
    label = 'Combined';
    shortLabel = 'Comb. %';
  } else {
    label = 'Scheduled';
    shortLabel = 'Sched. %';
  }

  if (percentageMode != PCT_MODES.SCHEDULED_CAPACITY) {
    shortLabel = 'Billable %';
  }

  return (
    <div style={{ display: 'flex' }}>
      {shortLabel}
      <Popover
        distance={0}
        arrow={false}
        placement="bottom"
        className="float-nav-dropdown"
        onOpenChange={setOpen}
        open={open}
        content={
          <PercentageSelectWrapper
            onClick={(e) => {
              prevent(e);
              setOpen(false);
            }}
          >
            <RowLabel
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                updateSettings?.({
                  percentageMode: PCT_MODES.SCHEDULED_CAPACITY,
                })
              }
            >
              {label} % of capacity
            </RowLabel>
            <RowLabel
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                updateSettings?.({
                  percentageMode: PCT_MODES.BILLABLE_CAPACITY,
                })
              }
            >
              Billable % of capacity
            </RowLabel>
            <RowLabel
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                updateSettings?.({
                  percentageMode: PCT_MODES.BILLABLE_SCHEDULED,
                })
              }
            >
              Billable % of {label}
            </RowLabel>
          </PercentageSelectWrapper>
        }
      >
        <PercentageSelectContainer>
          <IconWrapper>
            <IconDownSmall className="prevent-sort-action" />
          </IconWrapper>
        </PercentageSelectContainer>
      </Popover>
    </div>
  );
}

export function getPctHeader(
  ctx: {
    updateSettings?: (settings: unknown) => void;
    percentageMode?: (typeof PCT_MODES)[keyof typeof PCT_MODES];
  },
  type: string,
) {
  return <PercentageSelector ctx={ctx} type={type} />;
}

export function getPctValue(
  ctx: { percentageMode?: string },
  o: {
    billable: number;
    days: number;
    balance: number;
    logged: { scheduled: number; billable: number };
    combined: { scheduled: number; billable: number };
    scheduled: number;
    capacity: number;
  },
  basis: string,
) {
  const { percentageMode } = ctx;

  if (percentageMode === PCT_MODES.SCHEDULED_CAPACITY) {
    if (basis === 'balance') {
      return getPercentage(o.days, o.balance, false, true);
    } else if (basis === 'logged') {
      return getPercentage(o.logged.scheduled, o.capacity);
    } else if (basis === 'combined') {
      return getPercentage(o.combined.scheduled, o.capacity);
    } else {
      return getPercentage(o.scheduled, o.capacity);
    }
  }

  if (percentageMode === PCT_MODES.BILLABLE_SCHEDULED) {
    if (basis === 'balance') {
      return getPercentage(o.days, o.balance, false, true);
    } else if (basis === 'logged') {
      return getPercentage(o.logged.billable, o.logged.scheduled);
    } else if (basis === 'combined') {
      return getPercentage(o.combined.billable, o.combined.scheduled);
    } else {
      return getPercentage(o.billable, o.scheduled);
    }
  }

  if (percentageMode === PCT_MODES.BILLABLE_CAPACITY) {
    if (basis === 'balance') {
      return getPercentage(o.days, o.balance, false, true);
    } else if (basis === 'logged') {
      return getPercentage(o.logged.billable, o.capacity);
    } else if (basis === 'combined') {
      return getPercentage(o.combined.billable, o.capacity);
    } else {
      return getPercentage(o.billable, o.capacity);
    }
  }

  return 0;
}
