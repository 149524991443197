import React from 'react';
import styled from 'styled-components';

import { DatePicker } from '@float/common/components/DatePicker/DatePicker';
import { moment } from '@float/libs/moment';
import { bindVal } from '@float/ui/deprecated/helpers/forms';
import { ModalTopGreySection } from '@float/ui/deprecated/Modal';
import { ToggleGroup } from '@float/ui/deprecated/Toggle/ToggleGroup';
import { VirtualSelectWithRef as VirtualSelect } from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import WorkDays from 'components/elements/WorkDays';

import PersonNotes from '../components/personNotes';

const DateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  margin-right: 20px;

  input {
    font-size: 18px !important;
    width: 100%;
  }
`;

export const WorkDaysSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 22px;
`;

export const HoursContainer = styled.div`
  width: 110px;
  flex-wrap: wrap;
  margin-right: 30px;

  .icon-chevron-down {
    position: relative;
    left: 2px;
    margin-left: -6px;
  }
`;

let self;

export const employeeTypeOptions = [
  { value: 1, label: 'Full-time' },
  { value: 0, label: 'Part-time' },
];

function setNotes(notes) {
  self.setFormState({ notes });
}

function onDateChange(name) {
  return (momentDate) => {
    const date = momentDate ? momentDate.format('YYYY-MM-DD') : null;
    const { start_date, end_date } = self.state.form;
    const newState = { [name]: date };

    // Set start date to end date if end date was before start
    if (name === 'end_date') {
      if (date && start_date && start_date > date) {
        newState.start_date = date;
      }
    }

    // Set end date to start date if start date was after end
    if (name === 'start_date') {
      if (date && end_date && end_date < date) {
        newState.end_date = date;
      }
    }

    self.setFormState(newState);
  };
}

function onEmployeeTypeChange(value) {
  const newState = { employee_type: value };

  if (value == 0) {
    newState.work_days_hours = [...self.props.companyPrefs.work_days_hours];
  }

  self.setFormState(newState);
}

function setWorkDaysHours(val) {
  self.setState({ workDaysHours: val });
}

export default function renderPersonAvailability(that) {
  self = that;
  const { allRegions, notesFetched } = self.state;
  const { people_id: personId, notes } = self.state.form;
  const isStartDateSet = !!self.state.form.start_date;
  const isEndDateSet = !!self.state.form.end_date;
  const start_date = moment(`${self.state.form.start_date} 00:00:00`);
  const end_date = moment(`${self.state.form.end_date} 00:00:00`);

  return (
    <>
      <ModalTopGreySection style={{ display: 'flex' }}>
        <DateContainer>
          <DatePicker
            label="Start date"
            value={isStartDateSet ? start_date : null}
            allowClear
            onChange={onDateChange('start_date')}
          />
        </DateContainer>
        <DateContainer style={{ marginRight: 0 }}>
          <DatePicker
            label="End date"
            value={isEndDateSet ? end_date : null}
            allowClear
            onChange={onDateChange('end_date')}
          />
        </DateContainer>
      </ModalTopGreySection>

      {!self.isPlaceholder() && (
        <div style={{ marginBottom: 22 }}>
          <ToggleGroup
            appearance="stitched"
            value={self.state.form.employee_type}
            options={employeeTypeOptions}
            disableUnselect
            onChange={onEmployeeTypeChange}
          />
        </div>
      )}
      {self.isPartTime() &&
        !self.isPlaceholder() &&
        self.state.workDaysHours && (
          <WorkDays
            firstDay={self.props.currentUser.start_work_week}
            hideApplyTo={self.isAdding()}
            value={self.state.workDaysHours}
            onChange={setWorkDaysHours}
            style={{ marginBottom: 22 }}
          />
        )}

      {allRegions && allRegions.length ? (
        <VirtualSelect
          label="Public holidays"
          style={{ marginBottom: 22, marginTop: 25 }}
          visibleItems={6}
          clearInputOnDropdownOpen={false}
          options={self.state.allRegions}
          {...bindVal(self, 'region_id', null)}
        />
      ) : null}

      <PersonNotes
        autoFocus
        personId={personId}
        onChange={setNotes}
        value={notes}
        readOnly={!notesFetched}
      />
    </>
  );
}
