import React, { useCallback, useState } from 'react';

import { calculatePrice } from '@float/common/lib/pricing';
import { noop } from '@float/libs/utils/noop';
import { Button } from '@float/ui/deprecated/Button/Button';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';
import { TextToggleOption } from '@float/ui/deprecated/Earhart/Toggles/TextToggle';
import { FieldLabel } from '@float/ui/deprecated/Label';
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated/Modal';
import {
  withConfirm,
  WithConfirmExtraProps,
} from '@float/ui/deprecated/Modal/withConfirm';
import { onChatClick } from 'components/Nav/helpers';

import { StyledTotalPrice } from './styles';

export type UpdateBillingCycleModalProps = {
  totalSeats?: number;
  usedSeats?: number;
  initialIsMonthly: boolean;
  onSubmit?: (isMonthly?: boolean) => Promise<void>;
  onClose?: () => void;
  isEnterprisePlan: boolean;
  isProPlan: boolean;
};

export const UpdateBillingCycleModal = withConfirm(
  ({
    totalSeats = 1,
    initialIsMonthly,
    onSubmit = Promise.resolve,
    onClose = noop,
    confirm,
    isEnterprisePlan,
    isProPlan,
  }: UpdateBillingCycleModalProps & WithConfirmExtraProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isMonthly, setIsMonthly] = useState(initialIsMonthly);
    const showLoader = isConfirmed && isLoading;

    // Note: Despite the new plans, prices and their calculations remain unchanged. Therefore, these calculations
    // still include references to legacy features such as time tracking and plus pack add-ons."
    const pricing = calculatePrice({
      plans: {}, // only calculates new plan prices
      isMonthly,
      peopleLimit: totalSeats,
      hasPlusPack: isEnterprisePlan,
      hasTimeTracking: isProPlan || isEnterprisePlan,
    });

    const billingCycle: TextToggleOption<boolean>[] = [
      {
        value: false,
        label: 'Yearly',
        subLabel: `save ${pricing.annualDiscount}%`,
      },
      { value: true, label: 'Monthly' },
    ];

    const submit = useCallback(() => {
      setIsLoading(true);

      confirm({
        title: 'Confirm your new plan',
        message:
          'Your new plan will start immediately. You will be charged the new amount in your next billing cycle.',
        confirmLabel: 'Confirm',
        onConfirm: async () => {
          try {
            setIsConfirmed(true);
            await onSubmit(isMonthly);
            setIsLoading(false);
          } catch (e) {
            confirm({
              title: `Error updating billing cycle`,
              message: `Sorry, there was an error updating your billing cycle. Please
              refresh the page to try again. If the error persists, please contact
              our support team.`,
              hideCancel: true,
              onConfirm: close,
            });
            setIsLoading(false);
          }
        },
      });
    }, [confirm, onSubmit, isMonthly]);

    const handleRadioChange = (option: TextToggleOption<boolean>) => {
      const { value } = option;

      setIsDirty(initialIsMonthly !== value);

      setIsMonthly(value);
    };

    return (
      <Modal isOpen={true} onEnter={onSubmit} onClose={onClose}>
        <ModalHeader>
          <ModalTitle style={{ fontSize: 22 }}>Update billing cycle</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form noValidate onSubmit={submit}>
            <FieldLabel style={{ marginBottom: 8 }}>Billing cycle</FieldLabel>
            <TextToggle<boolean>
              onChange={handleRadioChange}
              options={billingCycle}
              value={isMonthly}
            />

            <FieldLabel style={{ marginTop: 20, marginBottom: 8 }}>
              {isMonthly ? 'Per month' : 'Per year'}
            </FieldLabel>
            <StyledTotalPrice>${pricing.total}</StyledTotalPrice>
          </form>
        </ModalBody>
        <ModalActions>
          <Button
            loader={showLoader}
            onClick={submit}
            disabled={!isDirty || showLoader}
          >
            Update
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            appearance="clear"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            onClick={onChatClick}
          >
            Message support
          </Button>
        </ModalActions>
      </Modal>
    );
  },
);
