import { TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { Input } from '@float/ui/deprecated/Input';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  margin-top: 32px;
`;

export const StyledTransitionGroup = styled(TransitionGroup)`
  width: 100%;
`;

export const StyledLabel = styled.h3`
  ${Typography.Label12.M500};

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  margin-bottom: 12px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;

  margin-bottom: 16px;

  transition-property: opacity, transform;
  transition-timing-function: ${Animation.Settings.Easing.InOutBounce};
  transition-duration: 0.5s;

  ${respondTo(
    'small',
    '<=',
  )(css`
    max-width: none;
  `)};

  &.input-enter {
    opacity: 0;
    transform: translateY(5px);
  }

  &.input-enter-active {
    opacity: 1;
    transform: none;
  }
`;
