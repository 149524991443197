import React, { useCallback } from 'react';
import cx from 'classnames';

import Icons from '@float/ui/deprecated/Icons';

import { IconContainer, SpinnerContainer, StyledButton } from './styles';

const ButtonInner = (props, ref) => {
  const {
    type = null,
    className,
    appearance = 'primary',
    size = 'medium',
    state = 'default',
    icon: Icon,
    iconRight: IconRight,
    autoFocus,
    as,
    loader,
    style,
    tabIndex,
    onClick,
    disabled = false,
    iconRightProps,
    minWidth,
    ['data-testid']: testid,
    ...rest
  } = props;

  const hasLoaderIcon =
    loader && (loader === true || loader.loading || loader.loaded);

  const onClickHandler = useCallback(
    (e) => {
      e.currentTarget.blur();
      if (onClick) onClick(e);
    },
    [onClick],
  );

  return (
    <StyledButton
      $appearance={appearance}
      $size={size}
      $iconLeft={!!Icon}
      $iconRight={!!IconRight || (loader && (loader.loading || loader.loaded))}
      type={type}
      className={cx(className, { active: state === 'active' })}
      ref={ref}
      as={as}
      loader={loader}
      onClick={loader ? undefined : onClickHandler}
      tabIndex={tabIndex}
      style={style}
      disabled={disabled}
      loaded={loader && loader.loaded}
      autoFocus={autoFocus}
      $minWidth={minWidth}
      data-testid={testid}
      {...rest}
    >
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}

      <span>{props.children}</span>

      {loader && (loader === true || loader.loading) && (
        <SpinnerContainer isRight>
          <Icons.Spinner />
        </SpinnerContainer>
      )}

      {loader && loader.loaded && (
        <IconContainer isRight>
          <Icons.TickAnimated />
        </IconContainer>
      )}

      {IconRight && !hasLoaderIcon && (
        <IconContainer isRight>
          <IconRight {...iconRightProps} />
        </IconContainer>
      )}

      {props.textRight && (
        <IconContainer isRight>{props.textRight}</IconContainer>
      )}
    </StyledButton>
  );
};

/**
 * @deprecated use `@float/ui/components/Button` instead
 */
export const Button = React.forwardRef(ButtonInner);
Button.Styled = StyledButton;
Button._styles = { StyledButton, IconContainer };

/**
 * @deprecated use `@float/ui/components/Button` instead
 */
export default Button;
