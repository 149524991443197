import React from 'react';
import { differenceBy, get, isEmpty, noop } from 'lodash';

import API3 from '@float/common/api3';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button } from '@float/ui/deprecated/Button/Button';

import { PhaseActionsMenu } from './PhaseActionsMenu';
import { getDeleteImpact } from './ProjectForm/TasksFragment';
import { validate } from './ProjectModal.helpers';
import {
  formatBudgetFields,
  onError,
  setProjectRoster,
} from './ProjectModalActions';

function hasProjectBudgetTypeChanged() {
  return +this.initialPhase.budget_type !== this.state.form.budget_type;
}

async function updateProjectBudgetType(newPhase) {
  try {
    const { budget_type, project_id, budget_priority } = newPhase;
    const patch = { budget_type, budget_priority };
    await this.props.actions.updateProject(project_id, patch);
  } catch (err) {
    onError.call(this, err);
    return false;
  }
  return true;
}

export async function savePhase(evt) {
  prevent(evt);
  if (!this.state.editing || this.state.isLoading || this.state.isUpdating) {
    return;
  }

  if (this.noChangesMade() && !this.isAdding()) {
    this.hide();
    return;
  }

  const { newMilestones, newTaskNames, ...newPhase } = this.state.form;
  formatBudgetFields(newPhase);
  setProjectRoster(
    this.props.currentUser,
    newPhase,
    this.props.people,
    this.initialProject,
    this.isAdding(),
  );
  if (!validate.call(this, newPhase, this.props.people)) {
    return;
  }

  this.setState({ isUpdating: true });
  newPhase.phase_team = newPhase.project_team;
  if (newPhase.phase_team?.set?.length == 0) {
    delete newPhase.phase_team.set;
  }
  delete newPhase.project_team;

  let shouldUpdateBudgetTypeAfterPhaseSave = false;
  if (hasProjectBudgetTypeChanged.call(this)) {
    if (!newPhase.budget_type) {
      // Resetting to "No budget". Save phase first to clear its budget_total.
      // If budget type is updated first, BE will fail on saving phase total.
      shouldUpdateBudgetTypeAfterPhaseSave = true;
    } else {
      const budgetTypeUpdated = await updateProjectBudgetType.call(
        this,
        newPhase,
      );
      if (!budgetTypeUpdated) {
        return;
      }
    }
  }

  // Create or update the phase. (The update could be done in
  // parallel with the other calls, but the create has to block to give us a
  // project id. For simplicity, we'll block on both here.)
  let phaseId = newPhase.phase_id;

  if (!newPhase.budget_priority) {
    delete newPhase.budget_total; // BE returns err if this attr is sent
  }

  if (this.props.modalSettings.isAdding) {
    newPhase.active = true;

    if (isEmpty(newPhase.phase_name)) {
      newPhase.phase_name = '* New Phase';
    }

    try {
      const createRes = await this.props.actions.createPhase(newPhase);
      phaseId = createRes.phase_id;
    } catch (err) {
      onError.call(this, err);
      return;
    }
  } else {
    try {
      await this.props.actions.updatePhase(newPhase);
    } catch (err) {
      onError.call(this, err);
      return;
    }
  }

  // We'll push all other operations onto this array and wait for completion
  const promises = [];

  if (shouldUpdateBudgetTypeAfterPhaseSave) {
    promises.push(updateProjectBudgetType.call(this, newPhase));
  }

  {
    // Add / remove milestones
    const toRemove = differenceBy(
      this.state.milestones,
      newMilestones,
      'milestone_id',
    );
    const toAdd = newMilestones.filter((m) => m.isNew);
    const toUpdate = newMilestones.filter((m) => {
      if (m.isNew) return false;
      const oldMilestone = this.state.milestones.find(
        (om) => om.milestone_id === m.milestone_id,
      );
      return (
        oldMilestone.date !== m.date ||
        oldMilestone.end_date !== m.end_date ||
        oldMilestone.name !== m.name
      );
    });

    promises.push(
      ...toRemove.map((m) =>
        this.props.actions.removeMilestone({
          milestone_id: m.milestone_id,
          force_skip_af: true,
        }),
      ),
    );

    const formatDate = (date) => {
      return date ? moment(date).format('YYYY-MM-DD') : null;
    };

    promises.push(
      ...toAdd.map((m) =>
        this.props.actions.createMilestone({
          project_id: this.state.form.project_id,
          phase_id: phaseId,
          name: m.name,
          date: formatDate(m.date),
          end_date: formatDate(m.end_date),
          force_skip_af: true,
        }),
      ),
    );

    promises.push(
      ...toUpdate.map((m) =>
        this.props.actions.updateMilestone({
          id: m.milestone_id,
          project_id: this.state.form.project_id,
          phase_id: phaseId,
          name: m.name,
          date: formatDate(m.date),
          end_date: formatDate(m.end_date),
          force_skip_af: true,
        }),
      ),
    );
  }

  {
    // Add tasks
    // Note: Only handling toAdd here (bulk add in case of phase create).
    // All other interactions persist inline.
    const toAdd = newTaskNames.filter((t) => t.isNew);
    promises.push(
      ...toAdd.map((t) =>
        API3.createTaskMeta({
          project_id: this.state.form.project_id,
          phase_id: phaseId,
          task_name: t.task_name,
          billable: t.billable,
        }),
      ),
    );
  }

  try {
    await Promise.all(promises);
  } catch (err) {
    onError.call(this, err);
    return;
  }

  if (this.props.modalSettings.postSave) {
    this.props.modalSettings.postSave(phaseId);
  }

  this.showMessage(
    `${newPhase.phase_name} ${
      this.props.modalSettings.isAdding ? 'added' : 'updated'
    }.`,
  );

  this.hide();
}

function handleActiveChange(active) {
  const onCancel = get(this.phaseNameRef, 'current.focusInput') || noop;

  const updateArchiveStatus = (newActiveStatus) => {
    this.setState(
      (ps) => ({
        form: {
          ...ps.form,
          active: newActiveStatus,
        },
      }),
      () => savePhase.call(this),
    );
  };

  if (!active) {
    this.props.confirm({
      title: 'Move to archive',
      message: (
        <p>
          <span>Archive </span>
          <strong>{this.state.form.phase_name}</strong>
          <span>?</span>
        </p>
      ),
      onConfirm: async () => {
        updateArchiveStatus(false);
      },
      onCancel,
    });
  } else {
    this.props.confirm({
      title: 'Move to active',
      message: (
        <p>
          <span>Move </span>
          <strong>{this.state.form.phase_name}</strong>
          <span> to active?</span>
        </p>
      ),
      onConfirm: async () => {
        updateArchiveStatus(true);
      },
      onCancel,
    });
  }
}

function handleDelete() {
  const { phase } = this.props.modalSettings;
  const canArchive = this.project.active && phase.active;

  const updateArchiveStatus = (newActiveStatus) => {
    this.setState(
      (ps) => ({
        form: {
          ...ps.form,
          active: newActiveStatus,
        },
      }),
      () => savePhase.call(this),
    );
  };

  const impact = getDeleteImpact.call(this);
  this.props.confirmDelete({
    title: 'Delete phase',
    item: phase.phase_name,
    impact,
    twoStep: Boolean(impact),
    onDelete: () => {
      this.props.actions.deletePhase(phase.phase_id).then(() => {
        this.hide();
        this.showMessage(`${phase.phase_name} deleted.`);
      });
    },
    onArchive:
      !canArchive || !impact
        ? undefined
        : () => {
            updateArchiveStatus(false);
            this.hide();
          },
  });
}

export default function PhaseModalActions() {
  const { isAdding, hideDelete, phase, projectId } = this.props.modalSettings;
  const { currentUser } = this.props;
  const showActions = !isAdding && !hideDelete;

  return (
    <>
      <Button
        type="submit"
        loader={this.state.isUpdating || this.state.isLoading}
        disabled={this.state.isLoading}
      >
        {this.props.modalSettings.isAdding ? 'Create' : 'Update'} phase
      </Button>
      <Button appearance="secondary" onClick={this.hide}>
        Cancel
      </Button>
      {showActions && (
        <PhaseActionsMenu
          phase={phase}
          projectId={projectId}
          currentUser={currentUser}
          onActiveChange={handleActiveChange.bind(this)}
          onDelete={handleDelete.bind(this)}
        />
      )}
    </>
  );
}
